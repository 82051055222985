import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import * as fromActions from './account.action';
import { switchMap, catchError, map, filter, take, tap } from 'rxjs/operators';
import * as fromSelectors from './account.selector';
import { AccountService } from '@core/services/account.service';
import { from, of } from 'rxjs';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { upsertAddress } from '../address/address.action';
import { ModalService } from '@shared/services/modal.service';
import { TaskActions } from '@core/store/actions';
import { AccountInfoContainerComponent } from '@shared/components/account-info-container/account-info-container.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AccountEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private accountService: AccountService,
    private errorSanitizerService: ErrorSanitizerService,
    private modalService: ModalService,
    private ngbModal: NgbModal
  ) {}

  createAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createAccount),
      switchMap(() =>
        this.store.select(fromSelectors.buildCreateAccountRequest).pipe(take(1))
      ),
      switchMap((request) => {
        return this.accountService.createAccount(request).pipe(
          switchMap((response) => {
            if (request.accountHolder.nonSpecifiedGender) {
              response.accountHolder.person.gender = 'X';
            }
            return of(fromActions.createAccountSuccess({ response }));
          }),
          catchError((error) => {
            return of(
              fromActions.createAccountFail({
                error: this.errorSanitizerService.sanitizeError(error),
              })
            );
          })
        );
      })
    )
  );

  addAddressFromCreateAccountResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createAccountSuccess),
      map((action) => action.response?.accountHolder?.address),
      filter((address) => !!address),
      map((address) =>
        upsertAddress({ payload: { ...address, addressType: 'Account' } })
      )
    )
  );

  showAccRegistrationmodal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showAccountRegistrationModal),
      switchMap((action) => {
        const nextActions: Action[] = [];
        if (action.dismissReviewTask) {
          nextActions.push(
            TaskActions.dismissReviewTask({
              payload: {
                field: 'onlineAccountRegistrationReview',
                productType: 'All',
              },
            })
          );
        }
        this.modalService.accountRegistrationModal();
        return from(nextActions);
      })
    )
  );

  showAccountInfoModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.showAccountInfoModal),
        tap(() => {
          const modal = this.ngbModal.open(AccountInfoContainerComponent, {
            centered: true,
            keyboard: false,
            size: 'lg',
          });
          modal.componentInstance.parent = modal;
        })
      ),
    { dispatch: false }
  );
}
