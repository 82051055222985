import { MaskHelper } from '@core/helper/mask-helper';
import { ProductType, RelationToPNIType } from '@core/models/api/dsm-types';
import { DriverRequest } from '@core/models/api/request/driver-request.model';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Dictionary } from '@ngrx/entity';
import { DriverRelationToPNI } from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
import { FeatureFlagUtils } from '@shared/utils/feature-flag.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { Nullable } from '@shared/utils/type.utils';
import { MemberModel } from '@core/models/views/person.model';

export class BuildDriverRequestUtil {
  static buildUpsertDriverRequest(
    driver: DriverEntity,
    product: ProductModel,
    quoteState: string,
    featureFlags: FeatureFlagsModel,
    correlationId: string
  ): DriverRequest {
    const productType = product?.type as ProductType;
    const request: DriverRequest = {
      quoteId: product?.quoteId,
      productType,
      driver: {
        entityId: driver.entityId,
        driverId: PersonUtils.getEntityId(driver, 'driver', productType),
        productType,
        person: {
          ...driver.person,
          // Bug filed with DSM over this issue. We can't send empty string.
          middleName: driver.person?.middleName || null,
          gender: ['M', 'F'].includes(String(driver.person?.gender))
            ? driver.person?.gender
            : null,
          suffix: driver?.person?.suffix || null,
          dateOfBirth: MaskHelper.maskPartiallyMaskedDob(
            driver?.person?.dateOfBirth
          ),
        },
        associateNumber: driver.associateNumber,
        driverType: 'Driver',
        driverOccupation: driver.driverOccupation,
        employmentInformation: driver.employmentInformation,
        endorsementDate: driver.endorsementDate?.includes('/')
          ? DateUtils.formatDateToDSM(driver.endorsementDate)
          : driver.endorsementDate,
        goodStudent: driver.goodStudent,
        hasGraduatedCollege: driver.hasGraduatedCollege,
        hasMotorcycleEndorsement: driver.hasMotorcycleEndorsement,
        infractions: BuildDriverRequestUtil.sanitizeInfractions(driver.infractionsByProduct?.[product.type]),
        isFinancialFilingRequired: false,
        isGenderXSelected: false,
        isLivingWithNWInsuredParent: driver.isLivingWithNWInsuredParent,
        isNationwideEmployee: driver.isNationwideEmployee,
        licenseNumber: driver.licenseNumber,
        licenseState: driver.licenseState || quoteState,
        numberOfYearsOfBoatingExperience:
          driver.numberOfYearsOfBoatingExperience,
        prefillId: driver.prefillId,
        relationToPrimaryNamedInsured: driver.relationToPrimaryNamedInsured,
        studentAway: driver.studentAway,
        yearsOfMotorcycleAndOffRoadExp: driver.yearsOfMotorcycleAndOffRoadExp,
      } as DriverEntity,
    };
    // ^^ Thank you for maintaining alphabetical order.^^
    const memberDriverRole = driver.driverRoles?.find(
      (m) => m.productType === productType
    );
    if (!!memberDriverRole) {
      request.driver.driverType = memberDriverRole?.driverType;
      request.driver.reasonNonDriver = memberDriverRole?.reasonNonDriver;
    }

    if (
      (featureFlags.showGenderX && productType === 'PersonalAuto') ||
      (featureFlags.showGenderXPowersports &&
        ['Boat', 'MSA', 'RV'].includes(productType))
    ) {
      if (!driver.person?.gender || driver.person?.gender === 'X') {
        request.driver.nonSpecifiedGender = true;
      }

      if (request.driver.nonSpecifiedGender && request.driver.person) {
        request.driver.person.gender = null;
      }
    } else if (
      (!driver.person?.gender || driver.person?.gender === 'X') &&
      request.driver.person
    ) {
      request.driver.person.gender = 'M';
      request.driver.isGenderXSelected = true;
    }

    /* response.driver.studentAway appears differently from other discounts...
    "eligibleDiscounts":["Student living over 100 miles away without a car=false","Good Student=false","Driver Training=false"],"
 */

    if (
      GeneralUtils.isBoolean(driver?.usArmedForces) &&
      driver.driverTraining === true
    ) {
      request.driver.usArmedForces = driver.usArmedForces;
    }

    if (
      GeneralUtils.isBoolean(driver?.mtNationalGuard) &&
      (driver.driverTraining === true || driver.safetyCourse)
    ) {
      request.driver.mtNationalGuard = driver.mtNationalGuard;
    }

    let requestEligibleDiscounts: EligibleDiscountsEntity[] = [];

    if (
      driver?.eligibleDiscountIds?.[productType] &&
      (driver.eligibleDiscountIds?.[productType] as EligibleDiscountsEntity[])
        .length > 0
    ) {
      requestEligibleDiscounts = this.getExistingEligibleDiscounts(
        driver,
        productType,
        featureFlags
      );
    } else {
      requestEligibleDiscounts = this.getNewEligibleDiscounts(
        driver,
        featureFlags,
        product
      );
    }

    requestEligibleDiscounts = this.eliminateEmptyStringDiscountValues(requestEligibleDiscounts);

    if (driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI) {
      if (driver?.driverOccupation !== undefined) {
        request.driver.driverOccupation = driver.driverOccupation || null;
      }
    }

    request.driver.employmentInformation = {
      currentEmployment:
        driver?.employmentInformation?.currentEmployment ||
        'GE12MoNotInclStudent_Ext',
      fullTimeEmployment:
        driver?.employmentInformation?.fullTimeEmployment || 'GE_12mo',
    };

    if (featureFlags.healthCarePlan) {
      // Must default for the initial addDriver because we haven't asked yet.
      // TODO: This might be a problem; it remains pre-populated at people page.
      // As an extra wrinkle: This field is either Mandatory or Forbidden, we MUST NOT send it when not required.
      if (
        ['PrimaryNamedInsured', 'Spouse', 'Child', 'OtherRelative'].includes(
          request.driver.relationToPrimaryNamedInsured as string
        )
      ) {
        if (driver.healthCarePlan) {
          request.driver.healthCarePlan = driver.healthCarePlan;
        } else {
          request.driver.healthCarePlan = 'QualifyingHealthCoverage';
        }
      } else if (request.driver.healthCarePlan) {
        delete request.driver.healthCarePlan;
      }
    } else if (request.driver.healthCarePlan) {
      delete request.driver.healthCarePlan;
    }

    if (typeof driver.isPersonalInjuryProtectionExcluded === 'boolean') {
      request.driver.isPersonalInjuryProtectionExcluded =
        driver.isPersonalInjuryProtectionExcluded;
    }

    if (driver.membership) {
      request.driver.membership = driver.membership;
    }

    if (GeneralUtils.isBoolean(driver?.driverTraining)) {
      request.driver.driverTraining = driver?.driverTraining;
      if (driver?.driverTraining && driver.trainingCourseCompletionDate) {
        request.driver.trainingCourseCompletionDate =
          driver.trainingCourseCompletionDate
            ? DateUtils.formatDateToDSM(driver.trainingCourseCompletionDate)
            : undefined;
      }
    }

    if (GeneralUtils.isBoolean(driver?.advancedDriverTraining)) {
      request.driver.advancedDriverTraining = driver?.advancedDriverTraining;
      if (
        driver?.advancedDriverTraining &&
        driver.advancedTrainingCourseCompletionDate
      ) {
        request.driver.advancedTrainingCourseCompletionDate =
          driver.advancedTrainingCourseCompletionDate
            ? DateUtils.formatDateToDSM(
                driver.advancedTrainingCourseCompletionDate
              )
            : undefined;
      }
    }

    if (GeneralUtils.isBoolean(driver?.safetyCourse)) {
      request.driver.safetyCourse = driver?.safetyCourse;
      if (driver?.safetyCourse && driver.trainingCourseCompletionDate) {
        request.driver.trainingCourseCompletionDate =
          driver.trainingCourseCompletionDate
            ? DateUtils.formatDateToDSM(driver.trainingCourseCompletionDate)
            : undefined;
      }
    }

    const age = PersonUtils.CalculateAgeFromDateOfBirth(
      DateUtils.formatDsmDateToOld(driver.person?.dateOfBirth as string)
    );

    // ageFirstLicensed is a required field if the person age > 14
    // minimum accepted age for ageFirstLicensed = 14

    if (age < 14) {
      delete request.driver.ageFirstLicensed;
    } else if (driver.ageFirstLicensed) {
      request.driver.ageFirstLicensed = driver.ageFirstLicensed;
    } else if (age >= 16) {
      request.driver.ageFirstLicensed = 16;
    } else if (age >= 14) {
      request.driver.ageFirstLicensed = 14;
    } else {
      delete request.driver.ageFirstLicensed;
    }

    let yearsOfExperience = age - (driver.ageFirstLicensed || age);

    if (yearsOfExperience < 0) yearsOfExperience = 0;
    if (
      featureFlags.yearsOfMotorcycleAndOffRoadExp &&
      driver.yearsOfMotorcycleAndOffRoadExp === undefined
    ) {
      request.driver.yearsOfMotorcycleAndOffRoadExp = yearsOfExperience;
    }
    if (
      productType === 'Boat' &&
      driver.numberOfYearsOfBoatingExperience === undefined
    ) {
      request.driver.numberOfYearsOfBoatingExperience = yearsOfExperience;
    }

    if (
      featureFlags.hasMotorcycleEndorsement &&
      !GeneralUtils.isBoolean(driver.hasMotorcycleEndorsement)
    ) {
      request.driver.hasMotorcycleEndorsement = true;
      request.driver.endorsementDate = DateUtils.getCurrentDateAsString();
    }

    return {
      ...request,
      driver: {
        ...request.driver,
        eligibleDiscounts: requestEligibleDiscounts,
      },
      correlationId,
    } as DriverRequest;
  }

  static sanitizeInfractions(infractions: DriverIncidentEntity[] | undefined): DriverIncidentEntity[] | undefined {
    // Return undefined if empty, do not return empty.
    if (!infractions?.length) return undefined;
    return infractions.map((infraction) => ({
      infractionDesc: infraction.infractionDesc,
      occurrenceDate: infraction.occurrenceDate,
      infractionType: infraction.infractionType,
      infractionId: (infraction.infractionId.startsWith('NEW-') ? undefined : infraction.infractionId) as string,
      overrideInfraction: !!infraction.overrideReason,
      overrideReason: infraction.overrideReason || undefined,
      infractionPoints: infraction.infractionPoints,
      isChargeable: infraction.isChargeable,
    }));
  }

  static eliminateEmptyStringDiscountValues(
    discounts: EligibleDiscountsEntity[]
  ): EligibleDiscountsEntity[] {
    return discounts.map((discount) => {
      if (!discount.selectedOptionValue) {
        const modified = { ...discount };
        delete (modified as any).selectedOptionValue;
        return modified;
      } else {
        return discount;
      }
    });
  }

  static replaceEligibleDiscount(
    discounts: Nullable<EligibleDiscountsEntity[]>,
    key: EligibleDiscountId,
    value: string
  ): Nullable<EligibleDiscountsEntity[]> {
    if (!discounts) {
      return discounts;
    }
    const index = discounts.findIndex((d) => d.eligibleDiscountId === key);
    if (index < 0) {
      return discounts;
    }
    const replacements = [...discounts];
    replacements[index] = {
      ...discounts[index],
      selectedOptionValue: value,
    };
    return replacements;
  }

  static removeEligibleDiscount(
    discounts: Nullable<EligibleDiscountsEntity[]>,
    key: EligibleDiscountId
  ): Nullable<EligibleDiscountsEntity[]> {
    if (!discounts) {
      return discounts;
    }
    const index = discounts.findIndex((d) => d.eligibleDiscountId === key);
    if (index < 0) {
      return discounts;
    }
    return discounts.filter((discount) => discount.eligibleDiscountId !== key);
  }

  static getRelationshipToPni(
    driver: DriverEntity,
    existingDrivers: Dictionary<DriverEntity>
  ): RelationToPNIType {
    const marriedDrivers = Object.values(existingDrivers).filter(
      (d) => d?.person?.maritalStatus === 'M'
    );
    if (
      marriedDrivers.find(
        (d) => d?.relationToPrimaryNamedInsured === 'PrimaryNamedInsured'
      )
    ) {
      if (driver.person?.maritalStatus === 'M') {
        if (marriedDrivers.length < 2) {
          return 'Spouse';
        } else {
          return 'Other';
        }
      } else {
        return 'Child';
      }
    } else {
      if (driver.person?.maritalStatus === 'M') {
        return 'Other';
      } else {
        return 'Child';
      }
    }
  }

  static getExistingEligibleDiscounts(
    driver: DriverEntity,
    productType: ProductType,
    featureFlags: FeatureFlagsModel
  ): EligibleDiscountsEntity[] {
    const memberDriverRole = driver.driverRoles?.find(
      (m) => m.productType === productType
    );
    if (memberDriverRole?.driverType !== 'Driver') {
      return [];
    }
    const returnEligibleDiscounts = [
      ...(driver.eligibleDiscountIds?.[productType] || []),
    ];
    for (const discount of driver.eligibleDiscountIds?.[productType] || []) {
      switch (discount.eligibleDiscountId) {
        case 'AdvancedDriverTraining': {
          if (GeneralUtils.isBoolean(driver.advancedDriverTraining)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = driver.advancedDriverTraining
              ? {
                  ...discount,
                  selectedOptionValue: driver.advancedDriverTraining + '',
                  qualifyingInformation: {
                    ...discount.qualifyingInformation,
                    advancedTrainingCourseCompletionDate:
                      driver.advancedTrainingCourseCompletionDate
                        ? DateUtils.formatDateToDSM(
                            driver.advancedTrainingCourseCompletionDate
                          )
                        : undefined,
                  },
                }
              : {
                  ...discount,
                  selectedOptionValue: driver.advancedDriverTraining + '',
                };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'Associate': {
          if (GeneralUtils.isBoolean(driver.isNationwideEmployee)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = driver.isNationwideEmployee
              ? {
                  ...discount,
                  selectedOptionValue: driver.isNationwideEmployee + '',
                  qualifyingInformation: {
                    ...discount.qualifyingInformation,
                    associateNumber: driver.associateNumber
                      ? +driver.associateNumber
                      : undefined,
                  },
                }
              : {
                  ...discount,
                  selectedOptionValue: driver.isNationwideEmployee + '',
                };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'DriverTraining': {
          if (GeneralUtils.isBoolean(driver.driverTraining)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = driver.driverTraining
              ? {
                  ...discount,
                  selectedOptionValue: driver.driverTraining + '',
                  qualifyingInformation: {
                    ...discount.qualifyingInformation,
                    trainingCourseCompletionDate:
                      driver.trainingCourseCompletionDate
                        ? DateUtils.formatDateToDSM(
                            driver.trainingCourseCompletionDate
                          )
                        : undefined,
                  },
                }
              : {
                  ...discount,
                  selectedOptionValue: driver.driverTraining + '',
                };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'GoodStudent': {
          // Must not send when criteria for bool is false or not met (regardless of selectedOptionValue)
          if (
            !PersonUtils.isGoodStudentDiscountApplicable(
              featureFlags,
              driver as MemberModel,
              [{ type: productType } as ProductModel]
            )
          ) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            if (idx >= 0) {
              returnEligibleDiscounts.splice(idx, 1);
            }
          } else if (GeneralUtils.isBoolean(driver.goodStudent)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.goodStudent + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'StudentAway': {
          // Must not send when criteria for bool is false or not met (regardless of selectedOptionValue)
          if (
            !PersonUtils.isResidentStudentDiscountApplicable(
              featureFlags,
              driver as MemberModel,
              [{ type: productType } as ProductModel]
            )
          ) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            if (idx >= 0) {
              returnEligibleDiscounts.splice(idx, 1);
            }
          } else if (GeneralUtils.isBoolean(driver.studentAway)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.studentAway + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'GroupOccupation': {
          if (driver.driverOccupation) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.driverOccupation + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'Membership': {
          if (driver.membership) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.membership + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'MTNationalGuard': {
          if (GeneralUtils.isBoolean(driver.mtNationalGuard)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.mtNationalGuard + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
        case 'SafetyCourse': {
          if (
            !PersonUtils.isSafetyCourseApplicable(
              featureFlags,
              driver as MemberModel
            )
          )
            break;

          const idx = returnEligibleDiscounts.indexOf(discount);
          const updatedDiscount = this.buildUpdatedSafetyCourseDiscount(
            discount,
            driver
          );
          if (idx > -1) {
            returnEligibleDiscounts.splice(idx, 1);
          }
          returnEligibleDiscounts.push(updatedDiscount);
          break;
        }

        case 'USArmedForces': {
          if (GeneralUtils.isBoolean(driver.usArmedForces)) {
            const idx = returnEligibleDiscounts.indexOf(discount);
            const updatedDiscount = {
              ...discount,
              selectedOptionValue: driver.usArmedForces + '',
            };
            if (idx > -1) {
              returnEligibleDiscounts.splice(idx, 1);
            }
            returnEligibleDiscounts.push(updatedDiscount);
          }
          break;
        }
      }
    }
    return returnEligibleDiscounts;
  }

  static getNewEligibleDiscounts(
    driver: DriverEntity,
    featureFlags: FeatureFlagsModel,
    product: ProductModel
  ): EligibleDiscountsEntity[] {
    const memberDriverRole = driver.driverRoles?.find(
      (m) => m.productType === product.type
    );
    if (memberDriverRole?.driverType !== 'Driver') {
      return [];
    }
    const requestEligibleDiscounts: EligibleDiscountsEntity[] = [];
    if (
      featureFlags.requireAdvancedAccidentPrevention &&
      GeneralUtils.isBoolean(driver.advancedDriverTraining)
    ) {
      driver.advancedTrainingCourseCompletionDate =
        driver.advancedTrainingCourseCompletionDate
          ? DateUtils.formatDateToDSM(
              driver.advancedTrainingCourseCompletionDate
            )
          : undefined;
    }
    if (
      featureFlags.isNationwideAssociateVisible &&
      GeneralUtils.isBoolean(driver.isNationwideEmployee)
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        eligibleDiscountId: 'Associate',
        productType: driver.productType,
        selectedOptionValue: driver.isNationwideEmployee + '',
        qualifyingInformation: {
          associateNumber: driver.associateNumber
            ? +driver.associateNumber
            : undefined,
        },
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      ((driver.productType === 'PersonalAuto' &&
        featureFlags.isEligibleDiscountDriverRequest) ||
        (driver.productType === 'MSA' &&
          featureFlags.msaDriverTrainingCourse) ||
        (driver.productType === 'RV' && featureFlags.rvDriverTrainingCourse)) &&
      GeneralUtils.isBoolean(driver.driverTraining)
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        eligibleDiscountId: 'DriverTraining',
        productType: driver.productType,
        selectedOptionValue: driver.driverTraining + '',
        qualifyingInformation: {
          trainingCourseCompletionDate: driver.trainingCourseCompletionDate
            ? DateUtils.formatDateToDSM(driver.trainingCourseCompletionDate)
            : undefined,
        },
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      GeneralUtils.isBoolean(driver.goodStudent) &&
      PersonUtils.isGoodStudentDiscountApplicable(
        featureFlags,
        driver as MemberEntity,
        [product]
      )
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        name: 'Good Student',
        eligibleDiscountId: 'GoodStudent',
        productType: driver.productType,
        selectedOptionValue: driver.goodStudent + '',
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      (GeneralUtils.isBoolean(driver.studentAway) &&
        PersonUtils.isResidentStudentDiscountApplicable(
          featureFlags,
          driver as MemberEntity,
          [product]
        )) ||
      false
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        name: 'Student Away',
        eligibleDiscountId: 'StudentAway',
        productType: driver.productType,
        selectedOptionValue: driver.studentAway + '',
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      featureFlags.driverOccupation &&
      driver.driverOccupation &&
      driver.productType === 'PersonalAuto'
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        eligibleDiscountId: 'GroupOccupation',
        productType: driver.productType,
        selectedOptionValue: driver.driverOccupation + '',
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      featureFlags.showMembership &&
      FeatureFlagUtils.FlagAppliesToProductType(
        'showMembership',
        product.type as ProductType
      ) &&
      Boolean(driver.membership)
    ) {
      const newDiscount: EligibleDiscountsEntity = {
        eligibleDiscountId: 'Membership',
        productType: driver.productType,
        selectedOptionValue: driver.membership + '',
      };
      requestEligibleDiscounts.push(newDiscount);
    }
    if (
      PersonUtils.isSafetyCourseApplicable(featureFlags, driver as MemberModel)
    ) {
      const newDiscount: EligibleDiscountsEntity =
        this.buildNewSafetyCourseDiscount(driver);
      requestEligibleDiscounts.push(newDiscount);
    }

    return requestEligibleDiscounts;
  }

  static buildUpdatedSafetyCourseDiscount(
    discount: EligibleDiscountsEntity,
    driver: DriverEntity
  ): EligibleDiscountsEntity {
    return {
      ...discount,
      selectedOptionValue: driver.safetyCourse + '',
      qualifyingInformation: {
        ...discount.qualifyingInformation,
        trainingCourseCompletionDate: driver.trainingCourseCompletionDate
          ? DateUtils.formatDateToDSM(driver.trainingCourseCompletionDate)
          : undefined,
      },
    };
  }

  static buildNewSafetyCourseDiscount(
    driver: DriverEntity
  ): EligibleDiscountsEntity {
    return {
      eligibleDiscountId: 'SafetyCourse',
      productType: driver.productType,
      qualifyingInformation: {
        trainingCourseCompletionDate: driver.trainingCourseCompletionDate
          ? DateUtils.formatDateToDSM(driver.trainingCourseCompletionDate)
          : undefined,
      },
      selectedOptionValue: driver.safetyCourse + '',
    };
  }
}
