import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromDiscounts from '@core/store/entities/discount/discount.reducers';
import { DiscountEntity, GroupedDiscountsObject } from './discount.entity';
import { getSelectedProductTypes } from '../product/product.selectors';

export const getDiscountState = createSelector(
  fromCore.getCoreState,
  (state) => state.discounts
);

export const {
  selectAll: getAllDiscounts,
  selectEntities: getDiscountEntities,
  selectTotal: getTotalDiscounts,
} = fromDiscounts.adapter.getSelectors(getDiscountState);

export const getSelectedDiscounts = createSelector(
  getAllDiscounts,
  (discounts) =>
    discounts
      .filter((discount) => discount.isDiscountApplied)
      .map((discount) => DiscountUtils.helpDiscountName(discount))
);

export const getDiscountsForSelectedProducts = createSelector(
  getAllDiscounts,
  getSelectedProductTypes,
  (discounts, products) =>
    discounts.filter((discount) => {
      return (
        discount.isDiscountApplied && products.includes(discount.productType)
      );
    })
);

// Return unique discounts, filtering out those with the same name that may have been applied to multiple vehicles/drivers
export const getFilteredAppliedDiscounts = createSelector(
  getSelectedDiscounts,
  (discounts) => DiscountUtils.filterDuplicates(discounts)
);

export const getGroupedSelectedDiscounts = createSelector(
  getSelectedDiscounts,
  (discounts) => {
    const grouped = DiscountUtils.groupDiscountsByProduct(discounts);

    return Object.values(grouped);
  }
);

const PAYMENT_METHOD_DISCOUNT_NAMES = [
  'Paid In Full Discount',
  'Recurring EFT Discount',
];

export const getAppliedPaymentMethodDiscounts = createSelector(
  getDiscountsForSelectedProducts,
  (discounts) => {
    discounts = discounts.filter(
      (d) =>
        d.isDiscountApplied &&
        PAYMENT_METHOD_DISCOUNT_NAMES.includes(d.description)
    );
    // Eliminate duplicates by description.
    discounts = discounts.filter((d, p) => {
      for (let i = p; i-- > 0; ) {
        if (discounts[i].description === d.description) {
          return false;
        }
      }
      return true;
    });
    return discounts;
  }
);

export class DiscountUtils {
  static helpDiscountName(discount: DiscountEntity): DiscountEntity {
    const NAME_REPLACEMENTS = [
      ['AgeofConstruction', 'Age of Construction'],
      ['HomeAndCarDiscount', 'Home and Car'],
      ['ClaimFree', 'Claim Free Discount'],
      ['NationwideAssociateDiscount', 'Associate Discount'],
      ['MultiLine', 'Multi Line Discount'],
      ['ProtectiveDevice', 'Protective Device Discount'],
      ['HomeRenovationDiscount', 'Home Renovation Discount'],
      ['WindMitigation', 'Windstorm Mitigation Credit'],
      ['FortifiedHome', 'Fortified Home Discount'],
      ['HomeFinancialDiscount', 'Home Financial Discount'],
      ['MultiLineDiscount', 'Multi Line Discount'],
      ['WindStormProtectiveDevice', 'Windstorm Protective Device Credit'],
      ['HomePurchaseDiscount', 'Home Purchase Discount'],
      ['FarmBureau', 'Farm Bureau'],
      ['IntraAgency', 'Intra Agency'],
      ['GatedCommunity', 'Gated Community'],
      ['PriorInsurance', 'Prior Insurance'],
      ['MaritalStatus', 'Marital Status'],
      ['SmartHomeDiscount', 'SmartHome Discount'],
      ['MembershipDiscount', 'Membership Discount'],
      ['Paperless', 'Paperless Discount'],
      ['GoodPayer Discount', 'Good Payer Discount'],
      ['FirePremiumTax', 'Fire Premium Tax'],
      ['TelematicsPropertyDiscount', 'Telematics Property Discount'],
    ];

    for (const replacement of NAME_REPLACEMENTS) {
      if (discount.description === replacement[0]) {
        return {
          ...discount,
          description: replacement[1],
        };
      }
    }
    return discount;
  }
  static groupDiscountsByProduct(
    discounts: DiscountEntity[]
  ): GroupedDiscountsObject {
    return discounts.reduce((group, discount) => {
      const newDiscount = { ...discount };
      const product = newDiscount.productType;

      if (!group[product]) {
        group[product] = [{ product, discounts: [newDiscount] }];
      } else {
        group[product][0].discounts.push(newDiscount);
      }

      return group;
    }, {} as GroupedDiscountsObject);
  }

  // 'Duplicate' discounts are those with the same description, but different coverableIds
  // ex. 'Anti Theft Devices' discounts on two separate vehicles
  static filterDuplicates(discounts: DiscountEntity[]): DiscountEntity[] {
    const copiedDiscounts = [...discounts];
    copiedDiscounts.forEach((disc) => {
      const duplicates = copiedDiscounts.filter(
        (d) =>
          d.description === disc.description &&
          d.productType === disc.productType &&
          d.coverableId !== disc.coverableId
      );
      duplicates.forEach((duplicate) => {
        copiedDiscounts.splice(copiedDiscounts.indexOf(duplicate), 1);
      });
    });
    return copiedDiscounts;
  }
}
