import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UwReferralModalComponent } from './uw-referral-modal.component';
import { DatepickerInputModule } from '@shared/components/datepicker-input/datepicker-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';

@NgModule({
  declarations: [UwReferralModalComponent],
  exports: [UwReferralModalComponent],
  imports: [
    CommonModule,
    CommonFormModule,
    ReactiveFormsModule,
    FormsModule,
    DatepickerInputModule,
    SharedComponentsModule,
    SharedDirectivesModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UwReferralModalModule {}
