<nwx-tasks-modal-form
  [tasks]="tasks$ | async"
  [products]="(products$ | async) || []"
  [agencyState]="agencyState$ | async"
  [accountId]="accountId$ | async"
  [pniPageModel]="pniPageModel$ | async"
  [membersPageModel]="membersPageModel$ | async"
  [autoPageModel]="(autoPageModel$ | async) || {}"
  [msaPageModel]="(msaPageModel$ | async) || {}"
  [boatPageModel]="(boatPageModel$ | async) || {}"
  [rvPageModel]="(rvPageModel$ | async) || {}"
  [uwActivities]="(uwActivities$ | async) || []"
  (closeModal)="onCloseModal()"
></nwx-tasks-modal-form>
