import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { AccountRequest } from '../models/api/request/account-request.model';
import { AccountResponse, sanitizeAccountResponse } from '../models/api/response/account-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountAdapter extends DsmAdapter {
  createAccount(request: AccountRequest): Observable<AccountResponse> {
    return this.request(
      sanitizeAccountResponse,
      'Account',
      'POST',
      '/accounts',
      'create-account',
      {
        body: request,
        extraLocks: ['initiate'],
      }
    );
  }
}
