<h1 class="bolt-heading-md bolt-serif">Review policies</h1>
<bolt-tabset
  borderless
  #boltTabGroup
  (bolt-tab-changed)="onTabChange($event)"
  [attr.id]="'reviewPoliciesTabGroup'"
>
  <bolt-tablabel [attr.id]="'coveragesTab'">Coverages</bolt-tablabel>
  <bolt-tabpanel class="custom-bolt-tab">
    <nwx-coverage-display-container
      (editTelematicsClicked)="setTelematicsTabActive()"
    ></nwx-coverage-display-container>
  </bolt-tabpanel>
  <bolt-tablabel [attr.id]="'discountsTab'"
    >Discounts and surcharges</bolt-tablabel
  >
  <bolt-tabpanel class="custom-bolt-tab">
    <nwx-discount-display-container></nwx-discount-display-container>
  </bolt-tabpanel>
  <bolt-tablabel
    [attr.id]="'telematicsTab'"
    *ngIf="(isAutoProductSelected$ | async) && !(isTelematicsDeclined$ | async)"
    >Telematics</bolt-tablabel
  >
  <bolt-tabpanel class="custom-bolt-tab" *ngIf="(isAutoProductSelected$ | async) && !(isTelematicsDeclined$ | async)">
    <nwx-telematics-container></nwx-telematics-container>
  </bolt-tabpanel>
  <bolt-tablabel
    [attr.id]="'reportsTab'"
    *ngIf="reportsAndUWApplicable$ | async"
    >Reports and underwriting</bolt-tablabel
  >
  <bolt-tabpanel
    class="custom-bolt-tab reports"
    *ngIf="reportsAndUWApplicable$ | async"
  >
    <nwx-uw-reports (scrollToTasks)="scrollToTasks.emit()"></nwx-uw-reports>
  </bolt-tabpanel>
</bolt-tabset>
