import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({
  providedIn: 'root',
})
export class Base64Utils {
  constructor(private window: Window) {}

  decodeBase64(encoded: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      try {
        const xhr = new (this.window as any).XMLHttpRequest();

        const dataUrl = 'data:application/octet-stream;base64,' + encoded;
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', dataUrl);
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) {
            return;
          }
          if (xhr.response) {
            resolve(xhr.response);
          } else {
            reject('Failed to decode');
          }
        };
        xhr.send();
      } catch (e) {
        reject(e);
      }
    });
  }

  async mergePdfs(pdfBlob1: Blob, pdfBlob2: Blob): Promise<Blob> {
    // Convert blobs to ArrayBuffers
    const arrayBuffer1 = await pdfBlob1.arrayBuffer();
    const arrayBuffer2 = await pdfBlob2.arrayBuffer();

    // Load PDF documents
    const pdfDoc1 = await PDFDocument.load(arrayBuffer1);
    const pdfDoc2 = await PDFDocument.load(arrayBuffer2);

    // Create a new PDF document
    const mergedPdf = await PDFDocument.create();

    // Copy pages from the first document
    const copiedPages1 = await mergedPdf.copyPages(
      pdfDoc1,
      pdfDoc1.getPageIndices()
    );
    copiedPages1.forEach((page) => mergedPdf.addPage(page));

    // Copy pages from the second document
    const copiedPages2 = await mergedPdf.copyPages(
      pdfDoc2,
      pdfDoc2.getPageIndices()
    );
    copiedPages2.forEach((page) => mergedPdf.addPage(page));

    // Set pdf title
    mergedPdf.setTitle('Express Sales Quote Letters 2.0');

    // Serialize the merged document
    const mergedPdfBytes = await mergedPdf.save();

    // Create a new blob from the merged PDF bytes
    const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

    return mergedBlob;
  }

  decodeBase64ToBlobUrl(
    encoded: string,
    contentType: string,
    supplementBlob?: Blob
  ): Promise<string> {
    return this.decodeBase64(encoded).then(async (bin) => {
      const blob = new (this.window as any).Blob([bin], { type: contentType });
      if (!!supplementBlob?.size) {
        const mergedBlob = await this.mergePdfs(blob, supplementBlob);
        return (this.window as any).URL.createObjectURL(mergedBlob);
      } else {
        return (this.window as any).URL.createObjectURL(blob);
      }
    });
  }
}
