import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { LeakDetectionFormComponent } from './leak-detection-form/leak-detection-form.component';
import { LeakDetectionModalComponent } from './leak-detection-modal/leak-detection-modal.component';
import { SharedComponentsModule } from '@shared/components/shared-components.module';

@NgModule({
  declarations: [LeakDetectionModalComponent, LeakDetectionFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, CommonFormModule, SharedComponentsModule],
  exports: [LeakDetectionModalComponent, LeakDetectionFormComponent],
})
export class LeakDetectionModule {}
