import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { CoveredLocationModel } from '@entities/covered-location/covered-location.model';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'nwx-leak-detection-form',
  templateUrl: './leak-detection-form.component.html',
  styleUrl: './leak-detection-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeakDetectionFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() coveredLocation!: Partial<CoveredLocationModel>;
  @Input() smartHome!: Nullable<EligibleDiscountsEntity>;
  @Input() isModal: boolean = false;

  @Output() smartHomeValueChange = new EventEmitter<EligibleDiscountsEntity>();
  @Output() coveredLocationValueChange = new EventEmitter<
    Partial<CoveredLocationModel>
  >();
  @Output() formReady = new EventEmitter<FormGroup>();

  highlightAcknowledgementRequired = false;
  showAcknowledgementHighlight = false;

  form!: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.coveredLocation, this.smartHome);

    this.form
      .get('waterProtectionDeviceRequirementCommunicated')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((value) => {
        this.chooseShowAcknowledgementHighlight();
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(this.hasCoveredLocationChanges)
      )
      .subscribe((changes) => {
        this.emitCoveredLocationChanges(changes);
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(this.hasSmartHomeChanges)
      )
      .subscribe((changes) => {
        this.emitSmartHomeChanges(changes);
      });

    this.formReady.emit(this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.coveredLocation) {
      this.form.patchValue({
        waterProtectionDeviceRequirementCommunicated: !!changes.coveredLocation
          .currentValue?.waterProtectionDeviceRequirementCommunicated
          ? true
          : undefined,
      });
    }
    if (this.form && changes.smartHome) {
      this.form.patchValue({
        selectedOptionValue: changes.smartHome.currentValue.selectedOptionValue,
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(
    coveredLocation?: Partial<CoveredLocationModel>,
    smartHome?: Nullable<EligibleDiscountsEntity>
  ): FormGroup {
    return this.fb.group({
      selectedOptionValue: this.fb.control(
        smartHome?.selectedOptionValue ?? null,
        []
      ),
      waterProtectionDeviceRequirementCommunicated: this.fb.control(
        !!coveredLocation?.waterProtectionDeviceRequirementCommunicated
          ? true
          : undefined,
        [(control) => this.validateAcknowledgement(control)]
      ),
      doNotDisable: this.fb.control(null, []), // DO NOT DELETE
    });
  }

  emitCoveredLocationChanges(changes: Partial<CoveredLocationModel>): void {
    this.coveredLocationValueChange.emit({
      productType: this.coveredLocation?.productType,
      waterProtectionDeviceRequirementCommunicated:
        GeneralUtils.parseBooleanFromString(
          changes?.waterProtectionDeviceRequirementCommunicated
        ),
    });
  }

  emitSmartHomeChanges(changes: EligibleDiscountsEntity): void {
    this.smartHomeValueChange.emit(changes);
  }

  hasCoveredLocationChanges(a: any, b: any): boolean {
    return (
      a.waterProtectionDeviceRequirementCommunicated ===
      b.waterProtectionDeviceRequirementCommunicated
    );
  }

  hasSmartHomeChanges(a: any, b: any): boolean {
    return a.selectedOptionValue === b.selectedOptionValue;
  }

  highlightRequiredErrors(): void {
    this.highlightAcknowledgementRequired = true;
    this.chooseShowAcknowledgementHighlight();
  }

  private chooseShowAcknowledgementHighlight(): void {
    let newValue: boolean = this.showAcknowledgementHighlight;
    if (this.highlightAcknowledgementRequired) {
      newValue = !this.form.get('waterProtectionDeviceRequirementCommunicated')
        ?.value;
    }
    if (newValue !== this.showAcknowledgementHighlight) {
      this.showAcknowledgementHighlight = newValue;
      this.changeDetector.markForCheck();
    }
  }

  private validateAcknowledgement(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return { fullVerbiageProvided: '' };
    }
    return null;
  }
}
