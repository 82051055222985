import { Injectable } from '@angular/core';
import { DsmAdapter } from './dsm.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { Observable } from 'rxjs';
import { ObjectValidator } from '@core/helper/object-validator';

// PC Typelist "UWIssueHistoryStatus"
export type UwIssuesStatus =
  | 'Approved'
  | 'ChangeEffDate'
  | 'Created'
  | 'Expired'
  | 'Rejected'
  | 'Removed'
  | 'Reopened';

export interface UwIssuesResponse {
  underwritingIssueId: number;
  category: string;
  status: UwIssuesStatus;
  description: string;
}

export function sanitizeUwIssuesStatus(src: unknown): UwIssuesStatus {
  if (typeof src !== 'string') {
    return 'Created';
  }
  switch (src) {
    case 'Approved':
    case 'ChangeEffDate':
    case 'Created':
    case 'Expired':
    case 'Rejected':
    case 'Removed':
    case 'Reopened':
      return src;
  }
  return 'Created';
}

export function sanitizeUwIssuesResponses(src: unknown): UwIssuesResponse[] {
  if (!(src instanceof Array)) return [];
  return src.map((response) =>
    ObjectValidator.forceSchema<UwIssuesResponse>(response, {
      underwritingIssueId: 'number',
      category: 'string',
      status: sanitizeUwIssuesStatus,
      description: 'string',
    })
  );
}

@Injectable({
  providedIn: 'root',
})
export class UwIssuesAdapter extends DsmAdapter {
  getUwIssues(
    productType: ProductType,
    quoteId: string
  ): Observable<UwIssuesResponse[]> {
    return this.request(
      sanitizeUwIssuesResponses,
      productType,
      'GET',
      `/quotes/${quoteId}/underwriting-issues`,
      'uw-issues'
    );
  }
}
