import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RouterActions } from '../actions';
import { RouterSelectors } from '../selectors';
import { LoadingService } from '@core/services/loading.service';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store,
    private ngZone: NgZone,
    private loadingService: LoadingService
  ) {}

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.go),
        tap(({ payload, showSpinner }) => {
          this.ngZone.run(() => {
            const endLoading =
              typeof showSpinner === 'string'
                ? () => this.loadingService.endLoading(showSpinner)
                : showSpinner
                ? this.loadingService.beginLoading('navigate', 'Loading')
                : null;
            this.router
              .navigate(payload.path, {
                queryParams: payload.query,
                ...payload.extras,
              })
              .then(() => endLoading?.())
              .catch(() => endLoading?.());
          });
        })
      ),
    { dispatch: false }
  );

  navigateConditionaly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.goConditionaly),
      switchMap((request) =>
        this.store
          .select(
            RouterSelectors.getConditionalRoute(request.page, request.query)
          )
          .pipe(
            take(1),
            map((payload) => RouterActions.go({ payload }))
          )
      )
    )
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.forward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );
}
