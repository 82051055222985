import { ProductType } from '@core/models/api/dsm-types';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { createAction, props } from '@ngrx/store';
import { ProductModel } from '../../entities/product/product.model';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';

export const enum CoveredLocationActionTypes {
  GET_COVERED_LOCATION = '[Covered Location] Get covered location',
  GET_COVERED_LOCATION_SUCCESS = '[Covered Location] Get covered location Success',
  GET_COVERED_LOCATION_FAIL = '[Covered Location] Get covered location Fail',

  UPDATE_COVERED_LOCATION = '[Covered Location] Update covered location',
  UPDATE_COVERED_LOCATION_SUCCESS = '[Covered Location] Update covered location Success',
  UPDATE_COVERED_LOCATION_FAIL = '[Covered Location] Update covered location Fail',

  RESET_COVERED_LOCATION_LOADED = '[Covered Location] Reset covered location loaded',

  REMOVE_COVERED_LOCATION = '[Covered Location] Remove covered location',

  SHOW_LEAK_DETECTION_MODAL = '[Covered Location] Show Leak Detection Modal',
}

export const getCoveredLocation = createAction(
  CoveredLocationActionTypes.GET_COVERED_LOCATION,
  props<{ payload: ProductType }>()
);

export const getCoveredLocationSuccess = createAction(
  CoveredLocationActionTypes.GET_COVERED_LOCATION_SUCCESS,
  props<{ payload: CoveredLocationEntity }>()
);

export const getCoveredLocationFail = createAction(
  CoveredLocationActionTypes.GET_COVERED_LOCATION_FAIL,
  props<{ error: ErrorModel }>()
);

export const updateCoveredLocation = createAction(
  CoveredLocationActionTypes.UPDATE_COVERED_LOCATION,
  props<{
    payload: ProductType;
    includeMsbEstimateNumber: boolean;
    correlationId?: string;
  }>()
);

export const updateCoveredLocationSuccess = createAction(
  CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_SUCCESS,
  props<{ payload: CoveredLocationEntity; correlationId?: string }>()
);

export const updateCoveredLocationError = createAction(
  CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_FAIL,
  props<{ error: ErrorModel; correlationId?: string }>()
);

export const storeCoveredLocation = createAction(
  '[Covered Location] Store covered location',
  props<{ payload: CoveredLocationEntity }>()
);

export const patchCoveredLocationInStore = createAction(
  '[Covered Location] Patch covered location in store',
  props<{ coveredLocation: Partial<CoveredLocationEntity> }>()
);

export const resetCoveredLocationLoaded = createAction(
  CoveredLocationActionTypes.RESET_COVERED_LOCATION_LOADED
);

export const removeCoveredLocation = createAction(
  CoveredLocationActionTypes.REMOVE_COVERED_LOCATION,
  props<{ payload: ProductType }>()
);

export const showLeakDetectionModal = createAction(
  CoveredLocationActionTypes.SHOW_LEAK_DETECTION_MODAL,
  props<{ productType: ProductType }>()
);
