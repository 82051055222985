import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromAccount from '@core/store/entities/account/account.reducer';
import * as fromAddress from '@core/store/entities/address/address.reducer';
import * as fromAgency from '@core/store/entities/agency/agency.reducer';
import * as fromAmfBankAccounts from '@core/store/entities/amf-bank-accounts/amf-bank-accounts.reducer';
import * as fromAnnouncementContent from '@entities/announcement-content/announcement-content.reducer';
import * as fromBilling from '@core/store/entities/billing/billing.reducer';
import * as fromBillingAccount from '@core/store/entities/billing-account/billing-account.reducer';
import * as fromCompraterRouting from '@core/store/entities/comprater-routing/comprater-routing.reducer';
import * as fromCoverageMessages from '@core/store/entities/coverage-message/coverage-message.reducer';
import * as fromCoverageSync from '@core/store/entities/coverage-sync/coverage-sync.reducer';
import * as fromCoverages from '@core/store/entities/coverage/coverage.reducer';
import * as fromCoveredLocation from '@core/store/entities/covered-location/covered-location.reducer';
import * as fromCurrentCarrier from '@core/store/entities/current-carrier/current-carrier.reducer';
import * as fromDigitalIdCardsText from '@core/store/entities/digital-id-cards-text/digital-id-cards-text.reducer';
import * as fromDiscounts from '@core/store/entities/discount/discount.reducers';
import * as fromDocuments from '@core/store/entities/document/document.reducers';
import * as fromDriverVehicleAssignments from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.reducers';
import * as fromDriverVehiclePrefill from '@core/store/entities/driver-vehicle-prefill/driver-vehicle-prefill.reducer';
import * as fromDsm from '@core/store/entities/dsm/dsm.reducer';
import * as fromEligibilityForm from '@app/eligibility/store/eligibility-form.reducer';
import * as fromEligibleDiscounts from '@core/store/entities/eligible-discounts/eligible-discounts.reducer';
import * as fromError from '@core/store/entities/error/error.reducer';
import * as fromEscrow from '@core/store/entities/escrow/escrow.reducer';
import * as fromFeatureFlag from '@core/store/entities/feature-flag/feature-flag.reducer';
import * as fromLayout from '@core/store/entities/layout/layout.reducer';
import * as fromLocationExcludedExposures from '@core/store/entities/exposures/location-excluded-exposures/location-excluded-exposures.reducer';
import * as fromLocationExposures from '@core/store/entities/exposures/location-exposures/location-exposures.reducer';
import * as fromMetaData from '@core/store/entities/metadata/metadata.reducer';
import * as fromModifiers from '@core/store/entities/modifier/modifier.reducers';
import * as fromMortgage from '@core/store/entities/mortgage/mortgage.reducer';
import * as fromHasMortgage from '@core/store/entities/has-mortgage/has-mortgage.reducer';
import * as fromNavigation from '../entities/navigation/navigation.reducer';
import * as fromOtherStructures from '@core/store/entities/other-structures/other-structures.reducer';
import * as fromPageAlert from '@core/store/entities/page-alert/page-alert.reducer';
import * as fromPeople from '@core/store/entities/member/member.reducer';
import * as fromPersona from '@core/store/entities/persona/persona.reducer';
import * as fromPreBindDocuments from '@core/store/entities/pre-bind-documents/pre-bind-documents.reducer';
import * as fromPremiums from '@core/store/entities/premium/premium.reducers';
import * as fromPrequalificationVerbiage from '@app/eligibility/prequalification-verbiage/prequalification-verbiage.reducer';
import * as fromProducts from '@core/store/entities/product/product.reducers';
import * as fromPropertyForm from '@core/store/property-form/property-form.reducer';
import * as fromProtectiveDevices from '@core/store/entities/protective-devices/protective-devices.reducer';
import * as fromQuote from '@core/store/entities/quote/quote.reducer';
import * as fromQuoteLetter from '@core/store/entities/quote-letter/quote-letter.reducer';
import * as fromRetrieve from '@core/store/retrieve/retrieve.reducer';
import * as fromScheduledCategories from '@core/store/entities/scheduled-categories/scheduled-category.reducer';
import * as fromSession from '@core/store/entities/session/session.reducer';
import * as fromTask from '@core/store/entities/task/task.reducer';
import * as fromTelematics from '@core/store/entities/telematics/telematics.reducer';
import * as fromUnderlyingPolicies from '@core/store/entities/underlying-policy/underlying-policy.reducer';
import * as fromUwActivities from '@core/store/entities/uw-activities/uw-activities.reducer';
import * as fromUwIssues from '@core/store/entities/uw-issues/uw-issues.reducer';
import * as fromUWReports from '@core/store/entities/uw-reports/uw-reports.reducer';
import * as fromUsers from '@core/store/entities/user/user.reducer';
import * as fromVehicleExcludedExposures from '@core/store/entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.reducer';
import * as fromVehicleExposures from '@core/store/entities/exposures/vehicle-exposures/vehicle-exposures.reducer';
import * as fromVehicleInquiry from '@core/store/entities/vehicle-inquiry/vehicle-inquiry.reducer';
import * as fromVehicles from '@core/store/entities/vehicle/vehicle.reducer';
import * as fromWatercraftExcludedExposures from '@core/store/entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.reducer';
import * as fromWatercraftExposures from '@core/store/entities/exposures/watercraft-exposures/watercraft-exposures.reducer';

import {
  AppLoadingState,
  appLoadingStateReducer,
} from '@core/store/loading/loading.reducer';

import { Reducers } from '@core/constants/reducers';

export interface CoreState {
  account: fromAccount.AccountState;
  address: fromAddress.AddressState;
  agency: fromAgency.AgencyState;
  amfBankAccounts: fromAmfBankAccounts.AmfBankAccountsState;
  announcementContent: fromAnnouncementContent.AnnouncementContent;
  billing: fromBilling.BillingState;
  billingAccount: fromBillingAccount.BillingAccountState;
  compraterRouting: fromCompraterRouting.CompraterRoutingState;
  coverageMessages: fromCoverageMessages.CoverageMessageState;
  coverages: fromCoverages.CoverageState;
  coverageSync: fromCoverageSync.CoverageSyncState;
  coveredLocation: fromCoveredLocation.CoveredLocationState;
  currentCarrier: fromCurrentCarrier.CarrierState;
  digitalIdCardsText: fromDigitalIdCardsText.DigitalIdCardsTextState;
  discounts: fromDiscounts.DiscountState;
  documents: fromDocuments.DocumentsState;
  driverVehicleAssignments: fromDriverVehicleAssignments.DriverVehicleAssignmentState;
  driverVehiclePrefill: fromDriverVehiclePrefill.DriverVehiclePrefillState;
  dsm: fromDsm.DsmState;
  eligibilityForm: fromEligibilityForm.EligibilityFormState;
  eligibleDiscounts: fromEligibleDiscounts.EligibleDiscountsState;
  error: fromError.ErrorState;
  escrow: fromEscrow.EscrowState;
  featureFlags: fromFeatureFlag.FeatureFlagState;
  hasMortgage: fromHasMortgage.HasMortgageState;
  layout: LayoutState;
  loadingState: AppLoadingState;
  locationExcludedExposures: fromLocationExcludedExposures.LocationExcludedExposureState;
  locationExposures: fromLocationExposures.LocationExposureState;
  members: fromPeople.MemberState;
  metadata: fromMetaData.MetadataState;
  modifiers: fromModifiers.ModifierState;
  mortgage: fromMortgage.MortgageState;
  navigation: fromNavigation.NavigationState;
  otherStructures: fromOtherStructures.OtherStructuresState;
  pageAlert: fromPageAlert.PageAlertState;
  persona: fromPersona.PersonaState;
  preBindDocuments: fromPreBindDocuments.PreBindDocumentsState;
  premiums: fromPremiums.PremiumState;
  prequalificationVerbiage: fromPrequalificationVerbiage.PrequalificationVerbiageState;
  products: fromProducts.ProductState;
  propertyForm: fromPropertyForm.PropertyFormState;
  protectiveDevices: fromProtectiveDevices.ProtectiveDevicesState;
  quote: fromQuote.QuoteState;
  quoteLetter: fromQuoteLetter.QuoteLetterState;
  retrieve: fromRetrieve.RetrieveState;
  scheduledCategories: fromScheduledCategories.ScheduledCategoriesState;
  session: fromSession.SessionState;
  task: fromTask.TaskState;
  telematics: fromTelematics.TelematicsEnrollmentsState;
  underlyingPolicies: fromUnderlyingPolicies.UnderlyingPolicyState;
  users: fromUsers.UserState;
  uwActivities: fromUwActivities.UwActivitiesState;
  uwIssues: fromUwIssues.UwIssuesState;
  uwReports: fromUWReports.UWReportsState;
  vehicleExcludedExposures: fromVehicleExcludedExposures.VehicleExcludedExposureState;
  vehicleExposures: fromVehicleExposures.VehicleExposureState;
  vehicleInquiry: fromVehicleInquiry.VehicleInquiryState;
  vehicles: fromVehicles.VehicleState;
  watercraftExcludedExposures: fromWatercraftExcludedExposures.WatercraftExcludedExposureState;
  watercraftExposures: fromWatercraftExposures.WatercraftExposureState;
  // THANK YOU FOR MAINTAINING ALPHABETICAL ORDER
}

export const reducers: ActionReducerMap<CoreState> = {
  account: fromAccount.reducer,
  address: fromAddress.reducer,
  agency: fromAgency.reducer,
  amfBankAccounts: fromAmfBankAccounts.reducer,
  announcementContent: fromAnnouncementContent.reducer,
  billing: fromBilling.reducer,
  billingAccount: fromBillingAccount.reducer,
  compraterRouting: fromCompraterRouting.reducer,
  coverageMessages: fromCoverageMessages.reducer,
  coverages: fromCoverages.reducer,
  coverageSync: fromCoverageSync.reducer,
  coveredLocation: fromCoveredLocation.reducer,
  currentCarrier: fromCurrentCarrier.reducer,
  digitalIdCardsText: fromDigitalIdCardsText.reducer,
  discounts: fromDiscounts.reducer,
  documents: fromDocuments.reducer,
  driverVehicleAssignments: fromDriverVehicleAssignments.reducer,
  driverVehiclePrefill: fromDriverVehiclePrefill.reducer,
  dsm: fromDsm.reducer,
  eligibilityForm: fromEligibilityForm.reducer,
  eligibleDiscounts: fromEligibleDiscounts.reducer,
  error: fromError.reducer,
  escrow: fromEscrow.reducer,
  featureFlags: fromFeatureFlag.reducer,
  hasMortgage: fromHasMortgage.reducer,
  layout: fromLayout.reducer,
  loadingState: appLoadingStateReducer,
  locationExcludedExposures: fromLocationExcludedExposures.reducer,
  locationExposures: fromLocationExposures.reducer,
  members: fromPeople.reducer,
  metadata: fromMetaData.reducer,
  modifiers: fromModifiers.reducer,
  mortgage: fromMortgage.reducer,
  navigation: fromNavigation.navigationStateReducer,
  otherStructures: fromOtherStructures.reducer,
  pageAlert: fromPageAlert.reducer,
  persona: fromPersona.reducer,
  preBindDocuments: fromPreBindDocuments.reducer,
  premiums: fromPremiums.reducer,
  prequalificationVerbiage: fromPrequalificationVerbiage.reducer,
  products: fromProducts.reducer,
  propertyForm: fromPropertyForm.reducer,
  protectiveDevices: fromProtectiveDevices.reducer,
  quote: fromQuote.reducer,
  quoteLetter: fromQuoteLetter.reducer,
  retrieve: fromRetrieve.reducer,
  scheduledCategories: fromScheduledCategories.reducer,
  session: fromSession.reducer,
  task: fromTask.reducer,
  telematics: fromTelematics.reducer,
  underlyingPolicies: fromUnderlyingPolicies.reducer,
  users: fromUsers.reducer,
  uwActivities: fromUwActivities.reducer,
  uwIssues: fromUwIssues.reducer,
  uwReports: fromUWReports.reducer,
  vehicleExcludedExposures: fromVehicleExcludedExposures.reducer,
  vehicleExposures: fromVehicleExposures.reducer,
  vehicleInquiry: fromVehicleInquiry.reducer,
  vehicles: fromVehicles.reducer,
  watercraftExcludedExposures: fromWatercraftExcludedExposures.reducer,
  watercraftExposures: fromWatercraftExposures.reducer,
};

export const getCoreState = createFeatureSelector<CoreState>(Reducers.Core);

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
} from '@angular/router';

import * as fromRouter from '@ngrx/router-store';
import { LayoutState } from '../entities/layout/layout.reducer';

// ROUTER
export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface RouterState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const routerReducers: ActionReducerMap<RouterState> = {
  router: fromRouter.routerReducer,
};

export const getRouterState =
  createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>(
    'router'
  );

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}

// const bootstrapActions = [
//   ProductActions.UPDATE_SELECTED_PRODUCTS_TYPE,
//   NavigationActions.ADD_REQUIRED_PAGE_TYPE,
//   NavigationActions.CLEAR_REQUIRED_PAGES_TYPE,
// ];
// const EXPIRY_HOURS = 24;

// export function persistMetaReducer(
//   reducer: ActionReducer<any>
// ): ActionReducer<any> {
// eslint-disable-next-line @typescript-eslint/no-explicit-any, space-before-function-paren
//   return function (state, action): any {
//     if (bootstrapActions.includes(action.type)) {
//       const hydrateString = localStorage.getItem('hydrate');
//       let hydrateStorage: any;
//       if (hydrateString) {
//         hydrateStorage = JSON.parse(hydrateString);
//       } else {
//         const expiry = new Date();
//         expiry.setHours(expiry.getHours() + EXPIRY_HOURS);
//         hydrateStorage = { expiry: expiry.getTime(), actions: [] };
//       }

//       hydrateStorage.actions.push(action);
//       const newExpiry = new Date();
//       newExpiry.setHours(newExpiry.getHours() + EXPIRY_HOURS);
//       hydrateStorage.expiry = newExpiry.getTime();
//       localStorage.setItem('hydrate', JSON.stringify(hydrateStorage));
//     }
//     return reducer(state, action);
//   };
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function devModeMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return (state, action) => {
    if (window.location.hostname === 'localhost') {
      if (action.type === '@ngrx/store/update-reducers') {
        const previousState = JSON.parse(
          sessionStorage.getItem('appState') || '{}'
        );
        sessionStorage.removeItem('appState');
        return Object.keys(previousState).length
          ? previousState
          : reducer(state, action);
      }

      const nextState = reducer(state, action);
      sessionStorage.setItem('appState', JSON.stringify(nextState));
      return nextState;
    } else {
      return reducer(state, action); // not local dev
    }
  };
}
