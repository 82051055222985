import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AgencyService } from '@core/services/agency.service';

@Pipe({
  name: 'fetchLicensedStates',
  pure: true,
})
export class FetchLicensedStatesPipe implements PipeTransform {
  constructor(private agencyService: AgencyService) {}

  transform(states: string[], producerCode?: string): Observable<string[]> {
    if (!producerCode?.length) {
      return of(states);
    }
    return this.agencyService.getAvailableLicensedStates(producerCode, []).pipe(
      take(1),
      catchError((error) => of(states))
    );
  }
}
