import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';
import {
  PriceSummaryDisplayContent,
  PriceSummaryDisplayProduct,
  PriceSummaryDownPayment,
} from '@app/hub/components/price-summary-display/price-summary-display-content';
import { ProductHelper } from '@core/helper/product.helper';
import { QuoteStatus } from '@core/models/api/dsm-types';
import { DownPaymentExperienceResponse } from '@core/models/api/response/down-payment-experience-response.model';
import { DiscountEntity } from '@core/store/entities/discount/discount.entity';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { PolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { openInPolicyCenter } from '@core/store/entities/task/task.action';
import { TaskModel } from '@core/store/entities/task/task.model';
import {
  VehicleEnrollment,
  MobileEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { TaskUtils } from '@entities/task/utils/task.util';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import {
  BillingPlanDisplay,
  DefaultBillingPlans,
} from '@shared/constants/billing-constants';
import { GeneralUtils } from '@shared/utils/general.utils';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { ErrorModel } from '@entities/error/error.model';
import { filter } from 'rxjs';
import { rateQuoteFail } from '@entities/quote/quote.action';

export interface PriceSummaryDisplayContentInputs {
  premiums: PremiumEntity[];
  pni: PolicyHolderEntity;
  selectedProducts: ProductModel[];
  umbrella: Nullable<ProductModel>;
  addOnProducts: ProductModel[];
  downPayment: DownPaymentExperienceResponse;
  overallQuoteStatus: QuoteStatus | undefined;
  billingPlansForm: BillingPlansFormModel;
  quoteLetterBase64: string | undefined;
  draftTasks: TaskModel[];
  paymentMethodDiscounts: DiscountEntity[];
  quoteInProgressCount: number;
  errors: ErrorModel[];
  telematicsPresent: boolean | undefined;
}

export interface BillingAccountsModalInputs {
  billingPlansFormModel: BillingPlansFormModel;
  downPaymentResponse: DownPaymentExperienceResponse;
  productModels: ProductModel[];
  quoteState: string;
  hasMortgage: boolean;
  telematicsEnrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  easyPayDiscountAmount: number;
  featureFlags: Nullable<FeatureFlagsModel>;
}

export class PriceSummaryUtils {
  static generateSortedPriceSummaryProducts(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDisplayProduct[] {
    const displayProducts = input.selectedProducts?.map((product) => {
      const premium = (input.premiums || []).find(
        (p) => p.productType === product.type
      );

      //console.log('price-summary.util input: ' + JSON.stringify(input))
      const output: PriceSummaryDisplayProduct = {
        displayType: 'selected',
        type: product.type,
        name: product.name,
        imageUrl: product.imageUrl,
        hasBindSuspension: product.hasBindSuspension || false,
      };
      const draftTasks = !!input.draftTasks?.filter((task) => {
        return task.productType === product.type;
      }).length;
      /* const anyDraftTaskExistsWithUmbrella =
        TaskUtils.anyDraftTaskExistsWithUmbrella(
          input.draftTasks,
          product.type
        ); */

  
      if (input.errors && input.errors.length && input.errors?.filter((errors) => {
          return errors.productType === product.type && errors.sourceActionType === rateQuoteFail.type
          }).length
        ) {
        if (ProductHelper.isQuoteBlockingError(product)) {
          output.errorMessage = 'Additional documentation required.';
        } else {
          output.errorMessage = 'Rate unavailable.';
        }
      } else if (
        product.quoteRating === true ||
        product.quoteBinding === true
      ) {
        // Leaving errorMessage and premiums unset causes an inline spinner.
      } else if (premium && premium.termMonths && premium.total?.amount) {
        output.installmentPrice = this.formatMoney(
          premium.total.amount / premium.termMonths
        );
        output.installmentFrequency = '\u00a0/ mo.';
        output.totalPrice = this.formatMoney(premium.total.amount);
        output.termLength = `\u00a0/ ${premium.termMonths} mos.`;
        if (product.type === 'PersonalAuto' && input.telematicsPresent) {
          output.helpText = '(Rate includes telematics)';
        }
      } else if (
        product.quoteStatus === 'Draft' &&
        !draftTasks /*  &&
        !anyDraftTaskExistsWithUmbrella */
      ) {
        output.installmentPrice = '$--.--';
        output.installmentFrequency = '\u00a0/ mo.';
        const termLength =
          product.termType === 'HalfYear'
            ? 6
            : product.termType === 'Annual'
            ? 12
            : 0;
        if (termLength) {
          output.totalPrice = '$--.--';
          output.termLength = `\u00a0/ ${termLength} mos.`;
        }
      } else if (
        product.quoteStatus === 'Draft' &&
        draftTasks /* || anyDraftTaskExistsWithUmbrella */
      ) {
        output.warningMessage = 'Complete tasks for rating.';
      } else {
        // Undocumented case. Put at least something up, so it's not the spinner.
        output.installmentPrice = '$--.--';
      }

      return output;
    });
    return GeneralUtils.sortPriceSummaryDisplayProducts(displayProducts);
  }

  static getPriceSummaryDisplayContent(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDisplayContent {
    const downPayments = this.generatePriceSummaryDownPayments(input);

    return {
      pniFirstName: input.pni?.person?.firstName || '',
      quoteStatusLabel: this.labelForQuoteStatus(
        input.quoteInProgressCount,
        input.overallQuoteStatus
      ),
      products: this.generateSortedPriceSummaryProducts(input),
      upsellProducts: this.generatePriceSummaryUpsellProducts(input),
      downPayments,
      downPaymentDisclaimer: downPayments.length
        ? 'Service fees not included'
        : null,
      selectedBillingPlan: this.selectedBillingPlanForPriceSummary(input),
      billingPlanByline: this.billingPlanBylineForPriceSummary(input),
      quotePdfAvailable: !!input.quoteLetterBase64,
      packageDisplayHidden: this.hidePackageDisplay(input),
    } as PriceSummaryDisplayContent;
  }

  static adjustBillingPlanDisplaysForTelematics(
    plans: BillingPlanDisplay[],
    telematics: VehicleEnrollment | MobileEnrollment
  ): BillingPlanDisplay[] {
    if ('vehicles' in telematics && telematics.vehicles?.length) {
      const smartMilesVehicle = telematics.vehicles.find(
        (vehicle) =>
          vehicle.enrollmentStatus === 'Enrolled' &&
          vehicle.vehicleProgram === 'SmartMiles'
      );
      if (smartMilesVehicle) {
        return plans.map((plan) => {
          if (
            plan.id === 'MONTHLY EFT' ||
            plan.id === 'MONTHLY RECURRING BANKCARD'
          ) {
            return plan;
          }
          return {
            ...plan,
            icon: 'exclamation-circle-filled',
            smartMiles:
              'This payment method is not available for SmartMiles variable monthly billing.',
          };
        });
      }
    }
    return plans;
  }

  static adjustBillingPlanDisplaysForPaidInFullDiscount(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'PAY IN FULL') {
        return {
          ...plan,
          discount: 'Paid in Full discount',
        };
      } else {
        return plan;
      }
    });
  }

  static adjustBillingPlanDisplaysForEzPayDiscount(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'MONTHLY EFT') {
        return {
          ...plan,
          discount: 'EasyPay discount',
        };
      } else {
        return plan;
      }
    });
  }

  static adjustBillingPlanDisplaysForReftDiscount(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'MONTHLY EFT') {
        return {
          ...plan,
          discount: 'RecurringEFT discount',
        };
      } else {
        return plan;
      }
    });
  }

  static adjustBillingPlanDisplaysForNonPaperless(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'MONTHLY DIRECT') {
        return {
          ...plan,
          billDelivery: 'U.S. Mail',
        };
      } else {
        return plan;
      }
    });
  }

  static subtitleForMortgage(mortgage: Nullable<MortgageModel>): string {
    if (mortgage) {
      const COMPANY_NAME_LIMIT = 15;
      const LOAN_UNMASKED_DIGIT_COUNT = 4;
      let company = mortgage.companyName || '';
      if (company.length > COMPANY_NAME_LIMIT) {
        company = company.substr(0, COMPANY_NAME_LIMIT) + '...';
      }
      let maskedLoanNumber = mortgage.loanNumber || '';
      if (maskedLoanNumber.length > LOAN_UNMASKED_DIGIT_COUNT) {
        maskedLoanNumber =
          '*****' +
          maskedLoanNumber.substr(
            maskedLoanNumber.length - LOAN_UNMASKED_DIGIT_COUNT
          );
      }
      return `${company}, Loan # ${maskedLoanNumber}`;
    }
    return '';
  }

  private static labelForQuoteStatus(
    quoteInProgressCount: number,
    status?: QuoteStatus
  ): string {
    switch (status) {
      case 'NotStarted':
        return 'Pending';
      case 'Error':
        return 'Error';
      case 'Draft':
        if (quoteInProgressCount > 0) {
          return 'Rating pending';
        } else {
          return 'Pending';
        }
      case 'Pending':
        return 'Rating pending';
      case 'Quoted':
        return 'Initial rate';
      case 'Binding':
        return 'Final rate';
      case 'Issued':
        return 'Final rate';
    }
    return '';
  }

  static formatMoney(amount: number): string {
    // If the input is fishy at all, return blank (don't default to zero, that would be misleading)
    if (typeof amount !== 'number' || isNaN(amount) || amount < 0) {
      return '';
    }
    // Careful here: Billing rounds everything down, so we must too:
    amount = Math.floor(amount * 100) / 100;
    return (
      '$' +
      amount.toLocaleString('en-us', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  static generatePriceSummaryUpsellProducts(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDisplayProduct[] {
    const output: PriceSummaryDisplayProduct[] = [];
    if (input.addOnProducts.length > 0) {
      const umbrella = input.addOnProducts.find(
        (product) => product.type === 'PersonalUmbrella'
      );
      if (
        umbrella &&
        !input.selectedProducts.find(
          (product) => product.type === 'PersonalUmbrella'
        ) &&
        this.isUmbrellaEligible(input.selectedProducts)
      ) {
        const draftTasksPresent = input.draftTasks.length > 0;
        output.push({
          displayType: 'addOn',
          type: umbrella.type,
          name: 'Add ' + umbrella.name,
          imageUrl: umbrella.imageUrl,
          helpText: draftTasksPresent
            ? 'Complete tasks below for quick add'
            : 'Quick add to see pricing',
          disabled: draftTasksPresent,
        });
      }
    }
    return output;
  }

  private static generatePriceSummaryDownPayments(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDownPayment[] {
    const output: PriceSummaryDownPayment[] = [];
    if (input.premiums) {
      // Casting to number because tsc doesn't like undefined with binary operators.
      // In this case, we really do want Javascript's natural undefined-propagation, so cast it thru.
      const totalTerm = input.premiums
        .map((p) => p.total?.amount)
        .reduce((v, a) => (v as number) + (a as number), 0);
      // Sic: Floor each before adding, then round at the end.
      const totalMonthly =
        Math.round(
          input.premiums
            .map((p) =>
              Math.floor(
                ((p.total?.amount as number) * 100) / (p.termMonths as number)
              )
            )
            .reduce((v, a) => v + a, 0)
        ) / 100;
      if (totalTerm && totalMonthly) {
        output.push({
          name: 'est. per month',
          amount: this.formatMoney(totalMonthly),
        });
        output.push({
          name: 'est. total',
          amount: this.formatMoney(totalTerm),
          isEstTotal: true,
        });
      }
    }
    if (input.billingPlansForm.billToEscrowAccount) {
      const amount = this.getEscrowBillAmount(input);
      if (amount) {
        const escrowLine = {
          name: 'escrow total',
          amount: this.formatMoney(amount),
        };
        if (output.length >= 2) {
          output.splice(1, 0, escrowLine);
        } else {
          output.push(escrowLine);
        }
      }
    }
    return output;
  }

  private static getEscrowBillAmount(
    input: PriceSummaryDisplayContentInputs
  ): number {
    const returnPremium = input.premiums.find((premium) =>
      ProductUtils.isEscrowProduct(premium.productType)
    );
    return returnPremium?.total?.amount || 0;
  }

  private static selectedBillingPlanForPriceSummary(
    input: PriceSummaryDisplayContentInputs
  ): string {
    if (this.priceSummaryIsEscrowOnly(input)) {
      return 'Escrow';
    }
    const planMetadata = DefaultBillingPlans.find(
      (dbp) => dbp.id === input.billingPlansForm?.billingPlan
    );
    if (planMetadata) {
      return planMetadata.name;
    }
    return '';
  }

  static billingPlanBylineForPriceSummary(
    input: PriceSummaryDisplayContentInputs
  ): string {
    if (this.priceSummaryIsEscrowOnly(input)) {
      return '';
    }
    if (input.paymentMethodDiscounts.length < 1) {
      return '';
    }
    if (input.billingPlansForm.billingPlan === 'PAY IN FULL') {
      if (
        input.paymentMethodDiscounts.find(
          (d) => d.description === 'Paid In Full Discount'
        )
      ) {
        return 'Paid in Full discount applied';
      }
      return '';
    }
    if (input.billingPlansForm.billingPlan === 'MONTHLY EFT') {
      if (
        input.paymentMethodDiscounts.find(
          (d) => d.description === 'Recurring EFT Discount'
        )
      ) {
        return 'EFT discount applied';
      }
      return '';
    }
    return 'Discount applied';
  }

  private static priceSummaryIsEscrowOnly(
    input: PriceSummaryDisplayContentInputs
  ): boolean {
    return !!(
      input.billingPlansForm.billToEscrowAccount &&
      input.selectedProducts.length === 1 &&
      ProductUtils.isEscrowProduct(input.selectedProducts[0].type)
    );
  }

  static isUmbrellaEligible(products: ProductModel[]): boolean {
    return (
      products.some((product) => product.type === 'PersonalAuto') &&
      products.some(
        (product) =>
          product.type === 'Homeowner' ||
          product.type === 'Tenant' ||
          product.type === 'Condominium'
      )
    );
  }

  static paymentMethodDiscountValueForBillingPlan(billingPlan: string): string {
    switch (billingPlan) {
      case 'PAY IN FULL':
        return 'FullPay';
      case 'MONTHLY DIRECT':
        return 'DirectBill';
      case 'MONTHLY EFT':
        return 'RecurringEFT';
      case 'MONTHLY RECURRING BANKCARD':
        return 'RecurringBankCard';
    }
    return billingPlan;
  }

  static billingPlanForPaymentMethodDiscountValue(value: string): string {
    switch (value) {
      case 'FullPay':
        return 'PAY IN FULL';
      case 'DirectBill':
        return 'MONTHLY DIRECT';
      case 'RecurringEFT':
        return 'MONTHLY EFT';
      case 'RecurringBankCard':
        return 'MONTHLY RECURRING BANKCARD';
    }
    return value;
  }

  /**
   * Keying off a display string feels kind of dirty, but it's logical.
   * The class should vary in lockstep with the displayed content.
   */
  static selectQuoteStatusClassName(label: string): string {
    switch (label) {
      case 'Pending':
        return 'qs-pending';
      case 'Initial rate':
        return 'qs-quoted';
      case 'Final rate':
        return 'qs-binding';
      case 'Rating pending':
        return 'qs-pending';
      case 'Error':
        return 'qs-error';
    }
    return 'qs-unknown';
  }

  static hidePackageDisplay(
    input: PriceSummaryDisplayContentInputs
  ): boolean{

    //determine number of risk filter UW erros encountered
    var errorCount = 0
    input.selectedProducts.forEach((prod) => {
      if (input.errors && input.errors.length && input.errors?.filter((errors) => {
          return errors.productType === prod.type && errors.sourceActionType === rateQuoteFail.type}).length)
        {
          errorCount += 1
        }
    }) 

    if (input.selectedProducts.length > errorCount){
      return false
    }
    else{
      return true
    }
  }
}
