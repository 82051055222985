import { CurrentCarrierUtils } from '@app/shared/utils/current-carrier.utils';
import { ProductType } from '@core/models/api/dsm-types';
import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { getSessionState } from '../session/session.selector';
import * as fromCurrentCarrier from '@core/store/entities/current-carrier/current-carrier.reducer';
import { StringUtils } from '@shared/utils/string.utils';
import { getProductModelsByTypes } from '@entities/product/product.selectors';
import { DateUtils } from '@shared/utils/date.utils';

export const getCurrentCarrierState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.currentCarrier
);

export const {
  selectAll: getAllCurrentCarriers,
  selectEntities: getCurrentCarrierEntities,
} = fromCurrentCarrier.adapter.getSelectors(getCurrentCarrierState);

export const getCurrentCarrier = (productId: ProductType) =>
  createSelector(
    getCurrentCarrierState,
    (currentCarrier) => currentCarrier.entities[productId]
  );

export const getCurrentCarrierFormModel = (productType: ProductType) =>
  createSelector(
    getCurrentCarrier(productType),
    getAllFeatureFlags,
    (currentCarrier, featureFlags) =>
      CurrentCarrierUtils.currentCarrierFormModelFromEntity(
        currentCarrier || {
          productType,
          entityId: StringUtils.generateEntityId(),
        },
        featureFlags
      )
  );

export const getCurrentCarrierHit = createSelector(
  getCurrentCarrierState,
  (state): boolean => state.isCurrentCarrierHit || false
);

export const getCurrentCarrierFormOptions = (productId: ProductType) =>
  createSelector(
    getCurrentCarrier(productId),
    getCurrentCarrierHit,
    getAllFeatureFlags,
    getSessionState,
    getProductModelsByTypes([productId]),
    (entity, hit, featureFlags, session, products) =>
      CurrentCarrierUtils.currentCarrierFormOptionsFromEntity(
        (entity as fromCurrentCarrier.CurrentCarrierEntity) ||
          ({
            productType: productId,
          } as fromCurrentCarrier.CurrentCarrierEntity),
        hit,
        featureFlags,
        productId,
        session.quoteState,
        products[0]?.effectiveDate || DateUtils.getCurrentDateIso()
      )
  );

export const getMsaCurrentCarrierFormOptions = (productId: ProductType) =>
  createSelector(
    getCurrentCarrier(productId),
    getAllFeatureFlags,
    (entity, featureFlags) =>
      CurrentCarrierUtils.msaCurrentCarrierFormOptionsFromEntity(
        entity as fromCurrentCarrier.CurrentCarrierEntity,
        featureFlags,
        productId
      )
  );
