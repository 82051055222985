import { Action, createReducer, on, createAction, props } from '@ngrx/store';

export const setAppLoading = createAction(
  '[Loading] Set App Loading',
  props<{
    serviceName: string;
    id: number;
    callSite: string;
    message?: string;
    iconUrl: string;
  }>()
);

export const endAppLoading = createAction(
  '[Loading] End App Loading',
  props<{ payload: { id: number } }>()
);

export const clearAppLoading = createAction('[Loading] Clear App Loading');

export interface APICallDescriptor {
  id: number;
  serviceName: string;
  callsite?: string;
}

export interface AppLoadingState {
  inFlight: { [key: number]: APICallDescriptor };
  message?: string;
  iconUrl: string;
}

const initialState: AppLoadingState = {
  inFlight: {},
  iconUrl: '',
};

const appLoadingStateReducerFunction = createReducer(
  initialState,
  on(setAppLoading, (state, payload) => {
    return {
      ...state,
      inFlight: { ...state.inFlight, [payload.id]: payload },
      message: payload.message ? payload.message : undefined,
      iconUrl: payload.iconUrl,
    };
  }),
  on(endAppLoading, (state, { payload }) => {
    const allExcept = Object.keys(state.inFlight)
      .map((key) => {
        const inFlight = state.inFlight as { [key: number]: APICallDescriptor };
        return inFlight[Number(key)];
      })
      .filter((descriptor: APICallDescriptor) => descriptor.id !== payload.id);
    return {
      ...state,
      inFlight: { ...allExcept },
    };
  }),
  on(clearAppLoading, (state, payload) => {
    return {
      ...state,
      inFlight: {},
    };
  })
);

export function appLoadingStateReducer(
  state: AppLoadingState | undefined,
  action: Action
): AppLoadingState {
  return appLoadingStateReducerFunction(state, action);
}
