import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ValidationErrors,
} from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { DiscountsService } from '@core/services/discounts.service';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { DiscountEntity } from '@entities/discount/discount.entity';
import { FeatureFlagsModel } from '@entities/feature-flag/feature-flag.model';
import { DateUtils } from '@shared/utils/date.utils';
import { combineLatest, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'nwx-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class DatepickerInputComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() controlName!: string;
  @Input() controlId!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() hasError!: boolean | null;
  @Input() invalidFeedbackText!: string;
  @Input() displayMonths: number = 2;
  // 'select' (select boxes for month and navigation arrows) | 'arrows' (only navigation arrows) | 'none' (no navigation visible at all)
  @Input() navigation: string = 'select';
  @Input() showWeekNumbers: boolean = false;
  @Input() outsideDays: string = 'visible';
  @Input() minDate: string | null = null;
  @Input() maxDate: string | null = null;
  @Input() startDate!: string | null;
  @Input() disabled = false;
  @Input() relevantProduct!: ProductType | null;
  @Input() bottomSpacingNeeded = true;
  @Input() quoteCreateDate!: string | undefined;
  @Input() bumpHeaderMargin = false;
  @Input() effectiveDate!: string | null;
  @ViewChild('inputField', { read: ElementRef }) inputField?: ElementRef;

  blankNgbDate = {
    day: 0,
    month: 0,
    year: 0,
  };

  showAdvanceQuoteDiscount = false;
  showAdvanceQuoteDiscountBanner = false;
  advancedQuoteDates!: string;
  featureFlags!: FeatureFlagsModel;

  private mutationObserver: MutationObserver;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService
  ) {
    this.mutationObserver = new MutationObserver((events) => {
      for (const event of events) {
        if (
          event.type === 'attributes' &&
          event.attributeName === 'error' &&
          event.target === this.inputField?.nativeElement
        ) {
          const value = this.inputField.nativeElement.getAttribute('error');
          if (value) {
            this.hasError = true;
            this.invalidFeedbackText = value;
          } else {
            this.hasError = false;
            this.invalidFeedbackText = '';
          }
          this.changeDetector.markForCheck();
        }
      }
    });
  }

  ngOnInit(): void {
    const eightDatesFromCreateDate = DateUtils.getFutureDate(
      !!this.quoteCreateDate
        ? this.quoteCreateDate
        : DateUtils.getCurrentDateIso(),
      8
    )
      .toISOString()
      .split('T')[0];
    const maximumDate = !!this.maxDate
      ? this.maxDate
      : DateUtils.getFutureDate(DateUtils.getCurrentDateIso(), 59)
          .toISOString()
          .split('T')[0];
    this.advancedQuoteDates = DateUtils.getDatesBetween(
      eightDatesFromCreateDate,
      maximumDate
    ).join(', ');
    this.featureFlagService
      .getAllFeatureFlags()
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((flags) => {
        this.showAdvanceQuoteDiscount = this.getAdvanceQuoteFeatureFlag(
          this.relevantProduct,
          flags
        );
        this.showAdvanceQuoteDiscountBanner =
          this.getShowAdvanceQuoteDiscountBanner(
            this.showAdvanceQuoteDiscount,
            this.advancedQuoteDates,
            this.effectiveDate
          );
      });
  }

  ngAfterViewInit(): void {
    if (this.inputField) {
      this.mutationObserver.observe(this.inputField.nativeElement, {
        attributeFilter: ['error'],
        attributes: true,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.effectiveDate) {
      this.showAdvanceQuoteDiscountBanner =
        this.getShowAdvanceQuoteDiscountBanner(
          this.showAdvanceQuoteDiscount,
          this.advancedQuoteDates,
          this.effectiveDate
        );
    }
  }

  static validate(
    control: AbstractControl,
    min: string,
    max: string
  ): ValidationErrors | null {
    const rawInput = control.value || '';
    if (!rawInput.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return { required: true };
    }
    const value = DateUtils.formatDateToDSM(rawInput);
    if (value < min) {
      return {
        requiredBefore: DateUtils.formatDsmDateToOld(min),
      };
    }
    if (value > max) {
      return {
        requiredAfter: DateUtils.formatDsmDateToOld(max),
      };
    }
    return null;
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onIconClick(event: Event, datepicker: any): void {
    event.preventDefault();
    if (!this.disabled) {
      datepicker.toggle();
    }
  }

  getAdvanceQuoteFeatureFlag(
    productType: ProductType | null,
    featureFlags: FeatureFlagsModel
  ): boolean {
    switch (productType) {
      case 'PersonalAuto':
        return !!featureFlags?.showAdvanceQuoteDiscountPersonalAuto;
      case 'MSA':
        return !!featureFlags?.showAdvanceQuoteDiscountMSA;
      case 'Boat':
        return !!featureFlags?.showAdvanceQuoteDiscountBoat;
      case 'RV':
        return !!featureFlags?.showAdvanceQuoteDiscountRV;
      default:
        return false;
    }
  }

  getShowAdvanceQuoteDiscountBanner(
    showAdvanceQuoteDiscount: boolean,
    advancedQuoteDates: string,
    effectiveDate: string | null
  ): boolean {
    return (
      showAdvanceQuoteDiscount &&
      advancedQuoteDates.includes(effectiveDate || 'null')
    );
  }
}
