import { Action, createReducer, on } from '@ngrx/store';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import {
  CoverageActions,
  PolicyIssueActions,
  PolicyNumberActions,
  ProductActions,
  QuoteActions,
  RetrieveActions,
  VehicleActions,
  SessionActions,
  CoveredLocationActions,
} from '@core/store/actions';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { ProductType, QuoteStatus } from '@core/models/api/dsm-types';
import { ProductDisplayOrder } from '@shared/constants/app-constants';
import { Products } from '@assets/metadata/products';
import { DateUtils } from '@shared/utils/date.utils';

export interface ProductState extends EntityState<ProductModel> {
  error: ErrorModel;
  loaded: boolean;
  selectedProducts: ProductType[];
}

export const adapter: EntityAdapter<ProductModel> =
  createEntityAdapter<ProductModel>({
    selectId: (product) => product.type,
    sortComparer: (a, b) => {
      const preferredOrder: unknown[] = [
        'PersonalAuto',
        'MSA',
        'Boat',
        'RV',
        'Homeowner',
        'Tenant',
        'Condominium',
        'DwellingFire',
        'PersonalUmbrella',
      ];
      return preferredOrder.indexOf(a.type) - preferredOrder.indexOf(b.type);
    },
  });

export const initialState: ProductState = {
  ids: Products.map((p) => p.type),
  entities: Products.reduce((a, v) => {
    a[v.type as string] = v;
    return a;
  }, {} as { [key: string]: ProductModel }),
  error: {},
  loaded: false,
  selectedProducts: [],
};

const productReducer = createReducer(
  adapter.getInitialState(initialState),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState(initialState)
  ),
  on(ProductActions.updateProduct, (state, { payload }) =>
    adapter.updateOne(payload, { ...state, loaded: true })
  ),
  on(ProductActions.upsertForAllProducts, (state, { payload }) =>
    adapter.upsertMany(
      (Object.values(state.entities) as ProductModel[]).map((p) => ({
        ...p,
        ...payload,
      })),
      state
    )
  ),
  on(ProductActions.updateDocDeliveryOption, (state, { payload }) =>
    adapter.upsertMany(
      (Object.values(state.entities) as ProductModel[]).map((product) => ({
        ...product,
        docDelPreference: payload.docDelPreference,
      })),
      state
    )
  ),
  on(ProductActions.updateSelectedProducts, (state, { payload }) => {
    return {
      ...state,
      selectedProducts: [...payload].sort(compareSelectedProductTypes),
    };
  }),
  on(ProductActions.removeProduct, (state, { payload: productType }) => ({
    ...state,
    selectedProducts: state.selectedProducts.filter(
      (selectedProductType) => selectedProductType !== productType
    ),
  })),
  on(ProductActions.clearProductErrors, (state) => {
    return adapter.updateMany(
      (Object.values(state.entities) as ProductModel[]).map((product) => ({
        id: product.type,
        changes: {
          hasError: false,
        },
      })),
      state
    );
  }),
  on(
    ProductActions.clearProductError,
    ProductActions.removeProduct,
    (state, { payload: productId }) => {
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            hasError: false,
          },
        },
        state
      );
    }
  ),
  on(QuoteActions.initiateNewBusinessSuccess, (state, { payload }) => {
    return adapter.updateOne(
      {
        id: payload.productType,
        changes: {
          hasError: false,
          initiated: true,
          hasBindSuspension: payload.informationalMessages?.some((message) =>
            message.warnings?.some((warning) =>
              warning.message?.includes('binding suspension')
            )
          ),
          quoteCreateDate: DateUtils.formatDsmDateToOld(
            DateUtils.getCurrentDateAsString()
          ),
        },
      },
      state
    );
  }),
  on(
    QuoteActions.rateQuote,
    (state, { productType: productId, ratingType }) => {
      const changes =
        ratingType === 'quote'
          ? {
              quoteRating: true,
              quoteRated: false,
              quoteCompleted: false,
              hasError: false,
            }
          : {
              quoteBinding: true,
              hasError: false,
            };
      return adapter.updateOne(
        {
          id: productId,
          changes,
        },
        state
      );
    }
  ),
  on(
    QuoteActions.rateQuoteSuccess,
    (state, { productType: productId, quoteStatus }) => {
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            quoteRating: false,
            quoteRated: true,
            quoteCompleted: true,
            quoteStatus,
            quoteFurthestStatus: quoteStatus,
          },
        },
        state
      );
    }
  ),
  on(
    QuoteActions.rateBindSuccess,
    (state, { productType: productId, quoteStatus }) => {
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            quoteBinding: false,
            quoteRating: false,
            quoteStatus,
            quoteFurthestStatus: quoteStatus,
          },
        },
        state
      );
    }
  ),
  on(QuoteActions.rateQuoteFail, (state, { productType: productId, error }) => {
    return adapter.updateOne(
      {
        id: productId,
        changes: {
          quoteRating: false,
          quoteBinding: false,
          hasError: true,
          quoteStatus: 'Error' as QuoteStatus,
          errorMessage:
            (error as ErrorModel)?.displayMessage ||
            (error as ErrorModel)?.developerMessage,
          errorCode: (error as ErrorModel)?.status?.toString(),
        },
      },
      state
    );
  }),
  on(CoverageActions.getAllCoveragesSuccess, (state, { payload }) => {
    return adapter.updateOne(
      {
        id: payload[0].productId,
        changes: {
          allQuoteCoverageLoaded: true,
          quoteCoverageLoaded: true,
          quoteCoverageLoading: false,
        },
      },
      state
    );
  }),
  on(CoverageActions.getAllCoveragesFail, (state, { error }) => {
    return adapter.updateOne(
      {
        id: error.productType || '',
        changes: {
          allQuoteCoverageLoaded: false,
          quoteCoverageLoaded: false,
          quoteCoverageLoading: false,
        },
      },
      state
    );
  }),
  on(CoverageActions.loadAllCoverages, (state) => {
    const updates: Update<ProductModel>[] = state.selectedProducts.map(
      (selectedProductId) => ({
        id: selectedProductId as ProductType,
        changes: {
          allQuoteCoverageLoaded: false,
          quoteCoverageLoaded: false,
          quoteCoverageLoading: true,
        },
      })
    );
    return adapter.updateMany(updates, state);
  }),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    const newState = {
      ...state,
    };
    if (!newState.selectedProducts.includes(payload.productType)) {
      newState.selectedProducts = [
        ...newState.selectedProducts,
        payload.productType,
      ];
    }

    const responseUpdate = {
      nationwideAccountRegistrationInfo: {
        ...payload.response.nationwideAccountRegistrationInfo,
      },
      type: payload.productType,
      sessionId: payload.response.sessionId,
      quoteRated: ['Quoted', 'Binding'].includes(payload.response.quoteStatus),
      quoteStatus: payload.response.quoteStatus,
      quoteFurthestStatus: payload.response.quoteStatus,
      quoteId: payload.response.quoteId,
      termType: payload.response.termType,
      offeringType: payload.response.offeringType,
      effectiveDate: payload.response.effectiveDate,
      quoteCoverageLoaded: payload.response.offeredQuotes.some(
        (quote) => quote.coverages.length > 0
      ),
      hasBindSuspension: payload.response.informationalMessages?.some(
        (message) =>
          message.warnings?.some((warning) =>
            warning.message?.includes('binding suspension')
          )
      ),
      automateElectronicDocumentDelivery:
        payload.response.automateElectronicDocumentDelivery,
    } as Partial<ProductModel>;

    if (payload.response.docDelPreference) {
      responseUpdate.docDelPreference = payload.response.docDelPreference;
    }

    switch (payload.productType) {
      case 'PersonalAuto':
        return adapter.updateOne(
          {
            id: payload.productType,
            changes: {
              ...responseUpdate,
              isAssignedRiskPlan: payload.response.isAssignedRiskPlan,
              hasCompanyCar: payload.response.hasCompanyCar,
              additionalHouseholdInfo: payload.response.additionalHouseholdInfo,
            },
          },
          newState
        );
      case 'Homeowner':
        return adapter.updateOne(
          {
            id: payload.productType,
            changes: {
              ...responseUpdate,
              needConstructionInfo: payload.response.needConstructionInfo,
              yearsWithPriorCarrier: payload.response.yearsWithPriorCarrier,
            },
          },
          newState
        );
      case 'PersonalUmbrella':
        return adapter.updateOne(
          {
            id: payload.productType,
            changes: {
              ...responseUpdate,
              eligibilityInformation: payload.response.eligibilityInformation,
            },
          },
          newState
        );
      case 'Boat':
      case 'MSA':
      case 'RV':
        return adapter.updateOne(
          {
            id: payload.productType,
            changes: {
              ...responseUpdate,
              hasNWPSPolicies: payload.response.hasNWPSPolicies,
              numberOfOtherVehicles: payload.response.numberOfOtherVehicles,
            },
          },
          newState
        );
      default:
        return adapter.updateOne(
          {
            id: payload.productType,
            changes: { ...responseUpdate },
          },
          newState
        );
    }
  }),
  on(RetrieveActions.retrieveInactiveQuoteSuccess, (state, { payload }) => {
    const newState = {
      ...state,
    };
    if (!newState.selectedProducts.includes(payload.productType)) {
      newState.selectedProducts = [
        ...newState.selectedProducts,
        payload.productType,
      ];
    }
    const responseUpdate = {
      type: payload.productType,
      quoteId: payload.response?.quoteId,
      isDsmActive: false,
    };
    return adapter.upsertOne(
      {
        ...responseUpdate,
        type: payload.productType,
      },
      newState
    );
  }),
  on(PolicyNumberActions.generatePolicyNumberSuccess, (state, { response }) => {
    return adapter.updateOne(
      {
        id: response.type,
        changes: {
          policyNumber: response.policyNumber,
          policyNumberLoaded: true,
          policyNumberLoading: false,
        },
      },
      state
    );
  }),
  on(PolicyNumberActions.generatePolicyNumber, (state) => {
    const changedEntities = state.selectedProducts
      .map((id): ProductModel | null => {
        if (state.entities[id]?.policyNumber) {
          return null;
        }
        return {
          ...state.entities[id],
          policyNumberLoading: true,
        } as ProductModel;
      })
      .filter((product) => !!product);
    const newEntities = {
      ...state.entities,
    };
    for (const entity of changedEntities) {
      newEntities[entity?.type || ''] = entity as ProductModel;
    }
    return {
      ...state,
      entities: newEntities,
    };
  }),
  on(PolicyNumberActions.generatePolicyNumberFail, (state, { product }) => {
    if (product) {
      return adapter.updateOne(
        {
          id: product,
          changes: {
            policyNumberLoading: false,
          },
        },
        state
      );
    } else {
      return state;
    }
  }),
  on(PolicyIssueActions.issuePolicyForProduct, (state, { product }) => {
    if (state.entities[product]?.quoteStatus === 'Issued') {
      return state;
    }
    return adapter.updateOne(
      {
        id: product,
        changes: {
          quoteStatus: 'Pending',
        },
      },
      state
    );
  }),
  on(PolicyIssueActions.issuePolicySuccess, (state, { response }) => {
    return adapter.updateOne(
      {
        id: response.productId,
        changes: {
          issueCompleted: true,
          issueLoading: false,
          issueFailed: false,
          quoteStatus: 'Issued',
          quoteFurthestStatus: 'Issued',
          tempUserId: response.tempUserId,
        },
      },
      state
    );
  }),
  on(PolicyIssueActions.issuePolicyFail, (state, { product, error }) => {
    return adapter.updateOne(
      {
        id: product,
        changes: {
          issueLoading: false,
          issueCompleted: false,
          issueFailed: true,
          quoteStatus: 'Error' as QuoteStatus,
        },
      },
      state
    );
  }),
  on(
    ProductActions.updateProductStatus,
    (state, { payload: productId, status }) => {
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            quoteStatus: status,
          },
        },
        state
      );
    }
  ),
  on(ProductActions.requiredDrivingConsentOnAddNewVehicle, (state) => {
    return adapter.updateOne(
      {
        id: 'PersonalAuto',
        changes: {
          requireDrivingConsent: true,
        },
      },
      state
    );
  }),
  on(VehicleActions.addVehicleSuccess, (state) => {
    return adapter.updateOne(
      {
        id: 'PersonalAuto',
        changes: {
          requireDrivingConsent: false,
        },
      },
      state
    );
  }),
  on(
    ProductActions.upsertYearsWithPriorCarrier,
    (state, { payload: productType, yearsWithPriorCarrier }) =>
      adapter.upsertOne(
        {
          ...state.entities?.[productType as ProductType],
          type: productType,
          yearsWithPriorCarrier,
        },
        state
      )
  ),
  on(ProductActions.updateProducer, (state, { product, producer }) =>
    adapter.updateOne(
      {
        id: product,
        changes: {
          producer: producer,
        },
      },
      state
    )
  ),
  on(CoveredLocationActions.getCoveredLocationSuccess, (state, { payload }) => {
    if (!!!state.entities[payload.productType]?.associatedMSBEstimateNumber) {
      return adapter.updateOne(
        {
          id: payload.productType,
          changes: {
            associatedMSBEstimateNumber: payload.associatedMSBEstimateNumber,
          },
        },
        state
      );
    } else {
      return state;
    }
  }),
  on(
    ProductActions.deleteWarningMessageFromProduct,
    (state, { message, productType }) => {
      const informationalMessages =
        state.entities[productType]?.informationalMessages;
      if (!!informationalMessages) {
        const infoMsgIndex = informationalMessages.findIndex((infoMsg) =>
          infoMsg.warnings.some((warning) => warning.message === message)
        );
        const warningIndex = informationalMessages[
          infoMsgIndex
        ].warnings.findIndex((warning) => warning.message === message);

        const cloneWarnings = [...informationalMessages[infoMsgIndex].warnings];
        cloneWarnings.splice(warningIndex, 1);

        let cloneInformationalMessages = [...informationalMessages];
        cloneInformationalMessages[infoMsgIndex] = {
          ...informationalMessages[infoMsgIndex],
          warnings: cloneWarnings,
        };

        return adapter.updateOne(
          {
            id: productType,
            changes: {
              informationalMessages: cloneInformationalMessages,
            },
          },
          state
        );
      } else {
        return state;
      }
    }
  ),
  on(
    ProductActions.updateProductCreateDate,
    (state, { payload: productId, createDate }) => {
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            quoteCreateDate: createDate,
          },
        },
        state
      );
    }
  )
);

function compareSelectedProductTypes(a: ProductType, b: ProductType): number {
  return ProductDisplayOrder.indexOf(a) - ProductDisplayOrder.indexOf(b);
}

function compareProductModels(a: ProductModel, b: ProductModel): number {
  return (
    ProductDisplayOrder.indexOf(a.type) - ProductDisplayOrder.indexOf(b.type)
  );
}

export function reducer(
  state: ProductState | undefined,
  action: Action
): ProductState {
  return productReducer(state, action);
}
