import { BaseEntity } from '@core/models/entities/base.entity';
import { ProductType } from '../api/dsm-types';
import { ObjectValidator } from '@core/helper/object-validator';

export interface DriverIncidentEntity {
  infractionDesc: string;
  occurrenceDate: string;
  infractionType: string;
  infractionId: string;
  overrideInfraction: boolean;
  overrideReason?: string;
  infractionPoints?: number;
  isChargeable?: string;
  productType?: ProductType;
}

export function sanitizeDriverIncidentEntity(
  response: unknown
): DriverIncidentEntity {
  return ObjectValidator.forceSchema<DriverIncidentEntity>(
    response,
    {
      infractionDesc: 'string',
      occurrenceDate: 'string',
      infractionType: 'string',
      infractionId: 'string',
      overrideInfraction: 'boolean',
      overrideReason: 'string',
      infractionPoints: 'number',
      isChargeable: 'string',
      productType: 'string',
    },
    ['overrideReason', 'infractionPoints', 'isChargeable', 'productType']
  );
}
