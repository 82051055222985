import { Pipe, PipeTransform } from '@angular/core';
import { TermType } from '@core/models/api/dsm-types';
import { Nullable } from '../utils/type.utils';

@Pipe({ name: 'termType' })
export class TermTypePipe implements PipeTransform {
  transform(termType: Nullable<TermType>): string {
    switch (termType) {
      case 'Annual':
        return '12 months';
      case 'HalfYear':
        return '6 months';
      default:
        return '6 months';
    }
  }
}
