import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';
import { SessionActions, UwActivitiesActions } from '@core/store/actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export interface UwActivityNote {
  noteTopic: string;
  body: string;
}

export interface UwActivity {
  activityId: number;
  assignedTo: string;
  category: string;
  notes: UwActivityNote[];
  status: string;
  subject: string;
}

export function sanitizeUwActivity(input: unknown): UwActivity {
  return ObjectValidator.forceSchema<UwActivity>(input, {
    activityId: 'number',
    assignedTo: 'string',
    category: 'string',
    notes: [
      {
        noteTopic: 'string',
        body: 'string',
      },
    ],
    status: 'string',
    subject: 'string',
  });
}

export function sanitizeUwActivities(input: unknown): UwActivity[] {
  return ObjectValidator.sanitizeArray(input, sanitizeUwActivity);
}

export interface UwActivitiesStoreModel {
  productType: ProductType;
  quoteId: string;
  activities: UwActivity[];
}

export interface UwActivitiesState
  extends EntityState<UwActivitiesStoreModel> {}

const adapter = createEntityAdapter<UwActivitiesStoreModel>({
  selectId: (model) => model.productType + '_' + model.quoteId,
});

export const reducer = createReducer(
  adapter.getInitialState(),
  on(SessionActions.clearSessionState, () => adapter.getInitialState()),
  on(UwActivitiesActions.storeActivity, (state, action) => {
    const key = action.productType + '_' + action.quoteId;
    const activities = (state.entities[key]?.activities || []).filter(
      (a) => a.activityId !== action.activity.activityId
    );
    return adapter.upsertOne(
      {
        productType: action.productType,
        quoteId: action.quoteId,
        activities: [...activities, action.activity],
      },
      state
    );
  }),
  on(UwActivitiesActions.storeActivities, (state, action) => {
    return adapter.upsertOne(
      {
        productType: action.productType,
        quoteId: action.quoteId,
        activities: action.activities,
      },
      state
    );
  })
);
