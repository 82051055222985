import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { RouterService } from '@core/services/router.service';
import { NextStep, UpNextService } from '@core/services/up-next.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { SortedTasks, TaskModel } from '@core/store/entities/task/task.model';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { LayoutService } from '@core/services/layout.service';
import { UwIssuesResponse } from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { UwIssuesByProduct } from '@entities/uw-issues/uw-issues.selector';

@Component({
  selector: 'nwx-next-step',
  templateUrl: './next-step.component.html',
  styleUrls: ['./next-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextStepComponent implements OnChanges, OnDestroy {
  @Input() products!: ProductModel[];
  @Input() uwIssues: UwIssuesByProduct = {};
  @Input() disabledUwProducts: ProductType[] = [];
  @Input() errorTasks!: Nullable<SortedTasks[][]>;
  @Input() blockedTasks!: Nullable<SortedTasks[][]>;
  @Input() tasks!: Nullable<SortedTasks[][]>;
  @Input() reviewTasks!: Nullable<SortedTasks[][]>;
  @Input() showTasks!: boolean;
  @Input() showIncompleteError = false;

  @Output() taskButtonClicked: EventEmitter<string> = new EventEmitter();
  @Output() clickEvent = new EventEmitter();

  nextStep$: Observable<NextStep>;
  maxSizeMedium$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private routerService: RouterService,
    private upNextService: UpNextService,
    private layoutService: LayoutService
  ) {
    this.nextStep$ = this.upNextService.getNextStep();
    this.maxSizeMedium$ = this.layoutService.maxSizeMedium();
  }

  ngOnChanges(changes: ComponentChanges<NextStepComponent>): void {
    if (
      changes.showIncompleteError &&
      changes.showIncompleteError.currentValue === true
    ) {
    } else {
      this.showIncompleteError = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToPage(url: string): void {
    this.routerService.go({ path: [url || '#'] });
  }

  getStatusMessageForProduct(product: ProductModel): string {
    return this.upNextService.getStatusMessageForProduct(product);
  }

  onTaskButtonClicked(taskPageUrl: string): void {
    this.taskButtonClicked.emit(taskPageUrl);
  }

  detectChanges(): void {
    this.changeDetector.detectChanges();
  }
}
