import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  NgZone,
} from '@angular/core';
import { HubPageRepresentation } from '@core/constants/pages';
import { AdjacentPages } from '@core/interfaces/interfaces';
import { LogEventName } from '@core/models/api/log.model';
import { LogService } from '@core/services/log.service';
import {
  NavigationService,
  Direction,
} from '@core/services/navigation.service';
import { ProductsService } from '@core/services/products.service';
import { QuoteLetterService } from '@core/services/quote-letter.service';
import { RouterService } from '@core/services/router.service';
import { TaskService } from '@core/services/task.service';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { ProductModel } from '@core/store/entities/product/product.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take, map } from 'rxjs/operators';
import { UpNextContainerComponent } from '../containers/up-next-container/up-next-container.component';
import { LayoutService } from '@core/services/layout.service';
import { ModalService } from '@shared/services/modal.service';
import { BillingService } from '@core/services/billing.service';
import { NextStep, UpNextService } from '@core/services/up-next.service';
import { Nullable } from '@shared/utils/type.utils';
import { Store } from '@ngrx/store';
import { HeaderService } from '@core/services/header.service';
import { UnderlyingPolicyService } from '@core/services/underlying-policy.service';
import { PersonaName, PersonaReport } from '@entities/persona/persona.model';
import { PersonaService } from '@core/services/persona.service';
import { MemberService } from '@core/services/member.service';

@Component({
  selector: 'nwx-hub-page',
  templateUrl: './hub-page.component.html',
  styleUrls: ['./hub-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubPageComponent implements OnInit, OnDestroy {
  page: Page = HubPageRepresentation;

  @ViewChild(UpNextContainerComponent)
  taskContainer: UpNextContainerComponent | null = null;

  needsRecalc$: Observable<boolean>;
  selectedUnsupportedProducts$: Observable<boolean>;
  effectiveDateTasks$: Observable<TaskModel[]>;
  products$: Observable<ProductModel[]>;
  telematicsTabActive$ = new Subject<boolean>();
  maxSizeMedium$: Observable<boolean>;
  quoteLetter$: Observable<string>;
  quoteLetter = '';
  forwardButtonIcon = 'arrow-right';
  showMobileNav = false;
  showMVRDisclaimer = false;
  nextStep$: Observable<NextStep>;
  nextStep: Nullable<NextStep> = null;
  private unsubscribe$ = new Subject<void>();
  hasExternalUnderlyPolicies$: Observable<boolean>;
  mvrDisclaimerText =
    'Are you sure you are ready to finalize this rate? Reports being ordered may result in ' +
    'additional costs deducted from commissions owed pursuant to the Commission Schedule';
  personaReport$: Observable<PersonaReport>;
  hasSecondaryMatchFlag$: Observable<boolean>;
  personaName: PersonaName = 'None';

  constructor(
    private productsService: ProductsService,
    private quoteLetterService: QuoteLetterService,
    private navigationService: NavigationService,
    private routerService: RouterService,
    private taskService: TaskService,
    private log: LogService,
    private changeDetector: ChangeDetectorRef,
    private element: ElementRef,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private billingService: BillingService,
    private upNextService: UpNextService,
    private store: Store,
    private headerService: HeaderService,
    private ngZone: NgZone,
    private underlyingPolicyService: UnderlyingPolicyService,
    private personaService: PersonaService,
    private memberService: MemberService
  ) {
    this.needsRecalc$ = this.productsService.productsNeedRerate();
    this.effectiveDateTasks$ = this.taskService.getEffectiveDateTasks();
    this.products$ = this.productsService.getSelectedProducts();
    this.selectedUnsupportedProducts$ =
      this.productsService.hasSelectedInactiveProducts();
    this.maxSizeMedium$ = this.layoutService.maxSizeMedium();
    this.quoteLetter$ = this.quoteLetterService.getQuoteLetter();
    this.quoteLetter$.pipe(takeUntil(this.unsubscribe$)).subscribe((letter) => {
      this.quoteLetter = letter;
      this.changeDetector.markForCheck();
    });
    this.nextStep$ = this.upNextService.getNextStep();
    this.nextStep$.pipe(takeUntil(this.unsubscribe$)).subscribe((ns) => {
      this.nextStep = ns;
      // TIB-206: Reenable this line if we want the MVR notice again. As of 2024-06-13, business does not want it displayed.
      //this.showMVRDisclaimer = ns.buttonLabel == 'Pay & Bind';
      this.updateFormattingForNewNextStep();
    });
    this.hasExternalUnderlyPolicies$ = this.underlyingPolicyService
      .getAllUnderlyingPolicies()
      .pipe(
        takeUntil(this.unsubscribe$),
        map((policies) => policies.some((p) => !p.isNationwidePolicy))
      );
    this.personaReport$ = this.personaService.getPersonaReport();
    this.personaReport$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((report) => {
        this.personaName = report.name;
        this.changeDetector.markForCheck();
      });
    this.hasSecondaryMatchFlag$ = this.memberService.hasSecondaryMatchFlag();
  }

  ngOnInit(): void {
    this.navigationService
      .getTargetPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((navigationBus) => {
        if (navigationBus?.direction === Direction.forward) {
          this.onSubmit();
        } else {
          this.routerService.go({
            path: [navigationBus?.destination?.url as string],
          });
        }
      });
    // We may be inside change detection, and these HeaderService calls will change things possibly already consumed.
    // So wait for NgZone to stablize first (ie wait for end of change detection).
    // Otherwise "ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked."
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.headerService.suppressNavBar();
      this.headerService.suppressWelcomeMessage();
    });
    this.log.logUiEvent('load-page-hub' as LogEventName);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateFormattingForNewNextStep(): void {
    this.forwardButtonIcon = '';
    switch (this.nextStep?.buttonAction?.type) {
      case '[Navigation] Navigate to Billing page':
        {
          this.forwardButtonIcon = 'arrow-right';
        }
        break;
    }
  }

  onSubmit(): void {
    if (!this.nextStep) {
      return;
    }
    if (this.nextStep.buttonAction) {
      const actionType = this.nextStep.buttonAction.type;
      this.store.dispatch(this.nextStep.buttonAction);
      if (actionType === '[Task] Toggle Show Tasks on Hub Page') {
        this.scrollToTasks();
      }
      return;
    }
  }

  navigateForward(): void {
    this.log.logUiEvent('submit-page-hub' as LogEventName);
    this.billingService.unsetDownPaymentFailed();
    this.navigationService.setComplete(this.page.id);
    this.navigationService
      .getAdjacentPages(this.page)
      .pipe(take(1))
      .subscribe({
        next: (adjacentPages: AdjacentPages) => {
          this.routerService.go({ path: [adjacentPages.next.url] });
        },
        error: () => {},
        complete: () => {},
      });
  }

  getQuoteLetterPdf(): void {
    this.quoteLetterService.showEncodedQuoteLetterInNewTab(
      this.quoteLetter,
      this.personaName
    );
  }

  scrollToTasks(): void {
    this.taskService.setShowTasks(true);
    this.element?.nativeElement
      ?.querySelector('nwx-next-step')
      ?.scrollIntoView();
  }

  launchUnsupportedModal(): void {
    this.modalService.nonNwxProductHubPivot();
  }

  // Stupid hack due to a Bolt bug; we need the button bar to re-create itself when anything changes.
  // Rumor has it, this will be fixed in Bolt 4.6.0. If so, please remove this ridiculous thing.
  forceRebuild(): boolean {
    return true;
  }

  continueToPC() {
    this.navigationService.pivotToPolicyCenter('External underlying policy');
  }

  dismissNotification(id: string): void {
    this.element?.nativeElement?.querySelector(`#${id}`)?.remove();
  }
}
