import { UwIssuesResponse } from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { UwIssuesStoreRecord } from './uw-issues.reducer';

export const clearUwIssuesForProduct = createAction(
  '[UW Issues] Clear Issues For Product',
  props<{ productType: ProductType }>()
);

export const setUwIssues = createAction(
  '[UW Issues] Set UW Issues',
  props<{ record: UwIssuesStoreRecord }>()
);

export const disableUwIssuesProduct = createAction(
  '[UW Issues] Disable Product',
  props<{ productType: ProductType }>()
);
