import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ProductModel } from '../../entities/product/product.model';
import { CoreState } from '../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { combineLatest, from } from 'rxjs';
import {
  switchMap,
  catchError,
  withLatestFrom,
  take,
  map,
  mergeMap,
  exhaustMap,
  tap,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { CoveredLocationAdapter } from '@core/adapters/covered-location.adapter';
import * as fromActions from './covered-location.action';
import { Nullable } from '@shared/utils/type.utils';
import { CoveredLocationSelectors, TaskSelectors } from '@core/store/selectors';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { CoveredLocationActions, TaskActions } from '@core/store/actions';
import { ModalService } from '@shared/services/modal.service';
import { CoveredLocationModel } from './covered-location.model';
import { TaskModel } from '@entities/task/task.model';
import { HomeownerPageRepresentation } from '@core/constants/pages';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { FeatureFlagsModel } from '@entities/feature-flag/feature-flag.model';

@Injectable()
export class CoveredLocationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private coveredLocationAdapter: CoveredLocationAdapter,
    private errorSanitizerService: ErrorSanitizerService,
    private productsService: ProductsService,
    private coveredLocationService: CoveredLocationService,
    private modalService: ModalService
  ) {}

  getCoveredLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCoveredLocation),
      exhaustMap((action) =>
        this.productsService.getProduct(action.payload).pipe(take(1))
      ),
      switchMap((product: Nullable<ProductModel>) => {
        return this.coveredLocationAdapter
          .getCoveredLocation(product as ProductModel)
          .pipe(
            switchMap((response) =>
              from([
                fromActions.getCoveredLocationSuccess({
                  payload: {
                    ...response,
                    productType: product?.type as ProductType,
                  },
                }),
              ])
            ),
            catchError((error) => {
              const safeError = this.errorSanitizerService.sanitizeError(
                error,
                product?.type
              );
              return from([
                fromActions.getCoveredLocationFail({ error: safeError }),
              ]);
            })
          );
      })
    )
  );

  updateCoveredLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCoveredLocation),
      mergeMap((action) =>
        this.store
          .select(
            CoveredLocationSelectors.buildCoveredLocationRequest(
              action.payload,
              action.includeMsbEstimateNumber
            )
          )
          .pipe(
            take(1),
            withLatestFrom(
              this.coveredLocationService.getCoveredLocation(action.payload)
            ),
            map(([request, existingCoveredLocation]) => ({
              request,
              correlationId: action.correlationId,
              existingCoveredLocation,
            }))
          )
      ),
      mergeMap(({ request, correlationId, existingCoveredLocation }) =>
        this.coveredLocationAdapter.updateCoveredLocation(request).pipe(
          switchMap((response: CoveredLocationEntity) => {
            let wasReconstructionCostUpdated = false;
            if (
              existingCoveredLocation.wasReconstructionCostUpdated ||
              existingCoveredLocation.reconstructionCost !==
                response.reconstructionCost
            ) {
              wasReconstructionCostUpdated = true;
            }
            return from([
              fromActions.updateCoveredLocationSuccess({
                payload: {
                  ...response,
                  wasReconstructionCostUpdated,
                  productType: request.product.type,
                },
                correlationId,
              }),
            ]);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.product.type
            );
            return from([
              fromActions.updateCoveredLocationError({
                error: safeError,
                correlationId,
              }),
            ]);
          })
        )
      )
    )
  );

  showLeakDetectionModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoveredLocationActions.showLeakDetectionModal),
        tap((action) => {
          this.modalService.leakDetectionModal(action.productType);
        })
      ),
    { dispatch: false }
  );

  toggleTaskWhenWaterProtectionDeviceAckChanges$ = createEffect(() =>
    combineLatest([
      this.store.select(CoveredLocationSelectors.getAllCoveredLocations),
      this.store.select(TaskSelectors.selectAllTasks),
      this.store.select(getAllFeatureFlags),
    ]).pipe(
      switchMap(([coveredLocations, tasks, featureFlags]) =>
        this.generateTaskActionsForAcknowledgement(
          coveredLocations,
          tasks,
          featureFlags
        )
      )
    )
  );

  private generateTaskActionsForAcknowledgement(
    coveredLocations: CoveredLocationModel[],
    tasks: TaskModel[],
    featureFlags: FeatureFlagsModel
  ): Action[] {
    const actions: Action[] = [];
    if (featureFlags.showLeakDetection) {
      for (const cl of coveredLocations) {
        const task = tasks.find(
          (t) =>
            t.entityType === 'coveredLocation' &&
            t.field === 'waterProtectionDeviceRequirementCommunicated' &&
            t.productType === cl.productType
        );
        if (!task) {
          if (!cl.waterProtectionDeviceRequirementCommunicated) {
            actions.push(this.acktionCreate(cl));
          }
        } else if (
          task.completed &&
          !cl.waterProtectionDeviceRequirementCommunicated
        ) {
          actions.push(this.acktionUncomplete(task));
        } else if (
          !task.completed &&
          cl.waterProtectionDeviceRequirementCommunicated
        ) {
          actions.push(this.acktionComplete(task));
        }
      }
    }
    return actions;
  }

  private acktionCreate(coveredLocation: CoveredLocationModel): Action {
    return TaskActions.addTask({
      payload: {
        page: HomeownerPageRepresentation,
        elementId: 'water-protection-device-requirement',
        field: 'waterProtectionDeviceRequirementCommunicated',
        entityType: 'coveredLocation',
        productType: coveredLocation.productType,
        ratingStatusOrdinal: 'Draft',
        message:
          'Please review and acknowledge water protection device requirement notice.',
        resolve: CoveredLocationActions.showLeakDetectionModal({
          productType: coveredLocation.productType,
        }),
        resolveLabel: 'Review leak detection eligibility',
      },
    });
  }

  private acktionUncomplete(task: TaskModel): Action {
    return TaskActions.uncompleteTask({ payload: task });
  }

  private acktionComplete(task: TaskModel): Action {
    return TaskActions.completeTask({ payload: task });
  }
}
