import { Injectable } from '@angular/core';
import {
  getSelectedProductsIncludingNWXInactive,
  getSelectedProductsWithoutErrors,
} from '@entities/product/product.selectors';
import { PreBindDocumentsFormService } from '@app/forms-store/services/pre-bind-documents-form.service';
import { TelematicsConfirmationModalComponent } from '@app/telematics/components/telematics-confirmation-modal/telematics-confirmation-modal.component';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { PreBindDocumentsModalComponent } from '../components/pre-bind-documents/pre-bind-documents-modal/pre-bind-documents-modal.component';
import { getPrimaryNamedInsured } from '@entities/member/member.selector';
import { SmartrideDataSharingConsentModalComponent } from '@app/telematics/components/smartride-data-sharing-consent-modal/smartride-data-sharing-consent-modal.component';
import { ButtonModalComponent } from '@shared/components/modals/button-modal/button-modal.component';
import { getAddressByType } from '@entities/address/address.selector';
import { getAccountId } from '@entities/account/account.selector';
import { ScheduledItemFormWrapperComponent } from '@app/coverages/scheduled-categories/scheduled-item-form-wrapper/scheduled-item-form-wrapper.component';
import { ScheduledCategoriesService } from '@core/services/scheduled-categories.service';
import {
  ScheduledCategoryItem,
  ScheduledItemFormWrapperInputs,
} from '@entities/scheduled-categories/scheduled-category.entity';
import { Nullable } from '@shared/utils/type.utils';
import {
  ConfirmationModalInputs,
  SimpleConfirmationModalComponent,
} from '@shared/components/simple-confirmation-modal/simple-confirmation-modal.component';
import { ModalEventMetadata } from '@app/coverages/scheduled-categories/scheduled-categories-container/scheduled-categories-container.component';
import { StringUtils } from '@shared/utils/string.utils';
import { SearchQuoteResult } from '@core/interfaces/interfaces';
import { OutboardMotor, VehicleModel } from '@core/models/views/vehicle.model';
import { OutboardMotorModalComponent } from '@app/boat/forms/outboard-motor-modal/outboard-motor-modal.component';
import { BoatTrailerFormComponent } from '@app/boat/forms/boat-trailer-form/boat-trailer-form.component';
import { DisambiguationModalComponent } from '@app/powersports/msa/forms/disambiguation-modal/disambiguation-modal.component';
import { DisambiguationOption } from '@app/powersports/msa/forms/powersports-year-make-model-form/powersports-year-make-model-form.component';
import { NavigationActions, SessionActions } from '@core/store/actions';
import { DvaContainerComponent } from '@app/vehicle/components/dva/dva-container.component';
import { ProductType } from '@core/models/api/dsm-types';
import { EligibilityModalContainerComponent } from '@app/eligibility/eligibility-modal-container/eligibility-modal-container.component';
import { AccountRegistrationContainerComponent } from '@app/online-account-registration/account-registration-container/account-registration-container.component';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { AdditionalInterestsFormContainerComponent } from '@app/additional-interests/additional-interests-form-container/additional-interests-form-container.component';
import { MsbEstimateModalComponent } from '@shared/components/msb-estimate-modal/msb-estimate-modal.component';
import { AnnouncementOverlayComponent } from '@app/announcement-overlay/announcement-overlay.component';
import {
  StructuresRentedModalComponent,
  StructuresRentedModalResult,
} from '@app/coverages/forms/structures-rented-modal/structures-rented-modal.component';
import {
  OtherStructureCoverage,
  OtherStructuresAdditionalDwellingUnit,
} from '@entities/other-structures/other-structures.reducer';
import {
  VehicleAdditionalInterestModalComponent,
  VehicleAdditionalInterestModalResult,
} from '@app/additional-interests/vehicle/vehicle-additional-interest-modal/vehicle-additional-interest-modal.component';
import {
  VehicleAdditionalInterestEntity,
  VehicleEntity,
} from '@entities/vehicle/vehicle.entity';
import { MemberModel } from '@core/models/views/person.model';
import { NamedInsuredModalComponent } from '@app/named-insured-modal/named-insured-modal.component';
import { ProductModel } from '@entities/product/product.model';
import { PremiumEntity } from '@entities/premium/premium.entity';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { CoveragesModalComponent } from '@app/hub/components/coverages-modal/coverages-modal.component';
import {
  MobileEnrollment,
  VehicleEnrollment,
} from '@entities/telematics/telematics.model';
import { PersonaRecommendationId } from '@entities/persona/persona.model';
import { CoverageModalComponent } from '@app/hub/components/coverage-modal/coverage-modal.component';
import { LeakDetectionModalComponent } from '@property/components/leak-detection/leak-detection-modal/leak-detection-modal.component';
import { LogService } from '@core/services/log.service';
import { LogEventName } from '@core/models/api/log.model';
import { InfractionsModalComponent } from '@shared/components/forms/infractions/infractions-modal/infractions-modal.component';
import { DriverIncidentViewModel } from '@core/models/views/driver-incident-view.model';
import { UwReferralModalComponent } from '@app/hub/components/uw-referral-modal/uw-referral-modal.component';

export interface ModalResultWithMetadata {
  items: any;
  metadata: ModalEventMetadata;
}
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private ngbModal: NgbModal,
    private store: Store,
    private preBindDocumentsFormService: PreBindDocumentsFormService,
    private scheduledCategoriesService: ScheduledCategoriesService,
    private log: LogService
  ) {}

  telematics(): Observable<any> {
    const modal = this.ngbModal.open(TelematicsConfirmationModalComponent, {
      centered: true,
      size: 'lg',
    });
    return from(modal.result.catch((e) => e));
  }

  preBindDocuments(): Observable<any> {
    const modal = this.ngbModal.open(PreBindDocumentsModalComponent, {
      centered: true,
      size: 'xl',
    });
    let isAutoSelected = false;
    let isMsaSelected = false;
    this.store
      .select(getSelectedProductsWithoutErrors)
      .pipe(take(1))
      .subscribe((products) => {
        isAutoSelected = !!products.find(
          (product) => product.type === 'PersonalAuto'
        );
        isMsaSelected = !!products.find((product) => product.type === 'MSA');
        modal.componentInstance.isAutoSelected = isAutoSelected;
        modal.componentInstance.isMsaSelected = isMsaSelected;
      });
    this.log.logUiEvent('acknowledge-pre-bind-docs-notice' as LogEventName, {
      productTypes: [
        isAutoSelected ? 'PersonalAuto' : '',
        isMsaSelected ? 'MSA' : '',
      ],
    });
    modal.componentInstance.resendEmail.subscribe(() => {
      const productTypes: ProductType[] = [];
      if (isAutoSelected) {
        productTypes.push('PersonalAuto');
      }
      if (isMsaSelected) {
        productTypes.push('MSA');
      }
      this.preBindDocumentsFormService.resendEmail(productTypes);
    });
    return from(modal.result);
  }

  driverVehicleAssignment(productType: ProductType): Observable<any> {
    const modal = this.ngbModal.open(DvaContainerComponent, {
      centered: true,
      size: 'xl',
      ariaLabelledBy: 'driverVehicleAssignment',
    });
    modal.componentInstance.productType = productType;
    return from(modal.result);
  }

  smartrideDataSharingConsent(): Observable<any> {
    const modal = this.ngbModal?.open(
      SmartrideDataSharingConsentModalComponent,
      {
        centered: true,
        size: 'lg',
        ariaLabelledBy: 'smartrideDataSharingHeader',
      }
    );
    return from(modal.result);
  }

  nonNwxProductPivot(): Observable<any> {
    const modal = this.ngbModal.open(ButtonModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'nonNwxProductPivot',
    });

    combineLatest([
      this.store.select(getSelectedProductsIncludingNWXInactive),
      this.store.select(getPrimaryNamedInsured),
      this.store.select(getAddressByType('Account')),
      this.store.select(getAccountId),
    ])
      .pipe(take(1))
      .subscribe(([products, pni, address, accountNumber]) => {
        const component = modal.componentInstance as ButtonModalComponent;

        component.headerText = 'Next Steps to continue in PolicyCenter';
        // Change all these constants back at some point NonNWXProductPivotToPolicyCenterText;
        component.primaryButtonText = 'Continue to PolicyCenter account';
        // change back to the constant NonNWXProductPivotToPolicyCenterButtonText;
        component.secondaryButtonText = 'Start a new quote';
        component.primaryButtonAction = NavigationActions.linkToPolicyCenter({
          payload: { reason: 'product page pivot', urlType: 'Account' },
        });
        component.secondaryButtonAction = NavigationActions.reloadApp();
        component.content = [
          {
            header: 'Account has been created',
            contentType: 'tile',
            iconUrl: '/assets/images/icons/person-circle-colorized.svg',
            orderedText: {
              first: pni?.person?.firstName + ' ' + pni?.person?.lastName,
              second:
                (address?.addressLine1 || '') +
                ', ' +
                (address?.city || '') +
                ' ' +
                (address?.state || '') +
                ' ' +
                (address?.postalCode || ''),
              third: 'Account # ' + accountNumber,
            },
          },
          {
            header: 'Quote products in PolicyCenter',
            text: [
              `In order to continue with the products you have selected, follow the link below to the PolicyCenter Account to complete your quote.\n\n`,
              `Tap "New Submission" to add these products with this customer's account.`,
            ],
            contentType: 'productTiles',
            products: products.filter((product) => !product.isDsmActive),
          },
        ];
        component.notification = {
          type: 'info-circle-filled',
          header:
            StringUtils.createProductNameList(
              products.filter((product) => !product.isDsmActive)
            ) +
            ' policy quoting is temporarily only supported in PolicyCenter.',
          message:
            'The products you have selected will be made available in Nationwide Express® soon! Please use PolicyCenter to quote and bind these at this time.',
        };
        component.changeDetector.markForCheck();
      });
    return from(modal.result);
  }

  nonNwxProductHubPivot(): Observable<any> {
    const modal = this.ngbModal.open(ButtonModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'nonNwxProductPivot',
    });

    combineLatest([
      this.store.select(getSelectedProductsIncludingNWXInactive),
      this.store.select(getPrimaryNamedInsured),
      this.store.select(getAddressByType('Account')),
      this.store.select(getAccountId),
    ])
      .pipe(take(1))
      .subscribe(([products, pni, address, accountNumber]) => {
        const component = modal.componentInstance as ButtonModalComponent;

        component.headerText = 'Next Steps to continue in PolicyCenter';
        component.primaryButtonText = 'Continue to PolicyCenter account';
        component.secondaryButtonText = 'Return to Hub';
        component.primaryButtonAction = NavigationActions.linkToPolicyCenter({
          payload: { reason: 'hub page pivot', urlType: 'Account' },
        });
        component.secondaryButtonAction = 'closeModal';
        component.content = [
          {
            header: 'Success, account created',
            contentType: 'tile',
            iconUrl: '/assets/images/icons/person-circle-colorized.svg',
            orderedText: {
              first: pni?.person?.firstName + ' ' + pni?.person?.lastName,
              second:
                (address?.addressLine1 || '') +
                ', ' +
                (address?.city || '') +
                ' ' +
                (address?.state || '') +
                ' ' +
                (address?.postalCode || ''),
              third: 'Account # ' + accountNumber,
            },
          },
          {
            header: 'Submissions created',
            contentType: 'productTiles',
            products: products.filter((product) => product.isDsmActive),
          },
          {
            header: 'Quote products in PolicyCenter',
            text: [
              `In order to continue with the products you have selected, follow the link below to the PolicyCenter Account to complete your quote.\n\n`,
              `Tap "New Submission" to add these products with this customer's account.`,
            ],
            contentType: 'productTiles',
            products: products.filter((product) => !product.isDsmActive),
          },
        ];
        component.notification = {
          type: 'info-circle-filled',
          header:
            StringUtils.createProductNameList(
              products.filter((product) => !product.isDsmActive)
            ) +
            ' policy quoting is temporarily only supported in PolicyCenter.',
          message:
            'The products you have selected will be made available in Nationwide Express® soon! Please use PolicyCenter to quote and bind these at this time.',
        };
        component.changeDetector.markForCheck();
      });
    return from(modal.result);
  }

  unsupportedRetrievePivot(): Observable<any> {
    const modal = this.ngbModal.open(ButtonModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'nonNwxProductPivot',
    });

    combineLatest([
      this.store.select(getSelectedProductsIncludingNWXInactive),
      this.store.select(getPrimaryNamedInsured),
      this.store.select(getAddressByType('Account')),
      this.store.select(getAccountId),
    ])
      .pipe(take(1))
      .subscribe(([products, pni, address, accountNumber]) => {
        const component = modal.componentInstance as ButtonModalComponent;

        component.headerText = 'Next Steps to continue in PolicyCenter';
        component.primaryButtonText = 'Continue to PolicyCenter account';
        component.secondaryButtonText = 'Return to search';
        component.primaryButtonAction = NavigationActions.linkToPolicyCenter({
          payload: { reason: 'product page pivot', urlType: 'Account' },
        });
        component.secondaryButtonAction = SessionActions.clearSessionState();
        component.content = [
          {
            header: 'Retrieved account details',
            contentType: 'tile',
            iconUrl: '/assets/images/icons/person-circle-colorized.svg',
            orderedText: {
              first: pni?.person?.firstName + ' ' + pni?.person?.lastName,
              second:
                (address?.addressLine1 || '') +
                ', ' +
                (address?.city || '') +
                ' ' +
                (address?.state || '') +
                ' ' +
                (address?.postalCode || ''),
              third: 'Account # ' + accountNumber,
            },
          },
          {
            header: 'Quote products in PolicyCenter',
            text: [
              `In order to continue with the products you have selected, follow the link below to the PolicyCenter Account to complete your quote.\n\n`,
              `Tap "New Submission" to add these products with this customer's account.`,
            ],
            contentType: 'productTiles',
            products: products.filter((product) => !product.isDsmActive),
          },
        ];
        component.notification = {
          type: 'info-circle-filled',
          header:
            StringUtils.createProductNameList(
              products.filter((product) => !product.isDsmActive)
            ) +
            ' policy quoting is temporarily only supported in PolicyCenter.',
          message:
            'The products you have selected will be made available in Nationwide Express® soon! Please use PolicyCenter to quote and bind these at this time.',
        };
        component.changeDetector.markForCheck();
      });
    return from(modal.result);
  }

  scheduledItemFormWrapper(inputs: ScheduledItemFormWrapperInputs): Observable<{
    item: Nullable<ScheduledCategoryItem>;
    metadata: ModalEventMetadata;
  }> {
    const modal = this.ngbModal.open(ScheduledItemFormWrapperComponent, {
      centered: true,
      size: 'xl',
      ariaLabelledBy: 'scheduledItemFormModalHeader',
    });
    modal.componentInstance.parentCategory$ =
      this.scheduledCategoriesService.findCategoryByEntityId(
        inputs.productType,
        inputs.entityId
      );
    modal.componentInstance.scheduledItem = inputs.currentItem;
    modal.componentInstance.scheduledItemType = inputs.scheduledItemType;
    modal.componentInstance.personalEffectItemsAdded$ =
      inputs.scheduledItemsAdded$;
    modal.componentInstance.allScheduledCategories$ =
      inputs.allScheduledCategories$;
    modal.componentInstance.productType = inputs.productType;
    return from(modal.result);
  }

  // modal will close with 'confirm' or 'cancel' as a result
  simpleConfirmationModal(
    size: string,
    inputs: ConfirmationModalInputs,
    options?: NgbModalOptions | undefined
  ): Observable<string> {
    const modal = this.ngbModal.open(SimpleConfirmationModalComponent, {
      centered: true,
      size: 'lg',
      ariaLabelledBy: 'confirmationModalTitle',
      windowClass: 'simple-confirm-modal-window',
      backdropClass: 'simple-confirm-modal-backdrop',
      ...(options || {}),
    });
    modal.componentInstance.headerTitle = inputs.headerTitle;
    modal.componentInstance.bodyMessage = inputs.bodyMessage;
    if (inputs.cancelBtnText) {
      modal.componentInstance.cancelBtnText = inputs.cancelBtnText;
    }
    if (inputs.forwardBtnText) {
      modal.componentInstance.forwardBtnText = inputs.forwardBtnText;
    }
    if (inputs.notificationType) {
      modal.componentInstance.notificationType = inputs.notificationType;
    }
    if (inputs.notificationMessage) {
      modal.componentInstance.notificationMessage = inputs.notificationMessage;
    }
    return from(modal.result);
  }

  outboardMotorModal(inputs: {
    vehicle: Partial<VehicleModel>;
    currentMotor?: Nullable<Partial<OutboardMotor>>;
  }): Observable<ModalResultWithMetadata> {
    const modal = this.ngbModal.open(OutboardMotorModalComponent, {
      size: 'lg',
      ariaLabelledBy: 'outboardMotorModalHeader',
      centered: true,
    });
    modal.componentInstance.vehicleId = inputs.vehicle.vehicleId;
    modal.componentInstance.vehicleNumOfMotors = inputs.vehicle.maxNoEngines;
    if (inputs.vehicle.motorDetails?.length) {
      modal.componentInstance.motorList = inputs.vehicle.motorDetails;
    }
    if (inputs.currentMotor) {
      modal.componentInstance.currentMotor = inputs.currentMotor;
    } else {
      let motorNumbers = inputs.vehicle.motorDetails?.map(
        (m) => m.nwmotorNumber || 0
      ) || [0];

      // [] is truthy, and we need at least [0]
      if (motorNumbers?.length === 0) {
        motorNumbers = [0];
      }
      let newMotorNumb = Math.max(...motorNumbers) + 1;
      modal.componentInstance.currentMotor = {
        nwmotorNumber: newMotorNumb,
      };
    }
    return from(modal.result);
  }

  trailerInfoModal(
    vehicle: Partial<VehicleModel>,
    vehicleIndex: number
  ): Observable<any> {
    const modal = this.ngbModal.open(BoatTrailerFormComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      ariaLabelledBy: 'boatTrailerModalHeader',
    });
    modal.componentInstance.vehicle = vehicle;
    modal.componentInstance.vehicleIndex = vehicleIndex;

    return from(modal.result);
  }

  disambiguationModal(inputs: {
    options: DisambiguationOption[];
    header: string;
    subHeader: string;
  }): Observable<string> {
    const modal = this.ngbModal.open(DisambiguationModalComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      ariaLabelledBy: 'disambiguationModalHeader',
      backdrop: 'static',
    });
    modal.componentInstance.disambiguationOptions = inputs.options;
    modal.componentInstance.header = inputs.header;
    modal.componentInstance.subHeader = inputs.subHeader;
    return from(modal.result);
  }

  eligibilityModal(productType: ProductType, quoteId: string): Observable<any> {
    const modal = this.ngbModal.open(EligibilityModalContainerComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
    });
    modal.componentInstance.productType = productType;
    modal.componentInstance.quoteId = quoteId;
    modal.componentInstance.ngOnChanges({ productType: {}, quoteId: {} });
    return from(modal.result);
  }

  additionalInterestsModal(inputs: {
    productType: ProductType;
    mode: 'add' | 'edit';
    interest?: MortgageModel;
  }): Observable<any> {
    const modal = this.ngbModal.open(
      AdditionalInterestsFormContainerComponent,
      {
        size: 'lg',
        centered: true,
        ariaLabelledBy: 'addlInterestsModalHeader',
      }
    );
    modal.componentInstance.productType = inputs.productType;
    modal.componentInstance.mode = inputs.mode;
    modal.componentInstance.interest = inputs.interest;

    return from(modal.result);
  }

  vehicleAdditionalInterestsModal(inputs: {
    productType: ProductType;
    mode: 'add' | 'edit';
    vehicle: Partial<VehicleModel>;
    interest?: VehicleAdditionalInterestEntity;
  }): Promise<VehicleAdditionalInterestModalResult> {
    const modal: NgbModalRef = this.ngbModal.open(
      VehicleAdditionalInterestModalComponent,
      {
        size: 'lg',
        centered: true,
        ariaLabelledBy: 'vehicleAdditionalInterest',
      }
    );
    modal.componentInstance.productType = inputs.productType;
    modal.componentInstance.mode = inputs.mode;
    modal.componentInstance.vehicle = inputs.vehicle;
    modal.componentInstance.interest = inputs.interest;
    return modal.result;
  }

  private getCommonQuote(
    quotes: SearchQuoteResult[]
  ): Nullable<SearchQuoteResult> {
    // untyped and dangerous
    return quotes.find((quote) => !!quote.accountNumber);
  }

  accountRegistrationModal(): Observable<any> {
    const modal = this.ngbModal.open(AccountRegistrationContainerComponent, {
      size: 'lg',
      centered: true,
      ariaLabelledBy: 'accountRegModalHeader',
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.isModal = true;
    return from(modal.result);
  }

  msbEstimateModal(
    associatedMSBEstimateNumber: Nullable<string>,
    initialMSBEstimateNumber: Nullable<string>,
    productType: Nullable<ProductType>,
    isLaunchedFromHubPage: boolean
  ): Observable<any> {
    const modal = this.ngbModal.open(MsbEstimateModalComponent, {
      centered: true,
      ariaLabelledBy: 'msbEstimateHeader',
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.associatedMSBEstimateNumber =
      associatedMSBEstimateNumber;
    modal.componentInstance.initialMSBEstimateNumber = initialMSBEstimateNumber;
    modal.componentInstance.productType = productType;
    modal.componentInstance.isLaunchedFromHubPage = isLaunchedFromHubPage;
    return from(modal.result);
  }

  announcementModal(): void {
    const modal = this.ngbModal.open(AnnouncementOverlayComponent, {
      animation: false,
      backdropClass: 'bolt-modal-backdrop',
      modalDialogClass: 'no-margin',
      ariaLabelledBy: 'annnouncementModalHeader',
    });
  }

  structuresRentedModal(
    unit: OtherStructuresAdditionalDwellingUnit | null,
    coverage: OtherStructureCoverage
  ): Promise<StructuresRentedModalResult> {
    const modal = this.ngbModal.open(StructuresRentedModalComponent, {
      size: 'lg',
    });
    const component = modal.componentInstance as StructuresRentedModalComponent;
    component.setUnit(unit);
    component.setCoverage(coverage);
    return modal.result;
  }

  namedInsuredModal(
    product: ProductModel,
    members: MemberModel[]
  ): Observable<any> {
    const modal = this.ngbModal.open(NamedInsuredModalComponent, {
      centered: true,
      ariaLabelledBy: 'namedInsuredHeader',
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.product = product;
    modal.componentInstance.members = members;
    return from(modal.result);
  }

  coveragesModal(
    coverages: CoverageEntity[],
    premiums: PremiumEntity[],
    product: ProductModel,
    vehicles: VehicleEntity[],
    enrollment: Nullable<VehicleEnrollment | MobileEnrollment>
  ): void {
    const modal = this.ngbModal.open(CoveragesModalComponent, {
      centered: true,
      size: 'md',
    });
    modal.componentInstance.coverages = coverages;
    modal.componentInstance.premiums = premiums;
    modal.componentInstance.product = product;
    modal.componentInstance.vehicles = vehicles;
    modal.componentInstance.enrollment = enrollment;
  }

  coverageModal(
    modalTitle: string,
    recommendationId: PersonaRecommendationId,
    productType: ProductType
  ): Promise<void> {
    const modal = this.ngbModal.open(CoverageModalComponent, {
      centered: true,
      size: 'md',
    });
    modal.componentInstance.modalTitle = modalTitle;
    modal.componentInstance.recommendationId = recommendationId;
    modal.componentInstance.productType = productType;
    return modal.componentInstance.result;
  }

  leakDetectionModal(productType: ProductType): Observable<any> {
    const modal = this.ngbModal.open(LeakDetectionModalComponent, {
      centered: true,
      size: 'lg',
    });
    modal.componentInstance.productType = productType;
    return from(modal.result);
  }

  infractionModal(
    infraction: DriverIncidentViewModel | undefined
  ): Observable<any> {
    const modal = this.ngbModal.open(InfractionsModalComponent, {
      size: 'lg',
      centered: true,
    });
    modal.componentInstance.infraction = infraction;
    modal.componentInstance.mode = !!infraction ? 'Edit' : 'Add';
    return from(modal.result);
  }

  uwReferralModal(productType: ProductType): Observable<any> {
    this.log.logUiEvent('open-uw-referral-modal', productType);
    const modal = this.ngbModal.open(UwReferralModalComponent, {
      centered: true,
      size: 'lg',
    });
    modal.componentInstance.productType = productType;
    modal.componentInstance.ngOnChanges?.({
      productType: {} as any,
    });
    return from(modal.result);
  }
}
