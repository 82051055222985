import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AdvancedPicklistItem } from '@core/models/advanced-picklist.model';
import { AgencyService } from '@core/services/agency.service';
import { DummyProducerProduct } from '@core/store/retrieve/retrieve.selector';
import { AgencyModel } from '@entities/agency/agency.model';
import { ProductModel } from '@entities/product/product.model';
import { Observable, Subject } from 'rxjs';
import { ProductType } from '@core/models/api/dsm-types';

@Component({
  selector: 'nwx-dummy-producer-form-container',
  templateUrl: './dummy-producer-form-container.component.html',
  styleUrls: ['./dummy-producer-form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyProducerFormContainerComponent implements OnInit {
  @Input() modalSubmitted!: Subject<boolean>;
  @Input() modalClosed!: Subject<boolean>;
  @Input() products!: DummyProducerProduct[];
  @Input() selectedProducts!: ProductModel[];
  @Output() error = new EventEmitter<any>();

  @Output()
  formReady = new EventEmitter<FormGroup>();

  agency$: Observable<AgencyModel>;
  form!: FormGroup;

  prefill = new Subject<AdvancedPicklistItem>();

  constructor(private fb: FormBuilder, private agencyService: AgencyService) {
    this.agency$ = this.agencyService.getAgency();
  }

  ngOnInit() {
    this.form = this.fb.group({});
    this.formReady.emit(this.form);
  }

  prefillEmpty(value: AdvancedPicklistItem) {
    this.prefill.next(value);
  }

  isProductSelected(type: ProductType): boolean {
    return this.selectedProducts?.some((p) => p.type == type) || false;
  }
}
