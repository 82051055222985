<form
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="earthquake-details"
  *ngIf="vm$ | async as vm"
>
  <div [class.card-padding]="!vm.insideModal">
    <h2>Earthquake questions</h2>
    <div class="bolt-container-fluid inner-card">
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          Will earthquake coverage be added to this location?
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            ngDefaultControl
            formControlName="isEarthquakeCoverageExpected"
            [attr.horizontal]="horizontalRadio || null"
            id="eqf-isEarthquakeCoverageExpected"
            required
            (change)="onEarthquakeCoverageExpectedChange($event)"
            [error]="errorMessage['isEarthquakeCoverageExpected']"
          >
            <bolt-radio value="true">Yes</bolt-radio>
            <bolt-radio value="false">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      @if (vm.showAdditionalEarthquakeQuestions) {
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          Earthquake masonry veneer covered?
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            ngDefaultControl
            formControlName="isMasonryVeneerCoverageExpected"
            [attr.horizontal]="horizontalRadio || null"
            id="eqf-isMasonryVeneerCoverageExpected"
            required
            [error]="errorMessage['isMasonryVeneerCoverageExpected']"
          >
            <bolt-radio value="true">Yes</bolt-radio>
            <bolt-radio value="false">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          Is there any unrepaired structural earthquake damage?
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            ngDefaultControl
            formControlName="isUnrepairedEQDamageExists"
            [attr.horizontal]="horizontalRadio || null"
            id="eqf-isUnrepairedEQDamageExists"
            required
            [error]="errorMessage['isUnrepairedEQDamageExists']"
          >
            <bolt-radio value="true">Yes</bolt-radio>
            <bolt-radio value="false">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          Is the dwelling within 100 feet of a slope of more than 30 degrees?
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            ngDefaultControl
            formControlName="isDwellingNearSlope"
            [attr.horizontal]="horizontalRadio || null"
            id="eqf-isDwellingNearSlope"
            required
            [error]="errorMessage['isDwellingNearSlope']"
          >
            <bolt-radio value="true">Yes</bolt-radio>
            <bolt-radio value="false">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          Are there any extensive overhangs on the dwelling?
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            ngDefaultControl
            formControlName="doExtensiveOverhangsExist"
            [attr.horizontal]="horizontalRadio || null"
            id="eqf-doExtensiveOverhangsExist"
            required
            [error]="errorMessage['doExtensiveOverhangsExist']"
          >
            <bolt-radio value="true">Yes</bolt-radio>
            <bolt-radio value="false">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      }
    </div>
  </div>
</form>
