import { ChangeDetectorRef } from '@angular/core';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { UpdateQuoteResponse } from '@core/models/api/response/update-quote-response.model';
import { ProductsService } from '@core/services/products.service';
import { QuoteService } from '@core/services/quote.service';
import {
  ProductModel,
  ProductWithState,
} from '@core/store/entities/product/product.model';
import { AgencyModel } from '@entities/agency/agency.model';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, forkJoin } from 'rxjs';
import { finalize, map, take, withLatestFrom } from 'rxjs/operators';
import { AgencyService } from '../../../core/services/agency.service';
import { OnlineAccountRegHelpText } from '@shared/constants/help-text-constants';
import { NavigationService } from '@core/services/navigation.service';
import { UserService } from '@core/services/user.service';
import { ProducerSearch } from '@core/adapters/producer-search.model';

type ProductMap<T> = {
  [Product in ProductType]?: T;
};

@Component({
  selector: 'nwx-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnChanges {
  @Input() agency: Nullable<AgencyModel>;
  @Input() products: Nullable<ProductModel[]> = [];
  @Input() productStateMap: Nullable<ProductWithState[]>;
  @Input() accountId: Nullable<string> = '';
  @Input() pni: Nullable<PolicyHolderEntity>;

  productStates = new Map<ProductType, string>();
  form: FormGroup;
  submitting = false;
  editing: ProductMap<boolean> = {};
  editingAny = false;
  agencyCode: string;
  canEdit = true;
  accountSetupDisclaimer = OnlineAccountRegHelpText;
  editingAccountRegistration: boolean = false;
  submitAccountRegistration: Subject<void> = new Subject<void>();
  agencyCodes$: Observable<string[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private quoteService: QuoteService,
    private agencyService: AgencyService,
    private productsService: ProductsService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
    this.form = fb.group({});
    this.agencyCode = '';
    this.agencyCodes$ = this.agencyService.getStoredProducerCodes().pipe(
      map((producerSearch) => {
        return Array.from(
          new Set(
            (producerSearch?.producers || []).map(
              (producer) => producer.agencyCode
            )
          )
        ).sort();
      })
    );
  }

  ngOnChanges(changes: ComponentChanges<AccountInfoComponent>) {
    if (changes.agency) {
      this.agencyCode = this.agency?.agent.agencyCode || '';
    }
    if (changes.products) {
      this.canEdit = !!this.products?.find(
        (p) => p.quoteStatus !== 'Issued' && p.quoteStatus !== 'Binding'
      );
    }
  }

  stateForProduct(product: ProductType): string {
    return (
      this.productStateMap?.find((p) => p.product === product)?.state || ''
    );
  }

  trackEdit(product: ProductType) {
    this.editing[product] = true;
    this.editingAny = true;
  }

  close(): void {
    this.activeModal.close();
  }

  save(): void {
    if (this.form.valid) {
      if (this.editingAccountRegistration) {
        this.submitAccountRegistration.next();
      }
      const calls: Observable<UpdateQuoteResponse>[] = [];
      for (let product of this.products || []) {
        if (this.agencyCode && this.editing[product.type]) {
          const producerOption = this.form.get(product.type + '-producerCode');
          const agencyOption = this.form.get(product.type + '-agencyCode');
          if (!producerOption?.value) break;
          if (agencyOption?.value) {
            this.agencyService.replaceAgencyFromSearch(
              producerOption.value,
              agencyOption.value
            );
          }
          calls.push(
            this.quoteService.updateQuote({
              productType: product.type,
              body: {
                productType: product.type,
                producer: {
                  agencyCode: agencyOption?.value || this.agencyCode,
                  producerCode: producerOption.value,
                },
              },
              quoteId: product.quoteId,
            })
          );
        }
      }
      this.submitting = true;
      forkJoin(calls)
        .pipe(
          finalize(() => {
            this.submitting = false;
            this.editingAny = false;
            this.close();
          }),
          withLatestFrom(this.agencyService.getProducerCodes()),
          take(1)
        )
        .subscribe({
          next: ([calls, search]) => {
            this.submitting = false;
            for (let response of calls) {
              const product = this.products?.find(
                (p) => p.quoteId == response.quoteId
              );
              if (!product || !this.editing[product.type]) continue;
              this.editing[product.type] = false;
              const matchingAgent = search.producers.find(
                (p) => p.producerCode == response.producer.producerCode
              );
              this.productsService.setProducer(product.type, {
                ...response.producer,
                agentName:
                  matchingAgent?.agentName || product.producer?.agentName || '',
              });
            }
            this.editingAny = false;
          },
        });
    } else {
      this.navigationService.submitPage();
    }
  }

  onToggleDisclaimer(): void {
    this.editingAny = true;
    this.editingAccountRegistration = true;
  }
  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }
}
