import { HttpParamsUtils } from '@shared/utils/http-params.utils';
import { getAddressForProduct } from '@core/store/entities/address/address.selector';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { CoreState } from '../store/reducers';
import * as fromDsmAction from '@core/store/entities/dsm/dsm.action';
import * as metaDataActions from '@core/store/entities/metadata/metadata.action';
import * as fromLayoutActions from '@core/store/entities/layout/layout.action';
import { map, take, tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
} from 'rxjs';
import { DateUtils } from '@shared/utils/date.utils';
import { environment } from 'src/environments/environment';
import { updateProduct } from '../store/entities/product/product.actions';
import { createAccountSuccess } from '../store/entities/account/account.action';
import { AccountResponse } from '../models/api/response/account-response.model';
import { getAccountId } from '../store/entities/account/account.selector';
import { getProduct } from '../store/entities/product/product.selectors';
import { upsertAddress } from '@core/store/entities/address/address.action';
import { Nullable } from '@shared/utils/type.utils';
import { DSM_STAGE_TO_TEST_TARGET_ENV } from '@shared/constants/app-constants';

const enum Environments {
  DEV = 'dev',
  LOCALDESKTOP = 'localdesktop',
  PROD = 'prod',
  STAGE = 'stage',
  TEST = 'test',
}

// TODO cleanse this list
export interface AppConfig {
  production: boolean;
  appName: string;
  apiKey: string;
  environmentName: 'localdesktop' | 'dev' | 'test' | 'stage' | 'prod';
  standardizedAddressUrl: string;
  standardizedAddressPartnerId: string;
  powersportsApiUrl: string;
  powersportsVehicleInquiryUrl: string;
  vehicleInquiryUrl: string;
  suggestAddressUrl: string;
  multiproductApi: string;
  splunkApiUrl: string;
  splunkToken: string;
  captchaApiUrl: string;
  captchaSiteToken: string;
  captchaEnabled: boolean;
  internetRegistrationUrl: string;
  billingApiUrl: string;
  personalLinesBillingApiUrl: string;
  moneyProcessingUrl: string;
  preferencesUrl: string;
  pciV2Url: string;
  downPaymentUrl: string;
  moneyProcessingApiUrl: string;
  billingTargetEnv: string;
  distributedDocumentsUrl: string;
  distributedDocumentsTargetEnv: string;
  compraterRoutingUrl: string;

  // DSM
  pcEdgeAutoUrl: string;
  pcEdgeCondoUrl: string;
  pcEdgeHomeownersUrl: string;
  pcEdgeLifeUrl: string;
  pcEdgeRentersUrl: string;
  pcEdgeUmbrellaUrl: string;
  pcEdgeDwellingFireUrl: string;
  pcEdgePowerSportsUrl: string;
  pcEdgeBoatUrl: string;
  pcEdgeRVUrl: string;
  pcEdgePolicyIssueUrl: string;
  pcEdgeSearchQuotesUrl: string;
  pcEdgeCompraterRetrieveUrl: string;
  pcEdgeAgentAccountManagementUrl: string;
  pcEdgePersonalLinesUtility: string;
  pcEdgeAutoPolicyManagementUrl: string;
  pcEdgeHomeownersPolicyManagementUrl: string;
  pcEdgeCondoPolicyManagementUrl: string;
  pcEdgeRentersPolicyManagementUrl: string;
  pcEdgeUmbrellaPolicyManagementUrl: string;
  pcEdgeDwellingFirePolicyManagementUrl: string;
  pcEdgePowerSportsPolicyManagementUrl: string;
  pcEdgeBoatPolicyManagementUrl: string;
  pcEdgeRVPolicyManagementUrl: string;
  pcInternalDeepLaunchUrl: string;
  pcExternalDeepLaunchUrl: string;
  driverVehiclePrefillUrl: string;
  euaUrl: string;
  euaUserInfoUrl: string;
  vehicleDetailsUrl: string;
  mortgageCompaniesUrl: string;
  targetEnv?: string;
  targetEnvPCType?: string;
  semafoneFragmentUrl: string;
  semafoneResponseUrl: string;
  semafoneClientReference: string;
  semafoneLicenseCode: string;
  contentfulSpaceId: string;
  contentfulAccessToken: string;
  contentfulPreviewAccessToken: string;
  contentfulEnvironment: string;
  contentfulHost: string;
  contentfulBasePath: string;
  escrowServiceUrl: string;
  // telematicsEnrollmentsUrl: string; -- Deprecated, use auto API endpoints
  natGenApiUrl: string;
  quoteAccountManagement: string;
  distributionPartnerManagementUrl: string;
  distributionPartnerManagementScope: string;
  jitLicensedProducersUrl: string;
  jitLicenseDetailsUrl: string;
  agentAppointmentFilteringUrl: string;
  versionDate: string;
  commitMessage: string;
  commitAuthor: string;
  commitDateTime: string;
  commitBranch: string;
  futureEffectiveDate: string;
  ignoreRiskFilter: boolean;
  autofill: boolean;
  autofillTarget: Nullable<string>;
  autoApproveUW: boolean;
  errorHandlerApiUrl: string;
  licensingAgencyAccountsUrl: string;
  docVaultExternalUrl: string;
  docVaultInternalUrl: string;
}

interface UrlMap {
  [key: string]: string;
}

const prodUrl = 'nationwideexpress.nationwide.com';
const localUrl = 'localhost:4200';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig!: AppConfig;
  private output = new BehaviorSubject<string>('');
  // TODO add other urls
  private urlMap: UrlMap = {
    'localhost:4200': 'localdesktop',
    'nationwideexpresstest.awspubliccloud.nationwide.com': 'test',
    'nwx-test1.apps.nwie.net': 'test',
    'nationwideexpress.nationwide.com': 'prod',
  };
  private testUrlRegex = /nwx-kube-test-\d+.apps.nwie.net/;
  private calculatedHost: null | string = null;

  constructor(
    private http: HttpClient,
    private store: Store<CoreState>,
    private window: Window
  ) {
    if (!environment.production) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const aw = this.window as any;
      aw.printNwxTestSessionConfig = this.printTestSessionConfig.bind(this);
    }
  }

  async loadAppConfig(): Promise<AppConfig> {
    this.applyTestSessionConfig();
    const data = await firstValueFrom(
      this.http.get<AppConfig>(`/assets/config/environment-${this.host}.json`)
    );
    this.appConfig = data;
    this.adjustConfigWithQueryParamsTestOnly();
    this._addBootstrapTags(data);
    this.deliverContentToStore();
    return data;
  }

  get config(): AppConfig {
    return this.appConfig;
  }

  set targetEnv(targetEnv: string) {
    this.config.targetEnv = targetEnv;
  }

  set targetEnvPCType(type: string) {
    this.config.targetEnvPCType = type;
  }

  set billingTargetEnv(targetEnv: string) {
    this.config.billingTargetEnv = targetEnv;
  }

  set ignoreRiskFilter(ignore: boolean) {
    this.config.ignoreRiskFilter = ignore;
  }
  set autofill(enabled: boolean) {
    this.config.autofill = enabled;
  }

  set autofillTarget(target: Nullable<string>) {
    this.config.autofillTarget = target;
  }

  set autoApproveUW(enabled: boolean) {
    this.config.autoApproveUW = enabled;
  }

  get autofillTarget() {
    return this.config.autofillTarget;
  }

  get host(): string {
    if (!this.calculatedHost) {
      const host = this.window.location.host;
      if (this.testUrlRegex.test(host)) return 'test';
      if (
        this.window.location.host !== prodUrl &&
        this.isCompraterRoute(this.window.location)
      ) {
        this.calculatedHost = 'stage';
      } else if (
        this.window.location.host !== prodUrl &&
        this.window.location.host !== localUrl &&
        this.window.sessionStorage.getItem('environmentName')
      ) {
        this.calculatedHost =
          this.window.sessionStorage.getItem('environmentName') ||
          this.urlMap[host];
      } else {
        this.calculatedHost = this.urlMap[host];
      }
    }

    return this.calculatedHost;
  }

  private isCompraterRoute(location: Location): boolean {
    const requestedRoute = HttpParamsUtils.extractRequestedRoute(location.hash);
    return (
      location.pathname === '/retrieve/compRater' ||
      (requestedRoute !== null &&
        requestedRoute.startsWith('/retrieve/compRater'))
    );
  }

  get isKubeTestEnv(): boolean {
    return !this.isProd() && this.testUrlRegex.test(this.window.location.host);
  }

  // get agencyIdentifier(): string {
  //   const mockIdentifier = sessionStorage.getItem('privateLabelIdentifier');
  //   if (this.isTest() && mockIdentifier) {
  //     return mockIdentifier;
  //   } else {
  //     const [agencyDomain] = window.location.hostname.split('.');

  //     return agencyDomain;
  //   }
  // }

  // isPrivateLabelDomain(): boolean {
  //   return Object.keys(PRIVATE_LABEL_DOMAIN_MAP).some((domain) => {
  //     const [agencyDomain] = domain.split('.');
  //     return this.agencyIdentifier === agencyDomain;
  //   });
  // }

  isProd(): boolean {
    return this.host === Environments.PROD;
  }

  isLocal(): boolean {
    return this.host === Environments.LOCALDESKTOP;
  }

  isTest(): boolean {
    return this.host === Environments.TEST;
  }

  isStage(): boolean {
    return this.host === Environments.STAGE;
  }

  // getUrlParameters(): HttpParams {
  //   const url = window.location.href;
  //   if (url.includes('?')) {
  //     return new HttpParams({ fromString: url.split('?')[1] });
  //   }
  //   return null;
  // }

  private _addBootstrapTags(data: AppConfig): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (data.production) {
      script.src = '//tags.nationwide.com/Bootstrap.js';
    } else {
      script.src = '//tags.nationwide.com/test/Bootstrap.js';
    }

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private deliverContentToStore(): void {
    this.effectiveDateInitializerFn();
    this.store.dispatch(fromLayoutActions.getInitialSize());
    this.store.dispatch(
      fromDsmAction.setTargetEnv({ targetEnv: this.config.targetEnv || '' })
    );
    this.store.dispatch(
      fromDsmAction.setApplicationName({
        applicationName: this.config.appName,
      })
    );
    this.store.dispatch(
      metaDataActions.setVersionInformation({
        payload: {
          dateTime: this.config.versionDate,
          commitAuthor: this.config.commitAuthor,
          commitMessage: this.config.commitMessage,
          commitDateTime: this.config.commitDateTime,
          commitBranch: this.config.commitBranch,
          deployEnv: this.config.environmentName,
        },
      })
    );
  }

  private applyTestSessionConfig(): void {
    if (
      environment.production ||
      !environment.hasOwnProperty('testSessionConfig')
    ) {
      return;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const config = (environment as any).testSessionConfig;
    console.log(`AppConfigService applying testSessionConfig`, config);
    this.window.sessionStorage.setItem('access_token', config.accessToken);
    this.window.sessionStorage.setItem(
      'jwt',
      JSON.stringify(config.jwtContent)
    );
    // Changes to Product will be overwritten when the metadata loads.
    // For lack of a clean solution, sleep a bit then update product later...
    this.window.setTimeout(() => {
      this.store.dispatch(
        updateProduct({
          payload: {
            id: 'PersonalAuto',
            changes: {
              quoteId: config.autoQuoteId,
              sessionId: config.autoSessionId,
            },
          },
        })
      );
    }, 2000);
    this.store.dispatch(
      createAccountSuccess({
        response: {
          accountId: config.accountId,
        } as AccountResponse,
      })
    );
    if (config.address) {
      this.store.dispatch(
        upsertAddress({
          payload: { ...config.address, addressType: 'Account' },
        })
      );
    }
  }

  private printTestSessionConfig(): void {
    if (environment.production) {
      return;
    }
    combineLatest([
      this.store.select(getAccountId),
      this.store.select(getProduct('PersonalAuto')),
      this.store.select(getAddressForProduct('PersonalAuto')),
    ])
      .pipe(take(1))
      .subscribe(([accountId, autoProduct, address]) => {
        console.log(
          JSON.stringify(
            {
              accessToken: this.window.sessionStorage.getItem('access_token'),
              jwtContent: JSON.parse(
                this.window.sessionStorage.getItem('jwt') || '{}'
              ),
              accountId,
              autoQuoteId: autoProduct?.quoteId,
              autoSessionId: autoProduct?.sessionId,
              address,
            },
            null,
            2
          )
        );
      });
  }

  private adjustConfigWithQueryParamsTestOnly(): void {
    if (this.isProd()) return;
    if (!this.window.location?.search?.match(/^\?.+/)) return;
    const query = this.window.location.search
      .substring(1)
      .split('&')
      .map((field) => {
        const separatorIndex = field.indexOf('=');
        if (separatorIndex < 0) {
          return [decodeURIComponent(field), ''];
        } else {
          return [
            decodeURIComponent(field.substring(0, separatorIndex)),
            decodeURIComponent(field.substring(separatorIndex + 1)),
          ];
        }
      })
      .reduce((a, v) => {
        a[v[0]] = v[1];
        return a;
      }, {} as { [key: string]: string });

    if (query.billingTargetEnv)
      this.appConfig.billingTargetEnv = query.billingTargetEnv;
    if (query.targetEnv) this.appConfig.targetEnv = query.targetEnv;
    if (query.ignoreRiskFilter)
      this.appConfig.ignoreRiskFilter = query.ignoreRiskFilter === 'true';
    if (query.autoApproveUW)
      this.appConfig.autoApproveUW = query.autoApproveUW === 'true';

    if (query.nwxEnv) {
      this.redirectToEnvIfNeeded(query.nwxEnv, query);
    }
  }

  private redirectToEnvIfNeeded(
    env: string,
    query: { [key: string]: string }
  ): void {
    let currentEnv = '';
    const cookies = this.window.document.cookie || '';
    for (const cookie in cookies.split(';')) {
      const separatorIndex = cookie.indexOf('=');
      const key = cookie.substring(0, separatorIndex);
      if (key !== 'aws_router') {
        continue;
      }
      currentEnv = cookie.substring(separatorIndex + 1);
      break;
    }
    if (env === currentEnv) {
      return;
    }
    // Duplicating logic from debug-overlay.component.ts
    if (env === 'uat') {
      this.deleteCookie('aws_router');
    } else {
      this.deleteCookie('aws_router');
      this.setCookie('aws_router', env);
    }
    this.window.localStorage.clear();
    this.window.sessionStorage.clear();
    const { nwxEnv, ...newQuery } = query;
    const newQueryString =
      '?' +
      Object.keys(newQuery)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(newQuery[k]);
        })
        .join('&');
    this.window.location.href =
      this.window.location.protocol +
      '//' +
      this.window.location.host +
      '/' +
      newQueryString;
  }

  private deleteCookie(cookieName: string): void {
    this.window.document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }

  private setCookie(cookieName: string, cookieValue: string): void {
    this.window.document.cookie = `${cookieName}=${cookieValue}; Max-Age=2147483647`;
  }

  refreshEffectiveDate(): void {
    if (!this.isProd() && this.config.targetEnv) {
      this.effectiveDateInitializerFn();
    }
  }

  private effectiveDateInitializerFn(): void {
    if (
      !this.isProd() &&
      this.config.targetEnv &&
      this.window.location.pathname !== '/retrieve/compRater'
    ) {
      if (this.config.futureEffectiveDate) {
        this.store.dispatch(
          fromDsmAction.setEffectiveDate({
            effectiveDate: this.config.futureEffectiveDate,
          })
        );
      } else {
        this.fetchDate(this.config.targetEnv)
          .pipe(take(1))
          .subscribe(() => {
            this.streamResults()
              .pipe(take(1))
              .subscribe((date) => {
                this.store.dispatch(
                  fromDsmAction.setEffectiveDate({ effectiveDate: date })
                );
              });
          });
      }
    } else {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      this.addOutput(today.toDateString());
      this.streamResults()
        .pipe(take(1))
        .subscribe((date) => {
          this.store.dispatch(
            fromDsmAction.setEffectiveDate({ effectiveDate: date })
          );
        });
    }
  }

  fetchDate(env: string): Observable<string> {
    return this.http
      .get<{
        effectiveDate: string;
      }>(
        'https://api-int-test.nwie.net/customer-acquisition/clocks/v1/clocks',
        {
          headers: {
            client_id: 'z3P2gOks4zbiRAQyzTGKOu8gsQE4hRJE',
            'X-NW-Message-ID': 'calling-from-nwx-test-page',
            'X-NW-Target-Env': this.getTargetEnv(env),
          },
        }
      )
      .pipe(
        map((result) => result.effectiveDate),
        tap((result) => this.addOutput(result))
      );
  }

  /* Reformat PC date to MM/DD/YYYY and emit it to our output stream.
   */
  addOutput(input: string): void {
    if (!input) {
      return;
    }
    const words = input.split(' ');
    const minValidLength = 4;
    if (words.length >= minValidLength) {
      // Realistically will always be 6.
      const month = DateUtils.monthFromString(words[1]);
      const day = (words[2].length < 2 ? '0' : '') + words[2];
      const year = words[words.length - 1];
      this.output.next(`${month}/${day}/${year}`);
    }
  }

  /* Emits the date as "MM/DD/YYYY" any time our environment changes and call completes.
   */
  streamResults(): Observable<string> {
    return this.output;
  }

  getTargetEnv(env: string): string {
    if (!env.toLowerCase().startsWith('pls')) {
      return (
        DSM_STAGE_TO_TEST_TARGET_ENV[
          env as keyof typeof DSM_STAGE_TO_TEST_TARGET_ENV
        ] || 'pls19'
      );
    } else {
      return env;
    }
  }
}
