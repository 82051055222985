import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Nullable } from '@shared/utils/type.utils';

export type ConfirmationModalInputs = {
  headerTitle: string;
  bodyMessage: string;
  cancelBtnText?: string;
  forwardBtnText?: string;
  notificationType?: string;
  notificationMessage?: string;
};
@Component({
  selector: 'nwx-simple-confirmation-modal',
  templateUrl: './simple-confirmation-modal.component.html',
  styleUrls: ['./simple-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleConfirmationModalComponent {
  @Input() headerExclamation = true;
  @Input() headerTitle: string = '';
  @Input() subtitle = '';
  @Input() bodyMessage: string = '';
  @Input() notificationType: Nullable<string>;
  @Input() notificationMessage: Nullable<string>;
  @Input() cancelBtnText: string = 'Cancel';
  @Input() forwardBtnText: string = 'Confirm';
  @Input() cancelButtonSlot = 'cancel';

  constructor(public modal: NgbActiveModal) {}

  cancelBtnClicked() {
    this.modal.close('cancel');
  }
  forwardBtnClicked() {
    this.modal.close('confirm');
  }
}
