import { PipeTransform, Pipe } from '@angular/core';
import { ProtectiveDevicesService } from '@core/services/protective-devices.service';
import { ProtectiveDeviceEntity } from '@core/store/entities/protective-devices/protective-devices.reducer';
import { ProtectiveDeviceModel } from '@entities/metadata/models/property-options.model';
import { Nullable } from '@shared/utils/type.utils';

@Pipe({
  name: 'protectiveDevicesType',
})
export class ProtectiveDevicesTypePipe implements PipeTransform {
  constructor(
    private protectiveDevicesService: ProtectiveDevicesService
  ) {}
  
  transform(input: Nullable<string>): string {
    return this.protectiveDevicesService.displayStringForProtectiveDeviceType(input);
  }
}

@Pipe({
  name: 'protectiveDevicesValue',
})
export class ProtectiveDevicesValuePipe implements PipeTransform {
  constructor(
    private protectiveDevicesService: ProtectiveDevicesService
  ) {}

  transform(device: ProtectiveDeviceModel): string {
    return this.protectiveDevicesService.typekeyStringForProtectiveDeviceValue(
      device.type,
      device.value
    );
  }
}

@Pipe({
  name: 'protectiveDevicesDisplay',
})
export class ProtectiveDevicesDisplayPipe implements PipeTransform {
  constructor(
    private protectiveDevicesService: ProtectiveDevicesService
  ) {}

  transform(device: ProtectiveDeviceModel): string {
    return this.protectiveDevicesService.sanitizeProtectiveDeviceValueForDisplay(
      device.type,
      device.value
    );
  }
}
