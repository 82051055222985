import {
  FormControl,
  FormGroup,
  AbstractControl,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ProductType, RelationToPNIType } from '@app/core/models/api/dsm-types';
import { RVSubtype } from '@core/models/views/vehicle.model';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { DriverRelationToPNI } from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { Nullable } from '@shared/utils/type.utils';

// TODO StandardizedAddresses should be defined by AddressService, once that exists.
interface StandardizedAddress {
  city: string;
  state: string;
  zipCode: string;
}
interface StandardizedAddresses {
  standardizedAddresses: StandardizedAddress[];
}

const DAYS_PER_WEEK = 7;

export class CustomValidators {
  static PicklistRequired(control: AbstractControl): ValidationErrors | null {
    if (!CustomValidators.hasRequiredField(control)) {
      return null;
    }

    return control.value !== null &&
      control.value !== undefined &&
      control.value !== '' &&
      control.value !== 'Please Select' &&
      control.value !== 'Month' &&
      control.value !== 'Year' &&
      control.value !== 'Day' &&
      control.value !== 'None'
      ? null
      : { picklistRequired: true };
  }

  static ValidNumOfDaysPerWeek(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const daysPerWeek = control.value;
    if (daysPerWeek < 1 || daysPerWeek > DAYS_PER_WEEK) {
      return { invalidDays: true };
    } else {
      return null;
    }
  }

  static validProductCombinationsRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const returnObject = {} as any;

    const selectedProducts = control.value as ProductType[];
    if (!selectedProducts.length) {
      returnObject.required = true;
    }
    if (
      CustomValidators.hasProductSelected('DwellingFire', selectedProducts) &&
      !CustomValidators.hasProperty(selectedProducts)
    ) {
      returnObject.invalidDwellingFireSelections = true;
    }
    return GeneralUtils.isEmpty(returnObject) ? null : returnObject;
  }

  static hasProperty(selectedProducts: ProductType[]): boolean {
    return selectedProducts.some(
      (id: ProductType) =>
        id === 'Condominium' || id === 'Homeowner' || id === 'Tenant'
    );
  }

  static hasProductSelected(
    productType: ProductType,
    selectedProducts: ProductType[]
  ): boolean {
    return selectedProducts.includes(productType);
  }

  static validUnderlyingUmbrellaSelections(
    selectedProducts: ProductType[]
  ): boolean {
    return (
      this.hasProperty(selectedProducts) &&
      this.hasProductSelected('PersonalAuto', selectedProducts)
    );
  }

  static specialRequired(
    key: string
  ): (control: FormControl) => ValidationErrors | null {
    return (control: FormControl) => {
      if (!control.value) {
        return { [key]: true };
      }
      if (control.value instanceof Array && !control.value.length) {
        return { [key]: true };
      }
      return null;
    };
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl.hasOwnProperty('controls')) {
      const fg = abstractControl as FormGroup; // so tsc thinks it has "controls"
      for (const controlName in fg.controls) {
        if (fg.controls[controlName]) {
          if (CustomValidators.hasRequiredField(fg.controls[controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static YearUpToNow(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const now = new Date();
      const currentYear = now.getFullYear();
      const input = +control.value;
      if (isNaN(input)) {
        return { year: true };
      }
      const LOWEST_FOUR_DIGIT_NUMBER = 1000;
      if (input < LOWEST_FOUR_DIGIT_NUMBER) {
        return { year: true };
      }
      if (input > currentYear) {
        return { thisYearOrEarlier: true };
      }
    }
    return null;
  }

  static DateUpToNow(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1;
      const currentDay = now.getDate();

      let copiedValue = control.value;
      if (control.value.includes('/')) {
        copiedValue = DateUtils.formatDateToDSM(control.value);
      }
      const inputYear = +copiedValue.split('-')[0];
      const inputMonth = +copiedValue.split('-')[1];
      const inputDay = +copiedValue.split('-')[2];
      const LOWEST_FOUR_DIGIT_NUMBER = 1000;
      if (inputYear < LOWEST_FOUR_DIGIT_NUMBER) {
        return { required: true };
      }
      if (inputYear > currentYear) {
        return { thisDateOrEarlier: true };
      }
      if (inputYear === currentYear) {
        if (inputMonth > currentMonth) {
          return { thisDateOrEarlier: true };
        }
        if (inputMonth === currentMonth && inputDay > currentDay) {
          return { thisDateOrEarlier: true };
        }
      }
    }
    return null;
  }

  /**
   *
   * @param control the form control
   * @param maxDate the maxmimum date in mm/dd/yyyy format
   */
  static DateUpToDate(
    control: AbstractControl,
    maxDate: string,
    validationMessageKey: string // keyof FORM_VALIDATION_VERBIAGE
  ): ValidationErrors | null {
    if (!control.value || !maxDate) {
      return null;
    }
    const parsedInputDate = this.comparableDateLikeThingFromString(
      control.value
    );
    const parsedCompareDate = this.comparableDateLikeThingFromString(
      maxDate || ''
    );
    if (!parsedCompareDate) {
      // If max is straight zeroes, call it valid.
      return null;
    }

    if (parsedInputDate > parsedCompareDate) {
      return { [validationMessageKey]: true };
    }

    return null;
  }

  private static comparableDateLikeThingFromString(input: string): number {
    if (!input) {
      return 0;
    }
    const mmDdYyyy = input.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    if (mmDdYyyy) {
      return +mmDdYyyy[3] * 10000 + +mmDdYyyy[1] * 100 + +mmDdYyyy[2];
    }
    const yyyyMmDd = input.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
    if (yyyyMmDd) {
      return +yyyyMmDd[1] * 10000 + +yyyyMmDd[2] * 100 + +yyyyMmDd[3];
    }
    return 0;
  }

  static DatePickerFormat(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(/^\d{4}(\/|\-)\d{2}(\/|\-)\d{2}$/);
    return dateRegEx.test(control.value) ? null : { dateFormat: true };
  }

  static DateFormat(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
    return dateRegEx.test(control.value) ? null : { dateFormat: true };
  }

  static DateFormatValidAppraisalDate(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
    const format = dateRegEx.test(control.value);

    if (!format) {
      return { dateformat: true };
    } else {
      const split = control.value.split('/');
      const year = split[2];
      const month = split[0];
      const day = split[1];
      if (+year < 1800) {
        const firstDate = '01/01/1800';
        return { requiredBefore: firstDate };
      } else if (+year > 2199) {
        const lastDate = '12/31/2199';
        return { requiredAfter: lastDate };
      } else {
        return CustomValidators._splitDateMakesSense(year, month, day)
          ? null
          : { dateFormat: true };
      }
    }
  }

  static DateFormatOrMasked(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(
      /^(\d{2}\/?\d{2}\/?\d{4}|\*\*\/?\*\*\/?\*\*\*\*)$/
    );
    return dateRegEx.test(control.value) ? null : { dateFormat: true };
  }

  static MaskableSocialSecurityNumberFormat(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/[^0-9*]/g, '');
    if (digits.length < 9) {
      return { socialSecurityNumberRequired: true };
    }
    const dateRegEx = new RegExp(/^[\d*]{3}\-[\d*]{2}\-[\d*]{4}$/);
    return dateRegEx.test(control.value)
      ? null
      : { socialSecurityNumberFormat: true };
  }

  static PhoneNumberFormat(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/[^0-9]/g, '');
    if (digits.length < 10) {
      return { required: true };
    }
    const dateRegEx = new RegExp(/^\d{3}\-\d{3}\-\d{4}$/);
    return dateRegEx.test(control.value) ? null : { phoneNumberFormat: true };
  }

  static MaskablePhoneNumberFormat(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/[^0-9*]/g, '');
    if (digits.length < 10) {
      return { phoneNumberRequired: true };
    }
    const dateRegEx = new RegExp(/^[\d*]{3}\-[\d*]{3}\-[\d*]{4}$/);
    return dateRegEx.test(control.value) ? null : { phoneNumberFormat: true };
  }

  /* Use Angular's email validator, but return "required" if it looks short.
   */
  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (!control.value.match(/^.+@.+\..+$/)) {
      return { emailIncomplete: true };
    }
    control = {
      value: control.value.replace(/\*/g, 'a').trim(),
    } as AbstractControl;
    return Validators.email(control);
  }

  static emailRequired(
    isQuoteStatusGreaterThanPending: boolean,
    isPersonalAutoSelected: boolean
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (isQuoteStatusGreaterThanPending && !control.value?.length) {
        if (isPersonalAutoSelected) {
          return { emailRequired: '& telematics programs.' };
        } else {
          return { emailRequired: '' };
        }
      }
      return null;
    };
  }

  static DateValid(control: AbstractControl): ValidationErrors | null {
    const date = control.value;
    if (!date) {
      return { required: true };
    }
    if (
      date === '**/**/****' ||
      (date.indexOf('*') > -1 && date.length === 10)
    ) {
      return null;
    }

    /* eslint-disable no-magic-numbers */
    if (date.replace(/[^\d]/g, '').length < 8 && !date.match(/[^0-9/-]/)) {
      // Fewer than 8 digits, report a passive "required" error.
      return { required: true };
    }
    const components = date.match(/(\d{2})\/?(\d{2})\/?(\d{4})/);
    if (!components || components.length !== 4) {
      return { dateFormat: true };
    }
    const month = +components[1];
    const day = +components[2];
    const year = +components[3];

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateInvalid: true };
    }

    return null;
  }

  static DateOfBirthValid(control: AbstractControl): ValidationErrors | null {
    const yearTooOld = 1900;
    const dob = control.value;
    if (!dob) {
      return { required: true };
    }
    if (dob === '**/**/****' || (dob.indexOf('*') > -1 && dob.length === 10)) {
      return null;
    }

    /* eslint-disable no-magic-numbers */
    if (dob.replace(/[^\d]/g, '').length < 8 && !dob.match(/[^0-9/-]/)) {
      // Fewer than 8 digits, report a passive "required" error.
      return { required: true };
    }
    const components = dob.match(/(\d{2})\/?(\d{2})\/?(\d{4})/);
    if (!components || components.length !== 4) {
      return { dateOfBirthInvalid: true };
    }
    const month = +components[1];
    const day = +components[2];
    const year = +components[3];
    /* eslint-enable no-magic-numbers */

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateOfBirthInvalid: true };
    }

    if (year < yearTooOld) {
      return { dateOfBirthTooOld: true };
    }

    return null;
  }

  static getMinimumAgeValidator(minimumAge: number): ValidatorFn {
    return (control: AbstractControl) => {
      // If it's not "MM/DD/YYYY", it's invalid, but that's another validator's problem.
      const value = control?.value || '';
      if (value.indexOf('*') >= 0) {
        return null;
      }
      const components = value.match(/^(\d{2})\/?(\d{2})\/?(\d{4})$/);
      if (!components) {
        return null;
      }
      const birthYear = +components[3];
      const birthMonth = +components[1];
      const birthDay = +components[2];
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = 1 + now.getMonth();
      const currentDay = now.getDate();
      let age = currentYear - birthYear;
      if (currentMonth < birthMonth) {
        age--;
      } else if (currentMonth === birthMonth && currentDay < birthDay) {
        age--;
      }
      if (age < 0) {
        return { dateOfBirthFuture: true };
      }
      return null;
    };
  }

  static SpouseMustBeMarried(
    control: AbstractControl
  ): ValidationErrors | null {
    if (GeneralUtils.isEmpty(control)) {
      return null;
    }
    const relation = control?.value as RelationToPNIType;
    if (!relation) {
      return { required: true };
    }
    const maritalStatus = control?.parent
      ?.get?.('person')
      ?.get?.('maritalStatus');

    CustomValidators.setRelationError(control, maritalStatus);

    return null;
  }

  static MaritalStatus(control: AbstractControl): ValidationErrors | null {
    if (GeneralUtils.isEmpty(control)) {
      return null;
    }
    const maritalStatus = control?.value;
    if (!maritalStatus) {
      return { required: true };
    }
    const relation = control?.parent?.parent?.get?.(
      'relationToPrimaryNamedInsured'
    );
    if (
      !relation ||
      relation.disabled ||
      relation.value === DriverRelationToPNI.PNI
    ) {
      return null;
    }

    CustomValidators.setRelationError(relation, control);
    return null;
  }

  static setRelationError(
    relationControl: Nullable<AbstractControl>,
    maritalStatusControl: Nullable<AbstractControl>
  ): ValidationErrors | null {
    const relation = relationControl?.value as RelationToPNIType;
    const maritalStatus = maritalStatusControl?.value;

    if (relation === DriverRelationToPNI.SPOUSE) {
      if (!maritalStatus || !['M', 'P'].includes(maritalStatus)) {
        relationControl?.setErrors({ spouseNotMarried: true });
      } else {
        relationControl?.setErrors(null);
      }
    } else {
      relationControl?.setErrors(null);
    }
    return null;
  }

  static DriverTrainingCourseDateValid(
    control: AbstractControl
  ): ValidationErrors | null {
    const courseDate = control.value;
    if (!courseDate) {
      return null;
    }

    const components = courseDate.match(/(\d{2})\/?(\d{2})\/?(\d{4})/);
    if (!components || components.length !== 4) {
      return { courseDateInvalid: true };
    }
    const month = +components[1];
    const day = +components[2];
    const year = +components[3];

    const currentDateInMilliseconds = new Date().getTime();
    const courseDateInMilliseconds = new Date(year, month - 1, day).getTime();

    if (courseDateInMilliseconds > currentDateInMilliseconds) {
      return { courseDateInvalid: true };
    }
    return null;
  }

  static DateMmddyyyyPastOnly(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }

    const COUNT_OF_THINGS_IN_A_THREE_THING_THING = 3;
    const split = value.split('/');
    if (split.length < COUNT_OF_THINGS_IN_A_THREE_THING_THING) {
      return { required: true };
    }
    const month = +split[0];
    const day = +split[1];
    const year = +split[2];
    if (year < 1000) {
      return { required: true };
    }

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateFormat: true };
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    if (year > currentYear) {
      return { futureDate: true };
    } else if (year === currentYear) {
      const currentMonth = now.getMonth() + 1;
      if (month > currentMonth) {
        return { futureDate: true };
      } else if (month === currentMonth) {
        const currentDay = now.getDate();
        if (day > currentDay) {
          return { futureDate: true };
        }
      }
    }

    return null;
  }

  static StringOnly(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[a-zA-Z-\\s]+$');
    return dateRegEx.test(control.value) ? null : { stringFormat: true };
  }

  static SpecialCharacterStringOnly(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const illegalMatch = control?.value.match(/[^a-zA-Z'\s-]/);
    if (!illegalMatch) {
      return null;
    }
    return { illegalCharacter: illegalMatch[0] };
  }

  static NumericCommaOnly(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[1-9]+[0-9,]*$');
    return dateRegEx.test(control.value) ? null : { stringFormat: true };
  }

  static ZipCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[0-9-]+$');
    return dateRegEx.test(control.value) ? null : { zipFormat: true };
  }

  static dsmCompanyName(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (!control.value.match(/^[a-zA-Z0-9][a-zA-Z0-9 '\-&/.,]*$/)) {
      const illegalCharacter = control.value.match(/[^a-zA-Z0-9 '\-&/.,]/);
      if (illegalCharacter) {
        return {
          illegalCharacter: illegalCharacter[0],
        };
      }
      return { invalid: true };
    }
    return null;
  }

  /* eslint-disable no-magic-numbers */
  private static _splitDateMakesSense(
    year: number,
    month: number,
    day: number
  ): boolean {
    if (day < 1 || month < 1 || year < 1) {
      return false;
    }
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }

    if (day > 31) {
      return false;
    }

    if (month > 12) {
      return false;
    }

    if (CustomValidators._is30DayMonth(month)) {
      if (day > 30) {
        return false;
      }
    }

    if (CustomValidators._isFebruary(month)) {
      const monthLength = CustomValidators._isLeapYear(year) ? 29 : 28;
      if (day > monthLength) {
        return false;
      }
    }

    return true;
  }
  /* eslint-disable no-magic-numbers */

  /* eslint-disable no-magic-numbers */
  private static _is30DayMonth(month: number): boolean {
    const months = [4, 6, 9, 11];
    return months.includes(month);
  }
  /* eslint-enable no-magic-numbers */

  private static _isFebruary(month: number): boolean {
    return month === 2;
  }

  private static _isLeapYear(year: number): boolean {
    const every4Years = 4;
    const every100Years = 100;
    const every400Years = 400;
    if (
      +year % every4Years === 0 &&
      (+year % every100Years !== 0 || +year % every400Years === 0)
    ) {
      return true;
    }

    return false;
  }

  static normalizeVin(input: string): string {
    if (!input) {
      return '';
    }
    return input.toUpperCase().trim().replace(/I/g, '1').replace(/O/g, '0');
  }

  /* eslint-disable no-magic-numbers */
  static ValidateVin(control: AbstractControl): ValidationErrors | null {
    // https://www.federalregister.gov/documents/2008/04/30/08-1197/vehicle-identification-number-requirements

    const vin = CustomValidators.normalizeVin(control.value);
    if (!vin) {
      return null;
    }
    if (vin.indexOf('*') >= 0) {
      return null;
    }
    if (vin.length < 17) {
      return { required: true };
    }
    if (vin.length > 17) {
      return { invalid: true };
    }

    const numericVin = Array.from(vin).map((ch: string) =>
      CustomValidators.vinCharacterValue(ch)
    );
    if (numericVin.indexOf(undefined as any) >= 0) {
      return { invalid: true };
    }

    const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < numericVin.length; i++) {
      numericVin[i] *= weights[i];
    }

    const sum = numericVin.reduce((a, v) => a + v, 0);
    const remainder = sum % 11;
    const checkDigit = remainder < 10 ? remainder.toString() : 'X';

    if (vin[8] !== checkDigit) {
      return { invalid: true };
    }
    return null;
  }
  /* eslint-enable no-magic-numbers */

  static vinCharacterValue(character: string): number {
    if (character >= '0' && character <= '9') {
      return +character;
    }
    return (
      {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        J: 1,
        K: 2,
        L: 3,
        M: 4,
        N: 5,
        P: 7,
        R: 9,
        S: 2,
        T: 3,
        U: 4,
        V: 5,
        W: 6,
        X: 7,
        Y: 8,
        Z: 9,
      } as any
    )[character];
  }

  static ValidateLuhn(control: AbstractControl): ValidationErrors | null {
    const cardNumber = control.value;
    let sum = 0;
    if (!cardNumber) {
      return null;
    }
    const numdigits = cardNumber.length;
    const minimumLength = 15;
    if (numdigits < minimumLength) {
      return { required: true };
    }
    const parity = numdigits % 2;
    for (let i = 0; i < numdigits; i++) {
      let digit = parseInt(cardNumber.charAt(i), 10);
      if (i % 2 === parity) {
        digit *= 2;
      }
      const luhnDigit9 = 9;
      if (digit > luhnDigit9) {
        digit -= luhnDigit9;
      }
      sum += digit;
    }
    return sum % 10 === 0 ? null : { invalidCardNumber: true };
  }

  static ValidateCreditCardType(value: string | null): string | null {
    let cardType = null;
    if (value !== null && value !== '') {
      if (value.substring(0, 1) === '4') {
        cardType = 'VISA';
      } else if (
        value.substring(0, 1) === '5' ||
        value.substring(0, 1) === '2'
      ) {
        cardType = 'MAST';
      } else if (value.substring(0, 1) === '6') {
        cardType = 'DISC';
      } else if (value.substring(0, 1) === '3') {
        cardType = 'AMEX';
      }
    }

    return cardType;
  }

  static ValidateCreditCardNumber(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const cardNumber = control.value.toString();
    // validating the card if it is valid payment type present in admin table picklist
    const ctype = CustomValidators.ValidateCreditCardType(cardNumber);
    if (ctype == null) {
      return { invalid: true };
    }
    const cardMaxLength = 16;
    const cardMinLength = 15;
    if (cardNumber.length < cardMinLength) {
      return { required: true };
    }
    if (
      cardNumber.length !== cardMaxLength &&
      (cardNumber.substring(0, 1) === '4' ||
        cardNumber.substring(0, 1) === '5' ||
        cardNumber.substring(0, 1) === '6')
    ) {
      return { cardNoLengthExceed: true };
    }

    if (
      cardNumber.length !== cardMinLength &&
      cardNumber.substring(0, 1) === '3'
    ) {
      return { invalidAMEXCardLength: true };
    }

    return null;
  }

  static validateCVV(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const cvvLength = 3;
    if (control.value.length < cvvLength) {
      return { required: true };
    }
    return control.value.length === cvvLength
      ? null
      : { cvvLengthExceed: true };
  }

  static validateCID(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const cvvLength = 4;
    return control.value.length === cvvLength
      ? null
      : { cidLengthExceed: true };
  }

  static validateAssociateNumber(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const isNumericOnly = control.value.match(/^[0-9]*$/);
    const associateNumberLength = 6;
    return control.value.length === associateNumberLength && isNumericOnly
      ? null
      : { invalidAssociateNumber: true };
  }

  static VerifyBankAccountInputMatch(
    formControl: AbstractControl
  ): ValidationErrors | null {
    const a = formControl.get('accountNumber');
    const b = formControl.get('verifyAccountNumber');
    if (!a?.value || !b?.value) {
      return null;
    }
    if (a.value === b.value) {
      return null;
    }
    // If one is a prefix of the other, call it "required" rather than "mustMatch".
    if (a.value.startsWith(b.value)) {
      b.setErrors({ required: true });
      return { required: true };
    }
    if (b.value.startsWith(a.value)) {
      a.setErrors({ required: true });
      return { required: true };
    }
    a.setErrors({ required: true });
    b.setErrors({ required: true });
    return { mustMatch: true };
  }

  static ValidateRoutingNumberLength(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const nineDigitRegEx = new RegExp('^[0-9]{9}$');
    return nineDigitRegEx.test(control.value) ? null : { invalid: true };
  }

  static ValidateZipCode(control: AbstractControl): ValidationErrors | null {
    const SHORT_ZIP_LENGTH = 5;
    if (!control.value || control.value.length < SHORT_ZIP_LENGTH) {
      return { required: true };
    }
    if (control.value.match(/^\d{5}(-\d{4})?$/)) {
      return null;
    } else if (control.value.match(/^\d{5}-\d{0,3}$/)) {
      return { required: true };
    } else {
      return { invalid: true };
    }
  }

  static MarkFormGroupTouched(formGroup: FormGroup): void {
    Object?.keys(formGroup?.controls).forEach((key) => {
      const control = formGroup?.controls[key] as any;
      control.markAsTouched();
      if (control.controls) {
        CustomValidators.MarkFormGroupTouched(control);
      }
    });
  }

  static EnableDisableFormGroup(formGroup: FormGroup, enable: boolean): void {
    Object?.keys(formGroup?.controls || {}).forEach((key) => {
      const control = formGroup?.controls?.[key] as any;
      enable ? control?.enable() : control?.disable();
      if (control.controls) {
        CustomValidators.EnableDisableFormGroup(control, enable);
      }
    });
  }

  static setFormEnabled(formGroup: FormGroup, selected: boolean): void {
    selected ? formGroup.enable() : formGroup.disable();
  }

  static isValidYearMonth(year: number, month: number): boolean {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (year < currentYear) {
      return false;
    }
    if (year === currentYear && month < currentMonth) {
      return false;
    }
    return true;
  }

  static isValidFutureYearDayMonthString(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const COUNT_OF_THINGS_IN_A_THREE_THING_THING = 3;
    const split = value.split('/');
    if (split.length < COUNT_OF_THINGS_IN_A_THREE_THING_THING) {
      return { dateFormat: true };
    }
    const month = +split[0];
    const day = +split[1];
    const year = +split[2];
    if (year < 1000) {
      return { dateFormat: true };
    }
    if (year > 2100) {
      return { invalidYear: true };
    }
    if (month > 12) {
      return { invalidMonth: true };
    }

    const now = new Date();
    const currentYear = now.getFullYear();

    if (year < currentYear) {
      return { dateInThePast: true };
    } else if (year === currentYear) {
      const currentMonth = now.getMonth() + 1;
      if (month < currentMonth) {
        return { dateInThePast: true };
      } else if (month === currentMonth) {
        const currentDay = now.getDate();
        if (day < currentDay) {
          return { dateInThePast: true };
        }
      }
    }
    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateInvalid: true };
    }
    return null;
  }

  static ValidateAddress(addresses: StandardizedAddresses): boolean {
    if (!addresses) {
      return true;
    }

    if (!addresses.standardizedAddresses) {
      return true;
    }

    if (addresses.standardizedAddresses.length < 1) {
      return true;
    }

    if (addresses.standardizedAddresses.length > 1) {
      return true;
    }

    return this.ValidateSingleAddress(addresses.standardizedAddresses[0]);
  }

  static ValidateSingleAddress(address: StandardizedAddress): boolean {
    if (!address.city) {
      return true;
    }

    if (!address.state) {
      return true;
    }

    if (!address.zipCode) {
      return true;
    }

    return false;
  }

  static validateAddressObject(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value: Partial<AddressModel> = control.value;
    if (
      !value.city ||
      !value.state ||
      !value.addressLine1 ||
      !value.postalCode
    ) {
      return { 'Address is required': true };
    }
    return null;
  }

  static validateAgeLicensedAgainstDateOfBirth(control: AbstractControl): any {
    const controls = CustomValidators._findAgeLicensedAndDateOfBirth(control);
    if (!controls) {
      return null;
    }
    const ageLicensed = CustomValidators._parseAge(
      controls.ageFirstLicensed.value
    );
    const age = PersonUtils.CalculateAgeFromDateOfBirth(
      controls.dateOfBirth.value
    );
    if (isNaN(ageLicensed) || isNaN(age)) {
      return null;
    }
    if (ageLicensed > age) {
      return { greaterThanAge: age?.toString() };
    }
    return null;
  }

  static validateYearsExpAgainstDateOfBirth(
    control: AbstractControl,
    dateOfBirth: Nullable<string>
  ): any {
    if (!control || !dateOfBirth) {
      return null;
    }
    const yearsBoatingExp = CustomValidators._parseAge(control.value);
    if (yearsBoatingExp < 0) {
      return { min: { min: '0' } };
    }
    let age;
    if (dateOfBirth.includes('*')) {
      age = DateUtils.getDiffInYearsFromNow(dateOfBirth);
    } else {
      age = PersonUtils.CalculateAgeFromDateOfBirth(
        DateUtils.formatDsmDateToOld(dateOfBirth)
      );
    }

    if (isNaN(yearsBoatingExp) || isNaN(age)) {
      return null;
    }
    if (yearsBoatingExp > age) {
      return { yearsBoatExpGreaterThanAge: age?.toString() };
    }
    return null;
  }
  static ConsentRequired(control: AbstractControl): ValidationErrors | null {
    return control.value === true ? null : { consentRequired: true };
  }

  static MobilePhoneRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    return control.value == null || control.value.length === 0
      ? { consentRequired: true }
      : null;
  }

  private static _findAgeLicensedAndDateOfBirth(
    control: AbstractControl | null
  ): any {
    while (control) {
      const ageFirstLicensed = control.get('ageFirstLicensed');
      const dateOfBirth = control.get('dateOfBirth');
      if (ageFirstLicensed && dateOfBirth) {
        return { ageFirstLicensed, dateOfBirth };
      }
      control = control.parent!;
    }
    return null;
  }

  private static _parseAge(input: string): number {
    return parseInt(input, 10);
  }

  static arrayLength(min: number, max: number): ValidatorFn {
    return (control: AbstractControl) => {
      const length = control.value?.length || 0;
      if (length < min) {
        return { arrayTooShort: min };
      }
      if (length > max) {
        return { arrayTooLong: max };
      }
      return null;
    };
  }

  static step(step: number, min = 0): ValidatorFn {
    return (control: AbstractControl) => {
      const value = +control.value;
      if (isNaN(value)) {
        return null;
      }
      if ((value - min) % step) {
        return { step };
      }
      return null;
    };
  }

  static UtilityTrailer(control: AbstractControl): ValidationErrors | null {
    if (control.value > 12000) {
      return { utilityTrailerValueHigh: true };
    }
    return null;
  }

  // Not currently using but keeping for future
  static RvUtilityTrailer(
    control: AbstractControl,
    subType: RVSubtype
  ): ValidationErrors | null {
    if (subType === 'UtilityTrailer') {
      if (control.value > 50000) {
        return { rvUtilityTrailerValueHigh: true };
      }
    }
    if (subType === 'CarHauler') {
      if (control.value > 200000) {
        return { rvCarHaulerValueHigh: true };
      }
    }

    return null;
  }

  static Horsepower(control: AbstractControl): ValidationErrors | null {
    if (control.value > 40) {
      return { horsepowerValueHigh: true };
    }
    return null;
  }

  static MarketValue(vehicleSubType: string): ValidatorFn {
    if (vehicleSubType === 'DuneBuggy') {
      return (control: AbstractControl) => {
        if (control.value > 15000) {
          return { duneBuggyMarketValueHigh: true };
        }
        return null;
      };
    }
    if (vehicleSubType === 'LawnandGardenTractors') {
      return (control: AbstractControl) => {
        if (control.value > 35000) {
          return { lawnandGardenTractorsMarketValueHigh: true };
        }
        return null;
      };
    }
    return () => null;
  }

  static MsaSubType(vehicleType: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        vehicleType === 'Motorcycle' &&
        control.value !== 'Other' &&
        control.value !== 'Trike'
      ) {
        return { invalidMsaSubType: true };
      }
      return null;
    };
  }

  static isIncrementOf(
    control: AbstractControl,
    min: number,
    max: number,
    increment: number
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (control.value < min) {
      return { min: { min } };
    }
    if (control.value > max) {
      return { max: { max } };
    }
    return +control.value % increment === 0
      ? null
      : { mustBeIncrementOf: increment };
  }

  static setNumberOfCarsRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    const garageType = control.value;
    const group = control.parent as FormGroup;
    const numberOfCarsControl = group?.get('numberOfCars');
    const numberOfCars = numberOfCarsControl?.value;
    const isApplicable = garageType;

    if (isApplicable && !numberOfCars) {
      numberOfCarsControl?.setValidators(Validators.required);
    } else {
      numberOfCarsControl?.clearValidators();
    }

    numberOfCarsControl?.updateValueAndValidity();

    return null;
  }

  static isInteger(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (!isNaN(control.value) && !Number.isInteger(+control.value)) {
      return { mustBeWholeNumber: true };
    }
    return null;
  }

  static isWholeInteger(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regEx = new RegExp('^[0-9]*$');
    return regEx.test(control.value) ? null : { mustBeWholeNumber: true };
  }

  static minAfterCleanup(limit: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = +(control.value || '').trim().replace(/[$,]/g, '') || 0;
      if (value < limit) {
        return { min: { min: limit } };
      }
      return null;
    };
  }

  static maxAfterCleanup(limit: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = +(control.value || '').trim().replace(/[$,]/g, '') || 0;
      if (value > limit) {
        return { max: { max: limit } };
      }
      return null;
    };
  }

  static MsbEstimateNumberValid(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const msbRegExp = new RegExp(/^00-\d{5,17}$|^ESTIMATE-\d{5,14}$/);
    return msbRegExp.test(control.value) ? null : { invalidMsbNumber: true };
  }

  static validateLiabilityLosses(
    control: AbstractControl
  ): ValidationErrors | null {
    if (GeneralUtils.parseBooleanFromString(control.value)) {
      return {
        liabilityLossesIsIneligible: true,
      };
    }
    return null;
  }

  static isValidCheckNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regEx = new RegExp('^[0-9]*$');
    return regEx.test(control.value) ? null : { checkNumberInvalid: true };
  }
}
