import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageModel } from '@core/models/views/coverage.model';
import { ProductsService } from '@core/services/products.service';
import { QuoteService } from '@core/services/quote.service';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '@shared/utils/date.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DatepickerInputComponent } from '@shared/components/datepicker-input/datepicker-input.component';
import { ErrorModel } from '@entities/error/error.model';
import { rateQuoteFail } from '@entities/quote/quote.action';
import { DeleteProductService } from '@core/services/delete-product.service';

interface UmbrellaCoverageDisplayFormModel {
  effectiveDate: Nullable<string>; // "MM/DD/YYYY"
}

@Component({
  selector: 'nwx-umbrella-coverage-display',
  templateUrl: './umbrella-coverage-display.component.html',
  styleUrls: ['./umbrella-coverage-display.component.scss'],
})
export class UmbrellaCoverageDisplayComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() product!: ProductModel;
  @Input() maxSizeMini!: boolean;
  @Input() errors: ErrorModel[] = [];

  form = new FormGroup({
    effectiveDate: new FormControl('', [
      (control) =>
        DatepickerInputComponent.validate(
          control,
          this.policyDateOptions?.minDate || '',
          this.policyDateOptions?.maxDate || ''
        ),
    ]),
  });

  policyDateOptions: Nullable<PolicyDateOptions> = null;

  totalPolicyPremium = 0;
  termMonths = 6;
  overviewLimits: CoverageModel[] = [];
  updateInProgress$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();
  showDatePicker: boolean = false;
  formValueDate: any;

  constructor(
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private productsService: ProductsService,
    private ref: ChangeDetectorRef,
    private deleteProductService: DeleteProductService
  ) {
    this.updateInProgress$ = this.quoteService
      .getQuoteUpdateInProgress()
      .pipe(map((v) => !!v));
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.updateInProgress$)
      )
      .subscribe(this.onFormChanged.bind(this));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.showDatePicker = false;
  }

  ngOnInit(): void {
    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((options) => {
        this.policyDateOptions = options;
        this.ref.detectChanges();
      });
  }

  ngOnChanges(
    changes: ComponentChanges<UmbrellaCoverageDisplayComponent>
  ): void {
    if (changes.premiums || changes.errors) {
      if (
        this.errors.find(
          (e) =>
            e.productType === 'PersonalUmbrella' &&
            e.sourceActionType === rateQuoteFail.type
        )
      ) {
        this.totalPolicyPremium = 0;
      } else {
        this.totalPolicyPremium =
          this.premiums.find((p) => p.productType === 'PersonalUmbrella')?.total
            ?.amount || 0;
        this.termMonths =
          this.premiums.find((p) => p.productType === 'PersonalUmbrella')
            ?.termMonths || 6;
      }
    }
    this.initOverviewLimits();
    if (changes.product) {
      this.form.patchValue({
        effectiveDate: DateUtils.formatDsmDateToOld(
          this.product.effectiveDate || ''
        ),
      });
    }
  }

  ngDoCheck(): void {
    if (this.formValueDate !== this.form.value.effectiveDate) {
      this.showDatePicker = false;
      this.formValueDate = this.form.value.effectiveDate;
    }
  }

  initOverviewLimits(): void {
    this.overviewLimits = [];
    const validIds = ['PULLiability', 'UMUIMBI'];
    this.coverages.forEach((cov) => {
      if (
        validIds.includes(cov.coverageId) &&
        !this.overviewLimits.find((limit) => limit.name === cov.coverageId)
      ) {
        let value =
          cov && cov.selectedValue?.length ? cov?.selectedValue[0]?.value : '';
        if (+value) {
          value =
            this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0') || '';
        }
        if (value === 'false') {
          value = 'Decline';
        }
        this.overviewLimits.push({
          name: cov.name,
          value,
        });
      }
    });
  }

  editCoverages(): void {
    this.router.navigate(['/quote/coverages'], {
      queryParams: { product: 'PersonalUmbrella' } as { product: ProductType },
    });
  }

  promptToRemovePolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndRemoveProduct(this.product);
  }

  promptToWithdrawPolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndWithdrawProduct(this.product);
  }

  private onFormChanged(
    value: [Partial<UmbrellaCoverageDisplayFormModel>, Nullable<boolean>]
  ): void {
    const updateInprogress = value[1] === true;
    const newDate = DateUtils.formatDateToDSM(value[0]?.effectiveDate);
    if (
      updateInprogress ||
      !this.product ||
      this.product.effectiveDate === newDate ||
      !this.form.valid
    ) {
      return;
    }
    this.productsService.updateQuote({
      productType: 'PersonalUmbrella',
      effectiveDate: newDate,
    });
  }
}
