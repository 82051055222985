import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CoverageModel } from '@app/core/models/views/coverage.model';
import { ProductsService } from '@app/core/services/products.service';
import { QuoteService } from '@app/core/services/quote.service';
import { RouterService } from '@app/core/services/router.service';
import { CoverageEntity } from '@app/core/store/entities/coverage/coverage.entity';
import { PremiumEntity } from '@app/core/store/entities/premium/premium.entity';
import { ProductModel } from '@app/core/store/entities/product/product.model';
import { VehicleEntity } from '@app/core/store/entities/vehicle/vehicle.entity';
import { DateUtils } from '@app/shared/utils/date.utils';
import { ComponentChanges } from '@app/shared/utils/general.utils';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { Nullable } from '@shared/utils/type.utils';
import { CoverageIds } from '@shared/constants/app-constants';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DatepickerInputComponent } from '@shared/components/datepicker-input/datepicker-input.component';
import { ErrorModel } from '@entities/error/error.model';
import { rateQuoteFail } from '@entities/quote/quote.action';
import { DeleteProductService } from '@core/services/delete-product.service';

interface MsaCoverageDisplayFormModel {
  effectiveDate: Nullable<string>; // "MM/DD/YYYY"
}

@Component({
  selector: 'nwx-powersports-coverage-display',
  templateUrl: './powersports-coverage-display.component.html',
  styleUrls: ['./powersports-coverage-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PowersportsCoverageDisplayComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() vehicles!: VehicleEntity[];
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() product!: ProductModel;
  @Input() maxSizeMini!: boolean;
  @Input() errors: ErrorModel[] = [];

  form = new FormGroup({
    effectiveDate: new FormControl('', [
      (control) =>
        DatepickerInputComponent.validate(
          control,
          this.policyDateOptions?.minDate || '',
          this.policyDateOptions?.maxDate || ''
        ),
    ]),
  });

  policyDateOptions: Nullable<PolicyDateOptions> = null;

  totalPolicyPremium = 0;
  termMonths = 6;
  remainingCoverageCount = 0;
  coveragesToDisplayIfUnavailable = [CoverageIds.FTPKG, 'VACLIAB', 'PKG'];
  coveragesToDisplayIfUnselected = ['PKG'];

  overviewLimits: CoverageModel[] = [];
  updateInProgress$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();
  showDatePicker: boolean = false;
  formValueDate: any;

  constructor(
    private routerService: RouterService,
    private currencyPipe: CurrencyPipe,
    private ref: ChangeDetectorRef,
    private quoteService: QuoteService,
    private productsService: ProductsService,
    private deleteProductService: DeleteProductService
  ) {
    this.updateInProgress$ = this.quoteService
      .getQuoteUpdateInProgress()
      .pipe(map((v) => !!v));
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.updateInProgress$)
      )
      .subscribe(this.onFormChanged.bind(this));
  }

  ngOnInit(): void {
    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((options) => {
        this.policyDateOptions = options;
        this.ref.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.showDatePicker = false;
  }

  ngOnChanges(
    changes: ComponentChanges<PowersportsCoverageDisplayComponent>
  ): void {
    if (
      this.errors.find(
        (e) =>
          e.productType === this.product.type &&
          e.sourceActionType === rateQuoteFail.type
      )
    ) {
      this.totalPolicyPremium = 0;
    } else {
      this.totalPolicyPremium =
        this.premiums.find((p) => p.productType === this.product.type)?.total
          ?.amount || 0;
      this.termMonths =
        this.premiums.find((p) => p.productType === this.product.type)
          ?.termMonths || 6;
    }
    this.initOverviewLimits();
    if (changes.product) {
      this.form.patchValue({
        effectiveDate: DateUtils.formatDsmDateToOld(
          this.product.effectiveDate || ''
        ),
      });
    }
  }

  ngDoCheck(): void {
    if (this.formValueDate !== this.form.value.effectiveDate) {
      this.showDatePicker = false;
      this.formValueDate = this.form.value.effectiveDate;
    }
  }

  getVehiclePremiumById(vehicleId: string | undefined): number {
    const autoPremiums = this.premiums.find(
      (p) => p.productType === this.product.type
    );
    // const vehiclePremium = autoPremiums?.vehiclePremiums?.find(
    //   (p) => p.vehicleID.toString() === vehicleId?.toString()
    // );
    const coverablePremium = autoPremiums?.coverablePremiums?.find(
      (p) => p.coverableID?.toString() === vehicleId?.toString()
    );
    let premium = 0;
    // *** Matching v1 behavior for now and not using variableMileagePremium ***
    // if (vehiclePremium) {
    //   premium = vehiclePremium.variableMileagePremium || 0;
    // } else
    if (coverablePremium) {
      premium = coverablePremium.coverableTotal || 0;
    }
    return premium / this.termMonths;
  }

  getDeductibleById(vehicleId: string, covId: string): number | string {
    const coverage = this.coverages.find(
      (cov) => cov.coverageId === covId && cov.coverableId === vehicleId
    );
    if (this.coveragesToDisplayIfUnavailable.includes(covId) && coverage) {
      if (!this.getAvailableById(covId, vehicleId)) {
        return 'Not available';
      }
      const splitVals = this.getCoverageSplit(coverage);
      const currencyVals = this.getCoverageCurrencyVals(splitVals);
      const combinedVal = this.getCoverageCombinedVal(currencyVals);
      return combinedVal;
    } else {
      return +(coverage?.selectedValue?.[0]?.value || 0);
    }
  }

  getSelValById(vehicleId: string | undefined, covId: string): string {
    const coverage = this.coverages.find(
      (cov) => cov.coverageId === covId && cov.coverableId === vehicleId
    );
    return coverage?.selectedValue?.[0]?.value || '';
  }

  getCovDescription(vehicleId: string, covId: string): string {
    const coverage = this.coverages.find(
      (cov) => cov.coverageId === covId && cov.coverableId === vehicleId
    );
    if (this.coveragesToDisplayIfUnavailable.includes(covId) && coverage) {
      if (!this.getAvailableById(covId, vehicleId)) {
        return 'Not available';
      }
    }
    if (this.coveragesToDisplayIfUnselected.includes(covId) && coverage) {
      if (this.getSelValById(vehicleId, covId) === 'false') {
        return 'Not selected';
      }
    }
    return coverage?.selectedValue?.[0]?.description || '';
  }

  getAvailableById(covId: string | undefined, coverableId: string): boolean {
    return (
      this.coverages.find(
        (c) => c.coverageId === covId && c.coverableId === coverableId
      )?.available || false
    );
  }
  initOverviewLimits(): void {
    this.overviewLimits = [];
    const validIds = [
      CoverageIds.PropertyDamage,
      CoverageIds.BodilyInjury,
      CoverageIds.UMBI,
      CoverageIds.ScheduledPersonalEffects,
      CoverageIds.FSHEQ,
    ];

    this.coverages
      .filter(
        (cov) =>
          cov.productId === this.product.type &&
          cov.selectedValue &&
          cov.selectedValue.length > 0
      )
      .forEach((cov) => {
        switch (cov.coverageId) {
          case CoverageIds.FSHEQ:
            this.initFishingEquipment(cov);
            break;
          case CoverageIds.ScheduledPersonalEffects:
            this.initScheduledPersonalEffects(cov);
            break;
          default:
            if (
              validIds.includes(cov.coverageId) &&
              !this.overviewLimits.some(
                (limit) => limit.name === cov.coverageId
              )
            ) {
              this.initLiabilityCoverage(cov);
            }
            break;
        }
      });
    this.remainingCoverageCount =
      this.coverages.filter((c) => c.productId === this.product.type).length -
      this.overviewLimits.length;
  }

  initFishingEquipment(cov: CoverageEntity): void {
    // Not selected
    if (this.getSelValById(undefined, cov.coverageId) === 'false') {
      this.overviewLimits.push({
        name: cov.coverageId,
        value: 'Not selected',
        description: cov.description,
      });
      return;
    }

    // Get Limit & Format
    let limit: Nullable<string> = cov.selectedValue.find(
      (sv) => sv.code === 'FSHEQLimit'
    )?.value;
    this.overviewLimits.push({
      name: cov.coverageId,
      value:
        this.currencyPipe.transform(limit, 'USD', '', '1.0-0') ||
        'Not selected',
      description: cov.description,
    });
  }

  initScheduledPersonalEffects(cov: CoverageEntity): void {
    this.overviewLimits.push({
      name: cov.coverageId,
      value:
        cov.selectedValue.find((c) => c.code === 'SPELimit')?.value ||
        'Not selected',
      description: cov.description,
    });
  }

  initLiabilityCoverage(cov: CoverageEntity): void {
    const splitVals = this.getCoverageSplit(cov);
    const currencyVals = this.getCoverageCurrencyVals(splitVals);
    const combinedVal = this.getCoverageCombinedVal(currencyVals);

    this.overviewLimits.push({
      name: cov.coverageId,
      value: combinedVal,
      description: cov.description,
    });
  }

  getCoverageSplit(cov: CoverageEntity): string[] {
    if (!cov || !cov.selectedValue?.length) return [];
    if (cov.selectedValue[0]?.description?.includes('CSL')) {
      return [cov.selectedValue[0]?.description];
    } else {
      return cov.selectedValue[0]?.value.split('/');
    }
  }

  getCoverageCurrencyVals(splitVals: string[]): Nullable<string>[] {
    return splitVals
      .map((val) => {
        if (val.includes('CSL')) return val;
        try {
          return this.convertToCurrencyDisplay(val);
        } catch {
          return '0';
        }
      })
      .filter(Boolean);
  }

  getCoverageCombinedVal(currencyVals: Nullable<string>[]): string {
    if (currencyVals.length === 2)
      return `${currencyVals[0]}/${currencyVals[1]}`;
    return `${currencyVals[0]}`;
  }

  convertToCurrencyDisplay(value: Nullable<string>): Nullable<string> {
    const leadingDigitsMatcher: Nullable<RegExpMatchArray> =
      value?.match(/^(\d+)000000$/);
    if (value?.length && value.length > 6 && leadingDigitsMatcher) {
      // Match returns [0]: '1000000', [1]: '1'
      return `${leadingDigitsMatcher[1]}ML`;
    }
    // no $ symbol for this limits section
    return this.currencyPipe.transform(
      value?.substring(0, 3),
      'USD',
      '',
      '1.0-0'
    );
  }

  editCoverages(): void {
    this.routerService.go({
      path: ['/quote/coverages'],
      extras: {
        queryParams: { product: this.product.type },
      },
    });
  }

  promptToRemovePolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndRemoveProduct(this.product);
  }

  promptToWithdrawPolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndWithdrawProduct(this.product);
  }

  private onFormChanged(
    value: [Partial<MsaCoverageDisplayFormModel>, Nullable<boolean>]
  ): void {
    const updateInprogress = value[1] === true;
    const newDate = DateUtils.formatDateToDSM(value[0]?.effectiveDate);
    if (
      updateInprogress ||
      !this.product ||
      this.product.effectiveDate === newDate ||
      !this.form.valid
    ) {
      return;
    }
    this.productsService.updateQuote({
      productType: this.product.type,
      effectiveDate: newDate,
    });
  }
}
