import { PersonEntity } from '@core/models/entities/person.entity';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { DriverEntity } from '../../driver/driver.entity';
import { MemberEntity } from '../../member/member.reducer';
import { PolicyHolderEntity } from '../../policyholder/policyholder.entity';
import { TaskValidators } from '../task-validators';
import { TaskVerbiage } from '../task-verbiage';
import { RuleTemplate } from '../task.model';

export const MemberRules = {
  members: {
    policyHolders: [],
    policyHolder: [],
    people: [
      {
        rule: TaskValidators.requiredEvenMarriedPeople(),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.atLeastOne<MemberEntity>(
          'Driver',
          TaskVerbiage.DRIVER_REQUIRED,
          'Draft',
          'PersonalAuto'
        ),
        productTypes: ['PersonalAuto', 'MSA', 'RV', 'Boat'],
        quoteStatus: 'Draft',
      },
    ] as RuleTemplate[],
    person: [
      {
        rule: TaskValidators.requiredField<PersonEntity>(
          'Please indicate marital status',
          'Draft',
          'All',
          'maritalStatus',
          'marital-status'
        ),
      },
    ] as RuleTemplate[],
    pni: [
      {
        rule: TaskValidators.requiredField<PolicyHolderEntity>(
          'Phone number is required',
          'Quoted',
          'All',
          'homeNumber',
          'phone'
        ),
      },
      // Probably not needed and doesn't really work
      // {
      //   rule: TaskValidators.requiredField<PolicyholderEntity>(
      //     'Address is required',
      //     'Draft',
      //     'All',
      //     'address',
      //     'address'
      //   ),
      // },
    ] as RuleTemplate[],
    driver: [
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            (driver.driverRoles?.some(
              (p) => p.driverType === 'Driver'
            ) as boolean) && !driver.licenseNumber,
          "Driver's license number is required",
          'Quoted',
          'PersonalAuto',
          'licenseNumber',
          'license-number'
        ),
        productTypes: ['PersonalAuto', 'MSA', 'Boat', 'RV'],
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredField<DriverEntity>(
          "Driver's license state is required",
          'Quoted',
          'PersonalAuto',
          'licenseState',
          'license-state'
        ),
        productTypes: ['PersonalAuto', 'MSA', 'Boat', 'RV'],
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredField<DriverEntity>(
          'Please indicate relation to primary named insured',
          'Quoted',
          'PersonalAuto',
          'relationToPrimaryNamedInsured',
          'relation-to-pni'
        ),
        productTypes: ['PersonalAuto', 'MSA', 'Boat', 'RV'],
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            driver.driverType === 'Driver' && !driver.ageFirstLicensed,
          'Driver age first licensed required',
          'Quoted',
          'PersonalAuto',
          'ageFirstLicensed',
          'age-first-licensed'
        ),
        productTypes: ['PersonalAuto', 'MSA', 'RV', 'Boat'],
        feature: 'ageFirstLicensed',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            driver.relationToPrimaryNamedInsured === 'PrimaryNamedInsured' &&
            !driver.driverOccupation,
          'Driver occupation is required',
          'Quoted',
          'PersonalAuto',
          'driverOccupation',
          'driver-occupation'
        ),
        feature: 'driverOccupation',
        productTypes: ['PersonalAuto', 'MSA'],
        quoteStatus: 'Quoted',
      },

      {
        rule: TaskValidators.nonEntityObjectRequiredFieldIf<DriverEntity>(
          (driver) =>
            (driver.driverRoles?.some(
              (p) => p.driverType === 'Driver'
            ) as boolean) && !driver.employmentInformation,
          'employmentInformation',
          'Employment information is required',
          'Draft',
          'PersonalAuto',
          'current-employment',
          ['currentEmployment', 'fullTimeEmployment']
        ),
        feature: 'driverOccupation',
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            (driver.driverRoles?.some(
              (p) => p.driverType === 'Driver'
            ) as boolean) && !driver.infractionType,
          'Driving history is required',
          'Quoted',
          'PersonalAuto',
          'infractionType',
          'infraction-type'
        ),
        feature: 'infractionDesc',
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            PersonUtils.isYoungDriver(driver as MemberEntity) &&
            typeof driver.isLivingWithNWInsuredParent !== 'boolean',
          "Is living with insured parent or spouse is required when driver's age is less than 25.",
          'Quoted',
          'PersonalAuto',
          'isLivingWithNWInsuredParent',
          'with-parent'
        ),
        productTypes: ['PersonalAuto'],
        feature: 'msaYouthfulDriver',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            PersonUtils.isYoungDriver(driver as MemberEntity) &&
            !GeneralUtils.isBoolean(driver.goodStudent),
          "Good Student field is required when driver's age is less than 25.",
          'Quoted',
          'PersonalAuto',
          'goodStudent',
          'good-student'
        ),
        productTypes: ['PersonalAuto'],
        feature: 'msaYouthfulDriver',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            PersonUtils.isYoungDriver(driver as MemberEntity) &&
            typeof driver.hasGraduatedCollege !== 'boolean',
          'Please indicate driver has graduated college',
          'Quoted',
          'MSA',
          'hasGraduatedCollege',
          'graduated-college'
        ),
        productTypes: ['PersonalAuto'],
        feature: ['msaYouthfulDriver'],
      },
      {
        rule: TaskValidators.nonNullField<DriverEntity>(
          (entity) => entity.driverType !== 'Excluded',
          'Please indicate years of motorcycle and off-road experience',
          'Draft',
          'MSA',
          'yearsOfMotorcycleAndOffRoadExp',
          'years-of-motorcycle-exp'
        ),
        feature: 'yearsOfMotorcycleAndOffRoadExp',
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            !driver.hasMotorcycleEndorsement &&
            driver.driverType !== 'Excluded',
          'Please indicate motorcycle endorsement',
          'Draft',
          'MSA',
          'hasMotorcycleEndorsement',
          'endorsement'
        ),
        feature: 'hasMotorcycleEndorsement',
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredFieldIf<DriverEntity>(
          (driver) =>
            GeneralUtils.isBoolean(driver.hasMotorcycleEndorsement) &&
            !!driver.hasMotorcycleEndorsement &&
            (typeof driver.endorsementDate !== 'string' ||
              !driver.endorsementDate?.length),
          'Please indicate motorcycle endorsement date',
          'Draft',
          'MSA',
          'endorsementDate',
          'endorsement-date'
        ),
        feature: 'hasMotorcycleEndorsement',
        quoteStatus: 'Draft',
      },
    ] as RuleTemplate[],
  },
};
