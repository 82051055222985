import {
  InformationalMessages,
  sanitizeInformationalMessages,
} from '@core/adapters/quote-retrieve.adapter';
import { ObjectValidator } from '@core/helper/object-validator';

export interface PolicyDocumentResponse {
  name: string;
  documentByteStream: string;
  informationalMessages?: InformationalMessages[];
}

export function sanitizePolicyDocumentResponse(
  input: unknown
): PolicyDocumentResponse {
  return ObjectValidator.forceSchema<PolicyDocumentResponse>(
    input,
    {
      documentByteStream: 'string',
      name: 'string',
      informationalMessages: [sanitizeInformationalMessages],
    },
    ['informationlMessages']
  );
}
