<div class="modal-header">
  <div>
    <span class="modal-header-title" id="telematicsChangeModalHeader"
      >Choose a program</span
    >
  </div>
  <span class="modal-header-label bolt-body-copy-sm">
    {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
  </span>
</div>
<div class="modal-body bolt-typography">
  <form
    [formGroup]="form"
    [nwxExposeFormErrors]="form"
    formName="telematics-change"
    ngbAutoFocus
  >
    <a
      *ngIf="recommendation"
      href="javascript:void(0)"
      (click)="disableSmartMiles ? null : setSelectedProgram('SmartMiles', $event)"
      class="text-decoration-none"
      [class.nwx-tile]="!disableSmartMiles"
      [class.nwx-tile-disabled]="disableSmartMiles"
      [ngClass]="{ selected: isSelectedProgram('SmartMiles') }"
      id="smartMiles"
    >
      <div
        class="tile-content"
        [class.bolt-space-bottom-sm]="
          showTelematicsWarning || showPaperlessWarning
        "
      >
        <div class="tile-img-content float-left">
          <img
            class="tile-img"
            src="/assets/images/telematics/smartmiles-purple.svg"
            alt="car icon"
          />
        </div>
        <div class="tile-text-content">
          <span class="content-label"
            >SmartMiles&reg; pay-per-mile program, with a device</span
          >
          <span class="content-subtext bolt-body-copy-sm"
            >For vehicles driven less than
            {{ tamFromRecommendation | number }} miles a year
          </span>
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'SmartMiles' ? 'SmartMiles' : null
            "
            [disabled]="disableSmartMiles"
            (change)="onRadioClicked('SmartMiles', $event)"
          >
            <bolt-radio
              value="SmartMiles"
              id="tcm-smart-miles"
              arialabel="Smart miles"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
      <ng-container *ngIf="showTelematicsWarning || showPaperlessWarning">
        <div class="bolt-space-bottom-sm">
          <nwx-notification-bubble type="info" size="sm" paddingsize="sm">
            <ng-container
              *ngFor="let message of smartmilesMessages; let i = index"
            >
              <div class="notification-message">
                <p class="bolt-body-copy-sm mb-0">
                  <strong>{{ message.topic }}:</strong>
                  {{ message.message }}
                </p>
                <hr *ngIf="i !== smartmilesMessages.length - 1" />
              </div>
            </ng-container>
          </nwx-notification-bubble>
        </div>
        <nwx-notification-bubble type="warning" size="sm" paddingsize="sm">
          <div class="notification-message">
            <p class="bolt-body-copy-sm mb-0">
              <ng-container
                *ngIf="
                  showTelematicsWarning && showPaperlessWarning;
                  else singleWarning
                "
              >
                By continuing you will be switched to
                <strong>Email (Paperless)</strong> option and
                <strong>Recurring EFT</strong> after recalculating the quote.
              </ng-container>
              <ng-template #singleWarning>
                <ng-container *ngIf="showTelematicsWarning">
                  By continuing you will be switched to
                  <strong>Recurring EFT</strong> after recalculating the quote.
                </ng-container>
                <ng-container *ngIf="showPaperlessWarning">
                  By continuing you will be switched to
                  <strong>Email (Paperless)</strong> option after recalculating
                  the quote.
                </ng-container>
              </ng-template>
            </p>
          </div>
        </nwx-notification-bubble>
      </ng-container>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('SmartRideInstant', $event)"
      class="text-decoration-none nwx-tile"
      *ngIf="showSmartRideInstant"
      [ngClass]="{ selected: isSelectedProgram('SmartRideInstant') }"
      id="smartRideInstant"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left">
          <img
            class="tile-img"
            src="/assets/images/telematics/connected-car-dk-blue.svg"
            alt="car icon"
          />
        </div>
        <div class="tile-text-content">
          <span class="content-label"
            >{{
              vehicle.enrollmentStatus !== 'NotEnrolled'
                ? vehicle.discountPercentage + '% '
                : ''
            }}SmartRide&reg; safe-driving discount applied</span
          >
          <span class="content-subtext bolt-body-copy-sm"
            >With the vehicle driving data you've already shared</span
          >
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'SmartRideInstant'
                ? 'SmartRideInstant'
                : null
            "
            (change)="onRadioClicked('SmartRideInstant', $event)"
          >
            <bolt-radio
              value="SmartRideInstant"
              id="tcm-smart-ride-instant"
              arialabel="SmartRide instant"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('SmartMilesConnectedCar', $event)"
      class="text-decoration-none nwx-tile"
      *ngIf="showSmartMilesConnectedCar"
      [ngClass]="{ selected: isSelectedProgram('SmartMilesConnectedCar') }"
      id="smartMilesConnectedCar"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left">
          <img
            class="tile-img"
            src="/assets/images/telematics/connected-car-purple.svg"
            alt="car icon"
          />
        </div>
        <div class="tile-text-content">
          <span class="content-label"
            >SmartMiles&reg; pay-per-mile program, with a connected car</span
          >
          <span *ngIf="!isCA()" class="content-subtext bolt-body-copy-sm"
            >For vehicles driven less than
            {{ tamFromRecommendation | number }} miles a year
          </span>
          <span *ngIf="isCA()" class="content-subtext bolt-body-copy-sm"
            >Based on mileage collected by your vehicle</span
          >
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'SmartMilesConnectedCar'
                ? 'SmartMilesConnectedCar'
                : null
            "
            (change)="onRadioClicked('SmartMilesConnectedCar', $event)"
          >
            <bolt-radio
              value="SmartMilesConnectedCar"
              id="tcm-smart-miles-connected-car"
              arialabel="SmartMiles Connected Car"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('ConnectedCar', $event)"
      class="text-decoration-none nwx-tile"
      *ngIf="showSmartRideConnectedCar"
      [ngClass]="{ selected: isSelectedProgram('ConnectedCar') }"
      id="connectedCar"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left">
          <img
            class="tile-img"
            src="/assets/images/telematics/connected-car-dk-blue.svg"
            alt="car icon"
          />
        </div>
        <div class="tile-text-content">
          <ng-container *ngIf="!isCA(); else CASmartRideCCContent">
            <span class="content-label"
              >SmartRide&reg; safe-driving discount, with a connected car</span
            >
            <span class="content-subtext bolt-body-copy-sm"
              >For vehicles driven more than
              {{ tamFromRecommendation | number }} miles a year
            </span>
          </ng-container>
          <ng-template #CASmartRideCCContent>
            <span class="content-label"
              >SmartRide&reg; low-mileage discount, with a connected car</span
            >
            <span class="content-subtext bolt-body-copy-sm"
              >Vehicle measures mileage</span
            >
          </ng-template>
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'ConnectedCar' ? 'ConnectedCar' : null
            "
            (change)="onRadioClicked('ConnectedCar', $event)"
          >
            <bolt-radio
              value="ConnectedCar"
              id="tcm-smart-ride-connected-car"
              arialabel="SmartRide Connected Car"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('SmartRide', $event)"
      class="text-decoration-none nwx-tile"
      [ngClass]="{ selected: isSelectedProgram('SmartRide') }"
      id="smartRide"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left">
          <img
            class="tile-img"
            src="/assets/images/telematics/smartride-teal.svg"
            alt="car icon"
          />
        </div>
        <div class="tile-text-content">
          <div class="tile-text-content" *ngIf="!showSmartRideDeviceLabel">
            <span class="content-label"
              >SmartRide&reg; safe-driving discount, with a mobile app</span
            >
            <span class="content-subtext bolt-body-copy-sm"
              >For vehicles driven more than
              {{ tamFromRecommendation | number }} miles a year</span
            >
          </div>
          <div class="tile-text-content" *ngIf="showSmartRideDeviceLabel">
            <ng-container *ngIf="!isCA(); else CASmartRideContent">
              <span class="content-label"
                >SmartRide&reg; safe-driving discount, with a device</span
              >
              <span class="content-subtext bolt-body-copy-sm"
                >For vehicles driven more than
                {{ tamFromRecommendation | number }} miles a year</span
              >
            </ng-container>
            <ng-template #CASmartRideContent>
              <span class="content-label">
                SmartRide&reg; low-mileage discount, with a device
              </span>
              <span class="content-subtext bolt-body-copy-sm">
                Plug-in device measures mileage
              </span>
            </ng-template>
          </div>
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'SmartRide' ? 'SmartRide' : null
            "
            (change)="onRadioClicked('SmartRide', $event)"
          >
            <bolt-radio
              value="SmartRide"
              id="tcm-smart-ride"
              arialabel="SmartRide"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('SelfReported', $event)"
      class="text-decoration-none nwx-tile"
      *ngIf="isSelfReportedEligibleState"
      [ngClass]="{ selected: isSelectedProgram('SelfReported') }"
      id="selfReported"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left"></div>
        <div class="tile-text-content">
          <span class="content-label"
            >Verified self-reported annual program, with a form</span
          >
          <span class="content-subtext bolt-body-copy-sm">
            Completed at enrollment and before every policy renewal
          </span>
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'SelfReported' ? 'SelfReported' : null
            "
            (change)="onRadioClicked('SelfReported', $event)"
          >
            <bolt-radio
              value="SelfReported"
              id="tcm-self-reported"
              arialabel="Self Reported"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
    <a
      href="javascript:void(0)"
      (click)="setSelectedProgram('NotEnrolled', $event)"
      class="text-decoration-none nwx-tile"
      [ngClass]="{ selected: isSelectedProgram('NotEnrolled') }"
      id="noProgram"
    >
      <div class="tile-content">
        <div class="tile-img-content float-left"></div>
        <div class="tile-text-content">
          <span class="content-label">No programs</span>
          <span class="content-subtext bolt-body-copy-sm"
            >Decline telematics for this vehicle</span
          >
        </div>
        <div class="tile-radio float-right">
          <bolt-radio-group
            [value]="
              selectedProgram?.value === 'NotEnrolled' ? 'NotEnrolled' : null
            "
            (change)="onRadioClicked('NotEnrolled', $event)"
          >
            <bolt-radio
              value="NotEnrolled"
              id="tcm-not-enrolled"
              arialabel="Not Enrolled"
              >&nbsp;</bolt-radio
            >
          </bolt-radio-group>
        </div>
      </div>
    </a>
  </form>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="back"
      [attr.id]="'tcm-cancel'"
      name="tcm-cancel"
      (click)="closeModal()"
      >Cancel</bolt-button
    >
    <bolt-button
      slot="forward"
      type="primary"
      [attr.id]="'tcm-save'"
      name="tcm-save"
      (click)="updateProgram()"
      >Save</bolt-button
    >
  </bolt-button-bar>
</div>
