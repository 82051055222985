import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  CoverageSelectors,
  CoveredLocationSelectors,
  CurrentCarrierSelectors,
  EligibleDiscountSelectors,
  MemberSelectors,
  ProductsSelectors,
  TelematicsSelectors,
  VehicleSelectors,
  ScheduledCategorySelectors,
  PersonaSelectors,
} from '@core/store/selectors';
import {
  PersonaRecommendation,
  PersonaRecommendationId,
  PersonaReport,
} from './persona.model';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { ScheduledCategoryEntity } from '@entities/scheduled-categories/scheduled-category.entity';
import { ProductModel } from '@entities/product/product.model';
import { getBillingPlansForm } from '@forms-store/store/models/billing-plans/billing-plans.selector';
import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';

export const getPersonaState = createSelector(
  fromCore.getCoreState,
  (core) => core.persona
);

export const getPersonaName = createSelector(
  getPersonaState,
  (state) => state.name
);

export const getManualPersonaName = createSelector(
  getPersonaState,
  (state) => state.manualName
);

export const getPersonaSelectionInputs = createSelector(
  ProductsSelectors.getSelectedProductEntities,
  VehicleSelectors.getAllSelectedVehiclesByProduct('PersonalAuto'),
  CurrentCarrierSelectors.getCurrentCarrierEntities,
  CoverageSelectors.getAllCoverages,
  MemberSelectors.getAllSelectedDriverMemberModels('PersonalAuto'),
  TelematicsSelectors.getCurrentEnrollment,
  CoveredLocationSelectors.getCoveredLocationEntities,
  PersonaSelectors.getPersonaName,
  (
    products,
    vehicles,
    currentCarriers,
    coverages,
    drivers,
    telematics,
    coveredLocations,
    personaName
  ) => ({
    products,
    vehicles,
    currentCarriers,
    coverages,
    drivers,
    telematics,
    coveredLocations,
    personaName,
  })
);

function populateRecommendation(
  recommendation: PersonaRecommendation,
  coverages: CoverageEntity[],
  telematicsEnrolled: boolean,
  telematicsDeclined: boolean,
  selectedProducts: ProductModel[],
  discounts: EligibleDiscountsEntity[],
  scheduledCategories: ScheduledCategoryEntity[] | undefined,
  billingPlansForm: BillingPlansFormModel
): void {
  // The recommendation's product must be selected.
  switch (recommendation.product) {
    case 'Auto':
      {
        if (!selectedProducts.find((p) => p.type === 'PersonalAuto')) {
          recommendation.available = false;
          return;
        }
      }
      break;
    case 'Property':
      {
        if (
          !selectedProducts.find(
            (p) =>
              p.type === 'Homeowner' ||
              p.type === 'Tenant' ||
              p.type === 'Condominium'
          )
        ) {
          recommendation.available = false;
          return;
        }
      }
      break;
  }
  const standardCoverage = (
    coverageId: string,
    optionCode = 'selected',
    optionValue: string | boolean = 'true'
  ) => {
    for (const coverage of coverages.filter(
      (coverage) => coverage.coverageId === coverageId
    )) {
      if (coverage.available) {
        recommendation.available = true;
        if (typeof optionValue === 'string') {
          if (
            coverage.selectedValue?.find(
              (v) => v.code === optionCode && v.value === optionValue
            )
          ) {
            recommendation.present = true;
          }
        } else {
          if (
            coverage.selectedValue?.find(
              (v) => v.code === optionCode && v.value
            )
          ) {
            recommendation.present = true;
          }
        }
      }
    }
  };
  switch (recommendation.id) {
    case 'Telematics':
      if (telematicsEnrolled) {
        recommendation.present = true;
      } else if (!telematicsDeclined) {
        recommendation.available = true;
      }
      break;

    case 'IdTheft':
      {
        const idTheftCoverages = coverages.filter(
          (c) =>
            ['IDFR', 'IdentityTheft'].includes(c.coverageId) &&
            selectedProducts.find((p) => p.type === c.productId)
        );
        if (idTheftCoverages?.some((coverage) => coverage.available)) {
          if (
            idTheftCoverages?.some((coverage) =>
              coverage.selectedValue?.find((v) =>
                ['IDFRLimit', 'IdentityTheftLimit'].includes(v.code)
              )
            )
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'ValuablesPlus':
      recommendation.available = !!scheduledCategories?.some(
        (cat) => !!cat.isScheduledItemsApplicable
      );
      recommendation.present = !!scheduledCategories?.some(
        (cat) =>
          (cat.scheduledItems ? cat.scheduledItems.length > 0 : false) ||
          (cat.limit ? cat.limit.length > 0 : false)
      );
      break;

    case 'Warranty':
      //TODO No idea what this is.
      break;

    case 'RoadsidePlus':
      standardCoverage('RSA', 'RSAOption', '1');
      break;

    case 'SmartHome':
      {
        const discount = discounts.find(
          (c) => c.eligibleDiscountId === 'SmartDevice'
        );
        if (discount) {
          if (discount.selectedOptionValue === 'Participating') {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'Umbrella':
      if (selectedProducts.find((p) => p.type === 'PersonalUmbrella')) {
        recommendation.present = true;
      } else {
        recommendation.available = true;
      }
      break;

    case 'HomeMaintenanceJourneyGuide':
      //TODO What is this?
      break;

    case 'ServiceLine':
      standardCoverage('ServiceLine', 'ServiceLineLimit', true);
      break;

    case 'EquipmentBreakdown':
      standardCoverage('EquipmentBreakdown');
      break;

    case 'WaterBackupLimited':
      standardCoverage('WaterBackupLimited', 'WaterBackupLimitedLimit', true);
      recommendation.isCoverageWithLimit = true;
      break;

    case 'OEM':
      standardCoverage('OEM');
      break;

    case 'NewCarReplacement':
      standardCoverage('NCRPCOLL');
      break;

    case 'AccidentForgiveness':
      standardCoverage('AF');
      break;

    case 'VanishingDeductible':
      standardCoverage('VD');
      break;

    case 'BrandNewBelongings':
      standardCoverage('BrandNewBelongings');
      break;

    case 'Paperless':
      {
        if (
          selectedProducts.find(
            (p) => p.docDelPreference === 'OnlineAccountAccess'
          )
        ) {
          recommendation.present = true;
        } else {
          recommendation.available = true;
        }
      }
      break;

    case 'PayInFull':
      {
        if (billingPlansForm.billingPlan === 'PAY IN FULL') {
          recommendation.present = true;
        } else {
          recommendation.available = true;
        }
      }
      break;
  }
  if (recommendation.present) {
    recommendation.available = true;
  }
}

export const getPersonaReport = createSelector(
  getPersonaName,
  getManualPersonaName,
  CoverageSelectors.getAllCoverages,
  TelematicsSelectors.isAnyTelematicsEnrolled,
  TelematicsSelectors.isTelematicsDeclined,
  ProductsSelectors.getSelectedProductEntities,
  ProductsSelectors.getSelectedProductTypes,
  EligibleDiscountSelectors.selectAllEligibleDiscount,
  ScheduledCategorySelectors.getScheduledCategoriesForSelectedProduct,
  getBillingPlansForm,
  (
    name,
    manualName,
    coverages,
    telematicsEnrolled,
    telematicsDeclined,
    selectedProducts,
    selectedProductTypes,
    discounts,
    scheduledCategories,
    billingPlansForm
  ) => {
    let isManualSelection = false;
    if (manualName) {
      name = manualName;
      isManualSelection = true;
    }
    const result: PersonaReport = {
      name,
      isManualSelection,
      recommendations: [],
    };
    const recommend = (
      id: PersonaRecommendationId,
      product: 'Auto' | 'Property' | ''
    ): void => {
      const recommendation = {
        id,
        product,
        present: false,
        available: false,
        isCoverageWithLimit: false,
      };
      populateRecommendation(
        recommendation,
        coverages,
        telematicsEnrolled,
        telematicsDeclined,
        selectedProducts,
        discounts,
        scheduledCategories,
        billingPlansForm
      );
      result.recommendations.push(recommendation);
    };
    switch (name) {
      case 'UpcomingProfessional':
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('Telematics', 'Auto');
        recommend('IdTheft', '');
        recommend('Paperless', '');
        recommend('PayInFull', '');
        break;
      case 'FirstTimeHomeBuyer':
        // recommend('HomeMaintenanceJourneyGuide', 'Property');
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        break;
      case 'TeenDriver':
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        break;
      case 'EmptyNester':
        if (
          selectedProductTypes.includes('Homeowner') ||
          selectedProductTypes.includes('Condominium')
        ) {
          recommend('EquipmentBreakdown', 'Property');
        }
        if (selectedProductTypes.includes('Homeowner')) {
          recommend('ServiceLine', 'Property');
        }
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('WaterBackupLimited', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('Telematics', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        recommend('Paperless', '');
        recommend('PayInFull', '');
        break;
      case 'DoItYourselfer':
        break;
      case 'Other':
        recommend('Telematics', 'Auto');
        recommend('IdTheft', '');
        recommend('ValuablesPlus', 'Property');
        //recommend('Warranty', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('SmartHome', 'Property');
        recommend('Umbrella', '');
        //recommend('HomeMaintenanceJourneyGuide', 'Property');
        if (selectedProductTypes.includes('Homeowner')) {
          recommend('ServiceLine', 'Property');
        }
        if (
          selectedProductTypes.includes('Homeowner') ||
          selectedProductTypes.includes('Condominium')
        ) {
          recommend('EquipmentBreakdown', 'Property');
        }
        recommend('WaterBackupLimited', 'Property');
        recommend('OEM', 'Auto');
        recommend('NewCarReplacement', 'Auto');
        recommend('AccidentForgiveness', 'Auto');
        recommend('VanishingDeductible', 'Auto');
        recommend('BrandNewBelongings', 'Property');
        recommend('Paperless', '');
        recommend('PayInFull', '');
        break;
    }
    return result;
  }
);
