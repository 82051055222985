import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  PolicyDateOptions,
  RenovationEntity,
} from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageModel } from '@core/models/views/coverage.model';
import { ProductsService } from '@core/services/products.service';
import { QuoteService } from '@core/services/quote.service';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ErrorModel } from '@entities/error/error.model';
import { rateQuoteFail } from '@entities/quote/quote.action';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerInputComponent } from '@shared/components/datepicker-input/datepicker-input.component';
import { DateUtils } from '@shared/utils/date.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';

interface CondoCoverageDisplayFormModel {
  effectiveDate: Nullable<string>; // "MM/DD/YYYY"
}

@Component({
  selector: 'nwx-condo-coverage-display',
  templateUrl: './condo-coverage-display.component.html',
  styleUrls: ['./condo-coverage-display.component.scss'],
})
export class CondoCoverageDisplayComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() product!: ProductModel;
  @Input() coveredLocation!: CoveredLocationEntity;
  @Input() maxSizeMini!: boolean;
  @Input() errors: ErrorModel[] = [];

  form = new FormGroup({
    effectiveDate: new FormControl('', [
      (control) =>
        DatepickerInputComponent.validate(
          control,
          this.policyDateOptions?.minDate || '',
          this.policyDateOptions?.maxDate || ''
        ),
    ]),
  });

  policyDateOptions: Nullable<PolicyDateOptions> = null;

  totalPolicyPremium = 0;
  termLengthMonths = 6;
  formattedAddress = '';
  yearRoofReplaced = 0;

  remainingCoverageCount = 0;
  displayCoverages: CoverageModel[] = [];
  displayDeductibles: CoverageModel[] = [];
  updateInProgress$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();
  showDatePicker: boolean = false;
  formValueDate: any;

  displayCoverageIds: string[] = [
    'PersonalLiability',
    'MedicalPayments',
    'BasicCoverage',
    'IncreasedLossOfUse',
  ];

  deductibleCoverageIds: string[] = ['SectionIDeductibles'];

  deductibleCodes: string[] = ['AllPerilDeductible'];

  constructor(
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private productsService: ProductsService,
    private ref: ChangeDetectorRef
  ) {
    this.updateInProgress$ = this.quoteService
      .getQuoteUpdateInProgress()
      .pipe(map((v) => !!v));
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.updateInProgress$)
      )
      .subscribe(this.onFormChanged.bind(this));
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.showDatePicker = false;
  }

  ngOnInit(): void {
    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((options) => {
        this.policyDateOptions = options;
        this.ref.detectChanges();
      });
  }

  ngOnChanges(changes: ComponentChanges<CondoCoverageDisplayComponent>): void {
    if (changes.premiums || changes.errors) {
      if (
        this.errors.find(
          (e) =>
            e.productType === 'Condominium' &&
            e.sourceActionType === rateQuoteFail.type
        )
      ) {
        this.totalPolicyPremium = 0;
      } else {
        const condoPremium = this.premiums.find(
          (premium) => premium.productType === 'Condominium'
        );
        if (condoPremium) {
          this.totalPolicyPremium = condoPremium.total?.amount || 0;
          this.termLengthMonths = condoPremium.termMonths || 6;
        }
      }
    }
    if (changes.coveredLocation) {
      this.formatAddress();
    }
    if (changes.coverages) {
      this.buildDisplayCoverages();
    }
    if (changes.product) {
      this.form.patchValue({
        effectiveDate: DateUtils.formatDsmDateToOld(
          this.product.effectiveDate || ''
        ),
      });
    }
  }

  ngDoCheck(): void {
    if (this.formValueDate !== this.form.value.effectiveDate) {
      this.showDatePicker = false;
      this.formValueDate = this.form.value.effectiveDate;
    }
  }

  private formatAddress(): void {
    const location = this.coveredLocation.location;
    if (location) {
      this.formattedAddress = `${location.addressLine1}, ${location.city}, ${
        location.state
      } ${location.postalCode?.substring(0, 5)}`;
    }
  }

  private buildDisplayCoverages(): void {
    this.displayCoverages = [];
    const displayEntities = this.coverages.filter(
      (c) =>
        c.productId === 'Condominium' &&
        this.displayCoverageIds.includes(c.coverageId)
    );
    displayEntities.forEach((cov) => {
      const val = cov.selectedValue[0]?.value || '';
      if (cov.coverageId === 'IncreasedLossOfUse') {
        this.displayCoverages.push({
          name: cov.name,
          value: cov.selectedValue[0]?.description || '',
        });
      } else if (this.isNumber(val)) {
        this.displayCoverages.push({
          name: cov.name,
          value: this.currencyPipe.transform(val) || '',
        });
      } else if (this.isAcceptDecline(val)) {
        this.displayCoverages.push({
          name: cov.name,
          value: val === 'true' ? 'Accept' : 'Decline',
        });
      }
    });

    this.remainingCoverageCount =
      this.coverages.filter((c) => c.productId === 'Condominium').length -
      this.displayCoverages.length;

    this.displayDeductibles = [];
    const deductibleCoverages = this.coverages.filter(
      (c) =>
        c.productId === 'Condominium' &&
        this.deductibleCoverageIds.includes(c.coverageId)
    );
    for (const cov of deductibleCoverages) {
      if (cov.coverageId === 'SectionIDeductibles') {
        const deductible = cov.selectedValue.find(
          (val) => val.code === 'AllPerilDeductible'
        );
        this.displayDeductibles.push({
          name: 'All other perils',
          value: deductible?.description || '',
        });
        break;
      }
    }
  }

  isNumber(val: string | number): boolean {
    return parseInt(`${val}`, 10) ? true : false;
  }

  isAcceptDecline(val: string): boolean {
    return val === 'false' || val === 'true';
  }

  editCoverages(): void {
    this.router.navigate(['/quote/coverages'], {
      queryParams: { product: 'Condominium' } as { product: ProductType },
    });
  }

  promptToRemovePolicy(event?: Event, content?: unknown): void {
    event?.preventDefault();
    if (content) {
      const modal = this.modalService.open(content);
      modal.result
        .then(() => {
          this.productsService.removeProduct(this.product);
        })
        .catch(() => {
          // Cancelled, no worries.
        });
    }
  }

  private onFormChanged(
    value: [Partial<CondoCoverageDisplayFormModel>, Nullable<boolean>]
  ): void {
    const updateInprogress = value[1] === true;
    const newDate = DateUtils.formatDateToDSM(value[0]?.effectiveDate);
    if (
      updateInprogress ||
      !this.product ||
      this.product.effectiveDate === newDate ||
      !this.form.valid
    ) {
      return;
    }
    this.productsService.updateQuote({
      productType: 'Condominium',
      effectiveDate: newDate,
    });
  }
}
