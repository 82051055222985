import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreakDisplay',
})
export class LineBreakDisplayPipe implements PipeTransform {
  transform(address: string): { line1: string; line2: string } {
    if (!address || !address.length) {
      return { line1: '', line2: '' };
    }
    if (address.includes(',')) {
      const firstCommaIdx = address.indexOf(',');
      const line1 = `${address.split('').slice(0, firstCommaIdx).join('')}`;
      const line2 = `${address
        .split('')
        .slice(firstCommaIdx + 1)
        .join('')}`;
      return { line1, line2 };
    }
    return { line1: address, line2: '' };
  }
}
