import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Message } from '@core/adapters/quote-retrieve.adapter';
import { ProductHelper } from '@core/helper/product.helper';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { ErrorMessageService } from '@core/services/error-message.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ProductsService } from '@core/services/products.service';
import { TaskService } from '@core/services/task.service';
import {
  ErrorModel,
  ErrorRemediation,
  ValidationErrorModel,
} from '@core/store/entities/error/error.model';
import {
  getCurrentPageValidationErrors,
  getDisplayableErrors,
} from '@core/store/entities/error/error.selector';
import { linkToPolicyCenter } from '@entities/navigation/navigation.action';
import {
  getProduct,
  getProductWarningMessages,
} from '@entities/product/product.selectors';
import {
  NotificationModel,
  getTaskNotificationsForCurrentPage,
} from '@entities/task/selectors/task.selector';
import { Action, Store } from '@ngrx/store';
import { ProductUtils } from '@shared/utils/product.util';
import { NEVER, Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { filter, map, pairwise, take, takeUntil } from 'rxjs/operators';

export interface ErrorDisplayModel extends ErrorModel {
  // For display purposes these two ErrorModel fields are required (we ensure it)
  displayMessage: string;
  remediation: ErrorRemediation;
  // And some stuff used only here:
  buttonAction: 'dismiss' | 'pc';
}

export interface WarningDisplayModel extends Message {
  productType: ProductType;
  buttonLabel: string;
  buttonAction: 'dismiss' | 'pc';
}

@Component({
  selector: 'nwx-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBarComponent implements OnDestroy, OnInit {
  @Input() position = 'all';
  notifications$: Observable<NotificationModel[]> = NEVER;
  errors$: Observable<ErrorDisplayModel[]> = NEVER;
  warnings$: Observable<WarningDisplayModel[]> = NEVER;
  warningLevelErrors$: Observable<ErrorDisplayModel[]> = NEVER;
  infoLevelErrors$: Observable<ErrorDisplayModel[]> = NEVER;
  validationErrors$: Observable<ValidationErrorModel[]> = NEVER;
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private taskService: TaskService,
    private errorMessageService: ErrorMessageService,
    private errorHandlerService: ErrorHandlerService,
    private store: Store,
    private element: ElementRef,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.validationErrors$ = this.store.select(getCurrentPageValidationErrors);
    this.errors$ = this.store.select(getDisplayableErrors).pipe(
      map((errors) => this.sanitizeErrorsForDisplay(errors)),
      map((errors) => this.removeUnwantedErrorsForDisplay(errors)),
      map((errors) => this.filterErrorsByPosition(errors, this.position))
    );
    this.warningLevelErrors$ = this.errorHandlerService
      .getConvertedErrors('warning')
      .pipe(
        map((errors) => this.sanitizeErrorsForDisplay(errors)),
        map((errors) => this.filterErrorsByPosition(errors, this.position))
      );
    this.infoLevelErrors$ = this.errorHandlerService
      .getConvertedErrors('information')
      .pipe(
        map((errors) => this.sanitizeErrorsForDisplay(errors)),
        map((errors) => this.filterErrorsByPosition(errors, this.position))
      );
    this.warnings$ = this.store
      .select(getProductWarningMessages)
      .pipe(map((warnings) => this.removeUnwantedWarningsForDisplay(warnings)));
    this.notifications$ = this.store.select(getTaskNotificationsForCurrentPage);

    combineLatest([
      merge(this.errors$, of([])),
      merge(this.warnings$, of([])),
      merge(this.warningLevelErrors$, of([])),
      merge(this.notifications$, of([])),
      merge(this.infoLevelErrors$, of([])),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map((errs) => errs.flat()),
        pairwise(),
        filter(([prev, next]) => {
          if (prev.length < next.length) {
            return true;
          }
          return false;
        })
      )
      .subscribe(() => {
        this.scrollToMessage();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private sanitizeErrorsForDisplay(errors: ErrorModel[]): ErrorDisplayModel[] {
    const outputs: ErrorDisplayModel[] = [];
    if (errors) {
      for (const input of errors) {
        let displayMessage = '';
        let buttonAction: '' | 'dismiss' | 'pc' = '';
        let buttonLabel = '';
        if (ProductHelper.isQuoteBlockingError(input)) {
          if (
            outputs.find(
              (o) =>
                o.productType === input.productType && o.buttonAction === 'pc'
            )
          ) {
            // Don't duplicate quote-blocking-error messages per product.
            continue;
          }
          displayMessage =
            'Additional documentation may be required prior to finalizing the quote.';
          buttonLabel = 'Continue to PolicyCenter';
          buttonAction = 'pc';
        } else if (ProductHelper.shouldProposePcForError(input)) {
          displayMessage =
            input.displayMessage || input.remediation?.description || '';
          buttonLabel = 'Continue to PolicyCenter';
          buttonAction = 'pc';
        } else {
          displayMessage =
            input.displayMessage || input.remediation?.description || '';
          buttonLabel = 'Dismiss';
          buttonAction = 'dismiss';
        }
        outputs.push({
          ...input,
          displayMessage,
          buttonAction,
          remediation: {
            ...input.remediation,
            description: buttonLabel,
          },
        });
      }
    }
    return outputs;
  }

  private removeUnwantedErrorsForDisplay(
    displayErrors: ErrorDisplayModel[]
  ): ErrorDisplayModel[] {
    return displayErrors.filter(
      (error) =>
        !error.displayMessage.includes(ErrorSanitizerService.MSB_NOHIT_PHRASE)
    );
  }

  private removeUnwantedWarningsForDisplay(
    displayWarnings: WarningDisplayModel[]
  ): WarningDisplayModel[] {
    return displayWarnings.filter((warning) =>{
      return warning.message.includes('Member Characteristics information')&&
        this.position !== 'bottom'
  });
  }

  public productTypeRename(productType: ProductType): string {
    return ProductUtils.productBillingName(productType);
  }

  private scrollToMessage(): void {
    this.element.nativeElement?.scrollIntoView();
  }

  resolveTask(resolveAction: Action) {
    this.taskService.dispatchAction(resolveAction);
  }

  clickErrorButton(error: ErrorDisplayModel): void {
    switch (error.buttonAction) {
      case 'dismiss':
        if (error.id) {
          this.errorMessageService.deleteErrorMessage(error.id);
        }
        break;
      case 'pc':
        this.continueToPolicyCenter(error.productType);
        break;
    }
  }

  clickWarningButton(warning: WarningDisplayModel): void {
    switch (warning.buttonAction) {
      case 'dismiss':
        this.productsService.deleteWarningMessage(
          warning.message,
          warning.productType
        );
        break;
    }
  }

  dismissError(error: ErrorDisplayModel): void {
    if (error.id) {
      this.errorMessageService.deleteErrorMessage(error.id);
    }
  }

  continueToPolicyCenter(productType: ProductType | undefined) {
    if (productType) {
      this.store
        .select(getProduct(productType))
        .pipe(take(1))
        .subscribe((product) => {
          this.store.dispatch(
            linkToPolicyCenter({
              payload: {
                reason: 'underwriting rule',
                urlType: 'Job',
                id: product?.quoteId,
              },
            })
          );
        });
    }
  }

  private filterErrorsByPosition(
    errors: ErrorDisplayModel[],
    position: string
  ): ErrorDisplayModel[] {
    if (position === 'all') {
      return errors;
    }
    return errors.filter((error) => {
      const isTopError = [
        'Risk Filters',
        'System Error',
        'UW Rule - FULL Block',
      ].includes(error.category as string);

      if (position === 'top') {
        return isTopError;
      }
      return !isTopError;
    });
  }
}
