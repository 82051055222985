import { ProductType } from '@core/models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PolicyHolderRequest } from '../models/api/request/update-policyholder-request.model';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../services/app-config.service';
import { Store } from '@ngrx/store';
import { ProductsService } from '../services/products.service';
import { SessionService } from '../services/session.service';
import { LogService } from '../services/log.service';
import { PolicyHolderEntity, sanitizePolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import { Actions } from '@ngrx/effects';
import { LoadingService } from '@core/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyholderAdapter extends DsmAdapter {
  constructor(
    httpClient: HttpClient,
    appConfigService: AppConfigService,
    store: Store,
    productService: ProductsService,
    sessionService: SessionService,
    log: LogService,
    actions$: Actions,
    loadingService: LoadingService
  ) {
    super(
      httpClient,
      appConfigService,
      store,
      productService,
      sessionService,
      log,
      loadingService
    );
  }

  addPolicyHolder(
    request: PolicyHolderRequest
  ): Observable<PolicyHolderEntity> {
    return this.request(
      sanitizePolicyHolderEntity,
      request.productType as ProductType,
      'post',
      `/quotes/${request.quoteId}/policy-holders`,
      'add-policyholder',
      {
        body: {
          policyHolder: {
            ...request.policyHolder,
            policyHolderId: undefined,
            entityId: undefined as unknown,
          } as PolicyHolderEntity,
        },
      }
    );
  }

  updatePolicyHolder(
    request: PolicyHolderRequest
  ): Observable<PolicyHolderEntity> {
    return this.request(
      sanitizePolicyHolderEntity,
      request.productType as ProductType,
      'put',
      `/quotes/${request.quoteId}/policy-holders/${request.policyHolder.policyHolderId}`,
      'update-policyholder',
      {
        body: {
          policyHolder: {
            ...request.policyHolder,
            entityId: undefined as unknown,
          } as PolicyHolderEntity,
        },
      }
    );
  }

  deletePolicyholder(request: PolicyHolderRequest): Observable<unknown> {
    return this.request(
      responseUnused,
      request.productType as ProductType,
      'delete',
      `/quotes/${request.quoteId}/policy-holders/${request.policyHolder.policyHolderId}`,
      'delete-policyholder'
    );
  }
}
