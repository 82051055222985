<div class="bolt-container-fluid">
  <div class="bolt-row bolt-align-items-center">
    <div class="bolt-col-12">
      <p *ngIf="!isCA; else CAValueProp">
        SmartRide&reg; <strong>rewards safe driving.</strong> Save
        {{ initialDiscount }} for enrolling.<br />Then, the safer the customer
        drives, the higher the discount they can earn –
        <strong>up to {{ maximumDiscount }}.</strong>
      </p>
      <ng-template #CAValueProp>
        <p>
          Smartride&reg; <strong>rewards low-mileage drivers</strong>. Save 15%
          just for enrolling, then, the fewer miles the customer drives, the
          higher the discount they can earn - <strong>up to 15%</strong>
        </p>
      </ng-template>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt=""
          />
        </div>
        <p *ngIf="!isCA" class="info">
          Driving behavior measured by the vehicle.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              This car has built-in connected car technology that can capture
              driving behavior. The customer consents to sharing this data with
              Nationwide.
            </p>
          </bolt-contextual-help>
        </p>
        <p *ngIf="isCA" class="info">
          Mileage measured by the vehicle.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              This car has built-in connected car technology that can capture
              mileage. The customer consents to sharing this data with
              Nationwide.
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/dollar-lock-dk-blue.svg"
            alt=""
          />
        </div>
        <p class="info">
          Earn the final discount in about 6 months.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              <ng-container *ngIf="!isCA; else CAHelpContent">
                The vehicle will measure mileage, hard braking/fast
                acceleration, idle time and nighttime driving. Once enough
                driving data is collected (about 4 - 6 months), the final
                discount is calculated based on how safely they drive – up to
                40%. The discount is applied at policy renewal.
              </ng-container>
              <ng-template #CAHelpContent>
                The vehicle will measure mileage. Once enough driving data is
                collected (about 4 - 6 months), the final discount is calculated
                based on how many miles the customer drives - up to 15%. The
                discount is applied at policy renewal.
              </ng-template>
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div *ngIf="!isCA" class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video">
      <iframe
        id="kaltura_player_srcc_notca"
        src="https://fast.wistia.net/embed/iframe/msyxqpqkt1"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'ConnectedCar'"
      [enrollment]="enrollment"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
