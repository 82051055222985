import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  UnderlyingPolicyActions,
  VehicleExposureActions,
} from '@core/store/actions';
import { VehicleExposureEntity } from './vehicle-exposures.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';

export interface VehicleExposureState
  extends EntityState<VehicleExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<VehicleExposureEntity>;
}

export const adapter: EntityAdapter<VehicleExposureEntity> =
  createEntityAdapter<VehicleExposureEntity>({
    selectId: (vehicleExposure: VehicleExposureEntity) =>
      vehicleExposure.vehicleExposureId,
  });

const VehicleExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as VehicleExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as VehicleExposureState)
  ),
  on(SessionActions.nukeProduct, (state, { productType }) => {
    if (productType === 'PersonalUmbrella') {
      return adapter.getInitialState({
        error: null,
        deleted: {},
      } as VehicleExposureState);
    }
    const removeIds = Object.values(state.entities)
      .filter((entity) => entity?.productId === productType)
      .map((e) => e!.vehicleExposureId);
    return adapter.removeMany(removeIds, state);
  }),
  on(
    VehicleExposureActions.upsertVehicleExposureSuccess,
    (state, { payload }) => {
      const vehicleExposureDeleted = {
        ...state.deleted,
      };

      UmbrellaUtils.findAccompanyingExposureToRemove<VehicleExposureEntity>(
        vehicleExposureDeleted,
        payload
      );

      return adapter.upsertOne(payload, {
        ...state,
        deleted: vehicleExposureDeleted,
      });
    }
  ),
  on(VehicleExposureActions.deleteVehicleExposure, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(
    VehicleExposureActions.deleteVehicleExposureSuccess,
    (state, { vehicleExposureId }) =>
      adapter.removeOne(vehicleExposureId, {
        ...state,
        deleted: {
          ...state.deleted,
          [vehicleExposureId]: state.entities[vehicleExposureId],
        },
      })
  ),
  on(
    VehicleExposureActions.completelyRemoveVehicleExposure,
    (state, { vehicleExposureId }) => {
      const vehicleExposuresDeleted = {
        ...state.deleted,
      };
      delete vehicleExposuresDeleted?.[vehicleExposureId];
      return {
        ...state,
        deleted: vehicleExposuresDeleted,
      };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.vehicleExposures, {
      ...state,
    });
  }),
  on(
    UnderlyingPolicyActions.upsertUnderlyingPolicySuccess,
    (state, { payload, existingPolicyNumber }) => {
      if (
        existingPolicyNumber &&
        !!existingPolicyNumber.length &&
        existingPolicyNumber !== payload.underlyingPolicyNumber
      ) {
        const updatedExposures: VehicleExposureEntity[] = [];
        const exposuresToUpdate = Object.values(state.entities).filter(
          (exposure) =>
            exposure?.underlyingPolicyNumber === existingPolicyNumber
        );
        for (const exposure of exposuresToUpdate) {
          updatedExposures.push({
            ...exposure,
            underlyingPolicyNumber: payload.underlyingPolicyNumber,
          } as VehicleExposureEntity);
        }
        return adapter.upsertMany(updatedExposures, state);
      }
      return state;
    }
  )
);

export function reducer(
  state: VehicleExposureState | undefined,
  action: Action
): VehicleExposureState {
  return VehicleExposureReducer(state, action);
}
