import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { filter, map, tap, withLatestFrom } from 'rxjs';
import {
  CoveredLocationActions,
  EligibleDiscountsActions,
  PropertyFormActions,
  RetrieveActions,
} from '../actions';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { Injectable } from '@angular/core';
import { RenovationEntity } from '@core/interfaces/interfaces';
import { QuoteRetrieveHomeownerResponse } from '@core/models/api/response/retrieve-response.model';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { updateFortifiedHome } from '@entities/eligible-discounts/eligible-discounts.action';
import { setFortifiedHome } from './property-form.action';
import { FortifiedHomeCertificationLevel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';
import { isNssUser } from "@entities/user/user.selector";

@Injectable({
  providedIn: 'root',
})
export class PropertyFormEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private eligibleDiscountsService: EligibleDiscountsService
  ) {}

  /* I would have liked to make this determination by watching the Store,
   * but our store slice for coveredLocation renovations does not distinguish PC state from Form state.
   * So instead, we watch API call success actions.
   */
  makeRoofYearImmutableIfSetInPc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RetrieveActions.retrieveQuoteSuccess,
        CoveredLocationActions.getCoveredLocationSuccess,
        CoveredLocationActions.updateCoveredLocationSuccess
      ),
      withLatestFrom(this.store.select(isNssUser)),
      filter(([action, isNss]) => this.isRoofYearSet(action) && !isNss),
      map(() =>
        PropertyFormActions.setRoofYearImmutable({ roofYearImmutable: true })
      )
    )
  );

  updatePolicyLineOnRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.retrieveQuoteSuccess),
      filter((action) => this.isPropertyRetrieveQuoteSuccess(action)),
      map(() =>
        EligibleDiscountsActions.getPolicyLine({ productType: 'Homeowner' })
      )
    )
  );

  getFortifiedHomeOnRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EligibleDiscountsActions.getPolicyLineSuccess),
      filter((action) =>
        action.response.some((e) => e.eligibleDiscountId === 'FortifiedHome')
      ),
      map((action) => {
        const res = action.response.find(
          (e) => e.eligibleDiscountId === 'FortifiedHome'
        );
        return setFortifiedHome({
          payload: {
            certificationLevel:
              (res?.selectedOptionValue as FortifiedHomeCertificationLevel) ||
              null,
            certificationDate:
              res?.qualifyingInformation?.fortifiedDesignationDate,
          },
        });
      })
    )
  );

  private isRoofYearSet(action: Action): boolean {
    if (this.isPropertyRetrieveQuoteSuccess(action)) {
      return this.isRoofYearSetInRenovations(
        action.payload.response.coveredLocation?.renovations
      );
    }
    if (this.isGetCoveredLocationSuccess(action)) {
      return this.isRoofYearSetInRenovations(action.payload.renovations);
    }
    if (this.isUpdateCoveredLocationSuccess(action)) {
      return this.isRoofYearSetInRenovations(action.payload.renovations);
    }
    return false;
  }

  private isPropertyRetrieveQuoteSuccess(action: Action): action is {
    type: string;
    payload: { response: QuoteRetrieveHomeownerResponse };
  } {
    return (
      action.type === '[Retrieve] Retrieve Quotes Success' &&
      (action as any).payload?.productType === 'Homeowner'
    );
  }

  private isGetCoveredLocationSuccess(
    action: Action
  ): action is { type: string; payload: CoveredLocationEntity } {
    return action.type === '[Covered Location] Get covered location Success';
  }

  private isUpdateCoveredLocationSuccess(
    action: Action
  ): action is { type: string; payload: CoveredLocationEntity } {
    return action.type === '[Covered Location] Update covered location Success';
  }

  private isRoofYearSetInRenovations(
    renovations?: RenovationEntity[]
  ): boolean {
    return !!renovations?.find(
      (r) => r.type === 'Roof' && r.year && r.isEditable === false
    );
  }
}
