import { initiateNewBusinessSuccess } from './quote.action';
import {
  ProductType,
  QuoteStatus,
  RatingType,
  quoteStatusGreaterThan,
} from '@core/models/api/dsm-types';
import { QuoteService } from '@core/services/quote.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  debounceTime,
  delayWhen,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromActions from '@core/store/actions/index';
import * as quoteSelector from './quote.selector';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import { ProductModel } from '../product/product.model';
import { Update } from '@ngrx/entity';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { getSelectedProducts } from '../product/product.selectors';
import { UpdateQuoteRequest } from '@core/models/api/request/update-quote-request.model';
import { QuoteUtils } from '@shared/utils/quote.utils';
import { getTelematicsLoading } from '../telematics/telematics.selector';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { ProductHelper } from '@core/helper/product.helper';
import { getPrimaryNamedInsured } from '../member/member.selector';
import { MemberEntity } from '../member/member.reducer';
import {
  MemberSelectors,
  ProductsSelectors,
  QuoteSelectors,
} from '@core/store/selectors';
import { CoveragesService } from '@core/services/coverages.service';
import { ProductsService } from '@core/services/products.service';
import { getAllCoverages } from '@entities/coverage/coverage.action';
import { removeProduct } from '@entities/product/product.actions';
import { removeAccompanyingPolicyIfRateFails } from '@entities/underlying-policy/underlying-policy.action';
import { dismissReviewTask } from '@entities/task/task.action';

@Injectable({
  providedIn: 'root',
})
export class QuoteEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private quoteService: QuoteService,
    private errorSanitizerService: ErrorSanitizerService,
    private featureFlagService: FeatureFlagService,
    private coverageService: CoveragesService,
    private productsService: ProductsService
  ) {}

  initiateNewBusiness$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.QuoteActions.initiateNewBusiness),
        mergeMap((action) =>
          this.store
            .select(
              quoteSelector.buildInitiateNewBusinessRequest(
                action.payload,
                action.correlationId
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.quoteService.initiateNewBusiness(request).pipe(
            mergeMap((response) =>
              of(
                fromActions.QuoteActions.initiateNewBusinessSuccess({
                  payload: { ...response, productType: request.productType },
                  correlationId: request.correlationId,
                })
              )
            ),
            catchError((error) => {
              return of(
                fromActions.QuoteActions.initiateNewBusinessFail({
                  request,
                  payload: this.errorSanitizerService.sanitizeError(
                    error,
                    request.productType
                  ),
                  correlationId: request.correlationId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  initiateNewBusinessSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateNewBusinessSuccess),
      map((action) => action.payload),
      concatMap((response) => {
        const productType = response.productType;
        const nextActions: Action[] = [];

        nextActions.push(
          fromActions.ProductActions.updateProduct({
            payload: {
              id: productType,
              changes: ProductHelper.buildProductChanges(response, productType),
            },
          })
        );
        nextActions.push(
          fromActions.EligibleDiscountsActions.getPolicyLine({
            productType,
          })
        );
        nextActions.push(
          fromActions.AddressActions.upsertAddress({
            payload: {
              ...response.policyAddress,
              addressType: productType,
            },
          })
        );

        response.policyHolders.forEach((policyHolder) => {
          nextActions.push(
            fromActions.MemberActions.upsertMember({
              payload: {
                ...policyHolder,
                productType,
              } as MemberEntity,
            })
          );
        });

        if (productType === 'Homeowner') {
          nextActions.push(
            fromActions.ProductActions.upsertYearsWithPriorCarrier({
              payload: productType,
              yearsWithPriorCarrier: response.yearsWithPriorCarrier || '0',
            })
          );
        }

        if (response.householdMembers?.length) {
          response.householdMembers.forEach((member) => {
            nextActions.push(
              fromActions.MemberActions.upsertMember({
                payload: { ...member, productType } as MemberEntity,
              })
            );
          });
        }

        nextActions.push(
          fromActions.QuoteActions.initiateNewBusinessSuccessAfterFirstReduction(
            {
              productType,
            }
          )
        );

        return from(nextActions);
      })
    )
  );

  initiateNewBusinessFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.initiateNewBusinessFail),
      mergeMap((action) => {
        const request = action.request;
        const productId = action.request.productType;
        const productChanges: ProductModel = {
          type: request.productType,
          initiated: false,
          hasError: true,
          quoteLoading: false,
          // TODO - this maybe needs to be an error state
          quoteStatus: 'Draft',
        };

        const productUpdate: Update<ProductModel> = {
          id: productId,
          changes: productChanges,
        };

        const nextActions: Action[] = [
          fromActions.ProductActions.updateProduct({ payload: productUpdate }),
        ];
        return from(nextActions);
      })
    )
  );

  patchQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ProductActions.patchProductQuote),
      map((action) => ({
        payload: action.payload,
        options: action.options,
        correlationId: action.correlationId,
      })),
      mergeMap((request) =>
        this.store
          .select(
            quoteSelector.buildPatchQuoteRequest(
              request.payload,
              request.options,
              request.correlationId
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) => this.updateQuote(request))
    )
  );

  updateQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.QuoteActions.updateQuote),
      filter((action) => action.productType !== undefined),
      mergeMap((action) =>
        this.store
          .select(
            quoteSelector.buildUpdateQuoteRequest(
              action.productType as ProductType,
              action.override,
              action.correlationId
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) => this.updateQuote(request))
    );
  });

  rateQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.rateQuote),
      delayWhen((action) => this.okToProceedWithRating(action)),
      mergeMap((action) =>
        this.store
          .select(
            quoteSelector.buildRateQuoteRequest(
              action.productType,
              action.ratingType
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.quoteService.rateQuote(request).pipe(
          withLatestFrom(this.featureFlagService.getAllFeatureFlags()),
          mergeMap(([response, featureFlags]) =>
            from(QuoteUtils.rateQuoteResponse(request, response, featureFlags))
          ),
          catchError((error) => {
            return of(
              fromActions.QuoteActions.rateQuoteFail({
                productType: request.productType,
                ratingType: request.ratingType,
                error: this.errorSanitizerService.sanitizeError(
                  error,
                  request.productType
                ),
              })
            );
          })
        )
      )
    )
  );

  rateSelectedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.rateQuoteSelectedProducts),
      map((action) => action.ratingType),
      withLatestFrom(this.store.select(getSelectedProducts)),
      mergeMap(([ratingType, products]) =>
        from(
          products.map((product) =>
            fromActions.QuoteActions.rateQuote({
              productType: product.type,
              ratingType,
            })
          )
        )
      )
    )
  );

  quoteCallsCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.QuoteActions.rateQuoteSuccess,
        fromActions.QuoteActions.rateBindSuccess,
        fromActions.QuoteActions.rateQuoteFail
      ),
      switchMap(() =>
        this.store.select(quoteSelector.getQuoteInProgressCount).pipe(
          take(1),
          filter((count) => count === 0),
          map(() => fromActions.QuoteActions.quoteCallsCompleted())
        )
      )
    )
  );

  private okToProceedWithRating(
    action: Action & { productType: ProductType; ratingType: RatingType }
  ): Observable<boolean> {
    return this.store.select(getTelematicsLoading).pipe(
      filter((loading) => !loading),
      take(1),
      switchMap(() => {
        /* if (action.productType === 'PersonalUmbrella') {
          return this.umbrellaService.syncUmbrellaToCurrentState();
        } */
        return of(true);
      })
    );
  }

  updateQuote(request: UpdateQuoteRequest): Observable<Action> {
    return this.quoteService.updateQuote(request).pipe(
      withLatestFrom(this.featureFlagService.getAllFeatureFlags()),
      concatMap(([response, featureFlags]) => {
        const updatedAddress: AddressEntity = {
          ...response.policyAddress,
          addressType: request.productType,
          street: PolicyholderBuilder.singleLineAddressFromResponse(
            response.policyAddress
          ),
        };

        const updatedProduct: Update<ProductModel> = {
          id: request.productType || '',
          changes: {
            effectiveDate: response.effectiveDate,
            offeringType: response.offeringType,
            hasDrivingDataConsent:
              typeof response.hasDrivingDataConsent === 'boolean'
                ? response.hasDrivingDataConsent
                : null,
            nationwideAccountRegistrationInfo:
              response.nationwideAccountRegistrationInfo,
          },
        };
        if (response.termType) {
          updatedProduct.changes.termType = response.termType;
        }
        if (response.currentBillTo) {
          updatedProduct.changes.currentBillTo = response.currentBillTo;
        }
        if (response.autoCharacteristics) {
          updatedProduct.changes.autoCharacteristics =
            response.autoCharacteristics;
        }
        if (response.docDelPreference) {
          updatedProduct.changes.docDelPreference = response.docDelPreference;
        }

        const nextActions: Action[] = [
          fromActions.AddressActions.upsertAddress({
            payload: updatedAddress,
          }),
          fromActions.ProductActions.updateProduct({
            payload: updatedProduct,
          }),
        ];

        if (
          typeof response.isAssignedRiskPlan === 'boolean' &&
          featureFlags.assignedRiskPlan
        ) {
          nextActions.push(
            fromActions.ProductActions.updateProduct({
              payload: {
                id: 'PersonalAuto',
                changes: { isAssignedRiskPlan: response.isAssignedRiskPlan },
              },
            })
          );
        }

        if (response.yearsWithPriorCarrier) {
          nextActions.push(
            fromActions.ProductActions.upsertYearsWithPriorCarrier({
              payload: request.productType,
              yearsWithPriorCarrier: response.yearsWithPriorCarrier,
            })
          );
        }

        nextActions.push(
          fromActions.QuoteActions.updateQuoteSuccess({
            payload: request.productType,
            effectiveDate: response.effectiveDate,
            correlationId: request.correlationId,
            response,
          })
        );

        if (request.productType === 'Homeowner') {
          nextActions.push(
            fromActions.CoveredLocationActions.getCoveredLocation({
              payload: request.productType,
            })
          );
        }

        nextActions.push(
          fromActions.ProductActions.updateProductStatus({
            payload: request.productType,
            status: request.quoteStatus || 'Draft',
          })
        );

        if (
          request.previousEffectiveDate &&
          request.previousEffectiveDate !== response.effectiveDate
        ) {
          nextActions.push(
            fromActions.EligibleDiscountsActions.getPolicyLine({
              productType: request.productType,
            })
          );
        }

        return nextActions;
      }),
      catchError((error) => {
        return of(
          fromActions.QuoteActions.updateQuoteFail({
            payload: request.productType,
            error: this.errorSanitizerService.sanitizeError(
              error,
              request.productType
            ),
            correlationId: request.correlationId,
          })
        );
      })
    );
  }

  validateCoverageMinimumsForUmbrella$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getAllCoverages),
        switchMap(() => {
          return combineLatest([
            this.coverageService.getAllQuoteCoveragesByVersion(),
            this.coverageService.isQuoteCoverageLoading(),
          ]);
        }),
        filter(([_, isLoading]) => {
          return !isLoading;
        }),
        take(1),
        switchMap(() => this.productsService.getSelectedProductTypes()),
        tap((products) => {
          if (products.includes('PersonalUmbrella')) {
            this.coverageService.updateUmbrellaCoverages();
          }
        })
      ),
    { dispatch: false }
  );

  removeUmbrellaOnAutoOrPropertyFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.rateQuoteFail),
      withLatestFrom(this.store.select(getSelectedProducts)),
      mergeMap(([action, selectedProducts]) => {
        if (
          selectedProducts.some(
            (p) => p.type === 'PersonalUmbrella' && p.isDsmActive
          ) &&
          (action.productType === 'PersonalAuto' ||
            action.productType === 'Homeowner' ||
            action.productType === 'Condominium' ||
            action.productType === 'Tenant')
        ) {
          return from([
            removeProduct({ payload: 'PersonalUmbrella' }),
            removeAccompanyingPolicyIfRateFails({
              productType: action.productType,
            }),
            dismissReviewTask({
              payload: {
                field: 'vehicleExposureMissing',
                productType: 'PersonalUmbrella',
              },
            }),
          ]);
        }
        return EMPTY;
      })
    )
  );
}
