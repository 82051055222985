import { UwIssuesResponse } from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import * as fromCore from '@core/store/reducers';
import { ProductsSelectors } from '@core/store/selectors';
import { createSelector } from '@ngrx/store';

export type UwIssuesByProduct = {
  [K in ProductType]?: UwIssuesResponse[];
};

export const getUwIssuesState = createSelector(
  fromCore.getCoreState,
  (core) => core.uwIssues
);

export const uwIssuesExistForAnySelectedProduct = createSelector(
  getUwIssuesState,
  ProductsSelectors.getSelectedProductTypes,
  (state, products) => {
    for (const key of state.ids as string[]) {
      const record = state.entities[key];
      if (record?.issues.find((issue) => issue.status !== 'Approved')) {
        const productType = (key.split('_')[0] || '') as ProductType;
        if (products.includes(productType)) return true;
      }
    }
    return false;
  }
);

export const getUwIssuesByProduct = createSelector(
  getUwIssuesState,
  (state) => {
    const byProduct: {
      [K in ProductType]?: UwIssuesResponse[];
    } = {};
    for (const id of state.ids as string[]) {
      const productType = id.split('_')[0] as ProductType;
      if (productType) {
        const record = state.entities[id];
        if (record) {
          byProduct[productType] = record.issues;
        }
      }
    }
    return byProduct;
  }
);

export const getActionableUwIssuesByProduct = createSelector(
  getUwIssuesByProduct,
  ProductsSelectors.getSelectedProductTypes,
  (issuesByProduct, selectedProducts) => {
    for (const productType of Object.keys(issuesByProduct) as ProductType[]) {
      if (!selectedProducts.includes(productType)) {
        delete issuesByProduct[productType];
        continue;
      }
      const filtered = issuesByProduct[productType]!.filter(
        (issue) => issue.status !== 'Approved'
      );
      if (filtered.length) {
        issuesByProduct[productType] = filtered;
      } else {
        delete issuesByProduct[productType];
      }
    }
    return issuesByProduct;
  }
);

export const getUwIssuesForProductType = (productType: ProductType) =>
  createSelector(getUwIssuesState, (state) => {
    const prefix = productType + '_';
    const key =
      (state.ids as string[]).find((id) => id.startsWith(prefix)) || '';
    return state.entities[key]?.issues || [];
  });

export const getActionableUwIssuesForProductType = (productType: ProductType) =>
  createSelector(getUwIssuesForProductType(productType), (issues) =>
    issues.filter((issue) => issue.status !== 'Approved')
  );

export const getUwIssuesForQuoteId = (quoteId: string) =>
  createSelector(getUwIssuesState, (state) => {
    const suffix = '_' + quoteId;
    const key = (state.ids as string[]).find((id) => id.endsWith(suffix)) || '';
    return state.entities[key]?.issues || [];
  });

export const getUwIssuesEnabled = (productType: ProductType) =>
  createSelector(
    getUwIssuesState,
    (state) => !state.disabledProducts.includes(productType)
  );

export const getDisabledUwProducts = createSelector(
  getUwIssuesState,
  (state) => state.disabledProducts
);
