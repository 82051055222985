import { UwIssuesResponse } from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { SessionActions, UwIssuesActions } from '@core/store/actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export interface UwIssuesStoreRecord {
  issues: UwIssuesResponse[];
  productType: ProductType;
  quoteId: string;
}

export interface UwIssuesState extends EntityState<UwIssuesStoreRecord> {
  // disabledProducts for ones the user has already requested approval, during this session.
  disabledProducts: ProductType[];
}

export const adapter: EntityAdapter<UwIssuesStoreRecord> =
  createEntityAdapter<UwIssuesStoreRecord>({
    selectId: (r) => r.productType + '_' + r.quoteId,
  });

const initialState: UwIssuesState = {
  ...adapter.getInitialState(),
  disabledProducts: [],
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(UwIssuesActions.clearUwIssuesForProduct, (state, action) => {
    const ids = (state.ids as string[]).filter((id) =>
      id.startsWith(action.productType + '_')
    );
    return adapter.removeMany(ids, state);
  }),
  on(UwIssuesActions.setUwIssues, (state, action) => {
    if (action.record?.issues?.length) {
      return adapter.setOne(action.record, state);
    } else if (action.record) {
      return adapter.removeOne(
        action.record.productType + '_' + action.record.quoteId,
        state
      );
    } else {
      return state;
    }
  }),
  on(UwIssuesActions.disableUwIssuesProduct, (state, action) => {
    if (
      !action.productType ||
      state.disabledProducts.includes(action.productType)
    ) {
      return state;
    }
    return {
      ...state,
      disabledProducts: [...state.disabledProducts, action.productType],
    };
  })
);
