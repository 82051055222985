import { Injectable } from '@angular/core';
import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import {
  sanitizeUwActivities,
  sanitizeUwActivity,
  UwActivitiesStoreModel,
  UwActivity,
} from './uw-activities.reducer';
import { UwActivitiesActions } from '@core/store/actions';
import { UwActivitiesSelectors } from '@core/store/selectors';

export interface UwActivitiesRequest {
  productType: ProductType;
  quoteId: string;
  activity: {
    subject: string;
    newNote: {
      topic: string;
      body: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class UwActivitiesService {
  constructor(private dsmAdapter: DsmAdapter, private store: Store) {}

  /* Adds to store and returns the response.
   */
  submitNewActivity(request: UwActivitiesRequest): Observable<UwActivity> {
    return this.dsmAdapter
      .request(
        sanitizeUwActivity,
        request.productType,
        'POST',
        `/quotes/${request.quoteId}/activities`,
        'add-uw-activity',
        {
          body: {
            activity: request.activity,
          },
        }
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(
            UwActivitiesActions.storeActivity({
              productType: request.productType,
              quoteId: request.quoteId,
              activity: response,
            })
          );
        })
      );
  }

  /* No store interaction, HTTP call only.
   */
  fetchActivities(
    productType: ProductType,
    quoteId: string
  ): Observable<UwActivity[]> {
    return this.dsmAdapter.request(
      sanitizeUwActivities,
      productType,
      'GET',
      `/quotes/${quoteId}/activities`,
      'get-uw-activities'
    );
  }

  storeActivities(
    productType: ProductType,
    quoteId: string,
    activities: UwActivity[]
  ): void {
    this.store.dispatch(
      UwActivitiesActions.storeActivities({
        productType,
        quoteId,
        activities,
      })
    );
  }

  getUwActivitiesForDisplay(): Observable<UwActivitiesStoreModel[]> {
    return this.store.select(UwActivitiesSelectors.getUwActivitiesForDisplay);
  }
}
