import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductType } from '@core/models/api/dsm-types';
import {
  CoverageChangeRequest,
  CoverageChangeResponse,
  CoveragesRequest,
  sanitizeCoverageChangeResponse,
} from '../models/api/request/coverages-request.model';
import { InitiateNewBusinessRequest } from '../models/api/request/initiate-new-business-request.model';
import {
  QuoteRequest,
  UpdateQuoteRequest,
} from '../models/api/request/update-quote-request.model';
import {
  InitiateNewBusinessResponse,
  sanitizeInitiateResponse,
} from '../models/api/response/initiate-new-business-response.model';
import {
  UpdateQuoteResponse,
  sanitizeUpdateQuoteResponse,
} from '../models/api/response/update-quote-response.model';
import {
  CoverageEntity,
  sanitizeCoveragesResponses,
} from '../store/entities/coverage/coverage.entity';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from '../services/app-config.service';
import {
  UpdateScheduledCategoryRequest,
  UpdateScheduledCategoryResponse,
  UpdateScheduledPersonalEffectRequest,
  sanitizeUpdateScheduledCategoryResponse,
  sanitizeUpdateScheduledPersonalEffectsResponse,
} from '@core/models/api/request/scheduled-categories.request.model';
import {
  ScheduledCategoryEntity,
  sanitizeScheduledCategoryEntities,
} from '@core/store/entities/scheduled-categories/scheduled-category.entity';
import { BaseRequest } from '@core/models/api/request/base-request.model';
import { ProductUtils } from '@shared/utils/product.util';

@Injectable({
  providedIn: 'root',
})
export class QuoteAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appConfigService: AppConfigService
  ) {}

  initiateNewBusiness(
    request: InitiateNewBusinessRequest
  ): Observable<InitiateNewBusinessResponse> {
    return this.adapter.request<InitiateNewBusinessResponse>(
      sanitizeInitiateResponse,
      request.productType,
      'POST',
      '/quotes',
      'initiate',
      {
        body: request,
        extraLocks: ['initiate'],
        spinnerServiceName: `initiate-${request.productType}`,
        spinnerMessage: `Initiating ${ProductUtils.getShortNameForProduct(
          request.productType
        )}...`,
      }
    );
  }

  rateQuote(request: QuoteRequest): Observable<UpdateQuoteResponse> {
    let headers = new HttpHeaders();
    if (!this.appConfigService.isProd()) {
      if (
        this.appConfigService.config.ignoreRiskFilter &&
        !['PersonalUmbrella', 'MSA'].includes(request.productType)
      ) {
        headers = headers.set('Ignore-Risk-Filter', 'true');
      }
      if (this.appConfigService.config.autoApproveUW) {
        headers = headers.set('Auto-Approve-Rules', 'true');
      }
    }
    return this.adapter
      .request<UpdateQuoteResponse>(
        sanitizeUpdateQuoteResponse,
        request.productType,
        'PATCH',
        `/quotes/${request.quoteId}?rate=${request.ratingType}`,
        request.ratingType,
        {
          body: {},
          headers,
        }
      )
      .pipe(
        map((response) => {
          response.productType = request.productType;
          response.ratingType = request.ratingType;
          return response;
        })
      );
  }

  updateQuote(request: UpdateQuoteRequest): Observable<UpdateQuoteResponse> {
    if (!request.quoteId) {
      return throwError(() => `attempted to update Quote with no quoteId`);
    }
    let headers = new HttpHeaders();
    if (!this.appConfigService.isProd()) {
      if (
        this.appConfigService.config.ignoreRiskFilter &&
        !['PersonalUmbrella', 'MSA'].includes(request.productType)
      ) {
        headers.set('Ignore-Risk-Filter', 'true');
      }
      if (this.appConfigService.config.autoApproveUW) {
        headers.set('Auto-Approve-Rules', 'true');
      }
    }
    return this.adapter.request<UpdateQuoteResponse>(
      sanitizeUpdateQuoteResponse,
      request.productType,
      'PATCH',
      `/quotes/${request.quoteId}`,
      'quote',
      {
        body: request.body,
        headers: headers,
      }
    );
  }

  retrieveAllCoverages(
    request: CoveragesRequest
  ): Observable<CoverageEntity[]> {
    let options;
    if (request.productModelSync) {
      options = {
        params: new HttpParams({
          fromObject: {
            refreshCoverages: 'true',
          },
        }),
      };
    }
    return this.adapter
      .request(
        sanitizeCoveragesResponses,
        request.productType as ProductType,
        'GET',
        `/quotes/${request.quoteId}/coverages`,
        'get-all-coverages',
        options
      )
      .pipe(
        map((responseArr) => {
          let coverages: CoverageEntity[] = [];
          responseArr.forEach((response) => {
            coverages = coverages.concat(
              response.coverages.map((cvg) => ({
                ...cvg,
                productId: request.productType as ProductType,
                version: response.packageName,
              }))
            );
          });
          return coverages;
        })
      );
  }

  updateCoverages(
    request: CoverageChangeRequest
  ): Observable<CoverageChangeResponse> {
    return this.adapter
      .request(
        sanitizeCoverageChangeResponse,
        request.productType as ProductType,
        'PATCH',
        `/quotes/${request.quoteId}/coverages`,
        'update-coverage',
        {
          body: { coverages: request.coverages },
        }
      )
      .pipe(
        map((response) => ({
          coverages: response.coverages.map((cvg) => ({
            ...cvg,
            productId: request.productType as ProductType,
          })),
          messages: response.messages,
        }))
      );
  }
  getScheduledCategories(
    request: BaseRequest
  ): Observable<ScheduledCategoryEntity[]> {
    return this.adapter
      .request(
        sanitizeScheduledCategoryEntities,
        request.productType as ProductType,
        'GET',
        `/quotes/${request.quoteId}/scheduled-categories`,
        'get-scheduled-categories'
      )
      .pipe(
        map((response) => {
          let scheduledCategories: ScheduledCategoryEntity[] = [];
          response.forEach((category) => {
            scheduledCategories = scheduledCategories.concat([
              {
                ...category,
                productType: request.productType,
              },
            ]);
          });
          return scheduledCategories;
        })
      );
  }

  updateScheduledCategory(
    request: UpdateScheduledCategoryRequest
  ): Observable<ScheduledCategoryEntity[]> {
    return this.adapter
      .request(
        sanitizeUpdateScheduledCategoryResponse,
        request.productType as ProductType,
        'PATCH',
        `/quotes/${request.quoteId}/scheduled-categories`,
        'update-scheduled-category',
        // TODO: Format this somewhere else
        {
          body: { scheduledCategories: [request.item] },
        }
      )
      .pipe(
        map((response: UpdateScheduledCategoryResponse) => {
          let scheduledCategories: ScheduledCategoryEntity[] = [];
          response.scheduledCategories.forEach((category) => {
            scheduledCategories = scheduledCategories.concat([
              {
                ...category,
                productType: request.productType,
              },
            ]);
          });
          return scheduledCategories;
        })
      );
  }

  getScheduledPersonalEffects(
    request: BaseRequest
  ): Observable<ScheduledCategoryEntity[]> {
    return this.adapter
      .request(
        sanitizeScheduledCategoryEntities,
        request.productType as ProductType,
        'GET',
        `/quotes/${request.quoteId}/scheduled-personal-effects`,
        'get-scheduled-personal-effects'
      )
      .pipe(
        map((response) => {
          let scheduledPersonalEffects: ScheduledCategoryEntity[] = [];
          response.forEach((personalEffect) => {
            scheduledPersonalEffects = scheduledPersonalEffects.concat([
              {
                ...personalEffect,
                productType: request.productType,
              },
            ]);
          });
          return scheduledPersonalEffects;
        })
      );
  }

  updateScheduledPersonalEffect(
    request: UpdateScheduledPersonalEffectRequest
  ): Observable<ScheduledCategoryEntity[]> {
    return this.adapter
      .request(
        sanitizeUpdateScheduledPersonalEffectsResponse,
        request.productType as ProductType,
        'PATCH',
        `/quotes/${request.quoteId}/scheduled-personal-effects`,
        'update-scheduled-personal-effect',
        {
          body: {
            scheduledPersonalEffects: [request.scheduledPersonalEffect],
          },
        }
      )
      .pipe(
        map((response) => {
          let scheduledPersonalEffects: ScheduledCategoryEntity[] = [];
          response.scheduledPersonalEffects.forEach((personalEffect) => {
            scheduledPersonalEffects = scheduledPersonalEffects.concat([
              {
                ...personalEffect,
                productType: request.productType,
              } as ScheduledCategoryEntity,
            ]);
          });
          return scheduledPersonalEffects;
        })
      );
  }

  deleteQuote(productType: ProductType, quoteId: string): Observable<unknown> {
    /* WILDLINGS-14970
     * They forgot to implement deleteQuote for MSA, Boat, and RV.
     * But lucky us, we can just use one of the proxies where it does exist, and it just works.
     * Please remove this once DSM fixes that defect.
     */
    const originalProductType = productType;
    if (
      productType === 'MSA' ||
      productType === 'Boat' ||
      productType === 'RV'
    ) {
      productType = 'PersonalAuto';
    }

    return this.adapter.request(
      responseUnused,
      productType,
      'DELETE',
      `/quotes/${quoteId}`,
      'delete-quote',
      { originalProductType }
    );
  }
}
