import { PipeTransform, Pipe } from '@angular/core';
import { DocumentsEntity } from '@core/store/entities/document/document.entity';

@Pipe({
  name: 'excludeUmbrellaPipe',
})
export class SummaryPolicyDocumentsPipe implements PipeTransform {
  transform(val: DocumentsEntity[]): DocumentsEntity[] {
    return val.filter((doc) => doc.productType !== 'PersonalUmbrella');
  }
}
