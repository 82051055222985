import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/services/app-config.service';
import { retryDsmErrors } from '@shared/rxjs/retry-dsm-errors.operator';
import { withLock } from '@shared/rxjs/with-lock.operator';
import { Store } from '@ngrx/store';
import { combineLatest, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getApplicationName,
  getTargetEnv,
} from '../store/entities/dsm/dsm.selector';
import { OneTypePipe } from '@shared/rxjs/one-type-pipe';
import { ProductModel } from '../store/entities/product/product.model';
import { ProductsService } from '../services/products.service';
import { Nullable } from '@shared/utils/type.utils';
import { LogApiName, LogHttpMethod } from '../models/api/log.model';
import { LogService } from '../services/log.service';
import { SessionService } from '../services/session.service';
import { ProductType, RequestType, QuoteStatus } from '../models/api/dsm-types';
import { StringUtils } from '@shared/utils/string.utils';
import { ProductsSelectors, SessionSelectors } from '@core/store/selectors';
import { DsmActions } from '@core/store/actions';
import { LoadingService } from '@core/services/loading.service';

const spinnerServiceNameToken = new HttpContextToken<string>(() => '');
const spinnerMessageToken = new HttpContextToken<string>(() => '');
const loadingServiceToken = new HttpContextToken<LoadingService | null>(
  () => null
);
const endLoadingToken = new HttpContextToken<() => void>(() => () => {});

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    handler: HttpHandler
  ): Observable<HttpEvent<any>> {
    const spinnerServiceName = req.context.get(spinnerServiceNameToken);
    const loadingService = req.context.get(loadingServiceToken);
    if (spinnerServiceName && loadingService) {
      const spinnerMessage = req.context.get(spinnerMessageToken);
      req.context.set(
        endLoadingToken,
        loadingService.beginLoading(spinnerServiceName, spinnerMessage)
      );
    }
    return handler.handle(req);
  }
}

/**
 * This is declared by Angular, but not as a named type.
 */
export interface HttpOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: 'response' | 'events' | 'body';
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
  withCredentials?: boolean;
  productType?: RequestType;
  context?: HttpContext;

  // Things not part of Angular:
  baseUrl?: string;
  extraLocks?: string[];
  neverRetry?: boolean;
  noLocks?: boolean;
  spinnerServiceName?: string;
  spinnerMessage?: string;
  originalProductType?: ProductType;
}

export type HttpResponseSanitizer<T> = (response: unknown) => T;

/** HttpResponseSanitizer for eg DELETEs, where the response will be discarded.
 */
export function responseUnused(response: unknown): unknown {
  return response;
}

@Injectable({
  providedIn: 'root',
})
export class DsmAdapter {
  constructor(
    protected httpClient: HttpClient,
    public appConfigService: AppConfigService,
    protected store: Store,
    private productService: ProductsService,
    private sessionService: SessionService,
    protected log: LogService,
    private loadingService: LoadingService
  ) {}

  request<T>(
    sanitize: HttpResponseSanitizer<T>,
    requestType: RequestType,
    method: string,
    path: string,
    serviceName: LogApiName,
    options?: HttpOptions
  ): Observable<T> {
    const callName = `${requestType}-${serviceName}`;
    this.store.dispatch(DsmActions.addCallInFlight({ name: callName }));
    return this.validateRequest(requestType, method, path, options).pipe(
      switchMap(() =>
        this.requestValidated(requestType, method, path, serviceName, options)
      ),
      map((response) => sanitize(response)),
      finalize(() =>
        this.store.dispatch(DsmActions.removeCallInFlight({ name: callName }))
      )
    );
  }

  cancelRequest<T>(serviceName: LogApiName) {
    this.store.dispatch(DsmActions.removeCallInFlight({ name: serviceName }));
  }

  private validateRequest(
    requestType: RequestType,
    method: string,
    path: string,
    options?: HttpOptions
  ): Observable<null> {
    switch (requestType) {
      case 'AgencyCodeSearch':
        return of(null);
      case 'ProducerCodeSearch':
        return of(null);
      case 'Account':
        return of(null);
      case 'MortgageCompanies':
        return of(null);
      default:
        return this.productService
          .getProductStatus(options?.originalProductType || requestType)
          .pipe(
            take(1),
            map((status) => {
              if (status === 'Withdrawn' && method !== 'GET') {
                if (method === 'POST' && path === '/quotes') {
                  // initiate is ok
                  return null;
                }
                throw new Error(`${requestType} quote has been withdrawn`);
              } else {
                return null;
              }
            })
          );
    }
  }

  private requestValidated(
    requestType: RequestType,
    method: string,
    path: string,
    serviceName: LogApiName,
    options?: HttpOptions
  ): Observable<unknown> {
    const url = this.composeUrl(requestType, path, options);
    options = {
      ...options,
      observe: 'response',
      responseType: 'json',
      withCredentials:
        options?.withCredentials === undefined ? true : options.withCredentials,
      context: new HttpContext()
        .set(spinnerServiceNameToken, options?.spinnerServiceName || '')
        .set(spinnerMessageToken, options?.spinnerMessage || '')
        .set(loadingServiceToken, this.loadingService),
    };
    const request = requestType === null ? '' : requestType;
    const headerAdder = options.withCredentials
      ? this.addAuthenticatedHeaders(requestType, options)
      : this.addUnauthenticatedHeaders(requestType, options);
    return headerAdder.pipe(
      switchMap((fullOptions) => {
        const correlationId = this.log.logApiRequest(
          method as LogHttpMethod,
          url,
          serviceName,
          {
            ...fullOptions.body,
            productId: requestType,
          }
        );

        const httpCall = this.httpClient.request(
          method,
          url,
          fullOptions
        ) as OneTypePipe<HttpResponse<unknown>>;
        return httpCall.pipe(
          // Order matters! Retry, then lock. We hold the lock across retries.
          options?.neverRetry
            ? tap(() => {})
            : retryDsmErrors(this.log, serviceName),
          ...(options?.noLocks
            ? [tap(() => {}) as MonoTypeOperatorFunction<HttpResponse<unknown>>]
            : [request, ...(options?.extraLocks || [])].map((name) =>
                withLock<HttpResponse<unknown>>(name)
              )),
          tap((response: HttpResponse<unknown>) => {
            this.log.logApiResponse(correlationId, response.status, {
              ...(response.body as unknown as object),
              productId: requestType,
            });
            this.updateQuoteStatus(
              response.headers,
              requestType as ProductType
            );
            options.context?.get(endLoadingToken)();
          }),
          map(
            (response) =>
              (response.body || '') as unknown as HttpResponse<unknown>
          ),
          catchError((error) => {
            options.context?.get(endLoadingToken)();
            if (error && typeof error === 'object') {
              error = { ...error, productId: requestType };
            } else {
              error = { error, productId: requestType };
            }
            return this.log.logApiError(correlationId, error);
          })
        );
      })
    );
  }

  composeUrl(
    requestType: RequestType,
    path: string,
    options?: HttpOptions
  ): string {
    if (options?.baseUrl) {
      return `${options.baseUrl}${path}`;
    }
    switch (requestType) {
      case 'PersonalAuto':
        return `${this.appConfigService.config.pcEdgeAutoUrl}${path}`;
      case 'Condominium':
        return `${this.appConfigService.config.pcEdgeCondoUrl}${path}`;
      case 'Homeowner':
        return `${this.appConfigService.config.pcEdgeHomeownersUrl}${path}`;
      case 'Tenant':
        return `${this.appConfigService.config.pcEdgeRentersUrl}${path}`;
      case 'PersonalUmbrella':
        return `${this.appConfigService.config.pcEdgeUmbrellaUrl}${path}`;
      case 'MSA':
        return `${this.appConfigService.config.pcEdgePowerSportsUrl}${path}`;
      case 'DwellingFire':
        return 'Not Enabled';
      case 'Boat':
        return `${this.appConfigService.config.pcEdgeBoatUrl}${path}`;
      case 'RV':
        return `${this.appConfigService.config.pcEdgeRVUrl}${path}`;
      case 'Account':
        return this.appConfigService.config.quoteAccountManagement + path;
      case 'AgencyCodeSearch':
        return (
          this.appConfigService.config.pcEdgeAgentAccountManagementUrl + path
        );
      case 'MortgageCompanies':
        return this.appConfigService.config.mortgageCompaniesUrl + path;
    }
    throw new Error(`Invalid request type '${requestType}'`);
  }

  addUnauthenticatedHeaders(
    requestType: RequestType,
    options?: HttpOptions
  ): Observable<HttpOptions> {
    return this.addCommonHeaders(requestType, options);
  }

  addAuthenticatedHeaders(
    requestType: RequestType,
    options?: HttpOptions
  ): Observable<HttpOptions> {
    return this.addCommonHeaders(requestType, options).pipe(
      withLatestFrom(
        this.productService.getProduct(requestType as ProductType),
        this.sessionService.getAccessToken()
      ),
      map(
        ([preliminaryOptions, product, accessToken]: [
          HttpOptions,
          Nullable<ProductModel>,
          Nullable<string>
        ]) => {
          if (!preliminaryOptions.headers || !accessToken) {
            throw new Error(`access token not found '${requestType}'`);
          }

          let headers = (preliminaryOptions.headers as HttpHeaders).set(
            'Authorization',
            `Bearer ${accessToken}`
          );

          if (product?.sessionId) {
            headers = headers.set('session-id', product.sessionId);
          }

          return {
            ...preliminaryOptions,
            withCredentials: false,
            headers,
          };
        }
      )
    );
  }

  updateQuoteStatus(
    headers: HttpHeaders,
    productType: ProductType | null
  ): void {
    if (productType) {
      const quoteStatus = headers.get('Quote-Status') || '';
      // Important that we not take "Pending". Others, less important, but let's only take known statuses.
      if (
        [
          'Draft',
          'Quoting',
          'Quoted',
          'Binding',
          'Issued',
          'Withdrawn',
          'Expired',
        ].includes(quoteStatus)
      ) {
        this.productService.updateProductStatus(
          productType,
          quoteStatus as QuoteStatus
        );
      }
    }
  }

  private addCommonHeaders(
    requestType: RequestType | null,
    options?: HttpOptions
  ): Observable<HttpOptions> {
    if (!options) {
      options = {};
    }
    let headers: HttpHeaders;
    if (options.headers) {
      if (options.headers instanceof HttpHeaders) {
        headers = options.headers;
      } else {
        headers = new HttpHeaders(options.headers);
      }
    } else {
      headers = new HttpHeaders();
    }
    options.headers = headers;

    headers = headers.set('client_id', this.appConfigService.config.apiKey);
    headers = headers.set('content-type', 'application/json');

    return combineLatest([
      this.store.select(getTargetEnv),
      this.store.select(getApplicationName),
      this.store.select(SessionSelectors.getSessionId),
    ]).pipe(
      take(1),
      map(([targetEnv, applicationName, sessionId]) => {
        if (targetEnv && requestType !== 'ProducerCodeSearch') {
          headers = headers.set('X-NW-Target-Env', targetEnv);
        }
        if (requestType !== 'ProducerCodeSearch') {
          headers = headers.set('Application-Name', applicationName);
        }
        headers = headers.set(
          'X-NW-Message-ID',
          StringUtils.generateMessageId(sessionId)
        );
        return {
          ...options,
          headers,
        };
      })
    );
  }
}
