import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Nullable } from './type.utils';

/*
  Date Notes

  JS Date Format - YYYY-MM-DDT
  DSM Date Format - yyyy-MM-dd
  'Old' Date Format - mm/dd/yyyy
  'Plain' format - MMDDYYYY
*/

export class DateUtils {
  // BASIC DATE GETTERS AND SETTERS

  static getCurrentDate(): Date {
    // return new Date('2021-07-25T12:00');
    return new Date();
  }

  static getCurrentDateIso(): string {
    return new Date().toISOString().split('T')[0];
  }

  static getDay(): number {
    return this.getCurrentDate().getDate();
  }

  static getFutureDate(date: string, days: number): Date {
    const future = new Date(date);
    future.setDate(future.getDate() + days);

    return future;
  }

  // ADVANCED DATE GETTERS AND SETTERS

  static getMonthFromMmYyyy(input: string): number {
    const TWO_PLUS_SEPARATOR_PLUS_FOUR = 7;
    if (!input || input.length < TWO_PLUS_SEPARATOR_PLUS_FOUR) {
      return 0;
    }
    const split = input.split('/');
    if (split.length !== 2) {
      return 0;
    }
    return +split[0];
  }

  // DATE VALIDATOR HELPERS

  /**
   * Validate the date with format mm/dd/yyyy
   */
  static isValidMDYDate(date: string): boolean {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return regex.test(date);
  }

  static buildMMDDCCYYDatetimeString(date: Date): string {
    return (
      date.toLocaleDateString() +
      ' ' +
      date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
    );
  }

  static buildPaymentCreditCardExpirationDate(
    year: Nullable<string>,
    month: Nullable<string>
  ): string | null {
    if (!year || !month) {
      return null;
    }
    return `${year}${month}`;
  }

  static padZero(digits: string, length = 2): string {
    if (!digits) {
      return '';
    }
    while (digits.length < length) {
      digits = '0' + digits;
    }
    return digits;
  }

  static getInitialPolicyEffectiveDate(): string {
    const now = this.getCurrentDate();
    let day = now.getDate().toString();
    let month = (now.getMonth() + 1).toString();
    const year = now.getFullYear().toString();

    if (day.length === 1) {
      day = '0' + day;
    }

    if (month.length === 1) {
      month = '0' + month;
    }

    return [month, day, year].join('/');
  }

  // DSM MM/DD/YYYY to YYYY-MM-DD  aka  01/22/1990 to 1990-01-22
  static formatDateToDSM(old: Nullable<string>): string {
    if (!old) {
      return '';
    }
    const split = old.split('/');

    const year = split[2] ? `${split[2]}-` : '';
    const month = split[0] ? `${this.padZero(split[0], 2)}-` : '';
    const day = split[1] ? `${this.padZero(split[1], 2)}` : '';

    return year + month + day;
  }

  // 1990-01-22 to 01/22/1990
  static formatDsmDateToOld(dsm: Nullable<string>): string {
    if (!dsm) {
      return '';
    }

    const maskMatch = dsm.match(/^(\d{4})[*-]+$/);
    if (maskMatch) {
      return `**/**/${maskMatch[1]}`;
    }

    const split = dsm.split('T')[0].split('-');

    //if split[0] is length 2, it's a month, otherwise it's a year
    //if the overall length of the string is less than 6, it's a month

    if (dsm.length < 6) {
      const month = split[0] ? `${split[0]}/` : '';
      const day = split[1] ? `${split[1]}` : '';
      return month + day;
    } else {
      const month = split[1] ? `${split[1]}/` : '';
      const day = split[2] ? `${split[2]}/` : '';
      const year = split[0] ? `${split[0]}` : '';
      return month + day + year;
    }
  }

  /**
   * receives date like MM/YYYY and transforms to YYYY-MM-01
   */
  static transformMonthYearSlashToYearMonthDayDash(
    input: Nullable<string>
  ): string {
    if (!input) {
      return '';
    }
    const split = input.split('/');
    if (split.length !== 2) {
      return input;
    }
    return split[1] + '-' + split[0] + '-01';
  }

  /**
   * receives date like YYYY-MM-01 and transforms to MM/YYYY
   */
  static transformYearMonthDayDashToMonthYearSlash(input: string): string {
    if (!input) {
      return '';
    }
    const split = input.split('-');
    const THE_NUMBER_THREE_BUT_DENUDED_OF_MAGIC = 3;
    if (split.length !== THE_NUMBER_THREE_BUT_DENUDED_OF_MAGIC) {
      return input;
    }
    return split[1] + '/' + split[0];
  }

  static formatDateAsString(date: Date): string {
    const formattedYear = date.getFullYear().toString().padStart(4, '0');
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = date.getDate().toString().padStart(2, '0');
    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  }

  static getCurrentDateAsString(): string {
    return this.formatDateAsString(this.getCurrentDate());
  }

  static getXDaysInFuture(days: number): string {
    const dt = this.getCurrentDate();
    dt.setDate(dt.getDate() + days);
    return dt.toISOString();
  }

  static monthFromString(input: string): string {
    // eslint-disable-next-line no-magic-numbers
    switch (input.toLowerCase().substr(0, 3)) {
      case 'jan':
        return '01';
      case 'feb':
        return '02';
      case 'mar':
        return '03';
      case 'apr':
        return '04';
      case 'may':
        return '05';
      case 'jun':
        return '06';
      case 'jul':
        return '07';
      case 'aug':
        return '08';
      case 'sep':
        return '09';
      case 'oct':
        return '10';
      case 'nov':
        return '11';
      case 'dec':
        return '12';
    }
    return '01';
  }

  static isDateInPast(date: string): boolean {
    const checkDate = new Date(date);
    const now = new Date();

    return checkDate < now;
  }

  /**
   * @param date a mm/dd/yyyy or YYYY-MM-DD formatted string date
   * @returns difference in years from now if date string is valid otherwise NaN
   */
  static getDiffInYearsFromNow(date?: string): number {
    if (!date || date.length !== 10) {
      return NaN;
    }
    if (date.includes('/')) {
      date = DateUtils.formatDateToDSM(date);
    }
    // eslint-disable-next-line prefer-const
    let [year, month, day] = date.split('-').map((n) => parseInt(n, 10) || 0);
    if (!day) {
      day = 31;
    }
    if (!month) {
      month = 12;
    }

    const now = this.getCurrentDate();
    const years = now.getFullYear() - year;
    if (now.getMonth() + 1 > month) {
      return years;
    }
    if (now.getMonth() + 1 < month) {
      return years - 1;
    }

    return now.getDate() < day ? years - 1 : years;
  }

  static yearWithinThreeOfNow(year: number): boolean {
    const currentYear = new Date().getFullYear();
    const difference = currentYear - year;
    return difference <= 3;
  }

  static yearWithinXYearsOfNow(year: number, allowableDiff: number): boolean {
    const currentYear = this.getCurrentDate().getFullYear();
    const actualDiff = currentYear - year;
    return actualDiff <= allowableDiff;
  }

  static getUmbrellaDateOptions(
    products: ProductModel[],
    dsmEffectiveDate: string
  ): PolicyDateOptions {
    const dates = products
      .filter((product) => product.type !== 'PersonalUmbrella')
      .map((product) => new Date(`${product.effectiveDate + 'T12:00'}`));

    const latestDate = dates.reduce((a, b) => {
      return a > b ? a : b;
    }, {} as Date);
    let minDate = '';
    if (latestDate instanceof Date && !isNaN(latestDate.getTime())) {
      minDate = DateUtils.formatDateAsString(latestDate);
    } else {
      minDate = DateUtils.formatDateToDSM(dsmEffectiveDate);
    }

    // Umbrella max date should be 60 days from current date despite any future minimum effective date
    // The dsmEffectiveDate param is either today or the PC /clocks system date as set by effectiveDateInitializerFn
    const maxDate = DateUtils.formatDateAsString(
      DateUtils.getFutureDate(dsmEffectiveDate, 60)
    );

    return { minDate, maxDate };
  }

  static parseEstimatedPurchaseYear(
    estimatedYearPurchasedValue: number | string
  ): [number, number] {
    const purchaseDateComponents = estimatedYearPurchasedValue
      ?.toString()
      ?.split(/\//);
    const month = parseInt(purchaseDateComponents?.[0], 10) || 0;
    const year = parseInt(purchaseDateComponents?.[1], 10) || 0;
    return [month, year];
  }

  // YYYY-MM-DD format
  static getEstimatedPurchaseYear(
    estimatedYearPurchasedValue: number | string
  ): [number, number] {
    const purchaseDateComponents = estimatedYearPurchasedValue
      ?.toString()
      ?.split(/\-/);
    const month = parseInt(purchaseDateComponents?.[1], 10) || 0;
    const year = parseInt(purchaseDateComponents?.[0], 10) || 0;

    return [month, year];
  }

  static getCalendarMinDate(
    product: Nullable<ProductModel>,
    policyDateOptions: PolicyDateOptions
  ): string {
    if (product?.effectiveDate) {
      return new Date(product.effectiveDate || '').getTime() <
        new Date(policyDateOptions.minDate).getTime()
        ? product.effectiveDate
        : policyDateOptions.minDate;
    }
    return policyDateOptions.minDate;
  }

  static getDatesBetween(startDate: string, endDate: string): string[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];

    while (start <= end) {
      dates.push(this.formatDsmDateToOld(start.toISOString().split('T')[0]));
      start.setDate(start.getDate() + 1);
    }

    return dates;
  }

  static addYearsToDate(startDate: string, numberOfYears: Nullable<number>): string {
    if (!startDate.length || !numberOfYears) {
      return '';
    }

    const date = new Date(startDate);
    date.setFullYear(date.getFullYear() + Number(numberOfYears));
    return this.formatDateAsString(date);
  }
}
