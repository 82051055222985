import { ScheduledCategoryOption } from '@core/store/entities/scheduled-categories/scheduled-category.entity';

export interface ScheduledCategoryMetadataType {
  displayGrouping: string;
  maxPerClass?: number;
  maxPerItem?: number;
  minPerClass?: number;
  earthquakeDeductibleMessage?: string;
  itemsOnPublicDisplayRequired?: boolean;
  isIncomeCheckRequired?: boolean;
  displayName?: string; // used only in contextual help content
  availableOptions?: ScheduledCategoryOption[]; // TODO: jewelry only. temp fix for API bug
  helpText?: string;
}

export const EARTHQUAKE_DEDUCTIBLE_MSG =
  '15% deductible applies to breakage caused by earthquake for Fine Arts';
export const INCOME_INELIGIBLE_MSG = 'Income over $5000 is ineligible';

export const JEWELRY_COMBINED_VALUE_MAX = 10000;

export const ScheduledCategoryMetadata: {
  [key: string]: ScheduledCategoryMetadataType;
} = {
  // BLANKET
  JewelryBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Jewelry',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
    helpText: 'to jewelry and watches',
  },
  CameraBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Camera',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
    helpText: 'to cameras',
  },
  FineArtsBlanket: {
    displayGrouping: 'Blanket',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
    displayName: 'Fine Arts',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
    helpText: 'to fine arts',
  },
  FirearmsBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Firearms',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
    helpText: 'to fire arms',
  },
  FursBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Furs',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
    helpText: 'or damage to furs',
  },
  GolfEquipmentBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Golf Equipment',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
    helpText: 'or damage to golf equipment',
  },
  MusicNonProfessionalBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Musical Instruments',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
    helpText: 'to musical instruments',
  },
  // PLUS
  BoatDocksLifts: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to boat docks and/or lifts, subject to the terms, conditions, limitations and exclusions of the policy.  For example, storm surge water damage is specifically excluded by the policy.',
  },
  CameraNonProfessional: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to cameras not used for pay, subject to the terms, conditions, limitations and exclusions of the policy. If you receive payment for using your cameras, contact us about the professional coverage.',
  },
  CameraProfessional: {
    displayGrouping: 'Plus',
    isIncomeCheckRequired: true,
    helpText:
      'Coverage up to your selected limit for direct physical loss to cameras, subject to the terms, conditions, limitations and exclusions of the policy. However, you must earn less than $5,000 per year using your cameras. Otherwise, you will need to cover your items with a business policy.',
  },
  Coins: {
    displayGrouping: 'Plus',
    itemsOnPublicDisplayRequired: true,
    helpText:
      'Coverage up to your selected limit for direct physical loss to coins, subject to the terms, conditions, limitations and exclusions of the policy. We also cover the items  used to display or store coins.',
  },
  ComputerOffPremise: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to "computer equipment" that is used anywhere on or off the residence premises,  subject to the terms, conditions, limitations and exclusions of the policy. A deductible of $250 applies.',
  },
  ComputerOnPremise: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to "computer equipment" that remains on the residence premises, subject to the terms, conditions, limitations and exclusions of the policy. A deductible of $100 applies.',
  },
  FineArts: {
    displayGrouping: 'Plus',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
    helpText:
      'Coverage up to your selected limit for direct physical loss to fine arts, subject to the terms, conditions, limitations and exclusions of the policy. Fine arts are only covered while within the United States and Canada.',
  },
  FineArtsBreakage: {
    displayGrouping: 'Plus',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
    helpText:
      'Coverage up to your selected limit for direct physical loss to fine arts,  including breakage to fragile fine arts, subject to the terms, conditions, limitations and exclusions of the policy. Fine arts are only covered while within the United States and Canada.',
  },
  Fur: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage, up to your selected limit, for direct physical loss to furs, subject to the terms, conditions, limitations and exclusions of the policy. Includes garments trimmed with fur.',
  },
  Golf: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage, up to your selected limit, for direct physical loss to golf equipment, subject to the terms, conditions, limitations and exclusions of the policy. Includes your other clothing while contained in a locker when you are playing golf.',
  },
  Jewelry: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to jewelry or watches, subject to the terms, conditions, exceptions and exclusions of the policy. Individual and loose stones are not covered.',
    availableOptions: [
      {
        value: '5%',
        priority: '5',
        code: '5',
      },
      {
        value: '10%',
        priority: '10',
        code: '10',
      },
      {
        value: 'None',
        priority: '15',
        code: '0',
      },
    ],
  },
  MusicNonProfessional: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss to musical instruments not used for pay, subject to the terms, conditions, limitations and exclusions of the policy. If you receive payment for playing your instrument, contact us about the professional coverage.',
  },
  MusicProfessional: {
    displayGrouping: 'Plus',
    isIncomeCheckRequired: true,
    helpText:
      'Coverage up to your selected limit for direct physical loss  to musical instruments, subject to the terms, conditions, limitations and exclusions of the policy. However, you must earn less than $5,000 per year playing your instrument. Otherwise, you will need to cover your items with a business policy.',
  },
  Silverware: {
    displayGrouping: 'Plus',
    helpText:
      'Coverage up to your selected limit for direct physical loss  to silverware, subject to the terms, conditions, exceptions and exclusions of the policy. Does not include pens, pencils, flasks, smoking items or jewelry that are made out of silver.',
  },
  Stamp: {
    displayGrouping: 'Plus',
    itemsOnPublicDisplayRequired: true,
    helpText:
      'Coverage up to your selected limit for direct physical loss to stamps, subject to the terms, conditions, limitations and exclusions of the policy. Includes other stamp collecting items such as books, pages, and mounting.',
  },
};
