<div #taskList class="task-box-container">
  <nwx-progress-bar
    [quoteStatus]="(quoteStatus$ | async) || 'Draft'"
  ></nwx-progress-bar>
  <nwx-next-step
    [tasks]="(tasks$ | async) || null"
    [reviewTasks]="(reviewTasks$ | async) || null"
    [uwIssues]="(uwIssues$ | async) || {}"
    [disabledUwProducts]="(disabledUwProducts$ | async) || []"
    [errorTasks]="(errorTasks$ | async) || []"
    [blockedTasks]="(blockedTasks$ | async) || []"
    [showTasks]="(showTasks$ | async) || false"
    [products]="(products$ | async) || []"
    (taskButtonClicked)="onTaskButtonClicked($event)"
    (clickEvent)="onClickEvent($event)"
  ></nwx-next-step>
</div>
