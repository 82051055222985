import { ProductsService } from '@core/services/products.service';
import { UWReportsResponse } from '@core/models/api/response/uw-reports-response.model';
import { UWReportsService } from '@core/services/uw-reports.service';
import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { ProductModel } from '@core/store/entities/product/product.model';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { LayoutService } from '@core/services/layout.service';
import {
  AutoCharacteristicsViewReport,
  UwReportsViewModel,
  UwReportsViewReport,
  UwReportsViewSection,
} from '@app/hub/components/uw-reports-card/uw-reports-view-model';
import { Nullable } from '@shared/utils/type.utils';
import { ProductType } from '@core/models/api/dsm-types';
import { Router } from '@angular/router';
import { ViewReportModalComponent } from '@app/hub/components/view-report-modal/view-report-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UWReportsByProduct } from '@entities/uw-reports/uw-reports.reducer';

@Component({
  selector: 'nwx-uw-reports',
  templateUrl: './uw-reports.component.html',
  styleUrls: ['./uw-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UwReportsComponent {
  @Output() scrollToTasks = new EventEmitter<void>();

  viewModel$: Observable<UwReportsViewModel>;
  readonly defaultViewModel: UwReportsViewModel = {
    loading: true,
    sections: [{ reports: [] }, { reports: [] }, { reports: [] }],
    refreshEnabled: false,
    maxSizeMini: false,
  };

  private products: ProductModel[] = [];

  constructor(
    private uwReportsService: UWReportsService,
    private productsService: ProductsService,
    private featureFlagService: FeatureFlagService,
    private layoutService: LayoutService,
    private router: Router,
    private ngbModal: NgbModal
  ) {
    this.viewModel$ = combineLatest([
      this.productsService.getSelectedProducts(),
      this.uwReportsService.getReportsGroupedByProduct(),
      this.featureFlagService.getAllFeatureFlags(),
      this.layoutService.maxSizeMini(),
      this.uwReportsService.getUwReportsLoading(),
    ]).pipe(
      map(
        ([products, reportsByProduct, featureFlags, maxSizeMini, loading]) => {
          this.products = products;
          return this.composeReport(
            products,
            this.sanitizeIncomingGroupedReports(reportsByProduct),
            featureFlags,
            maxSizeMini,
            loading
          );
        }
      )
    );
  }

  private sanitizeIncomingGroupedReports(
    reportsByProduct: UWReportsByProduct
  ): UWReportsByProduct {
    const output: UWReportsByProduct = {
      auto: [],
      homeowner: [],
      renters: [],
      condo: [],
      msa: [],
      rv: [],
      boat: [],
    };
    for (const key of Object.keys(
      reportsByProduct
    ) as (keyof UWReportsByProduct)[]) {
      output[key] = this.sanitizeIncomingReports(reportsByProduct[key]);
    }
    return output;
  }

  private sanitizeIncomingReports(
    reports: UWReportsResponse[]
  ): UWReportsResponse[] {
    return reports.map((report) => {
      /* Require status.
       * I've seen PLH reports come in without it, and in those cases,
       * there are two fields 'orderedDate' and 'receivedDate' -- clear enough.
       */
      if (!report.status) {
        if (report.receivedDate) {
          report = { ...report, status: 'Received' };
        } else if (report.orderedDate) {
          report = { ...report, status: 'Ordered' };
        } else {
          report = { ...report, status: 'Not Ordered' };
        }
      }

      return report;
    });
  }

  private composeReport(
    products: ProductModel[],
    reportsByProduct: UWReportsByProduct,
    featureFlags: FeatureFlagsModel,
    maxSizeMini: boolean,
    loading: boolean
  ): UwReportsViewModel {
    const sections: UwReportsViewSection[] = [];

    if (!loading) {
      this.addSectionIfNotEmpty(
        sections,
        this.generateAutoSection(reportsByProduct.auto, products, featureFlags)
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateHomeownerSection(
          reportsByProduct.homeowner,
          products,
          featureFlags
        )
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateCondoSection(
          reportsByProduct.condo,
          products,
          featureFlags
        )
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateRentersSection(
          reportsByProduct.renters,
          products,
          featureFlags
        )
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateMsaSection(reportsByProduct.msa, products, featureFlags)
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateRvSection(reportsByProduct.rv, products, featureFlags)
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateBoatSection(reportsByProduct.boat, products, featureFlags)
      );
      this.addSectionIfNotEmpty(
        sections,
        this.generateCreditSection(reportsByProduct, products, featureFlags)
      );
    }

    this.autoSetLinkElementIds(sections);

    return {
      loading,
      sections,
      refreshEnabled: !loading,
      maxSizeMini,
    };
  }

  private addSectionIfNotEmpty(
    sections: UwReportsViewSection[],
    section: Nullable<UwReportsViewSection>
  ): void {
    if (section && section.reports.length) {
      sections.push(section);
    }
  }

  // in place
  private autoSetLinkElementIds(sections: UwReportsViewSection[]): void {
    let index = 0;
    for (const section of sections) {
      for (const report of section.reports) {
        report.linkElementId = `uw-report-action-${index++}`;
      }
    }
  }

  private generateAutoSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'PersonalAuto')) {
      return null;
    }
    return {
      productDescription: 'Auto',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            case 'MVR':
              return this.generateMvrReportRow(response);
            case 'ALH':
              return this.generateAlhReportRow(response, 'Auto');
            case 'CurrentCarrier':
              return this.generateAccReportRow(response, featureFlags);
            case 'CBR':
              return null;
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateHomeownerSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'Homeowner')) {
      return null;
    }
    let reports = responses
      .map((response) => {
        switch (response.reportSummaryId) {
          case 'MSB':
            return this.generateMsbReportRow(response);
          case 'PLH':
            return this.generatePlhReportRow(response);
          case 'CBR':
            return null;
        }
        return null;
      })
      .filter((report) => !!report) as UwReportsViewReport[];

    const homeownerProduct = products.find((p) => p.type === 'Homeowner');
    let autoCharacteristics = undefined;
    if (
      featureFlags.showPropertyFourOne &&
      !!homeownerProduct?.autoCharacteristics
    ) {
      autoCharacteristics = {
        autoBiOccurLimit: homeownerProduct?.autoCharacteristics
          ?.autoBiOccurLimit
          ? '$' +
            homeownerProduct?.autoCharacteristics?.autoBiOccurLimit +
            ',000'
          : '$--.--',
        autoBiPerPersonLimit: homeownerProduct?.autoCharacteristics
          ?.autoBiPerPersonLimit
          ? '$' +
            homeownerProduct?.autoCharacteristics?.autoBiPerPersonLimit +
            ',000'
          : '$--.--',
        minVehicleModelYear:
          homeownerProduct?.autoCharacteristics?.minVehicleModelYear ?? '-',
      };
    }

    return {
      productDescription: 'Property',
      reports,
      autoCharacteristics,
    };
  }

  private generateCondoSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'Condominium')) {
      return null;
    }
    return {
      productDescription: 'Property',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            case 'PLH':
              return this.generatePlhReportRow(response);
            case 'CBR':
              return null;
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateRentersSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'Tenant')) {
      return null;
    }
    return {
      productDescription: 'Property',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            case 'PLH':
              return this.generatePlhReportRow(response);
            case 'CBR':
              return null;
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateMsaSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'MSA')) {
      return null;
    }
    return {
      productDescription: 'Powersports',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            case 'MVR':
              return this.generateMvrReportRow(response);
            case 'ALH':
              return this.generateAlhReportRow(response, 'Powersports');
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateRvSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'RV')) {
      return null;
    }
    return {
      productDescription: 'RV',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            // TODO Which reports for RV?
            case 'MVR':
              return this.generateMvrReportRow(response);
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateBoatSection(
    responses: UWReportsResponse[],
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection | null {
    if (!products.find((p) => p.type === 'Boat')) {
      return null;
    }
    return {
      productDescription: 'Boat',
      reports: responses
        .map((response) => {
          switch (response.reportSummaryId) {
            // TODO Which reports for Boat?
            case 'MVR':
              return this.generateMvrReportRow(response);
          }
          return null;
        })
        .filter((report) => !!report) as UwReportsViewReport[],
    };
  }

  private generateCreditSection(
    reportsByProduct: UWReportsByProduct,
    products: ProductModel[],
    featureFlags: FeatureFlagsModel
  ): UwReportsViewSection {
    const reports: UwReportsViewReport[] = [];
    for (const key of Object.keys(
      reportsByProduct
    ) as (keyof UWReportsByProduct)[]) {
      if (
        !products.find((p) => p.type === this.productTypeFromUwReportsKey(key))
      ) {
        continue;
      }
      for (const report of (reportsByProduct[key] ||
        []) as UWReportsResponse[]) {
        if (report.reportSummaryId === 'CBR') {
          reports.push(
            this.generateCbrReportRow(
              report,
              this.productTypeFromUwReportsKey(key)
            )
          );
        }
      }
    }
    return { productDescription: 'Account', reports };
  }

  private productTypeFromUwReportsKey(
    key: keyof UWReportsByProduct
  ): ProductType {
    switch (key) {
      case 'auto':
        return 'PersonalAuto';
      case 'homeowner':
        return 'Homeowner';
      case 'condo':
        return 'Condominium';
      case 'renters':
        return 'Tenant';
      case 'msa':
        return 'MSA';
      case 'rv':
        return 'RV';
      case 'boat':
        return 'Boat';
    }
    return 'PersonalAuto';
  }

  private generateMvrReportRow(
    response: UWReportsResponse
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = 'Accidents & violations';
    if (response.status === 'Received') {
      if (response.mvrReportDetails?.incidents?.length) {
        report.iconName = 'exclamation-triangle-filled';
        report.iconColor = 'theme-warning';
        report.summary = 'Adverse';
      } else {
        report.iconName = 'checkmark-circle-filled';
        report.iconColor = 'theme-success';
        report.summary = 'Clean';
      }
    } else if (response.status === 'Not Ordered') {
      report.linkText = 'Report available after Final Rate';
    }
    if (response.mvrReportDetails?.driverName) {
      report.summary += ` (${response.mvrReportDetails.driverName})`;
    }
    return report;
  }

  private generateAlhReportRow(
    response: UWReportsResponse,
    productNameForTitle: string
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = `${productNameForTitle} claims`;
    if (response.status === 'Received') {
      if (response.alhReportDetails?.status === 'Clean') {
        report.iconName = 'checkmark-circle-filled';
        report.iconColor = 'theme-success';
        report.summary = 'Clean (CLUE/ALH)';
      } else {
        report.iconName = 'exclamation-triangle-filled';
        report.iconColor = 'theme-warning';
        report.summary = 'Adverse (CLUE/ALH)';
      }
    } else if (response.status === 'Not Ordered') {
      report.linkText = 'Report available after Final Rate';
    }
    return report;
  }

  private generateAccReportRow(
    response: UWReportsResponse,
    featureFlags: FeatureFlagsModel
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = 'Current carrier';
    if (response.status === 'Received') {
      if (response.currentCarrierReportDetails?.status === 'Hit') {
        report.iconName = 'checkmark-circle-filled';
        report.iconColor = 'theme-success';
        report.summary =
          'Carrier: ' + (response.currentCarrierReportDetails?.carrier || '');
      } else {
        report.iconName = 'exclamation-triangle-filled';
        report.iconColor = 'theme-warning';
        report.summary = 'Carrier: not found';
      }
    }
    return report;
  }

  private generateMsbReportRow(
    response: UWReportsResponse
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = 'Home reconstruction';
    if (response.status === 'Received') {
      report.summary = 'Hit';
      report.linkText = 'View/edit property information';
      report.linkBehavior = 'navProperty';
    }
    return report;
  }

  private generatePlhReportRow(
    response: UWReportsResponse
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = 'Property claims';
    if (response.status === 'Received') {
      if (response.plhReportDetails?.losses?.length) {
        report.iconName = 'exclamation-triangle-filled';
        report.iconColor = 'theme-warning';
        report.summary = 'Adverse (CLUE/PLH)';
      } else {
        report.summary = 'Clean (CLUE/PLH)';
      }
    } else if (response.status === 'Not Ordered') {
      report.linkText = 'Report available after Final Rate';
    }
    return report;
  }

  private generateCbrReportRow(
    response: UWReportsResponse,
    productType: ProductType
  ): UwReportsViewReport {
    const report: UwReportsViewReport = this.genericReport(response);
    report.title = `Credit - ${productType}`;
    if (response.status === 'Received') {
      report.linkText = '';
      report.linkBehavior = '';
      const cbr = response.cbrReportDetails;
      if (cbr) {
        report.summary = `${cbr.status || ''} (${cbr.insuredName || ''})`;
        if (cbr.status === 'No Hit') {
          report.iconName = 'exclamation-triangle-filled';
          report.iconColor = 'theme-warning';
        }
      }
    }
    return report;
  }

  private blankReport(): UwReportsViewReport {
    return {
      iconName: '',
      iconColor: '',
      title: '',
      summary: '',
      linkText: '',
      linkBehavior: '',
      linkElementId: '',
      originalResponse: null,
    };
  }

  private genericReport(response: UWReportsResponse): UwReportsViewReport {
    const report = this.blankReport();
    report.originalResponse = response;
    switch (response.status) {
      case 'Ordered': {
        report.iconName = 'clock';
        report.iconColor = 'yellow';
        report.summary = 'Ordered';
        break;
      }
      case 'Not Ordered': {
        report.iconName = '';
        report.summary = 'Not ordered';
        break;
      }
      case 'Received': {
        // Caller will want to override in this case.
        // Favorable: checkmark-circle-filled, theme-success
        // Adverse: exclamation-triangle-filled, theme-warning
        // summary, linkText, linkBehavior: varies by report
        report.iconName = 'checkmark-circle-filled';
        report.iconColor = 'theme-success';
        report.summary = 'Received';
        report.linkText = 'View report';
        report.linkBehavior = 'modal';
        break;
      }
      case 'Failed': {
        report.iconName = 'exclamation-circle-filled';
        report.iconColor = 'theme-error';
        report.summary = 'Error';
        break;
      }
    }
    return report;
  }

  onClickReportLink(report: UwReportsViewReport): void {
    switch (report.linkBehavior) {
      case 'modal':
        this.openModalForReport(report);
        break;
      case 'scrollToTasks':
        this.scrollToTasks.emit();
        break;
      case 'navProperty':
        this.navigateToProperty();
        break;
    }
  }

  private openModalForReport(report: UwReportsViewReport): void {
    switch (report.originalResponse?.reportSummaryId) {
      case 'CurrentCarrier':
        this.openModal('Current carrier', report);
        break;

      case 'MVR':
        this.openModal('Accidents & violations: MVR report summary', report);
        break;

      case 'ALH':
        this.openModal(report.title + ': CLUE/ALH report summary', report);
        break;

      case 'PLH':
        this.openModal('Property claims: CLUE/PLH report summary', report);
        break;
    }
  }

  private openModal(title: string, report: UwReportsViewReport): void {
    const modalRef = this.ngbModal.open(ViewReportModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'reportModalHeader',
    });
    const component: ViewReportModalComponent = modalRef.componentInstance;
    component.modalTitle = title;
    if (report.originalResponse) {
      if (report.originalResponse.currentCarrierReportDetails) {
        component.currentCarrierReportDetails = [
          report.originalResponse.currentCarrierReportDetails,
        ];
      }
      if (report.originalResponse.mvrReportDetails) {
        component.mvrReportDetails = [report.originalResponse.mvrReportDetails];
      }
      if (report.originalResponse.alhReportDetails) {
        component.alhReportDetails = [report.originalResponse.alhReportDetails];
      }
      if (report.originalResponse.plhReportDetails) {
        component.plhReportDetails = [report.originalResponse.plhReportDetails];
      }
    }
  }

  private navigateToProperty(): void {
    this.router.navigateByUrl('/quote/property/homeowner');
  }

  onRefresh(): void {
    for (const product of this.products) {
      if (product.quoteId && product.uwReportsImplemented) {
        this.uwReportsService.dispatchGetUWReports(
          product.quoteId,
          product.type
        );
      }
    }
  }
}
