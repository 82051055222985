<ng-container *ngIf="vm$ | async as vm">
  <div class="coverages-outer-container" [formGroup]="form">
    <ng-container
      *ngIf="vehicles.length && totalPolicyPremium > 0; else premiumSubstitute"
    >
      <div class="premium-section bolt-justify-content-center">
        <h2 class="premium-monthly">
          {{ totalPolicyPremium / termMonths | currency
          }}<span class="per-month"><strong>&nbsp;/ month</strong></span>
        </h2>
      </div>
      <div class="bolt-row bolt-justify-content-center">
        <h3 class="premium-total">
          {{ totalPolicyPremium | currency }}
        </h3>
        <span class="per-month-monthly">
          &nbsp;/ {{ termMonths }} month term</span
        >
      </div>
      <hr />

      <div class="bolt-row">
        <div class="bolt-col-xs-12 bolt-col-sm-6 unpad-row">
          <div
            class="update-effectiveDate-container"
            *ngIf="!showDatePicker"
            (click)="showDatePicker = true"
          >
            <a id="acdc-Effective-date-{{ product.type }}"
              >Effective date {{ form.value.effectiveDate }}</a
            >
            <bolt-icon
              name="pencil"
              [attr.id]="'acdc-pencil-' + product.type"
              color="medium-blue"
              [attr.size]="maxSizeMini ? 'sm' : 'md'"
              class="icon-top-padding"
            ></bolt-icon>
          </div>
          <nwx-datepicker-input
            *ngIf="showDatePicker"
            controlId="effectiveDate"
            id="acdc-effective-date"
            label="Effective date"
            controlName="effectiveDate"
            placeholder="MM/DD/YYYY"
            [minDate]="policyDateOptions?.minDate || null"
            [maxDate]="policyDateOptions?.maxDate || null"
            [disabled]="(updateInProgress$ | async) || false"
            [relevantProduct]="product.type"
            [quoteCreateDate]="product.quoteCreateDate"
            [bumpHeaderMargin]="true"
          ></nwx-datepicker-input>
        </div>
        <div class="bolt-col-xs-12 bolt-col-sm-6 unpad-row">
          <ng-template #removeModal let-modal>
            <div class="modal-header">Operation can not be undone</div>
            <div class="modal-body">Remove this product?</div>
            <div class="modal-footer">
              <bolt-button [attr.id]="'acdc-cancel'" (click)="modal.dismiss()"
                >Cancel</bolt-button
              >
              <bolt-button [attr.id]="'acdc-remove'" (click)="modal.close()"
                >Remove</bolt-button
              >
            </div>
          </ng-template>
          <div
            class="remove-policy-container"
            (click)="promptToRemovePolicy($event, removeModal)"
          >
            <a href="#">Remove policy</a>
            <bolt-icon
              name="trash"
              [attr.id]="'acdc-trash'"
              color="medium-blue"
              [attr.size]="maxSizeMini ? 'sm' : 'md'"
              class="icon-top-padding"
            ></bolt-icon>
          </div>
        </div>
      </div>
      <hr />

      <div class="info-row heading">
        <div class="bolt-row">
          <div class="bolt-col unpad-row">
            <strong class="section-header">Member details</strong>
          </div>
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col no-left-pad">
          <b>Named Insured</b>
          <span
            *ngFor="let person of policyHoldersForDisplay; let isLast = last"
          >
            {{ person.name }} <b>{{ person.role }}</b
            >{{ isLast ? '' : ', ' }}
          </span>
          <a
            class="link"
            id="edit-pni-sni-PersonalAuto"
            href="javascript:0"
            (click)="openNamedInsuredModal($event)"
          >
            <bolt-icon
              id="edit-pni-sni-PersonalAuto-icon"
              title="Update named insureds"
              name="pencil"
              color="medium-blue"
              size="sm"
            ></bolt-icon>
          </a>
        </div>
      </div>
      <!--TODO Additional Interest-->
      <div class="bolt-row">
        <div class="bolt-col unpad-row">
          <b>Drivers</b>
          <span *ngFor="let person of driversForDisplay; let isLast = last">
            {{ person.name }}{{ isLast ? '' : ', ' }}
          </span>
          <bolt-contextual-help>
            <p>
              Modify status of driver, excluded, and non-drivers by editing the
              <a href="#" (click)="onEditDrivers($event)">Members page</a>.
            </p>
          </bolt-contextual-help>
        </div>
      </div>
      <hr />

      <div class="info-row heading">
        <div class="bolt-row bolt-justify-content-between">
          <div class="bolt-col no-left-pad">
            <strong class="section-header">Policy coverages overview</strong>
          </div>
        </div>
      </div>
      <nwx-coverages-table
        [coverages]="getCoveragesForPolicyTable()"
        [termMonths]="termMonths"
      ></nwx-coverages-table>
      <hr />

      <div *ngFor="let vehicle of vehicles; let i = index">
        <div class="info-row heading">
          <div class="bolt-row bolt-justify-content-between">
            <div class="bolt-col-xs-xs-6">
              <strong class="section-header"
                >{{ vehicle.year }} {{ vehicle.make || '' | titlecase }}
                {{ vehicle.model || '' | titlecase }}
              </strong>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="bolt-row bolt-justify-content-between coverage-row">
            <div
              class="bolt-col-xs-6 no-left-pad"
              *ngIf="
                getTelematicsEnrollmentById(vehicle.vehicleId) === 'SmartMiles';
                else noTelematics
              "
            >
              Variable premium est.
              <bolt-contextual-help>
                <p>
                  Monthly estimated premium is subject to change based on
                  telematics.
                </p>
              </bolt-contextual-help>
            </div>
            <ng-template #noTelematics>
              <div class="bolt-col-xs-6 no-left-pad">
                Premium est.
                <bolt-contextual-help>
                  <p>
                    Monthly vehicle premium is subject to change based on rating
                    tasks.
                  </p>
                </bolt-contextual-help>
              </div>
            </ng-template>
            <div class="bolt-col-xs-6">
              <strong
                >{{
                  getVehiclePremiumById(vehicle.vehicleId) | currency
                }}/mo</strong
              >
            </div>
          </div>
        </div>
        <nwx-coverages-table
          [coverages]="getCoveragesForVehicleTable(vehicle.vehicleId)"
          [termMonths]="termMonths"
        ></nwx-coverages-table>
        <ng-container *ngIf="vehicle.vehicleType === 'auto'">
          <div class="telematics-row">
            <div
              class="bolt-row bolt-justify-content-between"
              *ngIf="enrollment"
            >
              <div
                class="bolt-col-xs"
                *ngIf="
                  getTelematicsEnrollmentById(vehicle.vehicleId) as programType
                "
              >
                <!-- TELEMATICS ENROLLMENT STATUS HERE -->
                <span [class]="programType" class="telematics-badge">
                  <div>
                    <strong
                      >{{
                        programType === 'SmartRideInstant'
                          ? 'SmartRide Instant'
                          : programType
                      }}®</strong
                    >
                    | Telematics
                  </div>
                  <div class="spacer"></div>
                  <span class="enrollment-inliner">
                    <div class="enrollment" (click)="emitEditTelematics()">
                      <span
                        class="enrollment-text"
                        [attr.id]="'acdc-enrolled-' + i"
                        *ngIf="
                          getTelematicsEnrollmentById(vehicle.vehicleId);
                          else noTelematicsBadge
                        "
                        >Enrolled
                      </span>
                      <ng-template #noTelematicsBadge>
                        <span
                          class="enrollment-text"
                          [attr.id]="'acdc-not-enrolled-' + i"
                          >Not Enrolled
                        </span>
                      </ng-template>
                      <bolt-icon
                        class="enrollment-text"
                        name="pencil"
                        attr.id="acdc-edit-{{ i }}"
                        color="medium-blue"
                        [attr.size]="maxSizeMini ? 'sm' : 'md'"
                      ></bolt-icon>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <hr />
    </ng-container>

    <div class="button-container">
      <div class="bolt-row">
        <div class="bolt-col buttons-row">
          <div>
            <a
              href="javascript:0"
              (click)="editCoverages($event)"
              class="vertical-align"
            >
              <bolt-icon name="pencil" class="edit-coverages-icon"></bolt-icon>
              <div>Edit all coverages</div>
            </a>
          </div>
          <div>
            <bolt-button
              type="secondary"
              [attr.id]="'acdc-view-edit-coverage'"
              (click)="openCoveragesModal()"
              >View all coverages</bolt-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #premiumSubstitute>
  <div
    id="premiumSubstitute"
    class="premium-section bolt-justify-content-center"
  >
    <h2 class="premium-monthly">
      <span class="per-month"><strong>&nbsp;$ --.-- / month</strong></span>
    </h2>
  </div>
  <div class="bolt-row bolt-justify-content-center">
    <h3 class="premium-total">$ --.--</h3>
    <span class="per-month-monthly"> &nbsp;/ {{ termMonths }} month term</span>
  </div>
  <hr />
  <div class="coverages-footer pb-20">
    <nwx-notification-bubble type="info">
      Rate unavailable
    </nwx-notification-bubble>
  </div>
  <hr />
  <div class="details-container-last"></div>
</ng-template>
