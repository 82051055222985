<div class="modal-header">
  <h4 class="modal-title">Leak Detection System Eligibility</h4>
</div>
<div class="modal-body">
  <nwx-leak-detection-form
    [coveredLocation]="coveredLocation || {}"
    [smartHome]="smartHomeEligibleDiscount$ | async"
    [isModal]="true"
    [product]="product$ | async"
    (coveredLocationValueChange)="onCoveredLocationValueChange($event)"
    (smartHomeValueChange)="onSmartHomeValueChange($event)"
  >
  </nwx-leak-detection-form>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="forward"
      type="primary"
      name="submit"
      id="leak-detection-modal-submit"
      (click)="onSubmit()"
      >Save</bolt-button
    >
  </bolt-button-bar>
</div>
