import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Bolt from '@nationwide-bolt/bundle-core';

if (environment.production) {
  enableProdMode();
}

function addGoogleTagManagerToHead() {
  const body = document.getElementsByTagName('head')[0];
  const _js = document.createElement('script');
  _js.type = 'text/javascript';
  _js.text = window.location.href.includes('nationwideexpress.nationwide.com')
    ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5J9QVNWC');`
    : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TBFNRSMX');
  `;
  body.prepend(_js);
}

function addGoogleTagManagerToBody() {
  const body = document.getElementsByTagName('body')[0];
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  const source = window.location.href.includes(
    'nationwideexpress.nationwide.com'
  )
    ? 'https://www.googletagmanager.com/ns.html?id=GTM-5J9QVNWC'
    : 'https://www.googletagmanager.com/ns.html?id=GTM-TBFNRSMX';
  iframe.setAttribute('src', source);
  iframe.setAttribute('height', '0');
  iframe.setAttribute('width', '0');
  iframe.setAttribute('style', 'display:none;visibility:hidden');
  noscript.appendChild(iframe);
  document.body.appendChild(noscript);

  body.prepend(noscript);
}

addGoogleTagManagerToHead();
addGoogleTagManagerToBody();

Bolt.initialize().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
