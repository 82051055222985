import { Injectable } from '@angular/core';
import { DocumentsRequest } from '@core/store/entities/document/document.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, switchMap, take, tap } from 'rxjs';
import { DocumentsAdapter } from '../adapters/documents.adapter';
import { DocumentsActions } from '../store/actions';
import {
  DocumentEntity,
  DocumentsEntity,
} from '../store/entities/document/document.entity';
import { DocumentSelectors } from '../store/selectors';
import { ProductType } from '@core/models/api/dsm-types';
import { PolicyDocumentResponse } from '@core/models/api/response/policy-document-response.model';
import { Base64Utils } from '@shared/utils/base64.utils';
import { NavigationService } from './navigation.service';
import { AppConfigService } from './app-config.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private documentsAdapter: DocumentsAdapter,
    private store: Store,
    private base64Utils: Base64Utils,
    private navigationService: NavigationService,
    private appConfigService: AppConfigService,
    private logService: LogService
  ) {}

  getDocuments(request: DocumentsRequest): Observable<DocumentsEntity> {
    return this.documentsAdapter.getDocuments(request);
  }

  dispatchGetDocuments(): void {
    this.store.dispatch(DocumentsActions.getAllDocuments());
  }

  getDocumentsLoaded(): Observable<boolean> {
    return this.store.select(DocumentSelectors.getDocumentsLoaded);
  }

  getAllDocuments(): Observable<DocumentsEntity[]> {
    return this.store.select(DocumentSelectors.getAllDocuments);
  }

  getAllUniqueDocuments(): Observable<DocumentsEntity[]> {
    return this.store.select(DocumentSelectors.getAllUniqueDocuments);
  }

  updateDocumentsWithDocumentByteStream(
    docEntities: DocumentsEntity[]
  ): Observable<DocumentsEntity[]> {
    const operations: Observable<PolicyDocumentResponse>[] = [];
    docEntities.forEach((entity) => {
      entity.documents?.forEach((document) => {
        if (
          document.name.toLowerCase().includes('application') ||
          document.name.toLowerCase().includes('declaration')
        ) {
          operations.push(
            this.getDocumentByteStream(document, entity.productType).pipe(
              tap((response) => {
                document.documentByteStream = response.documentByteStream;
                // Set the Declarations document to esignable so it displays on the summary page using the object array filter
                // We don't look at the esignable flag for anything else currently.
                if (response.name.toLowerCase().includes('declaration')) {
                  document.isElectronicallySignable = true;
                }
              })
            )
          );
        }
      });
    });
    return combineLatest(operations).pipe(map(() => docEntities));
  }

  getDocumentByteStream(
    document: DocumentEntity,
    productType: ProductType
  ): Observable<PolicyDocumentResponse> {
    return this.store
      .select(
        DocumentSelectors.buildGetPolicyDocumentRequest(document, productType)
      )
      .pipe(
        take(1),
        switchMap((request) => {
          return this.documentsAdapter.getDocument(request);
        })
      );
  }

  getDocumentPdf(
    documentId: number,
    productType: ProductType
  ): Observable<string | null | undefined> {
    return this.store.select(
      DocumentSelectors.getDocumentByteStream(documentId, productType)
    );
  }

  showDocumentPdfInNewTab(documentId: number, productType: ProductType): void {
    this.getDocumentPdf(documentId, productType)
      .pipe(take(1))
      .subscribe((document) => {
        if (!!document) {
          this.showEncodedDocumentInNewTab(document);
        }
      });
  }

  showEncodedDocumentInNewTab(base64EncodedPdf: string): void {
    if (!base64EncodedPdf) {
      return;
    }
    this.base64Utils
      .decodeBase64ToBlobUrl(base64EncodedPdf, 'application/pdf')
      .then((url: any) => {
        this.navigationService.openExternalPage(url, '_blank');
      })
      .catch((err: any) => {
        // Opportunity here to report the error.
      });
  }

  openDocVault(isIaUser: boolean): void {
    let docVaultUrl = '';
    if (isIaUser) {
      docVaultUrl = this.appConfigService.config.docVaultExternalUrl;
    } else {
      docVaultUrl = this.appConfigService.config.docVaultInternalUrl;
    }
    this.logService.logBusinessEvent('open-docvault', docVaultUrl);
    this.navigationService.openExternalPage(docVaultUrl, '_blank');
  }
}
