<div class="modal-header">
  <h4>Underwriting approval request</h4>
</div>
<div class="modal-body bolt-container-fluid" [formGroup]="form" [nwxExposeFormErrors]="form" [submitted]="(submitted$ | async) || false">
  <div class="bolt-row">
    <div class="bolt-col instructions">
      Include any information the underwriter might need to approve the request.
    </div>
  </div>
  <div class="bolt-row">
    <div class="bolt-col-1">
      <img [src]="productIconUrl()"/>
    </div>
    <div class="bolt-col-11">
      <div class="product-name">{{ productName }}</div>
      <div class="quote-id">Submission ID# {{ quoteId }}</div>
    </div>
  </div>
  <div class="bolt-row">
    <div class="bolt-col issues-list">
      <ng-container *ngFor="let issue of issues$ | async">
        <div class="issue">{{ issue.description }}</div>
      </ng-container>
    </div>
  </div>
  <hr class="fill-modal-body"/>
  <div class="bolt-row">
    <div class="bolt-col">
      <bolt-select formControlName="subject" label="Subject" required>
        <option value="Accident/Violation Correction">Accident/Violation Correction</option>
        <option value="Cancellation Remedy">Cancellation Remedy</option>
        <option value="Change in Policy Risk">Change in Policy Risk</option>
        <option value="Follow-up to Underwriting Request">Follow-up to Underwriting Request</option>
      </bolt-select>
    </div>
  </div>
  <div class="bolt-row">
    <div class="bolt-col">
      <bolt-select formControlName="topic" label="Topic" required>
        <option value="Billing Verification">Billing Verification</option>
        <option value="Cancellation">Cancellation</option>
        <option value="Explain Coverage">Explain Coverage</option>
        <option value="General">General</option>
        <option value="Non-Renew">Non-Renew</option>
        <option value="Policy Change Transaction">Policy Change Transaction</option>
        <option value="Policy Verification">Policy Verification</option>
        <option value="Pre-Renewal Direction">Pre-Renewal Direction</option>
        <option value="Reinstatement">Reinstatement</option>
        <option value="Risk characteristics">Risk characteristics</option>
      </bolt-select>
    </div>
  </div>
  <div class="bolt-row">
    <div class="bolt-col">
      <bolt-textarea formControlName="description" label="Detailed description / comments"></bolt-textarea>
      <div>Include any information the underwriter might need to approve the request.</div>
    </div>
  </div>
  <div class="bolt-row" *ngIf="(errorMessage$ | async)?.displayMessage as message">
    <div class="bolt-col">
      <nwx-notification-bubble
        type="error"
      >{{ message }}</nwx-notification-bubble>
    </div>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button slot="cancel" type="primary" (click)="onCancel()">Cancel</bolt-button>
    <bolt-button
      slot="forward"
      type="primary"
      (click)="onSubmit()"
      [disabled]="this.disableSubmitButton$ | async"
    >Submit request</bolt-button>
  </bolt-button-bar>
</div>
