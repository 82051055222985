import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerInputComponent } from './datepicker-input.component';
import { SharedComponentsModule } from '../shared-components.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    PipesModule,
    SharedDirectivesModule,
    SharedComponentsModule,
  ],
  declarations: [DatepickerInputComponent],
  bootstrap: [DatepickerInputComponent],
  exports: [DatepickerInputComponent],
})
export class DatepickerInputModule {}
