import { createAction, props } from '@ngrx/store';
import { UwActivity } from './uw-activities.reducer';
import { ProductType } from '@core/models/api/dsm-types';

export const doDatUwActivitiesThang = createAction('[UW Activities Thang] Do');

export const storeActivity = createAction(
  '[UW Activities] Store Action',
  props<{
    productType: ProductType;
    quoteId: string;
    activity: UwActivity;
  }>()
);

export const storeActivities = createAction(
  '[UW Activities] Store Actions',
  props<{
    productType: ProductType;
    quoteId: string;
    activities: UwActivity[];
  }>()
);
