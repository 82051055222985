import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { PreBindDocumentsService } from '@core/services/pre-bind-documents.service';
import * as fromActions from '@core/store/actions/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  areAllPreBindDocumentsRetrieved,
  buildPreBindDocumentDetailRequest,
  buildPreBindDocumentEmailRequest,
  buildPreBindDocumentUploadRequest,
  getPreBindDocumentProductTypes,
  selectAllPreBindDocumentByProductType,
} from './pre-bind-documents.selector';
import { ConfirmationsAction } from '@forms-store/store/actions';
import { ModalService } from '@shared/services/modal.service';
import { PreBindDocumentsFormService } from '@forms-store/services/pre-bind-documents-form.service';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';
import { PreBindDocumentEntity } from '@core/models/entities/pre-bind-document.entity';
import { PreBindDocumentRequest } from '@core/models/api/request/pre-bind-document-request.model';
import { LogService } from '@core/services/log.service';
import { LogEventName } from '@core/models/api/log.model';

@Injectable()
export class PreBindDocumentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private preBindDocumentService: PreBindDocumentsService,
    private errorSanitizerService: ErrorSanitizerService,
    private modalService: ModalService,
    private preBindDocumentsFormService: PreBindDocumentsFormService,
    private productsService: ProductsService,
    private log: LogService
  ) {}

  replacePreBindDocumentsByProductType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.PreBindDocumentsActions.replacePreBindDocumentsByProductType
      ),
      map((action) =>
        fromActions.PreBindDocumentsActions.uploadPreBindDocuments({
          productType: action.productType,
        })
      )
    )
  );

  uploadAllProductsPreBindDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.PreBindDocumentsActions.uploadAllProductsPreBindDocuments
      ),
      switchMap(() => this.getPreBindDocumentProductTypes().pipe(take(1))),
      switchMap((productTypes) => {
        this.log.logUiEvent('retry-pre-bind-docs-upload' as LogEventName, {
          productTypes,
        });
        const nextActions: Action[] = [];
        for (let productType of productTypes) {
          nextActions.push(
            fromActions.PreBindDocumentsActions.uploadPreBindDocuments({
              productType,
            })
          );
        }
        return nextActions;
      })
    )
  );

  uploadPreBindDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.PreBindDocumentsActions.uploadPreBindDocuments),
      mergeMap((action) =>
        this.areAllPreBindDocumentsRetrieved(action.productType).pipe(
          take(1),
          map((areAllPreBindDocsRetrieved) => ({
            action,
            areAllPreBindDocsRetrieved,
          }))
        )
      ),
      mergeMap(({ action, areAllPreBindDocsRetrieved }) => {
        if (areAllPreBindDocsRetrieved) {
          return this.selectAllPreBindDocumentByProductType(
            action.productType
          ).pipe(
            take(1),
            map((allPreBindDocuments) => ({ action, allPreBindDocuments }))
          );
        } else {
          return this.buildPreBindDocumentDetailRequest(
            action.productType
          ).pipe(
            take(1),
            switchMap((requests) => {
              const individualDocRequests = requests.map((request) =>
                this.preBindDocumentService.getPreBindDocument(
                  request,
                  action.productType
                )
              );
              return forkJoin(individualDocRequests).pipe(
                take(1),
                map((allPreBindDocuments) => ({
                  action,
                  allPreBindDocuments,
                }))
              );
            })
          );
        }
      }),
      mergeMap(({ action, allPreBindDocuments }) =>
        this.buildPreBindDocumentUploadRequest(
          action.productType,
          allPreBindDocuments
        ).pipe(
          take(1),
          map((reqObj) => ({ action, reqObj }))
        )
      ),
      mergeMap(({ action, reqObj }) =>
        this.preBindDocumentService
          .uploadDocuments(reqObj.request, reqObj.accessToken, reqObj.sessionId)
          .pipe(
            take(1),
            mergeMap((response) =>
              of(
                fromActions.PreBindDocumentsActions.uploadPreBindDocumentsSuccess(
                  {
                    uploadResponse: response,
                    productType: action.productType,
                  }
                )
              )
            ),
            catchError((error) => {
              const safeError = this.errorSanitizerService.sanitizeError(error);
              return from([
                fromActions.PreBindDocumentsActions.uploadPreBindDocumentsFail({
                  error: safeError,
                }),
              ]);
            })
          )
      )
    )
  );

  resendDocumentUrlEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.PreBindDocumentsActions.resendDocumentUrlEmail),
      switchMap((action) => {
        const sendEmailRequests = action.productTypes?.map((productType) =>
          this.buildPreBindDocumentEmailRequest(productType).pipe(take(1))
        );
        return forkJoin(sendEmailRequests);
      }),
      switchMap((requests) => {
        const sendEmailCalls = requests.map((request) =>
          this.preBindDocumentService.sendEmail(
            request.request,
            request.accessToken,
            request.sessionId
          )
        );
        return forkJoin(sendEmailCalls);
      }),
      switchMap((responses) => {
        const nextActions: Action[] = [];
        responses.forEach((response) => {
          nextActions.push(
            fromActions.PreBindDocumentsActions.resendDocumentUrlEmailSuccess({
              response,
            })
          );
        });
        return from(nextActions);
      }),
      catchError((error) => {
        const safeError = this.errorSanitizerService.sanitizeError(error);
        return of(
          fromActions.PreBindDocumentsActions.resendDocumentUrlEmailFail({
            error: safeError,
          })
        );
      })
    )
  );

  showPreBindDocumentsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfirmationsAction.openPreBindDocumentsModal),
        exhaustMap(() => this.modalService.preBindDocuments()),
        filter((result) => result),
        switchMap((result) =>
          this.getPreBindDocumentProductTypes().pipe(
            take(1),
            map((productTypes) => [result, productTypes])
          )
        ),
        tap(([result, productTypes]) => {
          this.preBindDocumentsFormService.updatePreBindDocumentsForm(result);
          if (productTypes.includes('PersonalAuto')) {
            this.productsService.updateQuote(
              {
                productType: 'PersonalAuto',
                hasReceivedRequiredDocuments: true,
                hasCustomerViewedDocuments: true,
              },
              {
                overrideQuoteStatus: true,
              }
            );
          }
        })
      ),
    { dispatch: false }
  );

  private areAllPreBindDocumentsRetrieved(
    productType: ProductType
  ): Observable<boolean> {
    return this.store.select(areAllPreBindDocumentsRetrieved(productType));
  }

  private selectAllPreBindDocumentByProductType(
    productType: ProductType
  ): Observable<PreBindDocumentEntity[]> {
    return this.store.select(
      selectAllPreBindDocumentByProductType(productType)
    );
  }

  private buildPreBindDocumentDetailRequest(
    productType: ProductType
  ): Observable<PreBindDocumentRequest[]> {
    return this.store.select(buildPreBindDocumentDetailRequest(productType));
  }

  private buildPreBindDocumentUploadRequest(
    productType: ProductType,
    preBindDocuments: PreBindDocumentEntity[]
  ): Observable<any> {
    return this.store.select(
      buildPreBindDocumentUploadRequest(productType, preBindDocuments)
    );
  }

  private buildPreBindDocumentEmailRequest(
    productType: ProductType
  ): Observable<any> {
    return this.store.select(buildPreBindDocumentEmailRequest(productType));
  }

  private getPreBindDocumentProductTypes(): Observable<ProductType[]> {
    return this.store.select(getPreBindDocumentProductTypes);
  }
}
