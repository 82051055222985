<form [formGroup]="form" formName="account-info">
  <div class="bolt-row bolt-space-inner-lg bolt-space-bottom-lg nwx-task-tile">
    <div class="bolt-col-12 bolt-col-sm-6">
      <div class="bolt-row bolt-no-gutters">
        <div class="bolt-col-auto title-img-content flex-items-center">
          <img [src]="imageUrl(product.type)" alt="{{ product.type }} icon" class="title-img" />
        </div>
        <div class="bolt-col title-text-content">
          <div class="content-label">
            <strong>{{ product.name }}</strong>
          </div>
          <div class="content-subtext bolt-body-copy-sm">
            Submission ID: <span class="bold">{{ product.quoteId }}</span>
          </div>
          <div class="content-subtext bolt-body-copy-sm">
            Effective date: <span class="bold">{{ product.effectiveDate | date:'M/d/y' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bolt-col-12 bolt-col-sm-6 flex-center">
      <div *ngIf="editing; else display">
        <bolt-select [id]="product.type+'-select'" [name]="product.type" *ngIf="loading$ | async; else loading"
          [placeholder]="producerCodeDisplay()" [placeholderValue]="product.producer?.producerCode"
          [formControlName]="product.type + '-producerCode'" [aria-label]="product.type" ngDefaultControl required
          (change)="onProducerCodeChanged(product.type, $event)"
        >
          <option *ngFor="let producer of producerCodeOptions$ | async" [value]="producer.producerCode">
            {{producerOptionDisplay(producer)}}
          </option>
        </bolt-select>
        <ng-template #loading class="select-loading flex-center">
          <span [id]="product.type+'-select-loading'" class="producer-loading-label">Loading...</span>
          <bolt-waiting-indicator minimal></bolt-waiting-indicator>
        </ng-template>
      </div>
      <ng-template #display>
        <div class="nwx-producer-tile bolt-space-inner-sm">
          <span class="producer-display">
            {{producerCodeDisplay()}}
          </span>
          <bolt-icon
            *ngIf="canEdit"
            class="icon-edit"
            name="pencil"
            size="lg"
            color="medium-blue"
            (click)="beginEdit()"
            aria-label="edit"
          ></bolt-icon>
        </div>
      </ng-template>
    </div>
  </div>
</form>
