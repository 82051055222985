import {
  ProducerResponse,
  sanitizeProducerResponse,
} from './producer-response.model';
import { PersonResponse } from './person-response.model';
import {
  OfferedQuote,
  sanitizeOfferedQuote,
} from './update-quote-response.model';
import {
  DiscountEntity,
  sanitizeDiscountEntity,
} from '@core/store/entities/discount/discount.entity';
import {
  EligibleDiscountsEntity,
  sanitizeEligibleDiscountsEntity,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import {
  TelematicsEnrollmentResponse,
  sanitizeTelematicsEnrollmentResponse,
} from './telematics-enrollment-response.model';
import {
  PolicyHolderEntity,
  sanitizePolicyHolderEntities,
} from '@core/store/entities/policyholder/policyholder.entity';
import {
  AddressEntity,
  sanitizeAddressEntity,
} from '@core/store/entities/address/address.entity';
import {
  CurrentCarrierEntity,
  sanitizeCurrentCarrierEntity,
} from '@core/store/entities/current-carrier/current-carrier.reducer';
import {
  DriverEntity,
  sanitizeDriverEntity,
} from '@core/store/entities/driver/driver.entity';
import {
  ProductType,
  CCYYMMDDDate,
  QuoteStatus,
  TermType,
  OfferingType,
  LicenseStatus,
  NationwideAccountRegistrationInfo,
  sanitizeNationwideAccountRegistrationInfo,
} from '../dsm-types';
import {
  VehicleEntity,
  sanitizeVehicleEntity,
} from '@core/store/entities/vehicle/vehicle.entity';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import {
  PreBindDocumentEntity,
  sanitizePreBindDocumentEntity,
} from '@core/models/entities/pre-bind-document.entity';
import {
  ScheduledCategoryEntity,
  sanitizeScheduledCategoryEntity,
} from '@entities/scheduled-categories/scheduled-category.entity';
import {
  PreQualification,
  sanitizePreQualification,
} from '@core/interfaces/interfaces';
import {
  InformationalMessages,
  sanitizeInformationalMessage,
} from '@core/adapters/quote-retrieve.adapter';
import {
  OtherStructureCoverage,
  sanitizeOtherStructureCoverages,
} from '@entities/other-structures/other-structures.reducer';
import {
  AdditionalHouseholdInfo,
  AutoCharacteristics,
  EligibilityInformationEntity,
  sanitizeAdditionalHouseholdInfo,
  sanitizeAutoCharacteristics,
  sanitizeEligibilityInformation,
} from '@entities/product/product.model';
import { VehicleExposureEntity } from '@entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import {
  UnderlyingPolicyEntity,
  sanitizeUnderlyingPolicyEntity,
} from '@entities/underlying-policy/underlying-policy.entity';
import {
  LocationExposureEntity,
  sanitizeLocationExposureEntity,
} from '@entities/exposures/location-exposures/location-exposures.entity';
import {
  WatercraftExposureEntity,
  sanitizeWatercraftExposureEntity,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.entity';
import {
  VehicleExcludedExposureEntity,
  sanitizeVehicleExcludedExposureEntity,
} from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.entity';
import {
  LocationExcludedExposureEntity,
  sanitizeLocationExcludedExposureEntity,
} from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.entity';
import {
  WatercraftExcludedExposureEntity,
  sanitizeWatercraftExcludedExposureEntity,
} from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.entity';
import { ObjectValidator } from '@core/helper/object-validator';
import { sanitizeDriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { sanitizeEmploymentInfoEntity } from '@core/models/entities/employment-info.entity';
import { sanitizeFilingEntity } from '@core/models/entities/filing.entity';
import { sanitizePersonEntity } from '@core/models/entities/person.entity';
import { sanitizeModifierEntity } from '@entities/modifier/modifier.entity';
import { sanitizeCoveredLocation } from '@core/adapters/covered-location.adapter';
import { sanitizeMortgageEntities } from '../request/mortgage.request.model';

export type QuoteRetrieveResponse =
  | {
      productType: 'PersonalAuto';
      response: QuoteRetrievePersonalAutoResponse;
    }
  | {
      productType: 'Homeowner';
      response: QuoteRetrieveHomeownerResponse;
    }
  | {
      productType: 'Tenant';
      response: QuoteRetrieveTenantResponse;
    }
  | {
      productType: 'Condominium';
      response: QuoteRetrieveCondominiumResponse;
    }
  | {
      productType: 'PersonalUmbrella';
      response: QuoteRetrieveUmbrellaResponse;
    }
  | {
      productType: 'MSA';
      response: QuoteRetrieveMSAResponse;
    }
  | {
      productType: 'Boat';
      response: QuoteRetrieveBoatResponse;
    }
  | {
      productType: 'RV';
      response: QuoteRetrieveRVResponse;
    }
  | {
      productType: 'DwellingFire';
      response: QuoteRetrieveDwellingFireResponse;
    };

export type QuoteRetrieveApiResponse =
  | QuoteRetrievePersonalAutoResponse
  | QuoteRetrieveHomeownerResponse
  | QuoteRetrieveTenantResponse
  | QuoteRetrieveCondominiumResponse
  | QuoteRetrieveUmbrellaResponse
  | QuoteRetrieveMSAResponse
  | QuoteRetrieveBoatResponse
  | QuoteRetrieveRVResponse
  | QuoteRetrieveDwellingFireResponse;

export interface QuoteRetrieveError {
  productType: ProductType;
  response: any;
}

export interface BaseRetrieveQuoteResponse {
  accountId: string;
  autoCharacteristics: AutoCharacteristics;
  automateElectronicDocumentDelivery: boolean;
  currentBillTo?: string;
  creditConsent: boolean;
  discounts: DiscountEntity[];
  distributionPartnerId?: string;
  docDelPreference?: 'USMail' | 'OnlineAccountAccess';
  effectiveDate: CCYYMMDDDate; // CCYY-MM-DD format
  eligibleDiscounts?: EligibleDiscountsEntity[];
  informationalMessages?: InformationalMessages[];
  isVersioningEnabled?: boolean;
  nationwideAccountRegistrationInfo?: NationwideAccountRegistrationInfo;
  offeredQuotes: OfferedQuote[];
  offeringType?: OfferingType;
  policyAddress: AddressEntity;
  policyHolders: PolicyHolderEntity[];
  producer: ProducerResponse;
  quoteId: string;
  quoteStatus: QuoteStatus;
  sessionId: string;
  termType: TermType;
}

export function sanitizeQuoteRetrieveItem(
  input: unknown
): QuoteRetrieveApiResponse {
  return ObjectValidator.forceSchema<QuoteRetrieveApiResponse>(
    input,
    {
      // Base retrieve

      accountId: 'string',
      autoCharacteristics: sanitizeAutoCharacteristics,
      automateElectronicDocumentDelivery: 'boolean',
      currentBillTo: 'string',
      creditConsent: 'boolean',
      discounts: [sanitizeDiscountEntity],
      distributionPartnerId: 'string',
      docDelPreference: 'string',
      effectiveDate: 'string',
      eligibleDiscounts: [sanitizeEligibleDiscountsEntity],
      informationalMessages: [sanitizeInformationalMessage],
      isVersioningEnabled: 'boolean',
      nationwideAccountRegistrationInfo:
        sanitizeNationwideAccountRegistrationInfo,
      offeringType: 'string',
      policyAddress: sanitizeAddressEntity,
      policyHolders: sanitizePolicyHolderEntities,
      producer: sanitizeProducerResponse,
      quoteId: 'string',
      quoteStatus: 'string',
      sessionId: 'string',
      termType: 'string',

      // other shared fields
      currentCarrier: sanitizeCurrentCarrierEntity,
      documents: [sanitizePreBindDocumentEntity],
      drivers: [sanitizeDriverRetrieveEntity],
      hasConnectedCarConsent: 'boolean',
      hasDrivingDataConsent: 'boolean',
      vehicles: [sanitizeVehicleEntity],
      recreationalVehicles: [sanitizeVehicleEntity],
      pleasureBoatVehicles: [sanitizeVehicleEntity],
      telematicsEnrollments: [sanitizeTelematicsEnrollmentResponse],
      isAssignedRiskPlan: 'boolean',
      hasCompanyCar: 'boolean',
      additionalHouseholdInfo: sanitizeAdditionalHouseholdInfo,
      scheduledPersonalEffects: [sanitizeScheduledCategoryEntity],
      coveredLocation: sanitizeCoveredLocation,
      needConstructionInfo: 'boolean',
      offeredQuotes: [sanitizeOfferedQuote],
      prequalificationAnswers: [sanitizePreQualification],
      scheduledCategories: [
        {
          isScheduledItemsApplicable: 'boolean',
          name: 'string',
          scheduledCategoryId: 'string',
        },
      ],
      yearsWithPriorCarrier: 'string',
      mortgages: sanitizeMortgageEntities,
      otherStructureCoverages: sanitizeOtherStructureCoverages,
      underlyingPolicies: [sanitizeUnderlyingPolicyEntity],
      eligibilityInformation: sanitizeEligibilityInformation,
      householdMembers: 'any',
      vehicleExposures: [sanitizeVehicleEntity],
      vehicleExcludedExposures: [sanitizeVehicleExcludedExposureEntity],
      locationExposures: [sanitizeLocationExposureEntity],
      locationExcludedExposures: [sanitizeLocationExcludedExposureEntity],
      watercraftExposures: [sanitizeWatercraftExposureEntity],
      watercraftExcludedExposures: [sanitizeWatercraftExcludedExposureEntity],
    },
    [
      'autoCharacteristics',
      'currentBillTo',
      'distributionPartnerId',
      'docDelPreference',
      'eligibleDiscounts',
      'informationalMessages',
      'isVersioningEnabled',
      'nationwideAccountRegistrationInfo',
      'offeringType',
      'documents',
      'telematicsEnrollments',
      'isAssignedRiskPlan',
      'hasCompanyCar',
      'additionalHouseholdInfo',
      'scheduledCategories',
      'mortgages',
      'coveredLocation',
      'currentCarrier',
      'offeredQuotes',
      'prequalificationAnswers',
      'vehicles',
      'telematicsEnrollments',
      'drivers',
      'recreationalVehicles',
      'scheduledPersonalEffects',
      'pleasureBoatVehicles',
      'mortgages',
      'otherStructureCoverages',
      'underlyingPolicies',
      'eligibilityInformation',
      'householdMembers',
      'vehicleExposures',
      'vehicleExcludedExposures',
      'locationExcludedExposures',
      'watercraftExposures',
      'watercraftExcludedExposures',
      'prequalificationAnswers',
    ]
  );
}

export interface QuoteRetrievePersonalAutoResponse
  extends BaseRetrieveQuoteResponse {
  currentCarrier: CurrentCarrierEntity;
  documents?: PreBindDocumentEntity[];
  drivers: DriverRetrieveEntity[];
  hasConnectedCarConsent: boolean;
  hasDrivingDataConsent: boolean;
  vehicles: VehicleEntity[];
  telematicsEnrollments?: TelematicsEnrollmentResponse[];
  isAssignedRiskPlan?: boolean;
  hasCompanyCar?: boolean;
  additionalHouseholdInfo?: AdditionalHouseholdInfo;
}

export interface QuoteRetrieveMSAResponse extends BaseRetrieveQuoteResponse {
  currentCarrier: CurrentCarrierEntity;
  drivers: DriverRetrieveEntity[];
  vehicles: VehicleEntity[];
  hasNWPSPolicies: boolean;
  numberOfOtherVehicles?: number;
}

export interface QuoteRetrieveRVResponse extends BaseRetrieveQuoteResponse {
  currentCarrier: CurrentCarrierEntity;
  drivers: DriverRetrieveEntity[];
  recreationalVehicles: VehicleEntity[];
  scheduledPersonalEffects?: ScheduledCategoryEntity[];
  hasNWPSPolicies: boolean;
  numberOfOtherVehicles?: number;
}
export interface QuoteRetrieveBoatResponse extends BaseRetrieveQuoteResponse {
  currentCarrier: CurrentCarrierEntity;
  drivers: DriverRetrieveEntity[];
  pleasureBoatVehicles: VehicleEntity[];
  hasNWPSPolicies: boolean;
  numberOfOtherVehicles?: number;
}
export interface QuoteRetrieveHomeownerResponse
  extends BaseRetrieveQuoteResponse {
  coveredLocation: CoveredLocationEntity;
  needConstructionInfo: boolean;
  offeredQuotes: OfferedQuote[];
  prequalificationAnswers: PreQualification[];
  scheduledCategories?: ScheduledCategoryEntity[];
  yearsWithPriorCarrier: string;
  mortgages?: MortgageEntity[];
  otherStructureCoverages: OtherStructureCoverage[];
}

export interface QuoteRetrieveTenantResponse extends BaseRetrieveQuoteResponse {
  coveredLocation: CoveredLocationEntity;
  needConstructionInfo: boolean;
  offeredQuotes: OfferedQuote[];
  prequalificationAnswers: PreQualification[];
  scheduledCategories: {
    isScheduledItemsApplicable: boolean;
    name: string;
    scheduledCategoryId: string;
  }[];
  yearsWithPriorCarrier: string;
}

export interface QuoteRetrieveCondominiumResponse
  extends BaseRetrieveQuoteResponse {
  coveredLocation: CoveredLocationEntity;
  needConstructionInfo: boolean;
  offeredQuotes: OfferedQuote[];
  prequalificationAnswers: PreQualification[];
  scheduledCategories?: ScheduledCategoryEntity[];
  yearsWithPriorCarrier: string;
  mortgages?: MortgageEntity[];
}

export interface QuoteRetrieveUmbrellaResponse
  extends BaseRetrieveQuoteResponse {
  underlyingPolicies: UnderlyingPolicyEntity[];
  eligibilityInformation: EligibilityInformationEntity;
  householdMembers: unknown[];
  vehicleExposures: VehicleExposureEntity[];
  vehicleExcludedExposures: VehicleExcludedExposureEntity[];
  locationExposures: LocationExposureEntity[];
  locationExcludedExposures: LocationExcludedExposureEntity[];
  watercraftExposures: WatercraftExposureEntity[];
  watercraftExcludedExposures: WatercraftExcludedExposureEntity[];
  prequalificationAnswers: PreQualification[];
}

export interface QuoteRetrieveDwellingFireResponse
  extends BaseRetrieveQuoteResponse {
  productType: ProductType;
}

export type DriverRetrieveEntity = DriverEntity & {
  informationConfidenceLevel: 'HIGH' | 'LOW';
  isDomesticHelp: boolean;
  isInternationalLicense: boolean;
  isNewlyAddedDriver: true;
  licenseStatus: LicenseStatus;
  isPrincipalDriver: boolean;
  person: PersonResponse;
};

export function sanitizeDriverRetrieveEntity(
  input: unknown
): DriverRetrieveEntity {
  const driver = sanitizeDriverEntity(input);
  return ObjectValidator.forceSchema<DriverRetrieveEntity>(
    driver,
    {
      informationConfidenceLevel: 'string',
      isDomesticHelp: 'boolean',
      isInternationalLicense: 'boolean',
      isNewlyAddedDriver: 'boolean',
      licenseStatus: 'string',
      isPrincipalDriver: 'boolean',

      // fields copied from DriverEntity
      entityId: 'any',
      productIds: 'any',
      productType: 'any',
      quoteId: 'string',
      selected: 'boolean',
      eligibleDiscountIds: 'any',
      policyRoles: 'any',
      driverRoles: 'any',
      infractionsByProduct: 'any',
      advancedDriverTraining: 'boolean',
      advancedTrainingCourseCompletionDate: 'boolean',
      defensiveDrivingCourse: 'boolean',
      defensiveDrivingCourseDate: 'string',
      driverTraining: 'boolean',
      goodStudent: 'boolean',
      infractionDesc: 'string',
      infractionType: 'string',
      trainingCourseCompletionDate: 'string',
      // Real fields:
      ageFirstLicensed: 'number',
      associateNumber: 'string',
      driverId: 'number',
      driverOccupation: 'string',
      driverType: 'string',
      eligibleDiscounts: [sanitizeEligibleDiscountsEntity],
      employmentInformation: sanitizeEmploymentInfoEntity,
      endorsementDate: 'string',
      filings: [sanitizeFilingEntity],
      hasGraduatedCollege: 'boolean',
      hasMotorcycleEndorsement: 'boolean',
      healthCarePlan: 'string',
      infractions: [sanitizeDriverIncidentEntity],
      isFinancialFilingRequired: 'boolean',
      isLivingWithNWInsuredParent: 'boolean',
      isNationwideEmployee: 'boolean',
      isPersonalInjuryProtectionExcluded: 'boolean',
      licenseNumber: 'string',
      licenseState: 'string',
      membership: 'string',
      modifiers: [sanitizeModifierEntity],
      mtNationalGuard: 'boolean',
      nonSpecifiedGender: 'boolean',
      numberOfYearsOfBoatingExperience: 'number',
      person: sanitizePersonEntity,
      relationToPrimaryNamedInsured: 'string',
      reasonNonDriver: 'string',
      safetyCourse: 'boolean',
      sequenceNumber: 'number',
      studentAway: 'boolean',
      usArmedForces: 'boolean',
      yearsOfMotorcycleAndOffRoadExp: 'number',
    },
    [
      'entityId',
      'productIds',
      'productType',
      'quoteId',
      'selected',
      'eligibleDiscountIds',
      'policyRoles',
      'driverRoles',
      'infractionsByProduct',
      'advancedDriverTraining',
      'advancedTrainingCourseCompletionDate',
      'defensiveDrivingCourse',
      'defensiveDrivingCourseDate',
      'driverTraining',
      'goodStudent',
      'infractionDesc',
      'infractionType',
      'trainingCourseCompletionDate',
      'ageFirstLicensed',
      'associateNumber',
      'driverOccupation',
      'driverType',
      'eligibleDiscounts',
      'employmentInformation',
      'endorsementDate',
      'filings',
      'hasGraduatedCollege',
      'hasMotorcycleEndorsement',
      'healthCarePlan',
      'infractions',
      'isFinancialFilingRequired',
      'isLivingWithNWInsuredParent',
      'isNationwideEmployee',
      'isPersonalInjuryProtectionExcluded',
      'licenseNumber',
      'licenseState',
      'membership',
      'modifiers',
      'mtNationalGuard',
      'nonSpecifiedGender',
      'numberOfYearsOfBoatingExperience',
      'person',
      'relationToPrimaryNamedInsured',
      'reasonNonDriver',
      'safetyCourse',
      'sequenceNumber',
      'studentAway',
      'usArmedForces',
      'yearsOfMotorcycleAndOffRoadExp',
    ]
  );
}

export function sanitizeQuoteRetrieveResponse(
  input: unknown
): QuoteRetrieveResponse {
  return ObjectValidator.forceSchema<QuoteRetrieveResponse>(
    input,
    {
      productType: 'string',
      response: sanitizeQuoteRetrieveItem,
    },
    []
  );
}
