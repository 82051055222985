import { ObjectValidator } from '@core/helper/object-validator';
import {
  TelematicsRecommendationMessage,
  TelematicsQualifyingInformation,
  sanitizeTelematicsQualifyingInformation,
  sanitizeTelematicsRecommendationMessages,
} from '@core/store/entities/telematics/telematics.model';

export interface TelematicsRecommendationResponse {
  qualifyingInformation?: TelematicsQualifyingInformation;
  recommendedProgram?:
    | 'Mobile App Program'
    | 'Vehicle Program'
    | 'MobileAppEarlyDiscount'
    | 'None';
  messages?: TelematicsRecommendationMessage[];
}

export function sanitizeTelematicsRecommendation(
  response: unknown
): TelematicsRecommendationResponse {
  return ObjectValidator.forceSchema<TelematicsRecommendationResponse>(
    response,
    {
      qualifyingInformation: sanitizeTelematicsQualifyingInformation,
      recommendedProgram: 'string',
      messages: sanitizeTelematicsRecommendationMessages,
    },
    ['qualifyingInformation', 'recommendedProgram', 'messages']
  );
}
