import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  mergeMap,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { DocumentSelectors } from '../../selectors';
import { DocumentsActions } from '../../actions';
import { DocumentsService } from '@core/services/documents.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private documentsService: DocumentsService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  getAllDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentsActions.getAllDocuments),
        switchMap((action) =>
          this.store
            .select(DocumentSelectors.buildGetDocumentsRequests)
            .pipe(take(1))
        ),
        switchMap((requests) =>
          from(requests).pipe(
            mergeMap((request) => this.documentsService.getDocuments(request)),
            toArray(),
            tap((response) => {
              if (!response.length) {
                throw new Error('Empty documents response.');
              }
            }),
            switchMap((response) =>
              this.documentsService.updateDocumentsWithDocumentByteStream(
                response
              )
            ),
            switchMap((docEntities) => {
              const actions = docEntities.map((entity) =>
                DocumentsActions.addDocumentSuccess({ payload: entity })
              );
              return actions;
            }),
            catchError((error) => {
              error = this.errorSanitizerService.sanitizeError(
                error,
                requests[0]?.productType
              );
              return of(DocumentsActions.addDocumentError({ error }));
            })
          )
        )
      ) as Observable<Action>
  );
}
