<div class="outer-row">
  <div class="force-height" #forceHeight>
    <div class="bounds-fixture">
    <div class="horz-scroll" #horzScroll (scrollend)="onScroll($event)">
      <div class="wide-content" #wideContent>
        <ng-template *ngFor="let item of items"
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-template>
      </div>
    </div>
      <div [ngClass]="{ visible: showLeftButton }" class="fade-out left"></div>
      <div [ngClass]="{ visible: showRightButton }" class="fade-out right"></div>
    </div>
    <div class="buttons-vcenter">
    <bolt-button
      class="stepper step-left"
      [disabled]="showLeftButton ? null : 'disabled'"
      type="secondary"
      size="sm"
      (click)="onStep(-1)"
    >&lt;</bolt-button>
    <bolt-button
      class="stepper step-right"
      [disabled]="showRightButton ? null : 'disabled'"
      type="secondary"
      size="sm"
      (click)="onStep(1)"
    >&gt;</bolt-button>
    </div>
  </div>
</div>
