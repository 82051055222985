import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UwIssuesResponse } from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { UwIssuesSelectors } from '@core/store/selectors';
import { ProductsService } from '@core/services/products.service';
import { UwIssuesStoreRecord } from '@entities/uw-issues/uw-issues.reducer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ProductUtils } from '@shared/utils/product.util';
import {
  BehaviorSubject,
  map,
  NEVER,
  Observable,
  of,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { UwActivitiesService } from '@entities/uw-activities/uw-activities.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ErrorModel } from '@entities/error/error.model';
import { UwIssuesActions } from '@core/store/actions';

@Component({
  selector: 'nwx-uw-referral-modal',
  templateUrl: './uw-referral-modal.component.html',
  styleUrls: ['./uw-referral-modal.component.scss'],
})
export class UwReferralModalComponent implements OnChanges {
  // We're populated by ModalService; "@Input" here is just for show.
  @Input() productType!: ProductType;
  form: FormGroup;
  issues$: Observable<UwIssuesResponse[]>;
  productName = '';
  quoteId = '';
  disableSubmitButton$ = new BehaviorSubject(false);
  errorMessage$ = new BehaviorSubject<ErrorModel | null>(null);
  submitted$ = new Subject<boolean>();

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private store: Store,
    private productsService: ProductsService,
    private uwActivitiesService: UwActivitiesService,
    private errorSanitizerService: ErrorSanitizerService
  ) {
    this.form = this.buildForm();
    this.issues$ = NEVER;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productType) {
      this.issues$ = this.store.select(
        UwIssuesSelectors.getActionableUwIssuesForProductType(this.productType)
      );
      this.productsService
        .getProduct(this.productType)
        .pipe(take(1))
        .subscribe((product) => {
          this.productName = product?.name || '';
          this.quoteId = product?.quoteId || '';
        });
    }
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      subject: new FormControl(''),
      topic: new FormControl('General'),
      description: new FormControl(''),
    });
  }

  productIconUrl(): string {
    return ProductUtils.getImageUrlForProduct(this.productType);
  }

  onCancel(): void {
    this.ngbActiveModal.close(null);
  }

  onSubmit(): void {
    this.submitted$.next(true);
    if (!this.form.valid) {
      return;
    }
    const formValue = this.form.value;
    this.disableSubmitButton$.next(true);
    this.errorMessage$.next(null);
    this.composeActivityBody(formValue.description)
      .pipe(
        switchMap((body) =>
          this.uwActivitiesService.submitNewActivity({
            productType: this.productType,
            quoteId: this.quoteId,
            activity: {
              subject: formValue.subject,
              newNote: {
                topic: formValue.topic,
                body,
              },
            },
          })
        )
      )
      .subscribe({
        complete: () => {
          this.store.dispatch(
            UwIssuesActions.disableUwIssuesProduct({
              productType: this.productType,
            })
          );
          this.ngbActiveModal.close(null);
        },
        error: (error: any) => {
          this.disableSubmitButton$.next(false);
          this.errorMessage$.next(
            this.errorSanitizerService.sanitizeError(error, this.productType)
          );
        },
      });
  }

  private composeActivityBody(description: string): Observable<string> {
    if (this.issues$ === NEVER) {
      return of(description);
    }
    return this.issues$.pipe(
      take(1),
      map((issues) => {
        const codes = issues
          .map((issue) => {
            return issue.description.match(/\[([A-Z][A-Z0-9][0-9]+)\]/)?.[1];
          })
          .filter((s) => s);
        if (codes.length) {
          return `[${codes.join(',')}] ${description}`;
        } else {
          return description;
        }
      })
    );
  }
}
