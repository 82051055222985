import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Type,
  OnChanges,
} from '@angular/core';
import {
  DiscountEntity,
  GroupedDiscounts,
} from '@core/store/entities/discount/discount.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { SelectedCardModel } from '@shared/components/card-list/selected-card/selected-card.component';
import { DiscountsListComponent } from '@app/hub/components/discounts-list/discounts-list.component';
import { ProductType } from '@core/models/api/dsm-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscountsInfoModalComponent } from '../discounts-info-modal/discounts-info-modal.component';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Observable } from 'rxjs';
import { LayoutService } from '@core/services/layout.service';

interface DiscountsDisplayProduct {
  productType: ProductType;
  componentType: Type<{}>;
  model: SelectedCardModel;
  visible: boolean;
}

@Component({
  selector: 'nwx-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountsComponent implements OnChanges {
  @Input() discounts!: GroupedDiscounts[][];
  @Input() products!: ProductModel[];
  @Input() allDiscounts!: DiscountEntity[];

  maxSizeXSmall$: Observable<boolean>;
  discountsDisplayProducts: DiscountsDisplayProduct[] = [];
  model!: SelectedCardModel;
  formComponentType!: Type<{}>;
  staticTextHeader!: string;
  showUnratedWarning = false;

  constructor(
    private modalService: NgbModal,
    private layoutService: LayoutService
  ) {
    this.maxSizeXSmall$ = this.layoutService.maxSizeXSmall();
  }

  ngOnChanges(changes: ComponentChanges<DiscountsComponent>): void {
    if (changes.products) {
      this.addOrUpdateDiscountsDisplayProducts();
    }
    if (changes.allDiscounts) {
      this.allDiscounts = changes.allDiscounts.currentValue;
    }
    if (changes.products) {
      this.products = changes.products.currentValue;
    }
  }

  private addOrUpdateDiscountsDisplayProducts(): void {
    if (this.anyProductUnrated()) {
      this.showUnratedWarning = true;
    } else {
      this.showUnratedWarning = false;
      for (const product of this.products) {
        const ddp = this.discountsDisplayProducts.find(
          (p) => p.productType === product.type
        );
        if (ddp) {
          this.updateSelectedCardModelWithProduct(ddp.model, product);
          ddp.visible = this.productShouldDisplay(product);
        } else {
          const componentType = DiscountsListComponent;
          if (componentType) {
            this.discountsDisplayProducts.push({
              productType: product.type,
              componentType,
              model: this.selectedCardModelForProduct(product),
              visible: this.productShouldDisplay(product),
            });
          }
        }
      }
    }
  }

  private anyProductUnrated(): boolean {
    if (!this.products?.length) return true;
    for (const product of this.products) {
      if (product.quoteStatus === 'Draft') {
        return true;
      }
    }
    return false;
  }

  private productShouldDisplay(product: ProductModel): boolean {
    return !!(product.quoteId && product.quoteRated);
  }

  private selectedCardModelForProduct(
    product: ProductModel
  ): SelectedCardModel {
    const model = {
      id: product.type,
      headline: `${product.name} policy discounts`,
      unselectOption: false,
    } as SelectedCardModel<unknown>;
    this.updateSelectedCardModelWithProduct(model, product);
    return model;
  }

  private updateSelectedCardModelWithProduct(
    model: SelectedCardModel,
    product: ProductModel
  ): void {
    this.updateCardInputs(model, product);
  }

  private updateCardInputs(
    model: SelectedCardModel,
    product: ProductModel
  ): void {
    model.formComponentInputs = {
      discounts: this.findDiscountsForProduct(product.type),
      product,
    };
  }

  findDiscountsForProduct(p: ProductType): DiscountEntity[] {
    const result = this.allDiscounts.filter(
      (discount) =>
        discount.productType === p &&
        discount.isDiscountApplied === true &&
        discount.description !== 'MultiLineDiscount'
    );
    return result;
  }

  get hasMultiProductDiscounts(): boolean {
    return (
      this.allDiscounts.filter(
        (d) => d.isDiscountApplied && d.description === 'MultiLineDiscount'
      ).length > 0
    );
  }

  findMultiProductDiscounts(): DiscountEntity[] {
    let allProductIds: ProductType[] = [];
    this.products.forEach((product) =>
      allProductIds.push(product.type as ProductType)
    );
    let result = this.allDiscounts.filter(
      (discount) =>
        discount.isDiscountApplied === true &&
        discount.description == 'MultiLineDiscount' &&
        allProductIds.includes(discount.productType)
    );
    return result;
  }

  openDiscountInfoModal() {
    const modal = this.modalService.open(DiscountsInfoModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
      ariaLabelledBy: 'discountInfoModalHeader',
    });
    modal.componentInstance.products = this.products;
  }
}
