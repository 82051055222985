import { ProductType } from '@core/models/api/dsm-types';
import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  UWReportsResponse,
  sanitizeUWReportsResponses,
} from '../models/api/response/uw-reports-response.model';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { sanitizeCoveredLocation } from './covered-location.adapter';
import { ObjectValidator } from '@core/helper/object-validator';

export interface MSBUrlResponse {
  referenceReportHref: string;
}

export function sanitizeMSBUrlResponse(input: unknown): MSBUrlResponse {
  return ObjectValidator.forceSchema<MSBUrlResponse>(input, {
    referenceReportHref: 'string',
  });
}

@Injectable({
  providedIn: 'root',
})
export class UWReportsAdapter extends DsmAdapter {
  getUWReports(
    quoteId: string,
    product: ProductType
  ): Observable<UWReportsResponse[]> {
    return this.request<UWReportsResponse[]>(
      sanitizeUWReportsResponses,
      product,
      'GET',
      `/quotes/${quoteId}/uw-report-summaries`,
      'uw-reports'
    );
  }

  getMSBEstimate(
    quoteId: string,
    product: ProductType
  ): Observable<CoveredLocationEntity> {
    return this.request(
      sanitizeCoveredLocation,
      product,
      'GET',
      `/quotes/${quoteId}/uw-report-summaries/msb/estimate`,
      'uw-reports'
    );
  }

  getMSBLaunchUrl(
    quoteId: string,
    product: ProductType
  ): Observable<MSBUrlResponse> {
    return this.request<MSBUrlResponse>(
      sanitizeMSBUrlResponse,
      product,
      'GET',
      `/quotes/${quoteId}/uw-report-summaries/msb/url`,
      'uw-reports'
    );
  }
}
