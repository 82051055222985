import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { concatMap, distinctUntilChanged, filter, map, pairwise, switchMap, take, tap, withLatestFrom } from "rxjs";
import { CoveredLocationActions, EligibilityFormActions, QuoteActions, RetrieveActions, TaskActions } from "@core/store/actions";
import { EligibilityFormService } from './eligibility-form.service';
import { ProductType } from "@core/models/api/dsm-types";
import { QuoteRetrieveHomeownerResponse } from "@core/models/api/response/retrieve-response.model";
import { EligibilityDog, EligibilityFormModel, EligibilityPrequalificationAnswers, EligibilityRiskItems } from "./eligibility-form.model";
import { ModalService } from "@shared/services/modal.service";
import { ProductsService } from "@core/services/products.service";
import { Action, Store } from "@ngrx/store";
import { EligibilityFormSelectors, TaskSelectors } from "@core/store/selectors";
import { Dictionary } from "@ngrx/entity";
import { TaskModel } from "@entities/task/task.model";
import { getPageRepresentationByProductId } from "@core/constants/pages";
import { AppConfigService } from "@core/services/app-config.service";

@Injectable({
  providedIn: 'root',
})
export class EligibilityFormEffects {
  constructor(
    private actions$: Actions,
    private eligibilityFormService: EligibilityFormService,
    private modalService: ModalService,
    private productsService: ProductsService,
    private store: Store,
    private appConfigService: AppConfigService
  ) {}

  rebuildEligibilityFormOnInitiateResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.initiateNewBusinessSuccess),
      map((action) => ({
        prequalificationAnswers: action.payload.prequalificationAnswers,
        quoteId: action.payload.quoteId,
        productType: action.payload.productType,
      })),
      filter(
        (v) => !!(v.prequalificationAnswers && v.quoteId && v.productType)
      ),
      map(
        (v) =>
          ({
            quoteId: v.quoteId,
            productType: v.productType,
            prequalificationAnswers:
              this.eligibilityFormService.prequalificationAnswersToForm(
                v.prequalificationAnswers
              ),
          } as EligibilityFormModel)
      ),
      map((model) =>
        this.eligibilityFormService.redetermineInlineMessages(model)
      ),
      map((model) => EligibilityFormActions.upsertEligibilityForm({ model }))
    )
  );

  rebuildEligibilityFormOnUpdateQuoteResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.updateQuoteSuccess),
      map((action) => ({
        prequalificationAnswers: action.response.prequalificationAnswers,
        quoteId: action.response.quoteId,
        productType: action.payload,
      })),
      filter(
        (v) => !!(v.prequalificationAnswers && v.quoteId && v.productType)
      ),
      map(
        (v) =>
          ({
            quoteId: v.quoteId,
            productType: v.productType as ProductType,
            prequalificationAnswers:
              this.eligibilityFormService.prequalificationAnswersToForm(
                v.prequalificationAnswers
              ),
          } as EligibilityFormModel)
      ),
      map((model) =>
        this.eligibilityFormService.redetermineInlineMessages(model)
      ),
      map((model) => EligibilityFormActions.upsertEligibilityForm({ model }))
    )
  );

  rebuildEligibilityFormOnRetrieveResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.retrieveQuoteSuccess),
      map((action) => ({
        prequalificationAnswers: (
          action.payload.response as QuoteRetrieveHomeownerResponse
        ).prequalificationAnswers,
        coveredLocation: (
          action.payload.response as QuoteRetrieveHomeownerResponse
        ).coveredLocation,
        quoteId: action.payload.response.quoteId,
        productType: action.payload.productType,
        quoteStatus: action.payload.response.quoteStatus,
      })),
      filter(({ quoteId, productType }) => !!(quoteId && productType)),
      map(
        ({
          prequalificationAnswers,
          coveredLocation,
          quoteId,
          productType,
          quoteStatus,
        }) => {
          const model: Partial<EligibilityFormModel> = {
            quoteId,
            productType,
          };
          if (prequalificationAnswers?.length) {
            model.prequalificationAnswers =
              this.eligibilityFormService.prequalificationAnswersToForm(
                prequalificationAnswers
              );
          }
          if (coveredLocation) {
            model.riskItems = this.eligibilityFormService.coveredLocationToForm(
              productType,
              coveredLocation
            );
          }
          if (quoteStatus === 'Binding') {
            model.acknowledgement = true;
          }
          return model as EligibilityFormModel;
        }
      ),
      map((model) =>
        this.eligibilityFormService.redetermineInlineMessages(model)
      ),
      map((model) => EligibilityFormActions.upsertEligibilityForm({ model }))
    )
  );

  rebuildEligibilityFormOnCoveredLocationResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CoveredLocationActions.getCoveredLocationSuccess,
        CoveredLocationActions.updateCoveredLocationSuccess
      ),
      // CoveredLocationEntity claims to have a quoteId but that doesn't seem to be so.
      switchMap((action) =>
        this.productsService
          .getQuoteIdForProduct(action.payload.productType)
          .pipe(
            take(1),
            map(
              (quoteId) =>
                ({
                  quoteId,
                  productType: action.payload.productType,
                  riskItems: this.eligibilityFormService.coveredLocationToForm(
                    action.payload.productType,
                    action.payload
                  ),
                } as EligibilityFormModel)
            )
          )
      ),
      map((model) =>
        this.eligibilityFormService.redetermineInlineMessages(model)
      ),
      map((model) => EligibilityFormActions.upsertEligibilityForm({ model }))
    )
  );

  showEligibilityFormModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EligibilityFormActions.showEligibilityFormModal),
        tap((action) => {
          this.modalService.eligibilityModal(
            action.productType,
            action.quoteId
          );
        })
      ),
    { dispatch: false }
  );

  toggleTaskWhenEligibilityChanges$ = createEffect(() =>
    this.store.select(EligibilityFormSelectors.getAllEligibilityForms).pipe(
      map((forms) => this.filterToTaskableForms(forms)),
      pairwise(),
      map(([prev, next]) =>
        this.findFormsWithChangedAcknowledgement(prev, next)
      ),
      withLatestFrom(this.store.select(TaskSelectors.selectAllTasks)),
      switchMap(([forms, tasks]) =>
        this.generateTaskActionsForAcknowledgement(forms, tasks)
      )
    )
  );

  private filterToTaskableForms(
    forms: EligibilityFormModel[]
  ): EligibilityFormModel[] {
    return forms.filter((form) =>
      ['Homeowner', 'Tenant', 'Condominium'].includes(form.productType)
    );
  }

  private findFormsWithChangedAcknowledgement(
    prevs: EligibilityFormModel[],
    nexts: EligibilityFormModel[]
  ): EligibilityFormModel[] {
    const changed: EligibilityFormModel[] = [];
    for (const next of nexts) {
      const prev = prevs.find((f) => f.productType === next.productType);
      if (!prev || prev.acknowledgement !== next.acknowledgement) {
        changed.push(next);
      }
    }
    return changed;
  }

  private generateTaskActionsForAcknowledgement(
    forms: EligibilityFormModel[],
    tasks: TaskModel[]
  ): Action[] {
    const actions: Action[] = [];
    for (const form of forms) {
      const task = tasks.find(
        (t) =>
          t.entityType === 'eligibility' &&
          t.field === 'acknowledgement' &&
          t.productType === form.productType
      );
      const ignoreUntilFull =
        form.productType === 'Homeowner' && !form.riskItems;
      if (!task) {
        if (!form.acknowledgement && !ignoreUntilFull) {
          actions.push(this.acktionCreate(form));
        }
      } else if (task.completed && !form.acknowledgement && !ignoreUntilFull) {
        actions.push(this.acktionUncomplete(form, task));
      } else if (!task.completed && (form.acknowledgement || ignoreUntilFull)) {
        actions.push(this.acktionComplete(form, task));
      }
    }
    return actions;
  }

  private acktionCreate(form: EligibilityFormModel): Action {
    return TaskActions.addTask({
      payload: {
        page: getPageRepresentationByProductId(form.productType),
        field: 'acknowledgement',
        entityType: 'eligibility',
        productType: form.productType,
        ratingStatusOrdinal: 'Draft',
        message: 'Please review and acknowledge property risk details',
        resolve: EligibilityFormActions.showEligibilityFormModal({
          quoteId: form.quoteId,
          productType: form.productType,
        }),
        resolveLabel: 'Review property eligibility',
      },
    });
  }

  private acktionUncomplete(
    form: EligibilityFormModel,
    task: TaskModel
  ): Action {
    return TaskActions.uncompleteTask({ payload: task });
  }

  private acktionComplete(form: EligibilityFormModel, task: TaskModel): Action {
    return TaskActions.completeTask({ payload: task });
  }

  dropAcknowledgementIfAnythingElseChanges$ = createEffect(() =>
    this.store.select(EligibilityFormSelectors.getEligibilityFormEntities).pipe(
      pairwise(),
      filter(() => !this.appConfigService.config?.autofill),
      map(([prev, next]) => this.findChangedEntities(prev, next)),
      map((entities) => entities.filter((e) => e.acknowledgement)),
      concatMap((entities) =>
        entities.map((e) => {
          return EligibilityFormActions.upsertEligibilityForm({
            model: {
              ...e,
              acknowledgement: false,
            },
          });
        })
      )
    )
  );

  forceAcknowledgementWhenAutofillEnabled$ = createEffect(() =>
    this.store.select(EligibilityFormSelectors.getEligibilityFormEntities).pipe(
      filter(() => this.appConfigService.config?.autofill),
      map((entities) =>
        Object.values(entities).filter((e) => e && !e.acknowledgement)
      ),
      filter((entities) => !!entities.length),
      switchMap((entities) =>
        entities.map((e) => {
          return EligibilityFormActions.upsertEligibilityForm({
            model: {
              ...e,
              acknowledgement: true,
            },
          });
        })
      )
    )
  );

  /* Array of values from (next) either missing from (prev), or whose payload differs from (prev).
   * Values in (prev) absent from (next) are ignored.
   */
  private findChangedEntities(
    prev: Dictionary<EligibilityFormModel>,
    next: Dictionary<EligibilityFormModel>
  ): EligibilityFormModel[] {
    const changed: EligibilityFormModel[] = [];
    for (const key of Object.keys(next)) {
      const nent = next[key] as EligibilityFormModel;
      const pent = prev[key] as EligibilityFormModel;
      if (!pent || this.entityHasChanged(pent, nent)) {
        changed.push(nent);
        continue;
      }
    }
    return changed;
  }

  private entityHasChanged(
    prev: EligibilityFormModel,
    next: EligibilityFormModel
  ): boolean {
    if (next.prequalificationAnswers) {
      if (!prev.prequalificationAnswers) {
        return true;
      }
      if (
        this.prequalificationAnswersChanged(
          prev.prequalificationAnswers,
          next.prequalificationAnswers
        )
      ) {
        return true;
      }
    } else if (prev.prequalificationAnswers) {
      return true;
    }
    if (next.riskItems) {
      if (!prev.riskItems) {
        return true;
      }
      if (this.riskItemsChanged(prev.riskItems, next.riskItems)) {
        return true;
      }
    } else if (prev.riskItems) {
      return true;
    }
    return false;
  }

  private prequalificationAnswersChanged(
    prev: Partial<EligibilityPrequalificationAnswers>,
    next: Partial<EligibilityPrequalificationAnswers>
  ): boolean {
    for (const key of Object.keys(next)) {
      // All values are string, easy.
      const prevValue = prev[key as keyof EligibilityPrequalificationAnswers];
      const nextValue = next[key as keyof EligibilityPrequalificationAnswers];
      if (prevValue !== nextValue) {
        return true;
      }
    }
    return false;
  }

  private riskItemsChanged(
    prev: Partial<EligibilityRiskItems>,
    next: Partial<EligibilityRiskItems>
  ): boolean {
    for (const key of Object.keys(next)) {
      const prevValue = prev[key as keyof EligibilityRiskItems];
      const nextValue = next[key as keyof EligibilityRiskItems];
      if (key === 'dogs') {
        if (
          this.dogsChanged(
            prevValue as EligibilityDog[] | undefined,
            nextValue as EligibilityDog[] | undefined
          )
        ) {
          return true;
        }
      } else {
        // Everything else is string.
        if (prevValue !== nextValue) {
          return true;
        }
      }
    }
    return false;
  }

  private dogsChanged(
    prev: EligibilityDog[] | undefined,
    next: EligibilityDog[] | undefined
  ): boolean {
    if (!prev && !next) {
      return false;
    }
    if (!prev || !next) {
      return true;
    }
    if (prev.length !== next.length) {
      return true;
    }
    /* Have to be careful about the process here.
     * It is entirely possible to have multiple indistinguishable dogs in the list.
     * The order of dogs in the list can and will change when it bounces off DSM.
     * So we will drain the 'next' side while iterating 'prev'.
     */
    next = [...next];
    for (const pdog of prev) {
      const nindex = next.findIndex(
        (n) =>
          n.dogBreed === pdog.dogBreed &&
          n.biteHistory === pdog.biteHistory &&
          n.canineGoodCitizen === pdog.canineGoodCitizen
      );
      if (nindex < 0) {
        return true;
      }
      next.splice(nindex, 1);
    }
    return false;
  }
}
