<ng-container *ngIf="vm$ | async as vm">
  <div class="modal-header">
    <h4 id="tasksModalHeader" class="modal-title">Tasks to complete</h4>
  </div>
  <div id="blue-header" class="bolt-background-light-blue">
    <div class="bolt-row bolt-space-inner-wide-xl">
      <div class="bolt-col-9">
        <div class="flex-column">
          <div id="header-pni-name" class="bolt-subheading-lg">
            {{ pniPageModel?.pni?.person?.firstName }}
            {{ pniPageModel?.pni?.person?.lastName }}
          </div>
          <div id="header-pni-address" class="nwpii">
            {{ pniPageModel?.accountAddress?.addressLine1 }},
            {{ pniPageModel?.accountAddress?.city }}
            {{ pniPageModel?.accountAddress?.state }}
            {{ pniPageModel?.accountAddress?.postalCode }}
          </div>
          <div id="header-pni-account-label">
            Account #:
            <span id="header-pni-account-number">{{ accountId }}</span>
          </div>
        </div>
      </div>
      <div class="bolt-col-3 flex-items-center">
        <div id="header-agency-code-label">
          Agency code:
          <span id="header-agency-code">{{
            agencyState?.agent?.agencyCode
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="bolt-row">
      <div class="bolt-container">
        <div class="bolt-space-bottom-lg">
          <nwx-notification-bubble
            type="nwx-error"
            *ngIf="vm.showNoticeOfIssuance"
            class="bolt-space-bottom-lg"
          >
            <ng-container class="notification-message">
              Additional documentation may be required prior to finalizing the
              quote.
            </ng-container>
          </nwx-notification-bubble>
        </div>
        <div class="bolt-space-bottom-lg">
          <nwx-notification-bubble
            type="nwx-error"
            *ngIf="vm.showBindSuspensionWarning"
          >
            <ng-container *ngFor="let product of products">
              <ng-container *ngIf="product.hasBindSuspension">
                <div class="notification-heading">
                  <img
                    role="presentation"
                    [src]="product.imageUrl"
                    class="product-image"
                  />&nbsp;&nbsp;{{ product.name }}
                  binding suspension
                </div>
              </ng-container>
            </ng-container>
            <div class="notification-message">
              {{ bindSuspensionMessage }}
            </div>
          </nwx-notification-bubble>
        </div>
        <ng-container *ngIf="vm.showAvailabilityTasks">
          <div class="bolt-row">
            <div class="bolt-col">
              <h4 class="custom-heading">
                <span class="number-block">{{
                  stepNumbers.get('availability')
                }}</span
                >Unavailable products
              </h4>
            </div>
          </div>
          <div
            class="bolt-row nwx-task-tile bolt-space-bottom-sm"
            *ngFor="
              let task of tasks?.availabilityTasks || [];
              index as taskIndex
            "
          >
            <div class="bolt-col-8">
              {{ task.message }}
            </div>
            <div class="bolt-col-4 button-column" *ngIf="task.resolve">
              <bolt-button
                type="primary"
                attr.id="tasks-availability-button-{{ taskIndex }}"
                (click)="dispatchAction(task.resolve)"
                >{{ task.resolveLabel }}</bolt-button
              >
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="vm.showDummyProducerCodeTasks">
          <div class="bolt-row accordion" (click)="toggleDummyProducerTasks()">
            <div
              class="bolt-col-xs-9 bolt-col-sm-9 bolt-col-md-9 bolt-col-lg-9 bolt-col-10"
            >
              <h4 class="custom-heading bolt-space-bottom-md">
                <span class="number-block">{{
                  stepNumbers.get('dummyProducer')
                }}</span>
                Update producer code
              </h4>
            </div>
            <div
              class="bolt-col-xs-3 bolt-col-sm-3 bolt-col-md-3 bolt-col-lg-3 bolt-col-2 accordion-icon"
            >
              <span
                class="badge badge-required"
                *ngIf="dummyProducerIncomplete(); else dummyProducerComplete"
              >
                Task <br />
                required
              </span>
              <ng-template #dummyProducerComplete>
                <span class="badge badge-completed">
                  Task <br />
                  completed
                </span>
              </ng-template>
              <div class="icon-circle">
                <bolt-icon
                  [name]="
                    dummyProducerTasksOpen ? 'chevron-up' : 'chevron-down'
                  "
                >
                </bolt-icon>
              </div>
            </div>
          </div>
          <div
            class="bolt-row bolt-space-inner-md"
            *ngIf="dummyProducerTasksOpen && tasks?.dummyProducts?.length"
          >
            <div class="bolt-col">
              <nwx-dummy-producer-form-container
                [products]="tasks?.dummyProducts || []"
                [selectedProducts]="(selectedProducts$ | async) || []"
                [modalSubmitted]="submitted$"
                [modalClosed]="closed$"
                [agency]="agencyState"
                (formReady)="addChildForm('dummy-producer-form', $event)"
                (error)="onError($event)"
              ></nwx-dummy-producer-form-container>
            </div>
          </div>
          <hr
            *ngIf="
              vm.showReferralTasks ||
              vm.showEffectiveDateTasks ||
              vm.showMemberTasks ||
              vm.showProductTasks
            "
          />
        </ng-container>
        <ng-container *ngIf="vm.showReferralTasks">
          <div
            class="bolt-row bolt-space-bottom-md accordion"
            (click)="toggleReferralTasks()"
          >
            <div class="bolt-col-xs-9 bolt-col-sm-9 bolt-col-md-9 bolt-col-lg-9 bolt-col-10">
              <h4 class="custom-heading bolt-space-bottom-md">
                <span class="number-block">{{
                  stepNumbers.get('referral')
                }}</span>
                Referral status
              </h4>
            </div>
            <div
              class="bolt-col-xs-3 bolt-col-sm-3 bolt-col-md-3 bolt-col-lg-3 bolt-col-2 accordion-icon"
            >
              <div class="icon-circle">
                <bolt-icon
                  [name]="
                    referralTasksOpen ? 'chevron-up' : 'chevron-down'
                  "
                >
                </bolt-icon>
              </div>
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col" *ngIf="referralTasksOpen">
              <div class="bolt-container">
                <div class="bolt-row uw-activity" *ngFor="let activity of vm.referralTasks?.items">
                  <div class="bolt-col-4 uw-activity-left">
                    <bolt-icon name="info-circle-filled" color="dark-mint"></bolt-icon>
                    <div class="product-and-id">
                      <div class="product">
                        {{ activity.productName }} UW Request
                      </div>
                      <div class="id">
                        Issue ID: {{ activity.activityId }}
                      </div>
                    </div>
                  </div>
                  <div class="bolt-col-8 uw-activity-right">
                    <div class="field">
                      <span class="key">Status:</span>
                      <span class="value">{{ activity.status }}</span>
                    </div>
                    <div class="field">
                      <span class="key">Subject:</span>
                      <span class="value">{{ activity.subject }}</span>
                    </div>
                    <div class="field">
                      <span class="key">Body:</span>
                      <span class="value-body">{{ activity.body }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr *ngIf="vm.showEffectiveDateTasks || vm.showMemberTasks || vm.showProductTasks" />
        </ng-container>
        <ng-container *ngIf="vm.showEffectiveDateTasks">
          <div
            class="bolt-row bolt-space-bottom-md accordion"
            (click)="toggleEffectiveDateTasks()"
          >
            <div
              class="bolt-col-xs-9 bolt-col-sm-9 bolt-col-md-9 bolt-col-lg-9 bolt-col-10"
            >
              <h4 class="custom-heading bolt-space-bottom-md">
                <span class="number-block">{{
                  stepNumbers.get('effectiveDate')
                }}</span
                >Policy effective dates review
              </h4>
            </div>
            <div
              class="bolt-col-xs-3 bolt-col-sm-3 bolt-col-md-3 bolt-col-lg-3 bolt-col-2 accordion-icon"
            >
              <span
                class="badge badge-required"
                *ngIf="isEffectiveDateFormInvalid(); else effectiveDateComplete"
              >
                Task <br />
                required
              </span>
              <ng-template #effectiveDateComplete>
                <span class="badge badge-completed">
                  Task <br />
                  completed
                </span>
              </ng-template>
              <div class="icon-circle">
                <bolt-icon
                  [name]="
                    effectiveDateTasksOpen ? 'chevron-up' : 'chevron-down'
                  "
                >
                </bolt-icon>
              </div>
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col" *ngIf="effectiveDateTasksOpen">
              <ng-container *ngFor="let product of vm.products">
                <nwx-effective-date-form
                  [products]="vm.products || []"
                  [product]="product"
                  [submitted]="true"
                  [prefill]="prefillEffectiveDates"
                  (formReady)="
                    addChildForm(
                      product.name?.toLowerCase() + '-effectivedate',
                      $event
                    )
                  "
                  (policyRemoved)="onPolicyRemoved($event)"
                ></nwx-effective-date-form>
              </ng-container>
            </div>
          </div>
          <hr *ngIf="vm.showMemberTasks || vm.showProductTasks" />
        </ng-container>
        <ng-container *ngIf="vm.showMemberTasks">
          <div class="bolt-row accordion" (click)="toggleMemberTasks()">
            <div
              class="bolt-col-xs-9 bolt-col-sm-9 bolt-col-md-9 bolt-col-lg-9 bolt-col-10"
            >
              <h4 class="custom-heading">
                <span class="number-block">{{ stepNumbers.get('member') }}</span
                >Member tasks to complete
              </h4>
            </div>
            <div
              class="bolt-col-xs-3 bolt-col-sm-3 bolt-col-md-3 bolt-col-lg-3 bolt-col-2 accordion-icon"
            >
              <bolt-icon
                [name]="memberTasksOpen ? 'chevron-up' : 'chevron-down'"
              >
              </bolt-icon>
            </div>
          </div>
          <div class="bolt-row nwx-task-tile bolt-space-bottom-sm">
            <div class="bolt-col" [ngClass]="{ hidden: !memberTasksOpen }">
              <nwx-tasks-members-list
                [pniTasks]="tasks?.pniTasks || []"
                [memberTasks]="tasks?.memberTasks || []"
                [policyholder]="pniPageModel?.pni"
                [members]="vm.membersWithTasks || []"
                [submitted]="(submitted$ | async) || false"
                [products]="products"
                (valueChange)="onMembersChange($event)"
                (formReady)="addChildForm('policyMembers', $event)"
              >
              </nwx-tasks-members-list>
            </div>
          </div>
          <hr *ngIf="vm.showProductTasks" />
        </ng-container>
        <ng-container *ngIf="vm.showProductTasks">
          <div class="bolt-row accordion" (click)="toggleProductTasks()">
            <div class="bolt-col-11">
              <h4 class="custom-heading">
                <span class="number-block">{{
                  stepNumbers.get('product')
                }}</span
                >Product tasks to complete
                <span class="optional" *ngIf="productTasksAreOptional()">optional</span>
              </h4>
            </div>
            <div class="bolt-col-1 accordion-icon">
              <div class="icon-circle">
                <bolt-icon
                  [name]="productTasksOpen ? 'chevron-up' : 'chevron-down'"
                >
                </bolt-icon>
              </div>
            </div>
          </div>
          <div class="bolt-row nwx-task-tile bolt-space-bottom-sm">
            <div class="bolt-col" [ngClass]="{ hidden: !productTasksOpen }">
              <bolt-tabset class="products">
                <ng-container
                  *ngFor="let productType of tasks?.taskProductTypes || []"
                >
                  <bolt-tablabel>
                    <img
                      role="presentation"
                      [src]="imageUrl(productType)"
                      alt="{{ productType }} icon"
                      class="tile-img"
                    />
                    <span class="tab-label">{{
                      getProductTaskTypeDisplayName(productType)
                    }}</span>
                    <ng-container *ngIf="isProductFormInvalid(productType)"
                      ><bolt-icon
                        name="exclamation-circle-filled"
                        color="theme-error"
                        class="pl-1"
                      ></bolt-icon
                    ></ng-container>
                  </bolt-tablabel>
                  <bolt-tabpanel>
                    <nwx-tasks-property-form
                      *ngIf="isPropertyProductType(productType)"
                      [productType]="realProductTypesOnly(productType)"
                      [coveredLocation]="
                        getCoveredLocationForProduct(productType) | async
                      "
                      [propertyTasks]="tasks?.propertyTasks || []"
                      [submitted]="(submitted$ | async) || false"
                      (formReady)="addChildForm('property', $event)"
                    >
                    </nwx-tasks-property-form>
                    <nwx-tasks-vehicles-list
                      *ngIf="isVehicleProduct(productType)"
                      [vehicleTasks]="getTasksForProduct(productType)"
                      [vehicles]="
                        (getVehiclesForProduct(productType) | async) || []
                      "
                      [submitted]="(submitted$ | async) || false"
                      (valueChange)="onVehicleChange($event)"
                      (formReady)="
                        addChildForm(getFormGroupName(productType), $event)
                      "
                    >
                    </nwx-tasks-vehicles-list>
                  </bolt-tabpanel>
                </ng-container>
              </bolt-tabset>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="(submitted$ | async) || errorMessage">
          <hr />
          <bolt-notification>{{ errorMessage || 'Complete required fields above' }}</bolt-notification>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button slot="cancel" (click)="onCloseModal(true)"
        >Exit submission & return to search</bolt-button
      >
      <bolt-button slot="forward" type="primary" (click)="onSubmit()"
        >Continue</bolt-button
      >
    </bolt-button-bar>
  </div>
</ng-container>
