import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DocumentEntity,
  DocumentsEntity,
  sanitizeDocumentEntities,
} from '../store/entities/document/document.entity';
import { map } from 'rxjs/operators';
import {
  DocumentRequest,
  DocumentsRequest,
} from '@core/store/entities/document/document.selectors';
import { ProductType } from '@core/models/api/dsm-types';
import {
  PolicyDocumentResponse,
  sanitizePolicyDocumentResponse,
} from '@core/models/api/response/policy-document-response.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAdapter extends DsmAdapter {
  getDocuments(request: DocumentsRequest): Observable<DocumentsEntity> {
    return this.request<DocumentEntity[]>(
      sanitizeDocumentEntities,
      request.productType as ProductType,
      'GET',
      `/policies/${request.policyNumber}/documents?effectiveDate=${request.effectiveDate}`,
      'documents',
      {
        baseUrl: this.getPolicyManagementBaseUrl(
          request.productType as ProductType
        ),
      }
    ).pipe(
      map((response) => {
        return {
          documents: response,
          productType: request.productType as ProductType,
        };
      })
    );
  }

  getDocument(request: DocumentRequest): Observable<PolicyDocumentResponse> {
    return this.request<any>(
      sanitizePolicyDocumentResponse,
      request.productType as ProductType,
      'GET',
      `/policies/${request.policyNumber}/documents/${request.documentId}?effectiveDate=${request.effectiveDate}`,
      'policy-document',
      {
        baseUrl: this.getPolicyManagementBaseUrl(
          request.productType as ProductType
        ),
      }
    );
  }

  private getPolicyManagementBaseUrl(productType: ProductType): string {
    switch (productType) {
      case 'PersonalAuto': {
        return this.appConfigService.config.pcEdgeAutoPolicyManagementUrl;
      }
      case 'Homeowner': {
        return this.appConfigService.config.pcEdgeHomeownersPolicyManagementUrl;
      }
      case 'Condominium': {
        return this.appConfigService.config.pcEdgeCondoPolicyManagementUrl;
      }
      case 'Tenant': {
        return this.appConfigService.config.pcEdgeRentersPolicyManagementUrl;
      }
      case 'DwellingFire': {
        return this.appConfigService.config
          .pcEdgeDwellingFirePolicyManagementUrl;
      }
      case 'PersonalUmbrella': {
        return this.appConfigService.config.pcEdgeUmbrellaPolicyManagementUrl;
      }
      case 'MSA': {
        return this.appConfigService.config
          .pcEdgePowerSportsPolicyManagementUrl;
      }
      case 'Boat': {
        return this.appConfigService.config.pcEdgeBoatPolicyManagementUrl;
      }
      case 'RV': {
        return this.appConfigService.config.pcEdgeRVPolicyManagementUrl;
      }
      default: {
        return '';
      }
    }
  }
}
