export const propertyOptions = {
  numberOfStories: [
    { id: 1, label: '1', value: '1.0' },
    { id: 2, label: '1.5', value: '1.5' },
    { id: 3, label: '2', value: '2.0' },
    { id: 4, label: '2.5', value: '2.5' },
    { id: 5, label: '3', value: '3.0' },
  ],
  homeFeatures: {
    numberOfHalfBathrooms: [
      { id: 0, label: '0', value: 0 },
      { id: 1, label: '1', value: 1 },
      { id: 2, label: '2', value: 2 },
      { id: 3, label: '3', value: 3 },
      { id: 4, label: '4', value: 4 },
      { id: 5, label: '5', value: 5 },
    ],
    numberOfFullbaths: [
      { id: 1, label: '1', value: 1 },
      { id: 2, label: '2', value: 2 },
      { id: 3, label: '3', value: 3 },
      { id: 4, label: '4', value: 4 },
      { id: 5, label: '5', value: 5 },
    ],
    numberOfKitchens: [
      { id: 1, label: '1', value: 1 },
      { id: 2, label: '2', value: 2 },
      { id: 3, label: '3', value: 3 },
      { id: 4, label: '4', value: 4 },
    ],
    description: [
      { id: 1, label: 'Basic', value: 'Basic' },
      { id: 2, label: "Builder's Grade", value: "Builder's Grade" },
      { id: 3, label: 'Semi-Custom', value: 'Semi-Custom' },
      { id: 4, label: 'Custom', value: 'Custom' },
      { id: 5, label: 'Designer', value: 'Designer' },
    ],
    additionalHeating: [
      { id: 1, label: 'None', value: 'None' },
      { id: 2, label: 'Fireplace', value: 'Fireplace' },
      {
        id: 3,
        label: 'Fireplace and Portable Unit',
        value: 'FireplaceAndPortableUnit',
      },
      { id: 4, label: 'Fireplace with Insert', value: 'FireplaceWithInsert' },
      { id: 5, label: 'Franklin Stove', value: 'FranklinStove' },
      { id: 6, label: 'Freestanding Metal', value: 'FreestandingMetal' },
      { id: 7, label: 'Other Combination', value: 'OtherCombination' },
      { id: 8, label: 'Portable Unit', value: 'PortableUnit' },
    ],
  },
  homeStructuralDetails: {
    roofCondition: [
      {
        id: 1,
        label: 'Excellent',
        value: 'Excellent',
      },
      {
        id: 2,
        label: 'Good',
        value: 'Good',
      },
      {
        id: 3,
        label: 'Needs Repair',
        value: 'PoorOrNeedsRepair',
      },
      {
        id: 4,
        label: 'Severe',
        value: 'Severe_Ext',
      },
      {
        id: 5,
        label: 'Average',
        value: 'Average_Ext',
      },
      {
        id: 6,
        label: 'Poor',
        value: 'Poor_Ext',
      },
      {
        id: 7,
        label: 'No Score',
        value: 'NoScore_Ext',
      },
    ],
    roofMaterial: [
      {
        id: 1,
        label: 'Aluminum',
        value: 'Aluminium_Ext',
      },
      {
        id: 2,
        label: 'Architectural Shingle',
        value: 'ArchitecturalShingle_Ext',
      },
      {
        id: 3,
        label: 'Asphalt Rolled',
        value: 'AsphaltRolledRoof_Ext',
      },
      {
        id: 4,
        label: 'Composite Shingle',
        value: 'CompositionShingle_Ext',
      },
      {
        id: 5,
        label: 'Copper',
        value: 'Copper',
      },
      {
        id: 6,
        label: 'Corrugated Steel',
        value: 'CorrugatedSteel_Ext',
      },
      {
        id: 7,
        label: 'Foam',
        value: 'Foam_Ext',
      },
      {
        id: 8,
        label: 'Metal',
        value: 'Metal_Ext',
      },
      {
        id: 9,
        label: 'Rubber',
        value: 'Rubber_Ext',
      },
      {
        id: 10,
        label: 'Slate',
        value: 'Slate_Ext',
      },
      {
        id: 11,
        label: 'Steel Roofing',
        value: 'SteelRoofing_Ext',
      },
      {
        id: 12,
        label: 'Tar and Gravel',
        value: 'TarGravel',
      },
      {
        id: 13,
        label: 'Terne Metal',
        value: 'TerneMetal_Ext',
      },
      {
        id: 14,
        label: ' Tile, Clay',
        value: 'TileClay',
      },
      {
        id: 15,
        label: ' Tile, Spanish',
        value: 'SpanishTile_Ext',
      },
      {
        id: 16,
        label: 'Tin',
        value: 'Tin_Ext',
      },
      {
        id: 17,
        label: 'Wood Shingle',
        value: 'WoodShingle_Ext',
      },
      {
        id: 18,
        label: 'All Other',
        value: 'Other',
      },
    ],
    roofShape: [
      {
        id: 1,
        label: 'Flat',
        value: 'Flat',
      },
      {
        id: 2,
        label: 'Gable Moderate Pitch',
        value: 'GableModeratePitch',
      },
      {
        id: 3,
        label: 'Gable Slight Pitch',
        value: 'GableSlightPitch',
      },
      {
        id: 4,
        label: 'Gable Steep Pitch',
        value: 'GableSteepPitch',
      },
      {
        id: 5,
        label: 'Gambrel',
        value: 'Gambrel',
      },
      {
        id: 6,
        label: 'Hip Moderate Pitch',
        value: 'HipModeratePitch',
      },
      {
        id: 7,
        label: 'Hip Slight Pitch',
        value: 'HipSlightPitch',
      },
      {
        id: 8,
        label: 'Hip Steep Pitch',
        value: 'HipSteepPitch',
      },
      {
        id: 9,
        label: 'Mansard',
        value: 'Mansard',
      },
      {
        id: 10,
        label: 'Shed',
        value: 'Shed',
      },
      {
        id: 11,
        label: 'Turret Moderate Pitch',
        value: 'TurretModeratePitch',
      },
      {
        id: 12,
        label: 'Turret Slight Pitch',
        value: 'TurretSlightPitch',
      },
      {
        id: 13,
        label: 'Turret Steep Pitch',
        value: 'TurretSteepPitch',
      },
    ],
    constructionTypes: [
      {
        id: 1,
        label: 'Brick, Stone or Masonry',
        value: 'BrickStoneMasonry',
      },
      {
        id: 2,
        label: 'Brick, Stone or Masonry Veneer',
        value: 'BrickStoneMasonryVeneer',
      },
      {
        id: 3,
        label: 'Fire Resistive',
        value: 'FireResistive',
      },
      {
        id: 4,
        label: 'Frame',
        value: 'Frame_Ext',
      },
    ],
    exteriorWalls: [
      { id: 1, label: 'Adobe', value: 'Adobe' },
      { id: 2, label: 'Brick on frame', value: 'BrickOnFrame' },
      { id: 3, label: 'Brick, solid', value: 'BrickSolid' },
      {
        id: 4,
        label: 'Concrete block,  painted',
        value: 'ConcreteBlockPainted',
      },
      {
        id: 5,
        label: 'Concrete, poured-in-place, 4" to 6"',
        value: 'ConcretePouredInPlace4To6',
      },
      { id: 6, label: 'Siding, aluminum', value: 'SidingAluminum' },
      { id: 7, label: 'Siding, cement fiber', value: 'SidingCementFiber' },
      { id: 8, label: 'Siding, hard board', value: 'SidingHardBoard' },
      { id: 9, label: 'Siding, plywood only', value: 'SidingPlywoodOnly' },
      { id: 10, label: 'Siding, vinyl', value: 'SidingVinyl' },
      { id: 11, label: 'Siding, wood', value: 'SidingWood' },
      { id: 12, label: 'Shakes, wood', value: 'ShakesWood' },
      { id: 13, label: 'Stone on frame', value: 'StoneOnFrame' },
      { id: 14, label: 'Stucco on frame', value: 'StuccoOnFrame' },
    ],
    // exteriorWallsFourZero is NOT ALPHABETICAL SO OPTION IDS MATCH
    exteriorWallsFourZero: [
      { id: 1, label: 'Adobe', value: 'Adobe' },
      { id: 2, label: 'Brick on frame', value: 'BrickOnFrame' },
      { id: 3, label: 'Brick, solid', value: 'BrickSolid' },
      {
        id: 4,
        label: 'Concrete block,  painted',
        value: 'ConcreteBlockPainted',
      },
      {
        id: 5,
        label: 'Concrete, poured-in-place, 4" to 6"',
        value: 'ConcretePouredInPlace4To6',
      },
      { id: 6, label: 'Siding, aluminum', value: 'SidingAluminum' },
      { id: 7, label: 'Siding, cement fiber', value: 'SidingCementFiber' },
      { id: 8, label: 'Siding, hard board', value: 'SidingHardBoard' },
      { id: 9, label: 'Siding, plywood only', value: 'SidingPlywoodOnly' },
      { id: 10, label: 'Siding, vinyl', value: 'SidingVinyl' },
      { id: 11, label: 'Siding, wood', value: 'SidingWood' },
      { id: 12, label: 'Shakes, wood', value: 'ShakesWood' },
      { id: 13, label: 'Stone on frame', value: 'StoneOnFrame' },
      { id: 14, label: 'Stucco on frame', value: 'StuccoOnFrame' },
      { id: 15, label: 'Adobe with Stucco', value: 'AdobeWithStucco' },
      { id: 16, label: 'Aluminum on Masonry', value: 'AluminumOnMasonry' },
      { id: 17, label: 'Breezeway wall', value: 'BreezewayWall' },
      { id: 18, label: 'Brick Veneer', value: 'BrickOnFrame' },
      { id: 19, label: 'Brick Veneer on Masonry', value: 'BrickOnMasonry' },
      { id: 20, label: 'Faux Panel', value: 'FauxPanel' },
      { id: 21, label: 'Glass Block', value: 'GlassBlock' },
      { id: 22, label: 'Greenhouse Wall', value: 'GreenhouseWall' },
      {
        id: 23,
        label: 'Manufactured Wood Products on Masonry',
        value: 'ManufacturedWoodProductsOnMasonry',
      },
      {
        id: 24,
        label: 'Manufactured Wood Products Siding',
        value: 'ManufacturedWoodProductsSiding',
      },
      { id: 25, label: 'Siding, log', value: 'SidingLogs' },
      { id: 26, label: 'Solid Logs', value: 'LogsSolid' },
      { id: 27, label: 'Solid Stone', value: 'SolidStone' },
      { id: 28, label: 'Steel on Masonry', value: 'SteelOnMasonry' },
      { id: 29, label: 'Stone on Masonry', value: 'StoneOnMasonry' },
      { id: 30, label: 'Stone Veneer', value: '' },
      { id: 31, label: 'Stucco', value: 'Stucco' },
      { id: 32, label: 'Stucco on Masonry', value: 'StuccoOnMasonry' },
      { id: 33, label: 'Vinyl on Masonry', value: 'VinylOnMasonry' },
      { id: 34, label: 'Wood on Masonry', value: 'WoodSidingOnMasonry' },
    ],
    foundationTypes: [
      { id: 1, label: 'Slab', value: 'slab' },
      { id: 2, label: 'Crawl space', value: 'crawlSpace' },
      { id: 3, label: 'Basement', value: 'basement' },
      {
        id: 4,
        label: 'Basement walkout',
        value: 'daylightWalkoutBasement',
      },
      {
        id: 5,
        label: 'Suspended over hillside',
        value: 'suspendedOverHillside',
      },
      {
        id: 6,
        label: 'Piers Raised',
        value: 'piersRaised',
      },
    ],
    flatRoof: [
      { id: 1, label: 'Yes', value: true },
      { id: 2, label: 'No', value: false },
    ],
  },
  additionalStructures: {
    typeOfGarage: [
      { id: 1, label: 'Attached Garage', value: 'Attached Garage' },
      { id: 2, label: 'Basement Garage', value: 'Basement Garage' },
      { id: 3, label: 'Built-in Garage', value: 'Built-in Garage' },
      { id: 4, label: 'Carport', value: 'Carport' },
      { id: 5, label: 'Detached Garage', value: 'Detached Garage' },
    ],
    typeOfSmallGarage: ['Basement Garage', 'Built-in Garage', 'Carport'],
    numberOfCars: [],
    numberOfCarsNormalGarage: [
      { id: 1, label: '1 vehicle', value: '1' },
      { id: 2, label: '1.5 vehicles', value: '1.5' },
      { id: 3, label: '2 vehicles', value: '2' },
      { id: 4, label: '2.5 vehicles', value: '2.5' },
      { id: 5, label: '3 vehicles', value: '3' },
      { id: 6, label: '3.5 vehicles', value: '3.5' },
      { id: 7, label: '4 vehicles', value: '4' },
      { id: 8, label: '4.5 vehicles', value: '4.5' },
      { id: 9, label: '5 vehicles', value: '5' },
    ],
    numberOfCarsSmallGarages: [
      { id: 1, label: '1 vehicle', value: '1' },
      { id: 2, label: '1.5 vehicles', value: '1.5' },
      { id: 3, label: '2 vehicles', value: '2' },
      { id: 4, label: '2.5 vehicles', value: '2.5' },
      { id: 5, label: '3 vehicles', value: '3' },
    ],
    structures: [
      {
        id: 1,
        label: 'Open porch',
        value: 'openPorch',
      },
      {
        id: 2,
        label: 'Screen porch',
        value: 'screenPorch',
      },
      {
        id: 3,
        label: 'Sun/Solar room',
        value: 'sunSolarRoom',
      },
      {
        id: 4,
        label: 'Wood deck',
        value: 'woodDeck',
      },
      {
        id: 5,
        label: 'Breeze way',
        value: 'breezeway',
      },
    ],
  },
  tankMaterial: [
    {
      id: 1,
      label: 'Fiberglass',
      value: 'Fiberglass',
    },
    {
      id: 2,
      label: 'Steel',
      value: 'Steel',
    },
    {
      id: 3,
      label: 'Steel with Cathodic Protection',
      value: 'SteelwithCathodicProtection',
    },
    {
      id: 4,
      label: 'Other',
      value: 'Other',
    },
  ],
  tankLocation: [
    {
      id: 1,
      label: 'Indoors On Masonry Floor',
      value: 'IndoorsOnMasonryFloor',
    },
    {
      id: 2,
      label: 'Indoors On Non-Masonry Floor',
      value: 'IndoorsOnNon-MasonryFloor',
    },
    {
      id: 3,
      label: 'Outdoors Above Ground',
      value: 'OutdoorsAboveGround',
    },
    {
      id: 4,
      label: 'Outdoors Below Ground',
      value: 'OutdoorsBelowGround',
    },
  ],
  unitsInBuilding: [
    { id: 1, label: '1', value: 1 },
    { id: 2, label: '2', value: 2 },
    { id: 3, label: '3-4', value: 4 },
    { id: 4, label: '5-8', value: 8 },
    { id: 5, label: '9-16', value: 16 },
    { id: 6, label: '17+', value: 17 },
  ],
  offeringTypes: [
    { id: 1, label: 'Basic (HO-3)', value: 'HO3' },
    { id: 2, label: 'Home (HE-7)', value: 'HE7' },
    { id: 3, label: 'Expanded (HE-7 w/ HE-20)', value: 'HE7_20' },
    { id: 4, label: 'Extended (HE-7 w/ HE-21)', value: 'HE7_21' },
  ],
  hasHydrantIn1000Ft: [
    {
      id: 1,
      label: 'Yes',
      value: 'true',
    },
    {
      id: 2,
      label: 'No',
      value: 'false',
    },
  ],
  numberOfFamilies: [
    {
      id: 1,
      label: '1',
      value: '1',
    },
    {
      id: 2,
      label: '2',
      value: '2',
    },
    {
      id: 3,
      label: '3',
      value: '3',
    },
    {
      id: 4,
      label: '4',
      value: '4',
    },
  ],
};
