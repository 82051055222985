import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ProductsService } from '@core/services/products.service';
import { SortedTasks } from '@core/store/entities/task/task.model';
import { ProductType, QuoteStatus } from '@core/models/api/dsm-types';
import { filterOutUndefined } from '@shared/rxjs/filter-out-null.operator';
import { TaskService } from '@core/services/task.service';
import { RouterService } from '@core/services/router.service';
import { NextStepComponent } from '@app/hub/components/next-step/next-step.component';
import { Action, Store } from '@ngrx/store';
import { UwIssuesByProduct } from '@entities/uw-issues/uw-issues.selector';
import { UwIssuesSelectors } from '@core/store/selectors';

@Component({
  selector: 'nwx-up-next-container',
  templateUrl: './up-next-container.component.html',
  styleUrls: ['./up-next-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpNextContainerComponent implements OnDestroy {
  @ViewChild('taskList')
  taskList: ElementRef | null = null;

  @ViewChild(NextStepComponent)
  taskListComponent: NextStepComponent | null = null;

  products$: Observable<ProductModel[]>;
  tasks$: Observable<SortedTasks[][]>;
  uwIssues$: Observable<UwIssuesByProduct>;
  disabledUwProducts$: Observable<ProductType[]>;
  errorTasks$: Observable<SortedTasks[][]>;
  blockedTasks$: Observable<SortedTasks[][]>;
  reviewTasks$: Observable<SortedTasks[][]>;
  quoteStatus$: Observable<QuoteStatus>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showTasks$: Observable<boolean>;
  showPropertyEligibilityModal$: Observable<boolean>;

  constructor(
    private productsService: ProductsService,
    private taskService: TaskService,
    private routerService: RouterService,
    private store: Store
  ) {
    this.products$ = this.productsService.getSelectedProducts();
    this.tasks$ = this.taskService.getPageSortedTasks();
    this.uwIssues$ = this.store.select(
      UwIssuesSelectors.getActionableUwIssuesByProduct
    );
    this.disabledUwProducts$ = this.store.select(
      UwIssuesSelectors.getDisabledUwProducts
    );
    this.reviewTasks$ = this.taskService.getReviewTasks();
    this.errorTasks$ = this.taskService.getErrorProductsAsSortedTasks();
    this.blockedTasks$ = this.taskService.getBlockedProductsAsSortedTasks();
    this.showTasks$ = this.taskService.getShowTasks();
    this.quoteStatus$ = this.productsService
      .getOverallQuoteStatus()
      .pipe(filterOutUndefined());
    this.showPropertyEligibilityModal$ = of(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onScrollToTasks(): void {
    this.taskList?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  onClickEvent(event: Action): void {
    this.store.dispatch(event);
  }

  onTaskButtonClicked(taskPageUrl: string): void {
    this.routerService.go({ path: [taskPageUrl] });
  }

  forceFocus(): void {
    if (this.taskListComponent) {
      this.taskListComponent.showTasks = true;
      this.taskListComponent.detectChanges();
    }
    this.onScrollToTasks();
  }
}
