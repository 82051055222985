import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromPeople from '@core/store/entities/member/member.selector';
import * as fromAddress from '@core/store/entities/address/address.selector';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import * as fromPremium from '@core/store/entities/premium/premium.selectors';
import * as fromAgency from '@core/store/entities/agency/agency.selector';
import * as fromUsers from '@core/store/entities/user/user.selector';
import * as fromMortgages from '@core/store/entities/mortgage/mortgage.selector';
import * as fromBillingPlans from '@app/forms-store/store/models/billing-plans/billing-plans.selector';
import { PolicyHolderEntity } from '../policyholder/policyholder.entity';
import {
  EstablishEscrowAccountRequest,
  TermMonths,
} from '@core/models/api/request/establish-escrow-account-request.model';
import { ProductType, Role } from '@core/models/api/dsm-types';
import { AgencyModel } from '../agency/agency.model';

function digitsOnly(input: string): string {
  return (input.match(/\d+/g) || []).join('');
}

function nameFromPolicyholder(policyholder: PolicyHolderEntity): string {
  return `${policyholder.person?.firstName} ${policyholder.person?.lastName}`;
}

function formatCurrencyString(input: number): string {
  return input.toFixed(2);
}

/* eslint-disable no-magic-numbers */
function formatTermLength(input: number): TermMonths {
  if (input === 6) {
    return '006';
  } else if (input === 12) {
    return '012';
  } else {
    return '999';
  }
}

function buildAgentCode(role: Role | undefined, agency: AgencyModel): string {
  if (role === 'IA') {
    const leadingZeros = 3;
    return agency.agent.agencyCode.slice(leadingZeros) || '';
  } else {
    const trailingAgentNumber = 7;
    if (
      agency.producerCode &&
      agency.producerCode.length > trailingAgentNumber
    ) {
      return agency.producerCode.slice(
        agency.producerCode.length - trailingAgentNumber
      );
    } else {
      return agency.producerCode || '';
    }
  }
}

export const buildEstablishEscrowAccountRequest = (productType: ProductType) =>
  createSelector(
    fromCore.getCoreState,
    fromPeople.getPrimaryNamedInsuredForProduct(productType),
    fromAddress.getAddressForProduct(productType),
    fromProducts.getProduct(productType),
    fromPremium.getAllPremiums,
    fromAgency.getAgency,
    fromUsers.getEffectiveUserRole,
    (
      core,
      policyholder,
      policyAddress,
      product,
      premiums,
      agency,
      role
    ): EstablishEscrowAccountRequest => {
      const isPaperless = product?.docDelPreference === 'OnlineAccountAccess';
      const request: EstablishEscrowAccountRequest = {
        accountState: core.session.quoteState,
        paymentConfirmationPref: true,
        paymentReminderPref: true,
        paperlessPreference: isPaperless ? 'EMAL' : 'PAPR', // dependent on docDelPreference
        pbcConsentStatus: isPaperless,
        email: policyholder?.emailAddress || '',
        address: policyAddress?.addressLine1 || '',
        city: policyAddress?.city || '',
        postalCode: digitsOnly(policyAddress?.postalCode || ''),
        billingState: policyAddress?.state || '',
        CASSBypassReasonDesc: 'Use customer preferred city',
        CASSSuccess: false,
        preferredName: nameFromPolicyholder(
          policyholder || ({ policyHolderId: 0 } as PolicyHolderEntity)
        ),
        firstName: policyholder?.person?.firstName || '',
        lastName: policyholder?.person?.lastName || '',
        termMonths: formatTermLength(
          premiums.find((premium) => premium.productType === productType)
            ?.termMonths || 0
        ),
        displayPolicyNumber: product?.policyNumber || '',
        downPaymentAmount: '0.00',
        policyEffectiveDate: product?.effectiveDate || '',
        policyFinancialDivision: '02', // Independent Agent Financial Division is 02
        policyIndicator: 'N',
        policyState: core.session.quoteState,
        policyTermPremium: formatCurrencyString(
          premiums.find((premium) => premium.productType === productType)?.total
            ?.amount || 0
        ),
        productCode: '00067', // NAPS product code for Homeowners
        productGroupCode: 'FIRE',
        dayOfMonthDue: '01',
        feeAmount: '0.00',
        paymentPlan: '1P',
        producerNumber: buildAgentCode(role, agency),
        producerState: agency.agent.agentState || '',
        accountStatus: 'DB', // TODO what do these codes mean?
        accountTypeDescription: 'Flexible',
      };
      return request;
    }
  );

export const selectEscrowSelection = createSelector(
  fromBillingPlans.getBillingPlansForm,
  (form) => form.billToEscrowAccount ?? false
);

export const isEscrowOnlyPaymentMethod = createSelector(
  selectEscrowSelection,
  fromProducts.getSelectedProductsWithoutErrors,
  (isPayingThroughEscrow, products) => {
    return isPayingThroughEscrow && products?.length === 1;
  }
);

export const selectEscrowStatus = createSelector(
  fromCore.getCoreState,
  (state) => state.escrow?.status || ''
);
