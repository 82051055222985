import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { CoverageModel } from '@core/models/views/coverage.model';
import { ProductsService } from '@core/services/products.service';
import { DeleteProductService } from '@core/services/delete-product.service';
import { QuoteService } from '@core/services/quote.service';
import { RouterService } from '@core/services/router.service';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import {
  EnrolledVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '@shared/utils/date.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { Nullable } from '@shared/utils/type.utils';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { DatepickerInputComponent } from '@shared/components/datepicker-input/datepicker-input.component';
import { ComponentStoreService } from '@core/services/component-store.service';
import { ComponentStore } from '@ngrx/component-store';
import { MemberForDisplay, MemberService } from '@core/services/member.service';
import { ModalService } from '@shared/services/modal.service';
import { ErrorModel } from '@entities/error/error.model';
import { rateQuoteFail } from '@entities/quote/quote.action';

interface AutoCoverageDisplayFormModel {
  effectiveDate: Nullable<string>; // "MM/DD/YYYY"
}

interface AutoCoverageDisplayState {
  remainingCoverageCount?: number;
}

@Component({
  selector: 'nwx-auto-coverage-display',
  templateUrl: './auto-coverage-display.component.html',
  styleUrls: ['./auto-coverage-display.component.scss'],
  providers: [ComponentStoreService, ComponentStore],
})
export class AutoCoverageDisplayComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() vehicles!: VehicleEntity[];
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() product!: ProductModel;
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() editTelematics!: Subject<void>;
  @Input() maxSizeMini!: boolean;
  @Input() errors: ErrorModel[] = [];

  form = new FormGroup({
    effectiveDate: new FormControl('', [
      (control) =>
        DatepickerInputComponent.validate(
          control,
          this.policyDateOptions?.minDate || '',
          this.policyDateOptions?.maxDate || ''
        ),
    ]),
  });
  policyDateOptions: Nullable<PolicyDateOptions> = null;

  totalPolicyPremium = 0;
  termMonths = 6;
  isMobileEnrollment = false;
  remainingCoverageCount = 0;
  showDatePicker: boolean = false;
  formValueDate: any;
  overviewLimits: CoverageModel[] = [];
  limitOverviewCoverageIds = ['PD', 'BI', 'UMBI', 'RSA', 'IDFR'];
  policyHoldersForDisplay: MemberForDisplay[] = [];
  driversForDisplay: MemberForDisplay[] = [];

  vm$!: Observable<AutoCoverageDisplayState>;

  updateInProgress$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private routerService: RouterService,
    private currencyPipe: CurrencyPipe,
    private ref: ChangeDetectorRef,
    private quoteService: QuoteService,
    private ngbModal: NgbModal,
    private productsService: ProductsService,
    private readonly componentStore: ComponentStoreService<AutoCoverageDisplayState>,
    private memberService: MemberService,
    private modalService: ModalService,
    private deleteProductService: DeleteProductService
  ) {
    this.updateInProgress$ = this.quoteService
      .getQuoteUpdateInProgress()
      .pipe(map((v) => !!v));
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.updateInProgress$)
      )
      .subscribe(this.onFormChanged.bind(this));
    this.subscribeToPeople();
  }

  private subscribeToPeople(): void {
    this.memberService
      .getAllSelectedPeople()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((members) => {
        this.policyHoldersForDisplay =
          this.memberService.formatPolicyHoldersForDisplay(
            members,
            'PersonalAuto'
          );
        this.driversForDisplay = this.memberService.formatDriversForDisplay(
          members,
          'PersonalAuto'
        );
      });
  }

  ngOnInit(): void {
    this.componentStore.initialize({
      remainingCoverageCount:
        this.autoCoverages.length - this.limitOverviewCoverageIds.length,
    });
    this.vm$ = this.componentStore.get();
    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((options) => {
        this.policyDateOptions = options;
        this.ref.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.showDatePicker = false;
  }

  ngOnChanges(changes: ComponentChanges<AutoCoverageDisplayComponent>): void {
    if (
      this.errors.find(
        (e) =>
          e.productType === 'PersonalAuto' &&
          e.sourceActionType === rateQuoteFail.type
      )
    ) {
      this.totalPolicyPremium = 0;
    } else {
      this.totalPolicyPremium =
        this.premiums.find((p) => p.productType === 'PersonalAuto')?.total
          ?.amount || 0;
      this.termMonths =
        this.premiums.find((p) => p.productType === 'PersonalAuto')
          ?.termMonths || 6;
    }
    this.isMobileEnrollment = TelematicsUtils.isMobileEnrollment(
      this.enrollment
    );
    if (changes.product) {
      this.form.patchValue({
        effectiveDate: DateUtils.formatDsmDateToOld(
          this.product.effectiveDate || ''
        ),
      });
    }
  }

  ngDoCheck(): void {
    if (this.formValueDate !== this.form.value.effectiveDate) {
      this.showDatePicker = false;
      this.formValueDate = this.form.value.effectiveDate;
    }
  }

  getTelematicsEnrollmentById(vehicleId: string | undefined): string {
    let vehicleProgram = '';
    if (this.enrollment) {
      if (this.isMobileEnrollment) {
        vehicleProgram = 'SmartRide';
      } else {
        const vehicleEnrollment = this.enrollment as VehicleEnrollment;
        vehicleProgram = (
          vehicleEnrollment.vehicles.find(
            (v) =>
              v.vehicleId?.toString() === vehicleId?.toString() &&
              (v.enrollmentStatus === 'Enrolled' ||
                v.enrollmentStatus === 'VerifiedScore')
          ) as EnrolledVehicle
        )?.vehicleProgram;
      }
    }
    if (vehicleProgram === 'ConnectedCar') {
      return 'SmartRide';
    }
    if (vehicleProgram === 'SmartMilesConnectedCar') {
      return 'SmartMiles';
    }
    return vehicleProgram;
  }

  getVehiclePremiumById(vehicleId: string | undefined): number {
    const autoPremiums = this.premiums.find(
      (p) => p.productType === 'PersonalAuto'
    );
    // const vehiclePremium = autoPremiums?.vehiclePremiums?.find(
    //   (p) => p.vehicleID.toString() === vehicleId?.toString()
    // );
    const coverablePremium = autoPremiums?.coverablePremiums?.find(
      (p) => p.coverableID?.toString() === vehicleId?.toString()
    );
    let premium = 0;
    // *** Matching v1 behavior for now and not using variableMileagePremium ***
    // if (vehiclePremium) {
    //   premium = vehiclePremium.variableMileagePremium || 0;
    // } else
    if (coverablePremium) {
      premium = coverablePremium.coverableTotal || 0;
    }
    return premium / this.termMonths;
  }

  getDeductibleById(vehicleId: string | undefined, compOrColl: string): number {
    const compCov = this.coverages.find(
      (cov) => cov.coverageId === compOrColl && cov.coverableId === vehicleId
    );
    return +(compCov?.selectedValue?.[0]?.value || 0);
  }

  getCoveragesForPolicyTable(): CoverageEntity[] {
    return this.coverages
      .filter((c) => c.productId === 'PersonalAuto' && c.selectedValue)
      .filter((c) => this.limitOverviewCoverageIds.includes(c.coverageId))
      .filter((c) =>
        c.coverageCost?.find((cost) => cost?.actualTermAmount?.amount)
      )
      .filter(
        (c, i) =>
          !this.coverages.slice(0, i).find((o) => o.coverageId === c.coverageId)
      );
  }

  getCoveragesForVehicleTable(vehicleId: string): CoverageEntity[] {
    return this.coverages.filter((coverage) => {
      if (coverage.coverableId !== vehicleId) {
        return false;
      }
      if (!coverage.coverageCost?.find((c) => c?.actualTermAmount?.amount)) {
        return false;
      }
      return true;
    });
  }

  getCombinedVal(cov: CoverageEntity): string {
    const splitVals =
      cov && cov.selectedValue?.length
        ? cov.selectedValue[0]?.value.split('/')
        : [];
    const currencyVals = splitVals
      .map((val) => val?.substring(0, 3))
      .map((val) => {
        try {
          // no $ symbol for this limits section
          return this.currencyPipe.transform(val, 'USD', '', '1.0-0');
        } catch {
          return '0';
        }
      });
    const combinedVal =
      currencyVals.length === 2
        ? `${currencyVals[0]}/${currencyVals[1]}`
        : `${currencyVals[0]}`;
    return combinedVal;
  }

  get autoCoverages(): CoverageEntity[] {
    return this.coverages.filter((c) => c.productId === 'PersonalAuto');
  }

  editCoverages(event: Event): void {
    event.preventDefault();
    this.routerService.go({
      path: ['/quote/coverages'],
      extras: {
        queryParams: { product: 'PersonalAuto' },
      },
    });
  }

  emitEditTelematics(): void {
    this.editTelematics.next();
  }

  promptToRemovePolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndRemoveProduct(this.product);
  }

  promptToWithdrawPolicy(event?: Event): void {
    event?.preventDefault();
    this.deleteProductService.promptAndWithdrawProduct(this.product);
  }

  private onFormChanged(
    value: [Partial<AutoCoverageDisplayFormModel>, Nullable<boolean>]
  ): void {
    const updateInprogress = value[1] === true;
    const newDate = DateUtils.formatDateToDSM(value[0]?.effectiveDate);
    if (
      updateInprogress ||
      !this.product ||
      this.product.effectiveDate === newDate ||
      !this.form.valid
    ) {
      return;
    }
    this.productsService.updateQuote({
      productType: 'PersonalAuto',
      effectiveDate: newDate,
    });
  }

  openNamedInsuredModal(event: Event): void {
    event.preventDefault();
    this.memberService
      .getAllSelectedPeople()
      .pipe(take(1))
      .subscribe((members) => {
        this.modalService.namedInsuredModal(this.product, members);
      });
  }

  onEditDrivers(event: Event): void {
    event.preventDefault();
    this.routerService.go({ path: ['/quote/people'] });
  }

  openCoveragesModal(): void {
    this.modalService.coveragesModal(
      this.coverages,
      this.premiums,
      this.product,
      this.vehicles,
      this.enrollment
    );
  }
}
