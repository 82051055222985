import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './driver.actions';
import * as fromSelectors from '@core/store/entities/driver/driver.selectors';
import { from, Observable, of } from 'rxjs';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { DriverHelper } from '@core/helper/driver.helper';
import { DriverService } from '@core/services/driver.service';
import { MemberEntity } from '../member/member.reducer';
import { MemberSelectors } from '@core/store/selectors';
import { MemberActions } from '@core/store/actions';
import { getFeatureFlag } from '../feature-flag/feature-flag.selector';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable()
export class DriverEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: DriverService,
    private errorSanitizerService: ErrorSanitizerService,
    private driverHelper: DriverHelper,
    private productsService: ProductsService
  ) {}

  upsertDriver$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.upsertDriver),
        mergeMap((action) =>
          this.store
            .select(
              fromSelectors.buildDriverRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.addOrUpdateDriver(request).pipe(
            mergeMap((response) => {
              return from(
                this.driverHelper.getAddDriverNextActions(request, response)
              );
            }),
            catchError((error) =>
              from([
                fromActions.upsertDriverError({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    request.productType
                  ),
                  driverId: request.driver.driverId as number,
                  correlationId: request.correlationId,
                  entityId: request.driver.entityId as string,
                }),
                MemberActions.dropMemberPrefillContent({
                  payload: request.driver?.entityId?.toString() || '',
                }),
              ])
            )
          );
        })
      ) as Observable<Action>
  );

  deleteDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteDriver),
      map((actions) => ({
        payload: actions.payload,
        correlationId: actions.correlationId,
      })),
      switchMap(({ payload, correlationId }) =>
        this.store
          .select(fromSelectors.buildDriverRequest(payload, correlationId))
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.service.deleteDriver(request).pipe(
          switchMap(() => {
            return of(
              fromActions.deleteDriverSuccess({
                request,
                correlationId: request.correlationId,
              })
            );
          }),
          catchError((error) =>
            of(
              fromActions.deleteDriverError({
                error: this.errorSanitizerService.sanitizeError(
                  error,
                  request.productType
                ),
                driverId: request.driver.driverId as number,
                correlationId: request.correlationId,
              })
            )
          )
        )
      )
    )
  );

  getAllDriversForProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllDriversForProduct),
      mergeMap((action) =>
        this.productsService
          .getQuoteIdForProduct(action.productType as ProductType)
          .pipe(map((quoteId) => ({ action, quoteId })))
      ),
      mergeMap(({ action, quoteId }) =>
        this.service
          .getAllDriversForProduct(action.productType as ProductType, quoteId)
          .pipe(
            map((drivers) =>
              fromActions.getAllDriversForProductSuccess({
                drivers,
                productType: action.productType,
              })
            ),
            catchError((error) =>
              of(
                fromActions.getAllDriversForProductError({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    action.productType as ProductType
                  ),
                })
              )
            )
          )
      )
    )
  );
}
