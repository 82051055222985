import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';

@Pipe({ name: 'formatProductName' })
export class ProductNameTransformPipe implements PipeTransform {
  transform(name: ProductType): string {
    switch (name) {
      case 'PersonalAuto':
        return 'Auto';
      case 'PersonalUmbrella':
        return 'Umbrella';
    }
    return name;
  }
}
