<div
  class="coverages-outer-container"
  [formGroup]="form"
  id="policySummaryCard-{{ product.type }}"
>
  <ng-container
    *ngIf="vehicles.length && totalPolicyPremium > 0; else premiumSubstituteTop"
  >
    <div class="premium-section bolt-justify-content-center">
      <h2
        class="premium-monthly"
        attr.id="powersportsPremiumMonthly-{{ product.type }}"
      >
        {{ totalPolicyPremium / termMonths | currency
        }}<span class="per-month"><strong>&nbsp;/ month</strong></span>
      </h2>
    </div>
    <div class="bolt-row bolt-justify-content-center">
      <h3
        class="premium-total"
        attr.id="powersportsPremiumTotal-{{ product.type }}"
      >
        {{ totalPolicyPremium | currency }}
        <span class="per-month-monthly">
          &nbsp;/ {{ termMonths }} month term</span
        >
      </h3>
    </div>
    <hr />
  </ng-container>

  <div class="bolt-row effective-container">
    <div class="bolt-col-xs-12 bolt-col-sm-6">
      <div
        class="update-effectiveDate-container"
        *ngIf="!showDatePicker"
        (click)="showDatePicker = true"
      >
        <a id="mcsc-Effective-date-{{ product.type }}"
          >Effective date {{ form.value.effectiveDate }}</a
        >
        <bolt-icon
          name="pencil"
          [attr.id]="'mcsc-pencil-' + product.type"
          color="medium-blue"
          [attr.size]="maxSizeMini ? 'sm' : 'md'"
          class="icon-top-padding"
        ></bolt-icon>
      </div>
      <nwx-datepicker-input
        *ngIf="showDatePicker"
        controlId="effectiveDate"
        id="mcsc-effective-date-{{ product.type }}"
        label="Effective date"
        controlName="effectiveDate"
        placeholder="MM/DD/YYYY"
        [minDate]="policyDateOptions?.minDate || null"
        [maxDate]="policyDateOptions?.maxDate || null"
        [disabled]="(updateInProgress$ | async) || false"
        [relevantProduct]="product.type"
        [quoteCreateDate]="product.quoteCreateDate"
        [bumpHeaderMargin]="true"
      ></nwx-datepicker-input>
    </div>
    <div class="bolt-col-xs-12 bolt-col-sm-6">
      <div
        class="remove-policy-container"
        (click)="promptToRemovePolicy($event)"
        id="mcdc-remove-policy-{{ product.type }}"
      >
        <a href="#" id="mcdc-Remove-policy-{{ product.type }}">Save submission for later</a>
        <bolt-icon
          name="folder"
          [attr.id]="'mcdc-folder-' + product.type"
          color="medium-blue"
          [attr.size]="maxSizeMini ? 'sm' : 'md'"
          class="icon-top-padding"
        ></bolt-icon>
      </div>
      <div
        class="remove-policy-container"
        (click)="promptToWithdrawPolicy($event)"
        id="mcdc-withdraw-policy-{{ product.type }}"
      >
        <a href="#" id="mcdc-Withdraw-policy-{{ product.type }}">Delete submission</a>
        <bolt-icon
          name="trash"
          [attr.id]="'mcdc-trash-' + product.type"
          color="medium-blue"
          [attr.size]="maxSizeMini ? 'sm' : 'md'"
          class="icon-top-padding"
        ></bolt-icon>
      </div>
    </div>
  </div>
  <hr />

  <ng-container
    *ngIf="vehicles.length && totalPolicyPremium > 0; else premiumSubstitute"
  >
    <ng-container *ngFor="let vehicle of vehicles">
      <div
        class="details-container"
        id="vehicleCoverageDetails-{{ vehicle.vehicleId }}"
      >
        <div class="info-row heading">
          <div class="bolt-row bolt-justify-content-between">
            <div class="bolt-col-xs-xs-6">
              <strong
                >{{ vehicle.year }} {{ vehicle.make || '' | titlecase }}
                {{ vehicle.model || '' | titlecase }}
              </strong>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="bolt-row bolt-justify-content-between">
            <div class="bolt-col-xs-6 no-left-pad">
              Comprehensive deductible
            </div>
            <div class="bolt-col-xs-6" id="compDisplay-{{ vehicle.vehicleId }}">
              {{ getDeductibleById(vehicle.vehicleId, 'COMP') | currency }}
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="bolt-row bolt-justify-content-between">
            <div class="bolt-col-xs-6 no-left-pad">Collision deductible</div>
            <div class="bolt-col-xs-6" id="collDisplay-{{ vehicle.vehicleId }}">
              {{ getDeductibleById(vehicle.vehicleId, 'COLL') | currency }}
            </div>
          </div>
        </div>
        <ng-container>
          <div class="info-row">
            <div class="bolt-row bolt-justify-content-between">
              <div class="bolt-col-xs-6 no-left-pad">
                Physical Damage Settlement Option
              </div>
              <div
                class="bolt-col-xs-6"
                id="pdsoDisplay-{{ vehicle.vehicleId }}"
              >
                {{ getCovDescription(vehicle.vehicleId, 'PDSO') }}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            vehicle.productType === 'RV' &&
            vehicle.vehicleType !== 'UtilityTrailer'
          "
        >
          <div class="info-row">
            <div class="bolt-row bolt-justify-content-between">
              <div class="bolt-col-xs-6 no-left-pad">Full timers package</div>
              <div
                class="bolt-col-xs-6"
                id="ftpkgDisplay-{{ vehicle.vehicleId }}"
              >
                {{ getDeductibleById(vehicle.vehicleId, 'FTPKG') }}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            vehicle.productType === 'RV' &&
            vehicle.vehicleType !== 'UtilityTrailer'
          "
        >
          <div class="info-row">
            <div class="bolt-row bolt-justify-content-between">
              <div class="bolt-col-xs-6 no-left-pad">
                Pleasure use vacation liability
              </div>
              <div
                class="bolt-col-xs-6"
                id="vacliabDisplay-{{ vehicle.vehicleId }}"
              >
                <ng-container
                  *ngIf="
                    getAvailableById('VACLIAB', vehicle.vehicleId);
                    else vacliabUnavailable
                  "
                >
                  {{
                    getDeductibleById(vehicle.vehicleId, 'VACLIAB') | currency
                  }}
                </ng-container>
                <ng-template #vacliabUnavailable>
                  {{ getDeductibleById(vehicle.vehicleId, 'VACLIAB') }}
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            vehicle.productType === 'Boat' && vehicle.vehicleType === 'boat'
          "
        >
          <div class="info-row">
            <div class="bolt-row bolt-justify-content-between">
              <div class="bolt-col-xs-6 no-left-pad">Package</div>
              <div
                class="bolt-col-xs-6"
                id="pkgDisplay-{{ vehicle.vehicleId }}"
              >
                {{ getCovDescription(vehicle.vehicleId, 'PKG') }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <hr />
    <div class="details-container" id="limitsOverview-{{ product.type }}">
      <div class="info-row heading">
        <div class="bolt-row bolt-justify-content-between">
          <div class="bolt-col-xs-6 no-left-pad">
            <strong>Limits overview</strong>
          </div>
        </div>
      </div>
      <div
        *ngFor="let limit of overviewLimits"
        class="bolt-row bolt-justify-content-between limits-overview"
      >
        <div class="bolt-col-xs-xs-6 info-row">{{ limit.description }}</div>
        <div class="bolt-col-xs-xs-6 info-row">
          {{ limit.value }}
        </div>
      </div>
      <div class="coverages-footer">
        <div class="bolt-row">
          <div class="bolt-col-xs pb-20 no-left-pad">
            <strong class="remaining-covs"
              >... +{{ remainingCoverageCount }} more coverages</strong
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="button-container">
    <div class="bolt-row">
      <div class="bolt-col-xs">
        <bolt-button
          type="primary"
          size="sm"
          [attr.id]="'acdc-view-edit-coverage'"
          (click)="editCoverages()"
          >View/edit all coverages</bolt-button
        >
      </div>
    </div>
  </div>
</div>

<ng-template #premiumSubstituteTop>
  <div
    id="premiumSubstitute"
    class="premium-section bolt-justify-content-center"
  >
    <h2 class="premium-monthly">
      <span class="per-month"><strong>&nbsp;$ --.-- / month</strong></span>
    </h2>
  </div>
  <div class="bolt-row bolt-justify-content-center">
    <h3 class="premium-total">$ --.--</h3>
    <span class="per-month-monthly"> &nbsp;/ {{ termMonths }} month term</span>
  </div>
  <hr />
</ng-template>
<ng-template #premiumSubstitute>
  <div class="coverages-footer pb-20">
    <nwx-notification-bubble type="info">
      Rate unavailable
    </nwx-notification-bubble>
  </div>
  <hr />
  <div class="details-container-last"></div>
</ng-template>
