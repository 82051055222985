<div [ngClass]="{ 'bolt-space-bottom-lg': bottomSpacingNeeded }">
  <ng-container *ngIf="showAdvanceQuoteDiscount; else noAdvancedQuoteDiscount">
    <div
      *ngIf="showAdvanceQuoteDiscountBanner"
      [ngClass]="{ 'bump-header-margin': bumpHeaderMargin }"
    >
      <nwx-discount-badge
        name="Advanced quote discount is applied"
        [mini]="true"
      ></nwx-discount-badge>
    </div>
    <bolt-date-picker
      attr.id="{{ controlId }}-{{ relevantProduct }}"
      attr.name="{{ controlId }}-{{ relevantProduct }}"
      [label]="label"
      [min]="minDate | dateMmDdYyyy"
      [max]="maxDate | dateMmDdYyyy"
      [formControlName]="controlName"
      [attr.formControlName]="controlName"
      ngDefaultControl
      optionaltext=""
      datesonelabel="Advance discount will be applied"
      [datesone]="advancedQuoteDates"
    ></bolt-date-picker>
  </ng-container>
  <ng-template #noAdvancedQuoteDiscount>
    <bolt-date-picker
      attr.id="{{ controlId }}-{{ relevantProduct }}"
      attr.name="{{ controlId }}-{{ relevantProduct }}"
      [label]="label"
      [min]="minDate | dateMmDdYyyy"
      [max]="maxDate | dateMmDdYyyy"
      [formControlName]="controlName"
      [attr.formControlName]="controlName"
      ngDefaultControl
      optionaltext=""
    ></bolt-date-picker>
  </ng-template>
  <div *ngIf="hasError" class="faux-bolt-error" attr.id="{{ controlId }}Error">
    <bolt-icon
      class="faux-bolt-error-icon"
      name="exclamation-circle-filled"
      color="theme-error"
    ></bolt-icon>
    <span class="error-text">{{ invalidFeedbackText }}</span>
  </div>
</div>
