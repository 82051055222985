import { Injectable } from '@angular/core';
import { DsmActions, RetrieveActions } from '@core/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs';
import { UwActivitiesService } from './uw-activities.service';

@Injectable({
  providedIn: 'root',
})
export class UwActivitiesEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private uwActivitiesService: UwActivitiesService
  ) {}

  fetchActivitiesAfterRetrieve$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RetrieveActions.retrieveQuoteSuccess),
        tap((retrieveAction) =>
          this.store.dispatch(
            DsmActions.addCallInFlight({
              name: `uw-activities-${retrieveAction.payload.productType}`,
            })
          )
        ),
        mergeMap((retrieveAction) => {
          return this.uwActivitiesService
            .fetchActivities(
              retrieveAction.payload.productType,
              retrieveAction.payload.response.quoteId
            )
            .pipe(
              tap((activities) => {
                this.uwActivitiesService.storeActivities(
                  retrieveAction.payload.productType,
                  retrieveAction.payload.response.quoteId,
                  activities
                );
              }),
              catchError(() => []),
              finalize(() => {
                this.store.dispatch(
                  DsmActions.removeCallInFlight({
                    name: `uw-activities-${retrieveAction.payload.productType}`,
                  })
                );
              })
            );
        })
      ),
    { dispatch: false }
  );
}
