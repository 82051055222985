<div
  class="bolt-container-fluid help-padding"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="property"
  *ngIf="vm$ | async as vm"
>
  <div
    class="bolt-row nwx-task-tile bolt-space-bottom-xl"
    *ngIf="vm.showRoofYear"
  >
    <nwx-roof-year
      formControlName="roofYear"
      [isNssUser]="isNssUser$ | async"
      (confirmClicked)="onRoofYearConfirmed()"
    ></nwx-roof-year>
    <div class="bolt-col"></div>
  </div>

  <div
    class="bolt-row nwx-task-tile bolt-space-bottom-xl"
    *ngIf="vm.showWaterProtectionDeviceQuestions"
  >
    <div class="bolt-col">
      <nwx-leak-detection-form
        [coveredLocation]="coveredLocation || {}"
        [smartHome]="smartHomeEligibleDiscount$ | async"
        [isModal]="true"
        (coveredLocationValueChange)="
          onWaterProtectoinDeviceRequirementCommunicatedChange($event)
        "
        (smartHomeValueChange)="onSmartHomeValueChange($event)"
      ></nwx-leak-detection-form>
    </div>
  </div>

  <div
    class="bolt-row nwx-task-tile bolt-space-bottom-xl"
    *ngIf="vm.showEarthquakeQuestions"
  >
    <div class="bolt-col">
      <nwx-earthquake-details
        [earthquakeDetails]="coveredLocation?.earthquakeDetails || {}"
        [insideModal]="true"
        (formReady)="addChildForm('earthquakeDetails', $event)"
      ></nwx-earthquake-details>
    </div>
  </div>

  <div class="bolt-row nwx-task-tile" *ngIf="vm.showEligibilityQuestions">
    <div class="bolt-col">
      <nwx-eligibility-inline-container
        (statusChange)="onEligibilityStatusChange($event)"
        [productType]="productType"
      ></nwx-eligibility-inline-container>
    </div>
  </div>
</div>
