<div
  *ngFor="let product of model.products"
  class="issues-product-card bolt-row"
>
  <div class="bolt-col">
    <div class="top-row">
      <div class="text">
        <bolt-icon name="exclamation-circle-filled" color="cherry-red" size="sm"></bolt-icon>
        <div class="title">
          {{ product.productName }} UW issues
        </div>
        <div class="count">
          ({{ product.issues.length }} issues)
        </div>
      </div>
      <div class="spacer"></div>
      <div class="buttons">
        <bolt-button
          type="primary"
          (click)="onRequestUwApproval(product.productType)"
          [disabled]="product.disableApproval || null"
        >Request UW approval</bolt-button>
        <bolt-button type="secondary" (click)="onRemoveProduct(product.productType)">Remove {{ product.productName }}</bolt-button>
      </div>
    </div>
    <div
      *ngFor="let message of product.issues"
      class="message"
    >{{ message }}</div>
  </div>
</div>
