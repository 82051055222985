import { ProductsService } from '@core/services/products.service';
import { LoadingService } from '@core/services/loading.service';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar/nav-sidebar.component';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { attemptHydrate } from '@core/store/hydrate/hydrate.action';
import { Store } from '@ngrx/store';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { NavigationSelectors } from '@core/store/selectors';
import { Observable, Subject, tap } from 'rxjs';
import { Nullable } from './shared/utils/type.utils';
import { HeaderComponent } from './containers/header/header.component';
import { CompraterHeaderComponent } from './search/components/comprater-header/comprater-header.component';
import { AppConfigService } from '@core/services/app-config.service';
import { ApiCacheService } from '@core/services/api-cache.service';
import { Router } from '@angular/router';
import { LayoutService } from '@core/services/layout.service';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'nw-express-v2';

  @ViewChild('main')
  mainEl!: ElementRef;

  @ViewChild(NavSidebarComponent)
  navbar!: NavSidebarComponent;

  public appLoading$!: Observable<boolean>;
  public loadingMessage$!: Observable<{
    message: string | undefined;
    iconUrl: string;
  }>;

  currentPage$!: Observable<Nullable<Page>>;
  unsubscribe$: Subject<void> = new Subject();
  maxSizeXSmall$: Observable<boolean>;
  onSplashPage = true;
  showDebugOverlay = false;
  defaultLoadingMessage = { message: undefined, iconUrl: '' };
  dateOfLastAnnouncementSeen: any;

  constructor(
    private productService: ProductsService,
    private store: Store,
    private loadingService: LoadingService,
    private router: Router,
    private appConfigService: AppConfigService,
    private layoutService: LayoutService,
    //For API Cache Service Useage:
    // app > annotations defines @cacheable and implemented in agency.adapter
    private apiCacheService: ApiCacheService
  ) {
    this.maxSizeXSmall$ = this.layoutService.maxSizeXSmall();
  }

  ngOnInit(): void {
    this.appLoading$ = this.loadingService.isAppLoading();
    this.loadingMessage$ = this.loadingService.getLoadingMessage();
    this.currentPage$ = this.store.select(
      NavigationSelectors.selectCurrentPage
    );
    if (!this.appConfigService.isProd()) {
      this.showDebugOverlay = true;
    }

    this.store.dispatch(attemptHydrate());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  headerApplicable(page: Nullable<Page>): boolean {
    return !HeaderComponent.pagesWithoutHeader.some((p) => p?.id === page?.id);
  }

  compraterHeaderApplicable(page: Nullable<Page>): boolean {
    return CompraterHeaderComponent.pagesWithCompraterHeader.some(
      (p) => p?.id === page?.id
    );
  }
}
