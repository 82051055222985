import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';

@Pipe({
  name: 'displayValue',
})
export class DisplayValuePipe implements PipeTransform {
  transform(val: Nullable<string>): string {
    if (!val) {
      return '';
    }
    // This regex looks for a lowercase letter directly next to a capital letter
    // ex) centralAlarm would match 'lA'
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return (
      val
        .replace('_Ext', '')
        // add a space BEFORE the capital letter
        // ex) centralAlarm would become central Alarm
        .replace(regex, '$1$4 $2$3$5')
        .replace('Brick Stone Masonry Veneer', 'Brick, Stone or Masonry Veneer')
        .replace('Brick Stone Masonry', 'Brick, Stone or Masonry')
        .replace(/^(\d)ML$/, '$$$1,000,000')
    );
  }
}
