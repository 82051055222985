<form class="tasks-container">
  <div class="task-box">
    <div class="task-content-container">
      <nwx-next-step-header
        [nextStep]="nextStep$ | async"
        [showTasks]="showTasks"
        [maxSizeMedium]="maxSizeMedium$ | async"
        (clickEvent)="clickEvent.emit($event)"
      ></nwx-next-step-header>

      <div class="bolt-col-12 toggle-tasks-body" *ngIf="showTasks">
        <div class="bolt-row task-product-bar">
          <div
            class="task-product-header"
            *ngFor="let product of products; let i = index"
          >
            <img
              class="product-image"
              src="{{ product.imageUrl }}"
              alt="{{ product.type }} icon"
            />
            <h4 class="task-heading">
              {{ getStatusMessageForProduct(product) }}
            </h4>
          </div>
        </div>

        <nwx-next-step-uw-issues
          [uwIssues]="uwIssues"
          [disabledProducts]="disabledUwProducts"
        ></nwx-next-step-uw-issues>

        <!-----------  Remove Product Component ------------------------>
        <nwx-task-list
          *ngIf="errorTasks?.length"
          [tasks]="errorTasks"
          (taskButtonClicked)="onTaskButtonClicked($event)"
        ></nwx-task-list>

        <nwx-task-list
          *ngIf="blockedTasks?.length"
          [tasks]="blockedTasks"
          (taskButtonClicked)="onTaskButtonClicked($event)"
        ></nwx-task-list>

        <!-----------  Task List      ---------------------------------->

        <!-- <div
          class="bolt-col-12 align-items-center mb-3"
          *ngIf="showIncompleteError"
        >
          <bolt-notification
            >Complete these tasks before continuing to pay &
            bind.</bolt-notification
          >
        </div> -->

        <nwx-task-list
          *ngIf="tasks?.length"
          [tasks]="tasks"
          (taskButtonClicked)="onTaskButtonClicked($event)"
        ></nwx-task-list>

        <nwx-task-list
          *ngIf="reviewTasks?.length"
          [tasks]="reviewTasks"
          (taskButtonClicked)="onTaskButtonClicked($event)"
        ></nwx-task-list>
        <!-----------  Task List      ---------------------------------->
      </div>
    </div>
  </div>
</form>
