import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CurrentCarrierFormModel,
  CurrentCarrierFormOptions,
} from '@core/interfaces/interfaces';
import {
  CurrentCarrierCoverageLapse,
  MsaCurrentCarrierNames,
  ProductType,
} from '@core/models/api/dsm-types';
import { FormHelper } from '@core/helper/form-helper';
import { ComponentStoreService } from '@core/services/component-store.service';
import { getCurrentCarrierState } from '@core/store/entities/current-carrier/current-carrier.selector';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { CurrentCarrierMetadataService } from '@shared/services/current-carrier-metadata.service';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getRetrieveState } from '@core/store/retrieve/retrieve.selector';

interface CurrentCarrierFormState {
  showName?: boolean;
  showLimits?: boolean;
  showCoverageLapse?: boolean;
  showReasonForLapse?: boolean;
  carrierName?: string;
  carrierLimits?: string;
  liabilityLimitDisabled?: boolean;
  coverageLapseDisabled?: boolean;
  isAutoRetrieve?: boolean;
  displayCurrentCarrierWarning?: boolean;
}

@Component({
  selector: 'nwx-current-carrier-form',
  templateUrl: './current-carrier-form.component.html',
  styleUrls: ['./current-carrier-form.component.scss'],
  providers: [ComponentStoreService, ComponentStore],
})
export class CurrentCarrierFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() model!: Nullable<CurrentCarrierFormModel>;
  @Input() options: Nullable<CurrentCarrierFormOptions> = {};

  @Output() valueChange = new EventEmitter<CurrentCarrierFormModel>();
  @Output() formReady = new EventEmitter<AbstractControl>();

  form: FormGroup = new FormGroup({});

  vm$: Observable<CurrentCarrierFormState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<CurrentCarrierFormState>,
    private store: Store
  ) {
    this.componentStore.initialize({
      showName: false,
      showLimits: false,
      showCoverageLapse: false,
      showReasonForLapse: false,
      liabilityLimitDisabled: false,
      coverageLapseDisabled: false,
      isAutoRetrieve: false,
      displayCurrentCarrierWarning: false,
    });

    this.vm$ = this.componentStore.get();
  }

  ngOnInit(): void {
    this.form = this.buildForm(this.model || {});

    this.store
      .select(getRetrieveState)
      .pipe(
        take(1),
        tap((state) => {
          const isAutoRetrieve = !!state.entities.PersonalAuto;
          if (isAutoRetrieve) {
            const displayCurrentCarrierWarning = true;
            if (this.options) {
              this.options.showAccNoHitWarning = true;
            }
            this.componentStore.update({
              isAutoRetrieve: isAutoRetrieve,
              displayCurrentCarrierWarning: displayCurrentCarrierWarning,
            });
          }
        })
      )
      .subscribe();

    this.store
      .select(getCurrentCarrierState)
      .pipe(
        take(1),
        tap((state) => {
          if (state.isCurrentCarrierHit !== true) {
            this.componentStore.update({
              displayCurrentCarrierWarning: true,
            });
          }
        })
      )
      .subscribe();

    if (this.model) {
      this.componentStore.update({
        carrierName:
          CurrentCarrierMetadataService.getDisplayName(
            this.model?.currentCarrierName || ''
          ) || '',
        carrierLimits:
          CurrentCarrierMetadataService.getDisplayLimits(
            this.model?.currentBodilyInjuryLimit || ''
          ) || 'UNKNOWN',
      });
      // Ask Jim about this
      this.setShowName();
      // still need to ask Jim about whatever this unsavory thing is
      // this.model.productType as ProductType,
      // this.model.coverageLapse,
      // this.model.currentCarrierName || ''

      this.setShowLimits(this.model.productType || 'PersonalAuto');
      this.setShowCoverageLapseReasonForLapse(
        this.model.currentCarrierName,
        this.model.coverageLapse
      );
    }

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: CurrentCarrierFormModel) => {
        this.setShowReasonForLapse(changes.coverageLapse);
        this.valueChange.emit({
          currentCarrierName: changes.currentCarrierName,
          coverageLapse: changes.coverageLapse,
          currentBodilyInjuryLimit: changes.currentBodilyInjuryLimit,
          currentCarrierTerms: changes.currentCarrierTerms,
          reasonForCoverageLapse: changes.reasonForCoverageLapse,
          productType: this.options?.productType,
        } as CurrentCarrierFormModel);
      });

    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && this.form && this.model) {
      let reasonForCoverageLapse: string = changes?.model?.currentValue?.reasonForCoverageLapse;
        this.model.reasonForCoverageLapse = (!reasonForCoverageLapse) ? this.form?.value?.reasonForCoverageLapse : reasonForCoverageLapse;
      this.form.patchValue(this.model, { emitEvent: false });
    }
  }

  onNameChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    this.componentStore.update({
      carrierLimits:
        CurrentCarrierMetadataService.getDisplayLimits(value) || '',
    });

    if (this.model?.productType === 'PersonalAuto') {
      this.setBiLimitCoverageLapse(value);
    }
    if (this.model?.productType === 'MSA') {
      this.setMsaCoverageLapseValue(value);
    }
    this.setShowReasonForLapse(this.coverageLapse?.value);
  }

  onLimitChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.componentStore.update({
      carrierLimits:
        CurrentCarrierMetadataService.getDisplayLimits(value) || '',
    });
  }

  onCoverageLapseChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.form.get('reasonForCoverageLapse')?.setValue('');
    this.setCarrierNameBiLimit(value);
    this.setShowReasonForLapse(value);
  }

  get currentCarrierName(): AbstractControl | null {
    return this.form.get('currentCarrierName');
  }

  get currentBodilyInjuryLimit(): AbstractControl | null {
    return this.form.get('currentBodilyInjuryLimit');
  }

  get coverageLapse(): AbstractControl | null {
    return this.form.get('coverageLapse');
  }

  get reasonForCoverageLapse(): AbstractControl | null {
    return this.form.get('reasonForCoverageLapse');
  }

  private setBiLimitCoverageLapse(
    currentCarrierName: string | undefined
  ): void {
    if (
      ['NoPrevInsCarrierwoReason', 'NoPrevInsCarrwReason'].includes(
        currentCarrierName || ''
      )
    ) {
      const currentBodilyInjuryLimit = this.currentBodilyInjuryLimit;
      currentBodilyInjuryLimit?.setValue(this.options?.priorBiLimits?.[0]?.key);
      currentBodilyInjuryLimit?.updateValueAndValidity();
      const coverageLapse = this.coverageLapse;
      coverageLapse?.setValue('GT30Days');
      coverageLapse?.updateValueAndValidity();
      this.componentStore.update({
        liabilityLimitDisabled: true,
        coverageLapseDisabled: true,
      });
    } else {
      this.componentStore.update({
        liabilityLimitDisabled: false,
        coverageLapseDisabled: false,
      });
    }
  }

  private setCarrierNameBiLimit(currentCarrierName: string): void {
    if (
      [
        CurrentCarrierCoverageLapse.NoNeedForPriorInsurance,
        CurrentCarrierCoverageLapse.NoPriorInsurance,
      ].includes(currentCarrierName as CurrentCarrierCoverageLapse)
    ) {
      const carrierName = this.currentCarrierName;
      carrierName?.setValue('NoPrevInsCarrwReason');
      carrierName?.updateValueAndValidity();
      const currentBodilyInjuryLimit = this.currentBodilyInjuryLimit;
      currentBodilyInjuryLimit?.setValue(this.options?.priorBiLimits?.[0]?.key);
      currentBodilyInjuryLimit?.updateValueAndValidity();
      this.componentStore.update({
        liabilityLimitDisabled: true,
      });
    } else {
      this.componentStore.update({
        liabilityLimitDisabled: false,
      });
    }
  }

  private setCoverageLapse(value: boolean): void {
    this.componentStore.update({
      showCoverageLapse: value,
    });
    FormHelper.enableOrDisable(
      'coverageLapse',
      value,
      [Validators.required],
      this.form
    );
  }

  private setReasonForLapse(value: boolean): void {
    this.componentStore.update({
      showReasonForLapse: value,
    });
    FormHelper.enableOrDisable(
      'reasonForCoverageLapse',
      value,
      [Validators.required],
      this.form
    );
  }

  private setShowName(): // productType: ProductType,
  // coverageLapse?: Nullable<boolean>,
  // currentCarrierName?: string
  void {
    this.componentStore.update({
      showName: true,
    });

    // let showName = false;
    // if (productType === 'PersonalAuto') {
    //   showName = GeneralUtils.isBoolean(coverageLapse) ? !coverageLapse : false;
    // } else if (
    //   productType === 'MSA' ||
    //   productType === 'RV' ||
    //   productType === 'Boat'
    // ) {
    //   showName = true;
    // }

    // this.componentStore.update({
    //   showName: true,
    // });
    // FormHelper.enableOrDisable(
    //   'currentCarrierName',
    //   true,
    //   [Validators.required],
    //   this.form
    // );
    // this.setBiLimitCoverageLapse(currentCarrierName);
  }

  private setShowLimits(productId: ProductType): void {
    let showLimits = false;
    if (productId === 'PersonalAuto') {
      showLimits = true;
    }

    this.componentStore.update({
      showLimits,
    });
    FormHelper.enableOrDisable(
      'currentBodilyInjuryLimit',
      showLimits,
      [Validators.required],
      this.form
    );
  }

  private setShowCoverageLapseReasonForLapse(
    currentCarrierName: string | undefined,
    coverageLapse: string | undefined
  ): void {
    this.setCoverageLapse(true);
    this.setBiLimitCoverageLapse(currentCarrierName);
    this.setShowReasonForLapse(coverageLapse);
  }

  private setShowReasonForLapse(coverageLapse: string | undefined): void {
    if (
      [
        CurrentCarrierCoverageLapse.ZeroDays,
        CurrentCarrierCoverageLapse.ZeroDaysPrior6Months,
      ].includes(coverageLapse as CurrentCarrierCoverageLapse) ||
      !coverageLapse
    ) {
      this.setReasonForLapse(false);
    } else {
      this.setReasonForLapse(true);
    }
  }

  private setMsaCoverageLapseValue(value: string) {
    if (value === MsaCurrentCarrierNames.NoPrevInsurance) {
      if (this.model?.isNoNeedState) {
        const coverageLapse = this.coverageLapse;
        coverageLapse?.setValue(CurrentCarrierCoverageLapse.NoPriorInsurance);
        coverageLapse?.updateValueAndValidity();
        this.componentStore.update({
          coverageLapseDisabled: true,
        });
      } else {
        const coverageLapse = this.coverageLapse;
        coverageLapse?.setValue(CurrentCarrierCoverageLapse.OverThirtyDays);
        coverageLapse?.updateValueAndValidity();
        this.componentStore.update({
          coverageLapseDisabled: true,
        });
      }
    } else {
      const coverageLapse = this.coverageLapse;
      coverageLapse?.setValue('');
      coverageLapse?.updateValueAndValidity();
      this.componentStore.update({
        coverageLapseDisabled: false,
      });
    }
  }

  private hasChanges(
    a: CurrentCarrierFormModel,
    b: CurrentCarrierFormModel
  ): boolean {
    return (
      a.currentCarrierName === b.currentCarrierName &&
      a.coverageLapse === b.coverageLapse &&
      a.currentBodilyInjuryLimit === b.currentBodilyInjuryLimit &&
      a.currentCarrierTerms === b.currentCarrierTerms &&
      a.reasonForCoverageLapse === b.reasonForCoverageLapse
    );
  }

  private buildForm(currentCarrier: CurrentCarrierFormModel): FormGroup {
    return this.fb.group({
      currentCarrierName: this.fb.control(
        currentCarrier?.currentCarrierName || null,
        { validators: [Validators.required], updateOn: 'change' }
      ),
      currentBodilyInjuryLimit: this.fb.control(
        currentCarrier?.currentBodilyInjuryLimit || null,
        { validators: [], updateOn: 'change' }
      ),
      currentCarrierTerms: this.fb.control(
        currentCarrier?.currentCarrierTerms || null,
        { validators: [], updateOn: 'change' }
      ),
      coverageLapse: this.fb.control(currentCarrier?.coverageLapse || null, {
        validators: [],
        updateOn: 'change',
      }),
      reasonForCoverageLapse: this.fb.control(
        currentCarrier?.reasonForCoverageLapse || null,
        { validators: [], updateOn: 'change' }
      ),
      isNoNeedState: this.fb.control(
        currentCarrier?.isNoNeedState || false,
        {}
      ),
    });
  }
}
