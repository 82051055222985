import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import {
  MortgageRequest,
  sanitizeMortgageEntity,
  sanitizeMortgageEntities,
} from '@core/models/api/request/mortgage.request.model';
import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class MortgageAdapter extends DsmAdapter {
  addMortgage(request: MortgageRequest): Observable<MortgageEntity[]> {
    return this.request(
      sanitizeMortgageEntities,
      request.productType as ProductType,
      'POST',
      `/quotes/${request.quoteId}/mortgages`,
      'add-mortgage',
      {
        body: { mortgages: [request.mortgage] },
        productType: request.productType,
      }
    );
  }

  updateMortgage(request: MortgageRequest): Observable<MortgageEntity> {
    return this.request(
      sanitizeMortgageEntity,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/mortgages/${request.mortgage.mortgageId}`,
      'update-mortgage',
      {
        body: { mortgage: request.mortgage },
        productType: request.productType,
      }
    );
  }

  deleteMortgage(request: MortgageRequest): Observable<unknown> {
    return this.request(
      responseUnused,
      request.productType as ProductType,
      'DELETE',
      `/quotes/${request.quoteId}/mortgages/${request.mortgage.mortgageId}`,
      'delete-mortgage'
    );
  }
}
