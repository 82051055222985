import {
  isAppLoading,
  selectAppLoadingMessage,
} from '../store/loading/loading.selector';
import { Observable, of } from 'rxjs';
import {
  clearAppLoading,
  endAppLoading,
  setAppLoading,
} from '../store/loading/loading.reducer';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { SessionSelectors } from '@core/store/selectors';

export type EndLoadingCallback = (disposition?: string) => void;

const MS_PER_SEC = 1000;

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  static nextId = 0;
  static TIMEOUT_DURATION_SEC = 40;
  timeouts: {
    tid: number;
    id: number;
    serviceName: string;
    startTime: number;
  }[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  constructor(private _store: Store, private logService: LogService) {}

  private _genNewId(): number {
    LoadingService.nextId++;
    return LoadingService.nextId;
  }

  isAppLoading(): Observable<boolean> {
    return this._store.select(isAppLoading);
  }

  getLoadingMessage(): Observable<{
    message: string | undefined;
    iconUrl: string;
  }> {
    return this._store.select(selectAppLoadingMessage);
  }

  private iconUrlForServiceName(serviceName: string): string {
    switch (serviceName) {
      case 'searchquote':
        return '/assets/images/icons/search.svg';
      case 'retrievequote':
      case 'hydrate-quote':
      case 'pni-submit':
      case 'people-submit':
        return '/assets/images/nav-bar/icon-pni.svg';
      case 'Changing mortgage':
      case 'billing':
      case 'billingPlan':
        return '/assets/images/icons/money-colorized.svg';
      case 'ratequote':
      case 'rate-bind':
      case 'uw-reports':
        return '/assets/images/nav-bar/icon-finalize-quote.svg';
      case 'boat-submit':
      case 'initiate-Boat':
        return '/assets/images/nav-bar/icon-boat.svg';
      case 'driver-vehicle-prefill':
        return '/assets/images/icons/driver.svg';
      case 'property-info-resolver':
      case 'msb-estimate':
        return '/assets/images/icons/Arrow-Down-Outline.svg';
      case 'load-all-coverages':
        return '/assets/images/nav-bar/icon-coverages.svg';
      case 'scheduled-categories':
      case 'scheduled-personal-effects':
      case 'homeowner-submit':
      case 'update-covered-location':
      case 'msb-update':
      case 'initiate-Homeowner':
        return '/assets/images/nav-bar/home-nav.svg';
      case 'telematics':
      case 'update-telematics':
      case 'vehicles-submit':
      case 'initiate-PersonalAuto':
        return '/assets/images/nav-bar/icon-auto.svg';
      case 'add-umbrella':
      case 'adding-exposure':
      case 'adding-underlying-policies':
      case 'umbrella-submit':
      case 'initiate-PersonalUmbrella':
        return '/assets/images/nav-bar/icon-umbrella.svg';
      case 'search-accounts':
        return '/assets/images/icons/Link.svg';
      case 'msa-submit':
      case 'initiate-MSA':
        return '/assets/images/nav-bar/icon-motorcycle.svg';
      case 'condo-submit':
      case 'initiate-Condominium':
        return '/assets/images/nav-bar/condo-nav.svg';
      case 'tenant-submit':
      case 'initiate-Tenant':
        return '/assets/images/nav-bar/icon-renters.svg';
      case 'rv-submit':
      case 'initiate-RV':
        return '/assets/images/nav-bar/icon-rv.svg';
      case 'navigate':
        return '';
      /*
        return '/assets/images/icons/plus.svg'; // "Adding products"
      */
    }
    return '';
  }

  beginLoading(
    serviceName: string,
    message?: string,
    onTimeout?: () => void,
    overrideTimeoutSeconds?: number,
    iconUrl?: string
  ): EndLoadingCallback {
    const id = this._genNewId();
    const callSite = this._getCallSite();
    const timeoutSeconds =
      overrideTimeoutSeconds || LoadingService.TIMEOUT_DURATION_SEC;
    const startTime = Date.now();
    if (!iconUrl) {
      iconUrl = this.iconUrlForServiceName(serviceName);
    }

    this._store.dispatch(
      setAppLoading({ serviceName, message, id, callSite, iconUrl })
    );

    const timeoutId = window.setTimeout(() => {
      this.timeouts
        .map((t) => t.tid)
        .forEach((tid) => window.clearTimeout(tid));
      this.timeouts = [];
      this._store.dispatch(clearAppLoading());
      console.log(
        `A call to beginAppLoading was made which was not resolved within ${timeoutSeconds} seconds. ` +
          `Maybe an end app loading was missed?\n`,
        `Service Name: ${serviceName}\n`,
        `Call Site: ${callSite}`
      );
      this.logCompletion(startTime, serviceName, 'TIMEOUT');

      if (onTimeout) {
        onTimeout();
      }
    }, timeoutSeconds * MS_PER_SEC);
    this.timeouts.push({ tid: timeoutId, id, serviceName, startTime });

    return (disposition = 'OK') => {
      window.clearTimeout(timeoutId);
      this.timeouts.splice(
        this.timeouts.map((timeout) => timeout.tid).indexOf(timeoutId),
        1
      );
      this._store.dispatch(endAppLoading({ payload: { id } }));
      this.logCompletion(startTime, serviceName, disposition);
    };
  }

  endLoading(serviceName: string, disposition = 'OK'): void {
    const idx = this.timeouts.map((t) => t.serviceName).indexOf(serviceName);
    if (idx < 0) {
      return;
    }
    const timeout = this.timeouts[idx];
    window.clearTimeout(timeout.tid);
    this.timeouts.splice(idx, 1);
    this._store.dispatch(endAppLoading({ payload: { id: timeout.id } }));
    this.logCompletion(timeout.startTime, timeout.serviceName, disposition);
  }

  private logCompletion(
    startTime: number,
    serviceName: string,
    disposition: string
  ): void {
    const endTime = Date.now();
    const elapsedMs = endTime - startTime;
    this.logService.logBusinessEvent('loading-spinner', {
      duration: elapsedMs,
      serviceName,
      disposition,
    });
  }

  private _getCallSite(): string {
    const e = new Error();
    const stack = e.stack?.toString().split(/\r\n|\n/);
    return stack?.[3]?.trim?.() || 'unknown';
  }
}
