import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LogEventName } from '@core/models/api/log.model';
import { DriverIncidentViewModel } from '@core/models/views/driver-incident-view.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { LogService } from '@core/services/log.service';
import { NavigationService } from '@core/services/navigation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface InfractionsModalState {
  localInfraction?: Partial<DriverIncidentViewModel>;
}

@Component({
  selector: 'nwx-infractions-modal',
  templateUrl: './infractions-modal.component.html',
  styleUrl: './infractions-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class InfractionsModalComponent implements OnInit {
  @Input() infraction!: DriverIncidentViewModel;
  @Input() index!: number;
  @Input() mode: 'Edit' | 'Add' = 'Add';

  form!: FormGroup;
  submitted: boolean = false;
  vm$!: Observable<InfractionsModalState>;

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private navigationService: NavigationService,
    private readonly componentStore: ComponentStoreService<InfractionsModalState>,
    private log: LogService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({});

    this.componentStore.initialize({
      localInfraction: this.infraction,
    });
    this.vm$ = this.componentStore.get();
  }

  addChildForm(name: string, event: FormGroup) {
    this.form?.setControl(name, event);
  }

  removeInfraction(
    infraction: Partial<DriverIncidentViewModel> | undefined
  ): void {
    this.log.logUiEvent(
      'remove-infraction' as LogEventName,
      infraction,
      'navigation'
    );
    this.modal.close({
      infraction,
      action: 'Remove',
    });
  }

  cancel(): void {
    this.modal.close();
  }

  saveInfraction(
    infraction: Partial<DriverIncidentViewModel> | undefined
  ): void {
    if (this.form?.valid) {
      this.log.logUiEvent(
        'save-infraction' as LogEventName,
        infraction,
        'navigation'
      );
      this.submitted = true;
      this.modal.close({
        infraction,
        action: this.mode,
      });
    } else {
      this.navigationService.submitPage();
    }
  }

  onValueChange(value: Partial<DriverIncidentViewModel>): void {
    this.componentStore.update({
      localInfraction: value,
    });
  }
}
