import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { QuoteStatus } from '@core/models/api/dsm-types';
import { DiscountsService } from '@core/services/discounts.service';
import { ProductsService } from '@core/services/products.service';
import { TelematicsService } from '@core/services/telematics.service';
import {
  GroupedDiscounts,
  DiscountEntity,
} from '@core/store/entities/discount/discount.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import {
  VehicleEnrollment,
  MobileEnrollment,
} from '@app/core/store/entities/telematics/telematics.model';
import { Nullable } from '@app/shared/utils/type.utils';
import { UWReportsService } from '@core/services/uw-reports.service';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';

@Component({
  selector: 'nwx-review-policies-container',
  templateUrl: './review-policies-container.component.html',
  styleUrls: ['./review-policies-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewPoliciesContainerComponent implements OnInit, OnDestroy {
  @Output() scrollToTasks = new EventEmitter<void>();
  @ViewChild('boltTabGroup')
  boltTabGroup: ElementRef | null = null;

  selectedProductsWithoutErrors$: Observable<ProductModel[]>;
  discounts$: Observable<GroupedDiscounts[][]>;
  allDiscounts$: Observable<DiscountEntity[]>;
  telematics$: Observable<Nullable<VehicleEnrollment | MobileEnrollment>>;
  isTelematicsDeclined$: Observable<boolean>;
  telematicsTabActive$: Observable<void>;
  quoteStatus$: Observable<QuoteStatus>;
  isAutoProductSelected$: Observable<boolean>;
  uwReportsLoaded$: Observable<boolean>;
  isMSAOnlyProductSelected$: Observable<boolean>;
  reportsAndUWApplicable$: Observable<boolean>;

  unsubscribe$ = new Subject<void>();

  telematicsTabIndex = 2;

  constructor(
    private telematicsService: TelematicsService,
    private productsService: ProductsService,
    private discountsService: DiscountsService,
    private uwReportsService: UWReportsService
  ) {
    this.selectedProductsWithoutErrors$ =
      this.productsService.getSelectedQuoteProductsWithoutErrors();
    this.discounts$ = this.discountsService.getGroupedSelectedDiscounts();
    this.allDiscounts$ = this.discountsService.getAllDiscounts();
    this.telematics$ = this.telematicsService.getCurrentEnrollment();
    this.isTelematicsDeclined$ = this.telematicsService.isTelematicsDeclined();
    this.telematicsTabActive$ = this.telematicsService.getTelematicsTabActive();
    this.quoteStatus$ = this.productsService.getOverallQuoteStatus();
    this.isAutoProductSelected$ =
      this.productsService.isProductSelected('PersonalAuto');
    this.isMSAOnlyProductSelected$ =
      this.productsService.isOnlyProductSelected('MSA');
    this.uwReportsLoaded$ = this.uwReportsService.uwReportsLoaded();
    this.reportsAndUWApplicable$ = productsService.reportsAndUWApplicable();
  }

  ngOnInit(): void {
    this.telematicsTabActive$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.setTelematicsTabActive());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTabChange(event: any): void {
    if (+event.detail?.tabIndex === this.telematicsTabIndex) {
      this.setTelematicsReviewed();
    }
  }

  setTelematicsTabActive(): void {
    if (this.boltTabGroup?.nativeElement) {
      this.boltTabGroup.nativeElement.activetab = this.telematicsTabIndex;
      (this.boltTabGroup.nativeElement as HTMLElement).scrollIntoView({
        behavior: 'smooth',
      });
    }
    this.setTelematicsReviewed();
  }

  setTelematicsReviewed(): void {
    this.telematicsService.dispatchUpdateTelematicsReviewed(true);
  }
}
