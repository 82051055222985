import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { TelematicsService } from '@core/services/telematics.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromActions from './telematics.action';
import * as fromVehicleActions from '@core/store/entities/vehicle/vehicle.action';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { of, from, combineLatest, Observable } from 'rxjs';
import { TelematicsAdapter } from '@core/adapters/telematics.adapter';
import { ProductsService } from '@core/services/products.service';
import {
  EnrolledVehicle,
  NotEnrolledVehicle,
  VehicleEnrollment,
} from './telematics.model';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { updateTelematicsTextNotification } from './telematics.action';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { rebuildTasks } from '../task/task.action';
import { retrieveQuoteSuccess } from '../../retrieve/retrieve.action';
import { QuoteRetrievePersonalAutoResponse } from '@core/models/api/response/retrieve-response.model';
import { TELEMATICS_MILES_THRESHOLD } from '@shared/constants/app-constants';
import { VehicleService } from '@core/services/vehicle.service';
import {
  getCurrentRecommendation,
  getTelematicsReviewed,
  isSmartMilesEnrolled,
  isTelematicsDeclined,
} from './telematics.selector';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import { getBillingPlan } from '@forms-store/store/models/billing-plans/billing-plans.selector';
import {
  getDocumentDeliveryPreference,
  getSelectedProducts,
  isPersonalAutoSelected,
} from '../product/product.selectors';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { RetrieveActions, TelematicsActions } from '@core/store/actions';
import { ModalService } from '@shared/services/modal.service';

@Injectable()
export class TelematicsEffect {
  constructor(
    private actions$: Actions,
    private adapter: TelematicsAdapter,
    private telematicsService: TelematicsService,
    private productsService: ProductsService,
    private vehicleService: VehicleService,
    private errorSanitizerService: ErrorSanitizerService,
    private store: Store,
    private modalService: ModalService
  ) {}

  getTelematicsRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTelematicsRecommendation),
      switchMap((action) =>
        this.telematicsService.composeTelematicsRecommendationRequest().pipe(
          take(1),
          map((request) => ({ request, action }))
        )
      ),
      switchMap(({ request, action }) =>
        this.adapter.getTelematicsRecommendation(request).pipe(
          take(1),
          switchMap((response) => {
            const actions: Action[] = [
              fromActions.getTelematicsRecommendationSuccess({
                payload: response,
              }),
            ];
            if (
              response.recommendedProgram &&
              !action.dontAutoApply &&
              response.recommendedProgram !== 'None'
            ) {
              actions.push(
                fromActions.addTelematicsEnrollment({ payload: response })
              );
            } else {
              actions.push(fromActions.clearTelematicsLoading());
            }
            return from(actions);
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.getTelematicsRecommendationFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  addTelematicsEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTelematicsEnrollment),
      switchMap((action) =>
        this.telematicsService
          .composeAddTelematicsRequest(action.payload)
          .pipe(take(1))
      ),
      withLatestFrom(
        this.vehicleService.getSavedVehiclesByProductType('PersonalAuto')
      ),
      map(([request, vehicles]) =>
        this.telematicsService.sanitizeTelematicsRequest(request, vehicles)
      ),
      switchMap((request) =>
        this.adapter.addTelematicsEnrollment(request).pipe(
          take(1),
          switchMap((response) => {
            return of(
              fromActions.addTelematicsEnrollmentSuccess({
                payload: response,
              })
            );
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.addTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  getTelematicsEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTelematicsEnrollment),
      switchMap(() =>
        this.productsService.getProduct('PersonalAuto').pipe(take(1))
      ),
      switchMap((product) =>
        this.adapter.getTelematicsEnrollment(product?.quoteId).pipe(
          take(1),
          switchMap((response) => {
            if (response.mobileEnrollment || response.vehicleEnrollment) {
              const missedVehicleActions: Action[] = [];
              const missedVehicles =
                response.vehicleEnrollment?.vehicles.filter(
                  (v) => !v.enrollmentStatus
                );
              missedVehicles?.forEach((v) =>
                missedVehicleActions.push(
                  fromActions.updateRecommendationForVehicle({
                    vehicle: v as unknown as VehicleEntity,
                  })
                )
              );

              return from([
                fromActions.getTelematicsEnrollmentSuccess({
                  payload: response,
                }),
                ...missedVehicleActions,
              ]);
            } else {
              return of(fromActions.getTelematicsRecommendation({}));
            }
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.getTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  refreshTelematicsEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refreshTelematicsEnrollment),
      withLatestFrom(
        this.productsService.getProduct('PersonalAuto'),
        this.telematicsService.getCurrentEnrollment()
      ),
      switchMap(([action, product, previous]) =>
        this.adapter.getTelematicsEnrollment(product?.quoteId).pipe(
          take(1),
          map((response) =>
            fromActions.refreshTelematicsEnrollmentSuccess({
              payload: {
                response,
                previous,
                quoteId: product?.quoteId || '',
              },
            })
          ),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.refreshTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  // See CODEMINERS-4082, sometimes updateVehicle blanks vehicleProgramType in the telematics enrollment.
  updateTelematicsIfDsmMysteriouslyDropsProgramType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refreshTelematicsEnrollmentSuccess),
      map((action) =>
        this.telematicsService.composeUpdateTelematicsRequestIfProgramTypeDropped(
          action.payload.previous,
          action.payload.response,
          action.payload.quoteId
        )
      ),
      filterOutNull(),
      withLatestFrom(
        this.vehicleService.getSavedVehiclesByProductType('PersonalAuto')
      ),
      map(([request, vehicles]) =>
        this.telematicsService.sanitizeTelematicsRequest(request, vehicles)
      ),
      switchMap((request) =>
        this.adapter.updateTelematicsEnrollment(request).pipe(
          map((response) =>
            fromActions.updateTelematicsEnrollmentSuccess({
              payload: response,
            })
          ),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.updateTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  updateTelematicsEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTelematicsEnrollment),
      map((action) => action.payload),
      concatMap((payload) =>
        this.telematicsService
          .composeUpdateTelematicsRequest(payload)
          .pipe(take(1))
      ),
      filterOutNull(),
      withLatestFrom(
        this.vehicleService.getSavedVehiclesByProductType('PersonalAuto')
      ),
      map(([request, vehicles]) =>
        this.telematicsService.sanitizeTelematicsRequest(request, vehicles)
      ),
      concatMap((request) => {
        if (request) {
          return this.adapter.updateTelematicsEnrollment(request).pipe(
            switchMap((response) => {
              return of(
                fromActions.updateTelematicsEnrollmentSuccess({
                  payload: response,
                })
              );
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                'PersonalAuto'
              );
              return of(
                fromActions.updateTelematicsEnrollmentFail({
                  payload: saneError,
                })
              );
            })
          );
        } else {
          return of(fromActions.clearTelematicsLoading());
        }
      })
    )
  );

  updateTelematicsTextNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTelematicsTextNotification),
      map((action) => action.payload),
      switchMap((payload) =>
        this.telematicsService
          .composeUpdateTelematicsTextNotificationRequest(payload)
          .pipe(take(1))
      ),
      withLatestFrom(
        this.vehicleService.getSelectedVehiclesByProductType('PersonalAuto')
      ),
      map(([request, vehicles]) =>
        this.telematicsService.sanitizeTelematicsRequest(request, vehicles)
      ),
      switchMap((request) =>
        this.adapter.updateTelematicsEnrollment(request).pipe(
          switchMap((response) => {
            return of(
              fromActions.updateTelematicsTextNotificationSuccess({
                payload: response,
              })
            );
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.updateTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  removeTelematicsEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeTelematicsEnrollment),
      switchMap(() =>
        this.telematicsService.composeRemoveTelematicsRequest().pipe(take(1))
      ),
      map((request) =>
        this.telematicsService.sanitizeTelematicsRequest(request)
      ),
      switchMap((request) =>
        this.adapter.updateTelematicsEnrollment(request).pipe(
          switchMap((response) => {
            return of(
              fromActions.updateTelematicsEnrollmentSuccess({
                payload: response,
              })
            );
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.removeTelematicsEnrollmentFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  removeVehicleFromTelematics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeVehicleFromTelematics),
      switchMap((action) =>
        this.telematicsService.composeRemoveVehicleFromTelematicsRequest(
          action.payload
        )
      ),
      map((request) =>
        this.telematicsService.sanitizeTelematicsRequest(request)
      ),
      switchMap((request) =>
        this.adapter.updateTelematicsEnrollment(request).pipe(
          map((response) =>
            fromActions.removeVehicleFromTelematicsSuccess({
              payload: response,
            })
          ),
          catchError((error) =>
            of(fromActions.removeVehicleFromTelematicsFail())
          )
        )
      )
    )
  );

  resetTelematicsEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetTelematicsEnrollment),
      switchMap(() =>
        combineLatest([
          this.productsService.getProduct('PersonalAuto'),
          this.telematicsService.getCurrentEnrollment(),
          this.telematicsService.getCurrentRecommendation(),
        ]).pipe(take(1))
      ),
      filter(([product, enrollment, recommendation]) => !!product?.quoteId),
      switchMap(([product, enrollment, recommendation]) => {
        if (enrollment?.enrollmentId) {
          return this.adapter
            .deleteTelematicsEnrollment(
              product!.quoteId as string,
              enrollment.enrollmentId
            )
            .pipe(
              map((response) => ({ error: null, recommendation })),
              catchError((error) => {
                const saneError = this.errorSanitizerService.sanitizeError(
                  error,
                  'PersonalAuto'
                );
                return of({
                  error: fromActions.removeTelematicsEnrollmentFail({
                    payload: saneError,
                  }),
                  recommendation: null,
                });
              })
            );
        } else {
          return of({ error: null, recommendation });
        }
      }),
      switchMap(({ error, recommendation }) => {
        if (recommendation && recommendation.recommendedProgram !== 'None') {
          return of(
            fromActions.addTelematicsEnrollment({
              payload: recommendation as TelematicsRecommendationResponse,
            })
          );
        } else if (error) {
          return of(error);
        } else {
          return from([]);
        }
      })
    )
  );

  addNewVehicleToTelematics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromVehicleActions.addVehicleSuccess),
      filter((action) => action.payload.productType === 'PersonalAuto'),
      withLatestFrom(this.telematicsService.getCurrentEnrollment()),
      filter(([action, enrollment]) => !!enrollment?.enrollmentId),
      filter(
        ([action, enrollment]) =>
          !(enrollment as VehicleEnrollment).vehicles?.find(
            (v) => +v.vehicleId === +(action.payload.vehicleId || 0)
          ) || TelematicsUtils.isMobileEnrollment(enrollment)
      ),
      map(([action]) =>
        fromActions.updateRecommendationForVehicle({
          vehicle: action.payload,
        })
      )
    )
  );

  removeDeletedVehicleFromTelematics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromVehicleActions.deleteVehicleSuccess),
      withLatestFrom(this.telematicsService.getCurrentEnrollment()),
      filter(
        ([action, enrollment]) =>
          !!(enrollment as VehicleEnrollment)?.vehicles?.find(
            (v) => v.vehicleId === action.payload
          )
      ),
      map(([action, enrollment]) =>
        fromActions.removeVehicleFromTelematics({ payload: +action.payload })
      )
    )
  );

  updateTasksOnTelematicsChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.addTelematicsEnrollmentSuccess,
        fromActions.updateTelematicsEnrollmentSuccess
      ),
      switchMap(() =>
        this.productsService.getProductStatus('PersonalAuto').pipe(take(1))
      ),
      switchMap((quoteStatus) => {
        if (quoteStatus !== 'Draft' && quoteStatus !== 'Pending') {
          return from([
            fromActions.updateTelematicsConfirmation({ payload: false }),
            rebuildTasks(),
          ]);
        } else {
          return of(null);
        }
      }),
      filterOutNull()
    )
  );

  updateMobileEnrollmentAfterRetrieve$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(retrieveQuoteSuccess),
        filter((action) => action.payload.productType === 'PersonalAuto'),
        map((action) => {
          // Our type MobileEnrollment disagrees with the format actually returned by retrieve, hence "any".
          // I don't know which type is correct.
          return {
            enrollment: (
              action.payload.response as QuoteRetrievePersonalAutoResponse
            ).telematicsEnrollments?.[0] as any,
            phone: action.payload.response.policyHolders?.[0]?.homeNumber,
            quoteId: action.payload.response.quoteId,
          };
        }),
        filter(
          ({ enrollment, phone, quoteId }) =>
            !!(enrollment?.mobileEnrollment && phone && quoteId)
        ),
        filter(
          ({ enrollment }) =>
            !enrollment.mobileEnrollment.phoneNumber ||
            !enrollment.mobileEnrollment.hasConsentToTextActivation
        ),
        withLatestFrom(
          this.vehicleService.getSavedVehiclesByProductType('PersonalAuto')
        ),
        switchMap(([{ enrollment, phone, quoteId }, vehicles]) => {
          return this.adapter
            .updateTelematicsEnrollment(
              this.telematicsService.sanitizeTelematicsRequest(
                {
                  quoteId,
                  enrollment: {
                    vehicleEnrollment: null,
                    mobileEnrollment: {
                      ...enrollment,
                      mobileEnrollment: {
                        ...enrollment.mobileEnrollment,
                        phoneNumber: phone,
                        hasConsentToTextActivation: true,
                      },
                    },
                    enrollmentConsents: enrollment.enrollmentConsents,
                  },
                },
                vehicles
              )
            )
            .pipe(
              switchMap((response) => {
                return of(
                  fromActions.updateTelematicsEnrollmentSuccess({
                    payload: response,
                  })
                );
              }),
              catchError((error) => {
                const saneError = this.errorSanitizerService.sanitizeError(
                  error,
                  'PersonalAuto'
                );
                return of(
                  fromActions.updateTelematicsEnrollmentFail({
                    payload: saneError,
                  })
                );
              })
            );
        })
      ) as unknown as Observable<Action>
  );

  updateDataCollectionMethodAfterRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveQuoteSuccess),
      filter((action) => action.payload.productType === 'PersonalAuto'),
      filter(
        (action) =>
          !!(
            action.payload.response as QuoteRetrievePersonalAutoResponse
          )?.telematicsEnrollments?.find((enrollment) =>
            enrollment?.vehicleEnrollment?.vehicles?.find(
              (vehicle) =>
                (vehicle.enrollmentStatus === 'Enrolled' ||
                  vehicle.enrollmentStatus === 'VerifiedScore') &&
                !vehicle.dataCollectionMethod
            )
          )
      ),
      withLatestFrom(
        this.vehicleService.getSavedVehiclesByProductType('PersonalAuto')
      ),
      switchMap(([action, vehicles]) => {
        return this.adapter
          .updateTelematicsEnrollment(
            this.telematicsService.sanitizeTelematicsRequest(
              {
                quoteId: action.payload.response.quoteId,
                enrollment: (
                  action.payload.response as QuoteRetrievePersonalAutoResponse
                ).telematicsEnrollments![0]! as any,
              },
              vehicles
            )
          )
          .pipe(
            switchMap((response) => {
              return of(
                fromActions.updateTelematicsEnrollmentSuccess({
                  payload: response,
                })
              );
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                'PersonalAuto'
              );
              return of(
                fromActions.updateTelematicsEnrollmentFail({
                  payload: saneError,
                })
              );
            })
          );
      })
    )
  );

  validateProgramsOnVehicleChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromVehicleActions.updateVehicle),
      filter((action) => action.payload.productType === 'PersonalAuto'),
      concatMap((action) =>
        this.vehicleService
          .getSavedVehiclesByProductType('PersonalAuto')
          .pipe(take(1))
      ),
      concatMap((vehicles) =>
        combineLatest([
          this.actions$.pipe(
            ofType(
              fromVehicleActions.updateVehicleSuccess,
              fromVehicleActions.updateVehicleError
            )
          ),
          this.telematicsService.getCurrentEnrollment(),
          of(vehicles),
        ]).pipe(take(1))
      ),
      concatMap(([successAction, currentEnrollment, vehicles]) => {
        if (
          successAction.type !== fromVehicleActions.updateVehicleSuccess.type
        ) {
          return of(null);
        }
        const vehicle: VehicleEntity | undefined = vehicles.find(
          (v) =>
            v.vehicleId?.toString() ===
            successAction.payload.vehicleId?.toString()
        );
        const changedVin = successAction.payload.vin;
        const oldMiles = vehicle?.annualMiles;
        const newMiles = successAction.payload.annualMiles;

        if (
          vehicle &&
          newMiles &&
          oldMiles &&
          TelematicsUtils.isVehicleEnrollment(currentEnrollment)
        ) {
          const vehicleEnrollment = currentEnrollment as VehicleEnrollment;
          if (
            (+newMiles > TELEMATICS_MILES_THRESHOLD &&
              +oldMiles <= TELEMATICS_MILES_THRESHOLD &&
              vehicleEnrollment.vehicles.some(
                (v) =>
                  v.vehicleId?.toString() === vehicle.vehicleId?.toString() &&
                  v.enrollmentStatus === 'Enrolled' &&
                  v.vehicleProgram === 'SmartMiles'
              )) ||
            (+newMiles <= TELEMATICS_MILES_THRESHOLD &&
              +oldMiles > TELEMATICS_MILES_THRESHOLD &&
              vehicleEnrollment.vehicles.some(
                (v) =>
                  v.vehicleId?.toString() === vehicle.vehicleId?.toString() &&
                  v.enrollmentStatus === 'Enrolled' &&
                  v.vehicleProgram === 'SmartRide'
              ))
          ) {
            return of(
              fromActions.updateRecommendationForVehicle({
                vehicle: { ...vehicle, annualMiles: newMiles },
              })
            );
          }
        }

        if (
          vehicle &&
          newMiles &&
          oldMiles &&
          TelematicsUtils.isMobileEnrollment(currentEnrollment) &&
          +newMiles <= TELEMATICS_MILES_THRESHOLD &&
          +oldMiles > TELEMATICS_MILES_THRESHOLD
        ) {
          return of(
            fromActions.updateRecommendationForVehicle({
              vehicle: { ...vehicle, annualMiles: newMiles },
            })
          );
        }

        if (vehicle && newMiles && newMiles !== oldMiles) {
          const enrollmentVehicles:
            | (EnrolledVehicle | NotEnrolledVehicle)[]
            | undefined = (currentEnrollment as VehicleEnrollment)?.vehicles;
          const currentEnrollmentVehicle = enrollmentVehicles?.find(
            (v) => v.vehicleId?.toString() === vehicle.vehicleId?.toString()
          );
          if (
            currentEnrollmentVehicle &&
            currentEnrollmentVehicle.enrollmentStatus === 'Enrolled' &&
            currentEnrollmentVehicle.vehicleProgram === 'SmartMiles'
          ) {
            return of(
              fromActions.updateTelematicsEnrollment({
                payload: {
                  selectedProgram: currentEnrollmentVehicle.vehicleProgram,
                  vehicleId: vehicle.vehicleId,
                },
              })
            );
          }
        }

        if (vehicle && changedVin && vehicle?.vin !== changedVin) {
          return of(
            fromActions.updateRecommendationForVehicle({
              vehicle: { ...vehicle, vin: changedVin },
            })
          );
        } else {
          if (TelematicsUtils.isMobileEnrollment(currentEnrollment)) {
            return of(
              fromActions.updateTelematicsEnrollment({
                payload: {
                  selectedProgram: 'SmartRide',
                  vehicleId: vehicle?.vehicleId || '',
                },
              })
            );
          } else if (TelematicsUtils.isVehicleEnrollment(currentEnrollment)) {
            const currentEnrollmentVehicle = (
              currentEnrollment as VehicleEnrollment
            )?.vehicles?.find(
              (veh) =>
                veh.vehicleId === vehicle?.vehicleId &&
                veh.enrollmentStatus === 'Enrolled'
            );
            if (!!currentEnrollmentVehicle) {
              return of(
                fromActions.updateTelematicsEnrollment({
                  payload: {
                    selectedProgram: (
                      currentEnrollmentVehicle as EnrolledVehicle
                    )?.vehicleProgram,
                    vehicleId: vehicle?.vehicleId || '',
                  },
                })
              );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        }
      }),
      filterOutNull()
    )
  );

  updateRecommendationForVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRecommendationForVehicle),
      concatMap((action) =>
        this.vehicleService
          .getVehicleById(action.vehicle.vehicleId?.toString())
          .pipe(take(1))
      ),
      filterOutNull(),
      concatMap((vehicle) =>
        combineLatest([
          of(vehicle),
          this.telematicsService.getCurrentEnrollment().pipe(take(1)),
          this.telematicsService
            .composeTelematicsRecommendationRequest(vehicle)
            .pipe(take(1)),
        ])
      ),
      concatMap(([vehicle, enrollment, request]) =>
        this.adapter.getTelematicsRecommendation(request).pipe(
          take(1),
          switchMap((response) => {
            const actions: Action[] = [];
            let selectedProgram = 'SmartRide';
            let dataCollectionMethod;
            if (response.recommendedProgram === 'Vehicle Program') {
              const recommendationVehicle =
                response.qualifyingInformation?.vehicles?.find(
                  (v) =>
                    v.vehicleId?.toString() === vehicle?.vehicleId?.toString()
                );
              const recommendedProgram =
                recommendationVehicle?.availablePrograms?.find(
                  (p) => p.recommended
                );
              dataCollectionMethod = recommendedProgram?.dataCollectionMethod;
              selectedProgram = recommendedProgram?.name || selectedProgram;
            }
            if (enrollment?.enrollmentId) {
              actions.push(
                fromActions.updateTelematicsEnrollment({
                  payload: {
                    selectedProgram,
                    vehicleId: vehicle?.vehicleId || '',
                    dataCollectionMethod,
                  },
                })
              );
            } else {
              actions.push(fromActions.clearTelematicsLoading());
            }
            actions.push(
              fromActions.getTelematicsRecommendationSuccess({
                payload: response,
              })
            );
            return from(actions);
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalAuto'
            );
            return of(
              fromActions.getTelematicsRecommendationFail({
                payload: saneError,
              })
            );
          })
        )
      )
    )
  );

  // CODEMINERS-4120
  // Multiple telematics calls triggering on updateVehicleSuccess created race conditions.
  // refreshTelematicsEnrollment will be dispatched on updateTelematicsEnrollmentSuccess instead, which will always follow a vehicle update.
  refreshTelematicsOnVehicleUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTelematicsEnrollmentSuccess),
      map(() => fromActions.refreshTelematicsEnrollment())
    )
  );

  // CODEMINERS-4751
  // Updating telematics changes a vehicle's eligibleDiscounts.
  // It is important that we refresh vehicles after updating telematics, otherwise the next updateVehicle will reset the enrollment.
  fetchVehiclesAfterTelematicsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.addTelematicsEnrollmentSuccess,
        fromActions.updateTelematicsEnrollmentSuccess,
        fromActions.removeTelematicsEnrollmentSuccess,
        fromActions.removeVehicleFromTelematicsSuccess
      ),
      map(() =>
        fromVehicleActions.refetchAllVehicles({ productType: 'PersonalAuto' })
      )
    )
  );

  reconsiderRecommendationOnCertainBillingChanges$ = createEffect(() =>
    this.store.select(getBillingPlan).pipe(
      pairwise(),
      filter(
        ([oldPlan, newPlan]) =>
          (newPlan === 'MONTHLY EFT' ||
            newPlan === 'MONTHLY RECURRING BANKCARD') &&
          oldPlan !== 'MONTHLY EFT' &&
          oldPlan !== 'MONTHLY RECURRING BANKCARD'
      ),
      withLatestFrom(
        this.store.select(getTelematicsReviewed),
        this.store.select(isSmartMilesEnrolled),
        this.store.select(isPersonalAutoSelected),
        this.store.select(isTelematicsDeclined)
      ),
      filter(
        ([
          plans,
          reviewed,
          smartMilesEnrolled,
          isAutoSelected,
          isTelematicsDeclined,
        ]) =>
          !reviewed &&
          !smartMilesEnrolled &&
          isAutoSelected &&
          !isTelematicsDeclined
      ),
      map(() => fromActions.resetTelematicsEnrollment())
    )
  );

  reconsiderRecommendationOnDocDeliveryChanges$ = createEffect(() =>
    this.store.select(getDocumentDeliveryPreference).pipe(
      pairwise(),
      filter(
        ([oldPlan, newPlan]) =>
          newPlan === 'USMail' && oldPlan === 'OnlineAccountAccess'
      ),
      withLatestFrom(
        this.store.select(isSmartMilesEnrolled),
        this.store.select(isPersonalAutoSelected)
      ),
      filter(
        ([plans, smartMilesEnrolled, isAutoSelected]) =>
          smartMilesEnrolled && isAutoSelected
      ),
      map(() => fromActions.resetTelematicsEnrollment())
    )
  );

  removeTelematicsEnrollmentWhenNoSelectedVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromVehicleActions.deleteVehicleSuccess),
      withLatestFrom(
        this.productsService.getProduct('PersonalAuto'),
        this.telematicsService.getCurrentEnrollment(),
        this.vehicleService.getSelectedVehiclesByProductType('PersonalAuto')
      ),
      filter(
        ([action, product, enrollment, selectedVehicles]) =>
          !!product?.quoteId &&
          !!enrollment?.enrollmentId &&
          selectedVehicles.length === 0
      ),
      switchMap(([action, product, enrollment, selectedVehicles]) =>
        this.adapter
          .deleteTelematicsEnrollment(
            product!.quoteId as string,
            enrollment?.enrollmentId || ''
          )
          .pipe(
            map((response) => fromActions.removeTelematicsEnrollmentSuccess()),
            catchError((error) => {
              return of(
                fromActions.removeTelematicsEnrollmentFail({
                  payload: this.errorSanitizerService.sanitizeError(
                    error,
                    'PersonalAuto'
                  ),
                })
              );
            })
          )
      )
    )
  );

  getMissingRecommendationOnRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.allRetrievesComplete),
      withLatestFrom(
        this.productsService.getSelectedProducts(),
        this.telematicsService.getCurrentRecommendation()
      ),
      filter(
        ([_, activeProducts, recommendation]) =>
          activeProducts.some((product) => product.type == 'PersonalAuto') &&
          !recommendation
      ),
      map(() => TelematicsActions.getTelematicsRecommendation({}))
    )
  );

  showTelematicsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TelematicsActions.openTelematicsModal),
        exhaustMap(() => this.modalService.telematics()),
        filter((result) => result),
        tap((result) => {
          if (
            typeof result.textNotificationEvent?.textNotification === 'boolean'
          ) {
            this.telematicsService.dispatchUpdateTelematicsTextNotification(
              result.textNotificationEvent
            );
          }
          if (result.connectedCarConsent) {
            this.productsService.updateQuote(
              {
                productType: 'PersonalAuto',
                hasConnectedCarConsent: true,
              },
              {
                overrideQuoteStatus: true,
              }
            );
          }
          this.telematicsService.dispatchUpdateTelematicsConfirmation(true);
        })
      ),
    { dispatch: false }
  );
}
