import { Injectable } from '@angular/core';
import { ErrorHandlerAdapter } from '@core/adapters/error-handler.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorHandlerRequest } from '@core/models/api/request/error-handler-request.model';
import {
  ErrorHandlerResponse,
  TranslatedMessage,
} from '@core/models/api/response/error-handler-resposne.model';
import { ErrorModel } from '@entities/error/error.model';
import { getConvertedErrors } from '@entities/error/error.selector';
import { Store } from '@ngrx/store';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { Nullable } from '@shared/utils/type.utils';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private adapter: ErrorHandlerAdapter, private store: Store) {}

  searchErrorForTranslation(error: ErrorModel): Observable<ErrorModel> {
    let requestBody: ErrorHandlerRequest | null =
      this.convertErrorToRequestModel(error);
    if (!requestBody) {
      return EMPTY;
    }
    return this.adapter.search(requestBody).pipe(
      filterOutNull(),
      map((response) => this.mapTranslatedMessage(response, error)),
      filterOutNull()
    );
  }

  getConvertedErrors(severity?: string, category?: string) {
    return this.store.select(getConvertedErrors(severity, category));
  }

  private convertErrorToRequestModel(
    error: ErrorModel
  ): ErrorHandlerRequest | null {
    let requestBody: ErrorHandlerRequest = {
      appId: 'Nationwide Express V2',
      product: this.getProductFromProductType(error.productType),
    };

    if (error.userMessage) {
      requestBody.userMessages = this.splitMessageString(error.userMessage);
    }
    if (error.developerMessage) {
      requestBody.developerMessages = this.splitMessageString(
        error.developerMessage
      );
    }

    if (
      !requestBody.userMessages?.length &&
      !requestBody.developerMessages?.length
    ) {
      return null;
    }

    return requestBody;
  }

  private splitMessageString(input: string): string[] {
    const APIGEE_JSON_STRING_LIMIT = 500;
    return input
      .split('|')
      .map((s) => s.trim())
      .filter((s) => s.length > 0 && s.length <= APIGEE_JSON_STRING_LIMIT);
  }

  private getProductFromProductType(
    productType: ProductType | undefined
  ): string | undefined {
    switch (productType) {
      case 'PersonalAuto':
        return 'Auto';
      case 'Condominium':
        return 'Condo';
      case 'DwellingFire':
        return 'Dwelling Fire';
      case 'PersonalUmbrella':
        return 'Umbrella';
      case 'MSA':
        return 'Motorcycle';
      case 'Homeowner':
        return 'Homeowner';
      case 'Boat':
        return 'Boat';
      case 'Tenant':
        return 'Tenant'
      case 'RV':
        return 'Recreation Vehicles';
      default:
        return 'Auto';
    }
  }

  private mapTranslatedMessage(
    response: ErrorHandlerResponse,
    error: ErrorModel
  ): ErrorModel | null {
    let translatedMessage: TranslatedMessage | undefined =
      this.getBestTranslation(response.translatedMessages);
    if (translatedMessage) {
      return {
        ...error,
        id: 0,
        displayMessage: translatedMessage.translatedMessage,
        severity: translatedMessage.severity || error.severity,
        category: translatedMessage.category || error.category,
        sourcedByErrorHandler: true,
      };
    }
    return null;
  }

  private getBestTranslation(
    translatedMessages: TranslatedMessage[]
  ): TranslatedMessage | undefined {
    let translatedMessage: TranslatedMessage | undefined;

    for (const tm of translatedMessages || []) {
      // type doesn't say so, but this came right off the API and can be null
      // Translated Developer Message matters the most, take it and run
      if (tm.developerMessage && tm.translatedMessage) {
        translatedMessage = tm;
        break;
      }
      // A translated User Message will suffice, but keep looking
      if (tm.userMessage && tm.translatedMessage) {
        translatedMessage = tm;
      }
    }

    return translatedMessage;
  }
}
