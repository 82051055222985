import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';

export interface DocumentsEntity {
  documents: DocumentEntity[];
  productType: ProductType;
}

export interface DocumentEntity {
  documentId: number;
  name: string;
  docuSignEnvelopeId?: string;
  documentType: string;
  isElectronicallySignable: boolean;
  isAttachmentRequired: boolean;
  createdDate: string;
  documentByteStream?: string;
}

export interface Role {
  role: string;
}

export function sanitizeDocumentEntities(input: unknown): DocumentEntity[] {
  return ObjectValidator.sanitizeArray(input, sanitizeDocumentEntity);
}

export function sanitizeDocumentEntity(input: unknown): DocumentEntity {
  return ObjectValidator.forceSchema<DocumentEntity>(
    input,
    {
      createdDate: 'string',
      documentId: 'number',
      documentType: 'string',
      documentByteStream: 'string',
      docuSignEnvelopeId: 'string',
      isAttachmentRequired: 'boolean',
      isElectronicallySignable: 'boolean',
      name: 'string',
    },
    ['docuSignEnvelopeId', 'documentByteStream']
  );
}
