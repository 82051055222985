<div
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="infractions-modal"
  *ngIf="vm$ | async as vm"
>
  <div class="modal-header">
    <h4 class="modal-title">{{ mode }} accident / violation</h4>
  </div>

  <div class="modal-body">
    <nwx-edit-infractions-form
      [infraction]="infraction"
      (valueChange)="onValueChange($event)"
      (formReady)="addChildForm('infraction', $event)"
    >
    </nwx-edit-infractions-form>
  </div>

  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button
        *ngIf="mode === 'Edit'"
        slot="cancel"
        (click)="removeInfraction(vm.localInfraction)"
        name="remove-infraction"
        [attr.id]="'remove-infraction'"
        iconleft="trash"
        >Remove</bolt-button
      >
      <bolt-button
        slot="back"
        (click)="cancel()"
        name="cancel"
        [attr.id]="'cancel'"
        >Cancel</bolt-button
      >
      <bolt-button
        slot="forward"
        type="primary"
        (click)="saveInfraction(vm.localInfraction)"
        name="save-infraction"
        [attr.id]="'save-infraction'"
        >Save</bolt-button
      >
    </bolt-button-bar>
  </div>
</div>
