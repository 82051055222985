import { NextStep } from '@core/services/up-next.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import {
  NavigationActions,
  ProductActions,
  QuoteActions,
  TaskActions,
  TelematicsActions,
} from '@core/store/actions';
import { uploadAllProductsPreBindDocuments } from '@core/store/entities/pre-bind-documents/pre-bind-documents.action';
import { openPreBindDocumentsModal } from '@core/store/entities/confirmations/confirmations.action';

export class StepUtils {
  static getProductsPending(): NextStep {
    return {
      instructions: 'Waiting on rating for next steps...',
      noButton: false,
      buttonLabel: 'Next Steps',
      buttonDisabled: true,
      buttonType: 'default',
    };
  }

  static getRecalculateRate(): NextStep {
    return {
      instructions: 'Recalculate rate',
      noButton: false,
      buttonLabel: 'Recalculate quote',
      buttonAction: QuoteActions.rateQuoteSelectedProducts({
        ratingType: 'quote',
      }),
      buttonDisabled: false,
      buttonType: 'recalculate',
    };
  }

  static openInPolicyCenter(erroredProduct: ProductModel): NextStep {
    return {
      instructions:
        ` <strong>` +
        erroredProduct.name +
        ` policy blocked:</strong> This property is ineligible for final rating in Nationwide Express.
            You may retrieve this account in PolicyCenter by clicking 'Open in PolicyCenter' or you may remove the product by clicking 'Remove product'`,
      noButton: true,
      buttonFunction: () => {},
      buttonLabel: '',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static pivotToPolictCenter(): NextStep {
    return {
      instructions:
        'Use PolicyCenter to finish products not yet available in Nationwide Express',
      noButton: false,
      buttonAction: NavigationActions.linkToPolicyCenter({
        payload: { reason: 'task pivot', urlType: 'Account' },
      }),
      buttonLabel: 'PolicyCenter account',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static noProducts(): NextStep {
    return {
      instructions: 'No products left to quote.',
      // ` <strong>Remove ` +
      // erroredProduct.name +
      // ` and continue?</strong> To continue with the other products in this quote you can remove ` +
      // erroredProduct.name +
      // ` and continue`,
      noButton: true,
      buttonLabel: '',
      buttonDisabled: true,
      buttonType: 'default',
    };
  }

  static getRemoveProductsWithErrors(): NextStep {
    return {
      instructions: 'Remove products with errors',
      // ` <strong>Remove ` +
      // erroredProduct.name +
      // ` and continue?</strong> To continue with the other products in this quote you can remove ` +
      // erroredProduct.name +
      // ` and continue`,
      noButton: true,
      buttonAction: ProductActions.removeAllProductsWithErrors(),
      buttonLabel: 'Remove product',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static getRemoveProduct(product: ProductModel): NextStep {
    return {
      instructions: ' Remove product with error to continue.',
      noButton: false,
      buttonAction: ProductActions.removeProduct({ payload: product.type }),
      buttonLabel: 'Remove product',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static completeDraftTasks(): NextStep {
    return {
      instructions: ' Complete tasks to see initial rate.',
      noButton: false,
      buttonAction: TaskActions.toggleShowTasks(),
      buttonLabel: 'Start rating tasks',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static completeQuotedTasks(): NextStep {
    return {
      instructions: ' Complete tasks to order reports and see final rate.',
      noButton: false,
      buttonAction: TaskActions.toggleShowTasks(),
      buttonLabel: 'Start rating tasks',
      buttonDisabled: false,
      buttonType: 'default',
    };
  }

  static getFinalRate(): NextStep {
    return {
      instructions: " You're ready for your final rate",
      noButton: false,
      buttonAction: QuoteActions.rateQuoteSelectedProducts({
        ratingType: 'bind',
      }),
      buttonLabel: 'Order reports',
      buttonDisabled: false,
      buttonType: 'default',
      displayInCondensedView: true,
    };
  }

  static reviewTelematics(): NextStep {
    return {
      instructions: ' Review telematics enrollments',
      noButton: false,
      buttonAction: TelematicsActions.setTelematicsTabActive(),
      buttonLabel: 'Review telematics',
      buttonDisabled: false,
      buttonType: 'default',
      isTelematicsStep: true,
      displayInCondensedView: true,
    };
  }

  static acceptTelematics(): NextStep {
    return {
      instructions: ' Accept telematics enrollments',
      noButton: false,
      buttonAction: TelematicsActions.openTelematicsModal(),
      buttonLabel: 'Accept telematics',
      buttonDisabled: false,
      buttonType: 'default',
      isTelematicsStep: true,
      displayInCondensedView: true,
    };
  }

  static acceptPreBindDocuments(preBindCallsIdle: boolean): NextStep {
    return {
      instructions: ' Accept Pennsylvania Required Notice',
      noButton: false,
      buttonAction: openPreBindDocumentsModal(),
      buttonLabel: 'Acknowledge notice',
      buttonDisabled: false,
      buttonType: 'default',
      buttonReady: preBindCallsIdle,
      displayInCondensedView: true,
    };
  }

  static uploadPreBindDocuments(preBindCallsIdle: boolean): NextStep {
    return {
      instructions: ' Accept Pennsylvania Required Notice',
      noButton: false,
      buttonAction: uploadAllProductsPreBindDocuments(),
      buttonLabel: 'Retry',
      buttonDisabled: false,
      buttonType: 'default',
      buttonReady: preBindCallsIdle,
      displayInCondensedView: true,
    };
  }

  static getGoToBilling(): NextStep {
    return {
      instructions: 'Ready for purchase! This quote has a final rate.',
      noButton: false,
      buttonAction: NavigationActions.navigateToBillingPage(),
      buttonLabel: 'Pay & Bind',
      buttonDisabled: false,
      buttonType: 'default',
      displayInCondensedView: true,
    };
  }
}
