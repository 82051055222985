import {
  FilingEntity,
  sanitizeFilingEntity,
} from '@core/models/entities/filing.entity';
import { BasePersonEntity } from '@core/models/entities/base.entity';
import {
  EligibleDiscountsEntity,
  sanitizeEligibleDiscountsEntity,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import {
  DriverIncidentEntity,
  sanitizeDriverIncidentEntity,
} from '@core/models/entities/driver-incident.entity';
import {
  EmploymentInfoEntity,
  sanitizeEmploymentInfoEntity,
} from '@core/models/entities/employment-info.entity';
import {
  ModifierEntity,
  sanitizeModifierEntity,
} from '@core/store/entities/modifier/modifier.entity';
import { Nullable } from '@shared/utils/type.utils';
import {
  DriverType,
  NonDriverReason,
  RelationToPNIType,
} from '@core/models/api/dsm-types';
import {
  PersonEntity,
  sanitizePersonEntity,
} from '@core/models/entities/person.entity';
import { ObjectValidator } from '@core/helper/object-validator';

export interface DriverEntity extends BasePersonEntity {
  advancedDriverTraining?: boolean;
  advancedTrainingCourseCompletionDate?: string;
  ageFirstLicensed?: Nullable<number>;
  associateNumber?: string;
  defensiveDrivingCourse?: boolean;
  defensiveDrivingCourseDate?: string;
  driverId: Nullable<number>;
  driverOccupation?: Nullable<string>;
  driverTraining?: boolean;
  driverType?: DriverType; // doesn't reflect updates to initial status; use member.options.driverRoles[0].driverType
  eligibleDiscounts?: EligibleDiscountsEntity[];
  employmentInformation?: EmploymentInfoEntity;
  endorsementDate?: string;
  filings?: FilingEntity[];
  goodStudent?: boolean;
  hasGraduatedCollege?: boolean;
  hasMotorcycleEndorsement?: boolean;
  healthCarePlan?: string;
  infractionDesc?: string;
  infractionType?: string;
  infractions?: DriverIncidentEntity[] | null;
  infractionsByProduct?: {
    [productType: string /* ProductType */]: DriverIncidentEntity[];
  };
  isFinancialFilingRequired?: boolean;
  isGenderXSelected?: boolean;
  isLivingWithNWInsuredParent?: boolean;
  isNationwideEmployee?: boolean;
  isPersonalInjuryProtectionExcluded?: boolean;
  licenseNumber?: Nullable<string>;
  licenseState?: Nullable<string>;
  matchFlag?: string;
  membership?: Nullable<string>;
  modifiers?: ModifierEntity[];
  mtNationalGuard?: boolean;
  nonSpecifiedGender?: Nullable<boolean>;
  numberOfYearsOfBoatingExperience?: number;
  person?: Nullable<PersonEntity>;
  relationToPrimaryNamedInsured?: RelationToPNIType;
  reasonNonDriver?: NonDriverReason;
  safetyCourse?: boolean;
  sequenceNumber?: number;
  studentAway?: boolean;
  trainingCourseCompletionDate?: string;
  usArmedForces?: boolean;
  yearsOfMotorcycleAndOffRoadExp?: number;
}
// Thank you for maintaining alphabetical order.

export function sanitizeDriverEntity(response: unknown): DriverEntity {
  return ObjectValidator.forceSchema<DriverEntity>(
    response,
    {
      // Not present in responses. Declared here just for completeness:
      entityId: 'any',
      productIds: 'any',
      productType: 'any',
      quoteId: 'string',
      selected: 'boolean',
      eligibleDiscountIds: 'any',
      policyRoles: 'any',
      driverRoles: 'any',
      infractionsByProduct: 'any',
      advancedDriverTraining: 'boolean',
      advancedTrainingCourseCompletionDate: 'boolean',
      defensiveDrivingCourse: 'boolean',
      defensiveDrivingCourseDate: 'string',
      driverTraining: 'boolean',
      goodStudent: 'boolean',
      infractionDesc: 'string',
      infractionType: 'string',
      trainingCourseCompletionDate: 'string',
      // Real fields:
      ageFirstLicensed: 'number',
      associateNumber: 'string',
      driverId: 'number',
      driverOccupation: 'string',
      driverType: 'string',
      eligibleDiscounts: [sanitizeEligibleDiscountsEntity],
      employmentInformation: sanitizeEmploymentInfoEntity,
      endorsementDate: 'string',
      filings: [sanitizeFilingEntity],
      hasGraduatedCollege: 'boolean',
      hasMotorcycleEndorsement: 'boolean',
      healthCarePlan: 'string',
      infractions: [sanitizeDriverIncidentEntity],
      isFinancialFilingRequired: 'boolean',
      isLivingWithNWInsuredParent: 'boolean',
      isNationwideEmployee: 'boolean',
      isPersonalInjuryProtectionExcluded: 'boolean',
      licenseNumber: 'string',
      licenseState: 'string',
      membership: 'string',
      modifiers: [sanitizeModifierEntity],
      mtNationalGuard: 'boolean',
      nonSpecifiedGender: 'boolean',
      numberOfYearsOfBoatingExperience: 'number',
      person: sanitizePersonEntity,
      relationToPrimaryNamedInsured: 'string',
      reasonNonDriver: 'string',
      safetyCourse: 'boolean',
      sequenceNumber: 'number',
      studentAway: 'boolean',
      usArmedForces: 'boolean',
      yearsOfMotorcycleAndOffRoadExp: 'number',
    },
    [
      'entityId',
      'productIds',
      'productType',
      'quoteId',
      'selected',
      'eligibleDiscountIds',
      'policyRoles',
      'driverRoles',
      'infractionsByProduct',
      'advancedDriverTraining',
      'advancedTrainingCourseCompletionDate',
      'defensiveDrivingCourse',
      'defensiveDrivingCourseDate',
      'driverTraining',
      'goodStudent',
      'infractionDesc',
      'infractionType',
      'trainingCourseCompletionDate',
      'ageFirstLicensed',
      'associateNumber',
      'driverOccupation',
      'driverType',
      'eligibleDiscounts',
      'employmentInformation',
      'endorsementDate',
      'filings',
      'hasGraduatedCollege',
      'hasMotorcycleEndorsement',
      'healthCarePlan',
      'infractions',
      'isFinancialFilingRequired',
      'isLivingWithNWInsuredParent',
      'isNationwideEmployee',
      'isPersonalInjuryProtectionExcluded',
      'licenseNumber',
      'licenseState',
      'membership',
      'modifiers',
      'mtNationalGuard',
      'nonSpecifiedGender',
      'numberOfYearsOfBoatingExperience',
      'person',
      'relationToPrimaryNamedInsured',
      'reasonNonDriver',
      'safetyCourse',
      'sequenceNumber',
      'studentAway',
      'usArmedForces',
      'yearsOfMotorcycleAndOffRoadExp',
    ]
  );
}
