import { Pipe, PipeTransform } from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import {
  CoverageOption,
  CoverageTerm,
} from '@core/store/entities/coverage/coverage.entity';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoverageMinimumsForUmbrella } from '@shared/constants/app-constants';

@Pipe({
  name: 'filterUmbrellaMinimums',
})
export class FilterUmbrellaMinimumsPipe implements PipeTransform {
  constructor(private productsService: ProductsService) {}
  transform(
    options: CoverageOption[],
    term: CoverageTerm
  ): Observable<CoverageOption[]> {
    return this.productsService.getSelectedProductTypes().pipe(
      map((productIds) => {
        const affectedTerm = CoverageMinimumsForUmbrella.find(
          (item) => item.code === term.code
        );
        if (affectedTerm && productIds.includes('PersonalUmbrella')) {
          return options.filter((opt) => {
            const value = opt.value.split('/')[0];
            return +value >= affectedTerm?.limit;
          });
        } else {
          return options;
        }
      })
    );
  }
}
