import { FeatureFlagService } from '@core/services/feature-flag.service';
import { Injectable } from '@angular/core';
import { BillingAccountsModalComponent } from '@app/billing-payment/components/billing-accounts-modal/billing-accounts-modal.component';
import {
  BillingDetailsFormAccount,
  BillingDetailsFormInvoice,
  BillingDetailsFormProduct,
} from '@app/billing-payment/components/billing-details-form/billing-details-form.component';
import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';
import { ProductType, QuoteStatus } from '@core/models/api/dsm-types';
import { BillingService } from '@core/services/billing.service';
import { DiscountsService } from '@core/services/discounts.service';
import { MortgageService } from '@core/services/mortgage.service';
import { PremiumService } from '@core/services/premium.service';
import { ProductsService } from '@core/services/products.service';
import { QuoteLetterService } from '@core/services/quote-letter.service';
import { SessionService } from '@core/services/session.service';
import { TaskService } from '@core/services/task.service';
import { TelematicsService } from '@core/services/telematics.service';
import {
  getCurrentEnrollment,
  isSmartMilesEnrolled,
} from '@core/store/entities/telematics/telematics.selector';
import {
  PriceSummaryDisplayContent,
  PriceSummaryDisplayProduct,
  PriceSummaryDownPayment,
} from '@app/hub/components/price-summary-display/price-summary-display-content';
import {
  BillingPlanDisplay,
  DefaultBillingPlans,
} from '@shared/constants/billing-constants';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of, from, throwError } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import {
  billingPlansFormVisited,
  updateBillingPlansForm,
} from '../store/models/billing-plans/billing-plans.action';
import {
  getBillingPlan,
  getBillingPlansForm,
  getBillingPlansFormVisited,
} from '../store/models/billing-plans/billing-plans.selector';
import { FormsState } from '../store/reducers';
import { QuoteService } from '@core/services/quote.service';
import { LoadingService } from '@core/services/loading.service';
import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import { ProductUtils } from '@shared/utils/product.util';
import { MemberService } from '@core/services/member.service';
import {
  BillingAccountsModalInputs,
  PriceSummaryDisplayContentInputs,
  PriceSummaryUtils,
} from '@shared/utils/hub/price-summary/price-summary.util';
import { ProductModel } from '@entities/product/product.model';
import {
  VehicleEnrollment,
  MobileEnrollment,
} from '@entities/telematics/telematics.model';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { EligibleDiscountId } from '@entities/eligible-discounts/eligible-discounts.entity';
import { ErrorService } from '@entities/error/error.service';

@Injectable({
  providedIn: 'root',
})
export class BillingFormService {
  constructor(
    private store: Store<FormsState>,
    private premiumService: PremiumService,
    private peopleService: MemberService,
    private productsService: ProductsService,
    private billingService: BillingService,
    private quoteLetterService: QuoteLetterService,
    private sessionService: SessionService,
    private discountsService: DiscountsService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private taskService: TaskService,
    private mortgageService: MortgageService,
    private telematicsService: TelematicsService,
    private featureFlagsService: FeatureFlagService,
    private quoteService: QuoteService,
    private loadingService: LoadingService,
    private errorService: ErrorService
  ) {}

  static blankBillingPlanDisplay(): BillingPlanDisplay {
    return {
      name: '',
      id: '',
      hasPaymentDay: false,
      payWith: '',
      discount: '',
      serviceFee: '',
      smartMiles: '',
      askAutoRenew: false,
      icon: '',
      monthlyFees: '',
      billDelivery: '',
    };
  }

  updateBillingPlansForm(changes: Partial<BillingPlansFormModel>): void {
    this.store.dispatch(updateBillingPlansForm({ payload: changes }));
  }

  getBillingPlansForm(): Observable<BillingPlansFormModel> {
    return this.store.select(getBillingPlansForm);
  }

  getBillingPlan(): Observable<string> {
    return this.getBillingPlansForm().pipe(map((form) => form.billingPlan));
  }

  setBillingPlansFormVisited(): void {
    this.store.dispatch(billingPlansFormVisited());
  }

  getBillingPlansFormVisited(): Observable<boolean> {
    return this.store.select(getBillingPlansFormVisited);
  }

  getShowAutoRenew(): Observable<boolean> {
    return combineLatest([
      this.getBillingPlan(),
      this.eligibleDiscountsService.isEligibleDiscountPresent(
        'BillingPaymentMethod'
      ),
    ]).pipe(
      map(([billingPlan, bpmPresent]) => {
        return billingPlan === 'PAY IN FULL' && bpmPresent;
      })
    );
  }

  /**
   * Format the output of BillingService.generateInvoice() for display on billing-details-form.
   */
  getBillingDetailsFormInvoice(): Observable<BillingDetailsFormInvoice> {
    return combineLatest([
      this.billingService.generateInvoice(),
      this.productsService.getSelectedProducts(),
      this.store.select(isSmartMilesEnrolled),
      this.mortgageService.getPrimaryMortgage(),
      this.store.select(getBillingPlan),
    ]).pipe(
      map(([invoice, products, smartMiles, mortgage, billingPlan]) => {
        const accounts: BillingDetailsFormAccount[] = [];
        const $ = (amount: number) => PriceSummaryUtils.formatMoney(amount);
        const warnAboutVaryingInstallmentCount: boolean =
          billingPlan !== 'PAY IN FULL';

        if (invoice.containsPolicyFee) {
          return {
            accounts: [],
            downPaymentDisplay: $(invoice.allProducts.downPayment),
            detailsOmittedDueToPolicyFee: true,
            warnAboutVaryingInstallmentCount,
            downPaymentsUnavailable: invoice.downPaymentsUnavailable,
          };
        }

        const escrow = invoice.accounts.find(
          (a) => a.billingAccountType === 'escrow'
        );
        if (escrow) {
          const productDisplayName = ProductUtils.productBillingName(
            escrow.products[0].productType
          );
          const account = {
            title: `${productDisplayName} escrow`,
            // fix this to do all mortgages
            subtitle: PriceSummaryUtils.subtitleForMortgage(
              mortgage as MortgageModel
            ),
            monthlyPriceDisplay: '',
            priceDisplay: `${$(escrow.totalPayment)} / ${
              escrow.products[0].termLength
            } mo. term`,
            products: [],
          };
          // (account.products) is deliberately empty; we don't want per-product lines for escrow
          accounts.push(account);
        }

        const billingAccounts = invoice.accounts.filter(
          (a) => a.billingAccountType === 'billing'
        );
        let displayIndex = 1;
        for (const invoiceAccount of billingAccounts) {
          let title;
          let totalPriceDisplayHeader = '';
          if (billingAccounts.length === 1) {
            title = 'Nationwide account total';
          } else {
            title = `Nationwide Billing account for:`;
          }
          if (
            smartMiles &&
            invoiceAccount.products.find(
              (p) => p.productType === 'PersonalAuto'
            )
          ) {
            totalPriceDisplayHeader = 'approx. ';
          }
          const account: BillingDetailsFormAccount = {
            title,
            subtitle: invoiceAccount.products
              .map((product) =>
                ProductUtils.productBillingName(product.productType)
              )
              .join(', '),
            priceDisplay:
              totalPriceDisplayHeader +
              `${$(invoiceAccount.totalPayment)} / term total`,
            products: [],
          };

          for (const invoiceProduct of invoiceAccount.products) {
            title = ProductUtils.productBillingName(invoiceProduct.productType);
            let subtitle = '';
            if (smartMiles && invoiceProduct.productType === 'PersonalAuto') {
              title += ' (estimate)';
              subtitle = 'Installment amount due may vary with SmartMiles';
            }
            const product: BillingDetailsFormProduct = {
              title,
              subtitle,
              priceDisplay: `${$(invoiceProduct.totalPayment)} / ${
                invoiceProduct.termLength
              } mo. term`,
            };

            account.products.push(product);
          }

          // Fees are "products" for display purposes.
          for (const fee of invoiceAccount.fees) {
            const product: BillingDetailsFormProduct = {
              title: fee.description,
              subtitle: '',
              priceDisplay: `${$(fee.monthlyPayment)} / per payment`,
            };
            account.products.push(product);
          }

          accounts.push(account);
        }

        return {
          accounts,
          downPaymentDisplay: $(invoice.allProducts.downPayment),
          detailsOmittedDueToPolicyFee: false,
          warnAboutVaryingInstallmentCount,
          downPaymentsUnavailable: invoice.downPaymentsUnavailable,
        };
      })
    );
  }

  getBillingPlanDisplays(): Observable<BillingPlanDisplay[]> {
    return combineLatest([
      this.eligibleDiscountsService.isEligibleDiscountPresent(
        'BillingPaymentMethod'
      ),
      this.eligibleDiscountsService.isEligibleDiscountPresent('PaidInFull'),
      this.eligibleDiscountsService.isEligibleDiscountPresent('EasyPay'),
      this.telematicsService.getCurrentEnrollment(),
      this.productsService.getDocumentDeliveryPreference(),
      this.featureFlagsService.getAllFeatureFlags()
    ]).pipe(
      map(
        ([
          bpmPresent,
          pifPresent,
          ezPayPresent,
          telematics,
          docDeliveryPreference,
          featureFlags,
        ]) => {
          let plans = DefaultBillingPlans;
          if (telematics) {
            plans = PriceSummaryUtils.adjustBillingPlanDisplaysForTelematics(
              plans,
              telematics
            );
          }
          if (pifPresent) {
            plans =
              PriceSummaryUtils.adjustBillingPlanDisplaysForPaidInFullDiscount(
                plans
              );
          }
          if (ezPayPresent) {
            plans =
              PriceSummaryUtils.adjustBillingPlanDisplaysForEzPayDiscount(
                plans
              );
          }
          if (bpmPresent) {
            // When BillingPaymentMethod in play, we need to know the effective dates of the specific discounts...
            if (
              featureFlags.showRecurringEFTDiscountBoat ||
              featureFlags.showRecurringEFTDiscountMSA ||
              featureFlags.showRecurringEFTDiscountPersonalAuto ||
              featureFlags.showRecurringEFTDiscountRV
            ) {
              plans = PriceSummaryUtils.adjustBillingPlanDisplaysForReftDiscount(plans);
            }
            if (
              featureFlags.showFullPayDiscountBoat ||
              featureFlags.showFullPayDiscountCondominium ||
              featureFlags.showFullPayDiscountHomeowner ||
              featureFlags.showFullPayDiscountMSA ||
              featureFlags.showFullPayDiscountPersonalAuto ||
              featureFlags.showFullPayDiscountRV ||
              featureFlags.showFullPayDiscountTenant
            ) {
              plans = PriceSummaryUtils.adjustBillingPlanDisplaysForPaidInFullDiscount(plans);
            }
          }
          if (docDeliveryPreference === 'USMail') {
            plans =
              PriceSummaryUtils.adjustBillingPlanDisplaysForNonPaperless(plans);
          }
          return plans;
        }
      )
    );
  }

  orchestrateBillingPlanChangeWithSpinner(
    form: BillingPlansFormModel,
    rerate = true,
    callDownPayment = false,
    force = false
  ): Observable<never> {
    return this.getBillingPlansForm().pipe(
      take(1),
      switchMap((existingForm) => {
        if (
          existingForm.billingPlan !== form.billingPlan ||
          existingForm.billToEscrowAccount !== form.billToEscrowAccount ||
          force
        ) {
          return this.orchestrateBillingPlanChangeWithSpinnerNewPlan(
            form,
            rerate,
            callDownPayment
          );
        } else {
          this.updateBillingPlansForm(form);
          return from([]);
        }
      })
    );
  }

  private orchestrateBillingPlanChangeWithSpinnerNewPlan(
    form: BillingPlansFormModel,
    rerate: boolean,
    callDownPayment: boolean
  ): Observable<never> {
    const endLoading = this.loadingService.beginLoading(
      'billingPlan',
      'Changing billing plan...'
    );
    this.updateBillingPlansForm(form);
    return this.telematicsService
      .dropSmartMilesIfBillingPlanIncompatible(form.billingPlan)
      .pipe(
        switchMap(() => this.updateDiscountsForBillingPlan(form)),
        switchMap(() =>
          this.productsService.getSelectedProducts().pipe(take(1))
        ),
        switchMap((products) => this.updateCurrentBillToIfNeeded(products, form)),
        switchMap((productModels) =>
          rerate ? this.quoteService.bindRateAndWait(productModels) : of(null)
        ),
        switchMap(() =>
          callDownPayment
            ? this.billingService
                .refreshDownPaymentAndWait()
                .pipe(filter((loaded) => loaded))
            : of(null)
        ),
        take(1),
        filter(() => false),
        finalize(() => endLoading())
      ) as Observable<never>;
  }

  private updateCurrentBillToIfNeeded(
    products: ProductModel[],
    form: BillingPlansFormModel
  ): Observable<ProductModel[]> {
    const currentBillTo = form.billToEscrowAccount ? 'Mortgagee' : 'PolicyPriNamedInsured';
    const dirtyProductTypes: ProductType[] = [];
    for (const product of products) {
      if ((product.type !== 'Homeowner') && (product.type !== 'Condominium')) {
        continue;
      }
      if (product.currentBillTo === currentBillTo) {
        continue;
      }
      dirtyProductTypes.push(product.type);
    }
    if (!dirtyProductTypes.length) {
      return of(products);
    }
    return combineLatest(
      dirtyProductTypes.map(productType => this.mortgageService.getHasMortgageForProduct(productType, ['Mortgagee_Ext']))
    ).pipe(
      take(1),
      switchMap(hasMortgages => {
        if (hasMortgages.indexOf(false) >= 0) {
          return throwError(() => new Error('Must add mortgage lender to bill to escrow.'));
        }
        const calls = dirtyProductTypes.map(productType => this.quoteService.updateQuoteAndAwaitResponse(
          productType,
          { currentBillTo }
        ));
        return combineLatest(calls).pipe(
          map(() => products)
        );
      })
    );
  }

  private updateDiscountsForBillingPlan(
    form: BillingPlansFormModel
  ): Observable<unknown> {
    const calls: Observable<unknown>[] = [];
    if (form.billToEscrowAccount) {
      this.setPropertyDiscount(calls, 'BillingPaymentMethod', 'ThirdPartyBill');
      this.setPropertyDiscount(calls, 'EasyPay', 'false');
      this.setPropertyDiscount(calls, 'FullPay', 'false');
      this.setPropertyDiscount(calls, 'PaidInFull', 'false');
      this.setNonPropertyDiscount(
        calls,
        'BillingPaymentMethod',
        PriceSummaryUtils.paymentMethodDiscountValueForBillingPlan(form.billingPlan)
      );
      this.setNonPropertyDiscount(calls, 'EasyPay', form.billingPlan === 'MONTHLY EFT');
      this.setNonPropertyDiscount(calls, 'FullPay', form.billingPlan === 'PAY IN FULL');
      this.setNonPropertyDiscount(calls, 'PaidInFull', form.billingPlan === 'PAY IN FULL');
    } else {
      this.setAllDiscount(
        calls,
        'BillingPaymentMethod',
        PriceSummaryUtils.paymentMethodDiscountValueForBillingPlan(form.billingPlan)
      );
      this.setAllDiscount(calls, 'EasyPay', form.billingPlan === 'MONTHLY EFT');
      this.setAllDiscount(calls, 'FullPay', form.billingPlan === 'PAY IN FULL');
      this.setAllDiscount(calls, 'PaidInFull', form.billingPlan === 'PAY IN FULL');
    }
    return combineLatest(calls);
  }

  private setAllDiscount(calls: Observable<unknown>[], name: EligibleDiscountId, value: string | boolean): void {
    calls.push(this.eligibleDiscountsService.updateEligibleDiscountIfChanged(name, value.toString()));
  }

  private setPropertyDiscount(calls: Observable<unknown>[], name: EligibleDiscountId, value: string | boolean): void {
    calls.push(this.eligibleDiscountsService.updateEligibleDiscountIfChanged(
      name,
      value.toString(),
      (productType: ProductType) => ['Homeowner', 'Condominium'].includes(productType)
    ));
  }

  private setNonPropertyDiscount(calls: Observable<unknown>[], name: EligibleDiscountId, value: string | boolean): void {
    calls.push(this.eligibleDiscountsService.updateEligibleDiscountIfChanged(
      name,
      value.toString(),
      (productType: ProductType) => !['Homeowner', 'Condominium'].includes(productType)
    ));
  }

  private adjustBillingPlanDisplaysForTelematics(
    plans: BillingPlanDisplay[],
    telematics: VehicleEnrollment | MobileEnrollment
  ): BillingPlanDisplay[] {
    if ('vehicles' in telematics && telematics.vehicles?.length) {
      const smartMilesVehicle = telematics.vehicles.find(
        (vehicle) =>
          vehicle.enrollmentStatus === 'Enrolled' &&
          vehicle.vehicleProgram === 'SmartMiles'
      );
      if (smartMilesVehicle) {
        return plans.map((plan) => {
          if (
            plan.id === 'MONTHLY EFT' ||
            plan.id === 'MONTHLY RECURRING BANKCARD'
          ) {
            return plan;
          }
          return {
            ...plan,
            icon: 'exclamation-circle-filled',
            smartMiles:
              'This payment method is not available for SmartMiles variable monthly billing.',
          };
        });
      }
    }
    return plans;
  }

  private adjustBillingPlanDisplaysForPaidInFullDiscount(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'PAY IN FULL') {
        return {
          ...plan,
          discount: 'Paid in Full discount',
        };
      } else {
        return plan;
      }
    });
  }

  private adjustBillingPlanDisplaysForEzPayDiscount(
    plans: BillingPlanDisplay[]
  ): BillingPlanDisplay[] {
    return plans.map((plan) => {
      if (plan.id === 'MONTHLY EFT') {
        return {
          ...plan,
          discount: 'EasyPay discount',
        };
      } else {
        return plan;
      }
    });
  }

  private productDisplayName(
    products: ProductModel[],
    productType: ProductType
  ): string {
    const product = products.find((product) => product.type === productType);
    return product?.name || productType;
  }

  private subtitleForMortgage(mortgage: Nullable<MortgageEntity>): string {
    if (mortgage) {
      const COMPANY_NAME_LIMIT = 15;
      const LOAN_UNMASKED_DIGIT_COUNT = 4;
      let company = mortgage.companyName || '';
      if (company.length > COMPANY_NAME_LIMIT) {
        company = company.slice(0, COMPANY_NAME_LIMIT) + '...';
      }
      let maskedLoanNumber = mortgage.loanNumber || '';
      if (maskedLoanNumber.length > LOAN_UNMASKED_DIGIT_COUNT) {
        maskedLoanNumber =
          '*****' +
          maskedLoanNumber.slice(
            maskedLoanNumber.length - LOAN_UNMASKED_DIGIT_COUNT
          );
      }
      return `${company}, Loan # ${maskedLoanNumber}`;
    }
    return '';
  }

  private labelForQuoteStatus(status?: QuoteStatus): string {
    switch (status) {
      case 'NotStarted':
        return 'Pending';
      case 'Error':
        return 'Error';
      case 'Draft':
        return 'Pending';
      case 'Pending':
        return 'Rating pending';
      case 'Quoted':
        return 'Initial rate';
      case 'Binding':
        return 'Final rate';
      case 'Issued':
        return 'Final rate';
    }
    return '';
  }

  private generateSortedPriceSummaryProducts(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDisplayProduct[] {
    const displayProducts = input.selectedProducts?.map((product) => {
      const premium = (input.premiums || []).find(
        (p) => p.productType === product.type
      );

      const output: PriceSummaryDisplayProduct = {
        displayType: 'selected',
        type: product.type,
        name: product.name,
        imageUrl: product.imageUrl,
      };

      const draftTasks = !!input.draftTasks?.filter((task) => {
        return task.productType === product.type;
      }).length;

      if (product.quoteStatus === 'Error') {
        output.errorMessage = 'Rate unavailable.';
      } else if (product.quoteStatus === 'Pending') {
        // Leaving errorMessage and premiums unset causes an inline spinner.
      } else if (premium && premium.termMonths && premium.total?.amount) {
        output.installmentPrice = this.formatMoney(
          premium.total.amount / premium.termMonths
        );
        output.installmentFrequency = '\u00a0/ mo.';
        output.totalPrice = this.formatMoney(premium.total.amount);
        output.termLength = `\u00a0/ ${premium.termMonths} mos.`;
        if (product.type === 'PersonalAuto' && input.telematicsPresent) {
          output.helpText = '(Rate includes telematics)';
        }
      } else if (product.quoteStatus === 'Draft' && !draftTasks) {
        output.installmentPrice = '$--.--';
        output.installmentFrequency = '\u00a0/ mo.';
        const termLength =
          product.termType === 'HalfYear'
            ? 6
            : product.termType === 'Annual'
            ? 12
            : 0;
        if (termLength) {
          output.totalPrice = '$--.--';
          output.termLength = `\u00a0/ ${termLength} mos.`;
        }
      } else if (product.quoteStatus === 'Draft' && draftTasks) {
        output.warningMessage = 'Complete tasks for rating.';
      } else {
        // Undocumented case. Put at least something up, so it's not the spinner.
        output.installmentPrice = '$--.--';
      }

      return output;
    });
    return GeneralUtils.sortPriceSummaryDisplayProducts(displayProducts);
  }

  private formatMoney(amount: number): string {
    // If the input is fishy at all, return blank (don't default to zero, that would be misleading)
    if (typeof amount !== 'number' || isNaN(amount) || amount < 0) {
      return '';
    }
    // Careful here: Billing rounds everything down, so we must too:
    amount = Math.floor(amount * 100) / 100;
    return (
      '$' +
      amount.toLocaleString('en-us', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  private generatePriceSummaryUpsellProducts(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDisplayProduct[] {
    const output: PriceSummaryDisplayProduct[] = [];
    if (input.addOnProducts.length > 0) {
      const umbrella = input.addOnProducts.find(
        (p) => p.type === 'PersonalUmbrella'
      );
      if (
        umbrella &&
        !input.selectedProducts.find((p) => p.type === 'PersonalUmbrella') &&
        this.isUmbrellaEligible(input.selectedProducts)
      ) {
        const draftTasksPresent = input.draftTasks.length > 0;
        output.push({
          displayType: 'addOn',
          type: umbrella.type,
          name: umbrella.name,
          imageUrl: umbrella.imageUrl,
          helpText: draftTasksPresent
            ? 'Complete tasks below for quick add'
            : 'Quick add to see pricing',
          disabled: draftTasksPresent,
        });
      }
    }
    return output;
  }

  private generatePriceSummaryDownPayments(
    input: PriceSummaryDisplayContentInputs
  ): PriceSummaryDownPayment[] {
    const output: PriceSummaryDownPayment[] = [];
    if (input.premiums) {
      // Casting to number because tsc doesn't like undefined with binary operators.
      // In this case, we really do want Javascript's natural undefined-propagation, so cast it thru.
      const totalTerm = input.premiums
        .map((p) => p.total?.amount)
        .reduce((v, a) => (v as number) + (a as number), 0);
      // Sic: Floor each before adding, then round at the end.
      const totalMonthly =
        Math.round(
          input.premiums
            .map((p) =>
              Math.floor(
                ((p.total?.amount as number) * 100) / (p.termMonths as number)
              )
            )
            .reduce((v, a) => v + a, 0)
        ) / 100;
      if (totalTerm && totalMonthly) {
        output.push({
          name: 'est. per month',
          amount: this.formatMoney(totalMonthly),
        });
        output.push({
          name: 'est. total',
          amount: this.formatMoney(totalTerm),
          isEstTotal: true,
        });
      }
    }
    if (input.billingPlansForm.billToEscrowAccount) {
      const amount = this.getEscrowBillAmount(input);
      if (amount) {
        const escrowLine = {
          name: 'escrow total',
          amount: this.formatMoney(amount),
        };
        if (output.length >= 2) {
          output.splice(1, 0, escrowLine);
        } else {
          output.push(escrowLine);
        }
      }
    }
    return output;
  }

  private getEscrowBillAmount(input: PriceSummaryDisplayContentInputs): number {
    const returnPremium = input.premiums.find((premium) =>
      ProductUtils.isEscrowProduct(premium.productType)
    );
    return returnPremium?.total?.amount || 0;
  }

  private selectedBillingPlanForPriceSummary(
    input: PriceSummaryDisplayContentInputs
  ): string {
    if (this.priceSummaryIsEscrowOnly(input)) {
      return 'Escrow';
    }
    const planMetadata = DefaultBillingPlans.find(
      (dbp) => dbp.id === input.billingPlansForm?.billingPlan
    );
    if (planMetadata) {
      return planMetadata.name;
    }
    return '';
  }

  private billingPlanBylineForPriceSummary(
    input: PriceSummaryDisplayContentInputs
  ): string {
    if (this.priceSummaryIsEscrowOnly(input)) {
      return '';
    }
    if (input.paymentMethodDiscounts.length < 1) {
      return '';
    }
    if (input.billingPlansForm.billingPlan === 'PAY IN FULL') {
      if (
        input.paymentMethodDiscounts.find(
          (d) => d.description === 'Paid In Full Discount'
        )
      ) {
        return 'Paid in Full discount applied';
      }
      return '';
    }
    if (input.billingPlansForm.billingPlan === 'MONTHLY EFT') {
      if (
        input.paymentMethodDiscounts.find(
          (d) => d.description === 'Recurring EFT Discount'
        )
      ) {
        return 'EFT discount applied';
      }
      return '';
    }
    return 'Discount applied';
  }

  private priceSummaryIsEscrowOnly(
    input: PriceSummaryDisplayContentInputs
  ): boolean {
    return !!(
      input.billingPlansForm.billToEscrowAccount &&
      input.selectedProducts.length === 1 &&
      ProductUtils.isEscrowProduct(input.selectedProducts[0].type)
    );
  }

  private isUmbrellaEligible(products: ProductModel[]): boolean {
    return (
      products.some((product) => product.type === 'PersonalAuto') &&
      products.some(
        (product) =>
          product.type === 'Homeowner' ||
          product.type === 'Tenant' ||
          product.type === 'Condominium'
      )
    );
  }

  getPriceSummaryDisplayContentInputs(): Observable<PriceSummaryDisplayContent> {
    return combineLatest([
      this.premiumService.getPremiumsForSelectedProducts(),
      this.peopleService.getPrimaryNamedInsured(),
      this.productsService.getSelectedProducts(),
      this.productsService.getProduct('PersonalUmbrella'),
      this.productsService.getAddOnProducts(),
      this.billingService.getDownPayment(),
      this.productsService.getOverallQuoteStatus(),
      this.getBillingPlansForm(),
      this.quoteLetterService.getQuoteLetter(),
      this.taskService.getIncompleteDraftTasks(),
      this.discountsService.getAppliedPaymentMethodDiscounts(),
      this.telematicsService.isAnyTelematicsEnrolled(),
      this.quoteService.getQuoteInProgressCount(),
      this.errorService.getErrors()
    
    ]).pipe(
      map(
        ([
          premiums,
          pni,
          selectedProducts,
          umbrella,
          addOnProducts,
          downPayment,
          overallQuoteStatus,
          billingPlansForm,
          quoteLetterBase64,
          draftTasks,
          paymentMethodDiscounts,
          telematicsPresent,
          quoteInProgressCount,
          errors

        ]: // tslint:disable-next-line:no-any // it's a bit too much type
        any) =>
          PriceSummaryUtils.getPriceSummaryDisplayContent({
            premiums,
            pni,
            selectedProducts,
            umbrella,
            addOnProducts,
            downPayment,
            overallQuoteStatus,
            billingPlansForm,
            quoteLetterBase64,
            draftTasks,
            paymentMethodDiscounts,
            telematicsPresent,
            quoteInProgressCount,
            errors
          } as PriceSummaryDisplayContentInputs)
      )
    );
  }

  populateBillingAccountsModal(modal: BillingAccountsModalComponent): void {
    modal.modalTitle = 'Accounts & billing plans';
    this.getBillingAccountsModalInputs()
      .pipe(take(1))
      .subscribe((inputs) => {
        modal.billingPlansFormModel = inputs.billingPlansFormModel;
        modal.downPaymentResponse = inputs.downPaymentResponse;
        modal.productModels = inputs.productModels;
        modal.quoteState = inputs.quoteState;
        modal.hasMortgage = inputs.hasMortgage;
        modal.easyPayDiscountAmount = inputs.easyPayDiscountAmount;
        modal.featureFlags = inputs.featureFlags;
        modal.displayEscrowQuestion = !!inputs.productModels.find((product) => {
          return product.type === 'Homeowner' || product.type === 'Condominium';
        });
        modal.displayBillingPlanAsOptions = true;
      });
  }

  private getBillingAccountsModalInputs(): Observable<BillingAccountsModalInputs> {
    return combineLatest([
      this.getBillingPlansForm(),
      this.billingService.getDownPayment(),
      this.productsService.getSelectedProducts(),
      this.sessionService.getQuoteState(),
      this.mortgageService.hasMortgage(),
      this.store.select(getCurrentEnrollment),
      this.discountsService.getEasyPayDiscountAmount(),
      this.featureFlagsService.getAllFeatureFlags(),
    ]).pipe(
      map(
        ([
          billingPlansFormModel,
          downPaymentResponse,
          productModels,
          quoteState,
          hasMortgage,
          telematicsEnrollment,
          easyPayDiscountAmount,
          featureFlags,
        ]: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => ({
          billingPlansFormModel,
          downPaymentResponse,
          productModels,
          quoteState,
          hasMortgage,
          telematicsEnrollment,
          easyPayDiscountAmount,
          featureFlags,
        })
      )
    );
  }
}
