import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromProduct from '@core/store/entities/product/product.reducers';
import { ProductModel } from './product.model';
import {
  compareOrdinals,
  NationwideAccountRegistrationInfo,
  ProductType,
  quoteStatusGreaterThan,
  QuoteStatusOrdinality,
} from '@core/models/api/dsm-types';
import { DateUtils } from '@shared/utils/date.utils';
import { getEffectiveDate } from '../dsm/dsm.selector';
import { getQuoteState } from '../session/session.selector';
import { ProductButton } from '@core/interfaces/interfaces';
import { ProductUtils } from '@shared/utils/product.util';
import { WarningDisplayModel } from '@shared/components/notification-bar/notification-bar.component';

export const getProductState = createSelector(
  fromCore.getCoreState,
  (state) => state.products
);

export const { selectAll: getAllProducts, selectEntities: getProductEntities } =
  fromProduct.adapter.getSelectors(getProductState);

export const getProductsDataLoaded = createSelector(
  getProductState,
  (state) => state.loaded
);

export const getAllAvailableProducts = createSelector(
  getAllProducts,
  (products) => products.filter((product) => product.isAvailable)
);

export const getProduct = (productType: ProductType) =>
  createSelector(getProductEntities, (entities) => entities[productType]);

export const getEligibilityInformation = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.eligibilityInformation
  );

export const getProductModelsByTypes = (productTypes: ProductType[]) =>
  createSelector(getProductEntities, (entities) => {
    return productTypes
      .map((id) => entities[id])
      .filter((p) => !!p) as ProductModel[];
  });

export const getQuoteIdForProduct = (productType: ProductType) =>
  createSelector(
    getProductEntities,
    (entities) => entities[productType]?.quoteId
  );

export const getSelectedProductTypes = createSelector(
  getProductState,
  (state: fromProduct.ProductState) => state.selectedProducts
);

export const getSelectedProductEntities = createSelector(
  getAllProducts,
  getSelectedProductTypes,
  (products, selectedProductTypes) =>
    products.filter((p) => selectedProductTypes.includes(p.type))
);

export const getSelectedProducts = createSelector(
  getSelectedProductEntities,
  (selectedProducts) =>
    selectedProducts.filter((product) => product?.isDsmActive) as ProductModel[]
);

export const getSelectedActiveProductTypes = createSelector(
  getSelectedProducts,
  (products) => products.map((product) => product.type)
);

export const getSelectedProductsIncludingNWXInactive = createSelector(
  getSelectedProductEntities,
  (selectedProducts) => selectedProducts
);

export const getSelectedInactiveProducts = createSelector(
  getSelectedProductEntities,
  (selectedProducts) =>
    selectedProducts.filter(
      (product) => !product?.isDsmActive
    ) as ProductModel[]
);

export const getAnySelectedVehicleProduct = createSelector(
  getSelectedProductEntities,
  (selectedProducts) => {
    for (const product of selectedProducts) {
      switch (product.type) {
        case 'PersonalAuto':
          return product;
        case 'MSA':
          return product;
        case 'RV':
          return product;
        case 'Boat':
          return product;
      }
    }
    return null;
  }
);

export const getDocumentDeliveryPreference = createSelector(
  getSelectedProducts,
  (products) => products[0]?.docDelPreference // all products we chose have same docDeliveryPreference.
);

export const hasSelectedInactiveProducts = createSelector(
  getSelectedInactiveProducts,
  (unsupportedProducts) => unsupportedProducts.length > 0
);

export const selectAllSelectedProductsInitiated = createSelector(
  getSelectedProducts,
  (products) =>
    products.length > 0 &&
    products.some((product) => ProductUtils.IsQuoteStarted(product.quoteStatus))
);

export const getProductIfSelected = (productType: ProductType) =>
  createSelector(getSelectedProducts, (products) =>
    products.find((product) => product.type === productType)
  );
export const getProductIfSelectedIncludingInactive = (
  productType: ProductType
) =>
  createSelector(getSelectedProductsIncludingNWXInactive, (products) =>
    products.find((product) => product.type === productType)
  );

export const getPolicyDateOptions = (productType: ProductType) =>
  createSelector(
    getSelectedProducts,
    getEffectiveDate,
    (products, effectiveDate) => {
      if (productType === 'PersonalUmbrella') {
        return DateUtils.getUmbrellaDateOptions(products, effectiveDate);
      }
      const today = DateUtils.formatDateToDSM(effectiveDate);
      const futureDate = DateUtils.formatDateAsString(
        DateUtils.getFutureDate(today, 60)
      );
      return {
        minDate: today,
        maxDate: futureDate,
      };
    }
  );

export const getAddOnProducts = createSelector(getAllProducts, (products) =>
  products.filter((product) => product.isAddon && product.isDsmActive)
);

export const getProductsPending = createSelector(
  getSelectedProducts,
  (products) => products.some((product) => product.quoteStatus === 'Pending')
);

export const getSelectedQuoteProductsWithoutErrors = createSelector(
  getSelectedProducts,
  (selectedProducts) =>
    selectedProducts.filter(
      (product) => !product.hasError && product.type !== 'TermLife'
    )
);
export const getActiveSelectedProductsWithoutErrors = createSelector(
  getSelectedQuoteProductsWithoutErrors,
  (products) => products.filter((p) => p.isDsmActive)
);
export const getSelectedQuoteProductsWithoutErrorsAndQuoteStatus =
  createSelector(getSelectedQuoteProductsWithoutErrors, (selectedProducts) =>
    selectedProducts.filter((product) => !!product.quoteStatus)
  );

export const getSelectedProductsWithoutErrors = createSelector(
  getSelectedProducts,
  (selectedProducts) =>
    selectedProducts.filter(
      (product) => !product.hasError && product.type !== 'TermLife'
    )
);

export const getSelectedProductEntitiesWithErrors = createSelector(
  getSelectedProducts,
  (selectedProducts) =>
    selectedProducts.filter(
      (product) =>
        (product.hasError || product.quoteStatus === 'Error') &&
        product.type !== 'TermLife'
    )
);

export const getSelectedBlockedProductEntities = createSelector(
  getSelectedProducts,
  getQuoteState,
  (selectedProducts, quoteState) =>
    selectedProducts.filter(
      (product) =>
        product.type === 'MSA' &&
        quoteStatusGreaterThan(product.quoteStatus || 'Draft', 'Draft') &&
        quoteState === 'CA'
    )
);

export const getNeedConstructionInfo = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.needConstructionInfo || false
  );

export const getStatusOfAllProducts = createSelector(
  getSelectedProducts,
  (products) => products.map((product) => product.quoteStatus || 'Draft')
);

export const getStatusOfAllProductsWithoutErrors = createSelector(
  getSelectedProductsWithoutErrors,
  (products) => products.map((product) => product.quoteStatus || 'Draft')
);

export const getProductQuoteStatus = (productType: ProductType) =>
  createSelector(getProduct(productType), (product) => product?.quoteStatus);

export const getAllQuoteCoverageLoaded = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.allQuoteCoverageLoaded || false
  );

export const getOverallQuoteStatus = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    return (
      selectedProducts
        .map((p) => p.quoteStatus)
        .sort((a, b) => compareOrdinals(a, b, QuoteStatusOrdinality))[0] ||
      'Draft'
    );
  }
);

export const getOverallFurthestQuoteStatus = createSelector(
  getSelectedProducts,
  (selectedProducts) =>
    selectedProducts
      .map((p) => p.quoteFurthestStatus)
      .sort((a, b) => compareOrdinals(a, b, QuoteStatusOrdinality))[0] ||
    'Draft'
);

export const getProductFurthestQuoteStatus = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.quoteFurthestStatus
  );

export const isProductSelected = (productType: ProductType) =>
  createSelector(getSelectedProductTypes, (products) =>
    products.includes(productType)
  );

export const isOnlyProductSelected = (productType: ProductType) =>
  createSelector(
    getSelectedProductsWithoutErrors,
    (products) =>
      products.length === 1 &&
      products.map((product) => product.type).includes(productType)
  );

export const getProductNavigable = (productType: ProductType) =>
  createSelector(getProduct(productType), (product) => !!product?.quoteId);

export const isPersonalAutoSelected = createSelector(
  isProductSelected('PersonalAuto'),
  (isAutoSelected) => isAutoSelected
);

export const getProductTerm = (productType: ProductType) =>
  createSelector(getSelectedProducts, (entities) => {
    const productEntity = entities.find(
      (entity) => entity.type === productType
    );
    return productEntity?.termType || 'Annual';
  });

export const getOfferingType = (productType: ProductType) =>
  createSelector(getProduct(productType), (product) => {
    return product?.offeringType;
  });

export const isBindCompleted = createSelector(
  getSelectedQuoteProductsWithoutErrors,
  (products) => {
    return products.every(
      (product) =>
        product.quoteStatus === 'Binding' || product.quoteStatus === 'Issued'
    );
  }
);

export const isIssueCompleted = createSelector(
  getSelectedQuoteProductsWithoutErrors,
  (products) => {
    return products.every(
      (product) => product.issueCompleted && product.policyNumber
    );
  }
);

export const areAllProductsInitiated = createSelector(
  getSelectedProducts,
  (products) =>
    products.length > 0 &&
    products.every(
      (product) =>
        product.quoteId !== null || (product.hasError && !!product.errorCode)
    )
);

export const doAnyProductsHaveErrorsOrBlocked = createSelector(
  getSelectedProducts,
  getQuoteState,
  (products, quoteState) => {
    const doProductsHaveErrors = products.some(
      (p) => p.hasError || p.quoteStatus === 'Error'
    );
    const areProductsBlocked = products.some(
      (p) =>
        p.type === 'MSA' &&
        quoteStatusGreaterThan(p.quoteStatus || 'Draft', 'Draft') &&
        quoteState === 'CA'
    );
    return doProductsHaveErrors ?? areProductsBlocked;
  }
);

export const getIsAssignedRiskPlan = createSelector(
  getProduct('PersonalAuto'),
  (state) => (state ? state.isAssignedRiskPlan : false)
);

export const getProductEffectiveDate = (productId: ProductType) =>
  createSelector(
    getProduct(productId),
    (product) => product?.effectiveDate || DateUtils.getCurrentDateIso()
  );

export const reportsAndUWApplicable = createSelector(
  getSelectedProductEntities,
  (products) =>
    products
      .map((p) => p.type)
      ?.some((pType) => ProductUtils.ReportsAndUWProducts.includes(pType))
);

export const isProductDsmEnabled = (productType: ProductType) =>
  createSelector(getAllProducts, (products) =>
    products.some(
      (product) => product.type === productType && product.isDsmActive
    )
  );

export const getSelectedDriverProducts = () =>
  createSelector(getSelectedProducts, (products) =>
    products.filter((product) => ProductUtils.isDriverProduct(product.type))
  );

export const getProductButtons = (showSelectedProducts: boolean) =>
  createSelector(getSelectedProductEntities, (products) => {
    const addRemoveProductsButton = {
      altText: 'Plus sign inside circle',
      cssClass: 'blue',
      iconAction: 'plus',
      iconUrl: '',
      label: 'Add/remove products',
    };

    return showSelectedProducts
      ? ([
          ...products.map((p) => ({
            altText: p.type + 'icon',
            cssClass: '',
            iconAction: 'pencil',
            iconUrl: p.imageUrl || '',
            label: p.name || '',
          })),
          addRemoveProductsButton,
        ] as ProductButton[])
      : [addRemoveProductsButton];
  });

export const selectYearsWithPriorCarrier = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.yearsWithPriorCarrier
  );

export const getProducerCode = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    (product) => product?.producer?.producerCode
  );

export const getNationwideAccountRegistrationInfo = createSelector(
  getSelectedProducts,
  (products) =>
    products[0].nationwideAccountRegistrationInfo ||
    ({
      doesCustomerWantOnlineAccount: true,
      registrationLinkCommunicationMethod: 'ElectronicAddress',
    } as NationwideAccountRegistrationInfo) // will be same for all products (for now..?) phill17 07-2023
);

export const getProductWarningMessages = createSelector(
  getSelectedProducts,
  (products) => {
    const productsWithWarnings = products.filter((product) =>
      product.informationalMessages?.filter(
        (infoMsg) => infoMsg.warnings.length > 0
      )
    );
    let warningMessages: WarningDisplayModel[] = [];
    productsWithWarnings.forEach((product) =>
      product.informationalMessages?.forEach((infoMsg) =>
        infoMsg.warnings.map((warning) =>
          warningMessages.push({
            productType: product.type,
            message: warning.message,
            buttonLabel: 'Dismiss',
            buttonAction: 'dismiss',
          } as WarningDisplayModel)
        )
      )
    );
    return warningMessages;
  }
);
