import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  CoverageCodes,
  CoverageIds,
  CoveragesShowDescriptionInsteadOfName,
  StateExceptionCoverageLimit,
  StateStringReplacement,
} from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import {
  AllPerilWindHailDeductibleHelpText,
  CoverageHelpText,
  CustomEquipmentHelpText,
  FishingEquipmentHelpText,
  LimitedRoofLossHelpText,
  ProductSpecificCoverageHelpText,
  RCPELIMIT_HELP_TEXT,
  StateSpecificCoverageHelpText,
  WorkersCompensationHelpText,
} from '@shared/constants/help-text-constants';
import {
  CoverageEntity,
  CoverageTerm,
  CoverageOption,
  SelectedValue,
} from '@core/store/entities/coverage/coverage.entity';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { CoverageHelper } from '@core/helper/coverage.helper';
import { MSA_VEHICLE_SUB_TYPE_CLASSES } from '@shared/constants/msa-vehicle-type-constants';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';

@Injectable({
  providedIn: 'root',
})
export class CoverageRulesService {
  private excludedCoverageCodes = [CoverageCodes.BasicCoverageDeductible];
  private termCodesOfDescriptionsToInclude = [
    CoverageCodes.WindHailPerilDeductible,
    CoverageCodes.HurricanePerilDeductible,
    CoverageCodes.PersonalInjuryLimit,
  ];
  private termCodesOfTypesToExclude = [CoverageCodes.HurricanePerilDeductible];
  private quoteState: string = '';
  private featureFlags: FeatureFlagsModel = {} as FeatureFlagsModel;
  private isCompRater: boolean = false;

  constructor(private currencyPipe: CurrencyPipe) {}

  initCoverageRules(
    coverages: Nullable<CoverageEntity[]>,
    quoteState: string,
    featureFlags: FeatureFlagsModel,
    vehicles: Nullable<VehicleEntity[]>,
    coveredLocations: Nullable<CoveredLocationEntity[]>,
    previousCoverages: Nullable<CoverageEntity[]>,
    isCompRater: boolean = false
  ): CoverageEntity[] {
    if (!coverages) {
      return [];
    }

    this.quoteState = quoteState;
    this.featureFlags = featureFlags;
    this.isCompRater = isCompRater;

    return coverages
      .map((coverage) => this.updateCoverageAndTerms(coverage))
      .map((coverage) => this.addCoverageHelpText(coverage))
      .map((coverage) => this.updateCoverageName(coverage))
      .map((coverage) =>
        this.updateByCoverageId(
          coverage,
          coverages as any,
          vehicles,
          coveredLocations,
          previousCoverages
        )
      )
      .map((coverage) => this.addLabelForUneditableTerms(coverage));
  }

  private updateByCoverageId(
    coverage: CoverageEntity,
    coverages: CoverageEntity[],
    vehicles: Nullable<VehicleEntity[]>,
    coveredLocations: Nullable<CoveredLocationEntity[]>,
    previousCoverages: Nullable<CoverageEntity[]>
  ): CoverageEntity {
    const vehicle = vehicles?.find((v) => v.vehicleId === coverage.coverableId);

    switch (coverage.coverageId) {
      case CoverageIds.COMP:
        let updatedCompCoverage = coverage;
        updatedCompCoverage =
          this.updateFullSafetyGlassCoverage(updatedCompCoverage);
        updatedCompCoverage =
          this.updateAudioMediaCoverage(updatedCompCoverage);
        return updatedCompCoverage;

      case CoverageIds.COLL:
        if (coverage.terms) {
          coverage = {
            ...coverage,
            terms: [...coverage.terms].sort((a, b) =>
              a.code < b.code ? -1 : 1
            ),
          };
        }
        return coverage;

      case CoverageIds.LOI:
        return coverage.productId === 'MSA'
          ? { ...coverage, mandatory: true }
          : coverage;

      case CoverageIds.SectionIDeductibles:
        coverage = this.addCoverageSelectionIfPresentAndUnselected(
          coverage,
          CoverageCodes.WindHailPerilDeductible,
          'none'
        );
        coverage = this.addCoverageSelectionIfPresentAndUnselected(
          coverage,
          CoverageCodes.HurricanePerilDeductible,
          'none'
        );
        coverage = this.updateCoverageForPerilDeductible(coverage);
        coverage = this.updateCoverageForPerilDeductibleReminderHelpText(
          coverage,
          coveredLocations,
          coverages,
          previousCoverages
        );
        return coverage;

      case CoverageIds.BuildingAdditionsAlterations:
        return {
          ...coverage,
          name: 'Dwelling (Building Additions & Alterations)',
        };

      case CoverageIds.UnscheduledPersonalProperty:
        if (
          coverage.selectedValue?.find(
            (selectedValue) =>
              selectedValue.code ===
              CoverageCodes.UnscheduledPersonalPropertyDeductible
          )
        ) {
          const selectedValue = coverage.selectedValue.map(
            (currSelectedValue) => {
              if (
                currSelectedValue.code ===
                  CoverageCodes.UnscheduledPersonalPropertyDeductible &&
                !isNaN(+currSelectedValue.value)
              ) {
                return {
                  ...currSelectedValue,
                  value:
                    this.currencyPipe.transform(
                      +currSelectedValue.value,
                      '',
                      'symbol',
                      '1.0-0'
                    ) + ' deductible (per incident)',
                };
              }
              return currSelectedValue;
            }
          );
          return { ...coverage, selectedValue };
        }
        return coverage;

      case CoverageIds.UnscheduledSilverware:
        const personalPropertyBasicCoverageValue =
          this.getPersonalPropertyBasicCoverageValue(coverages);
        if (
          this.featureFlags.showAdditionalHelpTextForCoverages &&
          personalPropertyBasicCoverageValue &&
          coverage.selectedValue.length === 0
        ) {
          return {
            ...coverage,
            selectedValue: [
              {
                code: CoverageCodes.UnscheduledSilverwareLimit,
                value: (+personalPropertyBasicCoverageValue * 0.25).toString(),
              },
            ],
          };
        }
        return coverage;

      case CoverageIds.BioDeteriorationDamageCoverage:
        if (
          coverage.productId === 'Condominium' &&
          StateExceptionCoverageLimit[this.quoteState]
        ) {
          if (coverage.terms) {
            const nySpecificCoverage = coverage.terms
              .find(
                (term) =>
                  term.code ===
                  CoverageCodes.BioDeteriorationDamageCoverageLimit
              )
              ?.options.find((option) => option.value === '20000');
            if (
              nySpecificCoverage?.value ===
                StateExceptionCoverageLimit[this.quoteState][
                  CoverageIds.BioDeteriorationDamageCoverage
                ][CoverageCodes.BioDeteriorationDamageCoverageLimit].value &&
              coverage.selectedValue.length === 0
            ) {
              return {
                ...coverage,
                selectedValue: [
                  {
                    code: CoverageCodes.BioDeteriorationDamageCoverageLimit,
                    value: nySpecificCoverage.value,
                    description: nySpecificCoverage.description,
                  },
                ],
              };
            }
          }
        }
        return coverage;

      case CoverageIds.NCRPCOLL:
        if (
          !vehicle ||
          !vehicle.additionalInterests ||
          vehicle.additionalInterests.length < 1 ||
          vehicle.additionalInterests[0].additionalInterestType !== 'LESSOR'
        ) {
          return coverage;
        }
        return {
          ...coverage,
          available: false,
        };

      case CoverageIds.PIP:
        const limitOption = coverage.selectedValue?.find(
          (term) => term.code === CoverageCodes.PIPLimit
        );
        const optionOption = coverage.selectedValue?.find(
          (term) => term.code === CoverageCodes.PIPOption
        );
        if (
          coverage.productId === 'PersonalAuto' &&
          limitOption?.value === '50000' &&
          optionOption !== undefined
        ) {
          return {
            ...coverage,
            selectedValue: [
              limitOption,
              {
                ...optionOption,
                value: '7',
                description: 'NotCoordinated',
              } as SelectedValue,
            ],
            terms: coverage.terms.map((term) => {
              return {
                ...term,
                editable: term.code === CoverageCodes.PIPLimit ? true : false,
              };
            }),
          };
        } else if (coverage.productId === 'RV' && coverage.available) {
          return CoverageHelper.sortPipTerms(coverage, this.quoteState);
        } else if (coverage.available && coverage.editable && !coverage.terms?.[0]?.editable) {
          const pipCoverageHasLimitSelected = coverage.selectedValue?.some(
            (value) => value.code === 'PIPLimit' && value.value?.length > 0
          );
            return {
              ...coverage,
              isEditableAndTermUneditable: false,
              hasDummyTerms: true,
              terms: [
                {
                  isDummyTerm: true,
                  editable: false,
                  code: 'selected',
                  description: 'selected',
                  spreadable: false,
                  type: '',
                  options: [
                    {
                      optional: false,
                      description: 'Accept',
                      value: 'true',
                    } as CoverageOption,
                    {
                      optional: false,
                      description: 'Decline',
                      value: 'false',
                    } as CoverageOption,
                  ],
                },
                ...coverage.terms.filter((term) => {
                  // if selected, return the limit term, otherwise return no terms
                  if (pipCoverageHasLimitSelected) {
                    return term.code === 'PIPLimit';
                  } else {
                    return false;
                  }
                }),
              ],
            };
        } else {
            return {
              ...coverage,
              editable: true,
            };
        }
      case CoverageIds.GAPCOLL:
        if (!vehicle || vehicle.additionalInterests?.length) {
          return coverage;
        }
        if (!this.isCompRater) {
          return {
            ...coverage,
            available: false,
          };
        }
        return coverage;

      case CoverageIds.WorkersCompensation:
        if (this.featureFlags.workersCompensationHelpText) {
          return {
            ...coverage,
            reminderHelpText: WorkersCompensationHelpText,
            available: false,
          };
        }
        return coverage;

      case CoverageIds.FSHEQ:
        const updatedCoverage =
          CoverageUtils.removeNonSelectedTermOptionsForPackageCoverages(
            coverages,
            coverage
          );
        return {
          ...updatedCoverage,
          reminderHelpText: FishingEquipmentHelpText,
        };
        break;
      case CoverageIds.CustomEquipment:
        if (coverage.productId === 'RV') {
          return { ...coverage, helpText: CustomEquipmentHelpText };
        } else if (coverage.productId === 'MSA') {
          return { ...coverage };
        } else {
          return { ...coverage, reminderHelpText: CustomEquipmentHelpText };
        }

      case CoverageIds.ScheduledPersonalEffects:
        return {
          ...coverage,
          coverageWithScheduledItems: true,
          isEditableAndTermUneditable: true,
          hasDummyTerms: true,
          terms: [
            {
              isDummyTerm: true,
              editable: false,
              code: 'SPEselected',
              description: 'selected',
              spreadable: false,
              type: '',
              options: [
                {
                  optional: false,
                  description: 'Accept',
                  value: 'true',
                } as CoverageOption,
                {
                  optional: false,
                  description: 'Decline',
                  value: 'false',
                } as CoverageOption,
              ],
            },
          ],
        };
      case CoverageIds.RCPE:
        const coverageHasLimitSelected = coverage.selectedValue?.some(
          (value) => value.code === 'RCPELimit' && +value.value > 0
        );
        if (coverage.available) {
          return {
            ...coverage,
            isEditableAndTermUneditable: true,
            hasDummyTerms: true,
            terms: [
              {
                isDummyTerm: true,
                editable: false,
                code: 'RCPEselected',
                description: 'selected',
                spreadable: false,
                type: '',
                options: [
                  {
                    optional: false,
                    description: 'Accept',
                    value: 'true',
                  } as CoverageOption,
                  {
                    optional: false,
                    description: 'Decline',
                    value: 'false',
                  } as CoverageOption,
                ],
              },
              ...coverage.terms.filter((term) => {
                // if selected, return the limit term, otherwise return no terms
                if (coverageHasLimitSelected) {
                  return term.code === 'RCPELimit';
                } else {
                  return false;
                }
              }),
            ],
          };
        }
        break;
        // Added case for UIMBI
        case CoverageIds.UnderinsuredMotoristBodilyInjury:
          const uimbiCoverageHasLimitSelected = coverage.selectedValue?.some(
            (value) => value.code === 'UIMBILimit' && value.value?.length > 0
          );
          if (coverage.available && coverage.editable && !coverage.terms?.[0]?.editable
          ) {
            return {
              ...coverage,
              isEditableAndTermUneditable: false,
              hasDummyTerms: true,
              terms: [
                {
                  isDummyTerm: true,
                  editable: false,
                  code: 'selected',
                  description: 'selected',
                  spreadable: false,
                  type: '',
                  options: [
                    {
                      optional: false,
                      description: 'Accept',
                      value: 'true',
                    } as CoverageOption,
                    {
                      optional: false,
                      description: 'Decline',
                      value: 'false',
                    } as CoverageOption,
                  ],
                },
                ...coverage.terms.filter((term) => {
                  // if selected, return the limit term, otherwise return no terms
                  if (uimbiCoverageHasLimitSelected) {
                    return term.code === 'UIMBILimit';
                  } else {
                    return false;
                  }
                }),
              ],
            };
          }
          break;
      case CoverageIds.EarthquakeBasicCoverage:
        const eqCoverageSelected = !coverage.selectedValue.find(
          (v) => v.code === 'selected' && v.value === 'false'
        );
        const selectedValue = [...coverage.selectedValue];
        if (eqCoverageSelected) {
          selectedValue.push({ code: 'selected', value: 'true' });
        }
        const updatedEQCoverage = {
          ...coverage,
          selectedValue,
          hasDummyTerms: true,
          terms: [
            {
              isDummyTerm: true,
              editable: false,
              code: 'selected',
              description: 'selected',
              spreadable: false,
              type: '',
              options: [
                {
                  optional: false,
                  description: 'Accept',
                  value: 'true',
                } as CoverageOption,
                {
                  optional: false,
                  description: 'Decline',
                  value: 'false',
                } as CoverageOption,
              ],
            },
            ...coverage.terms,
          ],
        };
        return updatedEQCoverage;
      case CoverageIds.FTSS:
        const ftssCoverageHasLimitSelected = coverage.selectedValue?.some(
          (value) => value.code === 'FTSSLimit' && +value.value > 0
        );
        if (coverage.available) {
          return {
            ...coverage,
            isEditableAndTermUneditable: true,
            hasDummyTerms: true,
            terms: [
              {
                isDummyTerm: true,
                editable: false,
                code: 'FTSSSelected',
                description: 'selected',
                spreadable: false,
                type: '',
                options: [
                  {
                    optional: false,
                    description: 'Accept',
                    value: 'true',
                  } as CoverageOption,
                  {
                    optional: false,
                    description: 'Decline',
                    value: 'false',
                  } as CoverageOption,
                ],
              },
              ...coverage.terms.filter((term) => {
                // if selected, return the limit term, otherwise return no terms
                if (ftssCoverageHasLimitSelected) {
                  return term.code === 'FTSSLimit';
                } else {
                  return false;
                }
              }),
            ],
          };
        }
        break;
      case 'PEREF':
      case 'TL':
        return CoverageUtils.removeNonSelectedTermOptionsForPackageCoverages(
          coverages,
          coverage
        );
      case 'PPI':
      case 'LPD':
      case 'Sinkhole':
        return CoverageUtils.removeTermsAndForceSelectedValueBoolean(coverage);
      case CoverageIds.FarmLiability:
      case CoverageIds.PersonalInjury:
        const updatedLiabilityCoverage = {
          ...coverage,
          terms: coverage.terms?.length
            ? [
                {
                  ...coverage.terms[0],
                  editable: true,
                },
              ]
            : [],
        };
        return CoverageUtils.removeNonSelectedTermOptionsForLiabilityCoverages(
          coverages,
          updatedLiabilityCoverage
        );
      case CoverageIds.PersonalPropOtherRes:
        const pporCoverageSelected = !coverage.selectedValue.find(
          (v) => v.code === 'selected' && v.value === 'false'
        );
        const pporSelectedValue = [...coverage.selectedValue];
        if (pporCoverageSelected) {
          pporSelectedValue.push({ code: 'selected', value: 'true' });
        }
        const updatedPporCoverage =
          pporSelectedValue[0]?.code === 'PersonalPropOtherResLimit'
            ? {
                ...coverage,
                selectedValue: pporSelectedValue,
                coverageWithDeclinableInputLimit: true,
              }
            : {
                ...coverage,
                selectedValue: pporSelectedValue,
                coverageWithDeclinableInputLimit: true,
                terms: [
                  {
                    editable: true,
                    code: 'selected',
                    description: 'selected',
                    spreadable: false,
                    type: '',
                    options: [],
                  },
                  ...coverage.terms,
                ],
              };
        return updatedPporCoverage;
      case CoverageIds.PDSO:
        if (
          coverage.productId === 'MSA' &&
          MSA_VEHICLE_SUB_TYPE_CLASSES.find(
            (subTypeClass) =>
              subTypeClass.code === vehicle?.subTypeClass &&
              subTypeClass.uiQuestions?.includes('AgreedValue')
          ) &&
          !coverage.selectedValue?.find(
            (value) => value.description === 'Agreed Value'
          )
        ) {
          return {
            ...coverage,
            selectedValue: [
              {
                code: 'PDSOOption',
                description: 'Agreed Value',
                value: '2',
              } as SelectedValue,
            ],
          };
        }
        return coverage;
      case CoverageIds.LimitedRoofLoss:
        if (coverage.mandatory) {
          return {
            ...coverage,
            reminderHelpText: LimitedRoofLossHelpText,
          };
        }
        return coverage;
    }

    return coverage;
  }

  private modifyTermByTermCode(term: CoverageTerm): CoverageTerm {
    if (!this.termCodesOfDescriptionsToInclude.includes(term.code)) {
      term = { ...term, description: '' };
    }
    if (this.termCodesOfTypesToExclude.includes(term.code)) {
      term = { ...term, type: '' };
    }

    switch (term.code) {
      case CoverageCodes.ServiceLineDeductible:
        term.type = 'Service Line Deductible';
        break;
      case CoverageCodes.RCPELimit:
        term.instructionalText = RCPELIMIT_HELP_TEXT;
        break;
    }

    return term;
  }

  private modifyOptionsByTermCode(
    termCode: string,
    option: CoverageOption
  ): CoverageOption {
    switch (termCode) {
      case CoverageCodes.IncreasedLossOfUseALS:
        return {
          ...option,
          description: 'Actual Loss Sustained',
        };
      case CoverageCodes.UMPDDeductible:
        return {
          ...option,
          description: '$' + option.value + ' Deductible',
        };
      case CoverageCodes.ServiceLineDeductible:
        return {
          ...option,
          description: '$' + option.value + ' if Accepted',
          optional: false,
        };
    }
    return option;
  }

  private updateFullSafetyGlassCoverage(
    coverage: CoverageEntity
  ): CoverageEntity {
    const fullSafetyGlassTermIndex = coverage?.terms?.findIndex(
      (term) => term.code === 'COMPSafetyGlass'
    );
    if (fullSafetyGlassTermIndex >= 0) {
      const terms = this.setUpSafetyGlassTerms(
        coverage,
        [...coverage.terms],
        fullSafetyGlassTermIndex
      );

      return {
        ...coverage,
        terms,
      };
    }
    return coverage;
  }

  private setUpSafetyGlassTerms(
    coverage: CoverageEntity,
    terms: CoverageTerm[],
    fullSafetyGlassTermIndex: number
  ): CoverageTerm[] {
    terms?.splice(fullSafetyGlassTermIndex, 1);
    if (!coverage.selectedValue.some((v) => v.code === 'COMPSafetyGlass')) {
      terms.push({
        ...coverage.terms[fullSafetyGlassTermIndex],
        editable: true,
      });
    } else {
      const fullSafetyGlassOptions = coverage.terms[
        fullSafetyGlassTermIndex
      ].options?.filter((option) => option.value !== 'none');
      terms.push({
        ...coverage.terms[fullSafetyGlassTermIndex],
        editable: true,
        options: fullSafetyGlassOptions,
      });
    }
    return terms;
  }

  private updateAudioMediaCoverage(coverage: CoverageEntity): CoverageEntity {
    const audioMediaTermIndex = coverage?.terms?.findIndex(
      (term) => term.code === 'COMPAudioMedia'
    );
    if (audioMediaTermIndex >= 0) {
      const terms = this.setUpAudioMediaTerms(
        coverage,
        [...coverage.terms],
        audioMediaTermIndex
      );

      return {
        ...coverage,
        terms,
      };
    }
    return coverage;
  }

  private setUpAudioMediaTerms(
    coverage: CoverageEntity,
    terms: CoverageTerm[],
    audioMediaTermIndex: number
  ): CoverageTerm[] {
    terms?.splice(audioMediaTermIndex, 1);
    if (!coverage.selectedValue.some((v) => v.code === 'COMPAudioMedia')) {
      terms.push({
        ...coverage.terms[audioMediaTermIndex],
        description: coverage.terms[audioMediaTermIndex].type,
        editable: true,
      });
    } else {
      const audioMediaOptions = coverage.terms[
        audioMediaTermIndex
      ].options?.filter((option) => option.value !== 'none');
      terms.push({
        ...coverage.terms[audioMediaTermIndex],
        description: coverage.terms[audioMediaTermIndex].type,
        editable: true,
        options: audioMediaOptions,
      });
    }
    return terms;
  }

  private getPersonalPropertyBasicCoverageValue(
    coverages: CoverageEntity[]
  ): Nullable<string> {
    return coverages
      .find((coverage) => coverage.coverageId === CoverageIds.BasicCoverage)
      ?.selectedValue.find(
        (selected) => selected.code === CoverageCodes.BasicCoverageLimit
      )?.value;
  }

  private addCoverageSelectionIfPresentAndUnselected(
    coverage: CoverageEntity,
    code: string,
    defaultValue: string
  ): CoverageEntity {
    if (!coverage.terms.find((term) => term.code === code)) {
      return coverage;
    }
    if (coverage.selectedValue.find((value) => value.code === code)) {
      return coverage;
    }
    return {
      ...coverage,
      selectedValue: [...coverage.selectedValue, { code, value: defaultValue }],
    };
  }

  private addCoverageHelpText(coverage: CoverageEntity): CoverageEntity {
    return {
      ...coverage,
      helpText: this.getHelpText(coverage.coverageId, coverage.productId),
    };
  }

  private addCoverageTermHelpText(
    term: CoverageTerm,
    selectedValue: SelectedValue[],
    productId: ProductType
  ): CoverageTerm {
    let helpText = this.getHelpText(term.code, productId);

    if (term.code === CoverageCodes.TREXOption && selectedValue?.length > 0) {
      const teOption = selectedValue.find((x) => x.code === term.code);
      if (teOption?.value === '800') {
        helpText = CoverageHelpText[`${term.code}800`];
      }
    }

    return {
      ...term,
      helpText,
    };
  }

  private getHelpText(code: string, productId: ProductType): string {
    if (
      ProductSpecificCoverageHelpText[productId] &&
      typeof ProductSpecificCoverageHelpText[productId][code] === 'string'
    ) {
      return ProductSpecificCoverageHelpText[productId][code];
    } else if (
      StateSpecificCoverageHelpText[this.quoteState] &&
      typeof StateSpecificCoverageHelpText[this.quoteState][code] === 'string'
    ) {
      return StateSpecificCoverageHelpText[this.quoteState][code];
    } else {
      return CoverageHelpText[code];
    }
  }

  private updateCoverageName(coverage: CoverageEntity): CoverageEntity {
    if (
      StateStringReplacement[this.quoteState] &&
      StateStringReplacement[this.quoteState][coverage.name]
    ) {
      return {
        ...coverage,
        name: StateStringReplacement[this.quoteState][coverage.name]
          .replacement,
      };
    }
    if (CoveragesShowDescriptionInsteadOfName.includes(coverage.coverageId)) {
      if (coverage.description === 'Loan/Lease Gap-Collision') {
        return {
          ...coverage,
          name: 'Loan/Lease Gap - Collision',
        };
      }
      return {
        ...coverage,
        name: coverage.description,
      };
    }
    return coverage;
  }

  private addLabelForUneditableTerms(coverage: CoverageEntity): CoverageEntity {
    return {
      ...coverage,
      selectedValue: coverage.selectedValue?.map((selectedValue) => {
        const term = coverage.terms?.find((t) => t.code === selectedValue.code);
        if (!term || term.editable || !term.description) {
          return selectedValue;
        }
        return {
          ...selectedValue,
          description: `${term.description} ${selectedValue.description}`,
        };
      }),
    };
  }

  private updateCoverageAndTerms(coverage: CoverageEntity): CoverageEntity {
    if (!coverage.terms) {
      return coverage;
    }
    return {
      ...coverage,
      terms: coverage.terms
        ?.filter((term) => !this.excludedCoverageCodes.includes(term.code))
        .map((term, index) => {
          if (index > 0) {
            term = this.addCoverageTermHelpText(
              term,
              coverage.selectedValue,
              coverage.productId
            );
          }

          term = this.modifyTermByTermCode(term);

          return term.options
            ? {
                ...term,
                options: this.updateCoverageTermOptions(term),
              }
            : term;
        }),
    };
  }

  private updateCoverageTermOptions(term: CoverageTerm): CoverageOption[] {
    return term.options.map((option) => {
      option = {
        ...option,
        description: option.description.split('|')[0].trim(),
      };
      return this.modifyOptionsByTermCode(term.code, option);
    });
  }

  updateCoverageForPerilDeductible(coverage: CoverageEntity): CoverageEntity {
    if (
      !coverage.terms.find(
        (term) => term.code === CoverageCodes.AllPerilDeductible
      )
    ) {
      return { ...coverage, name: 'Deductibles' };
    }

    const terms = coverage?.terms?.map((term) => {
      return {
        ...term,
        type: term.type ? term.type : term.description + ' Deductible',
        description: '',
      };
    });
    return { ...coverage, name: 'All Other Perils Deductible', terms };
  }

  updateCoverageForPerilDeductibleReminderHelpText(
    coverage: CoverageEntity,
    coveredLocations: Nullable<CoveredLocationEntity[]>,
    coverages: CoverageEntity[],
    previousCoverages: Nullable<CoverageEntity[]>
  ): CoverageEntity {
    const homeownerCoveredLocation = coveredLocations?.find(
      (loc) => loc.productType === 'Homeowner'
    );
    const previousAllPerilsDeductible = previousCoverages
      ?.find((cov) => cov.coverageId === CoverageIds.SectionIDeductibles)
      ?.selectedValue?.find((val) => val.code === 'AllPerilDeductible')?.value;
    const currentAllPerilsDeductible = coverages
      ?.find((cov) => cov.coverageId === CoverageIds.SectionIDeductibles)
      ?.selectedValue?.find((val) => val.code === 'AllPerilDeductible')?.value;
    const previousWindHailDeductbile = previousCoverages
      ?.find((cov) => cov.coverageId === CoverageIds.SectionIDeductibles)
      ?.selectedValue?.find(
        (val) => val.code === 'WindHailPerilDeductible'
      )?.value;
    const currentWindHailDeductible = coverages
      ?.find((cov) => cov.coverageId === CoverageIds.SectionIDeductibles)
      ?.selectedValue?.find(
        (val) => val.code === 'WindHailPerilDeductible'
      )?.value;
    if (
      homeownerCoveredLocation?.wasReconstructionCostUpdated &&
      (previousAllPerilsDeductible !== currentAllPerilsDeductible ||
        previousWindHailDeductbile !== currentWindHailDeductible)
    ) {
      return {
        ...coverage,
        reminderHelpText: AllPerilWindHailDeductibleHelpText,
      };
    }
    return coverage;
  }

  // TODO: API work is pending as well as UI design, revisit this later
  // hasInvalidPIPLimit(
  //   coverageChanges: CoverageChange[],
  //   drivers: DriverEntity[],
  //   stateSpecificFlags: StateSpecificFlagsObject
  // ): boolean {
  //   if (!stateSpecificFlags.healthCarePlan]) {
  //     return false;
  //   }
  //   let returnValue = false;
  //   const pipFiftyThousandLimitCoverageChanges = coverageChanges?.filter(
  //     (change) =>
  //       change.coverageId === CoverageIds.PIP &&
  //       change.selectedValue?.filter(
  //         (value) =>
  //           value.code === CoverageCodes.PIPLimit && value.value === '50000'
  //       ).length === 1
  //   );
  //   if (!pipFiftyThousandLimitCoverageChanges.length) {
  //     return false;
  //   }

  //   if (
  //     drivers?.filter(
  //       (driver) =>
  //         driver.driverType === 'Driver' &&
  //         driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI &&
  //         driver.healthCarePlan !== HealthCarePlans.MEDICAID
  //     ).length
  //   ) {
  //     returnValue = true;
  //   } else if (drivers.length > 1) {
  //     // const applicable
  //     const driversWhoAreRelatives = drivers?.filter(
  //       (driver) =>
  //         driver.driverType === 'Driver' &&
  //         ['SPOUSE', 'CHILD', 'OTHER_RELATIVE'].includes(
  //           driver.relationToPrimaryNamedInsured || ''
  //         )
  //     );
  //     const driversWhoAreRelativesWithHealthCarePlans = drivers?.filter(
  //       (driver) =>
  //         driver.driverType === 'Driver' &&
  //         ['SPOUSE', 'CHILD', 'OTHER_RELATIVE'].includes(
  //           driver.relationToPrimaryNamedInsured || ''
  //         ) &&
  //         [
  //           'QualifyingHealthCoverage',
  //           'Medicare',
  //           'Medicaid',
  //           'OtherPIPHealthBenefitPolicy',
  //         ].includes(driver.healthCarePlan || '')
  //     );
  //     if (
  //       driversWhoAreRelatives.length !==
  //       driversWhoAreRelativesWithHealthCarePlans.length
  //     ) {
  //       returnValue = true;
  //     }
  //   }
  //   return returnValue;
  // }
}
