import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import * as fromScheduledCategories from '@core/store/entities/scheduled-categories/scheduled-category.reducer';
import { getProduct, getProductEntities } from '../product/product.selectors';
import { ScheduledCategoryMetadata } from '@core/constants/scheduled-categories-metadata';
import {
  DisplayGroupingType,
  ScheduledCategoriesViewModel,
  ScheduledCategoryEntity,
  ScheduledCategoryItem,
} from './scheduled-category.entity';
import { ProductType } from '@core/models/api/dsm-types';
import {
  UpdateScheduledCategoryRequest,
  UpdateScheduledPersonalEffectRequest,
} from '@core/models/api/request/scheduled-categories.request.model';
import { ProductsSelectors } from '@core/store/selectors';

export const getScheduledCategoriesState = createSelector(
  fromCore.getCoreState,
  (state) => state.scheduledCategories
);

export const {
  selectAll: getAllScheduledCategories,
  selectEntities: getScheduledCategoryEntities,
} = fromScheduledCategories.adapter.getSelectors(getScheduledCategoriesState);

export const getAllScheduledCategoriesByProduct = (productType: ProductType) =>
  createSelector(
    getScheduledCategoryEntities,
    (entities) => entities[productType]?.scheduledCategories
  );

export const getScheduledCategoriesForSelectedProduct = createSelector(
  getScheduledCategoryEntities,
  ProductsSelectors.getSelectedProductTypes,
  (entities, productTypes) => {
    let allScheduledCategories: ScheduledCategoryEntity[] = [];
    for (const productType of productTypes) {
      const cats = entities[productType]?.scheduledCategories;
      if (cats) {
        allScheduledCategories = allScheduledCategories.concat(cats);
      }
    }
    return allScheduledCategories;
  }
);

export const getScheduledCategoriesLoaded = createSelector(
  getScheduledCategoriesState,
  (state) => state.loaded && !state.loading
);

export const getScheduledCategoriesLoading = createSelector(
  getScheduledCategoriesState,
  (state) => state.loading > 0
);

export const buildScheduledCategoryRequest = (item: ScheduledCategoryEntity) =>
  createSelector(getProduct(item.productType as ProductType), (product) => {
    return {
      quoteId: product?.quoteId,
      productType: product?.type,
      item,
    } as UpdateScheduledCategoryRequest;
  });

export const getGroupedScheduledCategoriesByProduct = (
  product: ProductType,
  grouping: DisplayGroupingType
) =>
  createSelector(
    getAllScheduledCategoriesByProduct(product),
    (scheduledCategories) =>
      scheduledCategories?.filter(
        (scheduledCategory) =>
          ScheduledCategoryMetadata[scheduledCategory.scheduledCategoryId]
            ?.displayGrouping === grouping
      )
  );

export const getScheduledCategoriesViewModel = (
  productType: ProductType,
  grouping: DisplayGroupingType
) =>
  createSelector(
    getGroupedScheduledCategoriesByProduct(productType, grouping),
    (groupedCategories) => {
      return {
        title: getViewModelTitle(grouping),
        displayGrouping: grouping,
        scheduledCategories: groupedCategories,
      } as ScheduledCategoriesViewModel;
    }
  );

export const findCategoryByScheduledItemID = (
  productType: ProductType,
  itemId: string
) =>
  createSelector(
    getAllScheduledCategoriesByProduct(productType),
    (categories) =>
      categories?.find((category) =>
        category.scheduledItems?.find((item) => item.itemId === itemId)
      )
  );

export const findCategoryByEntityId = (
  productType: ProductType,
  entityId: string
) =>
  createSelector(
    getAllScheduledCategoriesByProduct(productType),
    (categories) => {
      return categories?.find((c) => c.scheduledCategoryId === entityId);
    }
  );

export const getAllScheduledItems = (productType: ProductType) =>
  createSelector(getAllScheduledCategoriesByProduct(productType), (state) => {
    const allScheduledItems: ScheduledCategoryItem[] = [];
    const personalEffectsWithItems = state?.filter(
      (effect) => effect.scheduledItems?.length
    );
    personalEffectsWithItems?.forEach((effect) => {
      effect.scheduledItems?.forEach((item) => {
        allScheduledItems.push({
          ...item,
          scheduledCategoryId: effect.scheduledCategoryId,
        });
      });
    });
    return allScheduledItems;
  });

export const getDisplayGroupLoading = createSelector(
  getScheduledCategoriesState,
  (state) => state.categoryLoading
);

export const getScheduledPersonalEffectByCategoryId = (
  id: string,
  productType: ProductType
) =>
  createSelector(getAllScheduledCategoriesByProduct(productType), (entities) =>
    entities?.find((e) => e.scheduledCategoryId === id)
  );

export const getScheduledPersonalEffectByItem = (
  item: ScheduledCategoryItem,
  productType: ProductType
) =>
  createSelector(
    getScheduledPersonalEffectByCategoryId(
      item.scheduledCategoryId,
      productType
    ),
    (personalEffect) => personalEffect
  );

export const buildScheduledPersonalEffectRequest = (
  item: ScheduledCategoryItem,
  productType: ProductType
) =>
  createSelector(
    getProductEntities,
    getScheduledPersonalEffectByItem(item, productType),
    (products, personalEffect) => {
      const product = products[personalEffect?.productType as ProductType];
      const itemsWithCategoryIds = personalEffect?.scheduledItems
        ?.map((item) => {
          return {
            ...item,
            scheduledCategoryId: personalEffect.scheduledCategoryId,
          };
        })
        ?.filter((i) => i.itemId !== item.itemId);
      return {
        quoteId: product?.quoteId,
        productType: product?.type,
        scheduledPersonalEffect: {
          ...personalEffect,
          scheduledItems: [...(itemsWithCategoryIds || []), item],
        },
      } as UpdateScheduledPersonalEffectRequest;
    }
  );

export const buildRemoveScheduledPersonalEffectRequest = (
  item: ScheduledCategoryItem,
  productType: ProductType
) =>
  createSelector(
    getProductEntities,
    getScheduledPersonalEffectByItem(item, productType),
    (products, personalEffect) => {
      const product = products[personalEffect?.productType as ProductType];
      const remainingPersonalEffects = personalEffect?.scheduledItems?.filter(
        (i) => i.itemId !== item.itemId
      );
      return {
        quoteId: product?.quoteId,
        productType: product?.type,
        scheduledPersonalEffect: {
          ...personalEffect,
          scheduledItems: remainingPersonalEffects,
        },
      } as UpdateScheduledPersonalEffectRequest;
    }
  );

export const getScheduledPersonalEffectsCombinedValue = (
  productType: ProductType
) =>
  createSelector(
    getAllScheduledCategoriesByProduct(productType),
    (personalEffects) => {
      const combinedValue =
        personalEffects?.reduce((total, category) => {
          const sumOfScheduledItemValues =
            category.scheduledItems?.reduce((sum, scheduledItem) => {
              return sum + +scheduledItem?.value;
            }, 0) || 0;

          return total + sumOfScheduledItemValues;
        }, 0) || 0;
      return combinedValue;
    }
  );

function getViewModelTitle(grouping: DisplayGroupingType): string {
  switch (grouping) {
    case 'Blanket':
      return 'Blanket personal property (Valuables Plus® Blanket)';
    case 'Plus':
      return 'Scheduled personal property (Valuables Plus®)';
  }
}
