import { Injectable } from '@angular/core';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { MortgageActions } from '../store/actions';
import { Nullable } from '@shared/utils/type.utils';
import {
  AddlInterestThirdPartyType,
  ProductType,
} from '@core/models/api/dsm-types';
import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import { MortgageAdapter } from '@core/adapters/mortgage.adapter';
import { MortgageRequest } from '@core/models/api/request/mortgage.request.model';
import { MortgageSelectors } from '@core/store/selectors';
import { MortgageeTypes } from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';
import { Actions, ofType } from '@ngrx/effects';
import { setHasMortgage } from '@entities/has-mortgage/has-mortgage.action';
import { selectHasMortgages } from '@entities/has-mortgage/has-mortgage.selector';
import { BasicCompanyInformation } from '@app/additional-interests/additional-interest-holder-selection/additional-interest-holder-selection.component';

@Injectable({
  providedIn: 'root',
})
export class MortgageService {
  constructor(
    private store: Store,
    private actions$: Actions,
    private adapter: MortgageAdapter
  ) {}

  dispatchAddMortgage(model: MortgageModel): void {
    this.store.dispatch(
      MortgageActions.addMortgage({ payload: model as MortgageEntity })
    );
  }

  dispatchUpdateMortgage(model: MortgageModel): void {
    this.store.dispatch(
      MortgageActions.updateMortgage({ payload: model as MortgageEntity })
    );
  }

  dispatchDeleteMortgage(model: MortgageModel): void {
    this.store.dispatch(
      MortgageActions.deleteMortgage({
        payload: model as MortgageEntity,
      })
    );
  }

  dispatchAllDeleteMortgage(): void {
    this.store.dispatch(MortgageActions.deleteAllMortgage());
  }

  dispatchDeleteMortgagesOfType(
    productType: ProductType,
    mortgageType: AddlInterestThirdPartyType
  ): void {
    this.store.dispatch(
      MortgageActions.deleteMortgagesOfType({
        payload: { productType, mortgageType },
      })
    );
  }

  dispatchDeleteMortgagesOfTypes(
    productType: ProductType,
    mortgageTypes: AddlInterestThirdPartyType[]
  ): void {
    this.store.dispatch(
      MortgageActions.deleteMortgagesOfTypes({
        payload: { productType, mortgageTypes },
      })
    );
  }

  dispatchSwitchMortgageToPrimary(model: MortgageModel): void {
    this.store.dispatch(
      MortgageActions.switchMortgageToPrimary({
        payload: model as MortgageEntity,
      })
    );
  }

  dispatchSetHasMortgage(hasMortgage: boolean) {
    this.store.dispatch(setHasMortgage({ payload: hasMortgage }));
  }

  getHasMortgageForProduct(
    productType: ProductType,
    mortgageTypes: AddlInterestThirdPartyType[]
  ): Observable<boolean> {
    return this.store.select(selectHasMortgages(productType, mortgageTypes));
  }

  switchMortgageToPrimaryAndAwaitResult(model: MortgageModel): Promise<void> {    
    if (model.type === MortgageeTypes.PRIMARY_MORTGAGEE) {
      return Promise.resolve();
    }
  
    return firstValueFrom(this.getAllMortgages())
    .then((mortgages) => {
      const promises = [];
      if (mortgages) {
        for (const mortgage of mortgages) {
          if (mortgage.type === MortgageeTypes.PRIMARY_MORTGAGEE) {
            promises.push(this.setMortgageToSecondary(mortgage as MortgageModel));
          }
        }
      }
      return Promise.all(promises);
    })
      .then(() => {
        return this.setMortgageToPrimary(model);
      })
      .catch((error) => {
        console.error('Error in switchMortgageToPrimaryAndAwaitResult:', error);
      });
  }

  private setMortgageToPrimary(model: MortgageModel): Promise<any> {
    const correlationId = StringUtils.generateUuid();
    const responseListener = firstValueFrom(
      this.actions$.pipe(
        ofType(
          MortgageActions.updateMortgageSuccess,
          MortgageActions.updateMortgageError
        ),
        filter((action) => action.correlationId === correlationId)
      )
    );
    this.store.dispatch(
      MortgageActions.updateMortgage({
        payload: {
          ...model,
          type: MortgageeTypes.PRIMARY_MORTGAGEE,
        } as MortgageEntity,
        correlationId,
      })
    );
    return responseListener;
  }

  private setMortgageToSecondary(model: MortgageModel): Promise<any> {
    const correlationId = StringUtils.generateUuid();
    const responseListener = firstValueFrom(
      this.actions$.pipe(
        ofType(
          MortgageActions.updateMortgageSuccess,
          MortgageActions.updateMortgageError
        ),
        filter((action) => action.correlationId === correlationId)
      )
    );
    this.store.dispatch(
      MortgageActions.updateMortgage({
        payload: {
          ...model,
          type: MortgageeTypes.MORTGAGEE,
        } as MortgageEntity,
        correlationId,
      })
    );
    return responseListener;
  }

  // This returns entities, if you need models, add a selector for that
  getAllMortgages(): Observable<Nullable<MortgageEntity[]>> {
    return this.store.select(MortgageSelectors.getAllMortgages);
  }

  getMortgages(productType: ProductType): Observable<MortgageModel[]> {
    return this.store.select(MortgageSelectors.selectMortgages(productType));
  }

  getFirstMortgageOfType(
    productType: ProductType,
    mortgageType: AddlInterestThirdPartyType
  ): Observable<Nullable<MortgageModel>> {
    return this.store.select(
      MortgageSelectors.selectFirstMortgageOfType(productType, mortgageType)
    );
  }

  hasMortgage(): Observable<boolean> {
    return this.store.select(MortgageSelectors.anyProductHasMortgage);
  }

  addOrUpdateMortgage(
    request: MortgageRequest
  ): Observable<MortgageEntity | MortgageEntity[]> {
    return request.mortgage.mortgageId
      ? this.adapter.updateMortgage(request)
      : this.adapter.addMortgage(request);
  }

  deleteMortgage(request: MortgageRequest): Observable<unknown> {
    return this.adapter.deleteMortgage(request);
  }

  getPrimaryMortgage(): Observable<MortgageEntity | undefined> {
    return this.store.select(MortgageSelectors.getPrimaryMortgage);
  }

  getMortgagesOfMortgageType(
    productType: ProductType,
    mortgageType: AddlInterestThirdPartyType
  ): Observable<MortgageModel[]> {
    return this.store.select(
      MortgageSelectors.getMortgagesOfMortgageType(productType, mortgageType)
    );
  }

  getMortgagesOfMortgageTypes(
    productType: ProductType,
    mortgageTypes: AddlInterestThirdPartyType[]
  ): Observable<MortgageModel[]> {
    return this.store.select(
      MortgageSelectors.getMortgagesOfMortgageTypes(productType, mortgageTypes)
    );
  }

  getAddlInterestHolderCompanies(
    productType: ProductType,
    mortgageTypes: AddlInterestThirdPartyType[]
  ): Observable<BasicCompanyInformation[]> {
    return this.store.select(
      MortgageSelectors.getAddlInterestHolderCompanies(
        productType,
        mortgageTypes
      )
    );
  }

  getUniqueAddlInterestHolderCompanies(
    productType: ProductType,
    mortgageTypes: AddlInterestThirdPartyType[]
  ): Observable<BasicCompanyInformation[]> {
    return this.store.select(
      MortgageSelectors.getUniqueAddlInterestHolderCompanies(
        productType,
        mortgageTypes
      )
    );
  }
}
