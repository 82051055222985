import { Injectable } from '@angular/core';
import {
  UwIssuesAdapter,
  UwIssuesResponse,
} from '@core/adapters/uw-issues.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { QuoteActions, UwIssuesActions } from '@core/store/actions';
import { ProductsSelectors } from '@core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, map, mergeMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UwIssuesEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private uwIssuesAdapter: UwIssuesAdapter
  ) {}

  dropUwIssuesOnRatingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.rateQuote),
      map((action) =>
        UwIssuesActions.clearUwIssuesForProduct({
          productType: action.productType,
        })
      )
    )
  );

  fetchUwIssuesOnRatingFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.rateQuoteFail),
      mergeMap((action) =>
        this.store
          .select(ProductsSelectors.getQuoteIdForProduct(action.productType))
          .pipe(
            take(1),
            map((quoteId) => ({
              productType: action.productType,
              quoteId: quoteId || '',
            }))
          )
      ),
      mergeMap(({ productType, quoteId }) => {
        return this.uwIssuesAdapter.getUwIssues(productType, quoteId).pipe(
          map((issues) => this.removeRiskFilterIssues(issues)),
          map((issues) =>
            UwIssuesActions.setUwIssues({
              record: { productType, quoteId, issues },
            })
          )
        );
      })
    )
  );

  /* DSM gives us risk filter errors along with the underwriting issues.
   * We don't want to show these to the user, and definitely don't want them referring to UW in those cases.
   * Find and remove as they arrive.
   */
  private removeRiskFilterIssues(
    issues: UwIssuesResponse[]
  ): UwIssuesResponse[] {
    return issues.filter((issue) => {
      const match = issue.description.match(/\[([A-Z][A-Z0-9][0-9]+)\]/);
      if (!match) {
        return true;
      }
      const code = match[1];
      switch (code) {
        case 'A0234': // "...ineligible for standard auto due to prior insurance..."
        case 'A0105': // "...ineligible for Nationwide"
        case 'HO320': // "Nationwide did not return a quote"
        case 'A0237': // "Nationwide did not return a quote"
        case 'RO167': // "Nationwide did not return a quote"
        case 'MO175': // "Nationwide did not return a quote"
        case 'B0159': // "Nationwide did not return a quote"
          return false;
      }
      return true;
    });
  }
}
