import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'nwx-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnDestroy, AfterViewInit {
  @Input() items: any[] = [];
  @Output() focusByScrollButtons = new EventEmitter<any>();
  @ContentChild(TemplateRef) itemTemplate!: TemplateRef<any>;
  @ViewChild('forceHeight') forceHeight: ElementRef | null = null;
  @ViewChild('horzScroll') horzScroll: ElementRef | null = null;
  @ViewChild('wideContent') wideContent: ElementRef | null = null;
  showLeftButton = false;
  showRightButton = false;
  private resizeObserver: ResizeObserver;
  private resizeObservingElement: Element | null = null;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.resizeObserver = new ResizeObserver((e) => this.onResize(e));
  }

  ngOnDestroy(): void {
    if (this.resizeObservingElement) {
      this.resizeObserver.unobserve(this.resizeObservingElement);
      this.resizeObservingElement = null;
    }
  }

  ngAfterViewInit(): void {
    if (this.wideContent?.nativeElement) {
      if (this.resizeObservingElement !== this.wideContent.nativeElement) {
        if (this.resizeObservingElement) {
          this.resizeObserver.unobserve(this.resizeObservingElement);
        }
        this.resizeObservingElement = this.wideContent.nativeElement;
        this.resizeObserver.observe(this.wideContent.nativeElement);
      }
    } else if (this.resizeObservingElement) {
      this.resizeObserver.unobserve(this.resizeObservingElement);
      this.resizeObservingElement = null;
    }
    // It's ugly, but if we refresh them immediately, Angular misses the change:
    setTimeout(() => {
      this.refreshScrollButtons(this.horzScroll?.nativeElement);
    }, 0);
  }

  // ScrollBehavior is "auto", "instant", or "smooth".
  focusIndex(index: number, behavior: ScrollBehavior = 'smooth'): void {
    if (index < 0 || index >= this.items.length) return;
    if (!this.wideContent?.nativeElement) return;
    const elements = Array.from(
      this.wideContent.nativeElement.querySelectorAll('.wide-content > *')
    ) as HTMLElement[];
    const element = elements[index];
    if (!element) return;
    element.scrollIntoView({
      behavior,
      block: 'nearest',
      inline: 'center',
    });
  }

  onScroll(event: Event): void {
    this.refreshScrollButtons(event?.target as HTMLElement);
  }

  private refreshScrollButtons(horzScroll?: HTMLElement): void {
    if (!horzScroll) return;
    // There's ambiguity about fractional scroll positions, hence this -2:
    const limit = horzScroll.scrollWidth - horzScroll.clientWidth - 2;
    this.showLeftButton = horzScroll.scrollLeft > 0;
    this.showRightButton = horzScroll.scrollLeft < limit;
    this.changeDetector.markForCheck();
  }

  onResize(events: ResizeObserverEntry[]): void {
    if (this.wideContent?.nativeElement && this.forceHeight?.nativeElement) {
      const bounds = this.wideContent.nativeElement.getBoundingClientRect();
      this.forceHeight.nativeElement.style.height = `${bounds.height}px`;
      this.refreshScrollButtons(this.horzScroll?.nativeElement);
    }
  }

  onStep(delta: number): void {
    if (!this.wideContent?.nativeElement || !this.horzScroll?.nativeElement) {
      return;
    }
    let elementToFocus: HTMLElement | null = null;
    const candidates = Array.from(
      this.wideContent.nativeElement.querySelectorAll('.wide-content > *')
    ) as HTMLElement[];
    const viewBounds = this.horzScroll.nativeElement.getBoundingClientRect();
    const horzCenter = viewBounds.x + viewBounds.width / 2;
    const horzFudge = 10; // mmmmmmmm horz fudge....
    if (delta > 0) {
      // Scrolling right, we want the first candidate right of center.
      for (const candidate of candidates) {
        const candidateBounds = candidate.getBoundingClientRect();
        const candidateCenter = candidateBounds.x + candidateBounds.width / 2;
        if (candidateCenter > horzCenter + horzFudge) {
          elementToFocus = candidate;
          break;
        }
      }
    } else if (delta < 0) {
      // Scrolling left, we want the last candidate left of center.
      for (const candidate of candidates) {
        const candidateBounds = candidate.getBoundingClientRect();
        const candidateCenter = candidateBounds.x + candidateBounds.width / 2;
        if (candidateCenter < horzCenter - horzFudge) {
          elementToFocus = candidate;
        } else {
          break;
        }
      }
    }
    if (elementToFocus) {
      elementToFocus.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
      const index = candidates.indexOf(elementToFocus);
      if (index >= 0 && index < this.items.length) {
        this.focusByScrollButtons.next(this.items[index]);
      }
    }
  }
}
