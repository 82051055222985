<div id="loading-spinner-container" *ngIf="loading">
  <div id="white-spinner-bg">
    <img *ngIf="message.iconUrl" class="loading-icon" [src]="message.iconUrl" />
    <div class="text-row">
      <canvas class="spinner" width="35" height="35" #spinner></canvas>
      <div class="message">
        {{ message.message }}
      </div>
    </div>
  </div>

  <ng-template #default>
    <div id="white-spinner-bg">
      <bolt-waiting-indicator [attr.id]="'loading-spinner'">
        Please wait while we retrieve data
      </bolt-waiting-indicator>
    </div>
  </ng-template>
</div>
