import { Injectable } from '@angular/core';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { TelematicsService } from '@core/services/telematics.service';
import {
  getPolicyLineSuccess,
  updatePolicyLine,
} from '@core/store/entities/eligible-discounts/eligible-discounts.action';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { getModifiersForMultipleEntities } from '@core/store/entities/eligible-discounts/eligible-discounts.selector';
import { addError } from '@core/store/entities/error/error.action';
import { BillingFormService } from '@app/forms-store/services/billing-form.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  billingPlanChangedDueToTelematics,
  updateBillingPlansForm,
} from './billing-plans.action';
import { getBillToEscrow, getBillingPlansForm } from './billing-plans.selector';
import { PriceSummaryUtils } from '@shared/utils/hub/price-summary/price-summary.util';
import { ProductsService } from '@core/services/products.service';
import { ProductUtils } from '@app/shared/utils/product.util';
import { updateTasks } from '@entities/task/task.action';
import { ProductsSelectors } from '@core/store/selectors';
@Injectable({
  providedIn: 'root',
})
export class BillingPlansEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private billingFormService: BillingFormService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private telematicsService: TelematicsService,
    private productsService: ProductsService
  ) {}

  /**
   * After getPolicyLineSuccess, look for the BillingPaymentMethod discount.
   * If there isn't one, or it agrees with our billing form, great.
   * Otherwise either:
   *  - Change the policy discount, if user has seen the billing form. (form is the authority)
   *  - Change the billing form, if she has not seen it yet. (backend is the authority)
   * Also, if we receive it with no value (eg after changing effective date), then our form is the authority.
   */
  updatePaymentMethodFormOrDiscountsOnServiceResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPolicyLineSuccess),
      map((action) =>
        action.response.find(
          (d) => d.eligibleDiscountId === 'BillingPaymentMethod'
        )
      ),
      filterOutNull(),
      withLatestFrom(
        this.billingFormService.getBillingPlansForm(),
        this.billingFormService.getBillingPlansFormVisited()
      ),
      map(([discount, form, visited]) => ({
        discount,
        currentSelection:
          PriceSummaryUtils.paymentMethodDiscountValueForBillingPlan(
            form?.billingPlan
          ),
        visited,
      })),
      filter(
        ({ discount, currentSelection }) =>
          discount.selectedOptionValue !== currentSelection
      ),
      switchMap(({ discount, currentSelection, visited }) => {
        const nextActions: Action[] = [];
        if (!visited && !discount.selectedOptionValue) {
          nextActions.push(
            updatePolicyLine({
              entity: {
                ...discount,
                selectedOptionValue: 'FullPay',
              },
            })
          );
          nextActions.push(
            updateBillingPlansForm({
              payload: {
                billingPlan:
                  PriceSummaryUtils.billingPlanForPaymentMethodDiscountValue(
                    'FullPay'
                  ),
              },
            })
          );
        } else if (visited || !discount.selectedOptionValue) {
          nextActions.push(
            updatePolicyLine({
              entity: {
                ...discount,
                selectedOptionValue: currentSelection,
              },
            })
          );
        } else {
          nextActions.push(
            updateBillingPlansForm({
              payload: {
                billingPlan:
                  PriceSummaryUtils.billingPlanForPaymentMethodDiscountValue(
                    discount.selectedOptionValue
                  ),
              },
            })
          );
        }
        return nextActions;
      })
    )
  );

  /**
   * After getPolicyLineSuccess, look for the PaidInFull discount.
   * If there isn't one, or it agrees with our billing form, great.
   * Otherwise:
   *  - Change the policy discount AND the billing form if the user has not visited it yet.
   *  - If she has visited the billing form, we hand off responsibility to the form.
   * Also, if we receive it with no value (eg after changing effective date), then our form is the authority.
   */
  updatePaymentMethodFormOrDiscountsOnServiceResponseForPowersports$ =
    createEffect(() =>
      this.actions$.pipe(
        ofType(getPolicyLineSuccess),
        map((action) =>
          action.response.find((d) => d.eligibleDiscountId === 'PaidInFull')
        ),
        filterOutNull(),
        withLatestFrom(
          this.billingFormService.getBillingPlansForm(),
          this.billingFormService.getBillingPlansFormVisited(),
          this.productsService.getSelectedProductTypes()
        ),
        filter(([discount, form, visited, productTypes]) =>
          productTypes.some((pType) =>
            ProductUtils.isPowersportsProductType(pType)
          )
        ),
        map(([discount, form, visited, productTypes]) => ({
          discount,
          currentSelection:
            PriceSummaryUtils.paymentMethodDiscountValueForBillingPlan(
              form?.billingPlan
            ),
          visited,
          productTypes,
        })),
        filter(
          ({ discount, currentSelection, productTypes }) =>
            discount.selectedOptionValue !== currentSelection
        ),
        switchMap(({ discount, currentSelection, visited, productTypes }) => {
          const nextActions: Action[] = [];
          if (!visited) {
            // if selectedOptionValue is false:
            if (discount.selectedOptionValue === 'false') {
              // set the payment method and apply the discount
              nextActions.push(
                updateBillingPlansForm({
                  payload: {
                    billingPlan: 'PAY IN FULL',
                  },
                })
              );
              nextActions.push(
                updatePolicyLine({
                  entity: {
                    ...discount,
                    selectedOptionValue: 'true',
                  },
                })
              );
            }
          }

          return nextActions;
        })
      )
    );

  forceRecurringBillingForSmartMiles$ = createEffect(() =>
    this.telematicsService.isSmartMilesEnrolled().pipe(
      distinctUntilChanged(),
      filter((enrolled) => enrolled),
      withLatestFrom(this.billingFormService.getBillingPlansForm(), this.telematicsService.isTelematicsDeclined()),
      filter(([enrolled, form, isTelematicsDeclined]) => {
        return (
          form.billingPlan !== 'MONTHLY EFT' &&
          form.billingPlan !== 'MONTHLY RECURRING BANKCARD' &&
          !isTelematicsDeclined
        );
      }),
      switchMap(([enrolled, form]) => {
        const completed = new Subject<string>();
        this.billingFormService
          .orchestrateBillingPlanChangeWithSpinner(
            {
              ...form,
              billingPlan: 'MONTHLY EFT',
            },
            false
          )
          .subscribe({
            complete: () => {
              completed.next(form.billingPlan);
            },
          });
        return completed;
      }),
      map((billingPlan) =>
        billingPlanChangedDueToTelematics({
          previous: billingPlan,
          billingPlan: 'MONTHLY EFT',
        })
      )
    )
  );

  updateTasksOnBillingChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBillingPlansForm),
      map((action) => action.payload),
      map((_) => updateTasks())
    )
  );
  generateToastWhenBillingPlanChangesAutomatically$ = createEffect(() =>
    this.actions$.pipe(
      ofType(billingPlanChangedDueToTelematics),
      withLatestFrom(this.billingFormService.getBillingPlansFormVisited()),
      filter(([action, visited]) => visited),
      map((action) =>
        addError({
          payload: {
            displayMessage:
              'We have changed the billing method to allow for SmartMiles.',
          },
        })
      )
    )
  );

  dropEscrowWhenPropertyProductChanges$ = createEffect(() =>
    this.store.select(ProductsSelectors.getSelectedProductTypes).pipe(
      map((productTypes) =>
        productTypes.find(
          (productType) =>
            productType === 'Homeowner' || productType === 'Condominium'
        )
      ),
      pairwise(),
      // Do nothing if setting to something from nothing -- important for escrow retrieves.
      filter(([prev, next]) => !!prev && prev !== next),
      withLatestFrom(this.store.select(getBillToEscrow)),
      filter(([productTypes, billToEscrowAccount]) => !!billToEscrowAccount),
      map(() =>
        updateBillingPlansForm({
          payload: {
            billToEscrowAccount: false,
          },
        })
      )
    )
  );
}
