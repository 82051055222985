import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { Nullable } from '@shared/utils/type.utils';

@Pipe({ name: 'mapProductName' })
export class ProductNameMapPipe implements PipeTransform {
  transform(productId: Nullable<ProductType>): string {
    switch (productId) {
      case 'PersonalAuto':
        return 'Auto policy';
      case 'Condominium':
        return 'Condo policy';
      case 'Homeowner':
        return 'Homeowners policy';
      case 'Tenant':
        return 'Renters policy';
      case 'TermLife':
        return 'Life policy';
      case 'PersonalUmbrella':
        return 'Umbrella policy';
      default:
        return `${productId} policy`;
    }
  }
}
