import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryParameterPreservingRedirectComponent } from './query-parameter-preserving-redirect/query-parameter-preserving-redirect.component';
import { StartNewQuoteComponent } from './start-new-quote/start-new-quote.component';
import { HelpComponent } from './help/help.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DiscountBadgeModule } from './discount-badge/discount-badge.module';
import { PreBindDocumentsModalComponent } from './pre-bind-documents/pre-bind-documents-modal/pre-bind-documents-modal.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CardComponentModule } from './card-component/card-component.module';
import {
  CardListComponent,
  CardListItemDirective,
} from './card-list/card-list.component';
import { PillListComponent } from './pill-list/pill-list.component';
import { PillItemComponent } from '@shared/pill-item/pill-item.component';
import { MessageBubbleComponent } from './message-bubble/message-bubble.component';
import { SimpleConfirmationModalModule } from './simple-confirmation-modal/simple-confirmation-modal.module';
import { ResponsiveNavigationToggleComponent } from './responsive-navigation-container/responsive-navigation-toggle/responsive-navigation-toggle.component';
import { ResponsiveNavigationContainerComponent } from './responsive-navigation-container/responsive-navigation-container.component';
import {
  TextWithMenuComponent,
  TextWithMenuResultsDirective,
} from './text-with-menu/text-with-menu.component';
import { DummyProducerFormComponent } from './dummy-producer-form/dummy-producer-form.component';
import { NotificationBubbleComponent } from './notification-bubble/notification-bubble.component';
import { DecorativeBoltCheckboxComponent } from './decorative-bolt-checkbox/decorative-bolt-checkbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AdvancedPicklistModule } from '@shared/components/advanced-picklist/advanced-picklist.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { DummyProducerFormContainerComponent } from './dummy-producer-form-container/dummy-producer-form-container.component';
import { CardWithActionButtonComponent } from './card-with-action-button/card-with-action-button.component';
import { TileWithIconComponent } from './tile-with-icon/tile-with-icon.component';
import { UnboltSelectComponent } from './unbolt-select/unbolt-select.component';
import { CarouselComponent } from './carousel/carousel.component';

@NgModule({
  declarations: [
    CardListComponent,
    CardListItemDirective,
    CardWithActionButtonComponent,
    CarouselComponent,
    DecorativeBoltCheckboxComponent,
    DummyProducerFormComponent,
    QueryParameterPreservingRedirectComponent,
    StartNewQuoteComponent,
    HelpComponent,
    ProfileMenuComponent,
    PreBindDocumentsModalComponent,
    LoadingSpinnerComponent,
    PillListComponent,
    PillItemComponent,
    MessageBubbleComponent,
    ResponsiveNavigationToggleComponent,
    ResponsiveNavigationContainerComponent,
    TextWithMenuComponent,
    TextWithMenuResultsDirective,
    NotificationBubbleComponent,
    DummyProducerFormContainerComponent,
    TileWithIconComponent,
    UnboltSelectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    DiscountBadgeModule,
    CardComponentModule,
    SimpleConfirmationModalModule,
    ReactiveFormsModule,
    AdvancedPicklistModule,
    SharedDirectivesModule,
  ],
  exports: [
    CardListComponent,
    CardListItemDirective,
    CardWithActionButtonComponent,
    CarouselComponent,
    DecorativeBoltCheckboxComponent,
    QueryParameterPreservingRedirectComponent,
    StartNewQuoteComponent,
    HelpComponent,
    ProfileMenuComponent,
    DiscountBadgeModule,
    LoadingSpinnerComponent,
    CardComponentModule,
    PillListComponent,
    PillItemComponent,
    MessageBubbleComponent,
    ResponsiveNavigationToggleComponent,
    ResponsiveNavigationContainerComponent,
    TextWithMenuComponent,
    TextWithMenuResultsDirective,
    NotificationBubbleComponent,
    DummyProducerFormContainerComponent,
    DummyProducerFormComponent,
    TileWithIconComponent,
    UnboltSelectComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
