import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RenovationEntity } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import {
  CoveredLocationEntity,
  CoveredLocationProtectiveDevices,
  CoveredLocationWindMitigation,
} from '@entities/covered-location/covered-location.reducer';
import { ProtectiveDeviceEntity } from '@entities/protective-devices/protective-devices.reducer';
import { RenovationModel } from '@property/components/forms/renovation-form/renovation-form.model';
import { PROTECTIVE_DEVICE_STRINGS } from '@shared/constants/protective-devices-constants';
import { Nullable } from '@shared/utils/type.utils';

@Injectable({
  providedIn: 'root',
})
export class PropertyHelper {
  private static findYearBuiltControl(form: FormGroup): FormControl | null {
    return (form.get('constructionInfoForm.yearBuilt') /* Homeowners */ ||
      form.get('yearBuilt')) as FormControl; /* Condo */
  }

  static sanitizeCoveredLocationRequest(
    coveredLocation: CoveredLocationEntity,
    protectiveDevices: ProtectiveDeviceEntity[],
    productType?: ProductType
  ): CoveredLocationEntity {
    if (coveredLocation.constructionInfo) {
      coveredLocation.constructionInfo = {
        ...coveredLocation.constructionInfo,
      };
      if (!coveredLocation.constructionInfo.additionalHeating) {
        delete coveredLocation.constructionInfo.additionalHeating;
      }
      if (
        productType === 'Homeowner' &&
        !+(coveredLocation.constructionInfo.numberOfHalfBathrooms || 0)
      ) {
        delete coveredLocation.constructionInfo.halfBathroomDescription;
      }
      if (!coveredLocation.constructionInfo.exteriorWalls?.length) {
        delete coveredLocation.constructionInfo.exteriorWalls;
      }
      // More constructionInfo fields that we must not send...
      delete coveredLocation.constructionInfo.wallHeights;
    }

    if (coveredLocation && !coveredLocation.hasOwnProperty('deededOwner')) {
      coveredLocation.deededOwner = true;
    }

    if (
      coveredLocation.constructionInfo &&
      coveredLocation.constructionInfo.yearBuilt
    ) {
      const yearBuiltValue = +coveredLocation.constructionInfo.yearBuilt;
      const today = new Date();
      const currentYear = today.getFullYear();
      const yearDifference = currentYear - yearBuiltValue;
      if (yearDifference < 70) {
        coveredLocation.historicDistrict = false;
      } else {
        coveredLocation.historicDistrict = true; // Property is 70 years old or older
      }
    }

    if (
      coveredLocation.constructionInfo &&
      !coveredLocation.constructionInfo?.garages?.[0]?.garageType
    ) {
      coveredLocation.constructionInfo.garages = [];
    }

    if (!coveredLocation.hasOilTank) {
      coveredLocation.hasOilTank = false;
      delete coveredLocation.oilTank;
    }

    if (protectiveDevices.length || !coveredLocation.protectiveDevices) {
      coveredLocation.protectiveDevices =
        this.formatProtectiveDevicesForRequest(protectiveDevices);
    }
    coveredLocation.renovations = this.sanitizeRenovationsForRequest(
      coveredLocation.renovations,
      coveredLocation.constructionInfo?.yearBuilt
    );

    // Eliminate bathrooms and kitchens with a count of zero.
    if (coveredLocation.constructionInfo?.bathrooms) {
      coveredLocation.constructionInfo.bathrooms =
        coveredLocation.constructionInfo.bathrooms.filter(
          (v) => v.bathroomTypeCount
        );
    }
    if (coveredLocation.constructionInfo?.kitchens) {
      coveredLocation.constructionInfo.kitchens =
        coveredLocation.constructionInfo.kitchens.filter(
          (v) => v.kitchenTypeCount
        );
    }

    delete coveredLocation.riskItems;
    delete coveredLocation.seasonal;
    delete coveredLocation.weeksRented;
    delete coveredLocation.warningMessages;
    return coveredLocation;
  }

  private static sanitizeRenovationsForRequest(
    renovations: RenovationEntity[] | undefined,
    yearBuilt: Nullable<number>
  ): RenovationEntity[] | undefined {
    if (!renovations) {
      return renovations;
    }
    return renovations.filter((r) => !!r.year);
  }

  private static formatProtectiveDevicesForRequest(
    devices: ProtectiveDeviceEntity[]
  ): CoveredLocationProtectiveDevices {
    const request: CoveredLocationProtectiveDevices = {};

    // Start with the basic 1:1 devices.
    for (const device of devices) {
      this.addProtectiveDeviceToRequest(request, device);
    }

    // If we have "Wind Protective Device", add all devices as one array.
    // In RI, WPD is "true" or "false" and there are other devices in the category.
    // In NY, CT, and MD, WPD is a single thing and its value is what we're after.
    const windProtectiveDevice = devices.find(
      (d) => d.type === 'Wind Protective Device'
    );
    if (windProtectiveDevice?.value === 'true') {
      request.windProtectiveDevices = devices
        .filter(
          (device) =>
            device.category === 'Wind Protective Device' &&
            device.type !== 'Wind Protective Device' &&
            device.value === 'true'
        )
        .map((device) => this.protectiveDeviceTypeTypekey(device.type));
    } else if (windProtectiveDevice?.value === 'false') {
      // do nothing
    } else if (windProtectiveDevice?.value) {
      request.windProtectiveDevices = [
        this.protectiveDeviceValueTypekey(
          windProtectiveDevice.type,
          windProtectiveDevice.value
        ) || '',
      ];
    }

    // Add everything in "Wind Mitigation" category, except "HasSecondaryWaterResist_Ext" and "Wind Mitigation" itself.
    // The request field is called "category" but they must have meant "type".
    const windMitigationPseudoDevice = devices.find(
      (d) => d.type === 'Wind Mitigation'
    );
    if (windMitigationPseudoDevice?.value === 'true') {
      request.windMitigations = devices
        .filter(
          (d) =>
            d.category === 'Wind Mitigation' &&
            d.type !== 'Wind Mitigation' &&
            d.type !== 'HasSecondaryWaterResist_Ext'
        )
        .map((d) => ({
          category: this.protectiveDeviceTypeTypekey(d.type),
          selectedType: this.protectiveDeviceValueTypekey(d.type, d.value),
        }))
        .filter((d) => d.selectedType) as CoveredLocationWindMitigation[];
    }
    if (!request.windMitigations?.length) {
      delete request.windMitigations;
      // hasSecondaryWaterResistance is also a "Wind Mitigation" but it's stored separate.
      // Error to include it without the windMitigations block.
      delete request.hasSecondaryWaterResistance;
    }

    return request;
  }

  private static addProtectiveDeviceToRequest(
    request: CoveredLocationProtectiveDevices,
    device: ProtectiveDeviceEntity
  ): void {
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find((c) => {
      return c.typekey === device.type || c.pcDisplayString === device.type;
    });
    switch (typeMetadata?.pcDisplayString || device.type) {
      case 'Burglar Alarm':
        request.burglarAlarm = this.protectiveDeviceValueTypekey(
          device.type,
          device.value
        );
        break;
      case 'Fire/Smoke Alarm':
        request.fireOrSmokeAlarm = this.protectiveDeviceValueTypekey(
          device.type,
          device.value
        );
        break;
      case 'Sprinkler System':
        request.sprinklerSystem = this.protectiveDeviceValueTypekey(
          device.type,
          device.value
        );
        break;
      case 'Wrought Iron Bars':
        request.hasWroughtIronBar = device.value === 'true';
        break;
      case 'Secondary Water Resistance':
        request.hasSecondaryWaterResistance = device.value === 'true';
        break;
    }
  }

  private static protectiveDeviceTypeTypekey(type: string): string {
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find((c) => {
      return c.typekey === type || c.pcDisplayString === type;
    });
    if (typeMetadata) {
      return typeMetadata.typekey;
    }
    return type;
  }

  private static protectiveDeviceValueTypekey(
    type: string,
    value: Nullable<string>
  ): string | null {
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find((c) => {
      return c.typekey === type || c.pcDisplayString === type;
    });
    const valueMetadata = typeMetadata?.values.find((v) => {
      return v.typekey === value || v.pcDisplayString === value;
    });
    if (valueMetadata) {
      return valueMetadata.typekey;
    }
    return value || null;
  }

  static getRenovationsArray(
    existingRenovations: Nullable<RenovationModel[]>,
    renovations: RenovationModel[]
  ): RenovationModel[] {
    const newRenovations: RenovationModel[] = [];

    existingRenovations?.forEach((existingRenovation) => {
      const replacementRenovation = renovations.find(
        (renovation) => renovation.type === existingRenovation.type
      );
      newRenovations.push(
        replacementRenovation ? replacementRenovation : existingRenovation
      );
    });

    return newRenovations;
  }
}
