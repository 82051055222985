<div class="vehicle-premium-tile">
  <div class="tile-header">
    {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
    <span class="badge nwx-badge" [class]="currentProgram">{{
      formatEnrollmentStatus()
    }}</span>
    <span *ngIf="discountPercentage" class="pl-5">
      Applied discount of
      <h3 class="discount-percentage">{{ discountPercentage }}%</h3>
    </span>
  </div>
  <div class="tile-body">
    <div class="bolt-container-fluid">
      <div class="bolt-row bolt-align-items-center">
        <div *ngIf="needsRecalc" class="bolt-col-xs-12 bolt-col-sm-auto price">
          <h4>$--.--/month*</h4>
        </div>
        <div
          *ngIf="!needsRecalc"
          class="bolt-col-xs-12 bolt-col-sm-auto price with-border"
        >
          <h3>
            {{ monthlyPremium | currency }}
            <span class="bolt-heading-sm">
              <strong>/ month<sup>*</sup></strong>
            </span>
          </h3>
          <p class="bolt-body-copy-sm" *ngIf="currentProgram === 'SmartMiles'">
            @if (vehicle.totalCostPerMile) {
              {{ vehicle.totalCostPerMile | currency }} cost per mile
            } @else {
              Rate quote to see cost per mile
            }
          </p>
        </div>
        <div
          *ngIf="!needsRecalc"
          class="bolt-col-xs-12 bolt-col-sm-4 price-desc"
        >
          <span *ngIf="currentProgram === 'SmartMiles'; else defaultText">
            Estimated average monthly cost for this vehicle
          </span>
          <ng-template #defaultText>Monthly cost for this vehicle</ng-template>
        </div>
        <div class="bolt-col-xs-12 bolt-col-sm">
          <div *ngIf="allowChange" class="change-btn">
            <bolt-button
              type="primary"
              attr.id="tvpc-change-enroll-vehicle-{{ vehicle.vehicleId }}"
              (click)="openChangeModal()"
              [attr.size]="maxSizeMini ? 'sm' : 'md'"
              >{{
                currentProgram !== 'NotEnrolled'
                  ? 'Change/remove enrollment'
                  : 'Enroll vehicle'
              }}</bolt-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
