import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import {
  quoteStatusGreaterThan,
  TelematicsVehicleProgramType,
} from '@core/models/api/dsm-types';
import { ProductsService } from '@core/services/products.service';
import { TelematicsService } from '@core/services/telematics.service';
import { VehicleService } from '@core/services/vehicle.service';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { BillingService } from '@core/services/billing.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Nullable } from '@shared/utils/type.utils';
import { take } from 'rxjs/operators';
import { TelematicsChangeModalComponent } from '../telematics-change-modal/telematics-change-modal.component';

@Component({
  selector: 'nwx-telematics-vehicle-premium',
  templateUrl: './telematics-vehicle-premium.component.html',
  styleUrls: ['./telematics-vehicle-premium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsVehiclePremiumComponent implements OnChanges {
  @Input() vehicle!: ExtendedTelematicsVehicle;
  @Input() currentProgram!: TelematicsVehicleProgramType | 'NotEnrolled';
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() allowChange = true;
  @Input() isMobile = false;
  @Input() maxSizeMini!: Nullable<boolean>;

  monthlyPremium = 0.0;
  discountPercentage? = 0;
  needsRecalc = false;

  constructor(
    private modalService: NgbModal,
    private productsService: ProductsService,
  ) {}

  ngOnChanges(): void {
    this.formatMonthlyPremium();
    this.checkVehicleEnrollment();
    this.checkQuoteStatus();
  }

  openChangeModal(): void {
    const modal = this.modalService.open(TelematicsChangeModalComponent, {
      size: 'lg',
      centered: true,
      ariaLabelledBy: 'telematicsChangeModalHeader',
    });
    modal.componentInstance.vehicle = this.vehicle;
    modal.componentInstance.currentProgram = this.currentProgram;
    modal.componentInstance.isMobile = this.isMobile;
    modal.componentInstance.enrollment = this.enrollment;
  }

  private checkQuoteStatus(): void {
    this.productsService
      .getProduct('PersonalAuto')
      .pipe(take(1))
      .subscribe((p) => {
        let recalc = false;

        if (p && p.quoteStatus && p.quoteFurthestStatus) {
          if (p.quoteStatus === 'Quoted' || p.quoteStatus === 'Draft') {
            recalc = quoteStatusGreaterThan(
              p.quoteFurthestStatus,
              p.quoteStatus
            );
          }
        }
        this.needsRecalc = recalc;
      });
  }

  private checkVehicleEnrollment(): void {
    if (this.vehicle.enrollmentStatus) {
      this.allowChange = true;
    }
    if (
      this.vehicle.enrollmentStatus !== 'NotEnrolled' &&
      this.vehicle.discountPercentage
    ) {
      this.discountPercentage = this.vehicle.discountPercentage;
    }
  }

  private formatMonthlyPremium(): void {
    // *** Matching v1 behavior for now and not using variableMileagePremium ***
    // if (this.vehicle.variableMileagePremium && this.vehicle.termMonths) {
    //   this.monthlyPremium =
    //     this.vehicle.variableMileagePremium / this.vehicle.termMonths;
    // } else
    if (this.vehicle.coverableTotal && this.vehicle.termMonths) {
      this.monthlyPremium =
        this.vehicle.coverableTotal / this.vehicle.termMonths;
    }
  }

  formatEnrollmentStatus(): string {
    if (this.vehicle.enrollmentStatus === 'NotEnrolled') {
      return 'Not Enrolled';
    } else if (this.vehicle.enrollmentStatus === 'VerifiedScore') {
      return 'Enrolled';
    } else {
      return this.vehicle.enrollmentStatus;
    }
  }
}
