import { SessionActions } from '@core/store/actions';
import { createEntityAdapter, Dictionary, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromAction from './task.action';
import { TaskModel } from './task.model';
import { TaskUtils } from './utils/task.util';

export interface TaskState extends EntityState<TaskModel> {
  nextId: number;
  resolving: boolean;
  showTasks: boolean;
}

const sortByPageIndex = (t1: TaskModel, t2: TaskModel): number => {
  if (t1.page && t2.page) {
    if (t1?.page?.index < t2?.page?.index) {
      return -1;
    } else if (t1?.page?.index > t2?.page?.index) {
      return 1;
    } else {
      return 0;
    }
  }
  return 0;
};

export const adapter = createEntityAdapter<TaskModel>({
  sortComparer: sortByPageIndex,
});

const initialState = {
  ...adapter.getInitialState(),
  nextId: 1,
  resolving: false,
  showTasks: false,
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(fromAction.clear, (state, action) => initialState),
  on(fromAction.addTask, (state, action) => {
    const isNew = TaskUtils.isNewTask(
      action.payload,
      Object.values(state.entities)
    );
    if (!isNew) return state;

    return adapter.addOne(
      {
        ...action.payload,
        completed: false,
        id: state.nextId,
      },
      {
        ...state,
        nextId: state.nextId + 1,
      }
    );
  }),
  on(fromAction.updateTask, (state, action) =>
    adapter.updateOne(action, state)
  ),
  on(fromAction.completeTask, (state, action) =>
    adapter.updateOne(
      { id: action.payload.id as number, changes: { completed: true } },
      state
    )
  ),
  on(fromAction.uncompleteTask, (state, action) =>
    adapter.updateOne(
      { id: action.payload.id as number, changes: { completed: false } },
      state
    )
  ),
  on(fromAction.dismissReviewTask, (state, action) => {
    const newEntities: Dictionary<TaskModel> = {};
    for (const key of state.ids) {
      const task = state.entities[key];
      if (task) {
        if (
          task.entityType === 'review' &&
          task.field === action.payload.field &&
          task.productType == action.payload.productType
        ) {
          newEntities[key] = {
            ...task,
            completed: true,
          };
        } else {
          newEntities[key] = task;
        }
      }
    }
    return { ...state, entities: newEntities };
  }),
  on(fromAction.clearReviewTasksForPage, (state, action) => {
    const reviewTasks = TaskUtils.findAllTasksByEntityTypeAndPage(
      'review',
      action.payload,
      Object.values(state.entities)
    );
    return adapter.updateMany(
      reviewTasks.map((task) => ({
        id: task?.id as number,
        changes: { completed: true },
      })),
      state
    );
  }),
  on(fromAction.beginResolving, (state) => ({ ...state, resolving: true })),
  on(fromAction.endResolving, (state) => ({ ...state, resolving: false })),
  on(fromAction.toggleShowTasks, (state) => ({
    ...state,
    showTasks: !state.showTasks,
  })),
  on(fromAction.setShowTasks, (state, action) => ({
    ...state,
    showTasks: action.payload,
  }))
);
