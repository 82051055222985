import {
  retrieveQuotes,
  retrieveQuoteSuccess,
} from '../../retrieve/retrieve.action';
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EligibleDiscountsEntity } from './eligible-discounts.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import * as EligibleDiscountsAction from './eligible-discounts.action';
import {
  EligibleDiscountsActions,
  ProductActions,
  RetrieveActions,
  SessionActions,
} from '../../actions';
import { ProductType } from '@core/models/api/dsm-types';

export interface EligibleDiscountsState
  extends EntityState<EligibleDiscountsEntity> {
  error?: ErrorModel | null;
  smartrideLoaded: boolean;
  loading: number;
  callsInFlight: string[]; // 'productType.eligibleDiscountId'
}

export const adapter: EntityAdapter<EligibleDiscountsEntity> =
  createEntityAdapter<EligibleDiscountsEntity>({
    selectId: (discount) =>
      `${discount.eligibleDiscountId}_${discount?.modelId}`,
  });

const eligibleDiscountsReducer = createReducer(
  adapter.getInitialState({
    smartrideLoaded: false,
    loading: 0,
    callsInFlight: [] as string[],
  }),

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      smartrideLoaded: false,
      loading: 0,
      callsInFlight: [],
    })
  ),

  on(EligibleDiscountsAction.getPolicyLineSuccess, (state, { response }) => {
    const modelId = response[0]?.modelId || '';
    const newState: EligibleDiscountsState = {
      ...state,
      ids: [],
      entities: {},
    };
    // Keep entities belonging to a different modelId; discard all others
    for (const id of state.ids) {
      const entity = state.entities[id];
      if (entity) {
        if (entity.modelId !== modelId) {
          (newState.ids as string[]).push(id as string);
          newState.entities[id] = entity;
        }
      }
    }
    return adapter.addMany(response, newState);
  }),

  on(EligibleDiscountsAction.addAllEligibleDiscounts, (state, { response }) =>
    adapter.addMany(response, { ...state })
  ),
  on(EligibleDiscountsAction.storeEligibleDiscount, (state, { entity }) => {
    return adapter.upsertOne(entity, state);
  }),
  on(EligibleDiscountsAction.getPolicyLineFail, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(EligibleDiscountsAction.updatePolicyLine, (state, { entity }) => ({
    ...state,
    loading: state.loading + 1,
    callsInFlight: addToCallsInFlight(
      state.callsInFlight,
      entity.productType as ProductType,
      entity.eligibleDiscountId
    ),
  })),
  on(EligibleDiscountsAction.updatePolicyLineSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, {
      ...state,
      loading: state.loading - 1,
      callsInFlight: removeFromCallsInFlight(
        state.callsInFlight,
        entity.productType as ProductType,
        entity.eligibleDiscountId
      ),
    })
  ),
  on(EligibleDiscountsAction.updatePolicyLineFail, (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: state.loading - 1,
      callsInFlight: removeFromCallsInFlight(
        state.callsInFlight,
        action.productType,
        action.eligibleDiscountId
      ),
    };
  }),
  on(retrieveQuoteSuccess, (state, { payload }) => {
    const modelId = payload.response.quoteId;
    const eligibleDiscounts = payload.response.eligibleDiscounts?.map((d) => ({
      ...d,
      modelId,
      quoteId: modelId,
      productType: payload.productType,
    }));

    if (eligibleDiscounts) {
      return adapter.addMany(eligibleDiscounts, { ...state });
    } else {
      return {
        ...state,
      };
    }
  }),
  on(EligibleDiscountsAction.storePolicyLineModifier, (state, { discount }) =>
    adapter.updateOne(discount, { ...state })
  ),
  on(
    EligibleDiscountsActions.removeDiscountsForProduct,
    ProductActions.removeProduct,
    (state, { payload }) => {
      const keys = (state.ids as string[])
        .map((id) => {
          return { id, entity: state.entities[id] };
        })
        .filter((e) => e?.entity?.productType === payload)
        .map((e) => e.id);
      return adapter.removeMany(keys, state);
    }
  ),
  on(SessionActions.nukeProduct, (state, { productType }) => {
    const keys = (state.ids as string[])
      .map((id) => {
        return { id, entity: state.entities[id] };
      })
      .filter((e) => e?.entity?.productType === productType)
      .map((e) => e.id);
    return adapter.removeMany(keys, state);
  })
);

export function reducer(
  state: EligibleDiscountsState | undefined,
  action: Action
): EligibleDiscountsState {
  return eligibleDiscountsReducer(state, action);
}

function addToCallsInFlight(
  callsInFlight: string[],
  productType: ProductType,
  eligibleDiscountId: string
): string[] {
  const token = `${productType}.${eligibleDiscountId}`;
  const index = callsInFlight.indexOf(token);
  if (index < 0) {
    return [...callsInFlight, token];
  }
  return callsInFlight;
}

function removeFromCallsInFlight(
  callsInFlight: string[],
  productType: ProductType,
  eligibleDiscountId: string
): string[] {
  const token = `${productType}.${eligibleDiscountId}`;
  const index = callsInFlight.indexOf(token);
  if (index >= 0) {
    const copy = [...callsInFlight];
    copy.splice(index, 1);
    return copy;
  }
  return callsInFlight;
}
