<nwx-hub-coverages-card-list
  [vehicles]="(vehicles$ | async) || []"
  [premiums]="(premiums$ | async) || []"
  [coverages]="(coverages$ | async) || []"
  [products]="(products$ | async) || []"
  [enrollment]="(enrollment$ | async) || null"
  [coveredLocations]="(coveredLocations$ | async) || {}"
  [maxSizeMini]="maxSizeMini$ | async"
  [errors]="(errors$ | async) || []"
  (editTelematicsClicked)="onTelematicsClicked()"
></nwx-hub-coverages-card-list>
