import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { PropertyService } from '@core/services/property.service';
import { CoveredLocationModel } from '@entities/covered-location/covered-location.model';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LeakDetectionFormComponent } from '../leak-detection-form/leak-detection-form.component';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@entities/product/product.model';

@Component({
  selector: 'nwx-leak-detection-modal',
  templateUrl: './leak-detection-modal.component.html',
  styleUrl: './leak-detection-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeakDetectionModalComponent implements OnInit, OnDestroy {
  @Input() productType!: ProductType;

  @ViewChild(LeakDetectionFormComponent)
  leakDetectionForm: Nullable<LeakDetectionFormComponent>;

  coveredLocation!: CoveredLocationModel;
  smartHomeEligibleDiscount$!: Observable<Nullable<EligibleDiscountsEntity>>;
  waterProtectionDeviceRequirementCommunicated: boolean | undefined;
  product$!: Observable<Nullable<ProductModel>>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private coveredLocationService: CoveredLocationService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private propertyService: PropertyService,
    private ngbActiveModal: NgbActiveModal,
    private productService: ProductsService
  ) {}

  ngOnInit(): void {
    this.coveredLocationService
      .getCoveredLocation(this.productType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((coveredLocation) => {
        this.coveredLocation = coveredLocation;
        this.waterProtectionDeviceRequirementCommunicated =
          coveredLocation.waterProtectionDeviceRequirementCommunicated;
      });
    this.smartHomeEligibleDiscount$ =
      this.eligibleDiscountsService.getPolicyLineModifier(
        'SmartDevice',
        this.productType
      );
    this.product$ = this.productService.getProduct(this.productType);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(): void {
    if (!this.waterProtectionDeviceRequirementCommunicated) {
      this.leakDetectionForm?.highlightRequiredErrors();
      return;
    }
    this.ngbActiveModal.close();
  }

  onCoveredLocationValueChange(cl: Partial<CoveredLocationModel>): void {
    if (!this.coveredLocation) {
      return;
    }
    this.waterProtectionDeviceRequirementCommunicated =
      cl.waterProtectionDeviceRequirementCommunicated;
    const output = {
      ...this.coveredLocation,
      waterProtectionDeviceRequirementCommunicated:
        cl.waterProtectionDeviceRequirementCommunicated,
    } as unknown as CoveredLocationEntity;
    this.propertyService.storeCoveredLocation(output);
  }

  onSmartHomeValueChange(changes: Partial<EligibleDiscountsEntity>): void {
    this.eligibleDiscountsService.dispatchUpdatePolicyLine({
      name: 'SmartDevice',
      eligibleDiscountId: 'SmartDevice',
      productType: this.productType,
      selectedOptionValue: changes.selectedOptionValue as string,
    });
  }
}
