import { ProductType } from '@core/models/api/dsm-types';
import { ExpectedActions } from '@core/services/action-aggregator.service';
import { CoveredLocationActions, QuoteActions } from '@core/store/actions';
import {
  ConstructionInfoModel,
  CoveredLocationModel,
} from '@core/store/entities/covered-location/covered-location.model';
import { ProtectiveDeviceEntity } from '@entities/protective-devices/protective-devices.reducer';
import { Action } from '@ngrx/store';
import { StringUtils } from '../string.utils';
import { PageUtils } from './page.utils';
import { FortifiedHomeModel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { DateUtils } from '../date.utils';

export class PropertyPageUtils extends PageUtils {
  static buildCoveredlocationModel(
    coveredLocation: CoveredLocationModel,
    changes: Partial<CoveredLocationModel>
  ): Partial<CoveredLocationModel> {
    return {
      productType: coveredLocation?.productType as ProductType,
      businessOnPremises:
        changes.businessOnPremises ?? coveredLocation.businessOnPremises,
      constructionInfo:
        changes.constructionInfo || coveredLocation.constructionInfo,
      currentConstruction:
        changes.currentConstruction ?? coveredLocation.currentConstruction,
      currentRemodelingOrRehabiliation:
        changes.currentRemodelingOrRehabiliation ??
        coveredLocation.currentRemodelingOrRehabiliation,
      datePurchased: changes.datePurchased || coveredLocation.datePurchased,
      deededOwner: changes.deededOwner ?? coveredLocation.deededOwner,
      electricWiring: changes.electricWiring || coveredLocation.electricWiring,
      eligibleProperty:
        changes.eligibleProperty ?? coveredLocation.eligibleProperty,
      flatRoof: changes.flatRoof ?? coveredLocation.flatRoof,
      gatedCommunity: changes.gatedCommunity ?? coveredLocation.gatedCommunity,
      hasHydrantIn1000Ft:
        changes.hasHydrantIn1000Ft ?? coveredLocation.hasHydrantIn1000Ft,
      hasMilitaryMovesLastFiveYears:
        changes.hasMilitaryMovesLastFiveYears ??
        coveredLocation.hasMilitaryMovesLastFiveYears,
      hasOilTank: changes.hasOilTank ?? coveredLocation.hasOilTank,
      historicDistrict:
        changes.historicDistrict ?? coveredLocation.historicDistrict,
      isInCurrLocForThreeYrs:
        changes.isInCurrLocForThreeYrs ??
        coveredLocation.isInCurrLocForThreeYrs,
      isShortSaleAuctForeclose:
        changes.isShortSaleAuctForeclose ??
        coveredLocation.isShortSaleAuctForeclose,
      location: changes.location || coveredLocation.location,
      modularHome: changes.modularHome ?? coveredLocation.modularHome,
      nonResidentialToResidential:
        changes.nonResidentialToResidential ??
        coveredLocation.nonResidentialToResidential,
      numberOfAddressesLastFiveYears:
        changes.numberOfAddressesLastFiveYears ||
        coveredLocation.numberOfAddressesLastFiveYears,
      numberOfAmps: changes.numberOfAmps || coveredLocation.numberOfAmps,
      numberOfFamilies:
        changes.numberOfFamilies || coveredLocation.numberOfFamilies,
      numberOfOccupants:
        changes.numberOfOccupants || coveredLocation.numberOfOccupants,
      occupancy: changes.occupancy || coveredLocation.occupancy,
      oilTank: changes.oilTank || coveredLocation.oilTank,
      // protectiveDevices:
      //   changes.protectiveDevices || coveredLocation.protectiveDevices,
      reconstructionCost:
        changes.reconstructionCost || coveredLocation.reconstructionCost,
      registeredHistoric:
        changes.registeredHistoric ?? coveredLocation.registeredHistoric,
      renovations: changes.renovations || coveredLocation.renovations,
      riskItems: changes.riskItems || coveredLocation.riskItems,
      roofCondition: changes.roofCondition || coveredLocation.roofCondition,
      roofUlType: changes.roofUlType || coveredLocation.roofUlType,
      seasonal: changes.seasonal ?? coveredLocation.seasonal,
      secondaryHome: changes.secondaryHome ?? coveredLocation.secondaryHome,
      thermostat: changes.thermostat ?? coveredLocation.thermostat,
      townhouse: changes.townhouse ?? coveredLocation.townhouse,
      unitsBetweenFirewalls: changes.unitsBetweenFirewalls,
      unitsInBuilding:
        changes.unitsInBuilding || coveredLocation.unitsInBuilding,
      weeksRented: changes.weeksRented || coveredLocation.weeksRented,
    };
  }

  static buildConstructionInfoModel(
    constructionInfo: ConstructionInfoModel,
    changes: Partial<ConstructionInfoModel>
  ): Partial<ConstructionInfoModel> {
    return {
      // This might be an issue since it doesn't really reflect how the form will be used.
      additionalFoundationType:
        changes.additionalFoundationType ||
        constructionInfo.additionalFoundationType,
      additionalHeating:
        changes.additionalHeating || constructionInfo.additionalHeating,
      bathrooms: changes.bathrooms || constructionInfo.bathrooms,
      ceilings: changes.ceilings || constructionInfo.ceilings,
      centralCoolingSystem:
        changes.centralCoolingSystem || constructionInfo.centralCoolingSystem,
      constructionType:
        changes.constructionType || constructionInfo.constructionType,
      exteriorWalls: changes.exteriorWalls || constructionInfo.exteriorWalls,
      flatRoof: changes.flatRoof || constructionInfo.flatRoof,
      flooring: changes.flooring || constructionInfo.flooring,
      foundationType: changes.foundationType || constructionInfo.foundationType,
      fullBathroomDescription:
        changes.fullBathroomDescription ||
        constructionInfo.fullBathroomDescription,
      garageType: changes.garageType || constructionInfo.garageType,
      garages: changes.garages || constructionInfo.garages,
      halfBathroomDescription:
        changes.halfBathroomDescription ||
        constructionInfo.halfBathroomDescription,
      heating: changes.heating || constructionInfo.heating,
      kitchenDescription:
        changes.kitchenDescription || constructionInfo.kitchenDescription,
      kitchens: changes.kitchens || constructionInfo.kitchens,
      numberOfFullBathrooms:
        changes.numberOfFullBathrooms || constructionInfo.numberOfFullBathrooms,
      numberOfHalfBathrooms:
        changes.numberOfHalfBathrooms || constructionInfo.numberOfHalfBathrooms,
      numberOfKitchens:
        changes.numberOfKitchens || constructionInfo.numberOfKitchens,
      numberOfStories:
        changes.numberOfStories || constructionInfo.numberOfStories,
      percentFinishedBasement:
        changes.percentFinishedBasement ||
        constructionInfo.percentFinishedBasement,
      otherStructures:
        changes.otherStructures || constructionInfo.otherStructures,
      roofMaterial: changes.roofMaterial || constructionInfo.roofMaterial,
      roofShape: changes.roofShape || constructionInfo.roofShape,
      siteAccess: changes.siteAccess || constructionInfo.siteAccess,
      squareFootage: changes.squareFootage || constructionInfo.squareFootage,
      wallHeights: changes.wallHeights || constructionInfo.wallHeights,
      walls: changes.walls || constructionInfo.walls,
      yearBuilt: changes.yearBuilt || constructionInfo.yearBuilt,
    };
  }
}

export class HomeownerPageUtils extends PropertyPageUtils {
  static update(nextActions: Action[], expectedActions: ExpectedActions): void {
    const correlationId = StringUtils.generateUuid();

    nextActions.push(
      CoveredLocationActions.updateCoveredLocation({
        payload: 'Homeowner',
        includeMsbEstimateNumber: false,
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        CoveredLocationActions.updateCoveredLocationSuccess.type,
        CoveredLocationActions.updateCoveredLocationError.type,
        correlationId
      )
    );

    nextActions.push(
      QuoteActions.updateQuote({
        productType: 'Homeowner',
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        QuoteActions.updateQuoteSuccess.type,
        QuoteActions.updateQuoteFail.type,
        correlationId
      )
    );
  }

  static formatFortifiedHomeEntity(
    fh: FortifiedHomeModel
  ): EligibleDiscountsEntity {
    const request = {
      eligibleDiscountId: 'FortifiedHome',
      productType: 'Homeowner',
      selectedOptionValue: fh.certificationLevel,
      qualifyingInformation: {},
    } as EligibleDiscountsEntity;
    if (
      fh.certificationLevel &&
      fh.certificationLevel !== 'FortifedHomeForSaferLiving' &&
      fh.certificationLevel !== '2006IRC'
    ) {
      request.qualifyingInformation = {
        fortifiedDesignationDate: DateUtils.formatDateToDSM(
          fh.certificationDate
        ),
      };
    }
    return request;
  }
}

export class CondoPageUtils extends PropertyPageUtils {
  static update(
    protectiveDevices: ProtectiveDeviceEntity[],
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    const correlationId = StringUtils.generateUuid();

    nextActions.push(
      CoveredLocationActions.updateCoveredLocation({
        payload: 'Condominium',
        includeMsbEstimateNumber: false,
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        CoveredLocationActions.updateCoveredLocationSuccess.type,
        CoveredLocationActions.updateCoveredLocationError.type,
        correlationId
      )
    );

    nextActions.push(
      QuoteActions.updateQuote({
        productType: 'Condominium',
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        QuoteActions.updateQuoteSuccess.type,
        QuoteActions.updateQuoteFail.type,
        correlationId
      )
    );
  }
}

export class TenantPageUtils extends PropertyPageUtils {
  static update(nextActions: Action[], expectedActions: ExpectedActions): void {
    const correlationId = StringUtils.generateUuid();

    nextActions.push(
      CoveredLocationActions.updateCoveredLocation({
        payload: 'Tenant',
        includeMsbEstimateNumber: false,
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        CoveredLocationActions.updateCoveredLocationSuccess.type,
        CoveredLocationActions.updateCoveredLocationError.type,
        correlationId
      )
    );

    nextActions.push(
      QuoteActions.updateQuote({
        productType: 'Tenant',
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        QuoteActions.updateQuoteSuccess.type,
        QuoteActions.updateQuoteFail.type,
        correlationId
      )
    );
  }
}
