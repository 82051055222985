import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  DriverVehicleAssignmentEntity,
  sanitizeDriverVehicleAssignmentEntities,
} from '../store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { DsmAdapter } from './dsm.adapter';
import {
  AddDriverVehicleAssignmentRequest,
  GetDriverVehicleAssignmentRequest,
} from '../models/api/request/driver-vehicle-assignment.request';

@Injectable({
  providedIn: 'root',
})
export class DriverVehicleAssignmentAdapter extends DsmAdapter {
  addAssignment(
    request: AddDriverVehicleAssignmentRequest
  ): Observable<DriverVehicleAssignmentEntity[]> {
    return this.request<DriverVehicleAssignmentEntity[]>(
      sanitizeDriverVehicleAssignmentEntities,
      request.productType,
      'PUT',
      `/quotes/${request.quoteId}/driver-vehicle-assignments`,
      'driver-vehicle-assignments',
      {
        body: {
          vehicleDrivers: request.assignments,
        },
      }
    );
  }

  getAssignments(
    request: GetDriverVehicleAssignmentRequest
  ): Observable<DriverVehicleAssignmentEntity[]> {
    return this.request<DriverVehicleAssignmentEntity[]>(
      sanitizeDriverVehicleAssignmentEntities,
      request.productType,
      'GET',
      `/quotes/${request.quoteId}/driver-vehicle-assignments`,
      'driver-vehicle-assignments'
    );
  }
}
