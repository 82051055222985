import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CurrentCarrierCoverageLapse,
  ProductType,
  RenovationType,
} from '@core/models/api/dsm-types';
import { CoverageModel } from '@core/models/views/coverage.model';
import { PeoplePageModel } from '@core/models/views/people-page-model';
import { MemberModel } from '@core/models/views/person.model';
import { PniPageModel } from '@core/models/views/pni-page-model';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { BoltIconType } from '@core/store/entities/task/task.model';
import {
  CoveredLocationModel,
  OilTankModel,
} from '@entities/covered-location/covered-location.model';
import { SmarthomeFormModel } from '@property/components/smart-home/smarthome-form/smarthome-form.model';
import { FilterablePicklistItem } from '@shared/services/current-carrier-metadata.service';
import { Nullable, NWXContentType } from '@shared/utils/type.utils';
import { FormOptions } from '../models/views/form-options';
import { Page } from '../store/entities/navigation/navigation.action';
import { ObjectValidator } from '@core/helper/object-validator';

export interface FormStandards<T> {
  valueChange: EventEmitter<Partial<T>>;
  formReady: EventEmitter<FormGroup>;
  hasChanges(a: T, b: T): boolean;
  buildForm(model: T): FormGroup;
  onValueChange(changes: Partial<T>): void;
  addChildForm(name: string, form: FormGroup): void;
}

export interface ToggleSelectionEvent {
  item: HeaderCardItem;
  index: number;
}

export interface UpdateSelectedEntity {
  entityId: string;
  selected: boolean;
}

export interface HeaderCardItem {
  entityId: string;
  entityIds: EntityIds;
  imageSource?: string;
  prefillId?: string;
  saved: boolean;
  selected: boolean;
  subtitle?: string;
  title: string;
  unselectable: boolean;
}

export interface EntityIds {
  policyHolderIds: Nullable<number>[];
  driverIds: Nullable<number>[];
  householdMemberIds: Nullable<number>[];
}

export interface CurrentCarrierFormModel {
  entityId?: string;
  currentCarrierName?: string;
  currentBodilyInjuryLimit?: string;
  currentCarrierTerms?: number;
  coverageLapse?: CurrentCarrierCoverageLapse;
  reasonForCoverageLapse?: string;
  productType?: ProductType;
  isNoNeedState?: boolean;
}

export interface CurrentCarrierFormOptions extends FormOptions {
  showAccHit?: boolean;
  showAccNoHitWarning?: boolean;
  allowManualEntry?: boolean;
  currentCarrierNames?: FilterablePicklistItem[];
  priorBiLimits?: FilterablePicklistItem[];
  lapseInCoverage?: FilterablePicklistItem[];
  reasonForLapse?: FilterablePicklistItem[];
  productType?: ProductType;
  sendDefault?: boolean;
}
export interface PolicyDateOptions {
  // YYYY-MM-DD
  minDate: string;
  maxDate: string;
}

export interface AdjacentPages {
  next: Page;
  previous: Page;
}

export type DsmEntityId = string | number;

export interface SubmitPeoplePageData {
  products: ProductModel[];
}

export interface SubmitPniPageData {
  products: ProductType[];
  auto: Nullable<ProductModel>;
  property: ProductModel;
}

export interface PeopleForm {
  people: MemberModel[];
}

export interface TogglePersonSelectionEvent extends Event {
  index: number;
  person: MemberModel;
}

export interface PeoplePageSubmitEvent {
  model: PeoplePageModel;
  nextPage: string;
  showSpinner?: string | boolean;
}

export interface PniPageSubmitEvent {
  model: PniPageModel;
  hasMailingAddress?: boolean;
  nextPage: string;
}

export interface PageSubmitEvent {
  nextPage: string;
  showSpinner?: string | boolean;
}

export interface CoverageUpdateEvent {
  productType: ProductType;
  coverage: Partial<CoverageModel>;
}

export interface ProductButton {
  altText: string;
  cssClass: string;
  iconAction: string;
  iconUrl: string;
  label: string;
}

export interface SelectOption {
  display: string;
  value: any;
}

export interface DiffedStringArrays {
  added: string[];
  removed: string[];
  unchanged: string[];
}

export interface ContentBundle {
  header?: string;
  text?: string[];
  orderedText?: {
    first?: string;
    second?: string;
    third?: string;
  };
  contentType?: NWXContentType;
  iconUrl?: string;
  products?: ProductModel[];
}

export interface BoltNotification {
  type: BoltIconType;
  message: string;
  header?: string;
}
export interface SearchQuoteResult {
  quoteId: string;
  productType: ProductType;
  accountNumber?: string;
  firstName?: string;
  lastName?: string;
  address?: AddressEntity;
  createDate?: string;
}

export interface PropertyInfoModel {
  propertyForm: Partial<Nullable<CoveredLocationModel>>;
  smarthomeForm: Partial<Nullable<SmarthomeFormModel>>;
  protectiveDevicesForm: Partial<Nullable<{ [key: string]: string }>>;
  eligibility?: 'yes' | 'no';
  oilTankForm: Partial<Nullable<OilTankModel>>;
}

export type Eligibility = 'yes' | 'no' | undefined;

export interface TasksFormModel {
  personalAutoEffectiveDate?: string;
  msaEffectiveDate?: string;
  homeownerEffectiveDate?: string;
  condominiumEffectiveDate?: string;
  tenantEffectiveDate?: string;
  personalUmbrellaEffectiveDate?: string;
  boatEffectiveDate?: string;
  rvEffectiveDate?: string;
}

export interface PreQualification {
  questionText?: string;
  questionCode: string;
  answerValue?: boolean;
}

export function sanitizePreQualifications(input: unknown): PreQualification[] {
  return ObjectValidator.sanitizeArray(input, sanitizePreQualification);
}

export function sanitizePreQualification(input: unknown): PreQualification {
  return ObjectValidator.forceSchema<PreQualification>(
    input,
    {
      questionText: 'string',
      questionCode: 'string',
      answerValue: 'boolean',
    },
    ['questionText', 'answerValue']
  );
}

export interface RenovationEntity {
  renovationId: Nullable<number>;
  type?: Nullable<RenovationType>;
  year?: Nullable<number>;
  isEditable?: boolean; // assume true if unset
}

export interface RenovationsListEntity {
  productType: ProductType;
  renovations: RenovationEntity[];
}
