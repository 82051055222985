import { createReducer, on } from "@ngrx/store";
import { PropertyFormActions, SessionActions } from "../actions";
import { FortifiedHomeModel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';

export interface PropertyFormState {
  roofYearImmutable: boolean;
  fortifiedHome: FortifiedHomeModel;
}

const initialState: PropertyFormState = {
  roofYearImmutable: false,
  fortifiedHome: {
    certificationLevel: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(SessionActions.nukeProduct, (state, { productType }) => {
    if (
      productType === 'Homeowner' ||
      productType === 'Condominium' ||
      productType === 'Tenant'
    ) {
      return initialState;
    }
    return state;
  }),
  on(
    PropertyFormActions.setRoofYearImmutable,
    (state, { roofYearImmutable }) => ({
      ...state,
      roofYearImmutable,
    })
  ),
  on(PropertyFormActions.setFortifiedHome, (state, { payload }) => ({
    ...state,
    fortifiedHome: payload,
  }))
);
