<div *ngFor="let product of products">
  <nwx-dummy-producer-form
    [product]="product"
    [disabled]="!isProductSelected(product.productType)"
    [modalClosed]="modalClosed"
    [form]="form"
    [agency]="(agency$ | async) || null"
    [submitted]="modalSubmitted"
    [prefill]="prefill"
    (selected)="prefillEmpty($event)"
    (error)="error.next($event)"
  ></nwx-dummy-producer-form>
</div>
