import {
  PreQualification,
  sanitizePreQualification,
} from '@core/interfaces/interfaces';
import {
  AddressEntity,
  sanitizeAddressEntity,
} from '@core/store/entities/address/address.entity';
import {
  CoverageEntity,
  sanitizeCoverageEntity,
} from '@core/store/entities/coverage/coverage.entity';
import {
  DiscountEntity,
  sanitizeDiscountEntity,
} from '@core/store/entities/discount/discount.entity';
import {
  DriverEntity,
  sanitizeDriverEntity,
} from '@core/store/entities/driver/driver.entity';
import {
  PolicyHolderEntity,
  sanitizePolicyHolderEntity,
} from '@core/store/entities/policyholder/policyholder.entity';
import {
  PremiumEntity,
  sanitizePremiumEntity,
} from '@core/store/entities/premium/premium.entity';
import {
  VehicleEntity,
  sanitizeVehicleEntity,
} from '@core/store/entities/vehicle/vehicle.entity';
import {
  PreBindDocumentEntity,
  sanitizePreBindDocumentEntity,
} from '@core/models/entities/pre-bind-document.entity';
import {
  DocumentDeliveryType,
  NationwideAccountRegistrationInfo,
  OfferingType,
  ProductType,
  QuoteStatus,
  RatingType,
  TermType,
  sanitizeNationwideAccountRegistrationInfo,
} from '../dsm-types';
import { ProducerResponse } from '@core/adapters/producer-search.model';
import {
  HouseholdMemberEntity,
  sanitizeHouseholdMemberEntity,
} from '@entities/household-member/household-member.model';
import {
  LocationExposureEntity,
  sanitizeLocationExposureEntity,
} from '@entities/exposures/location-exposures/location-exposures.entity';
import {
  UnderlyingPolicyEntity,
  sanitizeUnderlyingPolicyEntity,
} from '@entities/underlying-policy/underlying-policy.entity';
import {
  VehicleExposureEntity,
  sanitizeVehicleExposureEntity,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import {
  WatercraftExposureEntity,
  sanitizeWatercraftExposureEntity,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.entity';
import {
  InformationalMessages,
  sanitizeInformationalMessage,
} from '@core/adapters/quote-retrieve.adapter';
import {
  AutoCharacteristics,
  sanitizeAutoCharacteristics,
} from '@entities/product/product.model';
import { ObjectValidator } from '@core/helper/object-validator';
import { sanitizeProducerResponse } from './producer-response.model';

export interface UpdateQuoteResponse {
  informationalMessages?: InformationalMessages[];
  producer: ProducerResponse;
  autoCharacteristics?: AutoCharacteristics;
  creditConsent: boolean;
  currentBillTo?: 'PolicyPriNamedInsured' | 'Mortgagee';
  discounts: DiscountEntity[];
  documents?: PreBindDocumentEntity[];
  docDelPreference?: DocumentDeliveryType;
  drivers: DriverEntity[];
  effectiveDate: string;
  hasCustomerViewedDocuments?: boolean;
  hasDrivingDataConsent?: boolean;
  hasReceivedRequiredDocuments?: boolean;
  householdMembers?: HouseholdMemberEntity[];
  isAssignedRiskPlan?: boolean;
  locationExposures?: LocationExposureEntity[];
  nationwideAccountRegistrationInfo?: NationwideAccountRegistrationInfo;
  offeredQuotes: OfferedQuote[];
  offeringType?: OfferingType;
  policyAddress: AddressEntity;
  policyHolders: PolicyHolderEntity[];
  policyNumber?: string;
  prequalificationAnswers?: PreQualification[];
  productType: ProductType;
  quoteId: string;
  quoteStatus: QuoteStatus;
  ratingType?: RatingType;
  sessionId: string;
  termType: TermType;
  underlyingPolicies?: UnderlyingPolicyEntity[];
  vehicleExposures?: VehicleExposureEntity[];
  vehicles: VehicleEntity[];
  watercraftExposures?: WatercraftExposureEntity[];
  yearsWithPriorCarrier?: string;
}

export function sanitizeUpdateQuoteResponse(
  input: unknown
): UpdateQuoteResponse {
  return ObjectValidator.forceSchema<UpdateQuoteResponse>(
    input,
    {
      informationalMessages: [sanitizeInformationalMessage],
      producer: sanitizeProducerResponse,
      autoCharacteristics: sanitizeAutoCharacteristics,
      creditConsent: 'boolean',
      currentBillTo: 'string',
      discounts: [sanitizeDiscountEntity],
      documents: [sanitizePreBindDocumentEntity],
      drivers: [sanitizeDriverEntity],
      effectiveDate: 'string',
      hasCustomerViewedDocuments: 'boolean',
      hasDrivingDataConsent: 'boolean',
      hasReceivedRequiredDocuments: 'boolean',
      householdMembers: [sanitizeHouseholdMemberEntity],
      isAssignedRiskPlan: 'boolean',
      locationExposures: [sanitizeLocationExposureEntity],
      nationwideAccountRegistrationInfo:
        sanitizeNationwideAccountRegistrationInfo,
      offeredQuotes: sanitizeOfferedQuotes,
      offeringType: 'string',
      policyAddress: sanitizeAddressEntity,
      policyHolders: [sanitizePolicyHolderEntity],
      policyNumber: 'string',
      prequalificationAnswers: [sanitizePreQualification],
      productType: 'string',
      quoteId: 'string',
      quoteStatus: 'string',
      ratingType: 'string',
      sessionId: 'string',
      termType: 'string',
      underlyingPolicies: [sanitizeUnderlyingPolicyEntity],
      vehicleExposures: [sanitizeVehicleExposureEntity],
      vehicles: [sanitizeVehicleEntity],
      watercraftExposures: [sanitizeWatercraftExposureEntity],
      yearsWithPriorCarrier: 'string',
    },
    [
      'informationalMessages',
      'autoCharacteristics',
      'currentBillTo',
      'documents',
      'discounts',
      'hasCustomerViewedDocuments',
      'hasDrivingDataConsent',
      'hasReceivedRequiredDocuments',
      'householdMembers',
      'isAssignedRiskPlan',
      'locationExposures',
      'nationwideAccountRegistrationInfo',
      'offeringType',
      'policyNumber',
      'prequalificationAnswers',
      'ratingType',
      'underlyingPolicies',
      'vehicleExposures',
      'watercraftExposures',
      'yearsWithPriorCarrier',
    ]
  );
}

export interface OfferedQuote {
  coverages: CoverageEntity[];
  premium: PremiumEntity;
  hasBlockingUwIssues?: boolean;
  packageName?: string;
}

export function sanitizeOfferedQuotes(input: unknown): OfferedQuote[] {
  return ObjectValidator.sanitizeArray(input, sanitizeOfferedQuote);
}

export function sanitizeOfferedQuote(input: unknown): OfferedQuote {
  return ObjectValidator.forceSchema<OfferedQuote>(
    input,
    {
      coverages: [sanitizeCoverageEntity],
      premium: sanitizePremiumEntity,
      hasBlockingUwIssues: 'boolean',
      packageName: 'string',
    },
    ['premium', 'hasBlockingUwIssues', 'packageName']
  );
}
