import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import {
  CoverageCodes,
  CoverageIds,
  CUSTOM_EQUIPMENT_MAX,
  CUSTOM_EQUIPMENT_MIN,
  PERSONAL_AUTO_TRAILER_TYPE,
} from '@shared/constants/app-constants';
import { TRANSPORTATION_EXPENSE_HELP_TEXT } from '@shared/constants/help-text-constants';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { Nullable } from '@shared/utils/type.utils';
import { CoverageDisplayGrouping } from '../constants/coverage-display-grouping';
import { CoveragesMetadata } from '../constants/coverage-metadata';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageModel } from '../models/views/coverage.model';
import {
  AutoCoverageGrouping,
  CondoCoverageGroupings,
  CoverageGrouping,
  HomeownersCoverageGroupings,
  RentersCoverageGroupings,
  UmbrellaCoverageGroupings,
} from '../store/entities/coverage/coverage-grouping.model';
import {
  CoverageChange,
  CoverageEntity,
  CoverageOption,
  CoverageTerm,
  SelectedValue,
} from '../store/entities/coverage/coverage.entity';
import { DriverEntity } from '../store/entities/driver/driver.entity';
import { VehicleEntity } from '../store/entities/vehicle/vehicle.entity';
import { PersonUtils } from '@shared/utils/person.utils';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { CustomValidators } from '@app/validators/custom-validators';
import { PropertyService } from '@core/services/property.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoverageHelper {
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService
  ) {}

  initAutoMsaCoverageGrouping(
    coverages: Nullable<CoverageEntity[]>,
    quoteState: Nullable<string>,
    vehicles: Nullable<VehicleEntity[]>,
    drivers: Nullable<DriverEntity[]>,
    productType: ProductType
  ): AutoCoverageGrouping {
    const autoCoverageGroupings: AutoCoverageGrouping = { vehicles: [] };

    if (!coverages) {
      return autoCoverageGroupings;
    }

    const autoCoverages = coverages.filter(
      (coverage) => coverage.productId === productType
    );
    const autoPolicyCoverages = autoCoverages.filter(
      (coverage) =>
        !coverage.coverableId &&
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.Policy,
          quoteState || '',
          productType
        )
    );
    autoPolicyCoverages.sort(
      CoverageUtils.coverageSorter(quoteState || '', productType)
    );
    autoCoverageGroupings.policy = {
      coverages: this.mergeSpreadableCoverages(autoPolicyCoverages),
      title: CoverageDisplayGrouping.Policy,
    };

    autoCoverageGroupings.vehicles = [];
    vehicles?.forEach((vehicle, index) => {
      const vehicleCoverages = CoverageHelper.shouldFilterUnavailCoverages(
        vehicle
      )
        ? autoCoverages.filter(
            (coverage) =>
              Number(vehicle.vehicleId) === Number(coverage.coverableId) &&
              coverage.available
          )
        : autoCoverages.filter(
            (coverage) =>
              Number(vehicle.vehicleId) === Number(coverage.coverableId)
          );
      vehicleCoverages.sort(
        CoverageUtils.coverageSorter(quoteState || '', productType)
      );
      const title = `${vehicle.year} ${vehicle.make}`;
      const subTitle = `(${index + 1} of ${vehicles.length}) ${
        vehicle.series || vehicle.model || ''
      }`;
      autoCoverageGroupings.vehicles?.push({
        title,
        subTitle,
        coverages: vehicleCoverages,
        annualMiles: vehicle.annualMiles,
        vehicleId: vehicle.vehicleId,
      });
    });

    autoCoverageGroupings.drivers = [];
    drivers?.forEach((driver) => {
      const driverId = PersonUtils.driverIdFromEntity(
        driver as MemberEntity,
        productType
      );
      if (driverId) {
        const driverCoverages = autoCoverages.filter((coverage) => {
          return (
            driverId === Number(coverage.coverableId) &&
            CoverageUtils.filterCoverageByGrouping(
              coverage,
              CoverageDisplayGrouping.Driver,
              quoteState || '',
              productType
            )
          );
        });
        if (driverCoverages.length > 0) {
          driverCoverages.sort(
            CoverageUtils.coverageSorter(quoteState || '', productType)
          );
          autoCoverageGroupings.drivers?.push({
            title: PersonUtils.GetPersonName(driver as MemberEntity),
            coverages: driverCoverages,
            driverId,
          });
        }
      }
    });

    const autoAdditionalPolicyCoverages = autoCoverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.AdditionalPolicy,
        quoteState || '',
        productType
      )
    );
    autoAdditionalPolicyCoverages.sort(
      CoverageUtils.coverageSorter(quoteState || '', productType)
    );
    autoCoverageGroupings.additionalPolicy = {
      title: CoverageDisplayGrouping.AdditionalPolicy,
      coverages: autoAdditionalPolicyCoverages,
    };

    return autoCoverageGroupings;
  }

  initPropertyCoverageGrouping(
    coverages: Nullable<CoverageEntity[]>,
    quoteState: Nullable<string>,
    productType: ProductType
  ):
    | RentersCoverageGroupings
    | HomeownersCoverageGroupings
    | CondoCoverageGroupings {
    const propertyCoverageGroupings =
      this.getBasePropertyGroupings(productType);
    if (!coverages || propertyCoverageGroupings === null || !quoteState) {
      return {};
    }

    const propertyCoverages = coverages.filter(
      (coverage) => coverage.productId === productType
    );

    propertyCoverageGroupings.propertyLiability =
      this.buildPropertyLiabilityCoverageGrouping(
        propertyCoverages,
        quoteState,
        productType
      );
    propertyCoverageGroupings.additionalProtection =
      this.buildAdditionalProtectionCoverageGrouping(
        propertyCoverages,
        quoteState,
        productType
      );
    propertyCoverageGroupings.specialLimitsOfLiability =
      this.buildSpecialLimitsLiabilityCoverageGrouping(
        propertyCoverages,
        quoteState,
        productType
      );

    return propertyCoverageGroupings;
  }

  buildPropertyLiabilityCoverageGrouping(
    coverages: CoverageEntity[],
    quoteState: string,
    productId: ProductType
  ): CoverageGrouping {
    const propertyLiabilityCoverages = coverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.PropertyLiability,
        quoteState,
        productId
      )
    );
    propertyLiabilityCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    return {
      coverages: propertyLiabilityCoverages,
      title: CoverageDisplayGrouping.PropertyLiability,
      showOtherStructures: true,
    };
  }

  buildAdditionalProtectionCoverageGrouping(
    coverages: CoverageEntity[],
    quoteState: string,
    productId: ProductType
  ): CoverageGrouping {
    const additionalProtectionCoverages = coverages.filter(
      (coverage) =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.AdditionalProtection,
          quoteState,
          productId
        ) && CoverageUtils.filterCoverageByAvailable(coverage)
    );
    additionalProtectionCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    return {
      coverages: additionalProtectionCoverages,
      title: CoverageDisplayGrouping.AdditionalProtection,
    };
  }

  buildSpecialLimitsLiabilityCoverageGrouping(
    coverages: CoverageEntity[],
    quoteState: string,
    productId: ProductType
  ): CoverageGrouping {
    const specialLimitsOfLiabilityCoverages = coverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.SpecialLimitsOfLiability,
        quoteState,
        productId
      )
    );
    specialLimitsOfLiabilityCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    return {
      coverages: specialLimitsOfLiabilityCoverages,
      title: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    };
  }

  initUmbrellaCoverageGrouping(
    coverages: Nullable<CoverageEntity[]>,
    quoteState: Nullable<string>
  ): UmbrellaCoverageGroupings {
    const umbrellaCoverageGroupings: UmbrellaCoverageGroupings = {};

    if (!coverages) {
      return umbrellaCoverageGroupings;
    }

    const umbrellaCoverages = coverages.filter(
      (coverage) => coverage.productId === 'PersonalUmbrella'
    );

    const availableCoverages = umbrellaCoverages.filter(
      (coverage) => coverage.available
    );

    const umbrellaPolicyCoverages = availableCoverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.Policy,
        quoteState || '',
        'PersonalUmbrella'
      )
    );

    const umbrellaUnderlyingCoverages = availableCoverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.Underlying,
        quoteState || '',
        'PersonalUmbrella'
      )
    );

    const umbrellaAdditionalCoverages = availableCoverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        CoverageDisplayGrouping.AdditionalUnderlying,
        quoteState || '',
        'PersonalUmbrella'
      )
    );

    umbrellaPolicyCoverages.sort(
      CoverageUtils.coverageSorter(quoteState || '', 'PersonalUmbrella')
    );
    umbrellaCoverageGroupings.policy = {
      coverages: umbrellaPolicyCoverages,
      title: CoverageDisplayGrouping.Policy,
    };

    umbrellaCoverageGroupings.underlying = {
      coverages: umbrellaUnderlyingCoverages, // TODO
      title: CoverageDisplayGrouping.Underlying,
    };

    umbrellaCoverageGroupings.additional = {
      coverages: umbrellaAdditionalCoverages, // TODO
      title: CoverageDisplayGrouping.AdditionalUnderlying,
    };

    return umbrellaCoverageGroupings;
  }

  buildCoveragesForm(productId: ProductType, model: CoverageModel): FormGroup {
    const formGroup: { [key: string]: FormControl } = {};
    Object.keys(model).forEach((modelKey) => {
      const keySplit = modelKey.split('_');
      const termCode = !!keySplit[2] ? keySplit[2] : keySplit[1];
      formGroup[modelKey] = this.formBuilder.control(model[modelKey], {
        updateOn: 'blur',
      });
      this.addFormValidators(productId, termCode, formGroup[modelKey]);
    });
    return this.formBuilder.group(formGroup);
  }

  rebuildCoveragesForm(
    form: FormGroup,
    productId: ProductType,
    model: CoverageModel
  ): void {
    const modelKeys = Object.keys(model);
    for (const key of modelKeys) {
      if (!form.controls[key]) {
        const keySplit = key.split('_');
        const termCode = keySplit?.[2] || keySplit?.[1];
        const control = this.formBuilder.control(model[key], {
          updateOn: 'blur',
        });
        this.addFormValidators(productId, termCode, control);
        form.setControl(key, control);
      }
    }
    for (const key of Object.keys(form.controls)) {
      if (modelKeys.indexOf(key) < 0) {
        form.removeControl(key);
      }
    }
  }

  createCoverageChanges(
    coverages: Nullable<CoverageEntity[]>,
    coverage: Nullable<CoverageEntity>,
    code: string,
    value: string,
    quoteState?: Nullable<string>
  ): CoverageChange[] {
    if (!coverages || !coverage) {
      return [];
    }
    // spreadable coverages need to send a coverage change object for each coverable id
    if (this.isSpreadable(coverage)) {
      const coverableIds = coverages
        .filter((cov) => cov.coverageId === coverage.coverageId)
        .map((cov) => cov.coverableId);
      return coverableIds.map((coverableId) =>
        this.createCoverageChange({
          coverage,
          code,
          value,
          coverableId,
          quoteState,
        })
      );
    } else {
      const { productId, coverageId, coverageLevel, coverableId } = coverage;
      code = this.modifySelectedValuesCodeForChange(coverage, code, value);
      value = this.modifySelectedValuesValueForChange(
        coverages,
        coverage,
        value
      );
      return [
        this.createCoverageChange({
          coverage,
          code,
          value,
          coverableId,
        }),
      ];
    }
  }

  createCoverageChange(data: {
    coverage: CoverageEntity;
    code: string;
    value: string;
    coverableId?: number | string;
    quoteState?: Nullable<string>;
  }): CoverageChange {
    const selectedValues: SelectedValue[] = [];
    if (
      data.quoteState === 'MI' &&
      data.coverage.coverageId === CoverageIds.PIP &&
      data.value === '50000'
    ) {
      selectedValues.push(
        { code: data.code, value: data.value },
        { code: CoverageCodes.PIPOption, value: '7' }
      );
    } else if (
      data.quoteState === 'KY' &&
      data.code === 'PIPLimit' &&
      data.coverage.coverageId === CoverageIds.PIP
    ) {
      if (data.value === 'false') {
        selectedValues.push({ code: 'PIPGuestOnly', value: 'true' });
      } else {
        selectedValues.push({ code: 'PIPGuestOnly', value: 'false' });
      }
    } else if (
      data.quoteState === 'FL' &&
      data.coverage.coverageId === CoverageIds.PIP &&
      data.coverage.productId === 'RV'
    ) {
      // sort the terms and remove the outdated term from the selectedValue array
      const formattedSelectedValues = CoverageHelper.sortPipTerms(
        data.coverage,
        data.quoteState
      ).selectedValue.filter(
        (selectedValue) => selectedValue.code !== data.code
      );
      // add the updated term
      formattedSelectedValues.splice(1, 0, {
        code: data.code,
        value: data.value,
      });
      formattedSelectedValues.forEach((selectedValue) =>
        selectedValues.push(selectedValue)
      );
    } else if (
      data.coverage.coverageId === 'HomecareLiability' &&
      data.code === 'HomecareLiabilityLimit' &&
      data.value !== 'false'
    ) {
      selectedValues.push(
        { code: data.code, value: data.value },
        { code: 'NumberBeingCaredFor', value: '1' }
      );
    } else if (
      // If the coverage is being updated with a single SelectedValue of "selected:true", we must send a deductible selection.
      data.coverage.coverageId === CoverageIds.EarthquakeBasicCoverage &&
      data.code === 'selected' &&
      data.value === 'true'
    ) {
      this.applyLowestValueToSelectedValue(
        data.coverage,
        CoverageCodes.EQBasicCoverageDeductible,
        selectedValues
      );
    } else if (
      data.coverage.coverageId === CoverageIds.PersonalPropOtherRes &&
      data.code === CoverageCodes.PersonalPropOtherResLimit
    ) {
      if (data.value === 'false') {
        selectedValues.push({ code: 'selected', value: data.value });
      }
      if (data.value === 'true' || Number(data.value)) {
        const value = Number(data.value) ? data.value : '0';
        selectedValues.push({ code: data.code, value });
      }
    } else {
      selectedValues.push({ code: data.code, value: data.value });
      if (data.value !== 'false' && data.coverage?.terms?.length) {
        this.addSelectedValuesForSingleOptionTerms(
          selectedValues,
          data.coverage,
          data.code
        );
      }
    }
    this.removeSelectedFalseIfSettingSomethingElse(selectedValues, data.code);
    return {
      productId: data.coverage.productId,
      selectedValue: selectedValues,
      coverageId: data.coverage.coverageId,
      coverageLevel: data.coverage.coverageLevel,
      coverableId: (data.coverableId && +data.coverableId) || undefined,
    };
  }

  private removeSelectedFalseIfSettingSomethingElse(
    selectedValues: SelectedValue[],
    changedCode: string
  ): void {
    if (changedCode === 'selected') {
      return;
    }
    if (selectedValues.length < 2) {
      return;
    }
    const selectedIndex = selectedValues.findIndex(
      (v) => v.code === 'selected'
    );
    if (selectedIndex < 0 || selectedValues[selectedIndex].value === 'true') {
      return;
    }
    selectedValues.splice(selectedIndex, 1);
  }

  generateBlankCoverageModel(model: CoverageModel): Partial<CoverageModel> {
    const obj: CoverageModel = {};
    Object.keys(model).forEach((element) => {
      obj[element] = '';
    });
    return obj;
  }

  private isSpreadable(coverage: CoverageEntity): Nullable<boolean> {
    const metadata = CoveragesMetadata[coverage.coverageId];
    return (
      (coverage.terms && coverage.terms.some((term) => term.spreadable)) ||
      (metadata && metadata.spreadable)
    );
  }

  private modifySelectedValuesCodeForChange(
    coverage: CoverageEntity,
    code: string,
    value: string
  ): string {
    if (
      coverage.coverageId === CoverageIds.MineSubsidence &&
      value === 'true'
    ) {
      return CoverageCodes.MineSubsidenceLimit;
    } else if (
      coverage.coverageId === CoverageIds.PersonalPropOtherRes &&
      value !== 'false'
    ) {
      return CoverageCodes.PersonalPropOtherResLimit;
    } else {
      return code;
    }
  }

  private getLowestOption(term: CoverageTerm): Nullable<CoverageOption> {
    if (!term.options?.length) {
      return null;
    }
    const termOptions = [...term.options];
    const lowest = termOptions.sort((a, b) => +a.value - +b.value)[0];
    return lowest;
  }

  private applyLowestValueToSelectedValue(
    coverage: CoverageEntity,
    termCode: string,
    selectedValues: SelectedValue[]
  ): void {
    const term = coverage.terms.find((t) => t.code === termCode);
    const existingSelectedValue = selectedValues.find(
      (v) => v.code === termCode
    );
    if (term) {
      const lowestOption = this.getLowestOption(term);
      const newSelectedValue = {
        code: termCode,
        value: lowestOption?.value || '',
      };
      // this is a safety check, the selected value should not already exist in the array
      if (existingSelectedValue) {
        const index = selectedValues.indexOf(existingSelectedValue);
        selectedValues.splice(index, 1, newSelectedValue);
      } else {
        selectedValues.push(newSelectedValue);
      }
    }
  }

  private modifySelectedValuesValueForChange(
    coverages: CoverageEntity[],
    coverage: CoverageEntity,
    value: string
  ): string {
    if (
      coverage.coverageId === CoverageIds.MineSubsidence &&
      value === 'true'
    ) {
      const basicCoverage = coverages.find(
        (cvg) => cvg.coverageId === CoverageIds.BasicCoverage
      );
      const basicCoverageLimit = basicCoverage?.selectedValue.find(
        (selected) => selected.code === CoverageCodes.BasicCoverageLimit
      );
      return basicCoverageLimit?.value || '';
    }
    return value;
  }

  private addSelectedValuesForSingleOptionTerms(
    selectedValues: SelectedValue[],
    coverage: CoverageEntity,
    termCode: string
  ): void {
    for (const term of coverage.terms) {
      if (term.code === termCode) {
        continue;
      }
      if (!term.options || term.options.length !== 1) {
        continue;
      }
      selectedValues.push({
        code: term.code,
        value: term.options[0].value,
      });
    }
  }

  private addFormValidators(
    productId: ProductType,
    coverageCode: string,
    formControl: FormControl
  ): void {
    switch (productId) {
      case 'Homeowner':
        this.addHomeownersValidators(coverageCode, formControl);
        break;
      case 'MSA':
        this.addMsaValidators(coverageCode, formControl);
        break;
      case 'RV':
        this.addRvValidators(coverageCode, formControl);
        break;
    }
  }

  private addMsaValidators(termCode: string, formControl: FormControl): void {
    switch (termCode) {
      case CoverageCodes.CustomEquipmentLimit:
        formControl.setValidators([
          CustomValidators.minAfterCleanup(CUSTOM_EQUIPMENT_MIN),
          CustomValidators.maxAfterCleanup(CUSTOM_EQUIPMENT_MAX),
        ]);
        break;
      default:
        break;
    }
  }

  addHomeownersValidators(termCode: string, formControl: FormControl): void {
    switch (termCode) {
      case CoverageCodes.BasicCoverageLimit:
        this.propertyService
          .getReconstructionCost('Homeowner')
          .pipe(take(1))
          .subscribe((reconstructionCost) => {
            if (reconstructionCost) {
              formControl.setValidators([
                CustomValidators.minAfterCleanup(+reconstructionCost),
              ]);
            }
          });
        break;
      case CoverageCodes.BuildingAdditionsAlterationsLimit:
        // TODO: how do we determine the min/max value?
        // this.addValidators(formControl, Validators.max(DWELLING_MAX));
        formControl.setValidators([
          CustomValidators.minAfterCleanup(+formControl.value * 0.1),
        ]);
        break;
    }
  }

  addRvValidators(termCode: string, formControl: FormControl): void {
    switch (termCode) {
      case CoverageCodes.FTSSLimit:
      case CoverageCodes.RCPELimit:
        formControl.setValidators([
          () => CustomValidators.isIncrementOf(formControl, 1000, 99000, 1000),
        ]);
    }
  }

  private getBasePropertyGroupings(
    productId: ProductType
  ):
    | RentersCoverageGroupings
    | HomeownersCoverageGroupings
    | CondoCoverageGroupings
    | null {
    if (productId === 'Tenant') {
      return {} as RentersCoverageGroupings;
    }
    if (productId === 'Homeowner') {
      return {} as HomeownersCoverageGroupings;
    }
    if (productId === 'Condominium') {
      return {} as CondoCoverageGroupings;
    }
    return null;
  }

  mergeSpreadableCoverages(coverages: CoverageEntity[]): CoverageEntity[] {
    const result: CoverageEntity[] = [];
    coverages
      .filter(
        (coverage) =>
          coverage.selectedValue && coverage.selectedValue.length > 0
      )
      .forEach((coverage) => {
        coverage = { ...coverage };
        const isSpreadable = this.isSpreadable(coverage);
        const existingSpreadableCoverage = result.find(
          (cov) => cov.coverageId === coverage.coverageId
        );
        if (coverage.coverageId === 'TREX') {
          coverage.helpText = TRANSPORTATION_EXPENSE_HELP_TEXT;
        }

        if (coverage.coverageId === 'BI' && coverage.terms.length) {
          coverage.terms = coverage.terms.map((term) => {
            if (term.code !== CoverageCodes.BISuppSpousalLiability) {
              return term;
            }
            return {
              ...term,
              mandatory: true,
            };
          });
        }
        if (isSpreadable && existingSpreadableCoverage) {
          const existingCoverageCost =
            existingSpreadableCoverage.coverageCost || [];
          const currCoverageCost = coverage.coverageCost || [];
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const sum = (costs: any[]) =>
            costs
              .map((cost) => cost.actualTermAmount?.amount || 0)
              .reduce((v, a) => v + a, 0);
          const newAmount = sum(existingCoverageCost) + sum(currCoverageCost);
          if (newAmount) {
            existingSpreadableCoverage.coverageCost = [
              {
                actualTermAmount: {
                  amount: newAmount,
                  currency: 'usd',
                },
              },
            ];
          }
        } else {
          result.push({ ...coverage });
        }
      });
    return result;
  }

  static sortPipTerms(
    coverage: CoverageEntity,
    quoteState: string
  ): CoverageEntity {
    /**
     * 'Correct' term order (currently not guaranteed by dsm)
     * PIPLimit
     * PIPPackageLimit (DE)
     * PIPAppliesTo (FL)
     * PIPDeductible
     * PIPGuestOnly (KY)
     */
    const limitTerm = coverage.terms?.find(
      (t) =>
        t.code === CoverageCodes.PIPLimit ||
        t.code === CoverageCodes.PIPPackageLimit
    );
    const appliesToTerm = coverage.terms?.find(
      (t) => t.code === CoverageCodes.PIPAppliesTo
    );
    const guestOnlyTerm = coverage.terms?.find(
      (t) => t.code === CoverageCodes.PIPGuestOnly
    );
    const deductibleTerm = coverage.terms?.find(
      (t) => t.code === CoverageCodes.PIPDeductible
    );
    const workLossExcludedTerm = coverage.terms?.find(
      (t) => t.code === CoverageCodes.PIPWorkLossExcluded
    );

    if (limitTerm?.options?.length === 1) {
      const newTerms: CoverageTerm[] = [
        // If PIPLimit or PIPPackageLimit is saying it's editable but there's only one term, it's not editable.
        {
          ...limitTerm,
          spreadable: true,
          editable: quoteState !== 'NY' ? false : true,
        },
      ];
      if (appliesToTerm) {
        newTerms.push(appliesToTerm);
      }
      if (deductibleTerm) {
        newTerms.push(deductibleTerm);
      }
      if (guestOnlyTerm) {
        newTerms.push(guestOnlyTerm);
      }
      if (workLossExcludedTerm) {
        newTerms.push(workLossExcludedTerm);
      }
      return {
        ...coverage,
        terms: newTerms,
      };
    }
    return coverage;
  }

  static shouldFilterUnavailCoverages(vehicle: VehicleEntity): boolean {
    return (
      vehicle.vehicleType === 'motor' ||
      vehicle.vehicleType === 'UtilityTrailer' ||
      vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE
    );
  }
}
