import {
  DocumentDeliveryType,
  NationwideAccountRegistrationInfo,
  ProductType,
  QuoteStatus,
} from '@core/models/api/dsm-types';
import { UpdateQuoteRequestBody } from '@core/models/api/request/update-quote-request.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProductModel } from './product.model';
import { Nullable } from '@shared/utils/type.utils';
import { Producer } from '@entities/agency/agency.model';

export interface UpdateQuoteOptions {
  overrideQuoteStatus?: boolean;
  quoteStatus?: QuoteStatus;
}

export const updateProduct = createAction(
  '[Product] Update Product',
  props<{ payload: Update<ProductModel> }>()
);

export const upsertForAllProducts = createAction(
  '[Product] Upsert For All Products',
  props<{ payload: Partial<ProductModel> }>()
);

export const updateDocDeliveryOption = createAction(
  '[Product] Update Document Delivery Option',
  props<{ payload: Partial<ProductModel> }>()
);

export const documentDeliveryChangedDueToTelematics = createAction(
  '[Product] Document Delivery Preference Changed Due To Telematics',
  props<{
    previous: Nullable<DocumentDeliveryType>;
    docDeliveryPreference: DocumentDeliveryType;
  }>()
);

export const updateSelectedProducts = createAction(
  '[Product] Update Selected Products',
  props<{ payload: ProductType[] }>()
);

export const updateProductStatus = createAction(
  '[Product] Update Product Quote Status',
  props<{ payload: ProductType; status: QuoteStatus }>()
);

export const patchProductQuote = createAction(
  '[Product] Update Quote',
  props<{
    payload: UpdateQuoteRequestBody;
    options?: UpdateQuoteOptions;
    correlationId?: string;
  }>()
);

export const clearProductErrors = createAction('[Product] Clear Errors');

export const clearProductError = createAction(
  '[Product] Clear Error',
  props<{ payload: ProductType }>()
);

export const removeProduct = createAction(
  '[Product] Remove Product',
  props<{ payload: ProductType }>()
);

export const removeAllProductsWithErrors = createAction(
  '[Product] Remove All Products with Errors'
);

export const requiredDrivingConsentOnAddNewVehicle = createAction(
  '[Product] Upate driving data consent on add new Vehicle'
);

export const upsertYearsWithPriorCarrier = createAction(
  '[Product] Update years with prior carrier',
  props<{ payload: ProductType; yearsWithPriorCarrier: string }>()
);

export const updateProducer = createAction(
  '[Product] Update producer',
  props<{ product: ProductType; producer: Producer }>()
);

export const updateAgentName = createAction(
  '[Product] Update agent name',
  props<{ product: ProductType; agentName: string }>()
);

export const displayUnsupportedProductsModal = createAction(
  '[Product] Display unsupported products modal'
);
export const storeOnlineAccountRegistration = createAction(
  '[Product] Store Online Account Registration',
  props<{ registration: Partial<NationwideAccountRegistrationInfo> }>()
);

export const updateAccountRegistration = createAction(
  '[Product] Update account registration',
  props<{ registrationInfo: NationwideAccountRegistrationInfo }>()
);

export const deleteWarningMessageFromProduct = createAction(
  '[Product] Remove Warning Message From Product',
  props<{ message: string; productType: ProductType }>()
);

export const updateProductCreateDate = createAction(
  '[Product] Update Product Quote Create Date',
  props<{ payload: ProductType; createDate: string }>()
);
