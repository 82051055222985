import { Injectable } from '@angular/core';
import {
  PersonaName,
  PersonaRecommendation,
  PersonaRecommendationId,
  PersonaReport,
  PersonaSelectionCriteria,
  PersonaSelectionInputs,
  TwoWayRating,
} from '../store/entities/persona/persona.model';
import { ProductModel } from '@entities/product/product.model';
import { Store } from '@ngrx/store';
import {
  Observable,
  combineLatest,
  concatMap,
  filter,
  firstValueFrom,
  map,
  take,
  tap,
} from 'rxjs';
import { PersonaSelectors } from '@core/store/selectors';
import { TelematicsService } from '@core/services/telematics.service';
import { CoveragesService } from '@core/services/coverages.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { ProductsService } from '@core/services/products.service';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { LoadingService } from '@core/services/loading.service';
import { RouterService } from '@core/services/router.service';
import {
  CoverageEntity,
  CoverageSelectedValue,
} from '@core/store/entities/coverage/coverage.entity';
import { LogService } from '@core/services/log.service';
import {
  ProductType,
  quoteStatusGreaterThan,
} from '@core/models/api/dsm-types';
import { EligibleDiscountId } from '@entities/eligible-discounts/eligible-discounts.entity';
import { PersonaActions } from '@core/store/actions';
import { BillingFormService } from '@forms-store/services/billing-form.service';

@Injectable({
  providedIn: 'root',
})
export class PersonaService {
  constructor(
    private store: Store,
    private telematicsService: TelematicsService,
    private coveragesService: CoveragesService,
    private productsService: ProductsService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private loadingService: LoadingService,
    private routerService: RouterService,
    private logService: LogService,
    private billingFormService: BillingFormService
  ) {}

  getPersonaReport(): Observable<PersonaReport> {
    return this.store.select(PersonaSelectors.getPersonaReport);
  }

  setManualPersonaName(name: PersonaName | ''): void {
    this.store.dispatch(PersonaActions.setManualPersonaName({ name }));
  }

  acceptPersonaRecommendation(
    id: PersonaRecommendationId,
    coverageLimit?: string
  ): Promise<void> {
    this.productsService.getSelectedProducts().pipe(take(1)).subscribe((products)=>{
      this.logService.logBusinessEvent('accept-persona-recommendation', {
        recommendationId: id,
        coverageLimit,
        products:products.map((p)=>({type:p.type,quoteId:p.quoteId})),
      });
    });
    switch (id) {
      case 'Telematics':
        return this.acceptTelematics();
      case 'IdTheft':
        return this.acceptIdTheft();
      case 'ValuablesPlus':
        return this.acceptOrDeclineValuablesPlus();
      case 'Warranty':
        return this.acceptWarranty();
      case 'RoadsidePlus':
        return this.acceptRoadsidePlus();
      case 'SmartHome':
        return this.acceptSmartHome();
      case 'Umbrella':
        return this.acceptUmbrella();
      case 'HomeMaintenanceJourneyGuide':
        return this.acceptJourneyGuide();
      case 'ServiceLine':
        return this.acceptServiceLine();
      case 'EquipmentBreakdown':
        return this.acceptEquipmentBreakdown();
      case 'WaterBackupLimited':
        return this.acceptWaterBackUp(coverageLimit);
      case 'OEM':
        return this.acceptOem();
      case 'NewCarReplacement':
        return this.acceptNewCarReplacement();
      case 'AccidentForgiveness':
        return this.acceptAccidentForgiveness();
      case 'VanishingDeductible':
        return this.acceptVanishingDeductible();
      case 'BrandNewBelongings':
        return this.acceptBrandNewBelongings();
      case 'Paperless':
        return this.acceptPaperless();
      case 'PayInFull':
        return this.acceptPayInFull();
      default:
        return Promise.reject(`Unknown recommendation id: ${id}`);
    }
  }

  acceptAllPersonaRecommendations(
    recommendations: PersonaRecommendation[]
  ): Promise<void> {
    const unselectedRecommendations = recommendations.filter(
      (recommendation) => recommendation.available && !recommendation.present
    );
    return Promise.all(
      unselectedRecommendations.map((r) =>
        this.acceptPersonaRecommendation(r.id)
      )
    ).then(() => {});
  }

  private acceptTelematics(): Promise<void> {
    return this.telematicsService.resetEnrollmentAsPromise();
  }

  private acceptIdTheft(): Promise<any> {
    return firstValueFrom(
      combineLatest([
        this.productsService.getSelectedProducts(),
        this.coveragesService.getAllCoverages(),
      ])
    ).then(([products, coverages]) => {
      const propertyProduct = products.find(
        (p) =>
          p.type === 'Homeowner' ||
          p.type === 'Tenant' ||
          p.type === 'Condominium'
      );
      if (
        propertyProduct &&
        coverages.find(
          (c) =>
            c.productId === propertyProduct.type &&
            c.coverageId === 'IdentityTheft' &&
            c.available
        )
      ) {
        return this.acceptPropertyCoverage(
          'IdentityTheft',
          'IdentityTheftLimit'
        );
      } else if (
        products.find((p) => p.type === 'PersonalAuto') &&
        coverages.find(
          (c) =>
            c.productId === 'PersonalAuto' &&
            c.coverageId === 'IDFR' &&
            c.available
        )
      ) {
        return this.acceptCoverage('PersonalAuto', 'IDFR', 'selected');
      }
      return null;
    });
  }

  private acceptOrDeclineValuablesPlus(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.productsService
        .getSelectedProductTypes()
        .pipe(take(1))
        .subscribe((productTypes) => {
          const product =
            productTypes.find(
              (t) => t === 'Homeowner' || t === 'Tenant' || t === 'Condominium'
            ) || 'Homeowner';
          this.routerService.go({
            path: ['quote', 'coverages'],
            query: { product, coverageGroup: 'scheduledProperty' },
          });
          resolve();
        });
    });
  }

  private acceptWarranty(): Promise<void> {
    return Promise.reject('TODO: acceptWarranty');
  }

  private acceptRoadsidePlus(): Promise<void> {
    return this.acceptCoverage('PersonalAuto', 'RSA', 'RSAOption', '1');
  }

  private acceptSmartHome(): Promise<any> {
    return this.acceptPropertyPolicyLineModifier(
      'SmartDevice',
      'Participating'
    );
  }

  private acceptUmbrella(): Promise<void> {
    const endLoading = this.loadingService.beginLoading(
      'add-umbrella',
      'Adding product...'
    );
    this.productsService.selectProduct('PersonalUmbrella', true);
    return this.productsService
      .updateProductsAtHub()
      .then(() => {
        this.routerService.go({ path: ['quote', 'umbrella'] });
        endLoading();
      })
      .catch((error) => {
        endLoading();
        throw error;
      });
  }

  private acceptJourneyGuide(): Promise<void> {
    return Promise.reject('TODO: acceptJourneyGuide');
  }

  private acceptServiceLine(): Promise<any> {
    return this.acceptPropertyCoverage('ServiceLine', 'ServiceLineLimit');
  }

  private acceptEquipmentBreakdown(): Promise<any> {
    return this.acceptPropertyCoverage(
      'EquipmentBreakdown',
      'selected',
      'true'
    );
  }

  private acceptWaterBackUp(optionValue?: string | undefined): Promise<any> {
    return this.acceptPropertyCoverage(
      'WaterBackupLimited',
      'WaterBackupLimitedLimit',
      optionValue
    );
  }

  private acceptOem(): Promise<any> {
    return this.acceptCoverage('PersonalAuto', 'OEM', 'selected');
  }

  private acceptNewCarReplacement(): Promise<any> {
    return this.acceptCoverage('PersonalAuto', 'NCRPCOLL', 'selected');
  }

  private acceptAccidentForgiveness(): Promise<any> {
    return this.acceptCoverage('PersonalAuto', 'AF', 'selected');
  }

  private acceptVanishingDeductible(): Promise<any> {
    return this.acceptCoverage('PersonalAuto', 'VD', 'selected');
  }

  private acceptBrandNewBelongings(): Promise<any> {
    return this.acceptPropertyCoverage('BrandNewBelongings', 'selected');
  }

  private acceptPaperless(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.productsService
        .getSelectedProducts()
        .pipe(take(1))
        .subscribe((products) => {
          const calls: Promise<any>[] = [];
          for (const product of products) {
            if (product.docDelPreference !== 'OnlineAccountAccess') {
              calls.push(
                this.productsService.updateQuoteAndAwaitResponse({
                  productType: product.type,
                  docDelPreference: 'OnlineAccountAccess',
                })
              );
            }
          }
          Promise.all(calls).then(resolve).catch(reject);
        });
    });
  }

  private acceptPayInFull(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.billingFormService
        .getBillingPlansForm()
        .pipe(take(1))
        .subscribe({
          next: (form) => {
            form = { ...form, billingPlan: 'PAY IN FULL' };
            this.billingFormService
              .orchestrateBillingPlanChangeWithSpinner(form, false)
              .subscribe({
                next: () => {},
                error: reject,
                complete: () => resolve(null),
              });
          },
          error: reject,
        });
    });
  }

  private acceptPropertyCoverage(
    coverageId: string,
    optionCode: string,
    optionValue?: string
  ): Promise<any> {
    return this.getPropertyProduct().then((product) =>
      this.acceptCoverage(product.type, coverageId, optionCode, optionValue)
    );
  }

  private acceptCoverage(
    productType: ProductType,
    coverageId: string,
    optionCode: string,
    optionValue?: string
  ): Promise<any> {
    return firstValueFrom(
      this.coveragesService.getQuoteCoverages(productType).pipe(
        take(1),
        map((coverages) =>
          coverages?.filter(
            (c) => c.productId === productType && c.coverageId === coverageId
          )
        )
      )
    ).then((coverages) => {
      if (!coverages?.length) {
        return Promise.reject('Coverage not available');
      }
      return Promise.all(
        coverages.map((coverage) =>
          this.acceptOneCoverage(coverage, optionCode, optionValue)
        )
      );
    });
  }

  private acceptOneCoverage(
    coverage: CoverageEntity,
    optionCode: string,
    optionValue?: string
  ): Promise<any> {
    if (!optionValue) {
      if (
        coverage.selectedValue?.find(
          (v) => v.code !== 'selected' || v.value !== 'false'
        )
      ) {
        return Promise.resolve();
      }
    }
    if (
      coverage.selectedValue?.find(
        (v) => v.code === optionCode && v.value === optionValue
      )
    ) {
      return Promise.resolve();
    }
    let selectedValue: CoverageSelectedValue[];
    if (optionValue) {
      selectedValue = [{ code: optionCode, value: optionValue }];
    } else if (coverage.terms) {
      selectedValue = coverage.terms
        .filter((term) => term.options)
        .map((term) => ({
          code: term.code,
          value: term.options[0]?.value || '',
        }));
    } else {
      selectedValue = [{ code: 'selected', value: 'true' }];
    }
    return this.coveragesService.dispatchCoverageUpdateAsPromise([
      {
        productId: coverage.productId,
        coverageId: coverage.coverageId,
        coverageLevel: coverage.coverageLevel,
        coverableId: coverage.coverableId ? +coverage!.coverableId : undefined,
        selectedValue,
      },
    ]);
  }

  private acceptPropertyPolicyLineModifier(
    eligibleDiscountId: EligibleDiscountId,
    selectedOptionValue: string
  ): Promise<any> {
    return this.getPropertyProduct().then((product) =>
      this.acceptPolicyLineModifier(
        product.type,
        eligibleDiscountId,
        selectedOptionValue
      )
    );
  }

  private acceptPolicyLineModifier(
    productType: ProductType,
    eligibleDiscountId: EligibleDiscountId,
    selectedOptionValue: string
  ): Promise<any> {
    return firstValueFrom(
      this.eligibleDiscountsService.getPolicyLineModifier(
        eligibleDiscountId,
        productType
      )
    ).then((discount) => {
      if (!discount) {
        throw 'Discount not available';
      }
      if (discount.selectedOptionValue === selectedOptionValue) {
        return;
      }
      return this.eligibleDiscountsService.dispatchUpdatePolicyLineAsPromise({
        ...discount,
        selectedOptionValue,
      });
    });
  }

  declinePersonaRecommendation(
    id: PersonaRecommendationId,
    coverageLimit?: string
  ): Promise<void> {
    this.productsService.getSelectedProducts().pipe(take(1)).subscribe((products)=>{
      this.logService.logBusinessEvent('decline-persona-recommendation', {
        recommendationId: id,
        coverageLimit,
        products:products.map((p)=>({type:p.type,quoteId:p.quoteId})),
      });
    });
    switch (id) {
      case 'Telematics':
        return this.removeTelematics();
      case 'IdTheft':
        return this.declineIdTheft();
      case 'ValuablesPlus':
        return this.acceptOrDeclineValuablesPlus();
      case 'Warranty':
        return this.declineWarranty();
      case 'RoadsidePlus':
        return this.declineRoadsidePlus();
      case 'SmartHome':
        return this.declineSmartHome();
      case 'Umbrella':
        return this.declineUmbrella();
      case 'HomeMaintenanceJourneyGuide':
        return this.declineJourneyGuide();
      case 'ServiceLine':
        return this.declineServiceLine();
      case 'EquipmentBreakdown':
        return this.declineEquipmentBreakdown();
      case 'WaterBackupLimited':
        return this.declineWaterBackUp(coverageLimit);
      case 'OEM':
        return this.declineOem();
      case 'NewCarReplacement':
        return this.declineNewCarReplacement();
      case 'AccidentForgiveness':
        return this.declineAccidentForgiveness();
      case 'VanishingDeductible':
        return this.declineVanishingDeductible();
      case 'BrandNewBelongings':
        return this.declineBrandNewBelongings();
      case 'Paperless':
        return this.declinePaperless();
      case 'PayInFull':
        return this.declinePayInFull();
      default:
        return Promise.reject(`Unknown recommendation id: ${id}`);
    }
  }

  private removeTelematics(): Promise<void> {
    this.telematicsService.dispatchRemoveEnrollment();
    return Promise.resolve();
  }

  private declineIdTheft(): Promise<any> {
    return Promise.all([
      this.declineIdTheftByProductType('PersonalAuto', 'IDFR'),
      this.declineIdTheftByProductType('Homeowner', 'IdentityTheft'),
    ])
      .then(() => Promise.resolve())
      .catch(() => Promise.reject('Id Theft not removed'));
  }

  private declineIdTheftByProductType(
    productId: ProductType,
    coverageId: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.coveragesService
        .getQuoteCoverages(productId)
        .pipe(take(1))
        .subscribe((coverages) => {
          const coverage = coverages?.find((c) => c.coverageId === coverageId);
          if (!coverage) {
            resolve();
            return;
          }
          this.coveragesService
            .dispatchCoverageUpdateAsPromise([
              {
                productId,
                coverageId,
                coverageLevel: coverage.coverageLevel,
                coverableId: coverage.coverableId
                  ? +coverage.coverableId
                  : undefined,
                selectedValue: [{ code: 'selected', value: 'false' }],
              },
            ])
            .then(resolve)
            .catch(reject);
        });
    });
  }

  private declineWarranty(): Promise<void> {
    return Promise.reject('TODO: declineWarranty');
  }

  private declineRoadsidePlus(): Promise<void> {
    return this.declineCoverage('PersonalAuto', 'RSA');
  }

  private declineSmartHome(): Promise<any> {
    return this.acceptPropertyPolicyLineModifier('SmartDevice', 'Declined');
  }

  private declineUmbrella(): Promise<void> {
    this.productsService.selectProduct('PersonalUmbrella', false);
    return this.productsService.updateProductsAtHub();
  }

  private declineJourneyGuide(): Promise<void> {
    return Promise.reject('TODO: declineJourneyGuide');
  }

  private declineServiceLine(): Promise<any> {
    return this.declinePropertyCoverage('ServiceLine');
  }

  private declineEquipmentBreakdown(): Promise<any> {
    return this.declinePropertyCoverage('EquipmentBreakdown');
  }

  private declineWaterBackUp(optionValue?: string | undefined): Promise<any> {
    return this.declinePropertyCoverage('WaterBackupLimited');
  }

  private declineOem(): Promise<any> {
    return this.declineCoverage('PersonalAuto', 'OEM');
  }

  private declineNewCarReplacement(): Promise<any> {
    return this.declineCoverage('PersonalAuto', 'NCRPCOLL');
  }

  private declineAccidentForgiveness(): Promise<any> {
    return this.declineCoverage('PersonalAuto', 'AF');
  }

  private declineVanishingDeductible(): Promise<any> {
    return this.declineCoverage('PersonalAuto', 'VD');
  }

  private declineBrandNewBelongings(): Promise<any> {
    return this.declinePropertyCoverage('BrandNewBelongings');
  }

  private declinePaperless(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.productsService
        .getSelectedProducts()
        .pipe(take(1))
        .subscribe((products) => {
          const calls: Promise<any>[] = [];
          for (const product of products) {
            if (product.docDelPreference !== 'USMail') {
              calls.push(
                this.productsService.updateQuoteAndAwaitResponse({
                  productType: product.type,
                  docDelPreference: 'USMail',
                })
              );
            }
          }
          Promise.all(calls).then(resolve).catch(reject);
        });
    });
  }

  private declinePayInFull(): Promise<any> {
    this.billingFormService.updateBillingPlansForm({
      // sommea1: Unclear what we ought to set it to. I figure RBC is the likeliest, for no particular reason.
      billingPlan: 'MONTHLY RECURRING BANKCARD',
    });
    return Promise.resolve();
  }

  private declinePropertyCoverage(coverageId: string): Promise<any> {
    return this.getPropertyProduct()
      .then((product) => {
        return firstValueFrom(
          this.coveragesService.getQuoteCoverages(product.type).pipe(
            take(1),
            map((coverages) =>
              coverages?.find((c) => c.coverageId === coverageId)
            )
          )
        );
      })
      .then((coverage) => {
        if (!coverage) {
          return;
        }
        let selectedValue: CoverageSelectedValue[] = [
          { code: 'selected', value: 'false' },
        ];
        return this.coveragesService.dispatchCoverageUpdateAsPromise([
          {
            productId: coverage.productId,
            coverageId: coverageId,
            coverageLevel: coverage.coverageLevel,
            coverableId: coverage.coverableId
              ? +coverage.coverableId
              : undefined,
            selectedValue,
          },
        ]);
      });
  }

  private declineCoverage(
    productType: ProductType,
    coverageId: string
  ): Promise<any> {
    return firstValueFrom(
      this.coveragesService.getQuoteCoverages(productType).pipe(
        take(1),
        map(
          (coverages) =>
            coverages?.filter(
              (c) => c.productId === productType && c.coverageId === coverageId
            ) || []
        )
      )
    ).then((coverages) => {
      return Promise.all(
        coverages.map((coverage) => this.declineOneCoverage(coverage))
      );
    });
  }

  private declineOneCoverage(coverage: CoverageEntity): Promise<any> {
    if (
      coverage.selectedValue?.find(
        (v) => v.code === 'selected' && v.value === 'false'
      )
    ) {
      return Promise.resolve();
    }
    return this.coveragesService.dispatchCoverageUpdateAsPromise([
      {
        productId: coverage.productId,
        coverageId: coverage.coverageId,
        coverageLevel: coverage.coverageLevel,
        coverableId: coverage.coverableId ? +coverage.coverableId : undefined,
        selectedValue: [{ code: 'selected', value: 'false' }],
      },
    ]);
  }

  private getPropertyProduct(): Promise<ProductModel> {
    return firstValueFrom(
      this.productsService.getSelectedProducts().pipe(
        take(1),
        map((products) =>
          products.find(
            (p) =>
              p.type === 'Homeowner' ||
              p.type === 'Condominium' ||
              p.type === 'Tenant'
          )
        ),
        filterOutNull()
      )
    );
  }

  /**
   * Reformats content from the store, for delivery to this.choosePersona.
   * Our concern is formatting and maybe thresholds. No decision making.
   */
  digestPersonaSelectionInputs(
    inputs: PersonaSelectionInputs
  ): PersonaSelectionCriteria {
    const criteria: PersonaSelectionCriteria = {
      autoRated: false,
      propertyRated: false,
      propertyProduct: '',
      autoVehicleCount: 0,
      creditRating: TwoWayRating.Unknown,
      autoPriorTerms: 0,
      propertyPriorTerms: 0,
      driverRating: TwoWayRating.Unknown,
      highRiskTerritory: false,
      homeSquareFootage: 0,
      homeStories: 0,
      propertyAoi: 0,
      youngestDriverAge: 0,
      oldestDriverAge: 0,
      pniAge: 0,
      autoBiLimit: '',
      telematicsEnrolled: false,
      previousPersona: 'None',
      selectedProducts: [],
    };

    const { auto, property } = this.getPersonaSelectionProducts(
      inputs.products
    );
    if (auto) {
      criteria.selectedProducts.push('Auto');
      criteria.autoRated = quoteStatusGreaterThan(
        auto.quoteStatus || 'Draft',
        'Draft'
      );
      criteria.autoVehicleCount = inputs.vehicles.length;
      criteria.autoPriorTerms =
        inputs.currentCarriers.PersonalAuto?.currentCarrierTerms || 0;
      criteria.telematicsEnrolled = !!inputs.telematics?.enrollmentId;

      const driverAges = inputs.drivers
        .map((d) => this.ageFromDateOfBirth(d.person?.dateOfBirth || ''))
        .filter((age) => !!age)
        .sort((a, b) => a - b);
      if (driverAges.length) {
        criteria.youngestDriverAge = driverAges[0];
        criteria.oldestDriverAge = driverAges[driverAges.length - 1];
        criteria.pniAge = this.ageFromDateOfBirth(
          inputs.drivers.find((d) =>
            d.policyRoles?.find(
              (r) =>
                r.productType === 'PersonalAuto' &&
                r.entityType === 'policyHolder' &&
                r.roleSequence === 'primary'
            )
          )?.person?.dateOfBirth || ''
        );
      }

      const bi = inputs.coverages.find(
        (c) => c.productId === 'PersonalAuto' && c.coverageId === 'BI'
      );
      criteria.autoBiLimit =
        bi?.selectedValue?.find((v) => v.code === 'BILimit')?.value || '';

      criteria.driverRating = TwoWayRating.Unknown; // TODO
    }

    if (property) {
      criteria.selectedProducts.push('Property');
      criteria.propertyRated = quoteStatusGreaterThan(
        property.quoteStatus || 'Draft',
        'Draft'
      );
      criteria.propertyProduct = property.type;
      // Checking pro forma, but we don't ever ask about the property current carrier:
      criteria.propertyPriorTerms =
        inputs.currentCarriers[property.type]?.currentCarrierTerms || 0;

      const cl = inputs.coveredLocations[property.type];
      if (cl) {
        criteria.homeSquareFootage = cl.constructionInfo?.squareFootage || 0;
        criteria.homeStories =
          +(cl.constructionInfo?.numberOfStories || '') || 0;
      }

      // TODO Reqs say "AOI" ("Amount Of Insurance"). I'm assuming that means BasicCoverageLimit. Confirm with somebody.
      const basicCoverage = inputs.coverages.find(
        (c) => c.productId === property.type && c.coverageId === 'BasicCoverage'
      );
      const basicLimit = basicCoverage?.selectedValue?.find(
        (v) => v.code === 'BasicCoverageLimit'
      );
      if (basicLimit) {
        criteria.propertyAoi = +basicLimit.value || 0;
      }

      criteria.highRiskTerritory = false; // TODO
    }

    criteria.previousPersona = inputs.personaName;

    return criteria;
  }

  private getPersonaSelectionProducts(products: ProductModel[]): {
    auto: ProductModel | null;
    property: ProductModel | null;
  } {
    const result: {
      auto: ProductModel | null;
      property: ProductModel | null;
    } = { auto: null, property: null };
    for (const product of products) {
      switch (product.type) {
        case 'PersonalAuto':
          result.auto = product;
          break;
        case 'Homeowner':
        case 'Condominium':
        case 'Tenant':
          result.property = product;
          break;
      }
    }
    return result;
  }

  private ageFromDateOfBirth(dob: string): number {
    try {
      const [year, month, day] = dob.split('-').map((v) => +v);
      const now = new Date();
      let years = now.getFullYear() - year;
      let months = 1 + now.getMonth() - month;
      let days = now.getDate() - day;
      if (days < 0) months--;
      if (months < 0) years--;
      if (years < 0) return 0;
      return years;
    } catch (e) {
      return 0;
    }
  }

  /**
   * Given digested criteria, apply the business rules to decide which persona this fits.
   * "None" is a perfectly valid answer.
   */
  choosePersona(criteria: PersonaSelectionCriteria): PersonaName {
    // Used to be 'None', but now we want something selected always.
    const DEFAULT_PERSONA = 'Other';

    if (!criteria.autoRated || !criteria.propertyRated) {
      if (
        criteria.selectedProducts.includes('Auto') &&
        criteria.selectedProducts.includes('Property') &&
        criteria.previousPersona !== DEFAULT_PERSONA
      ) {
        return criteria.previousPersona;
      } else {
        return DEFAULT_PERSONA;
      }
    }
    if (criteria.highRiskTerritory) {
      return DEFAULT_PERSONA;
    }
    if (criteria.driverRating < 0) {
      // Only DoItYourselfer allows adverse driver rating.
      // TODO Spec says "Auto with Telematics/Home/Renters":
      // 1. Does that mean "Telematics OR Home OR Renters"? Assuming "Telematics AND (Home OR Renters)".
      // 2. Should we permit Condo too? Assuming yes.
      // "Brokerage for business not aligned to appetite", what does that mean?
      if (!criteria.telematicsEnrolled) {
        return DEFAULT_PERSONA;
      }
      if (this.biLimitSufficientForDiyPersona(criteria.autoBiLimit)) {
        return 'DoItYourselfer';
      }
      return DEFAULT_PERSONA;
    }
    if (
      criteria.propertyProduct === 'Homeowner' &&
      criteria.autoVehicleCount >= 2 &&
      this.homeSizeSufficientForEnPersona(
        criteria.homeSquareFootage,
        criteria.homeStories
      ) &&
      criteria.propertyAoi &&
      criteria.propertyAoi >= 400000 &&
      criteria.propertyAoi <= 750000 &&
      criteria.youngestDriverAge >= 50
    ) {
      return 'EmptyNester';
    }
    if (
      criteria.propertyProduct === 'Homeowner' &&
      criteria.telematicsEnrolled &&
      criteria.autoVehicleCount >= 3 &&
      criteria.youngestDriverAge < 20
    ) {
      //TODO Spec: "Home/Auto with Telematics, Auto only some territories". Which territories?
      // (and how will we know, assuming it's a rating territory code)
      return 'TeenDriver';
    }
    if (
      criteria.propertyProduct === 'Homeowner' &&
      this.homeSizeSufficientForFthbPersona(
        criteria.homeSquareFootage,
        criteria.homeStories
      ) &&
      criteria.autoVehicleCount >= 2 &&
      criteria.propertyAoi &&
      criteria.propertyAoi < 400000
    ) {
      return 'FirstTimeHomeBuyer';
    }
    if (
      criteria.propertyProduct === 'Tenant' &&
      criteria.autoVehicleCount >= 2 &&
      criteria.creditRating >= 0 &&
      criteria.autoPriorTerms >= 2
    ) {
      //TODO "Several terms with prior carriers", how many is several?
      //TODO Allowing "Unknown" credit rating, otherwise it will never pass, confirm that's OK.
      return 'UpcomingProfessional';
    }
    return DEFAULT_PERSONA;
  }

  private biLimitSufficientForDiyPersona(biLimit: string): boolean {
    try {
      const [perPerson, perIncident] = biLimit.split('/').map((v) => +v);
      if (isNaN(perPerson) || isNaN(perIncident)) {
        return false;
      }
      // TODO What constitutes a "High BI limit"?
      if (perPerson < 100000 || perIncident < 300000) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  private homeSizeSufficientForEnPersona(
    squareFootage: number,
    stories: number
  ): boolean {
    if (!squareFootage || !stories) {
      return false;
    }
    if (stories > 1.5) {
      return false;
    }
    return true;
  }

  private homeSizeSufficientForFthbPersona(
    squareFootage: number,
    stories: number
  ): boolean {
    if (!squareFootage || !stories) {
      return false;
    }
    // TODO Spec only says "Smaller home". Get exact rules from requirements.
    return true;
  }
}
