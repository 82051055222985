import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreState } from '../store/reducers';
import { CoveragesRequest } from '../models/api/request/coverages-request.model';
import {
  CoverageChange,
  CoverageEntity,
  CoverageInformationalMessage,
  CoverageWarning,
} from '../store/entities/coverage/coverage.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { Nullable } from '@shared/utils/type.utils';
import { filter, map, take, tap } from 'rxjs/operators';
import { ProductsService } from './products.service';
import { CoverageMinimumsForUmbrella } from '@shared/constants/app-constants';
import { CoverageHelper } from '../helper/coverage.helper';
import {
  CoverageSelectors,
  CoverageMessageSelectors,
} from '@core/store/selectors';
import { CoverageActions } from '@core/store/actions';
import { ErrorModel } from '@entities/error/error.model';
import { Actions, ofType } from '@ngrx/effects';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class CoveragesService {
  private activeTab$: BehaviorSubject<string>;

  constructor(
    private store: Store<CoreState>,
    private productService: ProductsService,
    private coverageHelper: CoverageHelper,
    private actions$: Actions
  ) {
    this.activeTab$ = new BehaviorSubject<string>('liability');
  }

  setActiveTab(tabName: string) {
    this.activeTab$.next(tabName);
  }

  getActiveTab(): Observable<string> {
    return this.activeTab$;
  }

  dispatchLoadQuoteCoverages(request: CoveragesRequest): void {
    this.store.dispatch(CoverageActions.getAllCoverages({ payload: request }));
  }

  dispatchLoadAllQuoteCoveragesForSelectedProducts(): void {
    this.store.dispatch(CoverageActions.loadAllCoverages());
  }

  dispatchCoverageUpdate(coverageChanges: CoverageChange[]): void {
    this.store.dispatch(
      CoverageActions.updateCoverage({
        payload: coverageChanges,
      })
    );
  }

  dispatchCoverageUpdateAsPromise(
    coverageChanges: CoverageChange[]
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.actions$
        .pipe(
          ofType(
            CoverageActions.updateCoverageSuccess,
            CoverageActions.updateCoverageError
          ),
          filter((action) => action.updatedCoverage === coverageChanges),
          take(1)
        )
        .subscribe((action) => {
          if (action.type === CoverageActions.updateCoverageSuccess.type) {
            resolve();
          } else {
            reject(action.error);
          }
        });
      this.store.dispatch(
        CoverageActions.updateCoverage({
          payload: coverageChanges,
        })
      );
    });
  }

  getAllQuoteCoveragesByVersion(
    version?: string
  ): Observable<CoverageEntity[]> {
    return this.store.select(
      CoverageSelectors.getAllCoveragesByVersion(version)
    );
  }

  getQuoteCoverages(
    productType: ProductType,
    version?: string
  ): Observable<Nullable<CoverageEntity[]>> {
    return this.store.select(
      CoverageSelectors.getCoveragesFor(productType, version)
    );
  }

  getAllCoverages(): Observable<CoverageEntity[]> {
    return this.store.select(CoverageSelectors.getAllCoverages);
  }

  isQuoteCoverageLoading(): Observable<boolean> {
    return this.store.select(CoverageSelectors.getQuoteCoveragesLoading);
  }

  isQuoteCoverageLoaded(): Observable<boolean> {
    return this.store.select(CoverageSelectors.getQuoteCoveragesLoaded);
  }

  getQuoteCoveragesError(): Observable<Nullable<ErrorModel>> {
    return this.store.select(CoverageSelectors.getQuoteCoveragesError);
  }

  getPageLevelCoverageMessages(
    productId: ProductType
  ): Observable<Nullable<CoverageInformationalMessage>> {
    return this.store.select(
      CoverageMessageSelectors.getPageLevelCoverageMessagesByProduct(productId)
    );
  }

  getCoverageLevelMessages(
    productId: ProductType
  ): Observable<Nullable<CoverageWarning[]>> {
    return this.store.select(
      CoverageMessageSelectors.getCoverageLevelMessagesByProduct(productId)
    );
  }

  updateUmbrellaCoverages(): void {
    this.productService
      .getSelectedProductTypes()
      .pipe(take(1))
      .subscribe((products) =>
        products.forEach((productId) => {
          this.getQuoteCoverages(productId)
            .pipe(take(1))
            .subscribe((coverages) => {
              // I think this belongs in a selector. Not sure where though. -CK
              const coverageChanges: CoverageChange[] = [];
              CoverageMinimumsForUmbrella.forEach((minCov) => {
                const coverage = coverages?.find(
                  (c) =>
                    c.coverageId === minCov.coverageId &&
                    minCov.productTypes.includes(productId)
                );
                if (coverage && coverage.selectedValue?.[0]) {
                  const currentValue =
                    +coverage.selectedValue[0].value.split('/')[0];
                  if (
                    currentValue >= minCov.limit ||
                    +coverage.selectedValue[0].value >= minCov.limit
                  ) {
                    return;
                  }

                  const termCode = coverage.selectedValue[0].code;
                  const options = coverage.terms.find(
                    (term) => term.code === termCode
                  );
                  const newOption = options?.options.find((option) => {
                    const value = option.value.split('/')[0];

                    return +value >= minCov.limit;
                  });

                  if (newOption) {
                    coverageChanges.push(
                      this.coverageHelper.createCoverageChange({
                        coverage,
                        code: termCode,
                        value: newOption.value,
                        coverableId: parseInt(coverage.coverableId || '', 10),
                      })
                    );
                  }
                }
              });
              if (coverageChanges.length) {
                this.dispatchCoverageUpdate(coverageChanges);
              }
            });
        })
      );
  }

  changeTab(tabName: string): void {}

  getQuoteIdForCoverage(coverage: CoverageEntity): Observable<string> {
    return this.productService
      .getProduct(coverage.productId)
      .pipe(map((product) => product?.quoteId || ''));
  }

  /* Dispatch an update to make all of toVehicles like fromVehicle.
   * Caller supplies the list of applicable coverages, can be the entire store.
   * It's fine if fromVehicle is listed among toVehicles.
   * Returns true if a change was dispatched, or false if they're already equivalent.
   */
  copyVehicleCoverages(
    toVehicles: VehicleEntity[],
    fromVehicle: VehicleEntity,
    coverages: CoverageEntity[]
  ): boolean {
    const changes: CoverageChange[] = [];
    for (const toVehicle of toVehicles) {
      if (toVehicle !== fromVehicle) {
        this.generateCoverageChangesForVehicles(
          changes,
          toVehicle,
          fromVehicle,
          coverages
        );
      }
    }
    if (!changes.length) {
      return false;
    }
    this.dispatchCoverageUpdate(changes);
    return true;
  }

  private generateCoverageChangesForVehicles(
    changes: CoverageChange[],
    toVehicle: VehicleEntity,
    fromVehicle: VehicleEntity,
    coverages: CoverageEntity[]
  ): void {
    const fromCoverages: CoverageEntity[] = [];
    const toCoverages: CoverageEntity[] = [];
    for (const coverage of coverages) {
      if (coverage.coverageLevel === 'Vehicle') {
        if (coverage.coverableId === fromVehicle.vehicleId) {
          fromCoverages.push(coverage);
        } else if (coverage.coverableId === toVehicle.vehicleId) {
          toCoverages.push(coverage);
        }
      }
    }
    for (const fromCoverage of fromCoverages) {
      const toCoverage = toCoverages.find(
        (c) => c.coverageId === fromCoverage.coverageId
      );
      if (!toCoverage) {
        continue;
      }
      this.generateCoverageChangesForCoverages(
        changes,
        toCoverage,
        fromCoverage
      );
    }
  }

  private generateCoverageChangesForCoverages(
    changes: CoverageChange[],
    toCoverage: CoverageEntity,
    fromCoverage: CoverageEntity
  ): void {
    if (!fromCoverage.selectedValue || !toCoverage.selectedValue) {
      return;
    }
    if (!fromCoverage.available || !toCoverage.available) {
      return;
    }

    // "selected:false" is its own thing, must be handled different from general value changes.
    const toUnselected = toCoverage.selectedValue.find(
      (v) => v.code === 'selected' && v.value === 'false'
    );
    const fromUnselected = fromCoverage.selectedValue.find(
      (v) => v.code === 'selected' && v.value === 'false'
    );
    if (toUnselected && fromUnselected) {
      return;
    }
    if (toUnselected) {
      this.generateCoverageChangeIfValuesMatch(
        changes,
        toCoverage,
        fromCoverage
      );
      return;
    }
    if (fromUnselected) {
      changes.push({
        coverageId: toCoverage.coverageId,
        productId: toCoverage.productId,
        coverageLevel: toCoverage.coverageLevel,
        coverableId: toCoverage.coverableId as any,
        selectedValue: [{ code: 'selected', value: 'false' }],
      });
      return;
    }

    // The general case, when both to and from are selected.
    for (const fromValue of fromCoverage.selectedValue) {
      const toValue = toCoverage.selectedValue.find(
        (v) => v.code === fromValue.code
      );
      if (!toValue) {
        continue;
      }
      if (fromValue.value === toValue.value) {
        continue;
      }
      // If one value is different, copy them all.
      this.generateCoverageChangeIfValuesMatch(
        changes,
        toCoverage,
        fromCoverage
      );
      break;
    }
  }

  /* Given two coverages that are both selected, generate a change for (toCoverage)
   * using values from (fromCoverage), if they are valid for (toCoverage).
   */
  private generateCoverageChangeIfValuesMatch(
    changes: CoverageChange[],
    toCoverage: CoverageEntity,
    fromCoverage: CoverageEntity
  ): void {
    const change: CoverageChange = {
      coverageId: toCoverage.coverageId,
      productId: toCoverage.productId,
      coverageLevel: toCoverage.coverageLevel,
      coverableId: toCoverage.coverableId as any,
      selectedValue: [],
    };
    // Some coverages have no terms, just "selected:true".
    if (fromCoverage.selectedValue.length === 1) {
      const fromValue = fromCoverage.selectedValue[0];
      if (fromValue.code === 'selected' && fromValue.value === 'true') {
        change.selectedValue.push({ ...fromValue });
        changes.push(change);
        return;
      }
    }
    // Generic enumerated or freeform terms:
    for (const fromValue of fromCoverage.selectedValue) {
      if (
        toCoverage?.selectedValue?.find(
          (toValue) =>
            toValue.code === fromValue.code && toValue.value === fromValue.value
        )
      ) {
        continue;
      }
      const toTerm = toCoverage.terms?.find((t) => t.code === fromValue.code);
      if (toTerm?.options?.find((o) => o.value === fromValue.value)) {
        // Enumerated and available.
        change.selectedValue.push({ ...fromValue });
      } else if (toTerm && !toTerm.options?.length) {
        const fromTerm = fromCoverage.terms?.find(
          (t) => t.code === fromValue.code
        );
        if (fromTerm && !fromTerm.options?.length) {
          // Freeform and available.
          change.selectedValue.push({ ...fromValue });
        }
      }
    }
    if (change.selectedValue.length) {
      changes.push(change);
    }
  }
}
