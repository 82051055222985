import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { UwActivitiesStoreModel } from './uw-activities.reducer';

export const getUwActivitiesState = createSelector(
  fromCore.getCoreState,
  (core) => core.uwActivities
);

export const getUwActivitiesForDisplay = createSelector(
  getUwActivitiesState,
  (state) => {
    return Object.values(state.entities) as UwActivitiesStoreModel[];
  }
);
