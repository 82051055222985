import { Injectable } from '@angular/core';
import { TasksModalContainerComponent } from '@app/hub/containers/tasks-modal-container/tasks-modal-container.component';
import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { ProductType } from '@core/models/api/dsm-types';
import { QuoteRetrieveResponse } from '@core/models/api/response/retrieve-response.model';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { LoadingService } from '@core/services/loading.service';
import { MetadataService } from '@core/services/metadata.service';
import { ProductsService } from '@core/services/products.service';
import { SessionService } from '@core/services/session.service';
import {
  CoverageActions,
  DriverVehiclePrefillActions,
  ProductActions,
  RetrieveActions,
  TelematicsActions,
} from '@core/store/actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductUtils } from '@shared/utils/product.util';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { QuoteRetrieveAdapter } from '../../adapters/quote-retrieve.adapter';
import { NavigationActions } from '../actions';
import { updateAgency } from '../entities/agency/agency.action';
import { getDriverVehicleAssignment } from '../entities/driver-vehicle-assignment/driver-vehicle-assignment.actions';
import { navigatePostRetrieveComplete } from '../entities/navigation/navigation.action';
import { replacePreBindDocumentsByProductType } from '../entities/pre-bind-documents/pre-bind-documents.action';
import { updateTasks } from '../entities/task/task.action';
import { excludeQuoteFromTelematics } from '../entities/telematics/telematics.action';
import { getUWReports } from '../entities/uw-reports/uw-reports.action';
import { AgencySelectors, TasksModalSelectors } from '../selectors';
import {
  allRetrievesComplete,
  hydrateQuote,
  hydrateQuoteError,
  hydrateQuoteSuccess,
  retrieveQuoteError,
  retrieveQuoteSuccess,
  retrieveQuotes,
} from './retrieve.action';
import { getRetrievePendingCallCount } from './retrieve.selector';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { ScheduledCategoryActions } from '@core/store/actions/index';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable()
export class RetrieveEffects {
  constructor(
    private actions$: Actions,
    private retrieveAdapter: QuoteRetrieveAdapter,
    private loadingService: LoadingService,
    private errorSanitizerService: ErrorSanitizerService,
    private featureFlagService: FeatureFlagService,
    private metadataService: MetadataService,
    private store: Store,
    private ngbModal: NgbModal,
    private productsService: ProductsService,
    private sessionService: SessionService
  ) {}

  retrieveQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveQuotes),
      map((a) => a.payload.quotes),
      // switchMap to create a closure to hold catchError
      switchMap((_) =>
        of(_).pipe(
          mergeMap((quotes) => {
            const responses: any[] = [];
            for (const { productType, quoteId, createDate } of quotes) {
              this.productsService.updateProductCreateDate(
                productType,
                createDate ||
                  DateUtils.formatDsmDateToOld(
                    DateUtils.getCurrentDateAsString()
                  )
              );
              responses.push(
                this.retrieveAdapter.retrieve(quoteId, productType)
              );
            }
            this.loadingService.beginLoading(
              'retrievequote',
              'Found it. Loading details'
            );
            return forkJoin(responses as Observable<any>[]);
          }),
          switchMap((responses) => {
            const state = responses.find(
              (resp) => !!resp.response?.policyAddress?.state
            )?.response.policyAddress.state;
            if (state) {
              this.sessionService.updateQuoteState(state);
            }
            return responses;
          }),
          toArray(),
          withLatestFrom(
            this.metadataService.getStateSpecificFlagsData(),
            this.productsService.getAllProducts()
          ),
          mergeMap(([responses, stateSpecificFlags, allProducts]) => {
            const nextActions = [];
            const products: ProductType[] = [];
            const commonResponse = this.getCommonResponse(responses);
            (responses as any[]).forEach((retrieveResponse) =>
              products.push(retrieveResponse.productType)
            );
            const atLeastOneActiveProduct: boolean =
              ProductUtils.atLeastOneActive(
                allProducts.filter((product) => products.includes(product.type))
              );
            this.assertValidStates(responses, stateSpecificFlags);

            nextActions.push(
              updateAgency({
                payload: {
                  producerCode: commonResponse.producer.producerCode,
                  agent: {
                    agencyCode:
                      commonResponse.producer.type === 'IndependentAgent'
                        ? commonResponse.producer.agencyCode
                        : '',
                  },
                },
              })
            );

            for (const retrieveResponse of responses) {
              const productModel = allProducts.find(
                (p) => p.type === retrieveResponse.productType
              );
              if (!productModel?.isDsmActive) {
                nextActions.push(
                  RetrieveActions.retrieveInactiveQuoteSuccess({
                    payload: retrieveResponse,
                  })
                );
              } else {
                if (
                  (retrieveResponse.response?.offeredQuotes?.length || 0) > 0
                ) {
                  nextActions.push(
                    CoverageActions.runCoverageRules({
                      payload:
                        retrieveResponse.response.offeredQuotes[0].coverages.map(
                          (coverage: any) => {
                            coverage.productId = retrieveResponse.productType;
                            coverage.version =
                              retrieveResponse.response.offeredQuotes[0].packageName;
                            return coverage;
                          }
                        ),
                    })
                  );
                }

                nextActions.push(
                  retrieveQuoteSuccess({ payload: retrieveResponse })
                );

                if (retrieveResponse.productType === 'PersonalAuto') {
                  if (
                    this.featureFlagService.isFlagApplicable(
                      stateSpecificFlags || ({} as StateSpecificFlagsModel),
                      retrieveResponse.response.policyAddress.state as string,
                      'driverVehicleAssignment',
                      retrieveResponse.productType
                    )
                  ) {
                    nextActions.push(
                      getDriverVehicleAssignment({ product: 'PersonalAuto' })
                    );
                  }
                  if (
                    !retrieveResponse.response.telematicsEnrollments?.length
                  ) {
                    nextActions.push(
                      excludeQuoteFromTelematics({
                        quoteId: retrieveResponse.response.quoteId,
                      })
                    );
                  }
                  nextActions.push(
                    TelematicsActions.getTelematicsRecommendation({
                      dontAutoApply: true,
                    })
                  );
                }

                if (retrieveResponse.productType === 'MSA') {
                  if (
                    this.featureFlagService.isFlagApplicable(
                      stateSpecificFlags || ({} as StateSpecificFlagsModel),
                      retrieveResponse.response.policyAddress.state as string,
                      'msaDriverVehicleAssignment',
                      retrieveResponse.productType
                    )
                  ) {
                    nextActions.push(
                      getDriverVehicleAssignment({ product: 'MSA' })
                    );
                  }
                }

                if (retrieveResponse.productType === 'RV') {
                  if (
                    this.featureFlagService.isFlagApplicable(
                      stateSpecificFlags || ({} as StateSpecificFlagsModel),
                      retrieveResponse.response.policyAddress.state as string,
                      'rvDriverVehicleAssignment',
                      retrieveResponse.productType
                    )
                  ) {
                    nextActions.push(
                      getDriverVehicleAssignment({ product: 'RV' })
                    );
                  }
                }

                if (
                  this.featureFlagService.isFlagApplicable(
                    stateSpecificFlags || ({} as StateSpecificFlagsModel),
                    retrieveResponse.response.policyAddress.state as string,
                    'preBindDocuments',
                    retrieveResponse.productType
                  ) &&
                  !!retrieveResponse.response.documents?.length
                ) {
                  nextActions.push(
                    replacePreBindDocumentsByProductType({
                      documents:
                        retrieveResponse.response.documents.map((doc: any) => {
                          return {
                            ...doc,
                            productType: retrieveResponse.productType,
                          };
                        }) || [],
                      productType: retrieveResponse.productType,
                    })
                  );
                }

                if (
                  ProductUtils.ReportsAndUWProducts.includes(
                    retrieveResponse.productType
                  )
                ) {
                  nextActions.push(
                    getUWReports({
                      quoteId: retrieveResponse.response.quoteId,
                      product: retrieveResponse.productType,
                    })
                  );
                }

                if (
                  retrieveResponse.productType === 'Homeowner' ||
                  retrieveResponse.productType === 'Condominium' ||
                  retrieveResponse.productType === 'Tenant'
                ) {
                  nextActions.push(
                    ScheduledCategoryActions.loadScheduledCategories({
                      payload: [retrieveResponse.productType],
                    })
                  );
                }
              }
            }
            if (atLeastOneActiveProduct) {
              nextActions.push(updateTasks());
              nextActions.push(NavigationActions.navigatePostRetrieve());
              if (
                products.includes('PersonalAuto') ||
                products.includes('MSA')
              ) {
                nextActions.push(
                  DriverVehiclePrefillActions.getCustomersInfo()
                );
              }
            } else {
              this.loadingService.endLoading('retrievequote');
            }

            return from(nextActions);
          }),
          catchError((error) => {
            this.loadingService.endLoading('retrievequote');
            return of(
              retrieveQuoteError({
                payload: this.errorSanitizerService.sanitizeError(error),
              })
            );
          })
        )
      )
    )
  );

  hydrateQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hydrateQuote),
      switchMap(({ quoteId, producerCode }) =>
        this.retrieveAdapter.hydrateQuote(quoteId, producerCode).pipe(
          map((response) => hydrateQuoteSuccess({ payload: response })),
          catchError((error) => {
            return of(
              hydrateQuoteError({
                payload: this.errorSanitizerService.sanitizeError(error),
              })
            );
          })
        )
      )
    )
  );

  hydrateQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hydrateQuoteSuccess),
      map((response) => {
        const quotes = response.payload
          .map((q) => ({
            quoteId: q.quoteId,
            productType: this.sanitizeIncomingProductType(q.productType),
          }))
          .filter((q) => q.quoteId && q.productType);
        return retrieveQuotes({
          payload: {
            quotes,
          },
        });
      })
    )
  );

  /* I'm not crazy about this, but the success case is already handled similarly (see retrieveQuote$)
   */
  dropSpinnerWhenHydrateFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hydrateQuoteError, retrieveQuoteError),
        tap(() => this.loadingService.endLoading('hydrate-quote'))
      ),
    { dispatch: false }
  );

  dropSpinnerWhenPostRetrieveNavigationCompletes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigatePostRetrieveComplete),
        tap(() => {
          this.loadingService.endLoading('hydrate-quote');
          this.loadingService.endLoading('retrievequote');
        })
      ),
    { dispatch: false }
  );

  signalCompletionOfMultipleRetrieveCalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        RetrieveActions.retrieveInactiveQuoteSuccess,
        retrieveQuoteError
      ),
      withLatestFrom(this.store.select(getRetrievePendingCallCount)),
      filter(([action, pendingCallCount]) => !pendingCallCount),
      map(() => allRetrievesComplete())
    )
  );

  alertForTasksAfterRetrieve$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.navigatePostRetrieve),
        switchMap(() =>
          this.store
            .select(TasksModalSelectors.getTasksForPostRetrieveModal)
            .pipe(take(1))
        ),
        filterOutNull(),
        tap((tasks) => {
          const modalRef = this.ngbModal.open(TasksModalContainerComponent, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            ariaLabelledBy: 'tasksModalHeader',
          });
          modalRef.componentInstance.tasks = tasks;
        })
      ),
    { dispatch: false }
  );

  updateProducersOnRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.retrieveQuoteSuccess),
      withLatestFrom(this.store.select(AgencySelectors.getProducerCodeSearch)),
      map(([_res, producerSearch]) => {
        const response = _res.payload.response;
        const productType = _res.payload.productType;
        const producer = producerSearch?.producers.find(
          (p) => response.producer.producerCode == p.producerCode
        );

        if (!producer) {
          return null;
        }
        return ProductActions.updateProducer({
          product: productType,
          producer: producer,
        });
      }),
      filterOutNull()
    )
  );

  private sanitizeIncomingProductType(input: string): ProductType {
    if (input === 'Homeowners') {
      return 'Homeowner';
    }
    // ok: PersonalAuto, Tenant
    // unknown: Condominium, powersports?
    return input as ProductType;
  }

  private assertValidStates(
    responses: QuoteRetrieveResponse[],
    stateSpecificFlags: StateSpecificFlagsModel
  ): void {
    for (const response of responses) {
      const state = response?.response?.policyAddress?.state;
      if (!state) {
        continue;
      }
      if (stateSpecificFlags.restrictAllProducts.states.includes(state)) {
        throw new Error(`Quote state not supported at this time (${state})`);
      }
    }
  }

  private getCommonResponse(responses: any[]): any {
    // untyped and dangerous
    return responses.find(
      (retrieveResponse) => !!retrieveResponse.response.producer
    ).response;
  }
}
