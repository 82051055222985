import { Injectable } from '@angular/core';
import { MortgageAdapter } from '@core/adapters/mortgage.adapter';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromActions from './mortgage.action';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { MortgageService } from '@core/services/mortgage.service';
import { Store } from '@ngrx/store';
import { buildMortgageRequest } from './mortgage.selector';
import { MortgageEntity } from './mortgage.reducer';
import { QuoteActions, TaskActions } from '@core/store/actions';
import { MortgageeTypes } from '@shared/constants/app-constants';
import { Action } from '@ngrx/store';
import { MortgageRequest } from '@core/models/api/request/mortgage.request.model';
import { StringUtils } from '@shared/utils/string.utils';
import { MortgageBuilder } from '@shared/utils/builders/mortgage/mortgage.builder';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { BillingFormService } from '@forms-store/services/billing-form.service';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable()
export class MortgageEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private mortgageAdapter: MortgageAdapter,
    private mortgageService: MortgageService,
    private errorSanitizerService: ErrorSanitizerService,
    private billingFormService: BillingFormService,
    private productsService: ProductsService
  ) {}

  addMortgage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addMortgage),
        exhaustMap((action) =>
          this.store
            .select(buildMortgageRequest(action.payload as MortgageModel))
            .pipe(
              take(1),
              map((request) => ({ request, action }))
            )
        ),
        exhaustMap(({ request, action }) => {
          return this.mortgageService.addOrUpdateMortgage(request).pipe(
            switchMap((response: MortgageEntity | MortgageEntity[]) => {
              const mortgages: MortgageEntity[] = Array.isArray(response)
                ? response.map((mortgage) => ({
                    // we must include a street to the address for editing purposes: (addl interests specific)
                    ...MortgageBuilder.formatMortgage(mortgage),
                    productType: request.productType,
                    escrow: false,
                  }))
                : [
                    {
                      // we must include a street to the address for editing purposes: (addl interests specific)
                      ...MortgageBuilder.formatMortgage(response),
                      productType: request.productType,
                      escrow: false,
                    },
                  ];
              return from([
                fromActions.addMortgageSuccess({
                  payload: mortgages,
                  correlationId: action.correlationId,
                }),
                TaskActions.updateTasks(),
              ]);
            }),
            catchError((error) =>
              of(
                fromActions.addMortgageError({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    action.payload.productType
                  ),
                  correlationId: action.correlationId,
                })
              )
            )
          );
        })
      ) as unknown as Observable<Action>
  );

  /* TIB-578: Usually we set quote.currentBillTo when the user toggles Escrow payment.
   * If they enable it with no mortgage added, DSM will reject the update.
   * So we need to check after adding the mortgage, and update quote.currentBillTo if needed.
   */
  updateCurrentBillToAfterAddMortgage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addMortgageSuccess),
    withLatestFrom(this.billingFormService.getBillingPlansForm()),
    filter(([action, billingPlansForm]) => !!billingPlansForm.billToEscrowAccount),
    switchMap(([action, billingPlansForm]) => {
      return this.productsService.getProduct(action.payload[0]?.productType as ProductType).pipe(
        take(1),
        map(product => ({ action, billingPlansForm, product }))
      );
    }),
    filter(({product}) => !!(product && product.currentBillTo !== 'Mortgagee')),
    map(({product}) => {
      const correlationId = StringUtils.generateUuid();
      return QuoteActions.updateQuote({
        productType: product!.type,
        override: { currentBillTo: 'Mortgagee' },
        correlationId,
      });
    })
  ));

  updateMortgage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.updateMortgage),
        exhaustMap((action) =>
          this.store
            .select(buildMortgageRequest(action.payload as MortgageModel))
            .pipe(
              take(1),
              map((request) => ({ request, action }))
            )
        ),
        mergeMap(({ request, action }) => {
          return this.mortgageAdapter.updateMortgage(request).pipe(
            switchMap((response: MortgageEntity | MortgageEntity[]) => {
              if (Array.isArray(response)) response = response[0];

              // we must include a street to the address for editing purposes: (addl interests specific)
              const formattedMortgage =
                MortgageBuilder.formatMortgage(response);
              return of(
                fromActions.updateMortgageSuccess({
                  payload: {
                    changes: { ...formattedMortgage, escrow: true },
                    id: request.mortgage.mortgageId,
                  },
                  correlationId: action.correlationId,
                })
              );
            }),
            catchError((error) => {
              return of(
                fromActions.updateMortgageError({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    action.payload.productType
                  ),
                  correlationId: action.correlationId,
                })
              );
            })
          );
        })
      ) as unknown as Observable<Action>
  );

  deleteMortgage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteMortgage),
        exhaustMap((action) => {
          return this.store
            .select(buildMortgageRequest(action.payload as MortgageModel))
            .pipe(
              take(1),
              withLatestFrom(this.mortgageService.getAllMortgages()),
              map(([request, mortgages]) => ({ request, mortgages, action }))
            );
        }),
        mergeMap(({ request, mortgages, action }) => {
          if (!request.mortgage.mortgageId) {
            return from([] as Action[]);
          }
          const secondaryMortgage =
            request.mortgage.mortgageId &&
            request.mortgage.type === MortgageeTypes.PRIMARY_MORTGAGEE &&
            mortgages?.find(
              (mortgage) =>
                mortgage?.mortgageId !== request.mortgage.mortgageId &&
                mortgage?.type === MortgageeTypes.MORTGAGEE
            );
            const otherPrimaryMortgages = (mortgages || []).filter(
              (mortgage) =>
                mortgage?.mortgageId !== request.mortgage.mortgageId &&
                mortgage?.type === MortgageeTypes.PRIMARY_MORTGAGEE
            );

          return this.mortgageService.deleteMortgage(request).pipe(
            switchMap(() => {
              const actions: Action[] = [
                fromActions.deleteMortgageSuccess({
                  mortgageId: request.mortgage.mortgageId,
                  correlationId: action.correlationId,
                }),
              ];
              if (secondaryMortgage  && otherPrimaryMortgages.length === 0) {
                actions.push(
                  fromActions.updateMortgage({
                    payload: {
                      ...secondaryMortgage,
                      type: MortgageeTypes.PRIMARY_MORTGAGEE,
                    },
                  })
                );
              } else {
                actions.push(TaskActions.updateTasks());
              }
              return from(actions);
            }),
            catchError((error) =>
              of([
                fromActions.deleteMortgageError({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    request.productType
                  ),
                  correlationId: action.correlationId,
                }),
              ])
            )
          );
        })
      ) as unknown as Observable<Action>
  );

  deleteAllMortgage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteAllMortgage),
        mergeMap(() =>
          this.mortgageService.getAllMortgages().pipe(
            take(1),
            filter(Boolean),
            mergeMap((mortgages: MortgageEntity[]) => {
              const join = mortgages.map((m) =>
                this.store
                  .select(buildMortgageRequest(m as MortgageModel))
                  .pipe(take(1))
              );

              return forkJoin<MortgageRequest[]>(join).pipe(take(1));
            })
          )
        ),
        mergeMap((mortgages: MortgageRequest[]) => {
          const deleteIndividualMortgage = (request: MortgageRequest) =>
            this.mortgageService.deleteMortgage(request).pipe(
              switchMap(() => {
                const actions: Action = fromActions.deleteMortgageSuccess({
                  mortgageId: request.mortgage.mortgageId,
                });
                return of(actions);
              }),
              catchError((error) =>
                of(
                  fromActions.deleteMortgageError({
                    error: this.errorSanitizerService.sanitizeError(
                      error,
                      request.productType
                    ),
                  })
                )
              )
            );
          return forkJoin(mortgages?.map(deleteIndividualMortgage)).pipe(
            mergeMap((response) => from(response))
          );
        })
      ) as unknown as Observable<Action>
  );

  // delete all of single type
  deleteMortgagesOfType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMortgagesOfType),
      switchMap((action) =>
        this.mortgageService
          .getMortgagesOfMortgageType(
            action.payload.productType,
            action.payload.mortgageType
          )
          .pipe(take(1))
      ),
      mergeMap((mortgages) => {
        const nextActions: Action[] = [];
        mortgages.map((mortgage) =>
          nextActions.push(
            fromActions.deleteMortgage({
              payload: mortgage as MortgageEntity,
              correlationId: StringUtils.generateUuid(),
            })
          )
        );
        return from(nextActions);
      })
    )
  );

  // delete all of multiple types
  deleteMortgagesOfTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMortgagesOfTypes),
      map((action) => action.payload),
      mergeMap(({ productType, mortgageTypes }) => {
        const nextActions: Action[] = [];
        mortgageTypes.map((mortgageType) =>
          nextActions.push(
            fromActions.deleteMortgagesOfType({
              payload: { productType, mortgageType },
            })
          )
        );
        return from(nextActions);
      })
    )
  );
}
