import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PniPageSubmission } from '@app/pni/pages/pni/pni-page.component';
import { MsaPageModel } from '@app/powersports/msa/pages/msa-page.component';
import { ProductHelper } from '@core/helper/product.helper';
import { ProductType, TaskProductType } from '@core/models/api/dsm-types';
import { PeoplePageModel } from '@core/models/views/people-page-model';
import { MemberModel } from '@core/models/views/person.model';
import { PniPageModel } from '@core/models/views/pni-page-model';
import { VehiclesPageModel } from '@core/models/views/vehicle-page-model';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { LogService } from '@core/services/log.service';
import { MemberService } from '@core/services/member.service';
import { ProductsService } from '@core/services/products.service';
import { PropertyService } from '@core/services/property.service';
import { TaskService } from '@core/services/task.service';
import { VehicleService } from '@core/services/vehicle.service';
import { QuoteActions } from '@core/store/actions';
import { ProductModel } from '@core/store/entities/product/product.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { AgencyModel } from '@entities/agency/agency.model';
import { CoveredLocationModel } from '@entities/covered-location/covered-location.model';
import { TasksModalModel } from '@entities/task/selectors/tasks-modal.selector';
import { ComponentStore } from '@ngrx/component-store';
import { Action, Store } from '@ngrx/store';
import {
  BIND_SUSPENSION_MESSAGE,
  PropertyProductTypes,
} from '@shared/constants/app-constants';
import { VehicleEntityFromFormBuilder } from '@shared/utils/builders/vehicles/vehicle-entity-from-form';
import { DateUtils } from '@shared/utils/date.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PeoplePageUtils } from '@shared/utils/pages/people-page.utils';
import { VehiclePageUtils } from '@shared/utils/pages/vehicles-page.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { BehaviorSubject, Observable, of, Subject, take } from 'rxjs';
import { map } from 'rxjs/operators';

interface TasksModalFormState {
  showAvailabilityTasks?: boolean;
  showNoticeOfIssuance?: boolean;
  showBindSuspensionWarning?: boolean;
  showDummyProducerCodeTasks?: boolean;
  showEffectiveDateTasks?: boolean;
  showMemberTasks?: boolean;
  showProductTasks?: boolean;
  products?: ProductModel[];
  membersWithTasks?: MemberModel[];
  autoVehiclesWithTasks?: VehicleModel[];
  msaVehiclesWithTasks?: VehicleModel[];
  rvVehiclesWithTasks?: VehicleModel[];
  boatVehiclesWithTasks?: VehicleModel[];
}

@Component({
  selector: 'nwx-tasks-modal-form',
  templateUrl: './tasks-modal-form.component.html',
  styleUrls: ['./tasks-modal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class TasksModalFormComponent implements OnInit {
  @Input() tasks: Nullable<TasksModalModel>;
  @Input() products!: ProductModel[];
  @Input() pniPageModel!: Nullable<PniPageModel>;
  @Input() membersPageModel!: Nullable<PeoplePageModel>;
  @Input() autoPageModel!: VehiclesPageModel;
  @Input() msaPageModel!: MsaPageModel;
  @Input() boatPageModel!: MsaPageModel;
  @Input() rvPageModel!: MsaPageModel;
  @Input() agencyState: Nullable<AgencyModel> = null;
  @Input() accountId: Nullable<string> = null;

  @Output() closeModal = new EventEmitter<boolean>();

  form!: FormGroup;
  selectedProducts$!: Observable<ProductModel[]>;
  index: number = 0;
  stepNumbers = new Map<string, number>();
  submitted$ = new BehaviorSubject<boolean>(false);
  closed$ = new BehaviorSubject<boolean>(false);
  prefillEffectiveDates = new Subject<string>();
  bindSuspensionMessage = BIND_SUSPENSION_MESSAGE;
  dummyProducerTasksOpen = true;
  effectiveDateTasksOpen = true;
  memberTasksOpen = false;
  productTasksOpen = true;
  prefilled = false;
  errorMessage = '';

  vm$!: Observable<TasksModalFormState>;

  constructor(
    private fb: FormBuilder,
    private taskService: TaskService,
    private vehicleService: VehicleService,
    private memberService: MemberService,
    private productsService: ProductsService,
    private readonly componentStore: ComponentStoreService<TasksModalFormState>,
    private store: Store,
    private coveredLocationService: CoveredLocationService,
    private propertyService: PropertyService,
    private logService: LogService,
    private router: Router,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  ngOnInit(): void {
    if ((this.hasEffectiveDateTasks() || this.hasDummyProducerCodeTasks()) && !this.hasPropertyEligibilityTasks()) {
      this.memberTasksOpen = false;
      this.productTasksOpen = false;
    }
    this.log();

    this.form = this.fb.group({});

    this.initializeStepNumbers();

    this.componentStore.initialize({
      showAvailabilityTasks: this.hasAvailabilityTasks(),
      showNoticeOfIssuance: ProductHelper.displayNoticeOfIssuance(
        this.agencyState?.agent?.agencyCode || ''
      ),
      showBindSuspensionWarning: this.hasProductsWithBindSuspension(),
      showDummyProducerCodeTasks: this.hasDummyProducerCodeTasks(),
      showEffectiveDateTasks: this.hasEffectiveDateTasks(),
      showMemberTasks: this.hasMemberTasks(),
      showProductTasks: this.hasProductTasks(),
      products: this.products,
      membersWithTasks: this.getMembersWithTasks(this.membersPageModel?.people),
      autoVehiclesWithTasks: this.getVehiclesWithTasks('PersonalAuto'),
      msaVehiclesWithTasks: this.getVehiclesWithTasks('MSA'),
      boatVehiclesWithTasks: this.getVehiclesWithTasks('Boat'),
      rvVehiclesWithTasks: this.getVehiclesWithTasks('RV'),
    });

    this.selectedProducts$ = this.productsService.getSelectedProducts();
    this.vm$ = this.componentStore.get();
  }

  private log(): void {
    this.logService.logUiEvent(
      'tasks-modal',
      this.getDisplayedTasksAsOneArray().map((task) => {
        return `${task.entityType}.${task.entityId}.${task.field}`;
      }),
      'task'
    );
  }

  private getDisplayedTasksAsOneArray(): TaskModel[] {
    if (!this.tasks) return [];
    return [
      ...this.tasks.availabilityTasks,
      ...this.tasks.effectiveDateTasks,
      ...this.tasks.pniTasks,
      ...this.tasks.memberTasks,
      ...this.tasks.autoTasks,
      ...this.tasks.msaTasks,
      ...this.tasks.boatTasks,
      ...this.tasks.rvTasks,
      ...this.tasks.propertyTasks,
    ];
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  onSubmit(): void {
    this.submitted$.next(true);

    if (!this.form.valid && this.form.status !== 'DISABLED') {
      return;
    }

    this.taskService.submitTasksModal();
    const producerCodeFormValid =
      this.form.get('dummy-producer-form') != null
        ? this.form.get('dummy-producer-form')?.valid || false
        : true;
    const memberFormValid =
      this.form.get('policyMembers') != null
        ? this.form.get('policyMembers')?.valid || false
        : true;
    let productFormHasValidUpdate = true;
    let effectiveDateFormsValid = true;
    for (const product of this.products) {
      if (
        this.form.get(product.name?.toLowerCase() + '-effectivedate')?.valid ==
        false
      ) {
        effectiveDateFormsValid = false;
      }
      const productForm = this.form.get(this.getFormGroupName(product.type));
      if (productForm && !productForm.valid) {
        productFormHasValidUpdate = false;
      }
      if (this.productTasksAreOptional() && productForm?.pristine) {
        productFormHasValidUpdate = false;
      }
    }

    if (this.hasEffectiveDateTasks()) {
      this.updatePolicyEffectiveDates(this.form.value);
    }
    if (this.hasMemberTasks() && memberFormValid) {
      this.updateMembers();
    }
    if (this.hasProductTasks() && productFormHasValidUpdate) {
      this.updateProducts();
    }
    this.onCloseModal();
  }

  onCloseModal(redirect: boolean = false): void {
    if (redirect) {
      this.router.navigateByUrl('/home/search');
    }
    this.closeModal.emit();
    this.closed$.next(true);
    this.closed$.complete();
  }

  onPolicyRemoved(productType: ProductType): void {
    if (!this.tasks) {
      return;
    }
    const taskProductType = this.getTaskProductType(productType);
    this.tasks = {
      ...this.tasks,
      taskProductTypes: this.tasks.taskProductTypes.filter(
        (type) => type !== taskProductType
      ),
    };

    this.componentStore.update({
      showAvailabilityTasks: this.hasAvailabilityTasks(),
      showEffectiveDateTasks: this.hasEffectiveDateTasks(),
      showMemberTasks: this.hasMemberTasks(),
      showProductTasks: this.hasProductTasks(),
    });
  }

  hasAvailabilityTasks(): boolean {
    return !!this.tasks?.availabilityTasks.length;
  }

  hasProductsWithBindSuspension(): boolean {
    return this.products?.some((product) => product.hasBindSuspension);
  }

  hasEffectiveDateTasks(): boolean {
    return !!this.tasks?.effectiveDateTasks.length;
  }

  hasDummyProducerCodeTasks(): boolean {
    return !!this.tasks?.dummyProducerCodeTasks.length;
  }

  hasMemberTasks(): boolean {
    return !!this.tasks?.memberTasks.length || !!this.tasks?.pniTasks.length;
  }

  hasProductTasks(): boolean {
    return !!this.tasks?.taskProductTypes.length;
  }

  hasPropertyEligibilityTasks(): boolean {
    return this.tasks?.propertyTasks?.some(
      (task) => task.field === 'acknowledgement'
    ) || false;
  }

  memberTasksAreOptional(): boolean {
    const anyProductQuoted = this.products?.some(p => p.quoteStatus === 'Quoted');
    return !anyProductQuoted && this.tasks?.pniTasks?.every(
      (task) => task.field === 'homeNumber' || 
                task.field === 'emailAddress' || 
                task.field === 'licenseNumber'
    ) || false;
  }

  productTasksAreOptional(): boolean {
    // Required tasks: "roofYear" and "acknowledgement", both under propertyTasks
    for (const task of this.tasks?.propertyTasks || []) {
      if (task.field === 'acknowledgement') {
        return false;
      }
      if (task.field === 'roofYear') {
        return false;
      }
    }
    return true;
  }

  onMembersChange(members: MemberModel[]): void {
    this.componentStore.update({
      membersWithTasks: members,
    });
  }

  onVehicleChange(vehicles: VehicleModel[]): void {
    switch (vehicles[0].productType) {
      case 'PersonalAuto':
        this.componentStore.update({
          autoVehiclesWithTasks: vehicles,
        });
        break;
      case 'MSA':
        this.componentStore.update({
          msaVehiclesWithTasks: vehicles,
        });
        break;
      case 'Boat':
        this.componentStore.update({
          boatVehiclesWithTasks: vehicles,
        });
        break;
      case 'RV':
        this.componentStore.update({
          rvVehiclesWithTasks: vehicles,
        });
        break;
    }
  }

  getProductTaskTypeDisplayName(type: string | undefined): string {
    switch (type?.toLowerCase()) {
      case 'homeowner':
        return 'Homeowners';
      case 'condominium':
        return 'Condo';
      case 'tenant':
        return 'Renters';
      case 'personalauto':
        return 'Auto';
      case 'msa':
        return 'Motorcycle/ATV';
      case 'boat':
        return 'Boat';
      case 'rv':
        return 'RV';
      default:
        return '';
    }
  }

  getTaskProductType(productType: ProductType): TaskProductType {
    return productType;
  }

  initializeStepNumbers(): void {
    this.index = 0;
    if (this.hasAvailabilityTasks()) {
      this.stepNumbers.set('availability', ++this.index);
    }
    if (this.hasDummyProducerCodeTasks()) {
      this.stepNumbers.set('dummyProducer', ++this.index);
    }
    if (this.hasEffectiveDateTasks()) {
      this.stepNumbers.set('effectiveDate', ++this.index);
    }
    if (this.hasMemberTasks()) {
      this.stepNumbers.set('member', ++this.index);
    }
    if (this.hasProductTasks()) {
      this.stepNumbers.set('product', ++this.index);
    }
  }

  updatePolicyEffectiveDates(formValue: {
    [key: string]: { effectiveDate: string };
  }) {
    for (const key of Object.keys(formValue)) {
      const [similarToProductType, identifier] = key.split('-');
      if (identifier !== 'effectivedate') {
        continue;
      }
      let productType: ProductType | '' = '';
      switch (similarToProductType) {
        case 'auto':
          productType = 'PersonalAuto';
          break;
        case 'homeowner':
          productType = 'Homeowner';
          break;
        case 'condo':
          productType = 'Condominium';
          break;
        case 'renters':
          productType = 'Tenant';
          break;
        case 'dwelling fire':
          productType = 'DwellingFire';
          break;
        case 'motorcycle / atv':
          productType = 'MSA';
          break;
        case 'umbrella':
          productType = 'PersonalUmbrella';
          break;
        case 'rv':
          productType = 'RV';
          break;
        case 'boat':
          productType = 'Boat';
          break;
      }
      if (!productType) {
        continue;
      }
      this.productsService.updateQuote({
        productType,
        effectiveDate: DateUtils.formatDateToDSM(formValue[key].effectiveDate),
      });
    }
    this.store.dispatch(QuoteActions.rerateAfterStable());
  }

  updateMembers() {
    this.vm$.pipe(take(1)).subscribe((vm) => {
      vm.membersWithTasks?.forEach((member) => {
        this.memberService.storeMember(member);
      });
      if (this.pniPageModel) {
        const updatedPni = vm.membersWithTasks?.find(
          (member) => member.policyHolderId
        );
        if (updatedPni) {
          this.pniPageModel.pni = { ...this.pniPageModel?.pni, ...updatedPni };
          this.memberService.submitPniPage(
            this.pniPageModel as PniPageSubmission
          );
        }
      }
      let members = this.membersPageModel?.people || [];
      vm.membersWithTasks?.forEach((member) => {
        const memberIndex = PersonUtils.getPersonIndex(
          this.membersPageModel?.people || [],
          member
        );
        if (memberIndex > -1) {
          members = GeneralUtils.pureReplace<MemberModel>(
            members,
            member,
            memberIndex
          );
        }
      });
      const submission = PeoplePageUtils.CreatePeoplePageSubmission(
        members,
        this.membersPageModel?.selectedProducts || [],
        this.membersPageModel?.ridingAssociation,
        this.membersPageModel?.rvAssociation
      );
      this.memberService.submitPeoplePage(submission);
      this.store.dispatch(QuoteActions.rerateAfterStable());
    });
  }

  updateProducts() {
    this.vm$.pipe(take(1)).subscribe((vm) => {
      if (this.tasks?.taskProductTypes.includes('PersonalAuto')) {
        this.updateVehicles(
          vm.autoVehiclesWithTasks || [],
          this.autoPageModel,
          'PersonalAuto'
        );
      }
      if (this.tasks?.taskProductTypes.includes('MSA')) {
        this.updateVehicles(
          vm.msaVehiclesWithTasks || [],
          this.msaPageModel,
          'MSA'
        );
      }
      if (this.tasks?.taskProductTypes.includes('Boat')) {
        this.updateVehicles(
          vm.boatVehiclesWithTasks || [],
          this.boatPageModel,
          'Boat'
        );
      }
      if (this.tasks?.taskProductTypes.includes('RV')) {
        this.updateVehicles(
          vm.rvVehiclesWithTasks || [],
          this.rvPageModel,
          'RV'
        );
      }
      if (
        this.tasks?.taskProductTypes.includes('Homeowner') ||
        this.tasks?.taskProductTypes.includes('Condominium') ||
        this.tasks?.taskProductTypes.includes('Tenant')
      ) {
        this.updateCoveredLocations();
      }
      this.store.dispatch(QuoteActions.rerateAfterStable());
    });
  }

  private updateVehicles(
    vehiclesWithTasks: VehicleModel[],
    pageModel: VehiclesPageModel | MsaPageModel,
    productType: ProductType
  ) {
    vehiclesWithTasks?.forEach((vehicle) => {
      this.vehicleService.storeVehicle(
        VehicleEntityFromFormBuilder.generateVehicleEntityFromForm(vehicle)
      );
    });
    if (productType === 'PersonalAuto') {
      let { vehicles, currentCarrier } = pageModel as VehiclesPageModel;
      vehicles = this.replaceWithUpdatedVehicles(
        vehicles || [],
        vehiclesWithTasks
      );
      const submission = VehiclePageUtils.createVehiclePageSubmission(
        vehicles,
        currentCarrier
      );
      this.vehicleService.submitVehiclePage(submission);
    } else if (productType === 'MSA') {
      let { vehicles, currentCarrier } = pageModel as MsaPageModel;
      vehicles = this.replaceWithUpdatedVehicles(
        vehicles || [],
        vehiclesWithTasks
      );
      const submission = VehiclePageUtils.CreateMsaPageSubmission(
        vehicles,
        currentCarrier || {}
      );
      this.vehicleService.submitMsaPage(submission);
    } else if (productType === 'Boat') {
      let { vehicles, currentCarrier } = pageModel as MsaPageModel;
      vehicles = this.replaceWithUpdatedVehicles(
        vehicles || [],
        vehiclesWithTasks
      );
      const submission = VehiclePageUtils.CreateBoatPageSubmission(
        vehicles,
        currentCarrier || {}
      );
      this.vehicleService.submitBoatPage(submission);
    } else if (productType === 'RV') {
      let { vehicles, currentCarrier } = pageModel as MsaPageModel;
      vehicles = this.replaceWithUpdatedVehicles(
        vehicles || [],
        vehiclesWithTasks
      );
      const submission = VehiclePageUtils.CreateRVPageSubmission(
        vehicles,
        currentCarrier || {}
      );
      this.vehicleService.submitRVPage(submission);
    }
  }

  private replaceWithUpdatedVehicles(
    vehicles: VehicleModel[],
    vehiclesWithTasks: VehicleModel[]
  ): VehicleModel[] {
    vehiclesWithTasks?.forEach((vehicle) => {
      const vehicleIndex = VehicleUtils.getVehicleIndex(
        vehicles || [],
        vehicle
      );
      if (vehicleIndex > -1) {
        vehicles = GeneralUtils.pureReplace<VehicleModel>(
          vehicles || [],
          vehicle,
          vehicleIndex
        );
      }
    });
    return vehicles;
  }

  private updateCoveredLocations(): void {
    for (const product of this.products) {
      switch (product.type) {
        case 'Homeowner': {
          this.propertyService.submitHomeownerPage();
          break;
        }
        case 'Condominium': {
          this.propertyService.submitCondoPage();
          break;
        }
        case 'Tenant': {
          this.propertyService.submitTenantPage();
          break;
        }
      }
    }
  }

  getMembersWithTasks(
    members: MemberModel[] | undefined
  ): MemberModel[] | undefined {
    const taskMemberIds =
      this.tasks?.memberTasks
        ?.map((t) => t.entityId)
        .concat(this.tasks.pniTasks?.map((t) => t.entityId)) || [];
    return members?.filter((m) => taskMemberIds.includes(m.entityId));
  }

  getVehiclesWithTasks(productType: string): VehicleModel[] | undefined {
    if (productType === 'PersonalAuto') {
      const taskVehicleIds =
        this.tasks?.autoTasks?.map((t) => t.entityId) || [];
      return this.autoPageModel.vehicles?.filter((v) =>
        taskVehicleIds.includes(v.vehicleId?.toString())
      );
    } else if (productType === 'MSA') {
      const taskVehicleIds = this.tasks?.msaTasks?.map((t) => t.entityId) || [];
      return this.msaPageModel.vehicles?.filter((v) =>
        taskVehicleIds.includes(v.vehicleId?.toString())
      );
    } else if (productType === 'Boat') {
      const taskVehicleIds =
        this.tasks?.boatTasks?.map((t) => t.entityId) || [];
      return this.boatPageModel.vehicles?.filter((v) =>
        taskVehicleIds.includes(v.vehicleId?.toString())
      );
    } else if (productType === 'RV') {
      const taskVehicleIds = this.tasks?.rvTasks?.map((t) => t.entityId) || [];
      return this.rvPageModel.vehicles?.filter((v) =>
        taskVehicleIds.includes(v.vehicleId?.toString())
      );
    } else {
      // default PersonalAuto
      const taskVehicleIds =
        this.tasks?.autoTasks?.map((t) => t.entityId) || [];
      return this.autoPageModel.vehicles?.filter((v) =>
        taskVehicleIds.includes(v.vehicleId?.toString())
      );
    }
  }

  isProductFormInvalid(type: string | undefined): boolean {
    return (
      this.form.get(type?.toLowerCase() || '')?.status === 'INVALID' &&
      this.submitted$.value
    );
  }

  isEffectiveDateFormInvalid(): boolean {
    return this.products.some((p) => {
      return (
        this.form.get(p.name?.toLowerCase() + '-effectivedate' || '')
          ?.status === 'INVALID' || false
      );
    });
  }

  getFormGroupName(name: string) {
    return name.toLowerCase();
  }

  imageUrl(product: string): string {
    return ProductUtils.getImageUrlForProduct(product as ProductType);
  }

  isVehicleProduct(product: TaskProductType): boolean {
    if (['All', 'Property'].includes(product)) return false;
    return ProductUtils.isVehicleProduct(product as ProductType);
  }

  getTasksForProduct(product: TaskProductType): TaskModel[] {
    if (!this.tasks) return [];
    switch (product) {
      case 'PersonalAuto':
        return this.tasks.autoTasks;
      case 'MSA':
        return this.tasks.msaTasks;
      case 'Boat':
        return this.tasks.boatTasks;
      case 'RV':
        return this.tasks.rvTasks;
      default:
        return [];
    }
  }
  getVehiclesForProduct(
    product: TaskProductType
  ): Observable<VehicleModel[] | undefined> {
    switch (product) {
      case 'PersonalAuto':
        return this.vm$.pipe(map((vm) => vm.autoVehiclesWithTasks));
      case 'MSA':
        return this.vm$.pipe(map((vm) => vm.msaVehiclesWithTasks));
      case 'Boat':
        return this.vm$.pipe(map((vm) => vm.boatVehiclesWithTasks));
      case 'RV':
        return this.vm$.pipe(map((vm) => vm.rvVehiclesWithTasks));
      default:
        return of([]);
    }
  }

  dispatchAction(action: Action): void {
    this.store.dispatch(action);
  }

  getCoveredLocationForProduct(
    product: TaskProductType
  ): Observable<CoveredLocationModel | undefined> {
    return this.coveredLocationService.getCoveredLocation(
      product as ProductType
    );
  }

  isPropertyProductType(product: TaskProductType): boolean {
    return PropertyProductTypes.includes(product as ProductType);
  }

  realProductTypesOnly(taskProductType: TaskProductType): ProductType {
    if (taskProductType === 'All') {
      return 'Homeowner';
    }
    return taskProductType;
  }

  toggleMemberTasks() {
    this.memberTasksOpen = !this.memberTasksOpen;
  }

  toggleProductTasks() {
    this.productTasksOpen = !this.productTasksOpen;
  }

  toggleEffectiveDateTasks() {
    this.effectiveDateTasksOpen = !this.effectiveDateTasksOpen;
  }

  toggleDummyProducerTasks() {
    this.dummyProducerTasksOpen = !this.dummyProducerTasksOpen;
  }

  dummyProducerIncomplete(): boolean {
    const producerTask = this.tasks?.incompleteTasks.find(
      (task) => task.field === 'producerCode'
    );
    // The task can and will disappear during play. When that happens it is "Complete".
    return producerTask ? !producerTask.completed : false;
  }

  onError(error: any): void {
    this.errorMessage =
      this.errorSanitizerService.sanitizeError(error).displayMessage || '';
  }
}
