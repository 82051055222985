import {
  CurrentCarrierFormModel,
  CurrentCarrierFormOptions,
} from '@app/core/interfaces/interfaces';
import {
  CurrentCarrierCoverageLapse,
  ProductType,
} from '@app/core/models/api/dsm-types';
import { CurrentCarrierEntity } from '@app/core/store/entities/current-carrier/current-carrier.reducer';
import { FeatureFlagsModel } from '@app/core/store/entities/feature-flag/feature-flag.model';
import {
  CurrentCarrierMetadataService,
  currentCarrierNames,
  FilterablePicklistItem,
  msaCurrentCarrierLapseInCoverage,
  currentCarrierLapseReasons,
  msaCurrentCarrierNames,
  currentCarrierLapseInCoverage,
} from '../services/current-carrier-metadata.service';
import { ProductUtils } from './product.util';
import { Nullable } from './type.utils';

export class CurrentCarrierUtils {
  static currentCarrierFormModelFromEntity(
    input: Nullable<CurrentCarrierEntity>,
    featureFlags: FeatureFlagsModel
  ): CurrentCarrierFormModel {
    if (!input) {
      return {
        // Let the form default most fields -- only set the no-ui fields.
        currentCarrierTerms: 6,
      };
    }
    const model: CurrentCarrierFormModel = {
      currentCarrierName: this.defaultCurrentCarrierName(
        input.currentCarrierName
      ),
      currentBodilyInjuryLimit: input.currentBodilyInjuryLimit || undefined,
      coverageLapse: input.coverageLapse,
      currentCarrierTerms: input.currentCarrierTerms,
    };
    if (typeof model.currentCarrierTerms !== 'number') {
      model.currentCarrierTerms = 6;
    }
    model.productType = input.productType || 'PersonalAuto';
    model.reasonForCoverageLapse = input.reasonForCoverageLapse;
    model.isNoNeedState =
      (model.productType === 'PersonalAuto'
        ? featureFlags.autoNoNeedForPriorInsurance
        : featureFlags.msaNoNeedForPriorInsurance) || false;
    return model;
  }

  static defaultCurrentCarrierName(input?: string): string {
    if (!input) {
      return '';
    }
    if (!currentCarrierNames.find((cc) => cc.key === input)) {
      return 'Other';
    }
    return input;
  }

  static responseIsComplete(entity?: CurrentCarrierEntity): boolean {
    if (!entity) {
      return false;
    }
    if (
      entity.currentCarrierHitStatus === 'No-Hit' ||
      !entity.currentCarrierName ||
      !entity.coverageLapse ||
      !entity.currentBodilyInjuryLimit ||
      (entity.coverageLapse !== '0Days' && !entity.reasonForCoverageLapse)
    ) {
      return false;
    }
    return true;
  }

  static currentCarrierFormOptionsFromEntity(
    currentCarrier: CurrentCarrierEntity,
    hit: boolean,
    featureFlags: FeatureFlagsModel,
    productType: ProductType,
    quoteState: string,
    productEffectiveDate: string
  ): CurrentCarrierFormOptions {
    const options: CurrentCarrierFormOptions = {};
    options.currentCarrierNames = currentCarrierNames;
    options.priorBiLimits = CurrentCarrierMetadataService.getBiLimits(
      quoteState,
      productEffectiveDate
    );
    options.productType = productType;
    options.lapseInCoverage = this.getCurrentCarrierLapseInCoverage(
      (productType === 'PersonalAuto'
        ? featureFlags.autoNoNeedForPriorInsurance
        : featureFlags.msaNoNeedForPriorInsurance) || false,
      productType
    );
    options.reasonForLapse = currentCarrierLapseReasons;
    options.productType = productType;
    options.allowManualEntry = false;
    if (hit) {
      options.showAccHit = true;
      // Even in hit cases, we must allow entry if existing data is incomplete.
      if (
        !currentCarrier.currentCarrierName ||
        !currentCarrier.coverageLapse ||
        !currentCarrier.currentBodilyInjuryLimit ||
        (currentCarrier.coverageLapse !== '0Days' &&
          !currentCarrier.reasonForCoverageLapse)
      ) {
        options.allowManualEntry = true;
      }
    } else {
      options.allowManualEntry = true;
      options.showAccHit = false;
      // Once entered, don't show the no-hit warning.
      if (currentCarrier?.currentCarrierName) {
        options.showAccNoHitWarning = false;
      } else {
        if (ProductUtils.isCurrentCarrierReportProduct(productType))
          options.showAccNoHitWarning = true;
      }
    }
    return options;
  }

  static msaCurrentCarrierFormOptionsFromEntity(
    currentCarrier: CurrentCarrierEntity | null,
    featureFlags: FeatureFlagsModel,
    productType: ProductType
  ): CurrentCarrierFormOptions {
    const options: CurrentCarrierFormOptions = {};
    options.currentCarrierNames = msaCurrentCarrierNames;
    options.lapseInCoverage = this.getCurrentCarrierLapseInCoverage(
      featureFlags.msaNoNeedForPriorInsurance || false,
      productType
    );
    options.reasonForLapse = currentCarrierLapseReasons;
    options.allowManualEntry = true;
    options.productType = productType;
    options.sendDefault = featureFlags.currentCarrierDefaults || false;
    return options;
  }

  static currentCarrierEntityFromForm(
    form: CurrentCarrierFormModel
  ): CurrentCarrierEntity {
    return {
      entityId: form.entityId as string,
      currentCarrierName: form.currentCarrierName,
      coverageLapse: form.coverageLapse,
      currentBodilyInjuryLimit: form.currentBodilyInjuryLimit,
      currentCarrierTerms: form.currentCarrierTerms,
      reasonForCoverageLapse: form.reasonForCoverageLapse,
      productType: form.productType,
    };
  }

  static getCurrentCarrierLapseInCoverage(
    noNeedForPriorInsurance: boolean | undefined,
    productType: ProductType
  ): FilterablePicklistItem[] {
    const picklistItems =
      productType === 'PersonalAuto'
        ? currentCarrierLapseInCoverage
        : msaCurrentCarrierLapseInCoverage;
    if (!noNeedForPriorInsurance) {
      return picklistItems.filter(
        (lapse) =>
          lapse.key !== CurrentCarrierCoverageLapse.NoNeedForPriorInsurance &&
          lapse.key !== CurrentCarrierCoverageLapse.NoPriorInsurance
      );
    }
    return picklistItems;
  }
}
