import { Pipe, PipeTransform } from '@angular/core';
import { GarageTypeAllowedCars } from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';

@Pipe({ name: 'filterNumberOfGarageCars' })
export class FilterNumberOfGarageCarsPipe implements PipeTransform {
  constructor() {}
  transform(options: number[], garageType: string) {
    return options && options.length && GarageTypeAllowedCars[garageType]
      ? options.filter((option) => option <= GarageTypeAllowedCars[garageType])
      : options;
  }
}
