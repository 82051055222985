import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { CoveredLocationEntity } from '../../covered-location/covered-location.reducer';
import { ProductModel } from '../../product/product.model';
import { TaskValidators } from '../task-validators';
import { RuleTemplate } from '../task.model';

export const CoveredLocationRules = {
  property: {
    product: [
      {
        rule: TaskValidators.requiredField<ProductModel>(
          'Property policy offering type is required',
          'Draft',
          'Homeowner',
          'offeringType',
          'offering-type'
        ),
        quoteStatus: 'Draft',
        feature: 'offeringTypeApplicable',
      },
    ] as RuleTemplate[],
    condo: [
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of occupants is required',
          'Draft',
          'Condominium',
          'numberOfOccupants',
          'ldf-numberOfOccupants'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Hydrant within 1000ft is required',
          'Draft',
          'Condominium',
          'hasHydrantIn1000Ft',
          'cif-hasHydrantIn1000Ft'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Secondary home is required',
          'Draft',
          'Condominium',
          'secondaryHome',
          'apdf-secondaryHome'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Roof condition is required. Please re-submit the Condominium product page.',
          'Draft',
          'Condominium',
          'roofCondition',
          'roof-condition'
        ),
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Date purchased is required.',
          'Draft',
          'Condominium',
          'datePurchased',
          'date-purchased'
        ),
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of units in building required.',
          'Draft',
          'Condominium',
          'unitsInBuilding',
          'units'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Year built is required.',
          'yearBuilt',
          'year-built'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Construction type is required.',
          'constructionType',
          'construction-type'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Square footage is required.',
          'squareFootage',
          'cf-squareFootage'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Number of stories is required.',
          'numberOfStories',
          'cf-numberOfStories'
        ),
      },
      // needs work, wrong entity
      // {
      //   rule: TaskValidators.requiredField<CondoFormModel>(
      //     'Please indicate if there is a mortgage on the property',
      //     'Quoted',
      //     'hasMortgage'
      //   ),
      //   quoteStatus: 'Quoted',
      // },
    ] as RuleTemplate[],
    renters: [
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of occupants is required',
          'Draft',
          'Tenant',
          'numberOfOccupants',
          'ldf-numberOfOccupants'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Secondary home is required',
          'Draft',
          'Tenant',
          'secondaryHome',
          'apdf-secondaryHome'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of units in building required.',
          'Draft',
          'Tenant',
          'unitsInBuilding',
          'units'
        ),
      },
    ] as RuleTemplate[],
    homeowner: [
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Roof condition is required. Please re-submit the Homeowner product page.',
          'Draft',
          'Homeowner',
          'roofCondition',
          'roof-condition'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of families is required',
          'Draft',
          'Homeowner',
          'numberOfFamilies',
          'ldf-numberOfFamilies'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Number of occupants is required',
          'Draft',
          'Homeowner',
          'numberOfOccupants',
          'ldf-numberOfOccupants'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Hydrant within 1000ft is required',
          'Draft',
          'Homeowner',
          'hasHydrantIn1000Ft',
          'cif-hasHydrantIn1000Ft'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Secondary home is required',
          'Draft',
          'Homeowner',
          'secondaryHome',
          'apdf-secondaryHome'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Modular home is required',
          'Draft',
          'Homeowner',
          'modularHome',
          'apdf-modularHome'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          'Townhouse is required',
          'Draft',
          'Homeowner',
          'townhouse',
          'apdf-townhouse'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredFieldIf<CoveredLocationEntity>(
          (entity) =>
            entity.townhouse === true && !entity.unitsBetweenFirewalls,
          'Units between firewalls is required',
          'Draft',
          'Homeowner',
          'unitsBetweenFirewalls',
          'apdf-unitsBetweenFirewalls'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.numberOfCarsInGarage(
          'Please provide the number of cars parked in the garage'
        ),
      },
      {
        rule: TaskValidators.requiredField<CoveredLocationEntity>(
          // ,HomeownerFormModel
          'Please provide the estimated year and month of home purchase',
          'Draft',
          'Homeowner',
          'datePurchased',
          'estimatedYearPurchased'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Year built is required',
          'yearBuilt',
          'year-build'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Square footage is required',
          'squareFootage',
          'square-footage'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Number of stories is required',
          'numberOfStories',
          'number-stories'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Construction type is required',
          'constructionType',
          'construction-type'
        ),
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Exterior walls is required',
          'exteriorWalls',
          'exterior-wall-type-0',
          'Draft'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'At least one bathroom is required',
          'bathrooms',
          'add-bathroom',
          'Draft'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'At least one kitchen is required',
          'kitchens',
          'add-kitchen',
          'Draft'
        ),
        quoteStatus: 'Draft',
      },
      {
        rule: TaskValidators.requiredConstructionInfo(
          'Roof material is required',
          'roofMaterial',
          'roof-material'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<CoveredLocationEntity>(
          // HomeownerFormModel
          (entity) => entity.renovations === null,
          'Please complete renovations form',
          'Quoted',
          'Homeowner',
          'renovations',
          'renovations'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<CoveredLocationEntity>(
          // HomeownerFormModel
          (entity) =>
            Boolean(
              entity.hasOilTank &&
                (!entity.oilTank ||
                  !entity.oilTank.tankLocation ||
                  (entity.oilTank.tankLocation === 'OutdoorsBelowGround' &&
                    !entity.oilTank.isClosureFillCertified) ||
                  !entity.oilTank.tankMaterial ||
                  !entity.oilTank.yearTankInstalled)
            ),
          'Oil tank form is required',
          'Quoted',
          'Homeowner',
          'oilTank',
          'oil-tank'
        ),
        feature: 'requiredOilTank',
      },
      {
        rule: TaskValidators.requiredEarthquakeDetails(
          'Will earthquake coverage be added is required',
          'isEarthquakeCoverageExpected',
          'eqf-isEarthquakeCoverageExpected',
          'Draft'
        ),
        feature: 'earthquakeDetails',
      },
      {
        rule: TaskValidators.requiredEarthquakeDetails(
          'Earthquake masonry veneer covered is required',
          'isMasonryVeneerCoverageExpected',
          'eqf-isMasonryVeneerCoverageExpected',
          'Draft'
        ),
        feature: 'earthquakeDetails',
      },
      {
        rule: TaskValidators.requiredEarthquakeDetails(
          'Unrepaired structural earthquake damage is required',
          'isUnrepairedEQDamageExists',
          'eqf-isUnrepairedEQDamageExists',
          'Draft'
        ),
        feature: 'earthquakeDetails',
      },
      {
        rule: TaskValidators.requiredEarthquakeDetails(
          'Dwelling near slope is required',
          'isDwellingNearSlope',
          'eqf-isDwellingNearSlope',
          'Draft'
        ),
        feature: 'earthquakeDetails',
      },
      {
        rule: TaskValidators.requiredEarthquakeDetails(
          'Extensive overhangs exist is required',
          'doExtensiveOverhangsExist',
          'eqf-doExtensiveOverhangsExist',
          'Draft'
        ),
        feature: 'earthquakeDetails',
      },
    ] as RuleTemplate[],
  },
};
