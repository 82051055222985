import { LogService } from '@core/services/log.service';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  InjectionToken,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import '@nationwide-bolt/bundle-core';
import { environment } from '../environments/environment';
import {
  routerReducers,
  CustomSerializer,
  devModeMetaReducer,
  reducers as coreReducers,
} from '@core/store/reducers';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AppConfigService } from '@core/services/app-config.service';
import { CoreModule } from '@core/core.module';
import { Reducers } from '@core/constants/reducers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { reducers as formsReducers } from './forms-store/store/reducers';
import { FormsStoreModule } from '@forms-store/forms-store.module';
import { NotificationBarModule } from '@shared/components/notification-bar/notification-bar.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { ToasterModule } from '@shared/components/toaster/toaster.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { FooterComponent } from './containers/footer/footer.component';
import { HeaderComponent } from './containers/header/header.component';
import { DebugModule } from './debug/debug.module';
import { NavSidebarModule } from './nav-sidebar/nav-sidebar.module';
import { CompraterHeaderComponent } from './search/components/comprater-header/comprater-header.component';
import { PageAlertService } from '@core/services/page-alert.service';
import { LogEventName } from '@core/models/api/log.model';
import { SplashModule } from './splash/splash.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { AnnouncementOverlayComponent } from './announcement-overlay/announcement-overlay.component';
import { LeakDetectionModule } from '@property/components/leak-detection/leak-detection.module';
import { SpinnerInterceptor } from '@core/adapters/dsm.adapter';
import { UwReferralModalModule } from './hub/components/uw-referral-modal/uw-referral-modal.module';
import { DatepickerInputModule } from '@shared/components/datepicker-input/datepicker-input.module';

// import { IsAuthorizedDirective } from '@shared/directives/is-authorized.directive';

const AppConfigDeps = new InjectionToken<(() => any)[]>('configDeps');

const appInitializerFn = (
  appConfig: AppConfigService,
  configDeps: (() => any)[]
) => {
  return (): Promise<any> => {
    return appConfig.loadAppConfig().then(() => {
      return Promise.all(configDeps.map((dep) => dep()));
    });
  };
};

const pageAlertsInitializerFn = (pageAlertService: PageAlertService) => {
  return () => {
    pageAlertService.loadPageAlerts();
    pageAlertService.loadAnnouncementModalContent();
  };
};

const fullstorySessionInitializer = (
  logService: LogService,
  window: Window
) => {
  return () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)._fs_ready = () => {
      const FS = (window as any).FS;
      const fullStoryUrl = FS.getCurrentSessionURL?.();
      logService.logUiEvent('associate-fs-url' as LogEventName, {
        fullStoryUrl,
      });
    };
  };
};

export function appConfigDependencyFactory(
  pageAlertService: PageAlertService,
  logService: LogService,
  window: Window
) {
  // additional dependencies can be added here
  return [
    pageAlertsInitializerFn(pageAlertService),
    fullstorySessionInitializer(logService, window),
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    // IsAuthorizedDirective,
    FooterComponent,
    HeaderComponent,
    CompraterHeaderComponent,
    AnnouncementOverlayComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    StoreModule.forFeature(Reducers.Core, coreReducers, {
      // metaReducers: [devModeMetaReducer], // uncomment to enable reload store persistence
    }),
    StoreModule.forFeature(Reducers.Forms, formsReducers),
    StoreModule.forRoot(routerReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomSerializer,
    }),
    CoreModule,
    NgbModule,
    ToasterModule,
    NavSidebarModule,
    SharedDirectivesModule,
    NotificationBarModule,
    FormsStoreModule,
    SharedComponentsModule,
    DebugModule,
    SplashModule,
    MaintenanceModule,
    LeakDetectionModule,
    UwReferralModalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService, AppConfigDeps],
    },
    {
      provide: AppConfigDeps,
      useFactory: appConfigDependencyFactory,
      deps: [PageAlertService, LogService, Window],
    },
    { provide: Window, useValue: window },
    { provide: Document, useValue: document },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
})
export class AppModule {}
