import { Injectable } from "@angular/core";
import { ProductModel } from "@entities/product/product.model";
import { ProductsService } from "./products.service";
import { QuoteAdapter } from "@core/adapters/quote.adapter";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { firstValueFrom } from "rxjs";
import { SimpleConfirmationModalComponent } from "@shared/components/simple-confirmation-modal/simple-confirmation-modal.component";
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class DeleteProductService {
  constructor(
    private productsService: ProductsService,
    private quoteAdapter: QuoteAdapter,
    private ngbModal: NgbModal
  ) {}

  promptAndRemoveProduct(product: ProductModel): Promise<string> {
    return this.confirmationModal(
      `Remove ${product.name} policy`,
      `Submission ID# ${product.quoteId}`,
      'Do you want to remove this product from the quote for now? ' +
        'You can retrieve it later from the Retrieve quote screen.',
      'No',
      'Yes, remove it'
    ).then((result) => {
      if (result === 'confirm') {
        this.productsService.removeProduct(product);
      }
      return result;
    });
  }

  promptAndWithdrawProduct(product: ProductModel): Promise<string> {
    if (!product.quoteId) return Promise.resolve('cancel');
    return this.confirmationModal(
      `Delete ${product.name} policy`,
      `Submission ID# ${product.quoteId}`,
      "Do you want to delete this submission? It can't be undone.",
      'No',
      'Yes, delete it'
    ).then((result) => {
      if (result === 'confirm') {
        return firstValueFrom(
          this.quoteAdapter.deleteQuote(product.type, product.quoteId || '')
        )
          .catch((error) => {
            if (
              error?.error?.developerMessage?.includes(
                'not in pending or quoted status'
              )
            ) {
              return this.forceQuoteToDraftStatusThenWithdraw(
                product.type,
                product.quoteId || ''
              );
            }
            throw error;
          })
          .then(() => {
            this.productsService.removeProductAndAssociatedState(product);
            return 'confirm';
          })
          .catch(() => 'cancel');
      }
      return result;
    });
  }

  private forceQuoteToDraftStatusThenWithdraw(
    productType: ProductType,
    quoteId: string
  ): Promise<unknown> {
    return firstValueFrom(
      this.quoteAdapter.updateQuote({
        productType,
        quoteId,
        body: { productType },
      })
    ).then(() =>
      firstValueFrom(this.quoteAdapter.deleteQuote(productType, quoteId))
    );
  }

  private confirmationModal(
    title: string,
    subtitle: string,
    message: string,
    cancelText: string,
    submitText: string
  ): Promise<'confirm' | 'cancel'> {
    const modal = this.ngbModal.open(SimpleConfirmationModalComponent, {
      centered: true,
      size: 'md',
      ariaLabelledBy: 'confirmationModalTitle',
      windowClass: 'simple-confirm-modal-window',
      backdropClass: 'simple-confirm-modal-backdrop',
    });
    modal.componentInstance.headerExclamation = false;
    modal.componentInstance.headerTitle = title;
    modal.componentInstance.subtitle = subtitle;
    modal.componentInstance.bodyMessage = message;
    modal.componentInstance.cancelBtnText = cancelText;
    modal.componentInstance.forwardBtnText = submitText;
    modal.componentInstance.cancelButtonSlot = 'back';
    return modal.result.catch(() => 'cancel');
  }
}
