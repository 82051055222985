import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  UnderlyingPolicyActions,
} from '@core/store/actions';
import { UnderlyingPolicyEntity } from './underlying-policy.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';

export interface UnderlyingPolicyState
  extends EntityState<UnderlyingPolicyEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<UnderlyingPolicyEntity>;
}

export const adapter: EntityAdapter<UnderlyingPolicyEntity> =
  createEntityAdapter<UnderlyingPolicyEntity>({
    selectId: (underlyingPolicy: UnderlyingPolicyEntity) =>
      underlyingPolicy.underlyingPolicyId,
  });

function findAccompanyingPolicyToRemove(
  underlyingPoliciesDeleted: Dictionary<UnderlyingPolicyEntity>,
  payload: UnderlyingPolicyEntity
) {
  const accompanyingPolicyToRemove = Object.values(
    underlyingPoliciesDeleted
  ).find((policy) => {
    if (policy) {
      return policy.underlyingPolicyNumber === payload.underlyingPolicyNumber;
    }
    return null;
  });

  if (accompanyingPolicyToRemove) {
    delete underlyingPoliciesDeleted?.[
      accompanyingPolicyToRemove.underlyingPolicyId
    ];
  }
}

const underlyingPolicyReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as UnderlyingPolicyState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as UnderlyingPolicyState)
  ),
  on(SessionActions.nukeProduct, (state, { productType }) => {
    if (productType === 'PersonalUmbrella') {
      return adapter.getInitialState({
        error: null,
        deleted: {},
      } as UnderlyingPolicyState);
    }
    return state;
  }),
  on(
    UnderlyingPolicyActions.upsertUnderlyingPolicySuccess,
    (state, { payload }) => {
      const underlyingPolciiesDeleted = {
        ...state.deleted,
      };

      findAccompanyingPolicyToRemove(underlyingPolciiesDeleted, payload);

      return adapter.upsertOne(payload, {
        ...state,
        deleted: underlyingPolciiesDeleted,
      });
    }
  ),
  on(UnderlyingPolicyActions.deleteUnderlyingPolicy, (state, { payload }) =>
    adapter.upsertOne(payload, {
      ...state,
      deleted: {
        ...state.deleted,
        [payload.underlyingPolicyId]: payload,
      },
    })
  ),
  on(
    UnderlyingPolicyActions.deleteUnderlyingPolicySuccess,
    (state, { underlyingPolicyId }) =>
      adapter.removeOne(underlyingPolicyId, {
        ...state,
      })
  ),
  on(UnderlyingPolicyActions.clearDeletedUnderlyingPolicies, (state) => {
    return {
      ...state,
      deleted: {},
    };
  }),
  on(
    UnderlyingPolicyActions.removeDeletedAccompanyingPolicy,
    (state, { underlyingPolicyId }) => {
      const underlyingPoliciesDeleted = {
        ...state.deleted,
      };
      delete underlyingPoliciesDeleted?.[underlyingPolicyId];
      return {
        ...state,
        deleted: underlyingPoliciesDeleted,
      };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.underlyingPolicies, {
      ...state,
    });
  })
);

export function reducer(
  state: UnderlyingPolicyState | undefined,
  action: Action
): UnderlyingPolicyState {
  return underlyingPolicyReducer(state, action);
}
