import {
  CurrentCarrierCoverageLapse,
  CurrentCarrierLapseReasons,
  MsaCurrentCarrierNames,
} from '@core/models/api/dsm-types';
import { PicklistItem } from '../models/picklist.model';
import { StateUtils } from '../utils/state.util';

export interface FilterablePicklistItem {
  key: string;
  value: string;
  states?: string[];
  effectiveDate?: { [key: string]: string };
  expirationDate?: { [key: string]: string };
}

export function allStatesExcept(...notStates: string[]): string[] {
  return Object.keys(StateUtils.stateCodeByState).filter(
    (state) => !notStates.includes(state)
  );
}

export const currentCarrierNames: FilterablePicklistItem[] = [
  { key: 'NoPrevInsCarrierwoReason', value: 'NO PREVIOUS INSURANCE CARRIER' },
  { key: 'NoPrevInsCarrwReason', value: 'NO PRIOR INSURANCE WITH REASON' },
  { key: 'Other', value: 'OTHER' },
  { key: '21stCentury', value: '21ST CENTURY' },
  { key: 'AAA', value: 'AUTOMOBILE CLUB' },
  { key: 'AID', value: 'AID' },
  { key: 'AIGCompanies', value: 'AIG' },
  { key: 'AIG_National_Ins', value: 'AIG NATIONAL INSURANCE CO' },
  { key: 'ALFAInsCorp', value: 'ALFA INSURANCE CORP' },
  { key: 'ALFAInsurance', value: 'ALFA INSURANCE' },
  { key: 'AMCO', value: 'AMCO' },
  { key: 'AcuityAMutual', value: 'ACUITY A MUTUAL' },
  { key: 'Aetna', value: 'AETNA' },
  { key: 'Affirmative', value: 'AFFIRMATIVE' },
  { key: 'AllOtherSelectRisk', value: 'A/O SELECT RISK' },
  { key: 'AllOtherSubstandard', value: 'A/O SUB-STD.' },
  { key: 'Alliance', value: 'ALLIANCE' },
  { key: 'Allied', value: 'ALLIED' },
  { key: 'Allstate', value: 'ALLSTATE' },
  { key: 'AllstateIdemnity', value: 'ALLSTATE INDEMNITY' },
  { key: 'Ambassador', value: 'AMBASSADOR' },
  { key: 'AmericanFamily', value: 'AMERICAN FAMILY' },
  { key: 'AmericanModernHome', value: 'AMERICAN MODERN HOME' },
  { key: 'AmericanMutal', value: 'AMERICAN MUTUAL' },
  { key: 'AmericanNational', value: 'AMERICAN NATIONAL' },
  { key: 'Ameriprise', value: 'AMERIPRISE' },
  { key: 'AmicaMutual', value: 'AMICA MUTUAL' },
  { key: 'Armco', value: 'ARMCO' },
  { key: 'AtlantaCasualty', value: 'ATLANTA CASUALTY' },
  { key: 'AustinMutalInsCo', value: 'AUSTIN MUTUAL INS CO' },
  { key: 'AutoClubOfNCalifornia', value: 'AUTO CLUB NORTHERN CAL.' },
  { key: 'AutoClubOfSCalifornia', value: 'AUTO CLUB SOUTHERN CAL.' },
  { key: 'AutoOwners', value: 'AUTO OWNERS' },
  { key: 'Automax', value: 'AUTO MAX' },
  { key: 'Badger', value: 'BADGER' },
  { key: 'Bankers', value: 'BANKERS' },
  { key: 'BearRiverMutal', value: 'BEAR RIVER MUTUAL' },
  { key: 'BenFranklin', value: 'BEN FRANKLIN' },
  { key: 'Bonneville', value: 'BONNEVILLE' },
  { key: 'BristolWest', value: 'BRISTOL WEST' },
  { key: 'BuckeyeUnion', value: 'BUCKEYE UNION' },
  { key: 'CCM', value: 'COLONIAL COUNTY MUTUAL' },
  { key: 'COCasualty', value: 'COLORADO CASUALTY' },
  { key: 'Cadillac', value: 'CADILLAC' },
  { key: 'CaliforniaCasualty', value: 'CALIFORNIA CASUALTY' },
  { key: 'CelinaMutual', value: 'CELINA MUTUAL' },
  { key: 'CentralMutual', value: 'CENTRAL MUTUAL' },
  { key: 'ChubbInsurance', value: 'CHUBB' },
  { key: 'CincinnattiInsCo', value: 'CINCINNATI INSURANCE' },
  { key: 'Citizens', value: 'CITIZENS' },
  { key: 'CnaCasualtyOfCA', value: 'CNA' },
  { key: 'ColonialPenn', value: 'COLONIAL PENN' },
  { key: 'Commercial', value: 'COMMERCIAL UNION' },
  { key: 'ConcordGroup', value: 'CONCORD GR.' },
  { key: 'Continental', value: 'CONTINENTAL' },
  { key: 'Coronet', value: 'CORONET' },
  { key: 'Cosmopolitan', value: 'COSMOPOLITAN' },
  { key: 'CottonStates', value: 'COTTON STATES' },
  { key: 'CountryCasualty', value: 'COUNTRY CASUALTY INSURANCE CO' },
  { key: 'CountryMutual', value: 'COUNTRY MUTUAL INSURANCE CO' },
  { key: 'CountryWide', value: 'COUNTRY-WIDE' },
  { key: 'Criterion', value: 'CRITERION' },
  { key: 'CrumForester', value: 'CRUM & FORESTER' },
  { key: 'Cumis', value: 'CUMIS' },
  { key: 'Dairyland', value: 'DAIRYLAND' },
  { key: 'Depositors', value: 'DEPOSITORS' },
  { key: 'Discover', value: 'DISCOVER COMPANY' },
  { key: 'Dixie', value: 'DIXIE' },
  { key: 'DonelGroup', value: 'DONEL GROUP' },
  { key: 'ESURANCE', value: 'MUTUAL OF ENUMCLAW' },
  { key: 'Economy', value: 'ECONOMY' },
  { key: 'Educators', value: 'EDUCATORS' },
  { key: 'Electric_Ins', value: 'ELECTRIC INS' },
  { key: 'EmployersMutualCasualty', value: 'EMPLOYERS MUTUAL' },
  { key: 'Encompass', value: 'ENCOMPASS' },
  { key: 'Erie', value: 'ERIE' },
  { key: 'EsuranceInsCo', value: 'ESURANCE' },
  { key: 'FarmBureauInsCo', value: 'FARM BUREAU INS. COMPANIES' },
  { key: 'FarmFamilyIns', value: 'FARM FAMILY INSURANCE COMPANY' },
  { key: 'Farmers', value: 'FARMERS INSURANCE CO' },
  { key: 'FarmersAutoInsAssoc', value: 'FARMERS AUTOMOBILE INS ASSOC' },
  { key: 'FarmersMutualOfNE', value: 'FARMERS MUTUAL OF NEBRASKA' },
  { key: 'FederalKemper', value: 'FEDERAL KEMPER' },
  { key: 'FiremansFund', value: 'FIREMANS FUND' },
  { key: 'Foremost', value: 'FOREMOST' },
  { key: 'GMACBrokerage', value: 'GMAC BROKERAGE' },
  { key: 'GMACDirect', value: 'GMAC DIRECT INSURANCE CO' },
  { key: 'GMACOnlineInc', value: 'GMAC INSURANCE CO ONLINE INC' },
  { key: 'Geico', value: 'GEICO' },
  { key: 'GeicoCasualty', value: 'GEICO CASUALTY' },
  { key: 'GeneralCasualtyCoOfIL', value: 'GENERAL CASUALTY CO OF IL' },
  { key: 'GlobeAmerican', value: 'GLOBE AMERICAN' },
  { key: 'Grange', value: 'GRANGE MUTUAL CAS CO' },
  { key: 'GrangeMutual', value: 'GRANGE MUTUAL INS CO' },
  { key: 'GreatAmerican', value: 'GREAT AMERICAN' },
  { key: 'GrinnellMutual', value: 'GRINNELL MUTUAL' },
  { key: 'Hanover', value: 'HANOVER' },
  { key: 'HanoverInsCo', value: 'IMT' },
  { key: 'Harleysville', value: 'HARLEYSVILLE' },
  { key: 'Hartford', value: 'HARTFORD INS CO' },
  { key: 'HastingsMutual', value: 'HASTINGS MUTUAL' },
  { key: 'HawkeyeSecurity', value: 'HAWKEYE SECURITY' },
  { key: 'Home', value: 'HOME' },
  { key: 'HorraceMann', value: 'HORACE MANN' },
  { key: 'INA', value: 'INA' },
  { key: 'IndianaFarmersMutual', value: 'INDIANA FARMERS MUTUAL' },
  { key: 'Indiana_Ins', value: 'INDIANA INS.' },
  { key: 'Integon', value: 'INTEGON' },
  { key: 'IowaMutual', value: 'IOWA MUTUAL' },
  { key: 'IowaNational', value: 'IOWA NATIONAL' },
  { key: 'JCPenny', value: 'J.C. PENNEY' },
  { key: 'Kemper', value: 'KEMPER' },
  { key: 'Keystone', value: 'KEYSTONE' },
  { key: 'LeaderNational', value: 'LEADER NATIONAL' },
  { key: 'LibertyMutual', value: 'LIBERTY MUTUAL' },
  { key: 'Lumbermans', value: 'LUMBERMANS' },
  { key: 'MDAutoInsuranceFund', value: 'MAIF' },
  { key: 'MDCasualty', value: 'MARYLAND CASUALTY' },
  { key: 'MEBonding', value: 'MAINE BONDING' },
  { key: 'MIC', value: 'MOTORS INSURANCE CORP' },
  { key: 'MIMutual', value: 'MICHIGAN MUTUAL' },
  { key: 'MembersMutual', value: 'MEMBERS MUTUAL' },
  { key: 'Merchants', value: 'MERCHANTS' },
  { key: 'Mercury', value: 'MERCURY INSURANCE COMPANY' },
  { key: 'Meridian', value: 'MERIDIAN' },
  { key: 'Metropolitan', value: 'METROPOLITAN' },
  { key: 'MetropolitanCasualty', value: 'METROPOLITAN CASUALTY' },
  { key: 'MetropolitanGrpPropAndCas', value: 'METROPOLITAN GROUP P&C' },
  { key: 'MetropolitanPropertyAndCas', value: 'METROPOLITAN P&C' },
  { key: 'MidCenturyCA', value: 'MID-CENTURY INSURANCE CO (CA)' },
  { key: 'MilbankMutual', value: 'MILBANK MUTUAL' },
  { key: 'MontgomeryMutual', value: 'MONTGOMERY MUTUAL' },
  { key: 'MotoristMutual', value: 'MOTORIST MUTUAL' },
  { key: 'NAICOA', value: 'NW AFFINITY INS CO OF AMERICA' },
  { key: 'NGIContinent', value: 'NGI' },
  { key: 'NICOA', value: 'NATIONWIDE INS. CO. OF AMERICA' },
  { key: 'NICOF', value: 'NATIONWIDE INS OF FL' },
  { key: 'NMFIC', value: 'NATIONWIDE MUTUAL FIRE' },
  { key: 'NPCC', value: 'NATIONWIDE PRP & CAS' },
  { key: 'Nationwide', value: 'NATIONWIDE' },
  { key: 'NationwideAgribusiness', value: 'NATIONWIDE AGRIBUSINESS' },
  { key: 'NationwideAssurance', value: 'NATIONWIDE ASSURANCE' },
  { key: 'NationwideCPMNSA', value: 'NW CPM NSA' },
  { key: 'NewlyPurchasedVehicle', value: 'NEWLY PURCHASED VEHICLE' },
  { key: 'NoPrevInsCarrierwoReason', value: 'NO PREVIOUS INSURANCE CARRIER' },
  { key: 'NoPrevInsCarrwReason', value: 'NO PREV INSURANCE WITH REASON' },
  { key: 'NodakMutual', value: 'NODAK MUTUAL' },
  { key: 'NorthPacific', value: 'NORTH PACIFIC' },
  { key: 'NorthStarCompanies', value: 'NORTH STAR COMPANIES' },
  { key: 'NorthWest', value: 'NORTH-WEST' },
  { key: 'OHCasualty', value: 'OHIO CASUALTY' },
  { key: 'OHFarmers', value: 'OHIO FARMERS' },
  { key: 'ORMutual', value: 'OREGON MUTUAL' },
  { key: 'OhioMutual', value: 'OHIO MUTUAL' },
  { key: 'Old_Dominion', value: 'OLD DOMINION' },
  { key: 'Other', value: 'OTHER' },
  { key: 'PEMCO', value: 'PEMCO' },
  { key: 'Peerless', value: 'PEERLESS' },
  { key: 'Pekin', value: 'PEKINS' },
  { key: 'PennGeneral', value: 'PENN. GENERAL' },
  {
    key: 'PermanentGeneralAssuranceCorpTN',
    value: 'PERMANENT GEN ASSUR CORP(TN)',
  },
  { key: 'PioneerStateMutual', value: 'PIONEER STATE MUTUAL' },
  { key: 'Progressive', value: 'PROGRESSIVE' },
  { key: 'ProgressiveBrokerageRolls', value: 'PROGRESSIVE BROKERAGE ROLLS' },
  { key: 'ProgressiveCasualty', value: 'PROGRESSIVE CASUALTY' },
  { key: 'ProgressiveHalycon', value: 'PROGRESSIVE HALCYON' },
  { key: 'ProgressiveMax', value: 'PROGRESSIVE MAX' },
  { key: 'ProgressiveNS', value: 'PROGRESSIVE N/S' },
  { key: 'ProgressiveNorthern', value: 'PROGRESSIVE NORTHERN' },
  { key: 'Prudential', value: 'PRUDENTIAL' },
  { key: 'Reliance', value: 'RELIANCE' },
  { key: 'Republic', value: 'REPUBLIC' },
  { key: 'RoyalGlobe', value: 'ROYAL GLOBE' },
  { key: 'SECURA', value: 'SECURA' },
  { key: 'Safeco', value: 'SAFECO' },
  { key: 'SelectiveInsuranceCompanyOfNE', value: 'SELECTIVE INS CO OF NE' },
  { key: 'Sentry', value: 'SENTRY' },
  { key: 'Sheboygan', value: 'SHEBOYGAN' },
  { key: 'ShelbyMutual', value: 'SHELBY MUTUAL' },
  { key: 'Shelter', value: 'SHELTER' },
  { key: 'ShieldOfShelter', value: 'SHIELD OF SHELTER' },
  { key: 'SouthernCarolina', value: 'SOUTHERN CAROLINA INS.' },
  { key: 'StPaul', value: 'ST. PAUL' },
  { key: 'StateAuto', value: 'STATE AUTO' },
  { key: 'StateFarm', value: 'STATE FARM INSURANCE COMPANIES' },
  { key: 'StateFarmFireandCasualty', value: 'STATE FARM FIRE AND CASUALTY' },
  { key: 'Stonewall', value: 'QUALITY INSURANCE' },
  { key: 'TakeOutAIP', value: 'AIP' },
  { key: 'Teledyne', value: 'TELEDYNE' },
  { key: 'TennesseeFarmers', value: 'TENNESSEE FARMERS' },
  { key: 'TexasFarmBureauCasualtyCo', value: 'TEXAS FARM BUREAU CASUALTY CO' },
  { key: 'Titan', value: 'TITAN' },
  { key: 'TransAmerica', value: 'TRANS AMERICA' },
  { key: 'Travelers', value: 'TRAVELERS' },
  { key: 'TravelersCountyMutual', value: 'TRAVELERS (COUNTY MUTUAL)' },
  { key: 'TravelersPropertyCasualty', value: 'TRAVELERS P&C' },
  { key: 'USAA', value: 'USAA' },
  { key: 'USAAGeneral_Indemnity', value: 'USAA GENERAL INDEMNITY' },
  { key: 'USFandG', value: 'USF&G' },
  { key: 'Unigard', value: 'UNIGARD' },
  { key: 'UnitedPacific', value: 'UNITED PACIFIC' },
  { key: 'UnitrinInsurance', value: 'UNITRIN' },
  { key: 'Unknown998', value: 'UNKNOWN' },
  { key: 'Viking', value: 'VIKING' },
  { key: 'WIMutual', value: 'WISCONSIN MUTUAL' },
  { key: 'WestAmerica', value: 'WEST AMERICA' },
  { key: 'WestBendMutual', value: 'WEST BEND MUTUAL' },
  { key: 'Western', value: 'WESTERN' },
  { key: 'WesternReserveMutualCasualty', value: 'WESTERN RESERVE MUTUAL' },
  { key: 'Westfield', value: 'WESTFIELD' },
  { key: 'WinterthruSwiss', value: 'WINTERTHRU SWISS' },
  { key: 'WolverineMutual', value: 'WOLVERINE MUTUAL' },
];

export const currentCarrierBiLimitDisplayNames: FilterablePicklistItem[] = [
  {
    key: 'PIPandPDOnly',
    value: 'Equal to 0 (No PRI Code or BI)',
    states: ['FL'],
  },
  {
    key: 'LE10_20StateMin',
    value: 'Less than or equal to 10/20',
    states: ['FL'],
  },
  {
    key: 'LE25_50',
    value: 'Less than or equal to 25/50 (CSL <50)',
    states: allStatesExcept('FL', 'MD', 'ME', 'MN', 'NC', 'TX', 'UT', 'VA'),
  },
  {
    key: 'LE30_65',
    value: 'Less than or equal to 30/65 (CSL <100)',
    states: ['UT'],
  },
  {
    key: 'LE30_60',
    value: 'Less than or equal to 30/60 (CSL <100)',
    states: ['MD', 'MN', 'NC', 'TX', 'VA'],
    expirationDate: { NC: '2025-07-01', VA: '2025-01-01' },
  },
  {
    key: 'LE50_100MaineOnly',
    value: 'Less than or equal to 50/100 (CSL <50)',
    states: ['ME', 'NC', 'VA'],
    effectiveDate: { NC: '2025-07-01', VA: '2025-01-01' },
  },
  { key: '25_50', value: 'Equal to 25/50', states: ['FL'] },
  {
    key: 'LT100_300GT50_100',
    value:
      'Greater than or equal to 50/100, less than 100/300 (CSL >=100, but <250)',
    states: ['NC', 'VA'],
    effectiveDate: { NC: '2025-07-01', VA: '2025-01-01' },
  },
  {
    key: 'LT100_300GT25_50',
    value: 'Greater than 25/50, less than 100/300 (CSL >=50, but <250)',
    states: allStatesExcept('MD', 'ME', 'MN', 'NC', 'TX', 'UT', 'VA'),
  },
  {
    key: 'LT100_300GT30_65',
    value: 'Greater than 30/65, less than 100/300 (CSL >=100, but <250)',
    states: ['UT'],
  },
  {
    key: 'LT100_300GT30_60',
    value: 'Greater than 30/60, less than 100/300 (CSL >=100, but <250)',
    states: ['MD', 'MN', 'NC', 'TX', 'VA'],
    expirationDate: { NC: '2025-07-01', VA: '2025-01-01' },
  },
  {
    key: 'LT250_500GT50_100',
    value: 'Greater than 50/100, less than 250/500 (CSL >=50, but <250)',
    states: ['ME'],
  },
  {
    key: 'LT250_500GE100_300',
    value:
      'Greater than or equal to 100/300 (CSL >=250), less than 250/500 (CSL <500)',
    states: ['IA', 'OH', 'TN', 'TX'],
    effectiveDate: { IA: '2025-04-20', OH: '2025-03-22', TN: '2025-04-22', TX: '2025-01-01' },
  },
  {
    key: 'LT50_100GT25_50',
    value: 'Greater than 25/50, less than 50/100 (CSL >=50, but <100)',
    states: [],
  },
  {
    key: 'GE100_300',
    value: 'Greater than or equal to 100/300 (CSL >=250)',
    states: allStatesExcept('ME'),
    expirationDate: { IA: '2025-04-20', OH: '2025-03-22', TN: '2025-04-22', TX: '2025-01-01' },
  },
  {
    key: 'GE250_500',
    value: 'Greater than or equal to 250/500 (CSL >=500)',
    states: ['IA', 'ME', 'OH', 'TN', 'TX'],
    effectiveDate: { IA: '2025-04-20', OH: '2025-03-22', TN: '2025-04-22', TX: '2025-01-01' },
  },
];

export const currentCarrierLapseInCoverage: FilterablePicklistItem[] = [
  {
    key: CurrentCarrierCoverageLapse.ZeroDays,
    value: '0 days',
  },
  {
    key: CurrentCarrierCoverageLapse.ThirtyDays,
    value: '1-30 days',
  },
  {
    key: CurrentCarrierCoverageLapse.OverThirtyDays,
    value: 'Over 30 days',
  },
  {
    key: CurrentCarrierCoverageLapse.NoNeedForPriorInsurance,
    value: 'No need for prior insurance',
  },
];

export const msaCurrentCarrierLapseInCoverage: FilterablePicklistItem[] = [
  {
    key: CurrentCarrierCoverageLapse.ZeroDaysPrior6Months,
    value: '0 days lapse in prior 6 months',
  },
  {
    key: CurrentCarrierCoverageLapse.ThirtyDays,
    value: '1-30 days',
  },
  {
    key: CurrentCarrierCoverageLapse.OverThirtyDays,
    value: 'Over 30 days',
  },
  {
    key: CurrentCarrierCoverageLapse.NoPriorInsurance,
    value: 'No Need for Prior Insurance',
  },
];

export const msaCurrentCarrierNames: FilterablePicklistItem[] = [
  {
    key: MsaCurrentCarrierNames.AllOther,
    value: 'All Other',
  },
  {
    key: MsaCurrentCarrierNames.Nationwide,
    value: 'Nationwide',
  },
  {
    key: MsaCurrentCarrierNames.NoPrevInsurance,
    value: 'No Previous Insurance',
  },
];

export const currentCarrierLapseReasons: FilterablePicklistItem[] = [
  {
    key: CurrentCarrierLapseReasons.VehicleNewlyPurchased,
    value: 'Did not own vehicle/newly purchased',
  },
  {
    key: CurrentCarrierLapseReasons.VehicleWithoutInsurance,
    value: 'Owned/operated vehicle without insurance',
  },
  {
    key: CurrentCarrierLapseReasons.VehicleNotDriven,
    value: 'Owned vehicle but not moved/driven in last 6 months',
  },
  {
    key: CurrentCarrierLapseReasons.OverseasForMilitary,
    value: 'Overseas for military',
  },
];
export class CurrentCarrierMetadataService {
  static getCarriers(state: string): PicklistItem[] {
    return currentCarrierNames
      .filter((input) => !input.states || input.states.includes(state))
      .map(
        (input) =>
        ({
          displayName: input.key,
          value: input.value,
        } as PicklistItem)
      );
  }

  static getDisplayName(value: string): string {
    return currentCarrierNames.find((o) => o.key === value)?.value || '';
  }

  static getBiLimits(
    state: string,
    productEffectiveDate: string
  ): FilterablePicklistItem[] {
    return currentCarrierBiLimitDisplayNames
      .filter((input) => {
        const effDate = input.effectiveDate ? input.effectiveDate[state] : '';
        const expDate = input.expirationDate ? input.expirationDate[state] : '';
        return (
          (!input.states || input.states.includes(state)) &&
          (!effDate || productEffectiveDate >= effDate) &&
          (!expDate || productEffectiveDate < expDate)
        );
      })
      .map((input) => ({
        key: input.key,
        value: input.value,
      }));
  }

  static getDisplayLimits(value: string): string {
    return (
      currentCarrierBiLimitDisplayNames.find((o) => o.key === value)?.value ||
      ''
    );
  }
}
