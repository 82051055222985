import { ProductModel } from '@core/store/entities/product/product.model';
import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoveredLocationRequest } from '@core/models/api/request/covered-location-request.model';
import {
  CoveredLocationEntity,
  CoveredLocationProtectiveDevices,
} from '@core/store/entities/covered-location/covered-location.reducer';
import { ObjectValidator } from '@core/helper/object-validator';
import {
  ConstructionInfoModel,
  OilTankModel,
  RiskItem,
} from '@entities/covered-location/covered-location.model';

@Injectable({
  providedIn: 'root',
})
export class CoveredLocationAdapter extends DsmAdapter {
  getCoveredLocation(product: ProductModel): Observable<CoveredLocationEntity> {
    return this.request(
      sanitizeCoveredLocation,
      product.type,
      'GET',
      `/quotes/${product?.quoteId}/covered-locations/primary`,
      'get-covered-location'
    );
  }

  updateCoveredLocation(
    request: CoveredLocationRequest
  ): Observable<CoveredLocationEntity> {
    const { coveredLocation, product } = request;
    return this.request(
      sanitizeCoveredLocation,
      product.type,
      'PATCH',
      `/quotes/${product.quoteId}/covered-locations/primary`,
      'update-covered-location',
      {
        body: { coveredLocation },
      }
    );
  }
}

export function sanitizeCoveredLocation(
  response: unknown
): CoveredLocationEntity {
  return ObjectValidator.forceSchema<CoveredLocationEntity>(
    response,
    {
      productType: 'any', // not a real response field
      entityId: 'any', // not a real response field
      associatedMSBEstimateNumber: 'string',
      businessOnPremises: 'boolean',
      constructionInfo: sanitizeConstructionInfo,
      currentConstruction: 'boolean',
      currentRemodelingOrRehabiliation: 'boolean',
      datePurchased: 'string',
      deededOwner: 'boolean',
      electricWiring: {
        fuseBox: 'boolean',
        circuitBreaker: 'boolean',
        aluminumWire: 'boolean',
        knobTube: 'boolean',
      },
      eligibleProperty: 'boolean',
      flatRoof: 'boolean',
      gatedCommunity: 'boolean',
      hasHydrantIn1000Ft: 'boolean',
      hasOilTank: 'boolean',
      historicDistrict: 'boolean',
      isInCurrLocForThreeYrs: 'boolean',
      isPersonalPropertyLimitSameAsPreviousPolicy: 'boolean',
      isShortSaleAuctForeclose: 'boolean',
      location: {
        addressId: 'number',
        addressLine1: 'string',
        city: 'string',
        state: 'string',
        postalCode: 'string',
      },
      modularHome: 'boolean',
      nonResidentialToResidential: 'boolean',
      numberOfAmps: 'string',
      numberOfFamilies: 'number',
      numberOfOccupants: 'number',
      occupancy: 'string',
      oilTank: sanitizeOilTank,
      protectiveDevices: sanitizeProtectiveDevices,
      rcelastUpdateDate: 'string',
      reconstructionCost: 'number',
      registeredHistoric: 'boolean',
      renovations: [
        {
          renovationId: 'number',
          type: 'string',
          year: 'number',
        },
      ],
      riskItems: sanitizeRiskItems,
      roofCondition: 'string',
      roofUlType: 'string',
      seasonal: 'boolean',
      secondaryHome: 'boolean',
      thermostat: 'boolean',
      townhouse: 'boolean',
      unitsInBuilding: 'number',
      unitsBetweenFirewalls: 'number',
      warningMessages: 'any',
      wasReconstructionCostUpdated: 'boolean',
      weeksRented: 'string',
    },
    [
      'electricWiring',
      'associatedMSBEstimateNumber',
      'businessOnPremises',
      'constructionInfo',
      'currentConstruction',
      'currentRemodelingOrRehabiliation',
      'datePurchased',
      'deededOwner',
      'electricWiring',
      'eligibleProperty',
      'flatRoof',
      'gatedCommunity',
      'hasHydrantIn1000Ft',
      'hasOilTank',
      'historicDistrict',
      'isInCurrLocForThreeYrs',
      'isPersonalPropertyLimitSameAsPreviousPolicy',
      'isShortSaleAuctForeclose',
      'location',
      'modularHome',
      'nonResidentialToResidential',
      'numberOfAmps',
      'numberOfFamilies',
      'numberOfOccupants',
      'occupancy',
      'oilTank',
      'protectiveDevices',
      'rcelastUpdateDate',
      'reconstructionCost',
      'registeredHistoric',
      'renovations',
      'riskItems',
      'roofCondition',
      'roofUlType',
      'seasonal',
      'secondaryHome',
      'thermostat',
      'townhouse',
      'unitsInBuilding',
      'unitsBetweenFirewalls',
      'warningMessages',
      'wasReconstructionCostUpdated',
      'weeksRented',
    ]
  );
}

export function sanitizeConstructionInfo(
  response: unknown
): ConstructionInfoModel {
  return ObjectValidator.forceSchema<ConstructionInfoModel>(
    response,
    {
      additionalFoundationType: {
        concreteMasonry: 'boolean',
        dirt: 'boolean',
        landfill: 'boolean',
        open: 'boolean',
        rock: 'boolean',
        sand: 'boolean',
      },
      additionalHeating: 'string',
      bathrooms: [
        {
          bathroomType: 'string',
          bathroomQuality: 'string',
          bathroomTypeCount: 'number',
        },
      ],
      ceilings: [
        {
          feature: 'string',
          percentage: 'number',
        },
      ],
      centralCoolingSystem: 'string',
      constructionType: 'string',
      exteriorWalls: [
        {
          type: 'string',
          percentage: 'number',
        },
      ],
      flatRoof: 'boolean',
      flooring: [
        {
          feature: 'string',
          percentage: 'number',
        },
      ],
      foundationType: {
        basement: 'boolean',
        crawlSpace: 'boolean',
        daylightWalkoutBasement: 'boolean',
        piersRaised: 'boolean',
        slab: 'boolean',
        suspendedOverHillside: 'boolean',
      },
      foundationTypeSelected: 'string',
      fullBathroomDescription: 'string',
      garageType: 'string',
      garages: [
        {
          garageType: 'string',
          numberOfCars: 'string',
          squareFeet: 'number',
          garageId: 'string',
          garageTypeCount: 'number',
        },
      ],
      halfBathroomDescription: 'string',
      heating: 'string',
      kitchens: [
        {
          kitchenQuality: 'string',
          kitchenTypeCount: 'number',
        },
      ],
      kitchenDescription: 'string',
      numberOfBathrooms: 'string',
      numberOfFullBathrooms: 'number',
      numberOfHalfBathrooms: 'number',
      numberOfKitchens: 'number',
      numberOfStories: 'string',
      otherStructures: {
        breezeway: 'boolean',
        openPorch: 'boolean',
        screenPorch: 'boolean',
        sunSolarRoom: 'boolean',
        woodDeck: 'boolean',
      },
      parkingStructureCapacity: 'string',
      percentFinishedBasement: 'number',
      roofMaterial: 'string',
      roofShape: [
        {
          type: 'string',
          percentage: 'number',
        },
      ],
      siteAccess: 'string',
      squareFootage: 'number',
      wallHeights: [
        {
          height: 'number',
          percentage: 'number',
        },
      ],
      walls: [
        {
          feature: 'string',
          percentage: 'number',
        },
      ],
      yearBuilt: 'number',
    },
    [
      'additionalFoundationType',
      'additionalHeating',
      'bathrooms',
      'ceilings',
      'centralCoolingSystem',
      'constructionType',
      'exteriorWalls',
      'flatRoof',
      'flooring',
      'foundationType',
      'foundationTypeSelected',
      'fullBathroomDescription',
      'garageType',
      'garages',
      'halfBathroomDescription',
      'heating',
      'kitchenDescription',
      'kitchens',
      'numberOfBathrooms',
      'numberOfFullBathrooms',
      'numberOfHalfBathrooms',
      'numberOfKitchens',
      'numberOfStories',
      'otherStructures',
      'parkingStructureCapacity',
      'percentFinishedBasement',
      'roofMaterial',
      'roofShape',
      'siteAccess',
      'squareFootage',
      'wallHeights',
      'walls',
      'yearBuilt',
    ]
  );
}

export function sanitizeOilTank(response: unknown): OilTankModel | undefined {
  if (!response) {
    return undefined;
  }
  return ObjectValidator.forceSchema<OilTankModel>(response, {
    isClosureFillCertified: 'boolean',
    tankLocation: 'string',
    tankMaterial: 'string',
    yearTankInstalled: 'number',
  });
}

export function sanitizeProtectiveDevices(
  response: unknown
): CoveredLocationProtectiveDevices | undefined {
  if (!response) {
    return undefined;
  }
  return ObjectValidator.forceSchema<CoveredLocationProtectiveDevices>(
    response,
    {
      sprinklerSystem: 'string',
      burglarAlarm: 'string',
      fireOrSmokeAlarm: 'string',
      hasWroughtIronBar: 'boolean',
      windProtectiveDevices: ['string'],
      windMitigations: [
        {
          category: 'string',
          selectedType: 'string',
          availableOptions: [
            {
              description: 'string',
              type: 'string',
            },
          ],
        },
      ],
      hasSecondaryWaterResistance: 'boolean',
      fireProtectiveDevice: 'string',
    },
    [
      'sprinklerSystem',
      'burglarAlarm',
      'fireOrSmokeAlarm',
      'hasWroughtIronBar',
      'windProtectiveDevices',
      'windMitigations',
      'hasSecondaryWaterResistance',
      'fireProtectiveDevice',
    ]
  );
}

export function sanitizeRiskItems(response: unknown): RiskItem | undefined {
  if (!response) {
    return undefined;
  }
  return ObjectValidator.forceSchema<RiskItem>(
    response,
    {
      animalThatCausedInjury: 'boolean',
      dangerousOrExoticAnimal: 'boolean',
      haveDogs: 'boolean',
      dogs: [
        {
          dogBreed: 'string',
          biteHistory: 'boolean',
        },
      ],
      incidentalFarm: 'boolean',
      swimmingPool: 'boolean',
      poolFence: 'boolean',
      selfLatchingGate: 'boolean',
      poolSlide: 'boolean',
      waterDepthUnderSlide: 'number',
      divingBoard: 'boolean',
      waterDepthUnderDivingBoard: 'number',
      poolLadder: 'boolean',
      removableLadder: 'boolean',
      swimmingLessonsProvided: 'boolean',
      typeOfSwimmingPool: 'string',
      distanceToNonOwnedBuildingPool: 'string',
      trampoline: 'boolean',
      trampolineFence: 'boolean',
      trampolineTieDowns: 'boolean',
      trampolineNet: 'boolean',
      distanceToNonOwnedBuildingTrampoline: 'string',
    },
    [
      'animalThatCausedInjury',
      'dangerousOrExoticAnimal',
      'haveDogs',
      'dogs',
      'incidentalFarm',
      'swimmingPool',
      'poolFence',
      'selfLatchingGate',
      'poolSlide',
      'waterDepthUnderSlide',
      'divingBoard',
      'waterDepthUnderDivingBoard',
      'poolLadder',
      'removableLadder',
      'swimmingLessonsProvided',
      'typeOfSwimmingPool',
      'distanceToNonOwnedBuildingPool',
      'trampoline',
      'trampolineFence',
      'trampolineTieDowns',
      'trampolineNet',
      'distanceToNonOwnedBuildingTrampoline',
    ]
  );
}
