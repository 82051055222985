import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as coveredLocationReducer from '../covered-location/covered-location.reducer';
import { CoveredLocationEntity } from './covered-location.reducer';
import { CommonType, ConstructionInfoModel } from './covered-location.model';
import { getProduct } from '../product/product.selectors';
import { getAddressForProduct } from '../address/address.selector';
import { PropertyHelper } from '@core/helper/property.helper';
import { ProductType } from '@core/models/api/dsm-types';
import { CoveredLocationRequest } from '@core/models/api/request/covered-location-request.model';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { getPropertyMetadata } from '../metadata/metadata.selector';
import { PropertyOptionsModel } from '../metadata/models/property-options.model';
import { getPolicyLineModifier } from '@entities/eligible-discounts/eligible-discounts.selector';
import { getProtectiveDevicesForProduct } from '@entities/protective-devices/protective-devices.selector';
import { EligibilityFormSelectors } from '@core/store/selectors';
import { ProductModel } from '@entities/product/product.model';

const getCoveredLocationState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.coveredLocation
);

export const {
  selectAll: getAllCoveredLocations,
  selectEntities: getCoveredLocationEntities,
} = coveredLocationReducer.adapter.getSelectors(getCoveredLocationState);

export const getCoveredLocationEntity = (productType: ProductType) =>
  createSelector(
    getCoveredLocationEntities,
    (entities) =>
      entities[productType] ||
      ({
        productType,
      } as CoveredLocationEntity)
  );

export const getConstructionInfo = (productType: ProductType) =>
  createSelector(
    getCoveredLocationEntity(productType),
    (coveredLocation) =>
      coveredLocation.constructionInfo || ({} as ConstructionInfoModel)
  );

export const getCoveredLocationLoading = createSelector(
  getCoveredLocationState,
  (state) => state.loading
);

export const getCoveredLocationFailed = createSelector(
  getCoveredLocationState,
  (state) => !!state.error
);

export const getReconstructionCost = (productType: ProductType) =>
  createSelector(
    getCoveredLocationEntity(productType),
    (coveredLocation) => coveredLocation.reconstructionCost
  );

export const getCoveredLocationLoaded = (productType: ProductType) =>
  createSelector(getCoveredLocationEntity(productType), (coveredLocation) => {
    return (
      Object.keys(coveredLocation).filter((k) => k !== 'productType').length > 0
    );
  });

export const isCoveredLocationLoadedSuccessfully = (productType: ProductType) =>
  createSelector(
    getCoveredLocationEntity(productType),
    getCoveredLocationLoading,
    getCoveredLocationFailed,
    (coveredLocation, failed, loading) => {
      return (
        !failed &&
        !loading &&
        Object.keys(coveredLocation).filter((k) => k !== 'productType').length >
          0
      );
    }
  );

export const getPropertyOptions = (productType: ProductType) =>
  createSelector(
    getPropertyMetadata,
    getAllFeatureFlags,
    getProduct(productType),
    getPolicyLineModifier('SmartDevice', productType),
    getConstructionInfo(productType),
    (metaData, featureFlags, product, smartHome, constructionInfo) => {
      const garageType = constructionInfo.garages?.[0]?.garageType;
      const isSmallGarage =
        metaData?.additionalStructures?.typeOfSmallGarage.includes(
          garageType as string
        ) || false;
      const additionalStructures = {
        ...(metaData?.additionalStructures || {}),
        numberOfCars: isSmallGarage
          ? metaData?.additionalStructures?.numberOfCarsSmallGarages
          : metaData?.additionalStructures?.numberOfCarsNormalGarage,
      };
      return {
        ...metaData,
        additionalStructures,
        oilTankRequired: featureFlags.requiredOilTank || false,
        isSmartHomeDiscountApplicable: !!smartHome || false,
        showDogsInPropertyDisqualifier:
          featureFlags.showDogsInPropertyDisqualifier || false,
        isAdditionalOfferingTypeEnabled:
          featureFlags.offeringTypeApplicable || false,
        showArsonQuestionInPropertyDisqualifier:
          featureFlags.showArsonQuestionInPropertyDisqualifier || false,
        restrictWindstormProtectiveDeviceToHomeowners:
          featureFlags?.restrictWindstormProtectiveDeviceToHomeowners || false,
        showGatedCommuinity: true,
        showNumberOfFamilies: product?.type === 'Homeowner',
        showPropertyFourOne: featureFlags?.showPropertyFourOne ?? false,
        showPropertyFourZero: featureFlags?.showPropertyFourZero ?? false,
        showPropertyFourZeroAll: featureFlags?.showPropertyFourZeroAll ?? false,
        showDatePurchased: product?.type === 'Homeowner',
        showYearRoofReplaced: product?.type === 'Homeowner',
        showSecondaryHome: true,
        showModularHome: product?.type === 'Homeowner',
        showTownhouse: product?.type === 'Homeowner',
        needConstructionInfo: product?.needConstructionInfo,
        showRoofDetails: featureFlags?.showRoofDetails ?? false,
        showLeakDetection: featureFlags?.showLeakDetection ?? false,
      } as PropertyOptionsModel;
    }
  );

export const selectCoveredLocationModel = (productType: ProductType) =>
  createSelector(
    getCoveredLocationEntity(productType),
    (coveredLocation) => coveredLocation
  );

function roofShapesInvalid(roofShapes: CommonType[]): boolean {
  return !!(
    !roofShapes.length || roofShapes.find((rs) => !rs.type || !rs.percentage)
  );
}

export const buildCoveredLocationRequest = (
  productType: ProductType,
  includeMsbEstimateNumber: boolean
) =>
  createSelector(
    getProduct(productType),
    getCoveredLocationEntity(productType),
    getAddressForProduct(productType),
    getProtectiveDevicesForProduct(productType),
    EligibilityFormSelectors.buildPartialCoveredLocationBitsFromEligibilityForm(
      productType
    ),
    getAllFeatureFlags,
    (
      product,
      coveredLocation,
      address,
      protectiveDevices,
      eligibits,
      featureFlags
    ) => {
      const request: CoveredLocationRequest = {
        product: product as ProductModel,
        coveredLocation: PropertyHelper.sanitizeCoveredLocationRequest(
          {
            ...coveredLocation,
            associatedMSBEstimateNumber: includeMsbEstimateNumber
              ? coveredLocation.associatedMSBEstimateNumber
              : undefined,
            location: {
              addressLine1: address?.addressLine1,
              city: address?.city,
              state: address?.state,
              postalCode: address?.postalCode,
            },
          },
          protectiveDevices
        ),
      };
      if (eligibits.coveredLocation) {
        request.coveredLocation = {
          ...request.coveredLocation,
          ...eligibits.coveredLocation,
        };
      }
      if (eligibits.riskItems) {
        request.coveredLocation.riskItems = {
          ...request.coveredLocation.riskItems,
          ...eligibits.riskItems,
        };
      }
      if (
        request.coveredLocation?.constructionInfo?.roofShape &&
        (!featureFlags.showPropertyFourZero ||
          roofShapesInvalid(coveredLocation.constructionInfo?.roofShape || []))
      ) {
        request.coveredLocation.constructionInfo = {
          ...request.coveredLocation.constructionInfo,
          roofShape: undefined,
        };
      }
      if (request.coveredLocation?.constructionInfo?.garages) {
        request.coveredLocation.constructionInfo.garages =
          request.coveredLocation.constructionInfo.garages.filter(
            (garage) => garage.garageType && garage.numberOfCars
          );
      }
      return request;
    }
  );
