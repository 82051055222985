import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayValuePipe } from './display-value/display-value.pipe';
import { FetchLicensedStatesPipe } from './fetch-licensed-states/fetch-licensed-states.pipe';
import {
  ProtectiveDevicesTypePipe,
  ProtectiveDevicesValuePipe,
  ProtectiveDevicesDisplayPipe,
} from './all-protective-devices/all-protective-devices.pipe';
import { ProductNameTransformPipe } from './product-name-formatter/product-name-formatter.pipe';
import { LineBreakDisplayPipe } from './line-break/line-break-display.pipe';
import { ProductNameMapPipe } from './product-name-map/product-name-map.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { FilterNumberOfGarageCarsPipe } from './number-of-garage-cars/number-of-garage-cars.pipe';
import { FilterUmbrellaMinimumsPipe } from './filter-umbrella-minimums/filter-umbrella-minimums.pipe';
import { SummaryPolicyDocumentsPipe } from './summary-policy-documents/summary-policy-documents.pipe';
import { TermTypePipe } from './term-type.pipe';
import { DateMmDdYyyyPipe } from './date-mm-dd-yyyy/date-mm-dd-yyyy.pipe';
import { AlphabetizeObjectsPipe } from './alphabetize-objects/alphabetize-objects.pipe';
import { FilterByObjectAttributePipe } from './filter-by-object-attribute/filter-by-object-attribute.pipe';

@NgModule({
  declarations: [
    ProtectiveDevicesTypePipe,
    ProtectiveDevicesValuePipe,
    ProtectiveDevicesDisplayPipe,
    AlphabetizeObjectsPipe,
    SummaryPolicyDocumentsPipe,
    DisplayValuePipe,
    FetchLicensedStatesPipe,
    ProductNameTransformPipe,
    ProductNameMapPipe,
    LineBreakDisplayPipe,
    ProductNameMapPipe,
    LineBreakDisplayPipe,
    SafeUrlPipe,
    FilterNumberOfGarageCarsPipe,
    FilterUmbrellaMinimumsPipe,
    TermTypePipe,
    DateMmDdYyyyPipe,
    FilterByObjectAttributePipe,
  ],
  exports: [
    ProtectiveDevicesTypePipe,
    ProtectiveDevicesValuePipe,
    ProtectiveDevicesDisplayPipe,
    AlphabetizeObjectsPipe,
    SummaryPolicyDocumentsPipe,
    DisplayValuePipe,
    FetchLicensedStatesPipe,
    ProductNameTransformPipe,
    ProductNameMapPipe,
    LineBreakDisplayPipe,
    ProductNameMapPipe,
    LineBreakDisplayPipe,
    SafeUrlPipe,
    FilterNumberOfGarageCarsPipe,
    FilterUmbrellaMinimumsPipe,
    TermTypePipe,
    DateMmDdYyyyPipe,
    FilterByObjectAttributePipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
