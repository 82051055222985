import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store/reducers';
import {
  EligibilityInformationEntity,
  ProductModel,
} from '../store/entities/product/product.model';
import { Observable } from 'rxjs';
import { Nullable } from '@shared/utils/type.utils';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  DocumentDeliveryType,
  NationwideAccountRegistrationInfo,
  OfferingType,
  ProductType,
  QuoteStatus,
  TermType,
} from '@core/models/api/dsm-types';
import { JsonMetadataAdapter } from '../adapters/json-metadata.adapter';
import { UpdateQuoteRequestBody } from '../models/api/request/update-quote-request.model';
import { PolicyDateOptions, ProductButton } from '../interfaces/interfaces';
import {
  CompositeActions,
  ProductActions,
  QuoteActions,
} from '../store/actions';
import { ProductsSelectors } from '@core/store/selectors';
import { PcUrlService } from '@shared/services/pc-url.service';
import { getAllAvailableProducts } from '@core/store/entities/feature-flag/feature-flag.selector';
import { selectYearsWithPriorCarrier } from '@entities/product/product.selectors';
import { UpdateQuoteOptions } from '@entities/product/product.actions';
import { Producer } from '@entities/agency/agency.model';
import { Actions, ofType } from '@ngrx/effects';
import { StringUtils } from '@shared/utils/string.utils';
import { UpdateQuoteResponse } from '@core/models/api/response/update-quote-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private store: Store<fromStore.CoreState>,
    private jsonMetadataAdapter: JsonMetadataAdapter,
    private pcUrlService: PcUrlService,
    private actions$: Actions
  ) {}

  getAllProducts(): Observable<ProductModel[]> {
    return this.store.select(ProductsSelectors.getAllProducts);
  }

  getAllAvailableProducts(): Observable<ProductModel[]> {
    return this.store.select(getAllAvailableProducts);
  }

  getSelectedProductTypes(): Observable<ProductType[]> {
    return this.store.select(ProductsSelectors.getSelectedProductTypes);
  }

  getSelectedActiveProductTypes(): Observable<ProductType[]> {
    return this.store.select(ProductsSelectors.getSelectedActiveProductTypes);
  }

  getProduct(productType: ProductType): Observable<Nullable<ProductModel>> {
    return this.store.select(ProductsSelectors.getProduct(productType));
  }

  getEligibilityInformation(
    productType: ProductType
  ): Observable<Nullable<EligibilityInformationEntity>> {
    return this.store.select(
      ProductsSelectors.getEligibilityInformation(productType)
    );
  }

  getAddOnProducts(): Observable<ProductModel[]> {
    return this.store.select(ProductsSelectors.getAddOnProducts);
  }

  getProductEntitiesByIds(ids: ProductType[]): Observable<ProductModel[]> {
    return this.store.select(ProductsSelectors.getProductModelsByTypes(ids));
  }

  getSelectedProducts(): Observable<ProductModel[]> {
    return this.store.select(ProductsSelectors.getSelectedProducts);
  }

  getDocumentDeliveryPreference(): Observable<Nullable<DocumentDeliveryType>> {
    return this.store.select(ProductsSelectors.getDocumentDeliveryPreference);
  }

  getSelectedProductsIncludingNWXInactive(): Observable<ProductModel[]> {
    return this.store.select(
      ProductsSelectors.getSelectedProductsIncludingNWXInactive
    );
  }

  getAllSelectedProductsInitiated(): Observable<boolean> {
    return this.store.select(
      ProductsSelectors.selectAllSelectedProductsInitiated
    );
  }

  getSelectedProductEntitiesWithErrors(): Observable<ProductModel[]> {
    return this.store.select(
      ProductsSelectors.getSelectedProductEntitiesWithErrors
    );
  }

  getActiveSelectedProductEntitiesWithoutErrors(): Observable<ProductModel[]> {
    return this.store.select(
      ProductsSelectors.getActiveSelectedProductsWithoutErrors
    );
  }
  getSelectedProductEntitiesWithoutErrors(): Observable<ProductModel[]> {
    return this.store.select(
      ProductsSelectors.getSelectedQuoteProductsWithoutErrors
    );
  }

  getSelectedInactiveProducts(): Observable<ProductModel[]> {
    return this.store.select(ProductsSelectors.getSelectedInactiveProducts);
  }

  hasSelectedInactiveProducts(): Observable<boolean> {
    return this.store.select(ProductsSelectors.hasSelectedInactiveProducts);
  }

  isBindCompleted(): Observable<boolean> {
    return this.store.select(ProductsSelectors.isBindCompleted);
  }

  getNeedConstructionInfo(
    productType: ProductType
  ): Observable<Nullable<boolean>> {
    return this.store.select(
      ProductsSelectors.getNeedConstructionInfo(productType)
    );
  }

  getProductIfSelected(
    productType: ProductType
  ): Observable<ProductModel | undefined> {
    return this.store.select(
      ProductsSelectors.getProductIfSelected(productType)
    );
  }

  getProductsDataLoaded(): Observable<boolean> {
    return this.store.select(ProductsSelectors.getProductsDataLoaded);
  }

  getQuoteIdForProduct(productType: ProductType): Observable<string> {
    return this.store
      .select(ProductsSelectors.getQuoteIdForProduct(productType))
      .pipe(map((quoteId) => quoteId || ''));
  }

  getProductTypeForQuoteId(quoteId: string): Observable<ProductType | ''> {
    return this.store.select(ProductsSelectors.getAllProducts).pipe(
      map(
        (products) => products.find((p) => p.quoteId === quoteId)?.type || ''
      ),
      distinctUntilChanged()
    );
  }

  getPolicyCenterUrlForProduct(productType: ProductType): Observable<string> {
    return this.getQuoteIdForProduct(productType).pipe(
      switchMap((quoteId) =>
        this.pcUrlService.getPolicyCenterUrl(quoteId, 'Job').pipe(take(1))
      )
    );
  }

  getStates(): Observable<string[]> {
    return this.jsonMetadataAdapter.getStates();
  }

  getStatesWeDoBusinessIn(): Observable<string[]> {
    return this.getStates().pipe(
      map((states) =>
        states.filter(
          (state) => ['AK', 'HI', 'LA', 'MA', 'OK'].indexOf(state) < 0
        )
      )
    );
  }

  getPolicyDateOptions(
    productType: ProductType
  ): Observable<PolicyDateOptions> {
    return this.store.select(
      ProductsSelectors.getPolicyDateOptions(productType)
    );
  }

  updateSelectedProducts(productTypes: ProductType[]): void {
    this.store.dispatch(
      ProductActions.updateSelectedProducts({ payload: productTypes })
    );
  }

  selectProduct(productType: ProductType, select: boolean): void {
    this.store
      .select(ProductsSelectors.getSelectedProductTypes)
      .pipe(take(1))
      .subscribe((selectedProducts) => {
        const index = selectedProducts.indexOf(productType);
        if (index < 0) {
          if (select) {
            this.store.dispatch(
              ProductActions.updateSelectedProducts({
                payload: [...selectedProducts, productType],
              })
            );
          }
        } else if (!select) {
          const newSelectedProducts = [...selectedProducts];
          newSelectedProducts.splice(index, 1);
          this.store.dispatch(
            ProductActions.updateSelectedProducts({
              payload: newSelectedProducts,
            })
          );
        }
      });
  }

  updateQuote(
    request: UpdateQuoteRequestBody,
    options?: UpdateQuoteOptions
  ): void {
    this.store.dispatch(
      ProductActions.patchProductQuote({
        payload: request,
        options,
      })
    );
  }

  updateQuoteAndAwaitResponse(
    request: UpdateQuoteRequestBody,
    options?: UpdateQuoteOptions
  ): Promise<UpdateQuoteResponse> {
    const correlationId = StringUtils.generateUuid();
    return new Promise((resolve, reject) => {
      this.actions$
        .pipe(
          ofType(QuoteActions.updateQuoteSuccess, QuoteActions.updateQuoteFail),
          filter((a) => a.correlationId === correlationId),
          take(1)
        )
        .subscribe({
          next: (action) => {
            if (action.type === QuoteActions.updateQuoteSuccess.type) {
              resolve(action.response);
            } else {
              reject(action.error);
            }
          },
          error: (error) => {
            reject(error);
          },
        });
      this.store.dispatch(
        ProductActions.patchProductQuote({
          payload: request,
          options,
          correlationId,
        })
      );
    });
  }

  storeProduct(update: ProductModel): void {
    this.store.dispatch(
      ProductActions.updateProduct({
        payload: {
          id: update.type,
          changes: { effectiveDate: update.effectiveDate || '' },
        },
      })
    );
  }

  upsertForAllProducts(product: Partial<ProductModel>): void {
    this.store.dispatch(
      ProductActions.upsertForAllProducts({ payload: product })
    );
  }

  removeProduct(product: ProductModel): void {
    this.store.dispatch(
      ProductActions.removeProduct({ payload: product.type })
    );
  }

  removeProductByType(productType: ProductType): void {
    this.store.dispatch(ProductActions.removeProduct({ payload: productType }));
  }

  reportsAndUWApplicable(): Observable<boolean> {
    return this.store.select(ProductsSelectors.reportsAndUWApplicable);
  }

  updateProduct(product: Partial<ProductModel>): void {
    if (!product.type) return;
    this.store.dispatch(
      ProductActions.updateProduct({
        payload: {
          id: product.type,
          changes: product,
        },
      })
    );
  }

  updateDocDeliveryOption(product: Partial<ProductModel>): void {
    this.store.dispatch(
      ProductActions.updateDocDeliveryOption({
        payload: product,
      })
    );
  }

  updateProductStatus(productType: ProductType, status: QuoteStatus): void {
    this.store.dispatch(
      ProductActions.updateProductStatus({
        payload: productType,
        status,
      })
    );
  }

  updateProductStatusForSelected(status: QuoteStatus): void {
    this.store
      .select(ProductsSelectors.getSelectedProductTypes)
      .pipe(take(1))
      .subscribe((productIds) => {
        for (const productId of productIds) {
          this.updateProductStatus(productId, status);
        }
      });
  }

  updateProductsAtHub(): Promise<void> {
    let resolve: () => void;
    let reject: () => void;
    const result = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    this.actions$
      .pipe(
        ofType(
          CompositeActions.submitPniPageSuccess,
          CompositeActions.submitPniPageError
        ),
        take(1)
      )
      .subscribe({
        next: (action) => {
          if (action.type === CompositeActions.submitPniPageSuccess.type) {
            resolve();
          } else {
            reject();
          }
        },
        error: () => reject(),
      });
    this.store.dispatch(QuoteActions.initiateOrUpdateSelectedProducts());
    return result;
  }

  getProductTerm(productId: ProductType): Observable<TermType> {
    return this.store.select(ProductsSelectors.getProductTerm(productId));
  }

  getOfferingType(
    productType: ProductType
  ): Observable<Nullable<OfferingType>> {
    return this.store.select(ProductsSelectors.getOfferingType(productType));
  }

  getStatusOfAllProducts(): Observable<QuoteStatus[]> {
    return this.store.select(ProductsSelectors.getStatusOfAllProducts);
  }

  getStatusOfAllProductsWithoutErrors(): Observable<QuoteStatus[]> {
    return this.store.select(
      ProductsSelectors.getStatusOfAllProductsWithoutErrors
    );
  }

  getOverallQuoteStatus(): Observable<QuoteStatus> {
    return this.store.select(ProductsSelectors.getOverallQuoteStatus);
  }

  getOverallFurthestQuoteStatus(): Observable<Nullable<QuoteStatus>> {
    return this.store.select(ProductsSelectors.getOverallFurthestQuoteStatus);
  }

  getProductStatus(product: ProductType): Observable<QuoteStatus | undefined> {
    return this.store.select(ProductsSelectors.getProductQuoteStatus(product));
  }

  getProductNavigable(product: ProductType): Observable<boolean> {
    return this.store.select(ProductsSelectors.getProductNavigable(product));
  }

  getProductEffectiveDate(
    product: ProductType
  ): Observable<string | undefined> {
    return this.store.select(
      ProductsSelectors.getProductEffectiveDate(product)
    );
  }

  getProductFurthestQuoteStatus(
    product: ProductType
  ): Observable<Nullable<QuoteStatus>> {
    return this.store.select(
      ProductsSelectors.getProductFurthestQuoteStatus(product)
    );
  }

  getAllQuoteCoverageLoaded(productId: ProductType): Observable<boolean> {
    return this.store.select(
      ProductsSelectors.getAllQuoteCoverageLoaded(productId)
    );
  }

  getSelectedQuoteProductsWithoutErrors(): Observable<ProductModel[]> {
    return this.store.select(
      ProductsSelectors.getSelectedQuoteProductsWithoutErrors
    );
  }

  getSelectedQuoteProductsWithoutErrorsAndQuoteStatus(): Observable<
    ProductModel[]
  > {
    return this.store.select(
      ProductsSelectors.getSelectedQuoteProductsWithoutErrorsAndQuoteStatus
    );
  }

  isProductSelected(product: ProductType): Observable<boolean> {
    return this.store.select(ProductsSelectors.isProductSelected(product));
  }

  isOnlyProductSelected(product: ProductType): Observable<boolean> {
    return this.store.select(ProductsSelectors.isOnlyProductSelected(product));
  }

  getIsAssignedRiskPlan(): Observable<boolean | undefined> {
    return this.store.select(ProductsSelectors.getIsAssignedRiskPlan);
  }

  clearProductErrors(): void {
    this.store.dispatch(ProductActions.clearProductErrors());
  }

  productsNeedRerate(): Observable<boolean> {
    return this.store.select(ProductsSelectors.productsNeedRerate);
  }

  getProductButtons(
    showSelectedProducts: boolean
  ): Observable<ProductButton[]> {
    return this.store.select(
      ProductsSelectors.getProductButtons(showSelectedProducts)
    );
  }

  isProductDsmEnabled(productType: ProductType): Observable<boolean> {
    return this.store.select(
      ProductsSelectors.isProductDsmEnabled(productType)
    );
  }

  getPriorCarrier(productType: ProductType): Observable<Nullable<string>> {
    return this.store.select(selectYearsWithPriorCarrier(productType));
  }

  setPriorCarrier(
    productType: ProductType,
    yearsWithPriorCarrier: string
  ): void {
    this.store.dispatch(
      ProductActions.upsertYearsWithPriorCarrier({
        payload: productType,
        yearsWithPriorCarrier,
      })
    );
  }

  setProducer(productType: ProductType, producer: Producer): void {
    this.store.dispatch(
      ProductActions.updateProducer({
        product: productType,
        producer,
      })
    );
  }

  getNationwideAccountRegistrationInfo(): Observable<
    Nullable<NationwideAccountRegistrationInfo>
  > {
    return this.store.select(
      ProductsSelectors.getNationwideAccountRegistrationInfo
    );
  }

  updateAccountRegistrationForAllProducts(
    registrationInfo: NationwideAccountRegistrationInfo
  ): void {
    this.store.dispatch(
      ProductActions.updateAccountRegistration({
        registrationInfo,
      })
    );
  }

  submitAccountRegistrationModal(
    registrationInfo: NationwideAccountRegistrationInfo
  ): void {
    this.store.dispatch(
      ProductActions.updateAccountRegistration({ registrationInfo })
    );
  }

  deleteWarningMessage(message: string, productType: ProductType) {
    this.store.dispatch(
      ProductActions.deleteWarningMessageFromProduct({ message, productType })
    );
  }

  updateProductCreateDate(productType: ProductType, createDate: string): void {
    this.store.dispatch(
      ProductActions.updateProductCreateDate({
        payload: productType,
        createDate,
      })
    );
  }
}
