import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/services/app-config.service';
import { getAccountId } from '@core/store/entities/account/account.selector';
import { getEffectiveUserRole } from '@core/store/entities/user/user.selector';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PC_INTERNAL_EXTERNAL_MAPPING } from '../constants/app-constants';

export type UrlType = 'Account' | 'Job' | 'Comprater';

@Injectable({
  providedIn: 'root',
})
export class PcUrlService {
  constructor(
    private store: Store,
    private appConfigService: AppConfigService
  ) {}

  getPolicyCenterUrl(id?: string, urlType?: UrlType): Observable<string> {
    return combineLatest([
      this.store.select(getEffectiveUserRole),
      this.store.select(getAccountId),
    ]).pipe(
      map(([role, accountId]) => {
        let url;

        if (!this.appConfigService?.config) {
          return '';
        }

        if (role === 'NSS') {
          url = this.appConfigService?.config?.pcInternalDeepLaunchUrl;
          url = url.replace(
            '<targetEnv>',
            this.appConfigService?.config?.targetEnv as string
          );
        } else {
          url = this.appConfigService?.config?.pcExternalDeepLaunchUrl;
          url = url.replace(
            '<targetEnv>',
            PC_INTERNAL_EXTERNAL_MAPPING[
              this.appConfigService?.config?.targetEnv as string
            ] ||
              this.appConfigService?.config?.targetEnv ||
              ''
          );
        }

        if (id && urlType) {
          switch (urlType) {
            case 'Job':
              url += `/pc/Job.do?JobNumber=${id}`;
              break;
            case 'Account':
              url += `/pc/AccountFile.do?AccountNumber=${id}`;
              break;
            case 'Comprater':
              url += `/pc/Job.do?LaunchingAppId=AC&Source=CompRater&ExternalQuoteID=${id}`;
              break;
            default:
              url += `/pc/Job.do?JobNumber=${id}`;
              break;
          }
        } else if (urlType === 'Account' && accountId) {
          url += `/pc/AccountFile.do?AccountNumber=${accountId}`;
        } else {
          url += '/pc/PolicyCenter.do';
        }

        return url;
      })
    );
  }
}
