import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  SimpleChanges,
  AfterViewChecked,
} from '@angular/core';

@Component({
  selector: 'nwx-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent implements AfterViewChecked {
  @Input() loading!: boolean;
  @Input() message!: { message: string | undefined; iconUrl: string };
  @ViewChild('spinner', { read: ElementRef }) spinner?: ElementRef;

  constructor() {}

  ngAfterViewChecked(): void {
    if (this.loading && this.spinner?.nativeElement) {
      this.renderSpinner(this.spinner.nativeElement);
    }
  }

  iconUrl(name: string): string {
    switch (name) {
      default:
        return '';
    }
    return '';
  }

  private renderSpinner(element: HTMLCanvasElement): void {
    const thickness = 2;
    const radius = element.width * 0.5 - thickness - 1;
    const ctx = element.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, element.width, element.height);
    ctx.beginPath();
    ctx.ellipse(
      element.width * 0.5,
      element.height * 0.5,
      radius,
      radius,
      0,
      0,
      Math.PI * 2
    );
    ctx.strokeStyle = '#bce';
    ctx.lineWidth = thickness;
    ctx.stroke();
    ctx.beginPath();
    ctx.ellipse(
      element.width * 0.5,
      element.height * 0.5,
      radius,
      radius,
      0,
      0,
      (Math.PI * 2) / 3
    );
    ctx.strokeStyle = '#23a';
    ctx.stroke();
  }
}
