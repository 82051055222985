import { ProductType } from '@core/models/api/dsm-types';
import { UWReportsResponse } from '@core/models/api/response/uw-reports-response.model';
import { ProductModel } from '@entities/product/product.model';
import { UwActivitiesStoreModel } from '@entities/uw-activities/uw-activities.reducer';

export interface UwReportsViewModel {
  loading: boolean;
  uwIssues?: UwIssuesViewModel;
  uwActivities?: UwActivitiesViewModel;
  // In general, one section per product. View should separate them with <hr>s
  sections: UwReportsViewSection[];
  refreshEnabled: boolean;
  maxSizeMini: boolean;
}

export interface UwReportsViewSection {
  reports: UwReportsViewReport[];
  autoCharacteristics?: AutoCharacteristicsViewReport;
  productDescription?: string;
}

export interface UwReportsViewReport {
  iconName: string; // from Bolt
  iconColor: string; // from Bolt
  title: string;
  summary: string;
  linkText: string;
  linkBehavior: '' | 'modal' | 'scrollToTasks' | 'navProperty';
  linkElementId: string;
  originalResponse: UWReportsResponse | null;
}

export interface AutoCharacteristicsViewReport {
  autoBiOccurLimit?: string;
  autoBiPerPersonLimit?: string;
  minVehicleModelYear?: string;
}

export interface UwIssuesViewModel {
  products: UwIssuesProductView[];
}

export interface UwIssuesProductView {
  productType: ProductType;
  productName: string;
  disableApproval: boolean;
  issues: string[];
}

export interface UwActivitiesViewModel {
  items: UwActivitiesViewItem[];
}

export interface UwActivitiesViewItem {
  productName: string;
  quoteId: string;
  activityId: string;
  status: string;
  subject: string;
  body: string;
}

export function composeUwActivitiesViewModel(
  uwActivities: UwActivitiesStoreModel[],
  products: ProductModel[]
): UwActivitiesViewModel | undefined {
  const viewModel: UwActivitiesViewModel = {
    items: [],
  };
  for (const activityBucket of uwActivities) {
    const product = products.find((p) => p.type === activityBucket.productType);
    if (!product) {
      continue;
    }
    for (const activity of activityBucket.activities) {
      if (!activity.notes?.length) {
        continue;
      }
      const bodyLengthLimit = 50;
      let body = activity.notes[0].body;
      if (body.length > bodyLengthLimit) {
        body = body.substring(0, bodyLengthLimit) + '...';
      }
      viewModel.items.push({
        productName: product.name || '',
        quoteId: product.quoteId || '',
        activityId: activity.activityId.toString(),
        status: activity.status,
        subject: activity.subject,
        body,
      });
    }
  }
  if (!viewModel.items.length) {
    return undefined;
  }
  return viewModel;
}
