import { createAction, props } from '@ngrx/store';
import { PersonaName } from './persona.model';

export const setPersonaName = createAction(
  '[Persona] Set Name',
  props<{ name: PersonaName }>()
);

export const setManualPersonaName = createAction(
  '[Persona] Set Manual Name',
  props<{ name: PersonaName | '' }>()
);
