import {
  InitiateNewBusinessRequest,
  PniFormModelForNewBusiness,
} from '@core/models/api/request/initiate-new-business-request.model';
import { InitiateNewBusinessResponse } from '@core/models/api/response/initiate-new-business-response.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { UpdateQuoteFormRequest } from '@core/models/api/request/update-quote-request.model';
import {
  ProductType,
  QuoteStatus,
  RatingType,
} from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { Nullable } from '@shared/utils/type.utils';
import { UpdateQuoteResponse } from '@core/models/api/response/update-quote-response.model';

export const enum QuoteActionType {
  INITIATE_SELECTED_PRODUCTS = '[Quote] Initiate Selected Products',
  INITIATE_NEW_BUSINESS = '[Quote] Initiate New Business',
  INITIATE_NEW_BUSINESS_AUTO = '[Quote] Initiate New Business Auto',
  INITIATE_NEW_BUSINESS_HOMEOWNERS = '[Quote] Initiate New Business Homeowners',

  INITIATE_NEW_BUSINESS_FAIL = '[Quote] Initiate New Business Fail',
  INITIATE_NEW_BUSINESS_SUCCESS = '[Quote] Initiate New Business Success',
  INITIATE_NEW_BUSINESS_SUCCESS_AFTER_FIRST_REDUCTION = '[Quote] Initiate New Business Success cntd...',

  RATE_QUOTE = '[Quote] Rate Quote',
  RATE_QUOTE_FAIL = '[Quote] Rate Quote Fail',
  RATE_QUOTE_SUCCESS = '[Quote] Rate Quote Success',

  RATE_BIND = '[Quote] Rate Bind',
  RATE_BIND_FAIL = '[Quote] Rate Bind Fail',
  RATE_BIND_SUCCESS = '[Quote] Rate Bind Success',

  UPDATE_QUOTE = '[Quote] Update Quote',
  UPDATE_QUOTE_FAIL = '[Quote] Update Quote Fail',
  UPDATE_QUOTE_SUCCESS = '[Quote] Update Quote Success',

  UPDATE_QUOTE_STATUS = '[Quote] Update Quote Status',
  UPDATE_QUOTE_IF_ASSIGNED_RISK_PLAN_CHANGED = '[Quote] Update Quote If Assigned Risk Plan Changed',
  QUOTE_CALLS_COMPLETED = '[Quote] Quote calls completed',

  STORE_QUOTE = '[Quote] Store Quote',
  SET_IS_ASSIGNED_RISK_PLAN = '[Quote] Update Assigned Risk Plan',

  RATE_QUOTE_SELECTED_PRODUCTS = '[Quote] Rate Quote Selected Products',

  MANUALLY_INVALIDATE_PRODUCT = '[Quote] Manually Invalidate Product',
}

export const initiateOrUpdateSelectedProducts = createAction(
  QuoteActionType.INITIATE_SELECTED_PRODUCTS
);

export const initiateNewBusiness = createAction(
  QuoteActionType.INITIATE_NEW_BUSINESS,
  props<{ payload: ProductType; correlationId?: string }>()
);

export const initiateNewBusinessSuccess = createAction(
  QuoteActionType.INITIATE_NEW_BUSINESS_SUCCESS,
  props<{
    payload: InitiateNewBusinessResponse;
    correlationId?: string;
  }>()
);

export const initiateNewBusinessSuccessAfterFirstReduction = createAction(
  QuoteActionType.INITIATE_NEW_BUSINESS_SUCCESS_AFTER_FIRST_REDUCTION,
  props<{ productType: ProductType }>()
);

export const initiateNewBusinessFail = createAction(
  QuoteActionType.INITIATE_NEW_BUSINESS_FAIL,
  props<{
    request: InitiateNewBusinessRequest;
    payload: ErrorModel;
    correlationId?: string;
  }>()
);

export const rateQuote = createAction(
  '[Quote] Rate Quote',
  props<{
    productType: ProductType;
    ratingType: RatingType;
  }>()
);

export const rateQuoteSelectedProducts = createAction(
  '[Quote] Rate Quote Selected Products',
  props<{ ratingType: RatingType }>()
);

export const rateQuoteFail = createAction(
  QuoteActionType.RATE_QUOTE_FAIL,
  props<{
    productType: ProductType;
    ratingType: string;
    suppressSoftfall?: boolean;
    error?: unknown;
  }>()
);

export const rateQuoteSuccess = createAction(
  QuoteActionType.RATE_QUOTE_SUCCESS,
  props<{
    productType: ProductType;
    quoteStatus: QuoteStatus;
  }>()
);

export const rateBindSuccess = createAction(
  QuoteActionType.RATE_BIND_SUCCESS,
  props<{
    productType: ProductType;
    quoteStatus: QuoteStatus;
  }>()
);

export const updateQuote = createAction(
  QuoteActionType.UPDATE_QUOTE,
  props<{
    productType: ProductType;
    override?: Partial<UpdateQuoteFormRequest>;
    correlationId?: string;
  }>()
);

export const updateQuoteSuccess = createAction(
  QuoteActionType.UPDATE_QUOTE_SUCCESS,
  props<{
    payload: ProductType | null;
    effectiveDate?: string;
    correlationId?: string;
    response: UpdateQuoteResponse;
  }>()
);

export const storeQuote = createAction(
  QuoteActionType.STORE_QUOTE,
  props<{
    payload: ProductType | null;
    override?: Partial<UpdateQuoteFormRequest>;
    correlationId?: string;
  }>()
);

export const updateQuoteFail = createAction(
  QuoteActionType.UPDATE_QUOTE_FAIL,
  props<{
    payload: ProductType | null;
    error: unknown;
    correlationId?: string;
  }>()
);

export const quoteCallsCompleted = createAction(
  QuoteActionType.QUOTE_CALLS_COMPLETED
);

export const manuallyInvalidateProduct = createAction(
  QuoteActionType.MANUALLY_INVALIDATE_PRODUCT,
  props<{ productType: ProductType; reason: string }>()
);
