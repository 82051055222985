import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  ExtendedTelematicsVehicle,
  EnrolledVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from './telematics.model';
import { TelematicsUtils } from '@app/shared/utils/telematics.utils';
import {
  getAllSelectedVehiclesByProduct,
  getAutoTypeVehicles,
} from '../vehicle/vehicle.selector';
import { getPremiumEntities } from '../premium/premium.selectors';
import { getSelectedProducts } from '../product/product.selectors';

export const getTelematicsState = createSelector(
  fromCore.getCoreState,
  (state) => state.telematics
);

export const getCurrentRecommendation = createSelector(
  getTelematicsState,
  (state) => state.recommendation
);

export const getCurrentEnrollment = createSelector(
  getTelematicsState,
  (state) => state.enrollment
);

export const getTelematicsLoading = createSelector(
  getTelematicsState,
  (state) => state.loading
);

export const getTelematicsLoaded = createSelector(
  getTelematicsState,
  (state) => state.loaded
);

export const getTelematicsConfirmed = createSelector(
  getTelematicsState,
  (state) => state.confirmed
);

export const getTelematicsReviewed = createSelector(
  getTelematicsState,
  (state) => state.reviewed
);

export const getCurrentEnrollmentVehicles = createSelector(
  getCurrentEnrollment,
  (enrollment) => (enrollment as VehicleEnrollment)?.vehicles || null
);

export const isRecommendationEnrolled = createSelector(
  getCurrentRecommendation,
  getCurrentEnrollment,
  (recommendation, enrollment) => {
    if (enrollment) {
      if (recommendation?.recommendedProgram === 'Mobile App Program') {
        return TelematicsUtils.isMobileEnrollment(enrollment);
      }
    }
    if (recommendation?.recommendedProgram === 'Vehicle Program') {
      if (TelematicsUtils.isVehicleEnrollment(enrollment)) {
        const enrollmentVehicles = (enrollment as VehicleEnrollment).vehicles;
        const recommendationVehicles =
          recommendation.qualifyingInformation?.vehicles;
        const diff = [];
        enrollmentVehicles?.forEach((enrollmentVehicle) => {
          const matchingVehicle = recommendationVehicles?.find(
            (v) => v.vehicleId === enrollmentVehicle.vehicleId
          );
          const recommended = matchingVehicle?.availablePrograms?.find(
            (p) => p.recommended
          )?.name;
          if (
            (recommended &&
              enrollmentVehicle.enrollmentStatus === 'Enrolled') ||
            enrollmentVehicle.enrollmentStatus === 'VerifiedScore'
          ) {
            if (
              enrollmentVehicle.vehicleProgram !== recommended &&
              enrollmentVehicle.vehicleProgram !== 'SmartRideInstant'
            ) {
              diff.push(enrollmentVehicle);
            }
          } else {
            diff.push(enrollmentVehicle);
          }
        });
        return diff.length === 0;
      }
    }
    return false;
  }
);

export const isAnyTelematicsEnrolled = createSelector(
  getCurrentEnrollment,
  (enrollment) => {
    if (!enrollment) {
      return false;
    }
    if (
      (enrollment as VehicleEnrollment).vehicles?.find(
        (v) => v.enrollmentStatus !== 'NotEnrolled'
      )
    ) {
      return true;
    }
    if (
      (enrollment as MobileEnrollment).mobileEnrollment &&
      (enrollment as MobileEnrollment).mobileEnrollment.enrollmentStatus !==
        'NotEnrolled'
    ) {
      return true;
    }
    return false;
  }
);

// ExtendedTelematicsVehicle objects in the store should eventually
// be updated with extra properties when the hub program tiles are built (below)
export const getStoreTelematicsVehicles = createSelector(
  getTelematicsState,
  (state) => state.vehicles
);

export const buildProgramTileVehicles = createSelector(
  getAutoTypeVehicles,
  getCurrentEnrollmentVehicles,
  getPremiumEntities,
  getCurrentEnrollment,
  (vehicleEntities, enrollmentVehicles, premiumEntities, enrollment) => {
    const vehiclePremiums = premiumEntities.PersonalAuto?.vehiclePremiums;
    const coverablePremiums = premiumEntities.PersonalAuto?.coverablePremiums;
    const termMonths = premiumEntities.PersonalAuto?.termMonths;
    const mobileEnrollmentStatus = (enrollment as MobileEnrollment)
      ?.mobileEnrollment?.enrollmentStatus;
    const programTileVehicles: ExtendedTelematicsVehicle[] =
      vehicleEntities.map((entity) => {
        const enrollmentVehicle = enrollmentVehicles?.find(
          (v) => v.vehicleId?.toString() === entity.vehicleId?.toString()
        );
        const vehiclePremium = vehiclePremiums?.find(
          (p) => p.vehicleID?.toString() === entity.vehicleId?.toString()
        );
        const coverablePremium = coverablePremiums?.find(
          (p) => p.coverableID?.toString() === entity.vehicleId?.toString()
        );
        const programTileVehicle = {
          enrollmentStatus: mobileEnrollmentStatus || 'NotEnrolled',
          ...entity,
          ...enrollmentVehicle,
          ...vehiclePremium,
          ...coverablePremium,
          termMonths,
        } as ExtendedTelematicsVehicle;
        return programTileVehicle;
      });
    return programTileVehicles;
  }
);

export const isTelematicsDeclined = createSelector(
  getCurrentRecommendation,
  (recommendation) => {
    return recommendation?.recommendedProgram === 'None';
  }
);

export const getShowTelematicsConfirmationTask = createSelector(
  getSelectedProducts,
  getTelematicsConfirmed,
  buildProgramTileVehicles,
  isTelematicsDeclined,
  (products, telematicsConfirmed, vehicles, isTelematicsDeclined) => {
    return (
      products?.some(
        (product) =>
          product.type === 'PersonalAuto' &&
          product.quoteFurthestStatus &&
          product.quoteStatus !== 'Error'
      ) &&
      !products.some((p) => p.quoteStatus === 'Pending') &&
      !telematicsConfirmed &&
      vehicles.some((v) => v.enrollmentStatus !== 'NotEnrolled') &&
      !isTelematicsDeclined
    );
  }
);

export const isSmartMilesEnrolled = createSelector(
  getCurrentEnrollment,
  (enrollment) => {
    return !!(enrollment as VehicleEnrollment)?.vehicles?.find(
      (v) => (v as EnrolledVehicle).vehicleProgram === 'SmartMiles'
    );
  }
);

// excludedQuoteIds are for retrieve only, not for a "None" best fit recommendation
export const isQuoteExcludedFromTelematics = (quoteId: string) =>
  createSelector(getTelematicsState, (state) =>
    state.excludedQuoteIds.includes(quoteId)
  );


