import {
  AccountHolderRequest,
  AccountRequest,
} from '@core/models/api/request/account-request.model';
import { AccountResponse } from '@core/models/api/response/account-response.model';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { createAction, props } from '@ngrx/store';

export const enum AccountActionType {
  CREATE_ACCOUNT = '[Account] Create Account',
  CREATE_ACCOUNT_SUCCESS = '[Account] Create Account Success',
  CREATE_ACCOUNT_FAIL = '[Account] Create Account Fail',
  CLEAR_ACCOUNT_ERROR = '[Account] Clear Account Error',
  SET_ACCOUNT_ID = '[Account] Set Account ID',
  LINK_ACCOUNT = '[Account] Linking Accounts',
  SHOW_ACCOUNT_REGISTRATION_MODAL = '[Account] Show Account Registration Modal',
  SHOW_ACCOUNT_INFO_MODAL = '[Account] Show Account Info Modal',
}

export const createAccount = createAction(AccountActionType.CREATE_ACCOUNT);

export const createAccountSuccess = createAction(
  AccountActionType.CREATE_ACCOUNT_SUCCESS,
  props<{ response: AccountResponse }>()
);

export const createAccountFail = createAction(
  AccountActionType.CREATE_ACCOUNT_FAIL,
  props<{ error: ErrorModel }>()
);

export const clearAccountError = createAction(
  AccountActionType.CLEAR_ACCOUNT_ERROR
);

export const setAccountId = createAction(
  AccountActionType.SET_ACCOUNT_ID,
  props<{ accountId: string }>()
);

export const linkAccount = createAction(AccountActionType.LINK_ACCOUNT);
export const showAccountRegistrationModal = createAction(
  AccountActionType.SHOW_ACCOUNT_REGISTRATION_MODAL,
  props<{ dismissReviewTask?: boolean }>()
);

export const showAccountInfoModal = createAction(
  AccountActionType.SHOW_ACCOUNT_INFO_MODAL
);
