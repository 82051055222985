import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/helper/form-helper';
import { ComponentStoreService } from '@core/services/component-store.service';
import { LayoutService } from '@core/services/layout.service';
import { EarthquakeDetailsModel } from '@entities/covered-location/covered-location.model';
import { ViewportType } from '@entities/layout/layout.entity';
import { ComponentStore } from '@ngrx/component-store';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';

export interface EarthquakeFormState {
  showAdditionalEarthquakeQuestions?: boolean;
  insideModal?: boolean;
}

@Component({
  selector: 'nwx-earthquake-details',
  templateUrl: './earthquake-details.component.html',
  styleUrl: './earthquake-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class EarthquakeDetailsComponent implements OnInit, OnDestroy {
  @Input() earthquakeDetails: Nullable<EarthquakeDetailsModel> =
    {} as EarthquakeDetailsModel;
  @Input() insideModal = false;

  @Output() valueChange = new EventEmitter<Partial<EarthquakeDetailsModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  horizontalRadio = false;
  vm$!: Observable<EarthquakeFormState>;
  form!: FormGroup;
  errorMessage: { [key: string]: string } = {};

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<EarthquakeFormState>,
    private layoutService: LayoutService,
    private cd: ChangeDetectorRef
  ) {
    this.layoutService
      .getViewport()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((viewport) => {
        const displayHorizontal: ViewportType[] = [
          'mini',
          'medium',
          'large',
          'xlarge',
          'xxlarge',
        ];
        this.horizontalRadio = displayHorizontal.includes(viewport);
      });
  }

  ngOnInit(): void {
    this.form = this.buildForm(this.earthquakeDetails);
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: EarthquakeDetailsModel) => {
        this.emitChanges(changes);
      });

    this.formReady.emit(this.form);

    this.componentStore.initialize({
      showAdditionalEarthquakeQuestions:
        GeneralUtils.booleanFromStringOrPassThrough(
          this.earthquakeDetails?.isEarthquakeCoverageExpected
        ) || false,
      insideModal: this.insideModal,
    });
    this.vm$ = this.componentStore.get();

    this.setAdditionalQuestions(
      GeneralUtils.booleanFromStringOrPassThrough(
        this.earthquakeDetails?.isEarthquakeCoverageExpected
      ) || false
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  emitChanges(changes: Partial<EarthquakeDetailsModel>): void {
    this.valueChange.emit({
      isEarthquakeCoverageExpected: GeneralUtils.parseBooleanFromString(
        changes.isEarthquakeCoverageExpected
      ),
      isMasonryVeneerCoverageExpected: GeneralUtils.parseBooleanFromString(
        changes.isMasonryVeneerCoverageExpected
      ),
      isUnrepairedEQDamageExists: GeneralUtils.parseBooleanFromString(
        changes.isUnrepairedEQDamageExists
      ),
      isDwellingNearSlope: GeneralUtils.parseBooleanFromString(
        changes.isDwellingNearSlope
      ),
      doExtensiveOverhangsExist: GeneralUtils.parseBooleanFromString(
        changes.doExtensiveOverhangsExist
      ),
    });
  }

  hasChanges(a: EarthquakeDetailsModel, b: EarthquakeDetailsModel): boolean {
    return (
      a?.isEarthquakeCoverageExpected === b?.isEarthquakeCoverageExpected &&
      a?.isMasonryVeneerCoverageExpected ===
        b?.isMasonryVeneerCoverageExpected &&
      a?.isUnrepairedEQDamageExists === b?.isUnrepairedEQDamageExists &&
      a?.isDwellingNearSlope === b?.isDwellingNearSlope &&
      a?.doExtensiveOverhangsExist === b?.doExtensiveOverhangsExist
    );
  }

  buildForm(earthquakeDetails: Nullable<EarthquakeDetailsModel>): FormGroup {
    return this.fb.group({
      isEarthquakeCoverageExpected: this.fb.control(
        GeneralUtils.parseStringFromBoolean(
          earthquakeDetails?.isEarthquakeCoverageExpected
        ) || null,
        [Validators.required]
      ),
      isMasonryVeneerCoverageExpected: this.fb.control(
        GeneralUtils.parseStringFromBoolean(
          earthquakeDetails?.isMasonryVeneerCoverageExpected
        ) || null,
        []
      ),
      isUnrepairedEQDamageExists: this.fb.control(
        GeneralUtils.parseStringFromBoolean(
          earthquakeDetails?.isUnrepairedEQDamageExists
        ) || null,
        []
      ),
      isDwellingNearSlope: this.fb.control(
        GeneralUtils.parseStringFromBoolean(
          earthquakeDetails?.isDwellingNearSlope
        ) || null,
        []
      ),
      doExtensiveOverhangsExist: this.fb.control(
        GeneralUtils.parseStringFromBoolean(
          earthquakeDetails?.doExtensiveOverhangsExist
        ) || null,
        []
      ),
    });
  }

  onEarthquakeCoverageExpectedChange(event: Event) {
    const value = GeneralUtils.getEventValue(event);
    const showAdditionalQuestions = GeneralUtils.parseBooleanFromString(value);
    this.componentStore.update({
      showAdditionalEarthquakeQuestions: showAdditionalQuestions ?? false,
    });
    this.setAdditionalQuestions(showAdditionalQuestions ?? false);
  }

  setAdditionalQuestions(showAdditionalQuestions: boolean): void {
    const controlNames = [
      'isMasonryVeneerCoverageExpected',
      'isUnrepairedEQDamageExists',
      'isDwellingNearSlope',
      'doExtensiveOverhangsExist',
    ];
    controlNames.forEach((controlName) => {
      FormHelper.enableOrDisable(
        controlName,
        showAdditionalQuestions,
        [Validators.required],
        this.form
      );
    });
  }

  highlightRequiredErrors(): void {
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control) {
        if (GeneralUtils.isEmpty(control.value)) {
          this.errorMessage[key] = 'This field is required to quote.';
        } else {
          this.errorMessage[key] = '';
        }
        this.cd.detectChanges();
      }
    });
  }
}
