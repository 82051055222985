import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MsaPageModel } from '@app/powersports/msa/pages/msa-page.component';
import { PeoplePageModel } from '@core/models/views/people-page-model';
import { PniPageModel } from '@core/models/views/pni-page-model';
import { VehiclesPageModel } from '@core/models/views/vehicle-page-model';
import { AgencyService } from '@core/services/agency.service';
import { MemberService } from '@core/services/member.service';
import { ProductsService } from '@core/services/products.service';
import { VehicleService } from '@core/services/vehicle.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { AccountService } from '../../../core/services/account.service';
import { AgencyModel } from '@entities/agency/agency.model';
import { TasksModalModel } from '@entities/task/selectors/tasks-modal.selector';
import { Store } from '@ngrx/store';
import { TasksModalSelectors } from '@core/store/selectors';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { TaskModel } from '@entities/task/task.model';
import { UwActivitiesStoreModel } from '@entities/uw-activities/uw-activities.reducer';
import { UwActivitiesService } from '@entities/uw-activities/uw-activities.service';

@Component({
  selector: 'nwx-tasks-modal-container',
  templateUrl: './tasks-modal-container.component.html',
  styleUrls: ['./tasks-modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksModalContainerComponent implements OnDestroy {
  products$: Observable<ProductModel[]>;
  agencyState$: Observable<AgencyModel>;
  pniPageModel$: Observable<PniPageModel>;
  membersPageModel$: Observable<PeoplePageModel>;
  autoPageModel$: Observable<VehiclesPageModel>;
  msaPageModel$: Observable<MsaPageModel>;
  boatPageModel$: Observable<MsaPageModel>;
  rvPageModel$: Observable<MsaPageModel>;
  accountId$: Observable<Nullable<string>>;
  tasks$: Observable<TasksModalModel>;
  uwActivities$: Observable<UwActivitiesStoreModel[]>;
  // Provided by caller:
  @Input() tasks!: TasksModalModel;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private vehicleService: VehicleService,
    private memberService: MemberService,
    private productsService: ProductsService,
    private agencyService: AgencyService,
    private accountService: AccountService,
    private store: Store,
    private uwActivitiesService: UwActivitiesService
  ) {
    this.products$ = this.productsService.getSelectedProducts().pipe(takeUntil(this.unsubscribe$));
    this.pniPageModel$ = this.memberService.getPniPageModel().pipe(takeUntil(this.unsubscribe$));
    this.membersPageModel$ = this.memberService.getPeoplePageModel().pipe(takeUntil(this.unsubscribe$));
    this.msaPageModel$ = this.vehicleService.getMsaPageModel().pipe(takeUntil(this.unsubscribe$));
    this.autoPageModel$ = this.vehicleService.getVehiclesPageModel().pipe(takeUntil(this.unsubscribe$));
    this.boatPageModel$ = this.vehicleService.getBoatPageModel().pipe(takeUntil(this.unsubscribe$));
    this.rvPageModel$ = this.vehicleService.getRvPageModel().pipe(takeUntil(this.unsubscribe$));
    this.agencyState$ = this.agencyService.getAgency().pipe(takeUntil(this.unsubscribe$));
    this.accountId$ = this.accountService.getAccountId().pipe(takeUntil(this.unsubscribe$));
    this.tasks$ = this.store.select(TasksModalSelectors.getStickyTasksForPostRetrieveModal).pipe(
      map((newTasks) => this.mergeTasks(this.tasks, newTasks)),
      take(1)
    );
    this.uwActivities$ = this.uwActivitiesService.getUwActivitiesForDisplay();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCloseModal(): void {
    this.activeModal.close();
  }

  /** New model with the tasks from initialTasks, replacing if they appear in incomingTasks.
   * But no tasks will be added or removed from initialTasks.
   */
  private mergeTasks(
    initialTasks: TasksModalModel,
    incomingTasks: TasksModalModel
  ): TasksModalModel {
    return {
      dummyProducts: initialTasks.dummyProducts,
      taskProductTypes: initialTasks.taskProductTypes,
      incompleteTasks: this.mergeTaskArrays(
        initialTasks.incompleteTasks,
        incomingTasks.incompleteTasks
      ),
      availabilityTasks: this.mergeTaskArrays(
        initialTasks.availabilityTasks,
        incomingTasks.availabilityTasks
      ),
      dummyProducerCodeTasks: this.mergeTaskArrays(
        initialTasks.dummyProducerCodeTasks,
        incomingTasks.dummyProducerCodeTasks
      ),
      effectiveDateTasks: this.mergeTaskArrays(
        initialTasks.effectiveDateTasks,
        incomingTasks.effectiveDateTasks
      ),
      pniTasks: this.mergeTaskArrays(
        initialTasks.pniTasks,
        incomingTasks.pniTasks
      ),
      memberTasks: this.mergeTaskArrays(
        initialTasks.memberTasks,
        incomingTasks.memberTasks
      ),
      propertyTasks: this.mergeTaskArrays(
        initialTasks.propertyTasks,
        incomingTasks.propertyTasks
      ),
      autoTasks: this.mergeTaskArrays(
        initialTasks.autoTasks,
        incomingTasks.autoTasks
      ),
      msaTasks: this.mergeTaskArrays(
        initialTasks.msaTasks,
        incomingTasks.msaTasks
      ),
      boatTasks: this.mergeTaskArrays(
        initialTasks.boatTasks,
        incomingTasks.boatTasks
      ),
      rvTasks: this.mergeTaskArrays(
        initialTasks.rvTasks,
        incomingTasks.rvTasks
      ),
    };
  }

  private mergeTaskArrays(
    initialTasks: TaskModel[],
    incomingTasks: TaskModel[]
  ): TaskModel[] {
    return initialTasks.map((task) => {
      const replacement = incomingTasks.find((incoming) => incoming.id === task.id);
      return replacement || {
        ...task
      };
    });
  }
}
