import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/store/reducers';

export const getAgencyState = createSelector(
  fromCore.getCoreState,
  (state) => state.agency
);

export const getAgency = createSelector(getAgencyState, (agency) => agency.agency);

export const isAgentStateLoaded = createSelector(
  getAgencyState,
  (agency) => agency.isAgentStateLoaded
);

export const getAgentState = createSelector(
  getAgency,
  (agency) => agency.agent?.agentState || ''
);

export const getProducerCodeSearch = createSelector(
  getAgencyState,
  (agency) => agency.producerSearch
)

export const getStateProducerSearch = createSelector(
  getAgencyState,
  (agency) => agency.producerOptions
)
