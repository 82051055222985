import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProducerResponse, ProducerSearch } from '@core/adapters/producer-search.model';
import { ProductType } from '@core/models/api/dsm-types';
import { AgencyService } from '@core/services/agency.service';
import { ProductModel } from '@entities/product/product.model';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { map, mergeWith, take } from 'rxjs/operators';

@Component({
  selector: 'nwx-account-info-form',
  templateUrl: './account-info-form.component.html',
  styleUrls: ['./account-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInfoFormComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() product!: ProductModel;
  @Input() state!: string;
  @Input() agencyCode!: string;
  @Input() canEdit = true;
  @Output() edit = new Subject<ProductType>();

  agencyService: AgencyService;
  fb: FormBuilder;

  producerCodeOptions$: Nullable<Observable<ProducerResponse[]>> = null;
  loading$: Observable<boolean> = of(false);
  unsubscribe$ = new Subject<void>();
  editing = false;

  constructor(agencyService: AgencyService, fb: FormBuilder) {
    this.fb = fb;
    this.agencyService = agencyService;
  }

  ngOnInit(): void {
    this.form.addControl(
      this.product.type + '-producerCode',
      this.fb.control(this.product.producer?.producerCode, [
        Validators.required,
      ])
    );
    this.form.addControl(
      this.product.type + '-agencyCode',
      this.fb.control('')
    );
    this.loadProducerCodeChoices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  beginEdit() {
    this.editing = true;
    this.edit.next(this.product.type);
  }

  loadProducerCodeChoices() {
    this.producerCodeOptions$ = this.agencyService
      .getProducerCodes(this.state)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((search: ProducerSearch) =>
          search.producers
            .filter((p) => this.isValidCodeForAgent(p), [])
            .sort((a, b) => {
              return a.producerCode <= b.producerCode ? -1 : 1;
            })
        )
      );
    this.loading$ = of(false).pipe(
      mergeWith(
        this.agencyService.getProducerCodes(this.state).pipe(
          takeUntil(this.unsubscribe$),
          map((res) => res.producers.length > 0)
        )
      )
    );
  }

  isValidCodeForAgent(producer: ProducerResponse): boolean {
    if (!producer) return false;
    if (producer.producerCode.endsWith('000')) return false;
    return true;
  }

  imageUrl(productType: ProductType): string {
    return ProductUtils.getImageUrlForProduct(productType);
  }

  producerCodeDisplay(): string {
    const producer = this.product?.producer;
    if (!this.product || !producer) return '';
    return producer.agentName
      ? `${producer.producerCode} | ${producer.agentName}`
      : producer.producerCode;
  }

  producerOptionDisplay(producer: ProducerResponse): string {
    if (!producer) return '';
    return producer.agentName
      ? `${producer.producerCode} | ${producer.agentName}`
      : producer.producerCode;
  }

  onProducerCodeChanged(productType: string, event: any): void {
    const producerCode = event?.target?.value;
    if (!producerCode || !this.producerCodeOptions$) {
      return;
    }
    this.producerCodeOptions$.pipe(take(1)).subscribe((options) => {
      const option = options.find((o) => o.producerCode === producerCode);
      if (option) {
        this.form.get(productType + '-agencyCode')?.setValue(option.agencyCode);
      }
    });
  }
}
