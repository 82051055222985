import { Producer } from '@core/store/entities/agency/agency.model';
import { PreQualification } from '@core/interfaces/interfaces';
import {
  CCYYMMDDDate,
  DocumentDeliveryType,
  NationwideAccountRegistrationInfo,
  OfferingType,
  ProductType,
  QuoteStatus,
  TermType,
} from '@core/models/api/dsm-types';
import { InformationalMessages } from '@core/adapters/quote-retrieve.adapter';
import { ObjectValidator } from '@core/helper/object-validator';

// Please keep Alphabetized
export interface ProductModel {
  type: ProductType;
  allQuoteCoverageLoaded?: boolean;
  associatedMSBEstimateNumber?: string;
  autoCharacteristics?: AutoCharacteristics;
  automateElectronicDocumentDelivery?: boolean;
  creditConsent?: boolean;
  currentBillTo?: 'PolicyPriNamedInsured' | 'Mortgagee';
  effectiveDate?: CCYYMMDDDate;
  errorCode?: string;
  errorMessage?: string;
  docDelPreference?: DocumentDeliveryType;
  hasBindSuspension?: boolean;
  hasConnectedCarConsent?: boolean;
  hasCustomerViewedDocuments?: boolean;
  hasDrivingDataConsent?: boolean | null;
  hasError?: boolean;
  hasNWPSPolicies?: boolean;
  hasReceivedRequiredDocuments?: boolean;
  imageUrl?: string;
  index?: number;
  informationalMessages?: InformationalMessages[];
  initiated?: boolean;
  isAddon?: boolean;
  isAssignedRiskPlan?: boolean;
  isAvailable?: boolean;
  isDsmActive?: boolean;
  isConditional?: boolean;
  isQualified?: boolean;
  issueCompleted?: boolean;
  issueFailed?: boolean; // Failure to issue is unlike regular errors
  issueLoading?: boolean;
  leadSentToCma?: boolean;
  monthlyPremium?: number;
  nationwideAccountRegistrationInfo?: NationwideAccountRegistrationInfo;
  name?: string;
  needConstructionInfo?: boolean;
  numberOfOtherVehicles?: number;
  offeringType?: OfferingType;
  policyNumber?: string;
  policyNumberLoaded?: boolean;
  policyNumberLoading?: boolean;
  prequalificationAnswers?: PreQualification[];
  producer?: Producer;
  quoteBinding?: boolean;
  quoteBound?: boolean;
  quoteCompleted?: boolean;
  quoteCoverageLoaded?: boolean;
  quoteCoverageLoading?: boolean;
  quoteFurthestStatus?: QuoteStatus;
  quoteCreateDate?: string;
  quoteId?: string;
  quoteLoading?: boolean;
  quoteRated?: boolean;
  quoteRating?: boolean;
  quoteStatus?: QuoteStatus;
  quoteUpdateLoaded?: boolean;
  quoteUpdateLoading?: boolean;
  requireDrivingConsent?: boolean;
  sessionId?: string;
  show?: boolean;
  tempUserId?: string;
  termType?: TermType;
  tokenLoaded?: boolean;
  tokenLoading?: boolean;
  uwReportsLoaded?: boolean;
  uwReportsImplemented?: boolean;
  yearsWithPriorCarrier?: string;

  // Product specific boolean flags
  hasPolicyholders?: boolean;
  hasDrivers?: boolean;
  hasVehicles?: boolean;
  hasTelematics?: boolean;

  additionalHouseholdInfo?: AdditionalHouseholdInfo;
  hasCompanyCar?: boolean;

  eligibilityInformation?: EligibilityInformationEntity;
}

export interface AdditionalHouseholdInfo {
  additionalAutoPoliciesUnderNWFamilyOfCompanies?: boolean;
  numberOfOtherDrivers?: number;
  numberOfOtherVehicles?: number;
  dateOfBirthOfYoungestDriver?: string;
  dateFirstLicensedOfLeastExperiencedDriver?: string;
}

export type ProductWithState = {
  product: ProductType;
  state: string;
};

export interface EligibilityInformationEntity {
  householdMemberInformation?: HouseholdMemberInformationEntity;
  vehicleExposureInformation: VehicleExposureInformationEntity;
  locationExposureInformation: LocationExposureInformationEntity;
  watercraftExposureInformation?: WatercraftExposureInformationEntity;
}

export function sanitizeEligibilityInformation(input: unknown) {
  return ObjectValidator.forceSchema<EligibilityInformationEntity>(
    input,
    {
      householdMemberInformation: sanitizeHouseholdMemberInformationEntity,
      vehicleExposureInformation: sanitizeVehicleExposureInformationEntity,
      locationExposureInformation: sanitizeLocationExposureInformationEntity,
      watercraftExposureInformation:
        sanitizeWatercraftExposureInformationEntity,
    },
    [
      'householdMemberInformation',
      'vehicleExposureInformation',
      'locationExposureInformation',
      'watercraftExposureInformation',
    ]
  );
}

export interface HouseholdMemberInformationEntity {
  isSuedForSlanderOrLibel?: boolean;
  hasLargePublicityInPast?: boolean;
  hasNonPaidOccupation?: boolean;
  // EVERYTHING BELOW IS NOT REAL. Card w/ Wildlings to add these to the model, but names could change
  isSuedForSlanderOrLibelDescription?: string;
  hasLargePublicityInPastDescription?: string;
  hasNonPaidOccupationDescription?: string;
  nameOfCharity?: string;
  nonPaidOccupationLiabilityCoverage?: boolean;
}

export function sanitizeHouseholdMemberInformationEntity(
  input: unknown
): HouseholdMemberInformationEntity {
  return ObjectValidator.forceSchema<HouseholdMemberInformationEntity>(
    input,
    {
      isSuedForSlanderOrLibel: 'boolean',
      hasLargePublicityInPast: 'boolean',
      hasNonPaidOccupation: 'boolean',
      isSuedForSlanderOrLibelDescription: 'string',
      hasLargePublicityInPastDescription: 'string',
      hasNonPaidOccupationDescription: 'string',
      nameOfCharity: 'string',
      nonPaidOccupationLiabilityCoverage: 'boolean',
    },
    [
      'isSuedForSlanderOrLibel',
      'hasLargePublicityInPast',
      'hasNonPaidOccupation',
      'isSuedForSlanderOrLibelDescription',
      'hasLargePublicityInPastDescription',
      'hasNonPaidOccupationDescription',
      'nameOfCharity',
      'nonPaidOccupationLiabilityCoverage',
    ]
  );
}

export interface VehicleExposureInformationEntity {
  anyUnlistedVehicle?: boolean;
  isAllVehiclesCoveredByNationwide?: boolean;
  hasRestrictedCoverage?: boolean;
  anyVehicleForCompetition?: boolean;
  anyVehicleForRacing?: boolean;
}

export function sanitizeVehicleExposureInformationEntity(
  input: unknown
): VehicleExposureInformationEntity {
  return ObjectValidator.forceSchema<VehicleExposureInformationEntity>(
    input,
    {
      anyUnlistedVehicle: 'boolean',
      isAllVehiclesCoveredByNationwide: 'boolean',
      hasRestrictedCoverage: 'boolean',
      anyVehicleForCompetition: 'boolean',
      anyVehicleForRacing: 'boolean',
    },
    [
      'anyUnlistedVehicle',
      'isAllVehiclesCoveredByNationwide',
      'hasRestrictedCoverage',
      'anyVehicleForCompetition',
      'anyVehicleForRacing',
    ]
  );
}

export interface LocationExposureInformationEntity {
  allLocationsCoveredByNationwide?: boolean;
}

export function sanitizeLocationExposureInformationEntity(
  input: unknown
): LocationExposureInformationEntity {
  return ObjectValidator.forceSchema<LocationExposureInformationEntity>(
    input,
    { allLocationsCoveredByNationwide: 'boolean' },
    ['allLocationsCoveredByNationwide']
  );
}

export interface WatercraftExposureInformationEntity {
  allWatercraftsCoveredByNationwide?: boolean;
  anyWatercraftForCompetition?: boolean;
}

export function sanitizeWatercraftExposureInformationEntity(
  input: unknown
): WatercraftExposureInformationEntity {
  return ObjectValidator.forceSchema<WatercraftExposureInformationEntity>(
    input,
    {
      allWatercraftsCoveredByNationwide: 'boolean',
      anyWatercraftForCompetition: 'boolean',
    },
    ['allWatercraftsCoveredByNationwide', 'anyWatercraftForCompetition']
  );
}

export interface AutoCharacteristics {
  autoBiOccurLimit?: string;
  autoBiPerPersonLimit?: string;
  minVehicleModelYear?: string;
}

export function sanitizeAutoCharacteristics(
  input: unknown
): AutoCharacteristics {
  return ObjectValidator.forceSchema<AutoCharacteristics>(
    input,
    {
      autoBiOccurLimit: 'string',
      autoBiPerPersonLimit: 'string',
      minVehicleModelYear: 'string',
    },
    ['autoBiOccurLimit', 'autoBiPerPersonLimit', 'minVehicleModelYear']
  );
}

export function sanitizeAdditionalHouseholdInfo(
  input: unknown
): AdditionalHouseholdInfo {
  return ObjectValidator.forceSchema<AdditionalHouseholdInfo>(
    input,
    {
      additionalAutoPoliciesUnderNWFamilyOfCompanies: 'boolean',
      numberOfOtherDrivers: 'number',
      numberOfOtherVehicles: 'number',
      dateOfBirthOfYoungestDriver: 'string',
      dateFirstLicensedOfLeastExperiencedDriver: 'string',
    },
    [
      'additionalAutoPoliciesUnderNWFamilyOfCompanies',
      'numberOfOtherDrivers',
      'numberOfOtherVehicles',
      'dateOfBirthOfYoungestDriver',
      'dateFirstLicensedOfLeastExperiencedDriver',
    ]
  );
}
