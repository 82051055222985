import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { UwIssuesViewModel } from '../uw-reports-card/uw-reports-view-model';
import { ModalService } from '@shared/services/modal.service';
import { ProductsService } from '@core/services/products.service';
import { UwIssuesByProduct } from '@entities/uw-issues/uw-issues.selector';
import { ProductUtils } from '@shared/utils/product.util';

@Component({
  selector: 'nwx-next-step-uw-issues',
  templateUrl: './next-step-uw-issues.component.html',
  styleUrls: ['./next-step-uw-issues.component.scss'],
})
export class NextStepUwIssuesComponent implements OnChanges {
  @Input() uwIssues: UwIssuesByProduct = {};
  @Input() disabledProducts: ProductType[] = [];
  model: UwIssuesViewModel = { products: [] };

  constructor(
    private modalService: ModalService,
    private productsService: ProductsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uwIssues || changes.disabledProducts) {
      this.model = { products: [] };
      for (const productType of Object.keys(this.uwIssues) as ProductType[]) {
        this.model.products.push({
          productType,
          productName: ProductUtils.getShortNameForProduct(productType),
          disableApproval: this.disabledProducts.includes(productType),
          issues: this.uwIssues[productType]!.map((issue) => issue.description),
        });
      }
    }
  }

  onRequestUwApproval(productType: ProductType): void {
    this.modalService.uwReferralModal(productType);
  }

  onRemoveProduct(productType: ProductType): void {
    this.productsService.selectProduct(productType, false);
  }
}
