<form [formGroup]="form" [nwxExposeFormErrors]="form" formName="leak-detection">
  <h2 *ngIf="isModal">Leak detection system eligibility</h2>
  <div [ngClass]="{ 'inner-card': isModal }">
    <div [ngClass]="{ 'content-padding': !isModal }">
      <div class="bolt-container-fluid">
        <div class="bolt-row bolt-space-bottom-lg">
          <div class="bolt-col">
            <p class="heading">
              Water Protection Device Requirement (notify customer)
            </p>
            <p>
              To continue with the quote, the customer must acknowledge the
              below water protection device requirements.
            </p>
            <p>
              Water protection devices can help prevent non-weather water damage
              to your home and belongings. Nationwide is requiring that one of
              these devices are in your home in order to get a quote and policy.
            </p>
            <strong>Nationwide is offering two ways to continue.</strong>
          </div>
        </div>
      </div>
    </div>
    <hr *ngIf="!isModal" />
    <div [ngClass]="{ 'content-padding': !isModal }">
      <div class="bolt-container-fluid">
        <div class="bolt-row bolt-space-bottom-lg">
          <div class="bolt-col-12 bolt-col-sm-6">
            <nwx-discount-badge
              name="Option 1"
              class="bolt-space-bottom-lg"
            ></nwx-discount-badge>
            <div class="indentation">
              <p class="smart-home-heading">Home monitor system discount</p>
              <p>
                Customer can get a water protection device by participating in
                Nationwide's Smart Home program.
              </p>
              <p>
                <bolt-radio-group
                  ngDefaultControl
                  formControlName="selectedOptionValue"
                  label="Enroll in smart home program?"
                  name="smarthome"
                  id="shf-smarthome"
                  horizontal
                >
                  <bolt-radio value="Participating">Yes</bolt-radio>
                  <bolt-radio value="Declined">No</bolt-radio>
                </bolt-radio-group>
              </p>
            </div>
          </div>
          <div class="bolt-col-12 bolt-col-sm-6">
            <div class="header-badge bolt-space-bottom-lg">Option 2</div>
            <p class="indentation">
              If customer already has a water protection device, a document
              might be needed to verify they have one of the devices we accept.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="!isModal" />
  <div [ngClass]="{ 'content-padding': !isModal }">
    <div class="bolt-container-fluid">
      <div class="bolt-row">
        <div
          class="bolt-col ack"
          [ngClass]="{ invalid: showAcknowledgementHighlight }"
        >
          <bolt-icon
            *ngIf="showAcknowledgementHighlight"
            name="exclamation-circle-filled"
            color="theme-error"
          ></bolt-icon>
          <bolt-checkbox
            id="water-protection-device-requirement-communicated"
            formControlName="waterProtectionDeviceRequirementCommunicated"
            name="waterProtectionDeviceRequirementCommunicated"
            value="true"
            arialabelledby="eligibility-ack-text"
            ngDefaultControl
          >
            <strong>
              I informed the customer of the water protection device
              requirements.
            </strong>
          </bolt-checkbox>
        </div>
      </div>
    </div>
  </div>
</form>
