import { Injectable } from '@angular/core';
import { PeoplePageSubmission } from '@app/people/pages/member/member-page.component';
import { PniPageSubmission } from '@app/pni/pages/pni/pni-page.component';
import { MemberModel } from '@core/models/views/person.model';
import { PniPageModel } from '@core/models/views/pni-page-model';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, take } from 'rxjs';
import {
  HeaderCardItem,
  SubmitPeoplePageData,
  SubmitPniPageData,
  UpdateSelectedEntity,
} from '../interfaces/interfaces';
import { PeoplePageModel } from '../models/views/people-page-model';
import { CompositeActions, MemberActions } from '../store/actions';
import { StringUtils } from '@shared/utils/string.utils';
import { MemberSelectors } from '@core/store/selectors';
import { GenderOption } from '@shared/constants/suffix-constants';
import { Nullable } from '@shared/utils/type.utils';
import { PolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { PeoplePageUtils } from '@shared/utils/pages/people-page.utils';
import { ProductsService } from './products.service';
import { Actions, ofType } from '@ngrx/effects';

export interface MemberForDisplay {
  name: string;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(
    private store: Store,
    private actions$: Actions,
    private productsService: ProductsService
  ) {}

  storeMember(person: MemberModel): void {
    this.store.dispatch(MemberActions.storeMember({ payload: person }));
  }

  addNewMember(): void {
    this.store.dispatch(
      MemberActions.addBlankMember({ payload: StringUtils.generateEntityId() })
    );
  }

  addPni(): void {
    this.store.dispatch(
      MemberActions.addPni({ payload: StringUtils.generateEntityId() })
    );
  }

  updateMemberSelected(updatedSelected: UpdateSelectedEntity): void {
    this.store.dispatch(
      MemberActions.updateMemberSelected({
        payload: updatedSelected,
      })
    );
  }

  getAllSelectedPeople(): Observable<MemberModel[]> {
    return this.store.select(MemberSelectors.getAllSelectedMemberModels);
  }

  getAllSelectedDrivers(productType: ProductType): Observable<MemberModel[]> {
    return this.store.select(
      MemberSelectors.getAllSelectedDriverMemberModels(productType)
    );
  }

  getGenderOptions(): Observable<GenderOption[]> {
    return this.store.select(MemberSelectors.getGenderOptions);
  }

  getPeoplePageModel(): Observable<PeoplePageModel> {
    return this.store.select(MemberSelectors.getMemberPageModel);
  }

  getAllPeopleHeaderItems(): Observable<HeaderCardItem[]> {
    return this.store.select(MemberSelectors.getAllMemberHeaderItems);
  }

  getPrimaryNamedInsured(): Observable<Nullable<PolicyHolderEntity>> {
    return this.store.select(MemberSelectors.getPrimaryNamedInsured);
  }

  getPrimaryNamedInsuredForProduct(
    productType: ProductType
  ): Observable<Nullable<PolicyHolderEntity>> {
    return this.store.select(
      MemberSelectors.getPrimaryNamedInsuredForProduct(productType)
    );
  }

  getSubmitPeoplePageData(): Observable<SubmitPeoplePageData> {
    return this.store.select(MemberSelectors.getSubmitPeoplePageData);
  }

  submitPeoplePage(submission: PeoplePageSubmission): void {
    this.store.dispatch(CompositeActions.submitPeoplePage({ submission }));
  }

  submitPeoplePageAndAwaitCompletion(): Promise<void> {
    let resolve: () => void;
    let reject: () => void;
    const result = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    this.actions$
      .pipe(
        ofType(
          CompositeActions.submitPeoplePageSuccess,
          CompositeActions.submitPeoplePageError
        ),
        take(1)
      )
      .subscribe({
        next: (action) => {
          if (action.type === CompositeActions.submitPeoplePageSuccess.type) {
            resolve();
          } else {
            reject();
          }
        },
        error: () => reject(),
      });
    combineLatest([
      this.store.select(MemberSelectors.getAllMemberModelsWithOptions),
      this.productsService.getSelectedProducts(),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([members, products]) => {
          const submission = PeoplePageUtils.CreatePeoplePageSubmission(
            members,
            products,
            null,
            null
          );
          this.submitPeoplePage(submission);
        },
      });
    return result;
  }

  getPniPageModel(): Observable<PniPageModel> {
    return this.store.select(MemberSelectors.getPniPageModel);
  }

  getSubmitPniPageData(): Observable<SubmitPniPageData> {
    return this.store.select(MemberSelectors.getSubmitPniPageData);
  }

  submitPniPage(submission: PniPageSubmission): void {
    this.store.dispatch(CompositeActions.submitPniPage({ submission }));
  }

  getPniHomeNumber(): Observable<Nullable<string>> {
    return this.store.select(MemberSelectors.getPniHomeNumber);
  }

  getPniEmail(): Observable<Nullable<string>> {
    return this.store.select(MemberSelectors.getPniEmail);
  }

  getPersonMinimumAge(
    person: Partial<Nullable<MemberModel>>
  ): Observable<number> {
    return this.store.select(MemberSelectors.getPersonMinimumAge(person));
  }

  formatPolicyHoldersForDisplay(
    members: MemberModel[],
    product: ProductType
  ): MemberForDisplay[] {
    const plabel = '(PNI)';
    const slabel = '(SNI)';
    const output: MemberForDisplay[] = [];
    for (const member of members) {
      const phRole = member.policyRoles?.find(
        (r) => r.productType === product && r.entityType === 'policyHolder'
      );
      if (phRole) {
        const disp: MemberForDisplay = {
          name: this.displayNameForPerson(member),
          role: '',
        };
        switch (phRole.roleSequence) {
          case 'primary':
            disp.role = plabel;
            break;
          case 'secondary':
            disp.role = slabel;
            break;
        }
        output.push(disp);
      }
    }
    output.sort((a, b) => {
      if (a.role === b.role) return 0;
      if (a.role === plabel) return -1;
      if (b.role === plabel) return 1;
      if (a.role === slabel) return -1;
      if (b.role === slabel) return 1;
      return 0;
    });
    return output;
  }

  private displayNameForPerson(person: MemberModel): string {
    if (person.person?.firstName || person.person?.lastName) {
      return `${person.person.firstName || ''} ${person.person.lastName || ''}`;
    }
    return person.entityId;
  }

  formatDriversForDisplay(
    members: MemberModel[],
    product: ProductType
  ): MemberForDisplay[] {
    const output: MemberForDisplay[] = [];
    for (const member of members) {
      if (member.driverType === 'Excluded') {
        continue;
      }
      const role = member.policyRoles?.find(
        (r) => r.productType === product && r.entityType === 'driver'
      );
      if (role) {
        output.push({
          name: this.displayNameForPerson(member),
          role: '',
        });
      }
    }
    return output;
  }

  hasSecondaryMatchFlag(): Observable<boolean> {
    return this.store.select(MemberSelectors.hasSecondaryMatchFlag);
  }
}
