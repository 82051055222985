import { Pipe, PipeTransform } from '@angular/core';
import { GeneralUtils } from '@shared/utils/general.utils';

@Pipe({ name: 'filterByObjectAttribute' })
export class FilterByObjectAttributePipe implements PipeTransform {
  /**
   * Filters an array of objects so that all entities have the desired value for a given attribute.
   * @param arr array of objects to filter on
   * @param attribute the attribute to filter by
   * @param desiredValue the desired value for that attribute
   */
  transform(arr: any[], attribute: any, desiredValue: any) {
    return arr.filter((obj) => {
      const attrValue = GeneralUtils.findAttributeValue(obj, attribute);
      return attrValue === desiredValue;
    });
  }
}
